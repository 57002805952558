import React from 'react';
import { getDashboardLink } from '../../Services/common';
import './upgrademesh.css';

function UpgradeMesh() {
	return (
		<div className="pageWrapper">
			<div className="pageContainer">
				<div className="order-dlts-wrapper">
					<div className="pageHeader d-flex align-items-center flex-column flex-lg-row">
						<div className="d-flex flex-fill align-items-center mesh-logoWrap flex-column flex-sm-row ">
							<div className="logoHolder mr-sm-4 text-sm-left text-center">
								<img className="img-fluid" src={require('../../assets/images/logo.png')} alt="Logo" />
							</div>
							<h1 className="btn-sty-title flex-fill my-3 my-lg-0">Upgrade your MESH*</h1>
						</div>
						<div className="mesh-control-btns d-flex align-items-center">
							<a href={getDashboardLink()}><button type="button" className="btn btn-polygon-sty dark-pink">
								<img className="img-fluid" src={require('../../assets/images/left-arrow.svg')} alt="icon" />
								<span>Go back</span>
							</button></a>
							<a href={getDashboardLink()}><button type="button" className="btn btn-polygon-sty dark-blue">
								<img className="img-fluid" src={require('../../assets/images/Monitor-icon.svg')} alt="icon" />
								<span>Dash<br />board</span>
							</button></a>
							<button type="button" className="btn btn-polygon-sty dark-green">
								<img className="img-fluid" src={require('../../assets/images/Cancel.svg')} alt="icon" />
								<span>check<br />out</span>
							</button>
						</div>
					</div>
				</div>
				<div className="pageContent">
					<div className="tableContent">
						<div className="table-responsive">
							<table className="table meshPackageTable">
								<thead>
									<tr>
										<th>&nbsp;</th>
										<th>
											<span className="tableHeadingText d-block">click to book:</span>
										</th>
										<th>
											<span className="btn mesh-btn-brown">Basic Mesh</span>
										</th>
										<th>
											<span className="btn mesh-btn-grey">Comfort Mesh</span>
										</th>
										<th>
											<span className="btn mesh-btn-yellow">Premium Mesh</span>
										</th>
										<th>&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>PROVISION ORDER FORM incl. consumption and nutrition overview</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Stores ORDER FORM including budget-overview <span className="badge badge-primary text-white">IMPA Coded</span></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Reliable global supplier network</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Sustainable vendor management</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Fixed prices in all main supply ports</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Automated price comparison per order</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Supply data transfer into customer´s ERP-System via interface</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td><strong>Dashboard</strong> including following information:</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Order history during membership period</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Supplier ranking per port</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Evaluation of actual victualing rate and consumption</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Evaluation of budget per IMPA stores department</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td><strong>Complete claim management</strong> including following services:</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Coordination of supplies with vendor and vessel  incl. organisation of back orders</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Order proposal for convenient ports</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Quarterly reports per fleet & vessel</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Frequent on-board audits</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Electronic invoice management</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Fixed prices in all main supply ports - import of pricelists via interface</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Your defined supply area with numerous selected ports</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Supplier ranking per region / per port by customer´s performance peports</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Communication with customer via cloud-chat</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Market place for your special offers</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Showroom to promote your company profile</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Quarterly reports per customer</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td><strong>Dashboard</strong> incl. important numbers (order rate, top seller items, etc.)</td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
									<tr>
										<td></td>
										<td>Frequent audits </td>
										<td>&nbsp;</td>
										<td>&nbsp;</td>
										<td><img src={require('../../assets/images/correct.svg')} alt="Icon" /></td>
										<td></td>
									</tr>
								</tbody>
								<tfoot>
									<tr className="spacerRow">
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
									<tr className="spacerRow">
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
									<tr>
										<td>&nbsp;</td>
										<td>Monthly Free</td>
										<td>Free</td>
										<td>59,95 EUR</td>
										<td>59,95 EUR</td>
										<td>&nbsp;</td>
									</tr>
									<tr>
										<td>&nbsp;</td>
										<td>Commision per order</td>
										<td>19,95 EUR</td>
										<td>2,5% off order value</td>
										<td>7,5%</td>
										<td>&nbsp;</td>
									</tr>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UpgradeMesh;