import React, { useEffect, useState  } from 'react';
import {Wrapper, DashboardNavbar } from '../../Common/Components/index';
import '../css/roles.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function CreateQuotes(props) {
        // const [startDate, setStartDate] = useState(new Date());
        // const [endDate, setEndDate] = useState(null);
        const navbarOptions = [
            {
                img: 'left-arrow.svg',
                className: "orange",
                name: "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.history.goBack()
            },
        ];


    return (
    <Wrapper>
            <div className="">
            <DashboardNavbar title="Create Quotes" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
                {/* filters */}
                <div className="formBox mt-4">
                    <form className="needs-validation">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group floating-label-wrap">
                                    <input type="text" id="full_name" name="name" className="form-control"  placeholder="Role Name" required />
                                    <lable htmlFor="full_name" className="foating-labels-sty">Name</lable>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group floating-label-wrap">
                                    <input type="text" id="full_name" name="name" className="form-control"  placeholder="Company Name" required/>
                                    <lable htmlFor="full_name" className="foating-labels-sty">Company Name</lable>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group floating-label-wrap">
                                    <input type="text" id="full_name" name="name" className="form-control"  placeholder="Company Website" required/>
                                    <lable htmlFor="full_name" className="foating-labels-sty">Company Website</lable>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group floating-label-wrap">
                                    <input type="text" id="full_name" name="name" className="form-control"  placeholder="Company Strenght" required />
                                    <lable htmlFor="full_name" className="foating-labels-sty">Company Strenght</lable>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group floating-label-wrap">
                                    <input type="text" id="full_name" name="name" className="form-control"  placeholder="Your Department" required/>
                                    <lable htmlFor="full_name" className="foating-labels-sty">Your Department</lable>
                                </div>
                            </div>                            
                            <div className="col-sm-6">
                                <div className="form-group floating-label-wrap">
                                    <input type="text" id="full_name" name="name" className="form-control"  placeholder="Your Designation" required/>
                                    <lable htmlFor="full_name" className="foating-labels-sty">Your Designation</lable>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group floating-label-wrap">
                                    <input type="text" id="full_name" name="name" className="form-control"  placeholder="Email" required/>
                                    <lable htmlFor="full_name" className="foating-labels-sty">Email</lable>
                                </div>
                            </div>                            
                            <div className="col-sm-6">
                                <div className="form-group floating-label-wrap">
                                    <textarea type="text" id="full_name" name="name" className="form-control"  placeholder="Message" required/>
                                    <lable htmlFor="full_name" className="foating-labels-sty">Message</lable>
                                </div>
                            </div>
                        </div>
                        <div className="form-btn d-flex justify-content-end flex-fill">
                            <button type="submit" className="btn btn themeReguler-btn bg-sky-blue">Submit</button>
                        </div>
                    </form>
                </div>
                </div>
            </Wrapper>
    )
}
