import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './dashboard-style.css';
import { Wrapper, DashboardNavbar, CsvDownload } from '../../Common/Components/index';
import { orderListing, cartListing } from '../ApiCalls/orderListing'
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from "react-datepicker";
import InfiniteScroll from "react-infinite-scroll-component";
import { vesselListing } from './../../Vessel/ApiCalls/vessel';
import loader from '../../../assets/images/Spinner-1s-200px.gif';
import { ApiClearAction, ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { capitalizeFirstLetter } from '../../../Services/common';
import Swal from 'sweetalert2';
const headers = [
    { label: 'Order Id', key: '_id' },
    { label: 'Order Type', key: 'type' },
    { label: 'Status', key: 'orderStatus' },
    { label: 'Price', key: 'totalPrice' },
    { label: 'Date', key: 'createdAt' },
];
var csvLinkRef = React.createRef();
function RecentOrderOverview(props) {
    const [state, setState] = useState({
        orderList: [],
        customerVessels: [],
        startDate: null,
        endDate: null,
        filterQuery: '',
        isCartList: false,
        isCsVDownload: false,
        activePage: 0,
        totalCount: 0,
        isHitApi: false,
    });
    useEffect(() => {
        if (!state.isHitApi) {
            let data = { ...state };
            let filterQuery = '';
            if (props.userDetail.userType === 'supplier') {
                filterQuery = `?supplierId=${props.userDetail.userId}&offset=${0}&limit=${0}`
                props.cartListing(0, filterQuery)
            } else if (props.userDetail.isSubAdmin && props.userDetail.userType === 'user' && props.userDetail.subType === 'captain') {
                filterQuery = `?captainId=${props.userDetail.userId}&offset=${0}&limit=${0}`
                props.cartListing(0, filterQuery)
            } else if (props.userDetail.subType === 'customer') {
                props.vesselListing(0, 50);
            } else if (props.userDetail.userType === 'user' && (props.userDetail.subType === 'user' || props.userDetail.subType === 'subAdmin')) {
                props.cartListing(0, `?offset=${0}&limit=${0}`)
            }
            data.isHitApi = true;
            data.filterQuery = filterQuery;
            setState(data);
        }
        if ((props.apiCallStatus.apiCallFor === "getOrder" || props.apiCallStatus.apiCallFor === "appendOrderList" || props.apiCallStatus.apiCallFor === "getCart" || props.apiCallStatus.apiCallFor === "appendCartList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            if (state.isCsVDownload) {
                csvLinkRef.current.link.click()
            }
            setState({
                ...state,
                orderList: (props.apiCallStatus.apiCallFor === "getCart" || props.apiCallStatus.apiCallFor === "appendCartList") ? props.dashboardReducer.cartListing : props.dashboardReducer.orderListing,
                totalCount: (props.apiCallStatus.apiCallFor === "getCart" || props.apiCallStatus.apiCallFor === "appendCartList") ? props.dashboardReducer.totalCart : props.dashboardReducer.totalOrder,
                isCartList: (props.apiCallStatus.apiCallFor === "getCart" || props.apiCallStatus.apiCallFor === "appendCartList") ? true : false,
                isCsVDownload: false,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if ((props.apiCallStatus.apiCallFor === "vesselListing" || props.apiCallStatus.apiCallFor === "appendVesselList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let customerVessels = [];
            if (props.userDetail.subType === 'customer' && props.vesselList.length > 0) {
                for (let index = 0; index < props.vesselList.length; index++) {
                    const id = props.vesselList[index]._id;
                    customerVessels.push(id)
                }
                let newArray = JSON.stringify(customerVessels)
                let cartQuery = `?vesselId=${newArray}`;
                props.cartListing(0, cartQuery)
            }
            setState({
                ...state,
                customerVessels,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
    }, [props, state.isHitApi])
    // const applyFilters = () => {
    //     setState({
    //         ...state,
    //         isHitApi: false
    //     })
    // }
    // const clearFilters = () => {
    //     setState({
    //         ...state,
    //         startDate: new Date(new Date().getTime() - (60 * 60 * 24 * 30 * 1000)),
    //         endDate: new Date(),
    //         isHitApi: false
    //     })
    // }
    const fetchMoreData = () => {
        let { activePage, filterQuery } = state;
        activePage += 1;
        setState({
            ...state,
            activePage
        })
        props.cartListing(activePage, filterQuery)
    };
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    const handleChange = (id, val) => {
        setState({
            ...state,
            [id]: val,
        })
    }
    const applyFilters = () => {
        if (state.startDate !== null && state.endDate !== null) {
            let data = { ...state };
            let from = moment(data.startDate).format('YYYY-MM-DD');
            let to = moment(data.endDate).add(1, 'days').format('YYYY-MM-DD');
            let filterQuery = `?from=${from}&to=${to}&offset=${0}&limit=${0}`;
            if (props.userDetail.userType === 'supplier') {
                filterQuery += `&supplierId=${props.userDetail.userId}`
                props.cartListing(0, filterQuery)
            } else if (props.userDetail.isSubAdmin && props.userDetail.userType === 'user' && props.userDetail.subType === 'captain') {
                filterQuery += `&captainId=${props.userDetail.userId}`
                props.cartListing(0, filterQuery)
            } else if (props.userDetail.subType === 'customer') {
                let newArray = JSON.stringify(state.customerVessels)
                filterQuery += `&vesselId=${newArray}`;
                props.cartListing(0, filterQuery)
            } else if (props.userDetail.userType === 'user' && (props.userDetail.subType === 'user' || props.userDetail.subType === 'subAdmin')) {
                props.cartListing(0, filterQuery)
            }
            data.isHitApi = true;
            data.filterQuery = filterQuery;
            data.activePage = 0;
            setState(data);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Please select both date."
            })
        }
    }
    const clearFilters = () => {
        let data = { ...state };
        let filterQuery = '';
        if (props.userDetail.userType === 'supplier') {
            filterQuery = `?supplierId=${props.userDetail.userId}&offset=${0}&limit=${0}`
            props.cartListing(0, filterQuery)
        } else if (props.userDetail.isSubAdmin && props.userDetail.userType === 'user' && props.userDetail.subType === 'captain') {
            filterQuery = `?captainId=${props.userDetail.userId}&offset=${0}&limit=${0}`
            props.cartListing(0, filterQuery)
        } else if (props.userDetail.subType === 'customer') {
            let newArray = JSON.stringify(state.customerVessels)
            filterQuery += `?vesselId=${newArray}`;
            props.cartListing(0, filterQuery)
        } else if (props.userDetail.userType === 'user' && (props.userDetail.subType === 'user' || props.userDetail.subType === 'subAdmin')) {
            props.cartListing(0, filterQuery)
        }
        data.isHitApi = true;
        data.filterQuery = filterQuery;
        data.startDate = null;
        data.endDate = null;
        data.activePage = 0;
        setState(data);
    }
    const apiCallForCSVData = () => {
        /**
         * Requesting csv files data
         */
        let filterQuery = '';
        if (props.userDetail.userType === 'supplier') {
            filterQuery = `?supplierId=${props.userDetail.userId}&offset=${0}&limit=${state.totalCount}`
            props.cartListing(0, filterQuery)
        } else if (props.userDetail.isSubAdmin && props.userDetail.userType === 'user' && props.userDetail.subType === 'captain') {
            filterQuery = `?captainId=${props.userDetail.userId}&offset=${0}&limit=${state.totalCount}`
            props.cartListing(0, filterQuery)
        } else if (props.userDetail.subType === 'customer') {
            let newArray = JSON.stringify(state.customerVessels)
            filterQuery += `?vesselId=${newArray}&offset=${0}&limit=${state.totalCount}`;
            props.cartListing(0, filterQuery)
        } else if (props.userDetail.userType === 'user' && (props.userDetail.subType === 'user' || props.userDetail.subType === 'subAdmin')) {
            props.cartListing(0, `?offset=${0}&limit=${state.totalCount}`)
        }
        setState({
            ...state,
            isCsVDownload: true
        })

    }
    return (
        <Wrapper>
            <DashboardNavbar title="Order overview (Current Orders)" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            {/* Filter  Area*/}
            <div className="ordCatg-table-wrap mt-3 system-cat-filter">
                <div className="card-bx-sty">
                    <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                        <div className="fil-wrap-ctn d-flex align-items-center flex-column flex-sm-row mb-2 mb-lg-0">
                            <div className="custom-select-menu mr-2 p-0">
                                <div className="date-range-picker d-flex align-items-center">
                                    <span className="d-inline-block ml-0">
                                        <DatePicker
                                            selected={state.startDate}
                                            onChange={(val) => handleChange('startDate', val)} className="link-style"
                                        />
                                    </span>
                                    to
                                    <span className="d-inline-block mr-0">
                                        <DatePicker
                                            selected={state.endDate}
                                            maxDate={new Date()}
                                            disabled={state.startDate === null ? true : false}
                                            minDate={state.startDate !== null ? new Date(moment(state.startDate).add(1, 'days')) : new Date(moment().add(1, 'days'))}
                                            onChange={(val) => handleChange('endDate', val)} className="link-style"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                            <button type="button" className="btn btn-yellow" onClick={() => applyFilters()} >Search</button>
                            <button type="button" className="btn btn-yellow ml-1" onClick={() => clearFilters()} >Clear</button>
                            <CsvDownload data={state.orderList} csvLinkRef={csvLinkRef} filename={"Recent-Orders.csv"} headers={headers} apiCallForCSVData={() => apiCallForCSVData()} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-lg-5 d-flex flex-column flex-lg-row">
                <div className="dashVesl-overview-wrap">
                    <div className=" dash-content-bx">
                        <div className="catalouge-details-wrap p-0">
                            <InfiniteScroll
                                dataLength={state.orderList.length}
                                next={fetchMoreData}
                                height={"80vh"}
                                hasMore={state.totalCount <= state.orderList.length ? false : true}
                                loader={<div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div>}
                            >
                                <table className="table theme-table-wrap2 short-table sticky-th-border">
                                    <thead>
                                        <tr>
                                            <th scope="col">Vessel Name</th>
                                            <th scope="col">Customer Name</th>
                                            <th scope="col">Department Name</th>
                                            <th scope="col">Delivery Date</th>
                                            <th scope="col">Delivery Place</th>
                                            <th scope="col">Nominated Supplier</th>
                                            <th scope="col">Order Value</th>
                                            <th scope="col">Status</th>
                                            <th>Detail</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(props.apiCallStatus.isStarted.indexOf("getCart") !== -1 || props.apiCallStatus.isStarted.indexOf("getOrder") !== -1) ?
                                            <td colSpan="20">
                                                <div className="loader-img text-center">
                                                    <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                                </div>
                                            </td>
                                            :
                                            state.orderList && state.orderList.length > 0 ?
                                                state.orderList.map((item, index) => {
                                                    return (
                                                        state.isCartList ? <tr key={index}>
                                                            <td>{item.vesselName}</td>
                                                            <td>{item.customerName}</td>
                                                            <td>{capitalizeFirstLetter(item.type)}</td>
                                                            <td>{moment(item.arrivalTime).format("DD.MM.YYYY")}</td>
                                                            <td>{item.placeOfDelivery.label}</td>
                                                            <td>{item.supplierName}</td>
                                                            <td>{item.totalPrice ? parseFloat(item.totalPrice.toFixed(2)) : '0'}</td>
                                                            <td>{item.orderStatus === 'pending' ?
                                                                <><span className="sta-indicator pend"></span> <span className="sta-text-wrap text-truncate"></span>
                                                                </> : item.orderStatus === 'confirmed'  && !item.isSupplied ? <><span className="sta-indicator appr"></span> <span className="sta-text-wrap text-truncate"></span>
                                                                </> : item.orderStatus === 'confirmed' && item.isSupplied? <><span className="sta-indicator past"></span> <span className="sta-text-wrap text-truncate"></span>
                                                                </> : item.orderStatus}</td>
                                                            <td><Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm mb-2" to={`/order-detail-description/${item._id}/${item.supplierId ? item.supplierId._id : 1234}`}>View Details</Link></td>
                                                        </tr> : <tr key={index}>
                                                                <td>{item._id}</td>
                                                                <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>
                                                                <td>{item.status === 'inprogress' ?
                                                                    <><span className="sta-indicator pend"></span> <span className="sta-text-wrap text-truncate"></span>
                                                                    </> : item.status === 'confirmed' ? <><span className="sta-indicator appr"></span> <span className="sta-text-wrap text-truncate"></span>
                                                                    </> : item.status === 'pastorder' ? <><span className="sta-indicator past"></span> <span className="sta-text-wrap text-truncate"></span>
                                                                    </> : item.status} {capitalizeFirstLetter(item.status)}</td>
                                                                {/* <td>{item.orderRequested}</td> */}
                                                                <td>{item.totalPrice ? parseFloat(item.totalPrice.toFixed(2)) : '0'}</td>
                                                                <td>{item.currency}</td>
                                                                <td>{moment(item.createdAt).format("Do MMM YYYY")}</td>
                                                                <td><Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm mb-2" to={`/order-detail-description/${item._id}/${item.supplierId}`}>View Details</Link></td>
                                                            </tr>
                                                    )
                                                }) : state.orderList && state.orderList.length === 0 ?
                                                    <td colSpan="40" className="text-center"><div>No Data Found</div></td> : ''}
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )

}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    vesselList: state.vesselReducer.list,
    userDetail: {
        userType: state.authReducer.userType,
        subType: state.authReducer.subType,
        userId: state.authReducer.userId,
        isSubAdmin: state.authReducer.isSubAdmin,
    },
    dashboardReducer: state.dashboardReducer,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    cartListing: (offset, filterQuery) => dispatch(cartListing(offset, filterQuery)),
    orderListing: (offset, filterQuery) => dispatch(orderListing(offset, filterQuery)),
    vesselListing: (offset, limit) => dispatch(vesselListing(offset, limit)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecentOrderOverview);
