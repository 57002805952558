import React, { useEffect, useState } from 'react';
import { Wrapper } from '../../Common/Components/CommonWrapper';
import { reminderListing, deleteReminder } from "../ApiCalls/reminder";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import loader from '../../../assets/images/Spinner-1s-200px.gif';
import Swal from 'sweetalert2'
import DashboardNavbar from '../../Common/Components/dashboardNavbar';
import { getCookie } from '../../../Services/cookies';
import { isAllowPermission } from '../../../Services/common';
import { getRoutePathBykey } from "../../../Constants/routesConfig";
function ReminderList(props) {
    const [state, setState] = useState({
        reminderList: [],
        totalCount: 0,
        isHitApi: false
    });
    useEffect(() => {
        let data = { ...state };
        if (!state.isHitApi) {
            if ((props.isSubAdmin && props.userType === 'user' && props.subType !== 'sub-admin') || isAllowPermission(window.location.pathname, props.roles)) {
                props.reminderListing();
                data.isHitApi = true;
                setState(data);
            } else if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            }
        }
        if (props.apiCallStatus.apiCallFor === "getReminders" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                reminderList: props.reminderReducer.list,
                totalCount: props.reminderReducer.count,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "deleteReminder" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
            Swal.fire("Deleted!", "Reminder has been deleted!", "success");
            props.reminderListing(0);
        }

    }, [props, state.isHitApi]);
    const removeRecord = (id) => {
        if (!isAllowPermission('/delete-reminder', props.roles)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "You don't have permission of this feature"
            })
        } else {
            Swal.fire({
                title: "Delete Reminder",
                text: "Are you sure you want to delete this Reminder?",
                icon: "warning",
                buttons: true,
                // showCancelButton: true,
                dangerMode: true,
            })
                .then(result => {
                    if (result.isConfirmed) {
                        props.deleteReminder(id)
                    }
                });
        }
    }
    const getNavbarOptions = () => {
        let options = [
            {
                img: 'left-arrow.svg',
                className: "orange",
                name: "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.history.goBack()
            }
        ]
        if (props.userType === 'user') {
            options.push({
                img: 'Edit-icon.svg',
                className: "dark-green",
                name: "Create<br />Reminder",
                path: "/create-reminder",
                isLogout: false
            })
        }
        return options;
    }

    return (
        <Wrapper>
            <DashboardNavbar title={`Reminders`} navbarOptions={getNavbarOptions()} className="mesh-board-wrap d-flex align-items-end" />
            <div className="table-responsive mt-4">
                <table className="table theme-table-wrap with-action-btns">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Days</th>
                            <th scope="col">Max Reminder</th>

                            {getCookie('userType') === 'user' ? props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editPort'), props.roles) && !isAllowPermission('/delete-port', props.roles) ? "" : <th scope="col">Action</th> : ''}
                        </tr>
                    </thead>
                    <tbody>
                        {(props.apiCallStatus.isStarted.indexOf("getReminders") !== -1) ?
                            <td colSpan="10">
                                <div className="loader-img text-center ">
                                    <img style={{ width: "46px" }} src={loader} alt='' />
                                </div>
                            </td> : state.reminderList && state.reminderList.length === 0 ?
                                <td colSpan="40" className="text-center">No Data Found</td>
                                : state.reminderList && state.reminderList.length > 0 && state.reminderList.map((item, index) => {
                                    let isDisableEdit = props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editReminder'), props.roles) ? true : false;
                                    let isDisableDel = props.isSubAdmin && props.roles.length > 0 && !isAllowPermission('/delete-reminder', props.roles) ? true : false;
                                    return <tr key={index + 1}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.days}</td>
                                        <td>{item.maxReminder}</td>
                                        {isDisableDel && isDisableEdit ? ""
                                            : getCookie('userType') === 'user' ?
                                                <td>
                                                    {isDisableEdit ? "" : <Link to={"/edit-reminder/" + item._id} className="btn btn-edit-tab mr-2"></Link>}
                                                    {isDisableDel ? "" : <button type="button" onClick={() => removeRecord(item._id)} className="btn btn-dlt-tab"></button>}
                                                </td>
                                                : ''}

                                    </tr>

                                })}
                    </tbody>
                </table>
            </div>
        </Wrapper >
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    reminderReducer: state.reminderReducer,
    userType: state.authReducer.userType,
    subType: state.authReducer.subType,
    roles: state.authReducer.roles,
    isSubAdmin: state.authReducer.isSubAdmin,
    userId: state.authReducer.userId
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    reminderListing: () => dispatch(reminderListing()),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    deleteReminder: (portId) => dispatch(deleteReminder(portId)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReminderList);
