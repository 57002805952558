import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import { socket } from '../../SocketConnection/Components/socket';
import { ApiRequestedAction } from "../../ApiCallStatus/Actions/action";
import { readNotificationAction } from "../../SocketConnection/Actions/actions";
import { Wrapper } from "../Css/dashboardNavbar";
import { getCookie } from '../../../Services/cookies';
import { Constant } from "../../../Constants/constant";
import $ from "jquery";
function DashboardNavbar(props) {
    return (
        <Wrapper>
            <div className={props.className}>
                {props.isShowLogo ?
                    props.logoType === "Supplier" ?
                        <div className="dash-head-logo-wrap d-flex align-items-start flex-fill">
                            {getCookie('userType') !== 'user' ?
                                <div className="your-logo-wrap" >
                                    <label htmlFor="file-input">
                                        <img className="img-fluid" src={getCookie('logo') !== '' ? Constant.mediaUrl + getCookie('logo') : require(`../../../assets/images/${props.logoUrl}`)} alt="icon" />
                                    </label>
                                    <input id="file-input" type="file" name="logo" accept="image/jpg, image/jpeg, image/png" onChange={(e) => { props.handleLogoChange(e) }} />
                                </div> : ''
                            }
                            <h1 className={props.title === 'Supplier Dashboard' ? "btn-sty-title flex-fill mr-0" : props.title === 'Customer Dashboard' ? "btn-sty-title flex-fill mr-0" : props.title === 'Admin Panel' ? "btn-sty-title flex-fill mr-0" : "btn-sty-title flex-fill"}>{props.title}
                                <NotificationPanel isSubAdmin={props.isSubAdmin} subType={props.subType} apiCallStatus={props.apiCallStatus} totalCount={props.totalCount} userId={props.userId} data={props.notificationsList} unReadMsgCount={props.unReadMsgCount} ApiRequestedAction={props.ApiRequestedAction} readNotificationAction={props.readNotificationAction} /></h1>
                        </div> : props.logoType === "Order" ?
                            <div className="d-flex flex-fill align-items-center mesh-logoWrap flex-column flex-sm-row ">
                                <div className="logoHolder mr-sm-4 text-sm-left text-center">
                                    <img className="img-fluid" src={require('../../../assets/images/logo.png')} alt="Logo" />
                                </div>
                                <h1 className={props.title === 'Supplier Dashboard' ? "btn-sty-title flex-fill mr-0" : props.title === 'Customer Dashboard' ? "btn-sty-title flex-fill mr-0" : props.title === 'Admin Panel' ? "btn-sty-title flex-fill mr-0" : "btn-sty-title flex-fill my-3 my-lg-0"} >{props.title}
                                    <NotificationPanel isSubAdmin={props.isSubAdmin} subType={props.subType} apiCallStatus={props.apiCallStatus} totalCount={props.totalCount} userId={props.userId} data={props.notificationsList} unReadMsgCount={props.unReadMsgCount} ApiRequestedAction={props.ApiRequestedAction} readNotificationAction={props.readNotificationAction} /></h1>
                            </div> : "" : <h1 className={props.title === 'Supplier Dashboard' ? "btn-sty-title flex-fill mr-0" : props.title === 'Customer Dashboard' ? "btn-sty-title flex-fill mr-0" : props.title === 'Admin Panel' ? "btn-sty-title flex-fill mr-0" : "btn-sty-title flex-fill"}>{props.title}
                        <NotificationPanel isSubAdmin={props.isSubAdmin} subType={props.subType} apiCallStatus={props.apiCallStatus} totalCount={props.totalCount} userId={props.userId} data={props.notificationsList} unReadMsgCount={props.unReadMsgCount} ApiRequestedAction={props.ApiRequestedAction} readNotificationAction={props.readNotificationAction} /></h1>
                }

                <NavbarOptions navbarOptions={props.navbarOptions} />
            </div>
        </Wrapper>
    )
};

function NavbarOptions(props) {
    return <div className="mesh-control-btns d-flex align-items-center">
        {props.navbarOptions.map((item, index) => {
            return item.isCustom ?
                <button type="button" key={index} onClick={(e) => item.onClick(e)} className={"btn btn-polygon-sty " + item.className}>
                    <img className="img-fluid" src={require(`../../../assets/images/${item.img}`)} alt="icon" />
                    <span dangerouslySetInnerHTML={{ __html: item.name }}></span>
                </button> : item.isLogout ?
                    ""
                    : <Link to={item.path} key={index} className={"btn btn-polygon-sty " + item.className}>
                        <img className="img-fluid" src={require(`../../../assets/images/${item.img}`)} alt="icon" />
                        <span dangerouslySetInnerHTML={{ __html: item.name }}></span>
                    </Link>
        })}
    </div>
}

function NotificationPanel(props) {
    useEffect(() => {
        $(document).on('click', '.tab', function (e) {
            e.stopPropagation();
        });
    }, [])
    const getMoreData = () => {
        if (props.data.length < props.totalCount) {
            props.ApiRequestedAction({ apiCallFor: "fetchNewNotification" })
        }
    };
    const scrollFunction = (o) => {
        if (Math.round($(o.target).scrollTop() + $(o.target).innerHeight()) >= $(o.target)[0].scrollHeight) {
            getMoreData();
        }
    }
    return <div className="dropdown bell-notifty " id="myDropdown">
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span className="bell-notifty"><i className="fa fa-bell" aria-hidden="true"></i><h5>{props.unReadMsgCount && props.unReadMsgCount !== 0 ?
                    <span className="contact-note"><h5>{props.unReadMsgCount}</h5></span> : ""}</h5></span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <div className="notification-heading "><h4 className="btn-sty-title mr-0 text-left">Notifications</h4>
                </div>
                <div className="tabs ">
                    <div className="notifications-wrapper" onScroll={(e) => scrollFunction(e)}>
                        {props.data && props.data.length !== 0 ? props.data.map((item, index) => {
                            return <NotificationItem isSubAdmin={props.isSubAdmin} subType={props.subType} key={index} index={index} userId={props.userId} {...item} className=".welcome-inn-content p" readNotificationAction={props.readNotificationAction} />
                        }) : <div className="text-center">No notification found</div>}
                    </div>
                </div>
                {props.apiCallStatus.isStarted.indexOf('appendNotificationList') !== -1 ?
                    <div className="loader-img text-center">
                        <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                    </div> : ""}
                <li className="divider"></li>
            </Dropdown.Menu>
        </Dropdown>
    </div >
    // return <div className="dropdown bell-notifty " id="myDropdown">
    //     <a id="dLabel" role="button" data-toggle="dropdown" data-target="#" href="/page.html">
    //         <span className="bell-notifty">
    //             <i className="fa fa-bell" aria-hidden="true"></i>
    //             {props.unReadMsgCount && props.unReadMsgCount !== 0 ?
    //                 <span className="contact-note"><h5>{props.unReadMsgCount}</h5></span> : ""}
    //         </span>
    //     </a>
    //     <ul className="dropdown-menu notifications" role="menu" aria-labelledby="dLabel" >
    //         <div className="notification-heading"><h4 className="btn-sty-title mr-0 text-left">Notifications</h4>
    //         </div>
    //         <div className="tabs">
    //             <div className="notifications-wrapper" onScroll={(e) => scrollFunction(e)}>
    //                 {props.data && props.data.length !== 0 ? props.data.map((item, index) => {
    //                     return <NotificationItem isSubAdmin={props.isSubAdmin} subType={props.subType} key={index} index={index} userId={props.userId} {...item} className=".welcome-inn-content p" readNotificationAction={props.readNotificationAction} />
    //                 }) : <div className="text-center">No notification found</div>}
    //             </div>
    //         </div>
    //         {props.apiCallStatus.isStarted.indexOf('appendNotificationList') !== -1 ?
    //             <div className="loader-img text-center">
    //                 <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
    //             </div> : ""}
    //         <li className="divider"></li>
    //     </ul>
    // </div>
}
const NotificationItem = React.memo(props => {
    const [count, setCount] = useState(0)
    const readNotification = (e) => {
        if (count === 0) {
            setCount(1);
            if (!props.isRead && socket) {
                console.log("emiting msg")
                socket.emit("readMessage", props._id, props.userId);
                props.readNotificationAction(props._id)
            }
        } else {
            setCount(0);
        }
    }
    return <div className="tab" id="tab" onClick={() => readNotification()} >
        <div className="">
            <input type="checkbox" id={"check" + props.index} name="" />
            <label className={!props.isRead ? "tab-label unread" : "tab-label"} htmlFor={"check" + props.index}>
                {props.title}</label>
            <div className="tab-content">
                {props.isSubAdmin && props.subType !== "captain" ?
                    <p>{props.message}</p>
                    :
                    <a href={props.url} target="_blank" rel="noopener noreferrer" >{props.message}</a>}
            </div>
            {/* <a href={props.url} target="_blank" rel="noopener noreferrer" className="tab-content">{props.message}</a> */}
        </div>
    </div>
})


const mapStateToProps = (state, ownProps) => ({
    notificationsList: state.socketReducer.notificationsList,
    unReadMsgCount: state.socketReducer.unReadMsgCount,
    totalCount: state.socketReducer.totalCount,
    userId: state.authReducer.userId,
    isSubAdmin: state.authReducer.isSubAdmin,
    subType: state.authReducer.subType,
    apiCallStatus: state.apicallStatusReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ApiRequestedAction: (data) => dispatch(ApiRequestedAction(data)),
    readNotificationAction: (notificationId) => dispatch(readNotificationAction(notificationId))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardNavbar)
// export { DashboardNavbar };