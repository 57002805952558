import React, { useEffect, useState  } from 'react';
import {Wrapper, DashboardNavbar } from '../../Common/Components/index';
import '../css/roles.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Allquotes(props) {
        const [startDate, setStartDate] = useState(new Date());
        const [endDate, setEndDate] = useState(null);
        const navbarOptions = [
            {
                img: 'left-arrow.svg',
                className: "orange",
                name: "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.history.goBack()
            },
            // {
            //     img: 'Edit-icon.svg',
            //     className: "dark-green",
            //     name: "Add<br/>Roles",
            //     path: "/",
            //     isLogout: false,
            //     isCustom: true,
            //     onClick: () => props.history.goBack()
            // }
        ];
        const onChange = dates => {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
          };

    return (
<Wrapper>
                <div className="">
                <DashboardNavbar title="All Quotes" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
                {/* filters */}
                <div className="ordCatg-table-wrap mt-4 system-cat-filter role-content-wrap">
                    <div className="card-bx-sty">
                        <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                            <div className="fil-wrap-ctn d-flex align-items-center flex-column flex-sm-row mb-2 mb-lg-0">
                                    {/* Date Range Picker */}
                                    <div className="date-range-picker d-flex align-items-center">
                                        <span className="d-inline-block ml-0">
                                            <DatePicker
                                                selected={startDate}
                                                onChange={date => setStartDate(date)}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                className="link-style"
                                            />
                                        </span>
                                        to
                                        <span className="d-inline-block mr-0">
                                            <DatePicker
                                                selected={endDate}
                                                onChange={date => setEndDate(date)}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                className="link-style"
                                            />
                                        </span>
                                    </div>
                                <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0 filter-searchbar">
                                    <div className="form-group">
                                        <input type="search" className="form-control" placeholder="search...."/>
                                    </div>
                                </div>
                            </div>
                            <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                                <button type="button" className="btn btn-yellow">Search</button>
                                <button type="button" className="btn btn-yellow ml-1">Clear</button>
                                <div className="d-flex flex-fill justify-content-end ml-1">
                                    <button type="button" className="btn btn-yellow bg-dark-green">Add Quotes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Roles Listing */}
                <div className="table-responsive mt-4">
                    <table className="table theme-table-wrap with-action-btns">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Organization</th>
                                <th scope="col">Location</th>
                                <th scope="col">Website</th>
                                <th scope="col">Email</th>
                                <th scope="col">Created At </th>
                                <th scope="col">Action </th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr>
                                <td>1</td>
                                <td>Admin kristin</td>
                                <td>Arhamsoft</td>
                                <td>Pakistan</td>
                                <td>https://arhamsoft.com</td>
                                <td>steffen.Shaheer@admin.com</td>
                                <td>Sep 29th 2020 06:06 pm</td>
                                <td>
                                    <button type="button" className="btn btn-edit-tab"></button>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Admin kristin</td>
                                <td>Arhamsoft</td>
                                <td>Pakistan</td>
                                <td>https://arhamsoft.com</td>
                                <td>steffen.Shaheer@admin.com</td>
                                <td>Sep 29th 2020 06:06 pm</td>
                                <td>
                                    <button type="button" className="btn btn-edit-tab"></button>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Admin kristin</td>
                                <td>Arhamsoft</td>
                                <td>Pakistan</td>
                                <td>https://arhamsoft.com</td>
                                <td>steffen.Shaheer@admin.com</td>
                                <td>Sep 29th 2020 06:06 pm</td>
                                <td>
                                    <button type="button" className="btn btn-edit-tab"></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
            </Wrapper>
    )
}
