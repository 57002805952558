export const getReminders = "GET_REMINDERS";
export const getReminderDetail = "GET_REMINDER_DETAIL";
export const AppendReminderList = "APPEND_REMINDER_LIST";

export function GetRemindersAction(list, count) {
  return {
    type: getReminders,
    payload: { list, count },
  };
}
export function AppendReminderAction(list) {
  return {
    type: AppendReminderList,
    payload: { list },
  };
}
export function GetReminderDetailAction(detail) {
  return {
    type: getReminderDetail,
    payload: { detail },
  };
}
