import React, { useEffect, useState } from 'react';
import { VesselWrapper } from './vesselWrapper';
import { createVessel } from "./../ApiCalls/vessel";
import { ApiClearAction, ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { supplierListing } from "../../Supplier/ApiCalls/supplier";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { getAllCaptain } from "../../Common/ApiCalls/captainList";
import { getAllCook } from "../../Common/ApiCalls/cookListing";
import { getCurrencyCode } from '../../Common/ApiCalls/countryList';
import { getCookie, setCookie } from '../../../Services/cookies';
import Select from 'react-select';
import Swal from 'sweetalert2';
import CustomSelect from '../../Common/Components/CustomSelect';
import { isAllowPermission } from '../../../Services/common';
var Config = require('./../../Orders/Components/Catalogue/config.json');

const formInitialState = {
    currencyOption: [],
    selectedOption: null,
    name: "",
    imo: "",
    rate: "",
    currency: "",
    pob: "",
    pic: "",
    owner: "",
    activeCustomerPage: 0,
    selectedCook: [],
    selectedCaptain: [],
    captainList: [],
    cookList: [],
    user: { label: "", value: "" },
    customerList: [],
    searchCustomer: '',
    isHitApi: false
}
function CreateVessel(props) {
    const [state, setState] = useState(formInitialState);
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.getCurrencyCode();
                props.getAllCaptain(0, 0, 'captain');
                props.getAllCook(0, 0, 'cook');
                props.customerListing(state.activeCustomerPage, 'customer', '');
                let data = { ...state };
                data.isHitApi = true;
                setState(data);
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getAllCaptain") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let arr = props.captainList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].name });
            }
            setState({
                ...state,
                captainList: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getAllCook") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let arr = props.cookList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].name });
            }
            setState({
                ...state,
                cookList: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getCurrencyCode") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let topCurrency = [];
            let finalArr = [];
            let arr = props.commonReducer.currencyCodeList;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === "EUR" || arr[i] === "USD" || arr[i] === "CAD") {
                    topCurrency.push({ value: arr[i], label: arr[i] })
                } else {
                    newArr.push({ value: arr[i], label: arr[i] });

                }
            }
            finalArr = topCurrency.concat(newArr)
            setState({
                ...state,
                currencyOption: finalArr,
                isHitApi: true,
            })
            props.ClearApiByNameAction("getCurrencyCode");
        }
        if ((props.apiCallStatus.apiCallFor === "createVessel") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(formInitialState)
            props.ApiClearAction();
            let vesselCount = parseInt(getCookie('vesselCount')) + 1;
            setCookie('vesselCount', vesselCount)
            Swal.fire("Success", `Vessel has been created successfully`, "success")
            props.history.push('/vessel-listing')
        }
        if ((props.apiCallStatus.apiCallFor === "supplierListing" || props.apiCallStatus.apiCallFor === "appendSupplierList") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let arr = props.customerList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i].id, label: arr[i].email });
            }
            setState({
                ...state,
                customerList: newArr,
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
    });
    const handleFormChange = (e, obj) => {
        if (obj || obj === "") {
            let data = { ...state };
            data["activeCustomerPage"] = 0;
            if (typeof obj === "string") {
                data[e] = obj;
                let query = "";
                if (obj !== "") {
                    query = "email=" + obj
                }
                props.customerListing(data.activeCustomerPage, 'customer', query);
            } else {
                data["user"] = obj;
            }
            setState(data);
        } else if (e.target.type === 'file') {
            if (e.target.files.length > 0) {
                let name = e.target.name;
                let formDetail = { ...state }
                formDetail[name] = e.target.files;
                setState(formDetail)
            }
        } else {
            let name = e.target.name;
            let value = e.target.value;
            let formDetail = { ...state }
            formDetail[name] = value;
            setState(formDetail)
        }
    }
    const handleSelectChange = (e, id) => {
        let formDetail = { ...state }
        formDetail[id] = e;
        setState(
            formDetail
        )
    }
    const createVessel = () => {
        if (formValidation()) {
            let vesselCount = getCookie('vesselCount');
            let obj = {
                userId: props.userType === "user" ? state.user.value : props.userId,
                name: state.name,
                imo: state.imo,
                rate: state.rate,
                currency: state.currency.label,
                pob: state.pob,
                pic: state.pic,
                owner: state.owner,
                vesselCount: parseInt(vesselCount),
                cook: state.selectedCook,
                captain: state.selectedCaptain
            }
            props.createVessel(obj)
        }
    }
    const formValidation = () => {
        let isValid = true;
        let msg = "";
        if (props.userType === 'user') {
            if (state.user.value === "") {
                isValid = false;
                msg = "Please assign customer to this vessel";
            } else if (state.cookList.length <= 0) {
                isValid = false;
                msg = "Please assign cook to this vessel";
            } else if (state.captainList.length <= 0) {
                isValid = false;
                msg = "Please assign captain to this vessel";
            }
        }
        if (!isValid) {
            Swal.fire("Error", msg, "error")
        }
        return isValid;
    }
    const fetchMoreCustomerData = () => {
        let data = { ...state };
        data.activeCustomerPage += 1;
        setState(data);
        let query = "";
        if (state.searchCustomer !== "") {
            query = "?email=" + state.searchCustomer;
        }
        props.customerListing(state.activeCustomerPage, 'customer', query);
    };
    return (
        <VesselWrapper>
            <div className="mesh-board-wrap d-flex align-items-end">
                <h1 className="btn-sty-title flex-fill">Configuration</h1>
                <div className="mesh-control-btns d-flex align-items-center">
                    <Link to="/vessel-listing" className="btn btn-polygon-sty orange">
                        <img className="img-fluid" src={require('../../../assets/images/left-arrow.svg')} alt="icon" />
                        <span>Go back</span>
                    </Link>
                </div>
            </div>
            <div className="formBox mt-4">
                <div className="conditional-content-wrap">
                    {/* <ul className="nav nav-tabs mb-5" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="vessel-form-tab" data-toggle="tab" href="#vessel-form" role="tab" aria-controls="vessel-form" aria-selected="true">Form</a>
                        </li>
                      
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="config-tab" data-toggle="tab" href="#config" role="tab" aria-controls="config" aria-selected="false">Configuration</a>
                        </li>
                    </ul> */}
                    <div>
                        {/* <VesselForm {...state} userType={props.userType} history={props.history} handleFormChange={handleFormChange} handleSelectChange={handleSelectChange} createVessel={createVessel} apiCallStatus={props.apiCallStatus} fetchMoreCustomerData={fetchMoreCustomerData} customerList={state.customerList} customerCount={props.customerCount} captainList={state.captainList} cookList={state.cookList} /> */}
                        <VesselConfiguration />
                    </div>
                </div>
            </div>
        </VesselWrapper>
    )
}

function VesselForm(props) {
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const extractFileName = (data) => {
        let val = "";
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            val += (element.filename ? element.filename : element.name ? element.name : "");
            if (data.length !== 1 && index !== data.length - 1) {
                val += ", "
            }
        }
        return val;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            props.createVessel();
        }
    }
    return (
        <div className="tab-pane fade show active" id="vessel-form" role="tabpanel" aria-labelledby="vessel-form-tab drop-vessels-form">
            <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group floating-label-wrap">
                            <input type="number" name="imo" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="IMO"
                                value={props.imo} required />
                            <label className="foating-labels-sty">IMO</label>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group floating-label-wrap">
                            <input type="text" name="name" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Name"
                                value={props.name} required
                            />
                            <label className="foating-labels-sty">Name</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group floating-label-wrap">
                            <input type="number" name="rate" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Rate"
                                value={props.rate} required
                            />
                            <label className="foating-labels-sty">Rate</label>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group d-flex align-items-center select-z-Index">
                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                <Select
                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Currency"
                                    value={props.currency === "" ? null : props.currency}
                                    onChange={(e) => { props.handleSelectChange(e, 'currency') }}
                                    options={props.currencyOption}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group floating-label-wrap">
                            <input type="number" name="pob" min={0} className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="POB Regular"
                                value={props.pob} required
                            />
                            <label className="foating-labels-sty">POB Regular</label>
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="form-input-container">
                            <input type="file" id="pic" name="pic" className="form-control" accept="image/jpg, image/jpeg, image/png" onChange={(e) => { props.handleFormChange(e) }} placeholder="PIC" required />
                            <label htmlFor="pic" className="d-flex align-items-center"><span className="text-truncate">{props.pic.length > 0 ? extractFileName(props.pic) : "Pic"}</span></label>
                        </div>
                    </div>
                </div>
                {props.userType === "user" ?
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group d-flex align-items-center select-z-Index">
                                <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                    <Select
                                        className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Captain"
                                        value={props.selectedCaptain}
                                        onChange={(e) => { props.handleSelectChange(e, 'selectedCaptain') }}
                                        options={props.captainList}
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group d-flex align-items-center select-z-Index">
                                <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                    <Select
                                        className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Cook"
                                        value={props.selectedCook}
                                        onChange={(e) => { props.handleSelectChange(e, 'selectedCook') }}
                                        options={props.cookList}
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> : ""}
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group floating-label-wrap">
                            <input type="text" name="owner" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Owner"
                                value={props.owner} required
                            />
                            <label className="foating-labels-sty">Owner</label>
                        </div>
                    </div>
                    {props.userType === "user" ?
                        <div className="col-sm-6">
                            <div className="form-group d-flex align-items-center">
                                <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100 btn-p-0">
                                    <CustomSelect pagesList={props.customerList} id="customer" isReturnObj={true} fetchMore={props.fetchMoreCustomerData} pageListTotal={props.customerCount} value={props.user.label} inputValue={props.searchPort} inputId="searchCustomer" placeholder="Select Customer" handleChange={props.handleFormChange} clickCustomeSelectHandler={props.handleFormChange} />
                                </div>
                            </div>
                        </div> : ""}
                </div>
                {props.apiCallStatus.apiCallFor === "createVessel" && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                    <div className="alert alert-danger">
                        "props.apiCallStatus.message"
                    </div>
                    : ""}
                {/* Upload File */}
                <div className="d-flex align-items-center flex-column flex-sm-row">
                    <div className="form-btn d-flex justify-content-end flex-fill">
                        {props.apiCallStatus.apiCallFor === "createVessel" && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                            <div className="loader-img text-center">
                                <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                            </div>
                            : ""}
                        <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">{props.isEdit ? "Update" : "Submit"}</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

function VesselConfiguration(props) {
    return <div >
        <div className="vess-config-wrap">
            <h5 className="form-title semi-bold sky-blue-clr">Ration Configuration</h5>
            <div className="vessel-rate-wrap mt-4">
                <h6>Rate</h6>
                <div className="table-responsive">
                    <table className="table vess-rate-lis mt-3">
                        <tbody>
                            <tr>
                                <td>Contracted Rate</td>
                                <td>7.50 USD</td>
                            </tr>
                            <tr>
                                <td>Proportions</td>
                                <td>7.50 USD</td>
                                <td>67.93%</td>
                            </tr>
                            <tr>
                                <td>Fresh</td>
                                <td>7.50 USD</td>
                                <td>67.93%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Ration Table */}
            <div className="vessel-Ration-wrap mt-4">
                <h6>Ration</h6>
                <div className="table-responsive mt-3">
                    <table className="table theme-table-wrap">
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Qty Person per day</th>
                                <th>Proportion of total Qty</th>
                                <th>Reference Qty</th>
                                <th>Unit</th>
                                <th>Difference</th>
                                <th>Difference %</th>
                                <th>Avg price per KG</th>
                                <th>Avg Value per category</th>
                                <th>Proportion of Total Value</th>
                                <th>Proportional Value of contracted rate by qty</th>
                                <th>Price per KG optimum</th>
                                <th>Number of items in optimum range</th>
                                <th>Proportional Value of Rate by value per category</th>
                                <th>Price per KG optimum per item category</th>
                                <th>Number of items in optimum range per category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Config && Config.category && Config.category.length > 0 ?
                                    Config.category.map((item, index) => {
                                        return <tr>
                                            <td style={{ textAlign: 'left' }}>{item.category}</td>
                                            <td>{item.qtyPerDay}</td>
                                            <td>{item['Proportion-of-total-Qty']}</td>
                                            <td>{item['Reference-Qty']}</td>
                                            <td>{item.Unit}</td>
                                            <td>{item.Difference}</td>
                                            <td>{item[`Difference%`]}</td>
                                            <td>{item['Avg-price-per-KG']}</td>
                                            <td>{item['Avg-Value-per-category']}</td>
                                            <td>{item['Proportion-of-Total-Value']}</td>
                                            <td>{item['Proportional-Value-of-contracted-rate-by-qty']}</td>
                                            <td>{item['Price-per-KG-optimum']}</td>
                                            <td>{item['Number-of-items-in-optimum-range']}</td>
                                            <td>{item['Proportional-Value-of-Rate-by-value-per-category']}</td>
                                            <td>{item['Price-per-KG-optimum-per-item-category']}</td>
                                            <td>{item['Number-of-items-in-optimum-range-per-category']}</td>

                                        </tr>
                                    })
                                    : ''
                            }

                        </tbody>
                    </table>
                </div>
            </div>
            {/* Administration Wrap */}
            <div className="vessel-Admin-wrap mt-5">
                <h6>Administration</h6>
                <div className="ves-admin-lis mt-4">
                    <form action="">
                        <div class="form-group">
                            <label htmlFor="exampleFormControlSelect1">Rate limit fixed</label>
                            <select class="form-control" id="exampleFormControlSelect1">
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="exampleFormControlSelect1">Rate tolerance</label>
                            <select class="form-control" id="exampleFormControlSelect1">
                                <option>1%</option>
                                <option>2%</option>
                                <option>3%</option>
                                <option>4%</option>
                                <option>5%</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="exampleFormControlSelect1">Maximum Quantity limit fixed</label>
                            <select class="form-control" id="exampleFormControlSelect1">
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="exampleFormControlSelect1">Maximum quantity tolerance</label>
                            <select class="form-control" id="exampleFormControlSelect1">
                                <option>1%</option>
                                <option>2%</option>
                                <option>3%</option>
                                <option>4%</option>
                                <option>5%</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="exampleFormControlSelect1">Category limit fixed</label>
                            <select class="form-control" id="exampleFormControlSelect1">
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="exampleFormControlSelect1">Maximum category tolerance</label>
                            <select class="form-control" id="exampleFormControlSelect1">
                                <option>1%</option>
                                <option>2%</option>
                                <option>3%</option>
                                <option>4%</option>
                                <option>5%</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="exampleFormControlSelect1">Minimum fixed</label>
                            <select class="form-control" id="exampleFormControlSelect1">
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="exampleFormControlSelect1">Sufficient provisions minimum</label>
                            <select class="form-control" id="exampleFormControlSelect1">
                                <option>80%</option>
                                <option>20%</option>
                                <option>30%</option>
                                <option>40%</option>
                                <option>50%</option>
                                
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="exampleFormControlSelect1">Allow durable provisions order in fresh top up</label>
                            <select class="form-control" id="exampleFormControlSelect1">
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label htmlFor="exampleFormControlSelect1">Fixed main supply ports</label>
                            <select class="form-control" id="exampleFormControlSelect1">
                                <option>Yes</option>
                                <option>No</option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    vesselReducer: state.vesselReducer,
    commonReducer: state.commonReducer,
    userId: state.authReducer.userId,
    userType: state.authReducer.userType,
    roles: state.authReducer.roles,
    customerList: state.supplierReducer.list,
    customerCount: state.supplierReducer.count,
    captainList: state.commonReducer.captainList,
    cookList: state.commonReducer.cookList,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createVessel: (data) => dispatch(createVessel(data)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    getCurrencyCode: () => dispatch(getCurrencyCode()),
    getAllCaptain: (offset, limit, type) => dispatch(getAllCaptain(offset, limit, type)),
    getAllCook: (offset, limit, type) => dispatch(getAllCook(offset, limit, type)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    customerListing: (offset, type, filterQuery) => dispatch(supplierListing(offset, type, filterQuery))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateVessel);
