import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { FETCH } from "../../../Services/fetch";
import {
    GetRoleAction,
    AppendRoleAction,
    GetRoleDetailAction,
} from "../Actions/action";
import { getRoutesDetail } from '../../../Constants/routesConfig';

export function addRole(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "addRole" }));
        let myJson = await FETCH("POST", Constant.apiURl + "/role", data);
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "addRole",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "addRole",
                    message: myJson.message,
                })
            );
        }
    };
}
export function roleListing(offset, query) {
    return async (dispatch) => {
        if (offset !== 0) {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendRoleList" }));
        } else {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "getRole" }));
            dispatch(GetRoleAction([], 0));
        }
        let url = Constant.apiURl + "/role/" + offset + "/20";
        if (query && query !== "") {
            url = url + query;
        }
        let myJson = await FETCH("GET", url);
        if (myJson && myJson.code === 201) {
            if (offset !== 0) {
                dispatch(AppendRoleAction(myJson.data));
                dispatch(
                    Actions.ApiFulfilledAction({
                        apiCallFor: "appendRoleList",
                        message: "",
                    })
                );
            } else {
                dispatch(GetRoleAction(myJson.data, myJson.count));
                dispatch(
                    Actions.ApiFulfilledAction({
                        apiCallFor: "getRole",
                        message: myJson.message,
                    })
                );
            }
        } else {
            if (offset !== 0) {
                dispatch(
                    Actions.ApiRejectedAction({
                        statusCode: myJson.statusCode,
                        apiCallFor: "appendRoleList",
                        message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
                    })
                );
            } else {
                dispatch(
                    Actions.ApiRejectedAction({
                        statusCode: myJson.statusCode,
                        apiCallFor: "getRole",
                        message: myJson.message,
                    })
                );
            }
        }
    };
}
export function deleteRole(roleId) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteRole" }));
        let myJson = await FETCH("DELETE", Constant.apiURl + "/role", {
            roleId,
        });
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "deleteRole",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "deleteRole",
                    message: myJson.message,
                })
            );
        }
    };
}

export function getRoleDetail(id) {
    var allRoutes = getRoutesDetail();
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getRoleDetail" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/role/" + id);
        if (myJson && myJson.code === 201) {
            let newArry = [];
            myJson.data.roles.map((item, index) => {
                let obj = {
                    key: item.key,
                    path: allRoutes[item.key] !== undefined ? allRoutes[item.key] : (item.key === 'sendRfq' || item.key === 'sendOrder') ? item.path : item.key
                }
                newArry.push(obj)
            })
            dispatch(
                GetRoleDetailAction({
                    roleName: myJson.data.roleName,
                    permission: myJson.data.permission,
                    roles: newArry,
                    permissionAll: myJson.data.permissionAll,
                    roleId: id,
                    isHitApi: true
                })
            );
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getRoleDetail",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getRoleDetail",
                    message: myJson.message,
                })
            );
        }
    };
}
export function updateRole(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateRole" }));
        let myJson = await FETCH("PUT", Constant.apiURl + "/role", data);
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "updateRole",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "updateRole",
                    message: myJson.message,
                })
            );
        }
    };
}