import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import {
  getRfqListAction,
  SupplierSelectionListAction,
  getCustomerOrderDetailAction,
  getDocumentsListAction,
  udpateDoucmentStatusAction,
  appendDocumentAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
import { getCookie } from "../../../Services/cookies";

export function getSupplierSelectionList(orderId) {
  return async (dispatch) => {
    let url = Constant.apiURl + "/users/suppliere/selection";
    dispatch(
      Actions.ApiRequestedAction({ apiCallFor: "getSupplierSelectionList" })
    );
    let myJson = await FETCH("POST", url, { orderId });
    if (myJson && myJson.code === 201) {
      dispatch(SupplierSelectionListAction(myJson.data, myJson.port));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getSupplierSelectionList",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getSupplierSelectionList",
          message: myJson.message,
        })
      );
    }
  };
}

export function getRfqList(orderId, supplierId, type) {
  return async (dispatch) => {
    let url = Constant.apiURl + "/users/listing/" + type;
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getRfqList" }));
    let myJson = await FETCH("POST", url, { orderId, supplierId });
    if (myJson && myJson.code === 201) {
      dispatch(getRfqListAction(myJson.data, myJson.orderStatus,myJson.orderDetail));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getRfqList",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getRfqList",
          message: myJson.message,
        })
      );
    }
  };
}

export function sendQuotation(
  orderId,
  supplierId,
  itemCodeList,
  orderType,
  totalPrice
) {
  return async (dispatch) => {
    let url = Constant.apiURl + "/quotation";
    let userId = getCookie("userId");
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "sendQuotation" }));
    let myJson = await FETCH("POST", url, {
      orderId,
      supplierId,
      itemCodeList,
      totalPrice,
      orderType,
      userId,
      email: getCookie("email"),
    });
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "sendQuotation",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "sendQuotation",
          message: myJson.message,
        })
      );
    }
  };
}

export function getCustomerOrderDetail(orderId, userId) {
  return async (dispatch) => {
    let url = Constant.apiURl + "/users/customer/order-detail";
    dispatch(
      Actions.ApiRequestedAction({ apiCallFor: "getCustomerOrderDetail" })
    );
    let myJson = await FETCH("POST", url, { orderId, userId });
    if (myJson && myJson.code === 201) {
      let data = { ...myJson.vessel };
      data.email = myJson.customer.email;
      data.customerName = myJson.customer.name;
      dispatch(getCustomerOrderDetailAction(data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getCustomerOrderDetail",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getCustomerOrderDetail",
          message: myJson.message,
        })
      );
    }
  };
}
export function approveDoucment(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "approveDoucment" }));
    let myJson = await FETCH(
      "POST",
      Constant.apiURl + "/orders/approve/document",
      data
    );
    if (myJson && myJson.code === 201) {
      dispatch(udpateDoucmentStatusAction(data.isApproved));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "approveDoucment",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "approveDoucment",
          message: myJson.message,
        })
      );
    }
  };
}
export function getDocumentsList(userId) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getDocumentsList" }));
    let myJson = await FETCH(
      "GET",
      Constant.apiURl + "/orders/documents/" + userId
    );
    if (myJson && myJson.code === 201) {
      dispatch(getDocumentsListAction(myJson.data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getDocumentsList",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getDocumentsList",
          message: myJson.message,
        })
      );
    }
  };
}
export function uploadDocuments(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "uploadDocuments" }));
    var formData = new FormData();
    formData.append("orderId", data.orderId);
    formData.append("data", JSON.stringify(data.data));
    if (data.invoice) {
      formData.append("invoice", data.invoice);
    }
    if (data.deliveryNote) {
      formData.append("deliveryNote", data.deliveryNote);
    }
    let myJson = await FETCH(
      "POST",
      Constant.apiURl + "/orders/upload/document",
      formData,
      true
    );
    if (myJson && myJson.code === 201) {
      dispatch(getDocumentsListAction(myJson.data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "uploadDocuments",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "uploadDocuments",
          message: myJson.message,
        })
      );
    }
  };
}
