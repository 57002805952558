import React, { useEffect, useState } from 'react';
import './orderCatalogue.css';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { getDashboardLink } from '../../Services/common';
import { connect } from 'react-redux';

const options = [
	{ value: 'Provisions', label: 'Provisions' },
	{ value: 'Deck', label: 'Deck' },
	{ value: 'Engine', label: 'Engine' },
	{ value: 'Galley / ', label: 'Galley / ' },
	{ value: 'laundry', label: 'laundry' },
	{ value: 'Electrical', label: 'Electrical' },
	{ value: 'Stationary', label: 'Stationary' },
];

function SupplierOverviewCatalogue(props) {
	const [state, setState] = useState({
		supplierList: [],
		activePage: 0,
		totalCount: 0,
		selectedOption: '',
		isHitApi: false
	});
	const handleChange = selectedOption => {
		this.setState({ selectedOption });
		console.log(`Option selected:`, selectedOption);
	};

	return (
		<div className="pageWrapper">
			<div className="pageContainer mb-0">
				<div className="order-dlts-wrapper">
					<div className="pageHeader d-flex align-items-center flex-column flex-lg-row">
						<div className="d-flex flex-fill align-items-center mesh-logoWrap flex-column flex-sm-row ">
							<div className="logoHolder mr-sm-4 text-sm-left text-center">
								<img className="img-fluid" src={require('../../assets/images/logo.png')} alt="Logo" />
							</div>
							<h1 className="btn-sty-title flex-fill my-3 my-lg-0">ABC Ship Supplier - Order overview</h1>
						</div>
						<div className="mesh-control-btns d-flex align-items-center">
							<Link to={getDashboardLink()}><button type="button" className="btn btn-polygon-sty dark-pink">
								<img className="img-fluid" src={require('../../assets/images/left-arrow.svg')} alt="icon" />
								<span>Go back</span>
							</button></Link>
							<Link to="/dashboard" className="btn btn-polygon-sty dark-blue">
								<img className="img-fluid" src={require('../../assets/images/Monitor-icon.svg')} alt="icon" />
								<span>Send<br />RFQ</span>
							</Link>
							<Link to="/checkout/123" className="btn btn-polygon-sty green">
								<img className="img-fluid" src={require('../../assets/images/Message-icon.svg')} alt="icon" />
								<span>Send<br />order</span>
							</Link>
						</div>
					</div>
				</div>
				{/* Order Overview */}
				<div className="ordCatg-wrapper supp-Oview-wrapper">
					<div className="card-bx-sty p-0">
						<div className="box-items-wrap">
							<div className="d-flex align-items-center flex-nowrap overflow-auto box-item-inn-wrap flex-fill mr-3 pb-1">
								<div className="card-bx-sty box-item-inn">
									<h1 className="text-nowrap">Order items:</h1>
									<p className="text-nowrap">500</p>
								</div>
								<div className="card-bx-sty box-item-inn">
									<h1 className="text-nowrap">Prices available:</h1>
									<p className="text-nowrap">450</p>
								</div>
								<div className="card-bx-sty box-item-inn">
									<h1 className="text-nowrap">To be requested:</h1>
									<p className="text-nowrap">50</p>
								</div>
								<div className="card-bx-sty box-item-inn">
									<h1 className="text-nowrap dark-green-clr fz-28">90 %</h1>
								</div>
								<div className="card-bx-sty box-item-inn">
									<h1 className="text-nowrap">Current amount:</h1>
									<p className="text-nowrap">3.600,00 EUR</p>
								</div>
							</div>
						</div>
						{/* Provision Catalogue Table */}
						<div className="ordCatg-table-wrap supp-Oview-wrap">
							{/* Table */}
							<div className="ordCatg-InnTable p-3 mt-0">
								<ul className="sup-Oview-list d-flex align-items-center flex-nowrap overflow-auto mb-0">
									<li>SHOW:</li>
									<li>ALL <span className="btn btn-sm-rounded bg-dark-green white-clr">90%</span></li>
									<li>Deck <span className="btn btn-sm-rounded bg-dark-yellow white-clr">89%</span></li>
									<li>Engine <span className="btn btn-sm-rounded bg-green white-clr">96%</span></li>
									<li>Galley <span className="btn btn-sm-rounded bg-green white-clr">100%</span></li>
									<li>Office <span className="btn btn-sm-rounded bg-green white-clr">100%</span></li>
									<li>Safety <span className="btn btn-sm-rounded bg-olive white-clr">94%</span></li>
									<li>Electrical <span className="btn btn-sm-rounded bg-light-orng white-clr fz-16 semi-bold">84%</span></li>
								</ul>
								<div className="table-outWrap">
									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th>Dept.</th>
													<th>IMPA</th>
													<th>Description</th>
													<th>Remarks</th>
													<th>Quantity</th>
													<th>Unit</th>
													<th>Price</th>
													<th>Amount</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td><span className="td-wrap-sty">DECK</span></td>
													<td><span className="td-wrap-sty">174321</span></td>
													<td><span className="td-wrap-sty text-left">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="whi-round">12</span></td>
													<td><span className="td-wrap-sty">PCS</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td>
														<div className="form-group cst-checkBx-sty">
															<input type="checkbox" id="html" checked />
															<label htmlFor="html"></label>
														</div>
													</td>
													<td><span className="whi-round text-nowrap">Add to RFQ</span></td>
													<td><span className="whi-round text-nowrap">Add to direct order</span></td>
												</tr>
												<tr>
													<td><span className="td-wrap-sty">DECK</span></td>
													<td><span className="td-wrap-sty">174321</span></td>
													<td><span className="td-wrap-sty text-left">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="whi-round">12</span></td>
													<td><span className="td-wrap-sty">PCS</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td>
														<div className="form-group cst-checkBx-sty">
															<input type="checkbox" id="html2" checked />
															<label htmlFor="html2"></label>
														</div>
													</td>
													<td><span className="whi-round text-nowrap">Add to RFQ</span></td>
													<td><span className="whi-round text-nowrap">Add to direct order</span></td>
												</tr>
												<tr>
													<td><span className="td-wrap-sty">DECK</span></td>
													<td><span className="td-wrap-sty">174321</span></td>
													<td><span className="td-wrap-sty text-left">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="whi-round">12</span></td>
													<td><span className="td-wrap-sty">PCS</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td>
														<div className="form-group cst-checkBx-sty">
															<input type="checkbox" id="html3" checked />
															<label htmlFor="html3"></label>
														</div>
													</td>
													<td><span className="whi-round text-nowrap">Add to RFQ</span></td>
													<td><span className="whi-round text-nowrap">Add to direct order</span></td>
												</tr>
												<tr>
													<td><span className="td-wrap-sty">DECK</span></td>
													<td><span className="td-wrap-sty">174321</span></td>
													<td><span className="td-wrap-sty text-left">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="whi-round">12</span></td>
													<td><span className="td-wrap-sty">PCS</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td>
														<div className="form-group cst-checkBx-sty">
															<input type="checkbox" id="html4" />
															<label htmlFor="html4"></label>
														</div>
													</td>
													<td></td>
													<td></td>
												</tr>
												<tr>
													<td><span className="td-wrap-sty">DECK</span></td>
													<td><span className="td-wrap-sty">174321</span></td>
													<td><span className="td-wrap-sty text-left">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="whi-round">12</span></td>
													<td><span className="td-wrap-sty">PCS</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td>
														<div className="form-group cst-checkBx-sty">
															<input type="checkbox" id="html5" />
															<label htmlFor="html5"></label>
														</div>
													</td>
													<td></td>
													<td></td>
												</tr>
												<tr>
													<td><span className="td-wrap-sty">DECK</span></td>
													<td><span className="td-wrap-sty">174321</span></td>
													<td><span className="td-wrap-sty text-left">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="whi-round">12</span></td>
													<td><span className="td-wrap-sty">PCS</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td>
														<div className="form-group cst-checkBx-sty">
															<input type="checkbox" id="html6" />
															<label htmlFor="html6"></label>
														</div>
													</td>
													<td></td>
													<td></td>
												</tr>
												<tr>
													<td><span className="td-wrap-sty">DECK</span></td>
													<td><span className="td-wrap-sty">174321</span></td>
													<td><span className="td-wrap-sty text-left">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="whi-round">12</span></td>
													<td><span className="td-wrap-sty">PCS</span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td><span className="td-wrap-sty"></span></td>
													<td>
														<div className="form-group cst-checkBx-sty">
															<input type="checkbox" id="html7" />
															<label htmlFor="html7"></label>
														</div>
													</td>
													<td></td>
													<td></td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
			{/* Order Details Modal */}
			<div className="modal fade theme-Roundedmodal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<img className="img-fluid" src={require('../../assets/images/cancel-icon.svg')} alt="icon" />
							</button>
						</div>
						<div className="modal-body">
							<div className="modal-card d-flex align-items-center">
								<label>Order details:</label>
								<div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
									<Select
										className="custom-select-wrap small-select-sty" classNamePrefix="react-select" placeholder="Category"
										value={state.selectedOption}
										onChange={() => handleChange()}
										options={options}
									/>
								</div>
							</div>
							<div className="modal-card d-flex align-items-center">
								<h1 className="modalCard-text d-flex align-items-center w-100 m-0">Remaining Pts: <span className="modalCard-value flex-fill text-center">7.532 / 10.000</span><span className="font-weight-bold">75%</span></h1>
							</div>
							<div className="modal-card d-flex align-items-center">
								<h1 className="modalCard-text font-weight-bold m-0">Provision needed until next order in kg:</h1>
							</div>
							<div className="modalTable-wrap table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th>Products</th>
											<th>Needed</th>
											<th>Ordered</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Meat</td>
											<td>361,00</td>
											<td><span className="rounded-td-wrap bg-pink">370,00</span></td>
										</tr>
										<tr>
											<td>Fish</td>
											<td>95,00</td>
											<td><span className="rounded-td-wrap">0,00</span></td>
										</tr>
										<tr>
											<td>Ham/Sausage</td>
											<td>361,00</td>
											<td><span className="rounded-td-wrap">370,00</span></td>
										</tr>
										<tr>
											<td>Dairy Products</td>
											<td>361,00</td>
											<td><span className="rounded-td-wrap">370,00</span></td>
										</tr>
										<tr>
											<td>Fruits/Vegetables</td>
											<td>361,00</td>
											<td><span className="rounded-td-wrap">370,00</span></td>
										</tr>
										<tr>
											<td>Baking goods</td>
											<td>361,00</td>
											<td><span className="rounded-td-wrap">370,00</span></td>
										</tr>
										<tr>
											<td>Flour/Rice/Noodles</td>
											<td>361,00</td>
											<td><span className="rounded-td-wrap">370,00</span></td>
										</tr>
										<tr>
											<td>Dry cereals/Muesli/Sem.</td>
											<td>361,00</td>
											<td><span className="rounded-td-wrap">370,00</span></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="model-footer custom-footer ">
							<div className="modal-card d-flex align-items-center nutri-head-wrap">
								<h1 className="modalCard-text font-weight-bold m-0 text-black">Nutrition per crew per day</h1>
							</div>
							<div className="nutrTable-wrap d-flex align-items-start">
								<div className="nutr-items-wrap">
									<h2 className="nutr-dtls-info nutr-type">Type</h2>
									<ul className="nutr-dtls-info">
										<li>Energy Kcal</li>
										<li> Protein gr.</li>
										<li>Carb. gr.</li>
										<li>Fat gr.</li>
									</ul>
								</div>
								<div className="nutr-items-wrap">
									<ul className="nutr-indicator-wrap">
										<li className="semi-bold">Indication</li>
										<li>2800</li>
										<li>101</li>
										<li>370</li>
										<li>92</li>
									</ul>
								</div>
								<div className="nutr-items-wrap">
									<ul className="nutr-order-wrap">
										<li className="semi-bold">Ordered</li>
										<li>632</li>
										<li>78</li>
										<li>7</li>
										<li>32</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
const mapStateToProps = (state, ownProps) => ({
	apiCallStatus: state.apicallStatusReducer,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	// ApiClearAction: () => dispatch(ApiClearAction())
})
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SupplierOverviewCatalogue);