import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { deleteCatalogue, deleteAllCatalogue } from '../ApiCalls/supplierCatalougeList';
import { getItemCode, getItemGroup, getDescription } from '../../Orders/ApiCalls/searchProvisionOrder';
import { provisionListing } from '../../Orders/ApiCalls/provisionOrder';
import { isAllowPermission } from '../../../Services/common';
import { Wrapper, DashboardNavbar } from '../../Common/Components/index';
import { connect } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import loader from '../../../assets/images/Spinner-1s-200px.gif';
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { getCookie } from '../../../Services/cookies';
import { Filters } from './filters';
import Swal from 'sweetalert2'
import { Link } from "react-router-dom";
import { getRoutePathBykey } from "../../../Constants/routesConfig";
var tableHeader = ["Category", "Item Code", "Alt. Code", "Durability", "Unit", "Actions"];
const tabsList = [
    { id: "provision", label: "Provision", headers: tableHeader },
    { id: "store", label: "Store", headers: tableHeader },
    { id: "additional", label: "Additional", headers: tableHeader },
]
let filterInputTimeout = null;
function SupplierCatalogueListing(props) {
    const [state, setState] = useState({
        catalogueList: [],
        activePage: 0,
        totalCount: 0,
        availability: 'p',
        currentTab: 'provision',
        pricePerCountry: [],
        baseQuantity: '',
        standardPackagingUnit: '',
        filters: {
            itemGroup: "",
            itemNo: "",
            inputItemNo: "",
            inputDescription: "",
            description: "",
            isApplied: false
        },
        itemNoList: [],
        totalItemCode: 0,
        itemCodePage: 0,
        descriptionList: [],
        totalDescription: 0,
        descriptionPage: 0,
        filterQuery: "",
        itemGroupList: [],
        isHitApi: false,
    });
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                let query = '?availability=' + state.availability;
                let filterQuery = `?systemType=${state.currentTab === 'provision' ? 'p' : state.currentTab === 'store' ? 's' : 'a'}`
                if (getCookie('userType') === 'user') {
                    props.provisionListing(0, 200, filterQuery);
                } else {
                    filterQuery += `&supplierId=${getCookie('userId')}`
                    props.provisionListing(0, 200, filterQuery);
                }
                props.getItemCode(0, query);
                props.getDescription(0, query);
                props.getItemGroup(query)
                let data = { ...state };
                data.isHitApi = true;
                data.filterQuery = filterQuery;
                setState(data);
            }
        }
        if ((props.apiCallStatus.apiCallFor === "deleteCatalogue" || props.apiCallStatus.apiCallFor === "deleteAllCatalogue") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
            Swal.fire("Success", `Catalogue has been Deleted successfully`, "success");
            clearFilters();

        }
        if ((props.apiCallStatus.apiCallFor === "getItemCode" || props.apiCallStatus.apiCallFor === "appendItemCode") &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.orderReducer.itemCodeList;
            for (let i = 0; i < arr.length; i++) {
                if (state.currentTab === "provision") {
                    newArr.push({ value: arr[i].itemCode, label: arr[i].itemCode });
                } else {
                    newArr.push({ value: arr[i].alternativeCode, label: arr[i].alternativeCode });
                }
            }
            setState({
                ...state,
                itemNoList: newArr,
                totalItemCode: props.orderReducer.totalItemCode,
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if ((props.apiCallStatus.apiCallFor === "getDescription" || props.apiCallStatus.apiCallFor === "appendDescription") &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.orderReducer.descriptionList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i].description, label: arr[i].description });
            }
            setState({
                ...state,
                descriptionList: newArr,
                totalDescription: props.orderReducer.totalDescription,
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }

        if ((props.apiCallStatus.apiCallFor === "getItemGroup") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let arr = props.orderReducer.itemGroupList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i], label: arr[i] });
            }
            setState({
                ...state,
                itemGroupList: newArr,
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
    }, [props])
    const fetchMoreData = (tabName) => {
        let { activePage } = state;
        if (tabName === state.currentTab) {
            activePage += 1;
            setState({
                ...state,
                activePage
            })
            props.provisionListing(activePage, 200, state.filterQuery);
            // `?systemType=${tab === 'provision' ? 'p' : tab === 'store' ? 's' : 'a'}`
            // `?supplierId=${getCookie('userId')}`
            // if (getCookie('userType') === 'user') {
            //     props.provisionListing(activePage, 200, state.filterQuery);
            // } else {
            //     props.provisionListing(activePage, 200, state.filterQuery);
            // }
        }

    };
    const handleFilterChange = (id, val) => {
        let filters = { ...state.filters };
        let obj = {}
        filters[id] = val;
        obj.filters = filters;
        clearTimeout(filterInputTimeout);
        let query = "?availability=" + state.availability;
        if (id === 'inputItemNo') {
            obj.itemCodePage = 0;
            if (val !== '') {
                if (state.currentTab === "provision") {
                    query = query + '&itemCode=' + val;
                } else {
                    query = query + '&alternativeCode2=' + val;
                }
            }
        } else if (id === 'inputDescription') {
            obj.descriptionPage = 0;
            if (val !== '') {
                query = query + '&description=' + val;
            }
        }
        // Make a new timeout set to go off in 1000ms (1 second)
        filterInputTimeout = setTimeout(function () {
            if (id === 'inputItemNo') {
                props.getItemCode(0, query)
            } else if (id === 'inputDescription') {
                props.getDescription(0, query)
            }
        }, 500);
        setState({ ...state, ...obj });
    };
    const removeAllCatalogues = () => {
        props.deleteAllCatalogue(getCookie('userType') === 'user' ? `?type=${state.currentTab}` : `?supplierId=${getCookie('userId')}&type=${state.currentTab}`);
    }
    const clickCustomeSelectHandler = (id, val) => {
        let filters = { ...state.filters };
        let obj = {}
        filters[id] = val;
        obj.filters = filters;
        let query = "?availability=" + state.availability;
        if (id === 'itemNo') {
            obj.itemCodePage = 0;
            if (val !== '') {
                if (state.currentTab === "provision") {
                    query = query + '&itemCode=' + val;
                } else {
                    query = query + '&alternativeCode2=' + val;
                }
            }
        } else if (id === 'description') {
            obj.descriptionPage = 0;
            if (val !== '') {
                query = query + '&description=' + val;
            }
        }
        setState({ ...state, ...obj });
    }
    const clearFilters = () => {
        let query = "?availability=" + state.availability;
        let filterQuery = '?systemType=' + state.availability;
        if (getCookie('userType') === 'user') {
            props.provisionListing(0, 200, filterQuery);
        } else {
            filterQuery += "&supplierId=" + getCookie('userId')
            props.provisionListing(0, 200, filterQuery);
        }
        props.getItemCode(0, query)
        props.getDescription(0, query)
        props.getItemGroup(query)
        setState({
            ...state,
            filters: {
                itemGroup: "",
                itemNo: "",
                inputItemNo: "",
                inputDescription: "",
                description: "",
                isApplied: false
            },
            filterQuery,
            activePage: 0,
            itemCodePage: 0,
            descriptionPage: 0
        })
    }
    const applyFilters = () => {
        let val1 = '';
        let val2 = '';
        let val3 = '';
        let activePage = 0;
        if (state.filters.itemGroup !== '') {
            val1 = "&itemGroup=" + encodeURIComponent(state.filters.itemGroup);
        } if (state.filters.itemNo !== '') {
            if (state.currentTab === "provision") {
                val2 = '&itemCode=' + state.filters.itemNo;
            } else {
                val2 = '&alternativeCode2=' + state.filters.itemNo;
            }
        }
        if (state.filters.description !== '') {
            val3 = "&description=" + encodeURIComponent(state.filters.description);
        }
        let filterQuery = '?systemType=' + state.availability;
        if (val1 !== '') {
            filterQuery += val1
        }
        if (val2 !== '') {
            filterQuery += val2
        }
        if (val3 !== '') {
            filterQuery += val3
        }
        setState({
            ...state,
            filterQuery,
            activePage,
            itemCodePage: 0,
            descriptionPage: 0,
            isApplied: true
        })
        if (getCookie('userType') === 'user') {
            props.provisionListing(activePage, 200, filterQuery);
        } else {
            filterQuery += `&supplierId=${getCookie('userId')}`
            props.provisionListing(activePage, 200, filterQuery);
        }
    }
    const removeRecord = (id) => {
        Swal.fire({
            title: "Delete Catalogue",
            text: "Are you sure you want to delete this item?",
            icon: "warning",
            buttons: true,
            // showCancelButton: true,
            dangerMode: true,
        })
            .then(result => {
                if (result.isConfirmed) {
                    props.deleteCatalogue(id)
                }
            });
    }
    const tabHandler = (tabName) => {
        let activePage = 0;
        let availability = tabName === 'provision' ? 'p' : tabName === 'store' ? 's' : 'a';
        let query = '?availability=' + availability;
        let filterQuery = `?systemType=${availability}`
        if (getCookie('userType') === 'user') {
            props.provisionListing(activePage, 200, filterQuery);
        } else {
            filterQuery += `&supplierId=${getCookie('userId')}`
            props.provisionListing(activePage, 200, filterQuery);
        }
        props.getItemCode(0, query);
        props.getDescription(0, query);
        props.getItemGroup(query)
        setState({
            ...state,
            filters: {
                itemGroup: "",
                itemNo: "",
                inputItemNo: "",
                inputDescription: "",
                description: "",
                isApplied: false
            },
            activePage,
            availability,
            catalogueList: [],
            filterQuery,
            currentTab: tabName
        })
    }
    const fetchMoreItemCode = () => {
        let { itemCodePage } = state;
        itemCodePage += 1;
        let query = "?availability=" + state.availability;
        if (state.filters.itemNo !== '') {
            if (state.currentTab === "provision") {
                query = query + '&itemCode=' + state.filters.itemNo;
            } else {
                query = query + '&alternativeCode2=' + state.filters.itemNo;
            }
        }
        setState({
            ...state,
            itemCodePage
        })
        props.getItemCode(itemCodePage, query)
    }
    const fetchMoreDescription = () => {
        let { descriptionPage } = state;
        descriptionPage += 1;
        let query = "?availability=" + state.availability;
        if (state.filters.description !== '') {
            query = query + '&description=' + state.filters.description;
        }
        setState({
            ...state,
            descriptionPage
        })
        props.getDescription(descriptionPage, query)
    }
    const openModel = (data) => {
        setState({
            ...state,
            pricePerCountry: data.pricePerCountry,
            baseQuantity: data.baseQuantity,
            standardPackagingUnit: data.standardPackagingUnit
        })
        $("#catalogueDetailModal").modal("show");
    }
    const closeModel = () => {
        setState({
            ...state,
            pricePerCountry: []
        })
        $("#catalogueDetailModal").modal("hide");
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Catalogues" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="ordCatg-table-wrap mt-3 system-cat-filter  fixscrolfilter">
                <Filters {...state.filters} totalItemCode={state.totalItemCode} roles={props.roles} totalDescription={state.totalDescription || 0} itemGroupList={state.itemGroupList} itemNoList={state.itemNoList} descriptionList={state.descriptionList || []} handleChange={handleFilterChange} clearFilters={clearFilters} clickCustomeSelectHandler={clickCustomeSelectHandler} applyFilters={applyFilters} fetchMoreItemCode={fetchMoreItemCode} fetchMoreDescription={fetchMoreDescription} removeAllCatalogues={removeAllCatalogues} catalogueDetailLength={props.catalogueList.length} userType={props.userType} isSubAdmin={props.isSubAdmin} />
            </div>
            <div className="setting-wrapper system-cat-wrap">
                <Tabs tabsHeader={tabsList} tabHandler={(tabName) => tabHandler(tabName)} />
                <div className="tab-content" id="myTabContent">
                    {
                        tabsList.map((tab, index) => {
                            let catalogueDetailLength = props.catalogueList.length;
                            return <TabWrapper key={index} isActive={index === 0} tableClassName={tab.className} id={tab.id} headers={tab.headers} catalogueData={props.catalogueList} fetchMoreData={(tabName) => fetchMoreData(tabName)} totalCount={props.totalCount} apiCallStatus={props.apiCallStatus}>
                                {props.apiCallStatus.isStarted.indexOf("getProvision") !== -1 ?
                                    <tr>
                                        <td colSpan="20"><div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                        </div>
                                        </td>
                                    </tr> : props.catalogueList && catalogueDetailLength === 0 ?
                                        <tr>
                                            <td colSpan="40" className="text-center"><div>No Data Found</div>
                                            </td>
                                        </tr>
                                        : props.catalogueList && catalogueDetailLength > 0 ?
                                            props.catalogueList.map((item, depIndex) => {
                                                return <tr key={depIndex}>
                                                    <td>{state.currentTab === 'provision' ? item.category : item.orderType}</td>
                                                    <td>{item.itemCode}</td>
                                                    <td>{item.alternativeCode}</td>
                                                    <td>{item.durability}</td>
                                                    <td>{item.standardPackagingUnit}</td>
                                                    <td>
                                                        <button type="button" onClick={() => openModel(item)} className="btn mr-2 btn-info-tab"></button>
                                                        {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editCatalogue'), props.roles) ? "" : <Link type="button" to={"/edit-catalogue/" + item._id + `/${state.currentTab}`} className="btn btn-edit-tab mr-2"></Link>}
                                                        {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission('/delete-catalogue', props.roles) ? "" : <button type="button" onClick={() => removeRecord(item._id)} className="btn btn-dlt-tab"></button>}
                                                    </td>
                                                </tr>
                                            }) : ""
                                }
                            </TabWrapper>
                        })
                    }

                </div>
            </div>
            <CatalogueDetailModal pricePerCountry={state.pricePerCountry} standardPackagingUnit={state.standardPackagingUnit} baseQuantity={state.baseQuantity} currentTab={state.currentTab} closeModel={closeModel} />
        </Wrapper>
    )

}
function Tabs(props) {
    return <ul className="nav nav-tabs" id="myTab" role="tablist">
        {props.tabsHeader.map((item, index) => {
            return <React.Fragment key={index}>
                <TabItem id={item.id} isActive={index === 0} label={item.label} tabHandler={props.tabHandler} />
            </React.Fragment>
        })}

    </ul>
}
function TabItem(props) {
    return <li className="nav-item" role="presentation" onClick={() => props.tabHandler(props.id)}>
        <a className={"nav-link " + (props.isActive ? 'active' : '')} id={props.id + "-tab"} data-toggle="tab" href={"#" + props.id} role="tab" aria-controls={props.id} aria-selected="true">{props.label}</a>
    </li>
}
function TabWrapper(props) {
    return <div className={"tab-pane fade " + (props.isActive ? "show active" : "")} id={props.id} role="tabpanel" aria-labelledby={props.id + "-tab"}>
        <div className="settings-steps-wrap">
            <div className="table-responsive">
                <InfiniteScroll
                    dataLength={props.catalogueData.length}
                    next={() => props.fetchMoreData(props.id)}
                    height={"80vh"}
                    hasMore={props.totalCount <= props.catalogueData.length ? false : true}
                    loader={(props.apiCallStatus.isStarted.indexOf("appendSupplierCatalouge") !== -1 || props.apiCallStatus.isStarted.indexOf("appendProvisionList") !== -1) ? <div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div> : ""}
                >
                    <table className={"table theme-table-wrap mt-2 with-action-btns"}>
                        <thead>
                            <tr>
                                {props.headers.map((headerItem, index) => {
                                    return <th key={index}>{headerItem}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {props.children}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
        </div>
    </div>
}
function CatalogueDetailModal(props) {
    return (
        < div className="modal fade theme-squareModal modal-centered" id="catalogueDetailModal" tabIndex="-1" aria-labelledby="NutritaionModal" aria-hidden="true" data-backdrop="static" data-keyboard="false" >
            <div className="modal-dialog w-530">
                <div className="modal-content">

                    <div className="model-body">
                        {/* Modal Content */}
                        <div className="modal-card d-flex align-items-center justify-content-between">
                            <h1 className="modalCard-text m-0">Price Per Country Detail</h1>
                            <button type="button" className="close circle-btn-style" onClick={() => props.closeModel()}></button>
                        </div>
                        <div className="nutrTable-wrap table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Base QTY</th>
                                        <th>PKG Unit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.pricePerCountry && props.pricePerCountry.length > 0 ?
                                        props.pricePerCountry.map((item, index) => {
                                            return <tr key={index}>
                                                <td> {item.name}</td>
                                                <td> {parseInt(item.price).toFixed(2)}</td>
                                                <td> {props.currentTab === 'provision' ? item.baseQuantity : props.baseQuantity}</td>
                                                <td> {props.currentTab === 'provision' ? item.packageUnit : props.standardPackagingUnit}</td>
                                            </tr>
                                        })
                                        : ''
                                    }
                                </tbody>
                            </table>

                        </div>
                        {/* <div className="modal-btn-wrap d-flex justify-content-end">
                            <button type="button" className="close footer-close-btn w-100" onClick={() => props.closeModel()}>Close</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div >
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    userType: state.authReducer.userType,
    isSubAdmin: state.authReducer.isSubAdmin,
    uploadReducer: state.uploadReducer,
    orderReducer: state.orderReducer,
    catalogueList: state.orderReducer.provisionList,
    totalCount: state.orderReducer.totalProvision,
    isSubAdmin: state.authReducer.isSubAdmin,
    roles: state.authReducer.roles,

})

const mapDispatchToProps = (dispatch, ownProps) => ({
    provisionListing: (offset, limit, filterQuery) => dispatch(provisionListing(offset, limit, filterQuery)),
    getItemCode: (offset, query) => dispatch(getItemCode(offset, query)),
    getDescription: (offset, query) => dispatch(getDescription(offset, query)),
    getItemGroup: (query) => dispatch(getItemGroup(query)),
    deleteCatalogue: (catalogueId) => dispatch(deleteCatalogue(catalogueId)),
    deleteAllCatalogue: (query) => dispatch(deleteAllCatalogue(query)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName))

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplierCatalogueListing);
