export const addOrder = "ADD_ORDER";
export const getProvision = "GET_PROVISION";
export const getRfqList = "GET_RFQ_LIST";
export const AppendProvisionList = "APPEND_PROVISION_LIST";
export const getOrder = "GET_ORDER";
export const AppendOrderList = "APPEND_ORDER_LIST";
export const getItemCode = "GET_ITEM_CODE";
export const getDescription = "GET_DESCRIPTION";
export const appendDescription = "APPEND_DESCRIPTION";
export const appendItemCode = "APPEND_ITEM_CODE";
export const getItemGroup = "GET_ITEM_GROUP";
export const getFilterDetail = "GET_FILTER_DETAIL_PROVISION";
export const AppendFilterDetail = "APPEND_FILTER_DETAIL_PROVISION";
export const supplierSelectionList = "SUPPLIER_SELECTION_LIST";
export const getCustomerOrderDetail = "GET_CUSTOMER_ORDER_DETAIL";
export const getTopItems = "GET_TOP_ITEMS";
export const AppendTopItems = "APPEND_TOP_ITEMS";
export const getDocumentsList = "GET_DOCUMENTS_LIST_ORDER";
export const udpateDoucmentStatus = "UPDATE_DOCUMENT_STATUS_ORDER";
export const appendDocument = "APPEND_DOCUMENT_ORDER";

export function getRfqListAction(list, orderStatus,orderDetail) {
  return {
    type: getRfqList,
    payload: { list, orderStatus,orderDetail },
  };
}

export function AddOrderAction(data) {
  return {
    type: addOrder,
    payload: { data },
  };
}

export function SupplierSelectionListAction(list, port) {
  return {
    type: supplierSelectionList,
    payload: { list, port },
  };
}

export function getCustomerOrderDetailAction(detail) {
  return {
    type: getCustomerOrderDetail,
    payload: { detail },
  };
}
export function GetProvisionAction(list, count) {
  return {
    type: getProvision,
    payload: { list, count },
  };
}
export function AppendProvisionAction(list) {
  return {
    type: AppendProvisionList,
    payload: { list },
  };
}
export function GetTopItemsAction(list, count) {
  return {
    type: getTopItems,
    payload: { list, count },
  };
}
export function AppendTopItemsAction(list) {
  return {
    type: AppendTopItems,
    payload: { list },
  };
}
export function GetItemCodeAction(list, count) {
  return {
    type: getItemCode,
    payload: { list, count },
  };
}
export function AppendItemCodeAction(list) {
  return {
    type: appendItemCode,
    payload: { list },
  };
}
export function GetDescriptionAction(list, count) {
  return {
    type: getDescription,
    payload: { list, count },
  };
}
export function AppendDescriptionAction(list) {
  return {
    type: appendDescription,
    payload: { list },
  };
}
export function GetItemGroupAction(list) {
  return {
    type: getItemGroup,
    payload: { list },
  };
}
export function GetFilterDetailAction(
  categoryList,
  categoryCodeList,
  categoryCodeCount
) {
  return {
    type: getFilterDetail,
    payload: { categoryList, categoryCodeList, categoryCodeCount },
  };
}
export function AppendFilterDetailAction(categoryCodeList, categoryCodeCount) {
  return {
    type: AppendFilterDetail,
    payload: { categoryCodeList, categoryCodeCount },
  };
}

export function GetOrderAction(list, count) {
  return {
    type: getOrder,
    payload: { list, count },
  };
}
export function AppendOrderAction(list) {
  return {
    type: AppendOrderList,
    payload: { list },
  };
}
export function getDocumentsListAction(list) {
  return {
    type: getDocumentsList,
    payload: { list },
  };
}
export function appendDocumentAction(obj) {
  return {
    type: appendDocument,
    payload: { obj },
  };
}
export function udpateDoucmentStatusAction(status) {
  return {
    type: udpateDoucmentStatus,
    payload: { status },
  };
}
