import * as Action from "../Actions/action";
import InitialState from "../../../Constants/initialState";
import cloneDeep from "lodash/cloneDeep";
export default function VesselReducer(state = InitialState.vessel, action) {
  switch (action.type) {
    case Action.getVessel:
      let s = cloneDeep(state);
      s.list = action.payload.list;
      s.count = action.payload.count;
      return s;
    case Action.getVesselDetail:
      let s1 = cloneDeep(state);
      s1.detail = action.payload.detail;
      return s1;
    case Action.appendVessalList:
      var s2 = cloneDeep(state);
      var arr = s2.list.slice();
      s2.list = arr.concat(action.payload.list);
      return s2;
    case Action.getQuestionaire:
      var s3 = cloneDeep(state);
      s3.questionaire = action.payload.detail
      return s3;

    default:
      return state;
  }
}
