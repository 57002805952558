import React, { Component } from 'react'
import { Wrapper } from '../Css/loader';

export default function Loader(props) {
    return (
        <Wrapper className="loader-wrap">
            <div className="mesh-spinner">
            <svg width="380px" height="380px" viewBox="0 0 100 100">
                <text x="50" y="50" textAnchor="middle" dy="0.38em" fill="none" strokeLinecap="round" strokeLinejoin="round" stroke="#250a72" strokeWidth="0.6000000000000001" fontSize="15" fontFamily="arial">
                <tspan>M</tspan>
                <tspan>E</tspan>
                <tspan>S</tspan>
                <tspan>H</tspan>
                </text>
            </svg>
            </div>
        </Wrapper>
    );
}