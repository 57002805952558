import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "./../../../Constants/constant";
import { GetCaptainAction, AppendCaptainAction, GetCaptainDetailAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function createCaptain(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "createCaptain" }));
        let myJson = await FETCH("POST", Constant.apiURl + "/captain", {
            name: data.name,
            country: data.country,
            port: data.port,
            vessel: data.vessel
        });
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "createCaptain",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "createCaptain",
                    message: myJson.message,
                })
            );
        }
    };
}
export function captainListing(offset) {
    return async (dispatch) => {
        if (offset !== 0) {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendCaptainList" }));
        } else {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCaptain" }));
            dispatch(GetCaptainAction([], 0))
        }
        let myJson = await FETCH("GET", Constant.apiURl + "/captain/" + offset + "/20");
        if (myJson && myJson.code === 201) {
            if (offset !== 0) {
                dispatch(AppendCaptainAction(myJson.data));
                dispatch(Actions.ApiFulfilledAction({ apiCallFor: "appendCaptainList", message: '' }));

            } else {
                dispatch(GetCaptainAction(myJson.data, myJson.count))
                dispatch(
                    Actions.ApiFulfilledAction({
                        apiCallFor: "getCaptain",
                        message: myJson.message,
                    }))
            }

        } else {

            if (offset !== 0) {
                dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "appendCaptainList", message: myJson.errorMessage ? myJson.errorMessage : myJson.message }));
            } else {
                dispatch(
                    Actions.ApiRejectedAction({
                        statusCode: myJson.statusCode,
                        apiCallFor: "getCaptain",
                        message: myJson.message,
                    }))
            }

        }
    };
}
export function updateCaptain(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateCaptain" }));
        let myJson = await FETCH("PUT", Constant.apiURl + "/captain", {
            name: data.name,
            country: data.country,
            port: data.port,
            vessel: data.vessel,
            captainId: data.captainId
        });
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "updateCaptain",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "updateCaptain",
                    message: myJson.message,
                })
            );
        }
    };
}
export function deleteCaptain(captainId) {
    return async dispatch => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteCaptain" }));
        let myJson = await FETCH('DELETE', Constant.apiURl + "/captain", {
            captainId
        });
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "deleteCaptain",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "deleteCaptain",
                    message: myJson.message,
                })
            );
        }
    }
}

export function getCaptainDetail(id) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCaptainDetail" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/captain/" + id);
        if (myJson && myJson.code === 201) {
            let portCheck = (myJson.data.port !== undefined && myJson.data.port !== null)
            let vesselCheck = (myJson.data.vessel !== undefined && myJson.data.vessel !== null)
            dispatch(
                GetCaptainDetailAction({
                    name: myJson.data.name,
                    country: myJson.data.country._id,
                    port: portCheck ? myJson.data.port._id : '',
                    vessel: vesselCheck ? myJson.data.vessel._id : '',
                    selectedCountryOption: { value: myJson.data.country._id, label: myJson.data.country.name, countryCode: myJson.data.country.countryCode },
                    selectedPortOption: portCheck ? { value: myJson.data.port._id, label: myJson.data.port.port } : null,
                    selectedVesselOption: vesselCheck ? { value: myJson.data.vessel._id, label: myJson.data.vessel.name } : null
                })
            );
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getCaptainDetail",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getCaptainDetail",
                    message: myJson.message,
                })
            );
        }
    };
}