import * as Action from "./../Actions/action";
import InitialState from "./../../../Constants/initialState";
import cloneDeep from "lodash/cloneDeep";
export default function CountryReducer(state = InitialState.country, action) {
    switch (action.type) {
        case Action.getCountry:
            let s = cloneDeep(state);
            s.list = action.payload.list;
            s.count=action.payload.count;
            return s;
        case Action.getCountryDetail:
            let s1 = cloneDeep(state);
            s1.detail = action.payload.detail;
            return s1;
            case Action.AppendCountryList:
                var s2 = cloneDeep(state);
                var arr = s2.list.slice();
                s2.list = arr.concat(action.payload.list);
                return s2;
        default:
            return state;
    }
}