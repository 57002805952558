import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "./../../../Constants/constant";
import { GetDepartmentAction, AppendDepartmentAction, GetDepartmentDetailAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function createDepartment(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "createDepartment" }));
        let myJson = await FETCH("POST", Constant.apiURl + "/department", {
            name: data.name,
            budgetPrice: data.budgetPrice,
        });
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "createDepartment",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "createDepartment",
                    message: myJson.message,
                })
            );
        }
    };
}
export function departmentListing(offset) {
    return async (dispatch) => {
        if (offset !== 0) {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendDepartmentList" }));
        } else {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "getDepartment" }));
            dispatch(GetDepartmentAction([], 0))
        }
        let myJson = await FETCH("GET", Constant.apiURl + "/department/" + offset + "/20");
        if (myJson && myJson.code === 201) {
            if (offset !== 0) {
                dispatch(AppendDepartmentAction(myJson.data));
                dispatch(Actions.ApiFulfilledAction({ apiCallFor: "appendDepartmentList", message: '' }));

            } else {
                dispatch(GetDepartmentAction(myJson.data, myJson.count))
                dispatch(
                    Actions.ApiFulfilledAction({
                        apiCallFor: "getDepartment",
                        message: myJson.message,
                    }))
            }

        } else {
            if (offset !== 0) {
                dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "appendDepartmentList", message: myJson.errorMessage ? myJson.errorMessage : myJson.message }));
            } else {
                dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getDepartment", message: myJson.message }))
            }
        }
    };
}
export function getDepartmentDetail(id) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getDepartmentDetail" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/department/" + id);
        if (myJson && myJson.code === 201) {
            dispatch(
                GetDepartmentDetailAction({
                    name: myJson.data.name,
                    budgetPrice: myJson.data.budgetPrice
                })
            );
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getDepartmentDetail",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getDepartmentDetail",
                    message: myJson.message,
                })
            );
        }
    };
}

export function updateDepartment(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateDepartment" }));
        let myJson = await FETCH("PUT", Constant.apiURl + "/department", {
            name: data.name,
            budgetPrice: data.budgetPrice,
            departmentId: data.departmentId
        });
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "updateDepartment",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "updateDepartment",
                    message: myJson.message,
                })
            );
        }
    };
}
export function deleteDepartment(departmentId) {
    return async dispatch => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteDepartment" }));
        let myJson = await FETCH('DELETE', Constant.apiURl + "/department", {
            departmentId
        });
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "deleteDepartment",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "deleteDepartment",
                    message: myJson.message,
                })
            );
        }
    }
}