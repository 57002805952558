import React, { useEffect, useState } from 'react';
import { Wrapper, DashboardNavbar } from '../../Common/Components/index';
import { userListing, deleteUser, changePassword } from "../ApiCalls/user";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import loader from '../../../assets/images/Spinner-1s-200px.gif';
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from 'sweetalert2'
import { isAllowPermission } from '../../../Services/common';
import { updateCustomer } from '../../Common/ApiCalls/customerList';
import { ChangePasswordModal } from "../../Common/Components/changePasswordModal";
import { getRoutePathBykey } from "../../../Constants/routesConfig";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
function UserListing(props) {
    const [state, setState] = useState({
        userList: [],
        type: 'all',
        name: '',
        typeOption: [
            { label: 'Captain', value: 'captain' },
            { label: 'Chief Mate(1st Officer)', value: 'chiefMate' },
            { label: 'Chief Engineer', value: 'chiefEngineer' },
            { label: 'Electrician', value: 'electrician' },
            { label: 'Cook', value: 'cook' },
            { label: 'Controller', value: 'controller' },
            { label: 'Super intendent', value: 'superIntendent' },
            { label: 'Purchase manager', value: 'purchaseManager' },
            { label: 'Purchaser', value: 'purchaser' },
            { label: 'Management customer', value: 'managementCustomer' },
        ],
        typeLabel: {
            'captain': 'Captain',
            'chiefMate': 'Chief Mate(1st Officer)',
            'chiefEngineer': 'Chief Engineer',
            'electrician': 'Electrician',
            'cook': 'Cook',
            'controller': 'Controller',
            'superIntendent': 'Super intendent',
            'purchaseManager': 'Purchase manager',
            'purchaser': 'Purchaser',
            'managementCustomer': 'Management customer'
        },
        filterQuery: '',
        selectedTypeOption: null,
        startDate: null,
        endDate: null,
        activePage: 0,
        totalCount: 0,
        isHitApi: false,
        selectedUser: "",
        showModal: '',
    });
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.userListing(0, 'all');
                let data = { ...state };
                data.isHitApi = true;
                setState(data);
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getUser" || props.apiCallStatus.apiCallFor === "appendUserList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                userList: props.userReducer.list,
                totalCount: props.userReducer.count,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "deleteUser" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            Swal.fire("Deleted!", "User has been deleted!", "success");
            props.userListing(0, 'all');
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }

    }, [props]);
    const fetchMoreData = () => {
        let { activePage, type, filterQuery } = state;
        activePage += 1;
        setState({
            ...state,
            activePage
        })
        props.userListing(activePage, "all", filterQuery)
    };
    const removeRecord = (id, subType) => {
        if (!isAllowPermission('/delete-user', props.roles)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "You don't have permission of this feature"
            })
        } else {
            Swal.fire({
                title: "Delete User",
                text: "Are you sure you want to delete this User?",
                icon: "warning",
                buttons: true,
                // showCancelButton: true,
                dangerMode: true,
            })
                .then(result => {
                    if (result.isConfirmed) {
                        props.deleteUser(id, `subType=${subType}`)
                    }
                });
        }
    }
    const statusHandler = (id, data) => {
        let obj = {
            isActive: data
        }
        let newArray = state.userList;
        let index = newArray.findIndex(x => x.id === id);
        newArray[index]['isActive'] = data;
        setState({
            ...state,
            userList: newArray
        })
        props.updateCustomer(id, obj)
    }
    const handleChange = (id, val) => {
        if (val.value) {
            setState({
                ...state,
                [id]: val.value,
                selectedTypeOption: val
            })
        } else {
            setState({
                ...state,
                [id]: val,
            })
        }

    }
    const applyFilters = () => {
        let data = { ...state };
        let from = moment(data.startDate).format('YYYY-MM-DD');
        let to = moment(data.endDate).add(1, 'days').format('YYYY-MM-DD');
        let name = data.name;
        let type = data.type;
        let filterQuery = '';
        if (state.startDate !== null) {
            filterQuery += `&from=${from}`
        }
        if (state.endDate !== null) {
            filterQuery += `&to=${to}`
        }
        // let filterQuery = `&from=${from}&to=${to}`
        if (name) {
            filterQuery += `&name=${name}`
        }
        if (type && type !== "all") {
            filterQuery += `&subType=${type}`
        }
        props.userListing(0, "all", filterQuery)
        data.isHitApi = true;
        data.activePage = 0;
        data.filterQuery = filterQuery;
        setState(data);
        // else {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Error',
        //         text: "Please select both date."
        //     })
        // }
    }
    const clearFilters = () => {
        let data = { ...state };
        data.activePage = 0
        data.startDate = null
        data.endDate = null
        data.name = '';
        data.type = 'all';
        data.selectedTypeOption = null;
        props.userListing(0, 'all', '')
        data.isHitApi = true;
        data.filterQuery = '';
        setState(data);
    }
    const handleModalChange = (val, userId) => {
        setState({
            ...state,
            selectedUser: userId,
            showModal: val,
        })
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
        {
            img: 'Edit-icon.svg',
            className: "dark-green",
            name: "Create<br />User",
            path: "/create-user",
            isLogout: false
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Users" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="ordCatg-table-wrap mt-4 system-cat-filter role-content-wrap">
                <div className="card-bx-sty">
                    <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                        <div className="fil-wrap-ctn d-flex align-items-center flex-column flex-sm-row mb-2 mb-lg-0">
                            <div className="date-range-picker d-flex align-items-center">
                                <span className="d-inline-block mr-1">
                                    <DatePicker
                                        selected={state.startDate}
                                        onChange={(val) => handleChange('startDate', val)}
                                        maxDate={new Date()}
                                        className="link-style"
                                    />
                                </span>
                                to
                                        <span className="d-inline-block ml-1">
                                    <DatePicker
                                        selected={state.endDate}
                                        onChange={(val) => handleChange('endDate', val)}
                                        disabled={state.startDate === null ? true : false}
                                        minDate={state.startDate !== null ? new Date(moment(state.startDate).add(1, 'days')) : new Date(moment().add(1, 'days'))}
                                        className="link-style"
                                    />
                                </span>
                            </div>
                            <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0 filter-searchbar">
                                <div className="form-group">
                                    <input type="text" className="form-control" onChange={(e) => handleChange('name', e.target.value)} value={state.name} placeholder="Search by name" />
                                </div>
                            </div>
                            <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0 filter-searchbar">

                                <div className="form-group">
                                    <Select
                                        className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select User Type"
                                        value={state.selectedTypeOption}
                                        onChange={(e) => handleChange('type', e)}
                                        options={state.typeOption}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                            <button type="button" className="btn btn-yellow" onClick={() => applyFilters()} >Search</button>
                            <button type="button" className="btn btn-yellow ml-1" onClick={() => clearFilters()} >Clear</button>
                        </div>
                    </div>
                </div>
            </div>
            <InfiniteScroll
                dataLength={state.userList.length}
                next={fetchMoreData}
                height={"80vh"}
                hasMore={state.totalCount <= state.userList.length ? false : true}
                loader={props.apiCallStatus.isStarted.indexOf("getUser") === -1 ? <div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div> : ""}
            >
                <div className="table-responsive mt-4">
                    <table className="table theme-table-wrap with-action-btns">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Country </th>
                                <th scope="col">Port </th>
                                <th scope="col">Type </th>
                                <th scope="col">Vessel </th>
                                <th scope="col">Role </th>
                                <th scope="col">Created At </th>
                                {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editUser'), props.roles) && !isAllowPermission('/delete-user', props.roles) ? "" : <th scope="col">Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {props.apiCallStatus.isStarted.indexOf("getUser") !== -1 ?
                                <td colSpan="20">
                                    <div className="loader-img text-center">
                                        <img style={{ width: "46px" }} src={loader} alt='' />
                                    </div>
                                </td> : state.userList && state.userList.length > 0 ? state.userList.map((item, index) => {
                                    let isDisableEdit = props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editUser'), props.roles) ? true : false;
                                    let isDisableDel = props.isSubAdmin && props.roles.length > 0 && !isAllowPermission('/delete-user', props.roles) ? true : false;
                                    return <tr key={index + 1}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.countryId && item.countryId.name ? item.countryId.name : ''}</td>
                                        <td>{item.portId && item.portId.port ? item.portId.port : ''}</td>
                                        <td>{state.typeLabel[item.subType] ? state.typeLabel[item.subType] : ""}</td>
                                        <td>{item.vesselId && item.vesselId.name ? item.vesselId.name : ''}</td>
                                        <td>{item.roleId && item.roleId.roleName ? item.roleId.roleName : ''}</td>
                                        <td>{moment(item.createdAt).format("LLL")}</td>
                                        {isDisableDel && isDisableDel ? ""
                                            : <td>
                                                <div className="Slide-checkbx" >
                                                    <input type="checkbox" value="None" onChange={() => statusHandler(item.id, !item.isActive)} id={index} checked={item.isActive} />
                                                    <label htmlFor={index}></label>
                                                    <span className="indic-line"></span>
                                                </div>
                                                {props.userType === "user" && !props.isSubAdmin ? <button className="btn btn-change-password-tab" onClick={() => handleModalChange('changePassword', item.id)}></button> : ""}
                                                {isDisableEdit ? "" : <Link to={"/edit-user/" + item.id} className="btn btn-edit-tab mr-2"></Link>}
                                                {isDisableDel ? "" : <button type="button" onClick={() => removeRecord(item.id, item.subType)} className="btn btn-dlt-tab"></button>}
                                            </td>}
                                    </tr>

                                }) : state.userList && state.userList.length === 0 ?
                                        <td colSpan="40" className="text-center">No Data Found</td>
                                        : ''}
                        </tbody>
                    </table>
                </div>
            </InfiniteScroll>
            <ChangePasswordModal ClearApiByNameAction={props.ClearApiByNameAction} apiCallStatus={props.apiCallStatus} changePassword={props.changePassword} selectedUser={state.selectedUser} showModal={state.showModal} handleModalChange={(val, userId) => handleModalChange(val, userId)} />
        </Wrapper >
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    userReducer: state.userReducer,
    roles: state.authReducer.roles,
    isSubAdmin: state.authReducer.isSubAdmin,
    userType: state.authReducer.userType
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    userListing: (offset, type, filterQuery) => dispatch(userListing(offset, type, filterQuery)),
    deleteUser: (id, query) => dispatch(deleteUser(id, query)),
    updateCustomer: (id, data) => dispatch(updateCustomer(id, data)),
    changePassword: (data) => dispatch(changePassword(data)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserListing);
