import React, { useEffect, useState } from 'react';
import { Wrapper, DashboardNavbar } from '../../Common/Components/index';
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { getSubAdminDetail, updateSubAdmin, getAllRole } from '../ApiCalls/subAdmin'
import { SubAdminForm } from './subAdminForm'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import $ from 'jquery';
import '../css/roles.css';
import "react-datepicker/dist/react-datepicker.css";
const subAdminInitialState = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: null,
    subAdminId: '',
    allRoles: [],
    isHitApi: false
}
function EditSubAdmin(props) {
    const [state, setState] = useState(subAdminInitialState);
    useEffect(() => {
        $('#password').removeAttr('required');
        $('#confirmPassword').removeAttr('required');
        if (!state.isHitApi) {
            if (props.isSubAdmin) {
                props.history.push('/not-allowed')
            } else {
                props.getSubAdminDetail(props.match.params.id)
                props.getAllRole()
                let data = { ...state };
                data.isHitApi = true;
                data.subAdminId = props.match.params.id;
                setState(data);
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getSubAdminDetail") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(props.roleReducer.subAdminDetail)
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getAllRole") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let arr = props.roleReducer.allRoles;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].roleName, roles: arr[i].roles, permission: arr[i].permission });
            }
            setState({
                ...state,
                allRoles: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "updateSubAdmin") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let msg = `The sub-admin has been updated successfully.`
            Swal.fire("Success", msg, "success");
            props.history.push('/subAdmins')
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
    })
    const handleFormChange = (e) => {
        let name = e.target !== undefined ? e.target.name : 'role';
        let value = e.target !== undefined ? e.target.value : e;
        let data = { ...state }
        data[name] = value;
        setState(data)
    }
    const updateHandler = () => {
        if (state.password !== '' && state.password.length < 8) {
            Swal.fire("Error!", 'password should be 8 characters', "error");
        }
        else if (state.password !== '' && state.password !== state.confirmPassword) {
            Swal.fire("Error!", 'Password and Confirmed Password does not match', "error");
        } else if (state.role === null) {
            Swal.fire("Error!", 'Select Role Permission', "error");
        } else {
            let obj = {
                name: state.name,
                email: state.email,
                type: "user",
                subType: "subAdmin",
                roleId: state.role.value,
                roleName: state.role.label,
                roles: state.role.roles,
                permission: state.role.permission
            }
            if (state.password) {
                obj['password'] = state.password
            }
            props.updateSubAdmin(state.subAdminId, obj)
        }

    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
    ];
    return (
        <Wrapper>
            <div className="">
                <DashboardNavbar title="Edit Sub Admin" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
                <div className="formBox mt-4">
                    <SubAdminForm {...state} history={props.history} isEdit={true} handleFormChange={handleFormChange} handleSubmit={updateHandler} apiCallStatus={props.apiCallStatus} apiName="updateSubAdmin" />
                </div>
            </div>
        </Wrapper>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    roleReducer: state.roleReducer,
    isSubAdmin: state.authReducer.isSubAdmin
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    getSubAdminDetail: (id) => dispatch(getSubAdminDetail(id)),
    updateSubAdmin: (id, data) => dispatch(updateSubAdmin(id, data)),
    getAllRole: () => dispatch(getAllRole())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditSubAdmin);
