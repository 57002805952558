import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "./../../../Constants/constant";
import { GetPackageAction, AppendPackageAction, GetPackageDetailAction, getPackageCategoryDetailAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function createPackage(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "createPackage" }));
        let myJson = await FETCH("POST", Constant.apiURl + "/package", {
            name: data.name,
            price: data.price,
            discount: data.discount,
            features: data.features,
            commision: data.commision,
        });
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "createPackage",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "createPackage",
                    message: myJson.message,
                })
            );
        }
    };
}
export function packageListing(offset) {
    return async (dispatch) => {
        if (offset !== 0) {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendPackageList" }));
        } else {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "getPackage" }));
            dispatch(GetPackageAction([], 0))
        }
        let myJson = await FETCH("GET", Constant.apiURl + "/package/" + offset + "/20");
        if (myJson && myJson.code === 201) {
            if (offset !== 0) {
                dispatch(AppendPackageAction(myJson.data));
                dispatch(Actions.ApiFulfilledAction({ apiCallFor: "appendPackageList", message: '' }));

            } else {
                dispatch(GetPackageAction(myJson.data, myJson.count))
                dispatch(
                    Actions.ApiFulfilledAction({
                        apiCallFor: "getPackage",
                        message: myJson.message,
                    }))
            }

        } else {
            if (offset !== 0) {
                dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "appendPackageList", message: myJson.errorMessage ? myJson.errorMessage : myJson.message }));
            } else {
                dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getPackage", message: myJson.message }))
            }
        }
    };
}
export function getPackageDetail(id) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getPackageDetail" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/package/" + id);
        if (myJson && myJson.code === 201) {
            dispatch(
                GetPackageDetailAction({
                    name: myJson.data.name,
                    price: myJson.data.price,
                    discount: myJson.data.discount,
                    features: myJson.data.features,
                    commision: myJson.data.commision
                })
            );
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getPackageDetail",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getPackageDetail",
                    message: myJson.message,
                })
            );
        }
    };
}
export function updatePackage(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "updatePackage" }));
        let myJson = await FETCH("PUT", Constant.apiURl + "/package", {
            name: data.name,
            price: data.price,
            discount: data.discount,
            commision: data.commision,
            features: data.features,
            packageId: data.packageId
        });
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "updatePackage",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "updatePackage",
                    message: myJson.message,
                })
            );
        }
    };
}
export function deletePackage(packageId) {
    return async dispatch => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "deletePackage" }));
        let myJson = await FETCH('DELETE', Constant.apiURl + "/package", {
            packageId
        });
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "deletePackage",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "deletePackage",
                    message: myJson.message,
                })
            );
        }
    }
}
export function getPackageCategoryDetail() {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getPackageCategoryDetail" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/package/category");
        if (myJson && myJson.code === 201) {
            dispatch(
                getPackageCategoryDetailAction(myJson.data)
            );
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getPackageCategoryDetail",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getPackageCategoryDetail",
                    message: myJson.message,
                })
            );
        }
    };
}