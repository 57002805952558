import React, { useEffect, useState } from 'react';
import { createSupplier } from "../../ApiCalls/supplier";
import { getAllPort } from "../../../Common/ApiCalls/portList";
import { getAllCountry } from "../../../Common/ApiCalls/countryList";
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import  DashboardNavbar  from '../../../Common/Components/dashboardNavbar';
import { Wrapper } from '../../../Common/Components/CommonWrapper';
import { SupplierForm } from './supplierForm';
import cloneDeep from 'lodash.clonedeep';
import { getDashboardLink, isAllowPermission } from '../../../../Services/common';
import Swal from 'sweetalert2'
import { getRoutePathBykey } from '../../../../Constants/routesConfig';
const formInitialState = {
    name: "",
    email: "",
    password: "",
    title: "",
    description: "",
    logo: [],
    companyName: "",
    websiteUrl: "",
    country: "",
    images: [],
    searchPort: "",
    supplierPorts: [],
    portList: [],
    countryList: [],
    isHitApi: false
}
function CreateSupplier(props) {
    const [state, setState] = useState(formInitialState);
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.getAllCountry('');
                let data = { ...state };
                data.isHitApi = true;
                setState(data);
            }
        }
        if ((props.apiCallStatus.apiCallFor === "createSupplier") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(formInitialState)
            props.ApiClearAction();
            Swal.fire("Success", `The supplier has been created successfully.`, "success")
            if (props.isSubAdmin && !isAllowPermission(getRoutePathBykey('supplierList'), props.roles)) {
                props.history.push(getDashboardLink())
            } else {
                props.history.push('/supplier-listing');
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getAllPort") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let arr = props.portList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].port, countryName: arr[i].countryName.toUpperCase() });
            }
            setState({
                ...state,
                portList: newArr,
            })
            props.ApiClearAction();
        }
        if ((props.apiCallStatus.apiCallFor === "getAllCountry") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let countryList = [];
            for (let index = 0; index < props.countryList.length; index++) {
                let val = props.countryList[index];
                countryList.push({ label: val.name, value: val._id, countryCode: val.countryCode })
            }
            setState({
                ...state,
                countryList: countryList,
            })
            props.ApiClearAction();
        }
    });
    const handleFormChange = (e) => {
        if (e.target.type === 'file') {
            if (e.target.files.length > 0) {
                let name = e.target.name;
                let formDetail = { ...state }
                formDetail[name] = e.target.files;
                setState(formDetail)
            }
        } else {
            let name = e.target.name;
            let value = e.target.value;
            let formDetail = { ...state }
            formDetail[name] = value;
            if (name === 'country') {
                formDetail["searchPort"] = "";
                formDetail["supplierPorts"] = [];
                formDetail["portList"] = []
                let query = "countryCode=" + value.countryCode;
                props.getAllPort(query);
            }
            setState(formDetail)
        }
    }
    const handleSupplierPortChange = (id, obj) => {
        let arr = cloneDeep(state.supplierPorts);
        let data = { ...state };
        let index = state.supplierPorts.findIndex(x => x.value === obj.value);
        if (id === 'removePorts') {
            if (index !== -1) {
                arr.splice(index, 1);
            }
            data.supplierPorts = arr;
        } else {
            if (index === -1) {
                arr.push(obj);
            }
            data.supplierPorts = arr;
        }
        setState(data);
    }


    const createSupplier = () => {
        let obj = {
            userId: props.userId,
            email: state.email,
            password: state.password,
            name: state.name,
            title: state.title,
            description: state.description,
            logo: state.logo,
            supplierPorts: state.supplierPorts,
            companyName: state.companyName,
            websiteUrl: state.websiteUrl,
            images: state.images,
            countryId: state.country.value
        }
        props.createSupplier(obj)
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Create Supplier" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="formBox mt-4">
                <SupplierForm {...state} history={props.history} handleFormChange={handleFormChange} handleSubmit={createSupplier} apiCallStatus={props.apiCallStatus} apiName="createSupplier" handleSupplierPortChange={handleSupplierPortChange} countryList={state.countryList} />
            </div>
        </Wrapper>
    )
}


const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    supplierReducer: state.supplierReducer,
    userId: state.authReducer.userId,
    roles: state.authReducer.roles,
    countryList: state.commonReducer.countryList,
    portList: state.commonReducer.portList,
    isSubAdmin: state.authReducer.isSubAdmin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
    createSupplier: (data) => dispatch(createSupplier(data)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    getAllCountry: (query) => dispatch(getAllCountry(query)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateSupplier);
