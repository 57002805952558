import styled from "styled-components";
import BgImage from "../../../assets/images/login-bg.jpg";

export const Wrapper2 = styled.div`
.supplierProfileItem{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;background: #EBEBEB;border: 1px solid #000000;-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 10px;padding:13px 15px;}
.profileTextHolder{font-weight: 600;font-size: 16px;line-height: 20px;color:#250A72;}
.profileTextHolder p{margin:0 0 20px;}
.pageProfileBottomText{font-weight: 600;font-size: 14px;line-height: 17px;position: absolute;right:5px;bottom:-35px;}
.supplierProfileItem .supplierName{font-weight:bold;font-size: 24px;line-height: 27px;color: #250A72;}
.supplierProfileItem .supplierName,.supplierProfileItem .starRating,.supplierProfileItem .preferredSupplier{margin:0 15px 0 0;}
.supplierProfileItem .starRating li{margin:0 5px 0 0;}
.supplierProfileItem .supplierLogoHolder{min-width:145px;max-width:145px;max-height: 157px;}
.supplierProfileItem .supplierLogoHolder img {width: 100%;height: 100%;-o-object-fit: cover;object-fit: cover;-o-object-position: center;object-position: center;}
.supplierItem .supplierLogoHolder{min-width:100px;max-width:100px;max-height: 80px;background: #f6f6f6;border: 1px solid #818181;border-radius: 3px;-webkit-box-shadow: 0 0 7px #00000042;box-shadow: 0 0 7px #00000042;}
.supplierItem .supplierLogoHolder img {width: 100%;height: 100%;-o-object-fit: contain;object-fit: contain;overflow: hidden;}
.supplierProfileItem .supplierImageHolder {max-width: 330px;width: 25%;max-height: 170px;height:100%;min-width: 130px;margin-bottom: 0 !important;}
.supplierProfileItem .supplierImageHolder img {width: 100%;height: 100%;-o-object-fit: contain;object-fit: contain;-o-object-position: bottom;object-position: bottom;}
.profileProgressHolder{max-width:450px;position: relative;}
.progressBarHolder{width:100%;background: rgb(79,82,193);background: -webkit-gradient(linear, left top, right top, from(rgba(79,82,193,1)), to(rgba(40,138,68,1)));background: -o-linear-gradient(left, rgba(79,82,193,1) 0%, rgba(40,138,68,1) 100%);background: linear-gradient(90deg, rgba(79,82,193,1) 0%, rgba(40,138,68,1) 100%);}
.progressBarHolder .progressStep{width:100px;border-right:1px solid #fff;height:10px;background-color:#e1e9ee;}
/* .progressBarHolder .progressStep:last-child{border-right: none;}  */
.progressBarHolder .progressStep.cleared{background-color:transparent;}
.supplierImagesHolder .selectOrderText{width:170px;}
.supplierImagesHolder .selectOrderText .btn-link{font-style: normal;font-weight: 600;font-size: 20px;line-height: 24px;color:#250A72;padding:0;}
.profileProgressHolder .completionArrow{width:30px;height:30px;background:#25b4bc;border-radius:100%;padding:3px;position: absolute;left: 50%;top:50%;transform: translateY(-50%);-o-transform: translateY(-50%);-ms-transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);}
.profileProgressHolder .completionArrowCircle{background:#fff;border-radius:100%;position: relative;}
.profileProgressHolder .completionArrowCircle:before {content: "";left: 9px;top: 5px;width: 7px;height: 13px;position: absolute;border: solid #25b4bc;border-width: 0 2px 2px 0;-webkit-transform: rotate(40deg);-ms-transform: rotate(40deg);transform: rotate(40deg);}
.webUrlHolder{font-weight: 600;font-size: 20px;line-height: 24px;color:#000;}
.webUrlHolder .webUrl{color:#000;-o-transition: all ease-in-out 0.2s;-webkit-transition: all ease-in-out 0.2s;transition: all ease-in-out 0.2s;}
.webUrlHolder .webUrl:hover{text-decoration: none;color:#17afb7;}
.supplierMainItems{font-weight: bold;font-size: 16px;line-height: 20px;color: #000000;}
.supplierMainItems li{margin:0 10px 10px 0;float:left;}


@media only screen and (max-width:1600px){
	.logoHolder img {width: 150px;}
	.pageWrapper .pageGoBackBtn {min-width: 110px;font-size: 14px;padding: 5px 20px;}
	.filterRow .filterCol .filterLabel {font-size: 14px;line-height: 19px;}
	.appliedFilterList{font-size:14px;line-height:12px;}
	.supplierProfileItem .supplierLogoHolder {min-width: 100px;max-width: 100px;max-height: 100px;}
	.supplierProfileItem .supplierName {font-size: 18px;}
	.supplierProfileItem .starRating li img {width: 15px;}
	.supplierCertificateBlock {width: 35px;}
	.ratingLogosHolder .supplierCertificateBlock {width: auto;}
	.preferredSupplier {font-size: 13px;}
	.webUrlHolder {font-size: 14px;}
	.progressBarHolder .progressStep {height: 6px}
	.profileProgressHolder .completionArrow {width: 25px;height: 25px;}
	.profileProgressHolder .completionArrowCircle:before {left: 7px;top: 4px;width: 5.3px;height: 10px;}
	.supplierMainItems {font-size: 12px;line-height: normal;margin-top: 13px !important;font-weight: 600;}
	.profileTextHolder {font-weight: 500;font-size: 13px;}
	.supplierProfileItem .supplierImageHolder {max-width: 130px;max-height: 110px;min-width: 130px;height:100%}
	.supplierImagesHolder .selectOrderText .btn-link {font-size: 14px;}
	.supplier-cont-item-wrap .supplierContentList {font-size: 13px;}
	.supplier-cont-item-wrap h2 {font-size: 18px;}
	.suppl-select-wrapper .supplierCertificateBlock div img {width: 30px}
	.supplierItem .bottomLinks .btn-link {font-size: 14px;}
	.supplier-select-boxes .customCheckBoxLabel {font-size: 16px;}
	.supplier-select-boxes .customCheckBoxLabel .checkmark {height: 19px;width: 19px;}
	.supplierItem .supplierLogoHolder {min-width: 80px;max-width: 80px;max-height: 70px;}
}
@media only screen and (max-width:1440px){
    .webUrlHolder{font-size:18px;line-height:22px;}
}
@media only screen and (max-width:1200px){
    .supplierProfileItem .supplierName{margin:0 0 10px;}
    .supplierProfileItem .starRating, .supplierProfileItem .preferredSupplier{margin:0 0 5px;}
    .webUrlHolder{font-size:16px;line-height:20px;}
}
@media only screen and (max-width:768px){
    .supplierProfileItem .supplierImageHolder{margin:0 0 15px;}
    .supplierImagesHolder .selectOrderText .btn-link{padding:5px 0;}
    .pageProfileBottomText{position:relative;bottom: auto;right:auto;}
}
@media only screen and (max-width: 576px){
    .supplierProfileItem .starRating, .supplierProfileItem .preferredSupplier{margin:0 0 10px;}
    .supplierProfilePageContent .filterRow .filterLabel{margin:0 0 7px;font-size:18px;line-height:22px;}
}

`


export const Wrapper = styled.div`
.supplierProfileItem{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;background: #EBEBEB;border: 1px solid #000000;-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 10px;padding:13px 15px;}
.profileTextHolder{font-weight: 600;font-size: 16px;line-height: 20px;color:#250A72;}
.profileTextHolder p{margin:0 0 20px;}
.pageProfileBottomText{font-weight: 600;font-size: 14px;line-height: 17px;position: absolute;right:5px;bottom:-35px;}
.supplierProfileItem .supplierName{font-weight:bold;font-size: 24px;line-height: 27px;color: #250A72;}
.supplierProfileItem .supplierName,.supplierProfileItem .starRating,.supplierProfileItem .preferredSupplier{margin:0 15px 0 0;}
.supplierProfileItem .starRating li{margin:0 5px 0 0;}
.supplierProfileItem .supplierLogoHolder{min-width:145px;max-width:145px;max-height: 157px;}
.supplierProfileItem .supplierLogoHolder img {width: 100%;height: 100%;-o-object-fit: cover;object-fit: cover;-o-object-position: center;object-position: center;}
.supplierItem .supplierLogoHolder{min-width:100px;max-width:100px;max-height: 80px;background: #f6f6f6;border: 1px solid #818181;border-radius: 3px;-webkit-box-shadow: 0 0 7px #00000042;box-shadow: 0 0 7px #00000042;overflow: hidden;}
.supplierItem .supplierLogoHolder img {width: 100%;height: 100%;-o-object-fit: contain;object-fit: contain;}
.supplierProfileItem .supplierImageHolder {max-width: 330px;width: 25%;max-height: 170px;height:100%;min-width: 130px;margin-bottom: 0 !important;}
.supplierProfileItem .supplierImageHolder img {width: 100%;height: 100%;-o-object-fit: contain;object-fit: contain;-o-object-position: bottom;object-position: bottom;}
.profileProgressHolder{max-width:450px;position: relative;}
.progressBarHolder{width:100%;background: rgb(79,82,193);background: -webkit-gradient(linear, left top, right top, from(rgba(79,82,193,1)), to(rgba(40,138,68,1)));background: -o-linear-gradient(left, rgba(79,82,193,1) 0%, rgba(40,138,68,1) 100%);background: linear-gradient(90deg, rgba(79,82,193,1) 0%, rgba(40,138,68,1) 100%);}
.progressBarHolder .progressStep{width:100px;border-right:1px solid #fff;height:10px;background-color:#e1e9ee;}
/* .progressBarHolder .progressStep:last-child{border-right: none;}  */
.progressBarHolder .progressStep.cleared{background-color:transparent;}
.supplierImagesHolder .selectOrderText{width:170px;}
.supplierImagesHolder .selectOrderText .btn-link{font-style: normal;font-weight: 600;font-size: 20px;line-height: 24px;color:#250A72;padding:0;}
.profileProgressHolder .completionArrow{width:30px;height:30px;background:#25b4bc;border-radius:100%;padding:3px;position: absolute;left: 50%;top:50%;transform: translateY(-50%);-o-transform: translateY(-50%);-ms-transform: translateY(-50%);-moz-transform: translateY(-50%);-webkit-transform: translateY(-50%);}
.profileProgressHolder .completionArrowCircle{background:#fff;border-radius:100%;position: relative;}
.profileProgressHolder .completionArrowCircle:before {content: "";left: 9px;top: 5px;width: 7px;height: 13px;position: absolute;border: solid #25b4bc;border-width: 0 2px 2px 0;-webkit-transform: rotate(40deg);-ms-transform: rotate(40deg);transform: rotate(40deg);}
.webUrlHolder{font-weight: 600;font-size: 20px;line-height: 24px;color:#000;}
.webUrlHolder .webUrl{color:#000;-o-transition: all ease-in-out 0.2s;-webkit-transition: all ease-in-out 0.2s;transition: all ease-in-out 0.2s;}
.webUrlHolder .webUrl:hover{text-decoration: none;color:#17afb7;}
.supplierMainItems{font-weight: bold;font-size: 16px;line-height: 20px;color: #000000;}
.supplierMainItems li{margin:0 10px 10px 0;float:left;}
.pageWrapper{height:100vh;background:url(${BgImage});background-size:cover;background-position: center center;padding:50px 12px;overflow-y: auto;position:relative;}
.pageContainer{margin:0 0 50px;background: rgba(249, 249, 249, 0.7);border: 1.5px solid #979797;-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 10px;padding:30px;position: relative;}
.filterRow{background: #EBEBEB;border: 1px solid #000000;-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 10px;padding:14px 0;}
.filterRow:after{content:"";clear:both;display:block;}
.filterRow .filterCol{width:25%;float:left;padding:0 15px;}
.filterRow .filterCol .filterLabel{min-width:100px;padding:5px 10px 0 0;font-style: normal;font-weight: 600;font-size: 20px;line-height: 24px;color:#250A72;}
.appliedFilterList{font-weight: 600;font-size: 16px;line-height: 20px;color: #250A72;}
.appliedFilterList li{position: relative;padding: 9px 0 5px 30px;margin: 0;display: -webkit-box;overflow: hidden;-webkit-line-clamp: 2;-webkit-box-orient: vertical;max-height: 50px;}
.appliedFilterList li:before{width:22px;height:16px;content:"";position: absolute;left:0;top:10px;background:url(../../assets/images/check-icon.png);background-size: contain;}
.customCheckBoxLabel {display: block;position: relative;padding-left:21px;margin-bottom:7px;cursor: pointer;font-weight: 600;font-size: 12px;line-height: 15px;color:#250A72;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.customCheckBoxLabel input {position: absolute;opacity: 0;cursor: pointer;height: 0;width: 0;}
.checkmark {position: absolute;top: 0;left: 0;height: 13px;width: 13px;border:1px solid #4A4A4A;background-color: #fff;border-radius:3px;-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);}
.customCheckBoxLabel input:checked ~ .checkmark {background-color: #4A4A4A;}
.supplierItem{background: #EBEBEB;border: 1px solid #000000;-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 10px;padding:13px 15px;width: calc(100% - 130px);max-width: calc(100% - 130px);}
.supplierProfileItem .supplierLogoHolder{min-width:145px;max-width:145px;max-height: 157px;}
.supplierProfileItem .supplierLogoHolder img {width: 100%;height: 100%;-o-object-fit: cover;object-fit: cover;-o-object-position: center;object-position: center;}
.supplierItem .supplierImageHolder{min-width:145px;}
.supplierItem .supplierDetailHolder{font-weight: 600;font-size: 12px;line-height: 15px;color: #250A72;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
.supplierItem .supplierDetailText{margin:0 0 15px;}
.supplierItem .supplierName{font-weight:bold;font-size: 20px;line-height: 27px;color: #250A72;}
.supplierItem .supplierName,.supplierItem .starRating,.supplierItem .preferredSupplier{margin:0 15px 0 0;}
.supplierItem .starRating li{margin:0 5px 0 0;}
.supplierCertificateBlock{min-width:140px;font-size: 12px;line-height: 15px;font-weight: 600;color:#250A72;}
.supplierItem .supplierDetailHolder p{margin:0;}
.preferredSupplier{font-weight: 600;font-size: 16px;line-height: 20px;color:#FA0808;}
.supplierItem .bottomLinks{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;}
.supplierItem .bottomLinks .btn-link{font-weight: 600;font-size: 16px;line-height: 24px;color:#250A72;padding:0;}
.supplierItem .bottomLinks .btn-link.btn-profile:before{content:"...";}
.pageBottomText{font-weight: 600;font-size: 14px;line-height: 17px;position: absolute;right:5px;bottom:-35px;}
/* Supplier Select Page Content */
.suppl-select-wrapper .filterRow .filterCol {width: auto;-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.suppl-select-wrapper .filterRow .last-filterCol {-webkit-box-flex: 1;-ms-flex: 1 1 40%;flex: 1 1 40%;}
.suppl-select-wrapper .filterRow .last-filterCol .filterCol {-webkit-box-flex: 1;-ms-flex: 1 1 50%;flex: 1 1 50%;}
.suppl-select-wrapper .filterRow .first-filterCol {-webkit-box-flex: 1;-ms-flex: 1 1 40%;flex: 1 1 40%;}
.suppl-select-wrapper .filterRow .filterCol .filterLabel {min-width: auto;}
.supplier-select-boxes {margin-right: 20px;}
.supplier-select-boxes .suppliersTypeList li {margin-bottom: 25px;}
.supplier-select-boxes .suppliersTypeList li:last-child {margin-bottom: 0;}
.supplier-select-boxes .customCheckBoxLabel {padding-left: 31px;font-size: 20px;}
.supplier-select-boxes .customCheckBoxLabel .checkmark {height: 22px;width: 22px;top: -4px;}
.supplier-cont-item-wrap {background: #BFBFBF;border: 1px solid #000000;-webkit-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 10px;-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;padding: 10px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;margin-right: 20px;}
.supplier-cont-item-wrap:last-child {margin-right: 0;}
.supplier-cont-item-wrap .supplierContentList {font-weight: 600;font-size: 16px;color: #000;margin-bottom: 0;width: 100%;max-width: 280px;}
.supplier-cont-item-wrap .supplierContentList li {margin-bottom: 8px;display: -webkit-box;display: -ms-flexbox;display: flex;width: 100%;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.supplier-cont-item-wrap .supplierContentList li:last-child {margin-bottom: 0;}
.suppl-select-wrapper .supplierCertificateBlock { min-width: 130px;max-width: 130px;}
.suppl-select-wrapper .supplierDetailContent {padding-right: 15px;}
.supplierItem-wrap {justify-content: space-between}



@media only screen and (max-width:1600px){
	.logoHolder img {width: 150px;}
	.pageWrapper .pageGoBackBtn {min-width: 110px;font-size: 14px;padding: 5px 20px;}
	.filterRow .filterCol .filterLabel {font-size: 14px;line-height: 19px;}
	.appliedFilterList{font-size:14px;line-height:12px;}
	.supplierProfileItem .supplierLogoHolder {min-width: 100px;max-width: 100px;max-height: 100px;}
	.supplierProfileItem .supplierName {font-size: 18px;}
	.supplierCertificateBlock {width: 35px;}
	.ratingLogosHolder .supplierCertificateBlock {width: auto;}
	.ratingLogosHolder .supplierCertificateBlock .certificate-logo-wrap img {height: 26px;}
	.preferredSupplier {font-size: 13px;}
	.webUrlHolder {font-size: 14px;}
	.progressBarHolder .progressStep {height: 6px}
	.profileProgressHolder .completionArrow {width: 25px;height: 25px;}
	.profileProgressHolder .completionArrowCircle:before {left: 7px;top: 4px;width: 5.3px;height: 10px;}
	.supplierMainItems {font-size: 12px;line-height: normal;margin-top: 13px !important;font-weight: 600;}
	.profileTextHolder {font-weight: 500;font-size: 13px;}
	.supplierProfileItem .supplierImageHolder {max-width: 130px;max-height: 110px;min-width: 130px;}
	.supplierImagesHolder .selectOrderText .btn-link {font-size: 14px;}
	.supplier-cont-item-wrap .supplierContentList {font-size: 13px;}
	.supplier-cont-item-wrap h2 {font-size: 18px;}
	.suppl-select-wrapper .supplierCertificateBlock div img {width: 30px}
	.supplierItem .bottomLinks .btn-link {font-size: 14px;}
	.supplier-select-boxes .customCheckBoxLabel {font-size: 16px;}
	.supplier-select-boxes .customCheckBoxLabel .checkmark {height: 19px;width: 19px;}
	.supplierItem .supplierLogoHolder {min-width: 80px;max-width: 80px;max-height: 70px;}
	.supplierItem .supplierDetailHolder .supplierCertificateBlock div img {
		height: 20px;
	}
	.supplierItem .supplierName {
		font-size: 18px;
	}
	.supplierItem .bottomLinks .btn-link:last-child {margin-left: 10px!important}
	.supplierItem {width: calc(100% - 112px);max-width: calc(100% - 112px);}
}
@media only screen and (max-width:1440px){
	.suppl-select-wrapper .filterRow .last-filterCol {-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;min-width: 265px;}
	.suppl-select-wrapper .filterRow .last-filterCol .filterCol {-webkit-box-flex: 1;-ms-flex: 1 1 100%;flex: 1 1 100%;width: 100%; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end;}
	.suppl-select-wrapper .filterRow .last-filterCol .filterCol:first-child {margin-bottom: 15px;}
	.suppl-select-wrapper .filterRow .last-filterCol .list-unstyled.suppliersTypeList {width: -webkit-max-content !important;width: -moz-max-content !important;width: max-content !important;margin-left: 20px;}
	.suppl-select-wrapper .filterRow .last-filterCol .list-unstyled.suppliersTypeList li .customCheckBoxLabel {width: -webkit-max-content;width: -moz-max-content;width: max-content;}
	.suppl-select-wrapper .filterRow .first-filterCol {-webkit-box-flex: 1;-ms-flex: 1 1 55%;flex: 1 1 55%;}
}
@media only screen and (max-width:1200px){
	.filterRow .filterCol{width:50%;}
	.supplierItem .supplierName{font-size:20px;line-height:24px;margin:0 0 10px;}
	.supplierItem .starRating, .supplierItem .preferredSupplier{margin:0 0 5px;}
	.suppl-select-wrapper .filterRow .first-filterCol {-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
	.suppl-select-wrapper .filterRow .first-filterCol .filterCol {width: 100%;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: start;}
	.suppl-select-wrapper .filterRow .first-filterCol {-webkit-box-flex: 1;-ms-flex: 1 1 auto;flex: 1 1 auto;}
	.supplier-cont-item-wrap .supplierContentList {font-size: 14px;}
	.supplier-cont-item-wrap .supplier-cont-list-title {font-size: 25px;}
}
@media only screen and (max-width:1023px){
	/* .pageHeader h1{font-size: 22px;line-height: 25px;} */
}
@media only screen and (max-width:992px){
	.pageWrapper{padding:20px 10px;}
	.pageContainer{padding: 15px;margin: 0 0 20px;}
	.pageHeader .pageHeading{padding:6px 10px;}
	/* .pageHeader h1{font-size: 20px;line-height: 23px;} */
	.suppl-select-wrapper .filterRow.mb-3.d-flex.align-items-start {-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
	.suppl-select-wrapper .filterRow .first-filterCol {-webkit-box-flex: 1;-ms-flex: 1 1 100%;flex: 1 1 100%;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;width: 100%;}
	.suppl-select-wrapper .filterRow .appliedFilterList {display: -webkit-box;display: -ms-flexbox;display: flex;}
	.suppl-select-wrapper .filterRow .appliedFilterList li {padding-right: 30px;}
	.suppl-select-wrapper .filterRow .last-filterCol {-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;min-width: 100%;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;margin-top: 20px;}
	.suppl-select-wrapper .filterRow .last-filterCol .filterCol {-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
	.suppl-select-wrapper .supplierCertificateBlock {-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-top: 20px;}
	.supplierItem .supplierImageHolder {min-width: 70px;width: 70px;}
	.supplierCertificateBlock{min-width:140px;width:-webkit-max-content;width:-moz-max-content;width:max-content}
	.certificateName {display: inline-block;width: -webkit-max-content;width: -moz-max-content;width: max-content;margin-right: 9px;}
}
@media only screen and (max-width:768px){
	.filterRow .filterCol{width:100%;margin:0 0 15px;}
	.appliedFilterList li{margin:0 0 5px;}
	.filterRow .custom-select-menu.custom-select2{max-width:100%;}
	.appliedFilterList{margin-bottom:0}
	.supplierItem .supplierImageHolder{margin:0 0 15px;}
	.pageBottomText{position:relative;bottom: auto;right:auto;}
	.suppl-select-wrapper .filterRow .filterCol { margin: 0 0 0;}
	.suppl-select-wrapper .filterRow .first-filterCol {-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;margin-bottom: 10px;}
	.suppl-select-wrapper .filterRow .first-filterCol .filterCol:nth-child(2) {margin-top: 6px;}
	.supplier-select-boxes .customCheckBoxLabel {font-size: 16px;}
	.suppl-select-wrapper .supplier-cont-item-wrap {min-width: 200px;}
	.suppl-select-wrapper .supplierDetailContent .supplier-cont-item-wrap:nth-of-type(2) {min-width: 100px;}
	.supplierProfileItem .supplierLogoHolder {margin-right: 0 !important; margin-bottom: 10px}
	.supplierProfileItem .supplierImageHolder {max-width: 100px;max-height: 100px;min-width: 100px}
	.supplierItem .supplierLogoHolder {margin-right: 0 !important;margin-bottom: 10px;}
}
@media only screen and (max-width: 576px){
	.filterRow .filterCol .filterLabel{margin:0 0 7px;}
	.filterRow .custom-select-menu.text-bx-wrap{margin:0 0 7px;}
	.suppl-select-wrapper .filterRow .appliedFilterList li {padding-right: 20px;}
	.suppl-select-wrapper .filterRow .first-filterCol .filterCol:nth-child(2) {margin-top: 0;}
	.suppl-select-wrapper .filterRow .appliedFilterList {-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
	.suppl-select-wrapper .filterRow .last-filterCol .list-unstyled.suppliersTypeList {margin-left: 0;}
	.suppl-select-wrapper .supplierItem-wrap {-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;-webkit-box-align: start;-ms-flex-align: start;align-items: flex-start;margin-top: 30px;}
	.suppl-select-wrapper .supplier-select-boxes {margin-right: 0;width: 100%;}
	.suppl-select-wrapper .supplierItem {width: 100%;max-width: 100%;}
	.suppl-select-wrapper .supplier-select-boxes .suppliersTypeList {display: -webkit-box;display: -ms-flexbox;display: flex;}
	.supplier-select-boxes .suppliersTypeList li {margin-bottom: 10px;margin-right: 20px;}
	.suppl-select-wrapper .filterRow.no-margin {margin-bottom: 0 !important;}
	.appliedFilterList li {padding: 9px 0 5px 0;}
}
@media only screen and (max-width:479px){
	.pageHeader .logoHolder,
	.pageHeader .heading-holder,
	.pageHeader .mesh-control-btns{width:100%;}
	.pageHeader .logoHolder{text-align: center;margin: 0 0 10px;}
	.pageHeader .logoHolder img{display: inline-block;vertical-align: top;}
}

@media only screen and (max-width:1200px){
    .supplierProfileItem .supplierName{margin:0 0 10px;}
	.supplierProfileItem .starRating, .supplierProfileItem .preferredSupplier{margin:0 0 5px;}
	.supplierProfileItem .ratingLogosHolder .starRating {margin:0 !important;}
}
@media only screen and (max-width:768px){
    .supplierProfileItem .supplierImageHolder{margin:0 0 15px;}
    .supplierImagesHolder .selectOrderText .btn-link{padding:5px 0;}
    .pageProfileBottomText{position:relative;bottom: auto;right:auto;}
}
@media only screen and (max-width: 576px){
    .supplierProfileItem .starRating, .supplierProfileItem .preferredSupplier{margin:0 0 10px;}
	.supplierProfilePageContent .filterRow .filterLabel{margin:0 0 7px;font-size:18px;line-height:22px;}
}
`;