import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import { Wrapper } from '../../../Common/Components/CommonWrapper';
import DashboardNavbar from '../../../Common/Components/dashboardNavbar';
import { sendQuotation } from '../../../Quotations/Apicalls/quotations';
import { getRfqList } from "../../../Orders/ApiCalls/supplier";
import loader from '../../../../assets/images/Spinner-1s-200px.gif';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2'


function OrderDetail(props) {
    const [state, setState] = useState({
        isHitApi: false,
        price: 0,
        orderDetail: {},
        orderId: '',
        orderType: '',
        quotationDetailList: [],
    });
    useEffect(() => {
        if (!state.isHitApi) {
            const urlParams = new URLSearchParams(window.location.search);
            const orderId = urlParams.get('orderId');
            const orderType = urlParams.get('orderType');
            const supplierId = urlParams.get('supplierId');
            props.getRfqList(orderId, supplierId, 'order')
            let data = { ...state };
            data.isHitApi = true;
            data.orderId = orderId;
            data.orderType = orderType;
            setState(data);
        }
        if (props.apiCallStatus.apiCallFor === "getRfqList" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let quotationDetailList = []
            if (state.orderType === "store") {
                quotationDetailList = props.rfqList.reduce((r, a) => {
                    r[a.department] = [...r[a.department] || [], a];
                    return r;
                }, {});
            } else {
                quotationDetailList = props.rfqList;
            }
            setState({
                ...state,
                quotationDetailList,
                isHitApi: true
            })
            props.ApiClearAction()
        }
        if (props.apiCallStatus.apiCallFor === "sendQuotation" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            // Swal.fire('Success', "Order send succesfully", 'success');
            Swal.fire('Success', "Your order confirmation has been uploaded successfully", 'success');
            props.history.push('/open-orders')
            props.ApiClearAction()
        }
        if (props.apiCallStatus.apiCallFor === "sendQuotation" &&
            props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed) {
            Swal.fire('Error', props.apiCallStatus.message && typeof props.apiCallStatus.message === 'string' ? props.apiCallStatus.message : "Error in saving quotation", 'error');
            props.ApiClearAction()
        }
    });

    const sendQuotation = () => {
        if(Object.size(state.quotationDetailList) > 0 || state.quotationDetailList.length > 0) {
            props.sendQuotation({
                items: state.quotationDetailList,
                quotationId: props.match.params.id,
                orderId: state.orderId,
                vesselName: props.orderDetail.vesselName,
                placeOfDelivery: props.orderDetail.placeOfDelivery.label,
                arrivalTime: moment(props.orderDetail.arrivalTime).format('YYYY-MM-DD'),
                supplierName: props.orderDetail.supplier.name
            })
        }
    }
    const getNavbarOptions = () => {
        let options = [
            {
                img: 'left-arrow.svg',
                className: "orange",
                name: "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.history.goBack()
            }
        ]
        if (props.userType === 'supplier' && props.orderStatus !== 'confirmed') {
            let classType = "btn-disable";
            if (Object.size(state.quotationDetailList) > 0 || state.quotationDetailList.length > 0) {
                classType = "";
            }
            options.push({
                img: 'Edit-icon.svg',
                className: "dark-green " + classType,
                name: "Confirm<br />Order",
                path: `/`,
                isLogout: false,
                isCustom: true,
                onClick: () => sendQuotation()
            })
        }
        return options;
    }
    return (
        <Wrapper>
            <DashboardNavbar title="Order Detail" navbarOptions={getNavbarOptions()} className="mesh-board-wrap d-flex align-items-end" />
            <div className="ordCatg-table-wrap mt-3 sup-rfq-wrap">
                {/* RFQ Detials Section */}
                <div className="rfq-details-wrap ordCatg-table-wrap sup-rfq-wrap d-flex align-items-start next-oder mb-2">
                    <div className="rfq-durations rfq-details-items  descriptrfq">
                        <h2 className="mt-2 font-weight-bold">Vessel Name</h2>
                        <div className="inner-content text-left">
                            <h4 className="mt-0 font-weight-bold">Del. Place:  <span className="font-weight-bold mt-1 d-inline-block"></span></h4>
                            <h4 className="mt-0 font-weight-bold">Del. date:   <span className="font-weight-bold mt-1 d-inline-block"></span></h4>
                            <h4 className="mt-0 font-weight-bold">Agent Detail:   <span className="font-weight-bold mt-1 d-inline-block"></span></h4>
                        </div>
                    </div>
                    {Object.size(props.orderDetail) > 0 ?
                        <div className="rfq-rating rfq-details-items">
                            <h2 className="mt-2">{props.orderDetail ? props.orderDetail.vesselName : ""}</h2>
                            <h2 className="mt-2">{props.orderDetail && props.orderDetail.placeOfDelivery ? props.orderDetail.placeOfDelivery.label : ""}</h2>
                            <h2 className="mt-2">{props.orderDetail ? moment(props.orderDetail.arrivalTime).format('YYYY-MM-DD') : ""}</h2>
                            <h2 className="mt-2">{props.orderDetail ? props.orderDetail.agentDetail : ""}</h2>
                        </div> : ""}
                        <div className="rfq-durations rfq-details-items  descriptrfq ml-5">
                            <div className="inner-content text-left">
                                <h4 className="mt-0 font-weight-bold"></h4>
                                <h4 className="mt-0 font-weight-bold">Supplier Name:  </h4>
                                <h4 className="mt-0 font-weight-bold">Supplier Email: </h4>
                                <h4 className="mt-0 font-weight-bold">Supplier Website: </h4>
                            </div>
                    </div>
                    {props.orderDetail && props.orderDetail.supplier ?
                        <div className="rfq-rating rfq-details-items">
                            <h2 className="mt-2"></h2>
                            <h2 className="mt-2">{props.orderDetail.supplier.name}</h2>
                            <h2 className="mt-2">{props.orderDetail.supplier.email}</h2>
                            <h2 className="mt-2">{props.orderDetail.supplier.websiteUrl}</h2>
                        </div> : ""}
                        <div className="contact-info rfq-details-items flex-fill d-flex flex-column align-items-center mt-2">
                        {/* <div> */}
                            {/* {props.orderDetail && props.orderDetail.supplier ?
                                <ul className="list-inline p-0 m-0">
                                    <li><span className="font-weight-bold">Supplier Name:</span> {props.orderDetail.supplier.name}</li>
                                    <li><span className="font-weight-bold">Supplier Email:</span>{props.orderDetail.supplier.email}</li>
                                    <li><span className="font-weight-bold">Supplier Website:</span>{props.orderDetail.supplier.websiteUrl}</li>
                                </ul> : ""} */}
                        {/* </div> */}
                    </div>
                </div>
                {/* RFQ Detials Section End */}
                {/* Table */}
                <div className="ordCatg-InnTable store-catgTable">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-left">Item Code.</th>
                                    <th className="text-left">IMPA</th>
                                    <th className="text-left">Description</th>
                                    <th className="text-left">Quantity</th>
                                    <th className="text-left">Unit</th>
                                    <th className="text-left">Price Per Unit</th>
                                    <th className="text-left">Total Price</th>
                                    {state.orderType === "store" ?
                                        <th className="text-left">Remarks</th>
                                        : ""}
                                </tr>
                            </thead>
                            <tbody>
                                {props.apiCallStatus.isStarted.indexOf("getRfqList") !== -1 ?
                                    <tr><td colSpan="20">
                                        <div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={loader} alt='' />
                                        </div>
                                    </td></tr>
                                    : state.orderType === "store" && Object.size(state.quotationDetailList) > 0 ?
                                        Object.keys(state.quotationDetailList).map((key, i) => {
                                            return state.quotationDetailList[key].map((item, index) => {
                                                let price = item.quantity[0] && item.quantity[0].pricePerCountry.price ? item.quantity[0].pricePerCountry.price : 0;
                                                return <>
                                                    {index === 0 ?
                                                        <tr >
                                                            <td><h1 className="text-center btn-sty-title2 mb-0">{key}</h1></td>
                                                        </tr> : ""}
                                                    <tr key={index}>
                                                        <td><span className="text-center td-wrap-sty">{item.itemCode}</span></td>
                                                        <td><span className="td-wrap-sty text-center">{item.alternativeCode}</span></td>
                                                        <td><span className="text-left td-wrap-sty">{item.description}</span></td>
                                                        <td><span className="text-center td-wrap-sty">{item.order}</span></td>
                                                        <td><span className="td-wrap-sty text-center">{item.standardPackagingUnit}</span></td>
                                                        <td><span className="td-wrap-sty text-right">{(parseFloat(price)).toFixed(2)}</span></td>
                                                        <td><span className="td-wrap-sty text-right">{(parseFloat(item.order * price)).toFixed(2)}</span></td>
                                                        <td><span className="td-wrap-sty text-right">{item.remark}</span></td>
                                                    </tr></>
                                            })
                                        }) : state.orderType === "provision" && state.quotationDetailList && state.quotationDetailList.length > 0 ?
                                            state.quotationDetailList.map((item, index) => {
                                                let price = item.quantity[0] && item.quantity[0].pricePerCountry.price ? item.quantity[0].pricePerCountry.price : 0;
                                                return <tr key={index}>
                                                    <td><span className="text-center td-wrap-sty">{item.itemCode}</span></td>
                                                    <td><span className="td-wrap-sty text-center">{item.alternativeCode}</span></td>
                                                    <td><span className="text-left td-wrap-sty">{item.description}</span></td>
                                                    <td><span className="text-center td-wrap-sty">{item.order}</span></td>
                                                    <td><span className="td-wrap-sty text-center">{item.standardPackagingUnit}</span></td>
                                                    <td><span className="td-wrap-sty text-right">{price !== 0 ? parseFloat(price).toFixed(2) : 0}</span></td>
                                                    <td><span className="td-wrap-sty text-right">{parseFloat(item.order * price).toFixed(2)}</span></td>
                                                </tr>

                                            }) : state.quotationDetailList && state.quotationDetailList.length === 0 ?
                                                <tr><td colSpan="40" className="text-center">No Data Found</td></tr> : ''}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    quotationReducer: state.quotationReducer,
    rfqList: state.orderReducer.rfqList,
    orderStatus: state.orderReducer.orderStatus,
    orderDetail: state.orderReducer.orderDetail,
    userId: state.authReducer.userId,
    userType: state.authReducer.userType
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    sendQuotation: (data) => dispatch(sendQuotation(data)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    getRfqList: (orderId, supplierId, type) => dispatch(getRfqList(orderId, supplierId, type)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderDetail);