export const getUser = "GET_USER";
export const getUserDetail = "GET_USER_DETAIL";
export const appendUserList="APPEND_USER_LIST";
export const roleAssignment="ROLE_ASSIGNMENT";
export function GetUserAction(list,count) {
    return {
      type: getUser,
      payload: { list,count },
    };
  }
  export function AppendUserAction(list) {
    return {
      type: appendUserList,
      payload: { list },
    };
  }
  export function GetUserDetailAction(detail) {
    return {
      type: getUserDetail,
      payload: { detail },
    };
  }
  export function roleAssignmentAction(data) {
    return {
      type: roleAssignment,
      payload: { data },
    };
  }
  