import React, { useState, useEffect } from 'react';
import { getDashboardLink } from '../../../../Services/common';
import { Wrapper, DashboardNavbar, CsvDownload } from '../../../Common/Components/index';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApiClearAction, ClearApiByNameAction } from "../../../ApiCallStatus/Actions/action";
import { quotationListing } from "../../../Quotations/Apicalls/quotations";
import { getCustomerOrderDetail, getDocumentsList, uploadDocuments, approveDoucment } from "../../ApiCalls/supplier";
import { CustomerDetailModal, UploadDocModal } from "./model";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from '../../../../assets/images/Spinner-1s-200px.gif';
import moment from 'moment';
import Swal from 'sweetalert2'
import $ from 'jquery';

const headers = [
    { label: 'Order Id', key: '_id' },
    { label: 'Order Type', key: 'type' },
    { label: 'Status', key: 'status' },
    { label: 'Created At', key: 'createdAt' },
];
var csvLinkRef = React.createRef();
function InProgressOrderList(props) {
    const [state, setState] = useState({
        isHitApi: false,
        qoutationList: [],
        totalQuotation: 0,
        isCsVDownload: false,
        csvData: [],
        activePage: 0,
        limit: 20,
        selectedOrder: "",
        query: '?status=inprogress'
    });
    useEffect(() => {
        if (!state.isHitApi) {
            props.quotationListing(0, 20, 'order')
            let data = { ...state };
            data.isHitApi = true;
            setState(data);
        }
        if ((props.apiCallStatus.apiCallFor === "getQuotation" || props.apiCallStatus.apiCallFor === "appendQuotationList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            if (state.isCsVDownload) {
                csvLinkRef.current.link.click()
            }
            let csvData = [];
            for (let index = 0; index < props.quotationList.length; index++) {
                const element = props.quotationList[index];
                let status = element.isSupplied && element.status === "confirmed" ? "Supplied" : element.status[0].toUpperCase() + element.status.slice(1)
                let csvObj = {
                    type: element.orderProvisionType[0].toUpperCase() + element.orderProvisionType.slice(1),
                    _id: element._id,
                    status: status,
                    createdAt: moment(element.createdAt).format("DD MMMM YYYY hh:mm:ss"),
                }
                csvData.push(csvObj)
            }
            setState({
                ...state,
                qoutationList: props.quotationList,
                totalQuotation: props.totalQuotation,
                csvData,
                isCsVDownload: false,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if ((props.apiCallStatus.apiCallFor === "uploadDocuments") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                selectedOrder: ""
            })
            $("#deliveryNote").val(null);
            $("#invoice").val(null);
            $("#uploadDocumentModal").modal("hide");
            Swal.fire('Success', "Your documents has been uploaded successfully. Please wait for the approval.", 'success')
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        } else if ((props.apiCallStatus.apiCallFor === "approveDoucment") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                selectedOrder: "",
                activePage: 0,
            })
            Swal.fire('Success', "Documents has been approved successfully.", 'success')
            $("#uploadDocumentModal").modal("hide");
            props.quotationListing(0, 20, 'order');
        }
    });
    const fetchMoreData = () => {
        let data = { ...state };
        data.activePage += 1;
        props.quotationListing(data.activePage, 20, 'order')

        setState(data);
    };
    const showCustomerDetailModal = (orderId, userId) => {
        $("#customerDetailModal").modal("show");
        props.getCustomerOrderDetail(orderId, userId);
    }
    const showDocDettailModal = (orderId) => {
        setState({
            ...state,
            selectedOrder: orderId
        })
        $("#uploadDocumentModal").modal("show");
        props.getDocumentsList(orderId);
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
        {
            img: 'Monitor-icon.svg',
            className: "dark-blue",
            name: "Dash<br />board",
            path: getDashboardLink(),
            isLogout: false
        }
    ];
    const apiCallForCSVData = () => {
        /**
         * Requesting csv files data
         */
        props.quotationListing(0, state.totalQuotation, 'order')
        setState({
            ...state,
            isCsVDownload: true
        })

    }
    return (
        <Wrapper>
            <DashboardNavbar title="Orders" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="ordCatg-table-wrap mt-3 system-cat-filter">
                <div className="card-bx-sty">
                    <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                        <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                            <CsvDownload data={state.csvData} csvLinkRef={csvLinkRef} filename={"Current Orders.csv"} headers={headers} apiCallForCSVData={() => apiCallForCSVData()} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Order Status Content */}
            <div className="row mt-lg-4 align-content-stretch newDash-contentStyle">
                <div className="col-12">
                    <div className="content-box-wrap dash-content-bx">
                        <div className="flex-fil text-left table-default-sty">
                            <div className="table-responsive">
                                <InfiniteScroll
                                    dataLength={state.qoutationList.length}
                                    next={fetchMoreData}
                                    height={"80vh"}
                                    hasMore={state.totalQuotation <= state.qoutationList.length ? false : true}
                                    loader={<div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div>}
                                >
                                    <table className="table mt-4 table-light1 bg-transparent with-action-btns">
                                        <thead className="rounded-dark-head">
                                            <tr>
                                                <th>#</th>
                                                <th>Id</th>
                                                <th className="text-left">Order Type</th>
                                                <th className="text-left">Created At</th>
                                                <th>Status</th>
                                                <th>Actions&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody className="spacer">
                                            {props.apiCallStatus.isStarted.indexOf("getQuotation") !== -1 ?
                                                <tr><td colSpan="20">
                                                    <div className="loader-img text-center">
                                                        <img style={{ width: "46px" }} src={loader} alt='' />
                                                    </div>
                                                </td></tr>
                                                : state.qoutationList && state.qoutationList.length > 0 ? state.qoutationList.map((item, index) => {
                                                    let status = item.isSupplied && item.status === "confirmed" ? "Supplied" : item.status[0].toUpperCase() + item.status.slice(1)
                                                    return <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item._id}</td>
                                                        <td className="semi-bold text-left">{item.orderProvisionType[0].toUpperCase() + item.orderProvisionType.slice(1)}</td>
                                                        <td className="semi-bold text-left">{moment(item.createdAt).format("DD MMMM YYYY hh:mm:ss")}</td>
                                                        <td className="semi-bold text-left">{status}</td>
                                                        <td className="text-right">
                                                            <div className="tb-buttons-wrap">
                                                                {item.status === "confirmed" ?
                                                                    <button type="button" onClick={() => showDocDettailModal(item.orderId)} className="btn tb-btn-rounded orng mr-2">{props.userType === "supplier" ? "Upload Document" : "View Document"}</button>
                                                                    : ""}
                                                                {props.userType === "supplier" ? ""
                                                                    // <button type="button" onClick={() => showCustomerDetailModal(item.orderId, item.userId)} className="btn tb-btn-rounded orng mr-2">View Customer Detail</button>
                                                                    : props.userType === "customer" ? <Link to={"/supplier-profile/" + item.supplierId} className="btn tb-btn-rounded orng mr-2">View Supplier Detail</Link> : ""}
                                                                {/* <button type="button" className="btn tb-btn-rounded red mr-2">Cancel</button> */}
                                                                <Link to={"/order-detail/" + item._id + `?orderId=${item.orderId}&orderType=${item.orderProvisionType}&supplierId=${item.supplierId._id}`} className={"btn tb-btn-rounded " + (item.status === "confirmed" ? "green " : "orng ")}>{props.userType === 'supplier' ? "View Order" : "View"}</Link>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                }) : state.qoutationList && state.qoutationList.length === 0 ?
                                                        <tr><td colSpan="40" className="text-center"><div>No Data Found</div></td></tr> : ''}
                                        </tbody>
                                    </table>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {props.userType === "supplier" ?
                <CustomerDetailModal customerDetail={props.customerDetail} />
                : ""}
            <UploadDocModal userType={props.userType} isSubAdmin={props.isSubAdmin} approveDoucment={props.approveDoucment} uploadDocuments={props.uploadDocuments} documentList={props.documentList} apiCallStatus={props.apiCallStatus} orderId={state.selectedOrder} userId={props.userId} />
            {/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
        </Wrapper>
    )
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    quotationList: state.quotationReducer.quotationList,
    totalQuotation: state.quotationReducer.totalQuotation,
    userType: state.authReducer.userType,
    isSubAdmin: state.authReducer.isSubAdmin,
    userId: state.authReducer.userId,
    documentList: state.orderReducer.documentList,
    customerDetail: state.orderReducer.customerDetail
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ApiClearAction: () => dispatch(ApiClearAction()),
    getDocumentsList: (userId) => dispatch(getDocumentsList(userId)),
    approveDoucment: (data) => dispatch(approveDoucment(data)),
    uploadDocuments: (data) => dispatch(uploadDocuments(data)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    quotationListing: (offset, limit, type) => dispatch(quotationListing(offset, limit, type)),
    getCustomerOrderDetail: (orderId, userId) => dispatch(getCustomerOrderDetail(orderId, userId))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InProgressOrderList);