import React from "react";
export function DepartmentForm(props) {
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            props.handleSubmit();
        }
    }
    return (
        <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="row">
                <div className="col-sm-6">

                    <div className="form-group floating-label-wrap">
                        <input type="text" name="name" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Department Name"
                            value={props.name} required />
                            <lable className="foating-labels-sty">Department Name</lable>

                    </div>
                </div>

                {/* <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="number" name="budgetPrice" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Budget Price"
                            value={props.budgetPrice} required />
                            <lable className="foating-labels-sty">Budget Price</lable>
                    </div>
                </div> */}
            </div>
            {props.apiCallStatus.apiCallFor === props.apiName && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                <div className="alert alert-danger">
                    {props.apiCallStatus.message}
                </div>
                : ""}
            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {props.apiCallStatus.apiCallFor === props.apiName && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">{props.isEdit ? "Update" : "Submit"}</button>
                </div>
            </div>
        </form>
    )
}