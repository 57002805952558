import styled from "styled-components";

export const ComparisonWrapper = styled.div`
/* Comparison Details Style */
.diff-details-wrap .rfq-details-items {
    padding: 10px 10px;
    width: 240px;
    min-width: 240px;
    text-align: center;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    margin-right: 20px;
    box-shadow: 0 0 8px #0000001f;
} 
.diff-details-wrap .rfq-details-items:last-child {
    margin-right: 10px;
}
.diff-details-wrap .rfq-details-items .btn.themeReguler-btn {
    width: 100%;
    max-width: 100%;
    font-size: 11px;
    padding: 5px 20px;
}
.diff-details-wrap .customCheckBoxLabel {
    margin-bottom: 0;
    width: 11px;
    height: 11px;
    padding-left: 17px;
}
.diff-details-wrap .customCheckBoxLabel .checkmark {
    border: 1px solid #9e9e9e;
    background-color: #e9e9e9;
    border-radius: 3px;
    box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 17%);
    border-radius: 50%;
    height: 11px;
    width: 11px;
}
.diff-details-wrap .customCheckBoxLabel input:checked ~ .checkmark {
    background-color: #ffffff;
    border-color: #E91E63;
}
.diff-details-wrap .next-process-btns .btn.themeReguler-btn {
    min-width: max-content;
    width: calc(50% - 5px);
    font-size: 10px;
    padding: 4px 20px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
.rfq-details-items {
    padding: 0 15px 
}
.rfq-durations.rfq-details-items {
    margin-right: 20px
}
.rfq-details-items h2 {
    font-size: 16px;
    font-weight: 400;
}
.rfq-details-items .inner-content h4 {
    font-size: 13px;
    font-weight: 400;
    margin-top: 15px;
    max-width: max-content;
}
.rfq-details-items .inner-content h4 span {
    font-size: 12px;
}
.rfq-rating.rfq-details-items .inner-content h4 {
    margin-top: 25px;
}
.star-checkbox {
    display: none;
}
.rating-checks label {
    color: #dad9d9;
    margin: 0;
    font-size: 13px;
    position: sticky;
}
.star-checkbox:checked + label {
    color: #fff;
}
.rfq-rating.rfq-details-items .rounded-tag{
    font-size: 9px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #559709;
    border-radius: 40px;
    color: #fff;
    padding: 4px 11px;
}
.rfq-rating.rfq-details-items .rounded-tag.orng-clr {
    background: #E6A935;
}
.rfq-rating li {
    padding: 0 2px;
}
.rfq-rating .rounded-tag.progress-bar-wrap {
    width: 90px;
    background: #00539CFF!important;
    box-shadow: 0 0 0 1px #b7b7b7;
    padding: 0
}
.rfq-rating .rounded-tag.progress-bar-wrap .rounded-tag{
   width: 85%
}
.diff-details-wrap .rfq-details-items p{
    font-size: 11px;
    text-align: left;
    color: #6b6b6b;
}
.contact-info.rfq-details-items ul li {
    font-size: 14px;
    color: #4c4c4c;
    margin-bottom: 5px
}
.contact-info.rfq-details-items ul li:last-child {
    margin-bottom: 0
}
.contact-info.rfq-details-items ul li span {
    display: inline-block;
    margin-right: 10px
}
.total-value-count {
    font-size: 13px;
    text-align: right;
}
.compar-table-items .compar-table-item {
    padding: 10px 10px;
    width: 240px;
    min-width: 240px;
    text-align: center;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    margin-right: 20px;
    box-shadow: 0 0 8px #0000001f;
}
.compar-table-items .compar-table-item:last-child {
    margin-right: 10px;
}
.compar-table-items .compar-table-item .table-title-wrap {
    font-size: 12px;
    padding: 10px 12px;
    font-weight: 600;
    background: #f2f2f2;
    margin-bottom: 0;
    border-bottom: 1px solid #E91E63;
    text-align: left;
}
.compar-table-items .compar-table-item .table-title-wrap h2 {
    font-size: 12px;
    font-weight: 600;
}
.compar-table-items .compar-table-item .table-responsive {
    height: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.compar-table-items.collapse-content .compar-table-item .table-responsive {
    height: auto;
    margin-top: 5px;
}
.compar-table-items .theme-table-wrap2 thead th {
    font-size: 10px;
    padding: 10px 12px;
    white-space: nowrap;
    color: #656565;
}
.compar-table-items .compar-table-item .btn.btn-link {
    padding: 0 !important;
    background: transparent !important;
    border: 8px solid transparent;
    border-left-color: #5e5e5e ;
    margin-right: 10px;
    transition: unset;
    -webkit-transition: unset;
    -moz-transition: unset;
    -ms-transition: unset;
    -o-transition: unset;
}
.compar-table-items.collapse-content .compar-table-item .btn.btn-link {
    border-top-color: #5e5e5e ;
    border-left-color: transparent;
    border-bottom: unset;
}
.supplier-comparison-wrap .ordCatg-table-wrap.sup-rfq-wrap {
    overflow: auto;
}
.comp-first-row, .comp-second-row {
    min-width: 1035px;
}
.price-text{margin-right:5px;vertical-align: revert;}
.text-inner-pop .request-price h3 {color:red !important; background:yellow;}
.compar-table-items .compar-table-item{white-space: nowrap;}
`;