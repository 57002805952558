import styled from "styled-components";

export const MarketDashWrap = styled.div`
.market-content-row .content-box-wrap{height:auto;min-height:300px}
.market-content-row .content-box-wrap.stores-select-wrap{max-width:400px;margin-right:20px}
.market-inner-row .dashOrder-buttons-wrap{background:#979797;border:2px solid #4A4A4A;-webkit-box-shadow:0 4px 4px rgba(0,0,0,0.25);box-shadow:0 4px 4px rgba(0,0,0,0.25);border-radius:10px;padding:13px;width:250px;min-width:250px;margin-top:0}
.market-inner-row .dashOrder-buttons-wrap .themeReguler-btn{-webkit-box-flex:unset;-ms-flex:unset;flex:unset}
.themeReguler-btn{-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;width:100%;text-align:center;min-width:auto;flex:1 1 auto;max-width:unset}
.mark-brand-tags-list .m-tags-items{display:inline-block;margin:10px}
.chat-img-wrap{width:100%;height:100%;overflow:hidden;border-radius:9px;position:relative;-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto}
.chat-img-wrap img{width:100%;height:100%;-o-object-position:top;object-position:top;position:absolute;top:0;left:0}
.chat-loc-wrap .form-group label{font-size:14px;color:#4A4A4A;font-weight:600;margin-bottom:0}
.chat-loc-wrap .form-group input{border:2px solid #4A4A4A;border-radius:5px;height:29px;max-width:240px;font-size:14px;color:#4A4A4A;font-weight:500;outline:none!important;-webkit-box-shadow:none!important;box-shadow:none!important;overflow:hidden;-o-text-overflow:ellipsis;text-overflow:ellipsis}
hr.thin-hr{width:9ss0%;border-top:1px solid #4A4A4A}
.chat-search-loc-ctn{border-radius:10px;background:#294561;padding:3px;width:400px;min-width:400px}
.contact-proposal .form-group .themeReguler-btn{-webkit-box-flex:unset;-ms-flex:unset;flex:unset;padding:6px 20px;margin-bottom:0;margin-left:10px;font-size:14px;width:-webkit-max-content;width:-moz-max-content;width:max-content}
.market-content-row.chat-content-row{margin-top:20px}

// responsive Qurries
@media (max-width: 1600px) {
    .mark-brand-tags-list .m-tags-items img {max-height: 50px}
     .chat-loc-wrap .form-group label {font-size: 12px;}
     .chat-loc-wrap .form-group input {height: 27px;font-size: 11px;padding: 4px 12px;}
     .contact-proposal .form-group .themeReguler-btn {padding: 5px 15px;font-size: 11px;}
}
@media (max-width: 1400px) {
    .market-content-row{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}
    .market-content-row .content-box-wrap.stores-select-wrap{max-width:100%;margin-right:0;margin-bottom:10px}
    .market-content-row.chat-content-row{margin-top:10px}
    .chat-search-loc-ctn{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;min-width:100%}
    .content-box-head.chat-search-loc-ctn{margin-top:10px}
    .content-box-head.chat-search-loc-ctn .content-box-wrap.stores-select-wrap:first-child{margin-right:10px!important}
    .mark-brand-tags-list .m-tags-items{display:inline-block;margin:5px}
    .content-box-wrap.dash-content-bx.mar-chat-box{margin-right:0!important}
}
@media (max-width:768px) {
    .dashOrder-buttons-wrap.d-flex.flex-fill.market-inner-row{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}
    .market-inner-row .dashOrder-buttons-wrap{width:100%;min-width:100%;margin-top:10px}
    .content-box-head.chat-search-loc-ctn{margin-top:10px;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}
    .content-box-head.chat-search-loc-ctn .content-box-wrap.stores-select-wrap:first-child{margin-right:0!important}
    .mark-brand-tags-list .m-tags-items img{max-height:50px}
}
`
