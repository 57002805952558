export const getCountry = "GET_COUNTRY";
export const getCountryDetail = "GET_COUNTRY_DETAIL";
export const AppendCountryList="APPEND_COUNTRY_LIST";
export function GetCountryAction(list, count) {
  return {
    type: getCountry,
    payload: { list, count },
  };
}
export function AppendCountryAction(list) {
  return {
    type: AppendCountryList,
    payload: { list },
  };
}
export function GetCountryDetailAction(detail) {
  return {
    type: getCountryDetail,
    payload: { detail },
  };
}