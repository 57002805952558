import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Wrapper, DashboardNavbar } from '../../Common/Components/index';
import { getOrderCount } from '../../Common/ApiCalls/getAllRoles';
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import { isAllowPermission } from '../../../Services/common';
import './dashboard-style.css';

const navbarOptions = [
    {
        img: 'Cancel.svg',
        className: "red",
        name: "Logout",
        path: "/",
        isLogout: true
    },
    // {
    //     img: 'Dashboard-icon.svg',
    //     className: "dark-green",
    //     name: "upgrade",
    //     path: "/UpgradeClient",
    //     isLogout: false
    // }
];

function UserDashboard(props) {
    const [state, setState] = useState({
        isHitApi: false,
        title: "",
        activeVesselId: "",
        count: 0,
        isProvisionDisable: false,
        isStoreDisable: false,
    });
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.userDetail.roles)) {
                props.history.push('/not-allowed')
            } else {
                let data = { ...state };
                let title = 'Admin Panel';
                if (props.isSubAdmin) {
                    title = props.roleName + ' - Dashboard';
                } else if (props.userType === 'customer') {
                    title = 'Customer Dashboard'
                } else if (props.userType === 'supplier') {
                    title = 'Supplier Dashboard'
                }
                let permission = props.userDetail.permission;
                let orderType = permission.department.length === 0 ? 'provision' : 'store';
                let department = permission.department.length !== 0 ? permission.department[0] : permission.provisionOrder[0];
                let vesselId = props.userDetail.activeVesselId;
                let userId = props.userDetail.userId;
                props.getOrderCount(userId, vesselId, department, orderType);
                data.isHitApi = true;
                data.title = title;
                data.activeVesselId = props.userDetail.activeVesselId
                data.isProvisionDisable = props.userDetail.isSubAdmin && props.userDetail.subType !== 'sub-admin' && props.userDetail.permission && props.userDetail.permission.provisionOrder && props.userDetail.permission.provisionOrder.length === 0
                data.isStoreDisable = props.userDetail.isSubAdmin && props.userDetail.subType !== 'sub-admin' && props.userDetail.permission && props.userDetail.permission.department && props.userDetail.permission.department.length === 0
                setState(data);
            }
        }
        if (props.apiCallStatus.apiCallFor === "getOrderCount" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let count = props.orderCount;
            setState({
                ...state,
                count
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
    }, [props, state.isHitApi])

    return (
        <Wrapper>
            <DashboardNavbar title={(props.userDetail.isSubAdmin && props.userDetail.userType === 'user' && props.userDetail.subType !== 'sub-admin') ? `${props.userDetail.roleName} Dashboard` : props.userDetail.userType === 'supplier' ? 'Supplier Dashboard' : 'Admin Panel'} navbarOptions={navbarOptions} isShowLogo={true} logoUrl="supplier-logo.png" logoType="Supplier" className="mesh-board-wrap d-flex align-items-end" />
            <div className="mt-lg-5 d-flex contract-dash-wrap">
                <div className="dashNew-ord-wrap d-flex flex-column align-items-start m-auto overWrap-ctrl user-dash-wrap">
                    {/* SUPPLIER MESH */}
                    <div className="content-box-wrap dash-content-bx d-flex flex-column">
                        {props.apiCallStatus.isStarted.indexOf("getOrderCount") !== -1 ?
                            <div className="loader-img text-center">
                                <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                            </div> :
                            state.count === 0 ? <>
                                <div className="content-box-head ord-status-head d-flex align-items-center justify-content-between">
                                    <h2 className="font-weight-bold">MESH* YOUR NEW ORDER:</h2>
                                </div>
                                <div className="dashOrder-buttons-wrap d-flex flex-column justify-content-center align-items-center flex-fill">
                                    <Link to={state.isProvisionDisable ? '#' : "/create-provision-order/" + state.activeVesselId} className={`btn themeReguler-btn bg-dark-green ${state.isProvisionDisable ? "not-allowed btn-disable" : ""}`} >PROVISION ORDER</Link>
                                    <Link to={state.isStoreDisable ? '#' : "/create-store-order/" + state.activeVesselId} type="button" className={`btn themeReguler-btn bg-dark-blue ${state.isStoreDisable ? "not-allowed btn-disable" : ""}`}>STORE ORDER</Link>
                                </div>
                            </> :
                                <div className="dashOrder-buttons-wrap d-flex flex-column justify-content-center align-items-center flex-fill">
                                    <Link to={'#'} className={`btn themeReguler-btn bg-dark-green `} >Order added into the basket.</Link>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </Wrapper >
    )
}

const mapStateToProps = (state, ownProps) => ({
    orderCount: state.commonReducer.orderCount,
    apiCallStatus: state.apicallStatusReducer,
    userDetail: {
        activeVesselId: state.authReducer.activeVesselId,
        roles: state.authReducer.roles,
        permission: state.authReducer.permission,
        activeVesselId: state.authReducer.activeVesselId,
        isSubAdmin: state.authReducer.isSubAdmin,
        roleName: state.authReducer.roleName,
        subType: state.authReducer.subType,
        userType: state.authReducer.userType,
        userId: state.authReducer.userId,
    }

})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    getOrderCount: (userId, vesselId, department, orderType) => dispatch(getOrderCount(userId, vesselId, department, orderType)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserDashboard);
