import React, { Component } from 'react';
import { Wrapper } from '../../../Css/previsionCatalouge';
import DashboardNavbar from '../../../../Common/Components/dashboardNavbar';
import { CatalougeTable } from './catalougeTable';
import { Filters } from './filters';
import { OrderDetailModal } from './models';
import { OrderOverView } from './orderOverview';
import cloneDeep from 'lodash.clonedeep';
import { provisionListing, getFilterDetail, updateOrder, getOrderDetail } from './../../../ApiCalls/provisionOrder';
import { getItemCode, getItemGroup, getDescription } from './../../../ApiCalls/searchProvisionOrder';
import { ApiClearAction } from "../../../../ApiCallStatus/Actions/action";
import { AddOrderAction } from './../../../Actions/action';
import Swal from 'sweetalert2'
import { connect } from 'react-redux';
import { getDashboardLink, Sum } from '../../../../../Services/common';
import $ from 'jquery';

const calculateTotalUsedPoint = (data, budget) => {
	let keys = Object.keys(data);
	let total = 0;
	let catOrderDetail = {};
	for (let index = 0; index < keys.length; index++) {
		const element = keys[index];
		let orderType = data[element].orderType;
		let order = parseFloat(data[element].order);
		if (!isNaN(order)) {
			if (catOrderDetail[orderType]) {
				catOrderDetail[orderType].count += order;
				catOrderDetail[orderType].totalPrice += parseFloat((parseFloat(data[element].pricePerBaseUnit) * order).toFixed(2))
			} else {
				catOrderDetail[orderType] = { name: orderType, count: order, totalPrice: parseFloat((parseFloat(data[element].pricePerBaseUnit) * order).toFixed(2)) };
			}
			if (data[element] && order !== 0) {
				if (data[element].pricePerBaseUnit !== 0) {
					let totalPrice = parseFloat((parseFloat(data[element].pricePerBaseUnit) * order).toFixed(2))
					total += totalPrice;
				}
			}
		}
	}
	let percentage = (total / budget) * 100;
	return { total: total.toFixed(2), percentage: percentage.toFixed(0), catOrderDetail };
}
let filterInputTimeout = null;
class StoreCatalogue extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOption: null,
			catalougeList: [],
			filterCatalouge: [],
			currentOrder: {},
			isOrder: true,
			orderDetail: {},
			deptBudget: 0,
			itemGroupList: [],
			cloneItemGroupList: [],
			itemNoList: [{ label: "1101", value: "1101" },
			{ label: "1102", value: "1102" },
			{ label: "1103", value: "1103" },
			{ label: "1104", value: "1104" },
			{ label: "1105", value: "1105" },
			{ label: "1106", value: "1106" },
			{ label: "1107", value: "1107" },
			{ label: "1108", value: "1108" },
			{ label: "1109", value: "1109" }],
			filters: {
				itemGroup: "",
				inputItemGroup: "",
				itemNo: "",
				inputItemNo: "",
				inputDescription: "",
				description: "",
				isApplied: false
			},
			descriptionList: [],
			totalDescription: 0,
			descriptionPage: 0,
			showOrderDetailModel: false,
			totalOrder: 0,
			filterQuery: '?systemType=' + (this.props.currentOrder.department === "Additional" ? "a" : "s"),
			totalCount: 0,
			totalItemCode: 0,
			activePage: 0,
			itemCodePage: 0,
			availability: this.props.currentOrder.department === "Additional" ? "a" : "s",
			catOrderDetail: [],
			isSaveAndClose: false,
			captainId: "",
			showOrderedList: false
		};
	}
	componentDidMount() {
		if (this.props.currentOrder && this.props.currentOrder.numberOfCrew && this.props.currentOrder.orderPeriod) {
			this.calculateAndUpdateOrderDetail();
		} else {
			this.props.getOrderDetail(this.props.match.params.orderId)
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (Object.keys(this.props.currentOrder).length !== 0 && Object.keys(this.state.currentOrder).length === 0 && this.state.isOrder) {
			this.calculateAndUpdateOrderDetail();
		}
		if (((prevProps.apiCallStatus.isStarted.indexOf('getProvision') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getProvision') === -1) || (prevProps.apiCallStatus.isStarted.indexOf('appendProvisionList') !== -1 &&
				this.props.apiCallStatus.isStarted.indexOf('appendProvisionList') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			this.setState({
				...this.state,
				catalougeList: this.props.orderReducer.provisionList,
				filterCatalouge: this.props.orderReducer.provisionList,
				totalCount: this.props.orderReducer.totalProvision,
			})
			if (this.props.apiCallStatus.apiCallFor === "getProvision") {
				this.disableScroll();
			}
			setTimeout(() => {
				$("input[type=number]").on("focus", function () {
					$(this).on("keydown", function (event) {
						if (event.keyCode === 38 || event.keyCode === 40 || event.key === "-" || event.key === ".") {
							event.preventDefault();
						}
					});
				});
			}, 500);
		}
		if (((prevProps.apiCallStatus.isStarted.indexOf('getItemCode') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getItemCode') === -1) || (prevProps.apiCallStatus.isStarted.indexOf('appendItemCode') !== -1 &&
				this.props.apiCallStatus.isStarted.indexOf('appendItemCode') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			let newArr = [];
			let arr = this.props.orderReducer.itemCodeList;
			for (let i = 0; i < arr.length; i++) {
				newArr.push({ value: arr[i].alternativeCode, label: arr[i].alternativeCode });
			}
			this.setState({
				...this.state,
				itemNoList: newArr,
				totalItemCode: this.props.orderReducer.totalItemCode,
			})
		}
		if (((prevProps.apiCallStatus.isStarted.indexOf('getDescription') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getDescription') === -1) || (prevProps.apiCallStatus.isStarted.indexOf('appendDescription') !== -1 &&
				this.props.apiCallStatus.isStarted.indexOf('appendDescription') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			let newArr = [];
			let arr = this.props.orderReducer.descriptionList;
			for (let i = 0; i < arr.length; i++) {
				newArr.push({ value: arr[i].description, label: arr[i].description });
			}
			this.setState({
				...this.state,
				descriptionList: newArr,
				totalDescription: this.props.orderReducer.totalDescription,
			})
		}

		if ((prevProps.apiCallStatus.isStarted.indexOf('getItemGroup') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getItemGroup') === -1) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			let newArr = [];
			let arr = this.props.orderReducer.itemGroupList;
			for (let i = 0; i < arr.length; i++) {
				newArr.push({ value: arr[i], label: arr[i] });
			}
			this.setState({
				...this.state,
				cloneItemGroupList: newArr,
				itemGroupList: newArr,
			})
		}
		if ((prevProps.apiCallStatus.isStarted.indexOf('updateOrder') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('updateOrder') === -1) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			this.props.AddOrderAction({})
			if (this.state.isSaveAndClose) {
				this.props.history.push(getDashboardLink())
			} else {
				let vesselId = this.props.currentOrder.vessel;
				let url = getDashboardLink();
				if (this.props.isSubAdmin && this.props.userType === 'user' && this.props.subType === 'captain') {
					url = `/order-basket?vesselId=${vesselId}`;
				}
				let msg = 'Your order has been added into the basket successfully.';
				if (this.props.userType === "user" && !this.props.isSubAdmin) {
					msg = 'Your order was added to the order basket.';
				}
				Swal.fire({
					title: msg,
					icon: 'success',
					confirmButtonColor: '#3085d6',
					allowOutsideClick: false,
					confirmButtonText: 'Ok'
				}).then((result) => {
					if (result.isConfirmed) {
						this.props.history.push(url)

					}
				})
				this.props.ApiClearAction();
			}
		}
	}
	calculateAndUpdateOrderDetail = () => {
		let availability = this.props.currentOrder.department === "Additional" ? "a" : "s";
		let filterQuery = '?systemType=' + availability;
		let query = '?availability=' + availability;
		let deptBudget = ((parseFloat(this.props.currentOrder.deptBudget) * Sum(this.props.currentOrder.orderPeriod.regular, this.props.currentOrder.orderPeriod.expectedDelay)) / 365).toFixed(2);
		let captainId = this.props.currentOrder.captain;
		this.setState({
			orderDetail: this.props.currentOrder && this.props.currentOrder.orderDetail ? this.props.currentOrder.orderDetail : {},
			availability,
			filterQuery,
			currentOrder: this.props.currentOrder,
			isOrder: false,
			deptBudget: parseFloat(deptBudget),
			captainId
		})
		this.props.provisionListing(0, 200, filterQuery)
		this.props.getItemCode(0, query);
		this.props.getDescription(0, query);
		this.props.getItemGroup(query)
	}
	handleFilterChange = (id, val) => {
		let filters = { ...this.state.filters };
		let obj = {}
		filters[id] = val;
		obj.filters = filters;
		let th = this;
		clearTimeout(filterInputTimeout);
		let query = "?availability=" + this.state.availability;
		if (id === 'inputItemNo') {
			obj.itemCodePage = 0;
			if (val !== '') {
				query = query + '&alternativeCode2=' + val;
			}
		} else if (id === 'inputDescription') {
			obj.descriptionPage = 0;
			if (val !== '') {
				query = query + '&description=' + val;
			}
		} else if (id === "inputItemGroup") {
			let arr = this.state.cloneItemGroupList.filter((item) => {
				let trimVal = val.toLowerCase();
				let selectedVal = item.value.toLowerCase();
				return selectedVal.indexOf(trimVal) !== -1
			});

			obj.itemGroupList = arr;
		}
		// Make a new timeout set to go off in 1000ms (1 second)
		filterInputTimeout = setTimeout(function () {
			if (id === 'inputItemNo') {
				th.props.getItemCode(0, query)
			} else if (id === 'inputDescription') {
				th.props.getDescription(0, query)
			}
		}, 500);
		this.setState(obj);
	};
	clickCustomeSelectHandler = (id, val) => {
		let filters = { ...this.state.filters };
		let obj = {}
		filters[id] = val;
		// let query = "?availability=" + this.state.availability;
		if (id === 'itemNo') {
			filters.inputItemNo = val;
			obj.itemCodePage = 0;
			// if (val !== '') {
			// 	query = query + '&alternativeCode2=' + val;
			// }
		} else if (id === 'description') {
			filters.inputDescription = val;
			obj.descriptionPage = 0;
			// if (val !== '') {
			// 	query = query + '&description=' + val;
			// }
		} else if (id === "itemGroup") {
			filters.inputItemGroup = val;
		}
		obj.filters = filters;
		this.setState(obj, () => this.applyFilters());
	}
	clearFilters = () => {
		let query = "?availability=" + this.state.availability;
		let filterQuery = '?systemType=' + this.state.availability;
		this.setState({
			filters: {
				itemGroup: "",
				inputItemGroup: "",
				itemNo: "",
				inputItemNo: "",
				inputDescription: "",
				description: "",
				isApplied: false
			},
			itemGroupList: this.state.cloneItemGroupList,
			itemCodePage: 0,
			descriptionPage: 0,
			filterQuery
		}, () => {
			this.props.provisionListing(0, 200, filterQuery)
			this.props.getItemCode(0, query)
			this.props.getDescription(0, query)
			this.props.getItemGroup(query)
		})
	}
	applyFilters = () => {
		let val1 = '';
		let val2 = '';
		let val3 = '';
		let activePage = 0;
		if (this.state.filters.itemGroup !== '') {
			val1 = "&itemGroup=" + encodeURIComponent(this.state.filters.itemGroup);
		} if (this.state.filters.itemNo !== '') {
			val2 = "&alternativeCode2=" + this.state.filters.itemNo;
		}
		if (this.state.filters.description !== '') {
			val3 = "&description=" + encodeURIComponent(this.state.filters.description);
		}
		let filterQuery = '?systemType=' + this.state.availability;
		if (val1 !== '') {
			filterQuery += val1
		}
		if (val2 !== '') {
			filterQuery += val2
		}
		if (val3 !== '') {
			filterQuery += val3
		}
		this.setState({
			filterQuery,
			activePage,
			itemCodePage: 0,
			isApplied: true
		}, () => {
			this.props.provisionListing(activePage, 200, filterQuery)
		})
	}
	handleTableChange = (itemNo, id, value, category, item, nutritions) => {
		let orderDetail = cloneDeep(this.state.orderDetail);
		// let catOrderDetail = cloneDeep(this.state.catOrderDetail);
		let obj = orderDetail[itemNo];
		let totalOrder = 0;
		value = value.replace(".", "")
		if (obj) {
			obj[id] = value;
			obj.category = category;
			obj.description = nutritions.description
			obj.explanation = nutritions.explanation
			obj.standardPackagingUnit = nutritions.standardPackagingUnit
			obj.pricePerBaseUnit = nutritions.pricePerBaseUnit
			obj.orderUsedPoints = nutritions.orderUsedPoints
			obj.category = nutritions.category
			obj.itemCode = nutritions.itemCode
			obj.department = this.props.currentOrder.department
			obj.energy = obj.order !== '' && nutritions.energy !== 0 ? obj.order * nutritions.energy : 0;
			obj.protein = obj.order !== '' && nutritions.protein !== 0 ? obj.order * nutritions.protein : 0;
			obj.fat = obj.order !== '' && nutritions.fat !== 0 ? obj.order * nutritions.fat : 0;
			obj.carbohydrates = obj.order !== '' && nutritions.carbohydrates !== 0 ? obj.order * nutritions.carbohydrates : 0;
		} else {
			delete item._id;
			delete item.createdAt;
			delete item.updatedAt;
			obj = { ...item, remark: '', order: '', category: "" };
			obj[id] = value;
			obj.category = category;
			obj.item = item;
			obj.description = nutritions.description
			obj.explanation = nutritions.explanation
			obj.standardPackagingUnit = nutritions.standardPackagingUnit
			obj.pricePerBaseUnit = nutritions.pricePerBaseUnit
			obj.orderUsedPoints = nutritions.orderUsedPoints
			obj.category = nutritions.category;
			obj.department = this.props.currentOrder.department
			obj.itemCode = nutritions.itemCode
			obj.energy = obj.order !== '' && nutritions.energy !== 0 ? obj.order * nutritions.energy : 0;
			obj.protein = obj.order !== '' && nutritions.protein !== 0 ? obj.order * nutritions.protein : 0;
			obj.fat = obj.order !== '' && nutritions.fat !== 0 ? obj.order * nutritions.fat : 0;
			obj.carbohydrates = obj.order !== '' && nutritions.carbohydrates !== 0 ? obj.order * nutritions.carbohydrates : 0;
		}
		let order = Number(obj.order);
		let count = 0;
		if (!isNaN(order)) {
			count += order
		}
		orderDetail[itemNo] = obj;
		let usedPoints = calculateTotalUsedPoint(orderDetail, this.state.deptBudget);
		if (this.state.deptBudget >= usedPoints.total && usedPoints.percentage <= 100) {
			// if (calculateTotalUsedPoint(orderDetail, this.state.deptBudget).percentage <= 100) {
			let orderkeys = Object.keys(orderDetail);
			for (let index = 0; index < orderkeys.length; index++) {
				const element = orderkeys[index];
				if (orderDetail[element]) {
					let num = parseFloat(orderDetail[element].order)
					if (!isNaN(num)) {
						totalOrder += num;
					}
				}
			}
			this.setState({
				totalOrder,
				orderDetail
			})
		} else {
			Swal.fire("Error", "Limit Exceeded.", "error");
		}
	}
	fetchMoreData = () => {
		let { activePage } = this.state;
		activePage += 1;
		this.setState({
			activePage
		}, () => { this.props.provisionListing(activePage, 200, this.state.filterQuery) })
	};
	fetchMoreItemCode = () => {
		let { itemCodePage } = this.state;
		itemCodePage += 1;
		let query = "?availability=" + this.state.availability;
		if (this.state.filters.inputItemNo !== '') {
			query = query + '&alternativeCode2=' + this.state.filters.inputItemNo;
		}
		this.setState({
			itemCodePage
		}, () => { this.props.getItemCode(itemCodePage, query) })
	}
	fetchMoreDescription = () => {
		let { descriptionPage } = this.state;
		descriptionPage += 1;
		let query = "?availability=" + this.state.availability;
		if (this.state.filters.description !== '') {
			query = query + '&description=' + this.state.filters.description;
		}
		this.setState({
			descriptionPage
		}, () => { this.props.getDescription(descriptionPage, query) })
	}
	updateOrder = () => {
		let data = {};
		let orderDetail = []
		let itemCodeList = [];
		let itemPriceAvailable = 0;
		let itemPriceReq = 0;
		let totalPricePerBaseUnit = 0;
		let totalPrice = 0;
		let keys = Object.keys(this.state.orderDetail);
		let isZeroOrderExist = false;
		for (let index = 0; index < keys.length; index++) {
			const element = keys[index];
			let order = parseFloat(this.state.orderDetail[element].order);
			if (order !== "" && !isNaN(order) && order !== 0) {
				if (this.state.orderDetail[element].pricePerBaseUnit > 0) {
					itemPriceAvailable += 1;
				} else {
					itemPriceReq += 1;
				}
				if (this.state.orderDetail[element].pricePerBaseUnit !== 0) {
					totalPricePerBaseUnit += this.state.orderDetail[element].pricePerBaseUnit;
					totalPrice += this.state.orderDetail[element].pricePerBaseUnit * parseInt(this.state.orderDetail[element].order)
				}
				orderDetail.push({ ...this.state.orderDetail[element] })
				itemCodeList.push(element);
			} else if (order === 0) {
				isZeroOrderExist = true;
			}
		}
		if (!isZeroOrderExist) {
			data.orderId = this.props.match.params.orderId;
			data.orderDetail = orderDetail;
			data.orderItems = orderDetail.length;
			data.itemPriceReq = itemPriceReq;
			data.itemPriceAvailable = itemPriceAvailable;
			data.totalBudget = 0;
			data.pointsUsed = 0;
			data.orderRequested = 0;
			data.totalPricePerBaseUnit = totalPricePerBaseUnit;
			data.totalPrice = totalPrice;
			data.itemCodeList = itemCodeList;
			data.orderNeeded = this.state.deptBudget;
			data.captain = this.state.captainId;
			data.redirectionPath = "/store-catalogue/";
			delete data.vesselRate;
			if (orderDetail.length > 0) {
				this.props.updateOrder(data);
			} else {
				Swal.fire("Error", `Please select some items before sending order.`, "error");
			}
		} else {
			Swal.fire("Error", `Ordered value must be greater than 0.`, "error");
		}
	}
	calculateOrderedProduct = (category, orderDetail) => {
		let sum = 0;
		let keys = Object.keys(orderDetail)
		for (let index = 0; index < keys.length; index++) {
			const element = keys[index];
			if (orderDetail[element].category === category && orderDetail[element].order !== '') {
				sum += parseFloat(orderDetail[element].order)
			}
		}
		return sum;
	}
	disableScroll = () => {
		$('.ordCatg-InnTable').on('focus', 'input[type=number]', function (e) {
			$(this).on('wheel.disableScroll', function (e) {
				e.preventDefault()
			})
		})
	}
	goBack = () => {
		let url = getDashboardLink();
		if (this.props.currentOrder && this.props.currentOrder.vessel) {
			url = '/create-store-order/' + this.props.currentOrder.vessel + "?orderId=" + this.props.match.params.orderId;
		}
		let data = { ...this.props.currentOrder };
		data.orderDetail = this.state.orderDetail;
		data.country.label = data.country.name;
		this.props.AddOrderAction(data);
		this.props.history.push(url);
	}
	closeOrder = () => {
		this.props.AddOrderAction({});
		this.props.history.push(getDashboardLink());
	}
	saveAndClose = (e) => {
		this.setState({
			isSaveAndClose: true,
		})
		this.updateOrder()
	}
	handleOrderedList = (showOrderedList) => {
		let filterCatalouge = [];
		let totalCount = 0;
		if (showOrderedList) {
			filterCatalouge = Object.values(this.state.orderDetail);
			totalCount = filterCatalouge.length;
		} else {
			filterCatalouge = this.props.orderReducer.provisionList;
			totalCount = this.props.orderReducer.totalProvision;
		}
		this.setState({
			filterCatalouge,
			totalCount,
			showOrderedList
		})
	}
	render() {
		const navbarOptions = [
			{
				img: 'left-arrow.svg',
				className: "orange",
				name: "Go back",
				path: '/create-provision-order/',
				isLogout: false,
				isCustom: true,
				onClick: this.goBack
			},
			{
				img: 'Monitor-icon.svg',
				className: "dark-blue",
				name: "Save<br />Order",
				path: '',
				isLogout: false,
				isCustom: true,
				onClick: this.saveAndClose
			},
			{
				img: 'Message-icon.svg',
				className: "green",
				name: "Add to Order Basket",
				path: "/checkout",
				isLogout: false,
				isCustom: true,
				onClick: this.updateOrder
			}
		];
		const totalUsedPoints = calculateTotalUsedPoint(this.state.orderDetail, this.state.deptBudget);
		return (
			<Wrapper>
				<div className="pageWrapper">
					<div className="pageContainer mb-0">
						<div className="order-dlts-wrapper">
							<DashboardNavbar title="STORE ORDER DETAILS" navbarOptions={navbarOptions} isShowLogo={true} logoUrl="logo.png" logoType="Order" className="pageHeader d-flex align-items-center flex-column flex-lg-row" />
						</div>
						{/* Order Overview */}
						<OrderOverView totalUsedPoints={totalUsedPoints} deptBudget={this.state.deptBudget} department={this.props.currentOrder.department} />
						{/* Provision Catalogue Table */}
						<div className="ordCatg-table-wrap mt-3">
							<Filters {...this.state.filters} handleOrderedList={this.handleOrderedList} showOrderedList={this.state.showOrderedList} fetchMoreItemCode={this.fetchMoreItemCode} fetchMoreDescription={this.fetchMoreDescription} totalItemCode={this.state.totalItemCode} totalDescription={this.state.totalDescription} itemGroupList={this.state.itemGroupList} itemNoList={this.state.itemNoList} descriptionList={this.state.descriptionList} handleChange={this.handleFilterChange} clickCustomeSelectHandler={this.clickCustomeSelectHandler} clearFilters={this.clearFilters} applyFilters={this.applyFilters} getFilterDetail={this.props.getFilterDetail} />
							{/* Table */}
							<CatalougeTable data={this.state.filterCatalouge} fetchMoreData={this.fetchMoreData} totalCount={this.state.totalCount} handleTableChange={this.handleTableChange} orderDetail={this.state.orderDetail} apiCallStatus={this.props.apiCallStatus} country={this.props.currentOrder && this.props.currentOrder.country ? this.props.currentOrder.country.label : ''} />
						</div>
					</div>
					{/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
					{/* Order Details Modal */}
					<OrderDetailModal totalUsedPoints={totalUsedPoints} deptBudget={this.state.deptBudget} orderDetail={this.state.orderDetail} />
				</div>
			</Wrapper>
		)
	}
}

const mapStateToProps = (state, ownProps) => ({
	apiCallStatus: state.apicallStatusReducer,
	orderReducer: state.orderReducer,
	currentOrder: state.orderReducer.currentOrder,
	userId: state.authReducer.userId,
	userType: state.authReducer.userType,
	isSubAdmin: state.authReducer.isSubAdmin,
	subType: state.authReducer.subType,

})

const mapDispatchToProps = (dispatch, ownProps) => ({
	provisionListing: (offset, limit, filterQuery) => dispatch(provisionListing(offset, limit, filterQuery)),
	getItemCode: (offset, query) => dispatch(getItemCode(offset, query)),
	getDescription: (offset, query) => dispatch(getDescription(offset, query)),
	getItemGroup: (query) => dispatch(getItemGroup(query)),
	getFilterDetail: (offset, filterQuery) => dispatch(getFilterDetail(offset, filterQuery)),
	updateOrder: (data) => dispatch(updateOrder(data)),
	AddOrderAction: (data) => dispatch(AddOrderAction(data)),
	ApiClearAction: () => dispatch(ApiClearAction()),
	getOrderDetail: (orderId) => dispatch(getOrderDetail(orderId))
})
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StoreCatalogue);




