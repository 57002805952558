import * as Action from "./../Actions/action";
import InitialState from "./../../../Constants/initialState";
import cloneDeep from "lodash/cloneDeep";
export default function OrderReducer(state = InitialState.quotation, action) {
  switch (action.type) {
    case Action.getQuotation:
        let s1 = cloneDeep(state);
        s1.quotationList = action.payload.list;
        s1.totalQuotation = action.payload.count;
        return s1;
      case Action.AppendQuotationList:
        var s2 = cloneDeep(state);
        var arr = s2.quotationList.slice();
        s2.quotationList = arr.concat(action.payload.list);
        return s2;
      case Action.getQuotationDetail:
        let s3 = cloneDeep(state);
        s3.quotationDetailList = action.payload.list;
        s3.basketId = action.payload.basketId;
        s3.orderDetail = action.payload.orderDetail;
        return s3;
    default:
      return state;
  }
}
