import React from "react";
import { Link } from "react-router-dom";
import { Constant } from "../../../../Constants/constant";
import { RatingStars } from "../../../Common/Components/ratingStars";

export function ProfileItem(props) {
    return (
        <div className="d-flex supplierItem-wrap align-items-center mb-3">
            {/* check box wrap */}
            {props.isAllowRfq || props.isAllowOrder ?
                <div className="supplier-select-boxes">
                    <ul className="list-unstyled suppliersTypeList mb-0">
                        {props.zeroPriceItems !== 0 && props.isAllowRfq ?
                            <li>
                                <label className="customCheckBoxLabel">RFQ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <input type="checkbox" onChange={() => props.handleItemSelect(props.supplier._id, 'rfq')} checked={props.selectedItem === props.supplier._id && props.selectionType === 'rfq'} />
                                    <span className="checkmark"></span>
                                </label>
                            </li>
                            : ""}
                        {
                            props.totalPrice !== 0 && props.isAllowOrder ?
                                <li>
                                    <label className="customCheckBoxLabel">ORDER
											<input type="checkbox" onChange={() => props.handleItemSelect(props.supplier._id, 'order')} checked={props.selectedItem === props.supplier._id && props.selectionType === 'order'} />
                                        <span className="checkmark"></span>
                                    </label>
                                </li> : ""
                        }

                    </ul>
                </div> : ""}
            <article className={`supplierItem d-flex flex-column flex-md-row flex-fill ${props.isAllowRfq || props.isAllowOrder ? '':'w-100 mw-100'} `}>
                <div className="supplierLogoHolder mr-3">
                    <img className="img-fluid" src={props.supplier.logo ? Constant.mediaUrl + props.supplier.logo.filename : ""} alt="Image" />
                </div>
                <div className="supplierDetailHolder d-flex flex-fill text-left">
                    <div className="d-flex flex-fill flex-column flex-lg-row">
                        <div className="supplierDetailBlock flex-fill">
                            <div className="headingBlock d-flex mb-2 flex-column flex-lg-row">
                                <h2 className="supplierName mr-2">{props.supplier.companyName}</h2>
                                <RatingStars className="starRating" id={props.supplier._id} ratingAvg={props.ratingAvg} />
                            </div>
                            <div className="supplierDetailContent d-flex flex-nowrap overflow-auto pr-0 mr-3">
                                <div className="supplier-cont-item-wrap">
                                    <ul className="list-inline supplierContentList">
                                        <li><span className="font-weight-bold">Ordered items</span>
                                            <span className="dark-green-clr font-weight-bold mr-2">{props.totalItems}</span>
                                        </li>
                                        <li><span>Prices available:</span>
                                            <span className="dark-green-clr font-weight-bold mr-2">{props.totalItems - props.zeroPriceItems}</span>
                                        </li>
                                        <li><span>To be requested</span>
                                            <span className="dark-green-clr font-weight-bold mr-2">{props.zeroPriceItems}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="supplier-cont-item-wrap">
                                    <h2 className="dark-green-clr font-weight-bold supplier-cont-list-title mb-0 text-center">{(((props.totalItems - props.zeroPriceItems) / props.totalItems) * 100).toFixed(2)} %</h2>
                                </div>
                                <div className="supplier-cont-item-wrap">
                                    <ul className="list-inline supplierContentList">
                                        <li className="justify-content-center font-weight-bold">Order amount</li>
                                        <li className="justify-content-center">{props.totalPrice ? props.totalPrice.toFixed(2) : 0} EUR</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="supplierCertificateBlock d-flex flex-sm-row flex-lg-column flex-column">
                            <div className="text-center mb-0 mb-lg-3 h-auto">
                                <span className="certificateName">ISO/DIN Certificate</span>
                            </div>
                            <div className="certificate-logo-wrap w-100 text-center mb-0 mb-lg-3 h-auto">
                                <img className="img-fluid" src={require('../../../../assets/images/certificate-01.png')} alt="" />
                            </div>
                            <div className="d-flex flex-fill bottomLinks flex-column flex-sm-row">
                                <Link to={"/supplier-profile/" + props.supplier._id + `?selectionType=${props.zeroPriceItems !== 0 ? 'rfq' : 'order'}&&id=${props.currentId}`} className="btn btn-link ml-4 mb-2 cur-poi btn-profile">View Profile</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div >
    )
}