import React from 'react';
export function RatingStars(props) {
    return <React.Fragment>
        <div className={props.className}>
            <Wrapper isShowModel={props.isShowModel} id={props.id} showRatingModal={props.showRatingModal}> <Stars ratingAvg={props.ratingAvg} id={props.id} handleChange={props.handleChange} /></Wrapper>
        </div>
        {!props.isEdit && props.ratingAvg > 3 ? <span className="preferredSupplier">Preferred supplier</span> : ""}
    </React.Fragment>
}
function Stars(props) {
    return <fieldset className="rating">
        <Star type="radio" handleChange={props.handleChange} id={props.id + "-star5"} name={props.id + "rating"} value={5} checked={props.ratingAvg > 4.5 && props.ratingAvg <= 5} className="full" htmlFor={props.id + "-star5"} title="Awesome - 5 stars" />
        <Star type="radio" handleChange={props.handleChange} id={props.id + "-star4half"} name={props.id + "rating"} value={4.5} checked={props.ratingAvg > 4 && props.ratingAvg <= 4.5} className="half" htmlFor={props.id + "-star4half"} title="Pretty good - 4.5 stars" />
        <Star type="radio" handleChange={props.handleChange} id={props.id + "-star4"} name={props.id + "rating"} value={4} checked={props.ratingAvg > 3.5 && props.ratingAvg <= 4} className="full" htmlFor={props.id + "-star4"} title="Pretty good - 4 stars" />
        <Star type="radio" handleChange={props.handleChange} id={props.id + "-star3half"} name={props.id + "rating"} value={3.5} checked={props.ratingAvg > 3 && props.ratingAvg <= 3.5} className="half" htmlFor={props.id + "-star3half"} title="Meh - 3.5 stars" />
        <Star type="radio" handleChange={props.handleChange} id={props.id + "-star3"} name={props.id + "rating"} value={3} checked={props.ratingAvg > 2.5 && props.ratingAvg <= 3} className="full" htmlFor={props.id + "-star3"} title="Meh - 3 stars" />
        <Star type="radio" handleChange={props.handleChange} id={props.id + "-star2half"} name={props.id + "rating"} value={2.5} checked={props.ratingAvg > 2 && props.ratingAvg <= 2.5} className="half" htmlFor={props.id + "-star2half"} title="Kinda bad - 2.5 stars" />
        <Star type="radio" handleChange={props.handleChange} id={props.id + "-star2"} name={props.id + "rating"} value={2} checked={props.ratingAvg > 1.5 && props.ratingAvg <= 2} className="full" htmlFor={props.id + "-star2"} title="Kinda bad - 2 stars" />
        <Star type="radio" handleChange={props.handleChange} id={props.id + "-star1half"} name={props.id + "rating"} value={1.5} checked={props.ratingAvg > 1 && props.ratingAvg <= 1.5} className="half" htmlFor={props.id + "-star1half"} title="Meh - 1.5 stars" />
        <Star type="radio" handleChange={props.handleChange} id={props.id + "-star1"} name={props.id + "rating"} value={1} checked={props.ratingAvg > 0.5 && props.ratingAvg <= 1} className="full" htmlFor={props.id + "-star1"} title="Sucks big time - 1 star" />
        <Star type="radio" handleChange={props.handleChange} id={props.id + "-starhalf"} name={props.id + "rating"} value={0.5} checked={props.ratingAvg > 0 && props.ratingAvg <= 0.5} className="half" htmlFor={props.id + "-starhalf"} title="Sucks big time - 0.5 stars" />
    </fieldset >
}

function Star(props) {
    return <><input type={props.type} id={props.id} name={props.name} value={props.value} checked={props.checked} /><label className={props.className} onClick={() => props.handleChange(props.value)} htmlFor={props.htmlFor} title={props.title}></label></>
}
function Wrapper(props) {
    return props.isShowModel ? <button type="button" className="btn btn-link p-0 m-0" onClick={() => props.showRatingModal(props.id)}>
        {props.children}
    </button> : props.children;

}