import React, { Component } from 'react';
import { getDashboardLink, isAllowPermission } from '../../../../../Services/common';
import { Link } from "react-router-dom";
import DashboardNavbar from '../../../../Common/Components/dashboardNavbar';
import { sendQuotation } from "../../../ApiCalls/supplier";
import { ClearApiByNameAction } from "../../../../ApiCallStatus/Actions/action";
import { Wrapper } from '../../../Css/previsionCatalouge';
import { SupplierComparisonListing } from '../../../../Supplier/ApiCalls/supplier';
import { getOrderDetail } from './../../../ApiCalls/provisionOrder';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import moment from 'moment'
import DemoHeader from '../../../../Common/Components/demoHeader';
import { getRoutePathBykey } from '../../../../../Constants/routesConfig';
import { Modal } from 'react-responsive-modal';
import { RatingStars } from '../../../../Common/Components/ratingStars';
class SendRfq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.match.params.type,
            orderId: this.props.match.params.orderId,
            supplierId: this.props.match.params.supplierId,
            totalPrice: 0,
            "data": {},
            "vesselDetail": {},
            "departments": {},
            "totalItems": 0,
            "orderType": "",
            "isShowModal": false
        }
    }

    componentDidMount() {
        let path = getRoutePathBykey('SendRfq');
        path = path.replace(":type", this.state.type);
        if (this.props.isSubAdmin && this.props.roles && this.props.roles.length > 0 && !isAllowPermission(path, this.props.roles)) {
            this.props.history.push('/not-allowed')
        } else {
            let resObj = { basketId: this.state.orderId, supplierId: this.state.supplierId, "type": this.state.type }
            if (this.state.type === "rfq") {
                resObj['priceType'] = 'estimated';
            }
            this.props.SupplierComparisonListing(resObj);
            // this.props.getOrderDetail(this.props.match.params.orderId)
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (((prevProps.apiCallStatus.isStarted.indexOf('sendQuotation') !== -1 &&
            this.props.apiCallStatus.isStarted.indexOf('sendQuotation') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
            let type = this.state.type === 'order' ? 'Order' : 'RFQ';
            if (type === 'RFQ') {
                Swal.fire({
                    title: 'Do you want to send RFQ to another supplier?',
                    showDenyButton: true,
                    confirmButtonText: `Yes`,
                    denyButtonText: `No`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push("/supplier-comparison/" + this.state.orderId)
                    } else if (result.isDenied) {
                        this.props.history.push(getDashboardLink())
                    }
                })
            } else {
                Swal.fire("Success", `Your ${type} has been sent successfully`, "success");
                this.props.history.push(getDashboardLink())
            }
        } else if (((prevProps.apiCallStatus.isStarted.indexOf('sendQuotation') !== -1 &&
            this.props.apiCallStatus.isStarted.indexOf('sendQuotation') === -1)) && this.props.apiCallStatus.isCompleted && this.props.apiCallStatus.isFailed) {
            Swal.fire("Error", this.props.apiCallStatus.message, "error");
        } if (((prevProps.apiCallStatus.isStarted.indexOf('SupplierComparisonListing') !== -1 &&
            this.props.apiCallStatus.isStarted.indexOf('SupplierComparisonListing') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
            let data = this.props.orderDetail.data && this.props.orderDetail.data[0] ? this.props.orderDetail.data[0] : {}
            let totalPrice = 0;
            data.orderedItems && data.orderedItems.forEach(element => {
                totalPrice += element.totalPrice
            });
            let orderedItems = [];
            if (data.orderedItems) {
                if (this.props.orderDetail.type === "store") {
                    orderedItems = data.orderedItems.reduce((r, a) => {
                        r[a.department] = [...r[a.department] || [], a];
                        return r;
                    }, {});
                } else {
                    orderedItems = data.orderedItems;
                }
            }

            this.setState({
                "data": data,
                orderedItems: orderedItems,
                "vesselDetail": this.props.orderDetail.vesselDetail,
                "departments": this.props.orderDetail.departments,
                "totalItems": this.props.orderDetail.totalItems,
                "orderType": this.props.orderDetail.type,
                totalPrice
            })
            this.props.ClearApiByNameAction(prevProps.apiCallStatus.apiCallFor)
        }
    }
    handleSubmit = () => {
        this.handleModalChange(false)
        let itemCodeList = this.state.data.orderedItems.map(a => a.itemCode);
        this.props.sendQuotation(this.state.orderId, this.state.supplierId, itemCodeList, this.state.type, this.state.totalPrice)
    }
    handleModalChange = (val) => {
        this.setState({
            isShowModal: val
        })
    }
    render() {
        const navbarOptions = [
            {
                img: 'left-arrow.svg',
                className: "dark-pink",
                name: "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => this.props.history.push("/supplier-comparison/" + this.state.orderId)
            },
            {
                img: 'Message-icon.svg',
                className: "green",
                name: "Send<br />" + (this.state.type === 'order' ? 'Order' : "RFQ "),
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => this.handleModalChange(true)
            }
        ];
        return (
            <Wrapper>
                <div className="pageWrapper">
                    <div className="pageContainer mb-0">
                        <DemoHeader />
                        <div className="order-dlts-wrapper">
                            <DashboardNavbar title={`${this.state.type === 'rfq' ? "RFQ Description" : "Order Description"}`} navbarOptions={navbarOptions} isShowLogo={true} logoUrl="logo.png" logoType="Order" className="pageHeader d-flex align-items-center flex-column flex-lg-row" />
                        </div>
                        {/* RFQ Detials Section */}
                        <div className="rfq-details-wrap ordCatg-table-wrap sup-rfq-wrap d-flex align-items-start">
                            <div className="rfq-durations rfq-details-items  descriptrfq">
                                <h2 className="mt-2 font-weight-bold">{this.state.vesselDetail.name ? this.state.vesselDetail.name : ''}</h2>
                                <div className="inner-content text-left">
                                    <h4 className="mt-0"> <span className="font-weight-bold mt-1 d-inline-block mr-1">Del. Place:</span> {this.props.orderDetail.portName}</h4>
                                    <h4 className="mt-0"><span className="font-weight-bold mt-1 d-inline-block mr-1">Del. date:</span>{moment(this.props.orderDetail.deliveryDate).format('YYYY-MM-DD')}</h4>
                                    <h4 className="mt-0"><span className="font-weight-bold mt-1 d-inline-block mr-1">Agent Detail:</span>{this.props.orderDetail.agentDetail}</h4>
                                    <h4 className="mt-0"><span className="font-weight-bold mt-1 d-inline-block mr-1">{`${this.state.type === 'rfq' ? "Requested items:" : "Items ordered:"}`}</span>{this.state.totalItems}</h4>
                                </div>
                            </div>
                            <div className="rfq-rating rfq-details-items">
                                <button type="button" className="btn themeReguler-btn bg-dark-blue m-0">{this.state.data.supplier ? this.state.data.supplier.name : ""}</button>
                                <div className="inner-content text-center">
                                    <div className="rating-wrap d-flex align-items-center mt-2">
                                        <h3 className="rounded-tag mb-0 mr-2">Best Rating</h3>
                                        <RatingStars id="input" className="rating-checks list-inline pl-0 d-flex align-items-center mb-0" isEdit={true} ratingAvg={this.state.data.supplier ? this.state.data.supplier.ratingAvg : 0} handleChange={() => { }} />
                                        {/* <ul className="rating-checks list-inline pl-0 d-flex align-items-center mb-0">
                                            <li>
                                                <input type="checkbox" className="star-checkbox" id="start-1" />
                                                <label htmlFor="start-1"><i class="fas fa-star"></i></label>
                                            </li>
                                            <li>
                                                <input type="checkbox" className="star-checkbox" id="start-2" />
                                                <label htmlFor="start-2"><i class="fas fa-star"></i></label>
                                            </li>
                                            <li>
                                                <input type="checkbox" className="star-checkbox" id="start-3" />
                                                <label htmlFor="start-3"><i class="fas fa-star"></i></label>
                                            </li>
                                            <li>
                                                <input type="checkbox" className="star-checkbox" id="start-4" />
                                                <label htmlFor="start-4"><i class="fas fa-star"></i></label>
                                            </li>
                                            <li>
                                                <input type="checkbox" className="star-checkbox" id="start-5" />
                                                <label htmlFor="start-5"><i class="fas fa-star"></i></label>
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div className="inner-content text-center d-flex align-items-center mt-2">
                                        <h3 className="rounded-tag mr-2 orng-clr mb-0">Most prices</h3>
                                        {this.state.data.supplier ? <div className="rounded-tag progress-bar-wrap"><h3 className="rounded-tag mb-0" style={{ width: ((this.state.data.supplier.fixedItems / this.state.data.supplier.estimatedItems) * 100) }}>{this.state.data.supplier.fixedItems}/{this.state.data.supplier.estimatedItems}</h3></div> : ""}
                                    </div>
                                    {/* <p className="mt-3 mb-1">Items with fixed prices </p>
                                    <p>Items with estimated prices </p> */}
                                    <h4 className="mt-0"><span className="font-weight-bold mt-1 d-inline-block mr-1">Estimated Total Amount:</span> {this.state.totalPrice ? this.state.totalPrice.toFixed(2) : 0} {this.state.vesselDetail.currency ? this.state.vesselDetail.currency : ''}</h4>
                                </div>
                            </div>
                            <div className="contact-info rfq-details-items flex-fill d-flex flex-column align-items-center">
                                <div>
                                    <ul className="list-inline p-0 m-0">
                                        {/* <li><span className="semi-bold">Contact person:</span> {this.state.data.supplier ? this.state.data.supplier.name : ""}</li> */}
                                        <li><span className="font-weight-bold">email:</span>{this.props.orderDetail && this.props.orderDetail.data && this.props.orderDetail.data[0] && this.props.orderDetail.data[0].supplier ? this.props.orderDetail.data[0].supplier.email : ''}</li>
                                        <li><span className="font-weight-bold">website:</span>{this.props.orderDetail && this.props.orderDetail.data && this.props.orderDetail.data[0] && this.props.orderDetail.data[0].supplier ? this.props.orderDetail.data[0].supplier.websiteUrl : ''}</li>
                                    </ul>
                                    <Link to={"/supplier-profile/" + this.state.supplierId} className="btn themeReguler-btn bg-sky-blue mt-3 mx-0">View Supplier Profile</Link>
                                </div>
                            </div>
                        </div>
                        {/* RFQ Detials Section End */}

                        {/* Provision Catalogue Table */}
                        <div className="ordCatg-table-wrap mt-3 sup-rfq-wrap">
                            {/* Table */}
                            <div className="ordCatg-InnTable store-catgTable py-3 sendRfq-t">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="text-left">Item Code.</th>
                                                <th className="text-left">IMPA</th>
                                                <th className="text-left">Item group</th>
                                                {this.props.orderDetail && this.props.orderDetail.type === "store" ? <th className="text-left">Department </th> : ""}
                                                <th className="text-left">Description</th>
                                                <th className="text-left">Quantity</th>
                                                <th className="text-left">Unit</th>
                                                <th className="text-left">Estimated Price Per Unit</th>
                                                {/* <th className="text-left">Total Price</th> */}
                                                {this.props.orderDetail && this.props.orderDetail.type === "store" ?
                                                    <th className="text-left">Remarks</th>
                                                    : ""}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.apiCallStatus.isStarted.indexOf("SupplierComparisonListing") !== -1 ? <tr><td colSpan="20"><div className="loader-img text-center w-100">
                                                <img style={{ width: "46px" }} src={require("../../../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                            </div></td></tr> : Object.size(this.state.data) === 0 ? <tr><td colSpan="40" className="text-center">No Data Found</td></tr> :
                                                    this.state.orderType !== "" ?
                                                        this.state.orderType === "store" ?
                                                            Object.keys(this.state.orderedItems).map((key, i) => {
                                                                return this.state.orderedItems[key].map((item, index) => {
                                                                    let order = item.order ? item.order : 0;
                                                                    let remark = item.remark ? item.remark : '';
                                                                    return <React.Fragment key={index}>
                                                                        {index === 0 ?
                                                                            <tr >
                                                                                <td><h1 className="text-center btn-sty-title2"> {key}</h1></td>
                                                                            </tr> : ""}
                                                                        <tr >
                                                                            <td><span className="text-center td-wrap-sty">{item.itemCode}</span></td>
                                                                            <td><span className="td-wrap-sty text-center">{item.alternativeCode}</span></td>
                                                                            <td><span className="td-wrap-sty text-center">{item.category}</span></td>
                                                                            <td><span className="td-wrap-sty text-center">{item.department}</span></td>
                                                                            <td><span className="text-left td-wrap-sty">{item.description}</span></td>
                                                                            <td className="button-round-td"><span className="whi-round text-center">{order}</span></td>
                                                                            <td><span className="td-wrap-sty text-center">{item.supplierStandardPackagingUnit}</span></td>
                                                                            <td><span className="td-wrap-sty text-right">{this.state.type === 'rfq' ? 0 : parseFloat(item.itemPrice).toFixed(2)}</span></td>
                                                                            <td><span className="td-wrap-sty text-right">{remark}</span></td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                })
                                                            })
                                                            : this.state.orderedItems.map((item, index) => {
                                                                let order = item.order ? item.order : 0;
                                                                return <tr key={index}>
                                                                    <td><span className="text-center td-wrap-sty">{item.itemCode}</span></td>
                                                                    <td><span className="td-wrap-sty text-center">{item.alternativeCode}</span></td>
                                                                    <td><span className="td-wrap-sty text-center">{item.category}</span></td>
                                                                    <td><span className="text-left td-wrap-sty">{item.description}</span></td>
                                                                    <td className="button-round-td"><span className="whi-round text-center">{order}</span></td>
                                                                    <td><span className="td-wrap-sty text-center">{item.supplierStandardPackagingUnit}</span></td>
                                                                    <td><span className="td-wrap-sty text-right">{this.state.type === 'rfq' ? 0 : parseFloat(item.itemPrice).toFixed(2)}</span></td>
                                                                </tr>
                                                            }) : ""}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
                </div>
                <Modal open={this.state.isShowModal} onClose={() => this.handleModalChange(false)} center>
                    <div className="formBox request-price">
                        <div className="col-12">
                            <h5>You have chosen {this.state.data.supplier ? this.state.data.supplier.name : ""} for your {this.state.type}. Do you want to proceed?</h5>
                            <div className="row">
                                <div className="col-sm-6 text-center">
                                    <button className="btn themeReguler-btn bg-sky-blue m-0" onClick={() => this.handleSubmit()}>Yes</button>
                                </div>
                                <div className="col-sm-6 text-center">
                                    <button className="btn themeReguler-btn bg-sky-blue m-0" onClick={() => this.handleModalChange(false)} >No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Wrapper>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    rfqList: state.orderReducer.rfqList,
    currentOrder: state.orderReducer.currentOrder,
    roles: state.authReducer.roles,
    isSubAdmin: state.authReducer.isSubAdmin,
    orderDetail: state.supplierReducer.comparison
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    getOrderDetail: (orderId) => dispatch(getOrderDetail(orderId)),
    SupplierComparisonListing: (data) => dispatch(SupplierComparisonListing(data)),
    sendQuotation: (orderId, supplierId, itemCodeList, orderType, totalPrice) => dispatch(sendQuotation(orderId, supplierId, itemCodeList, orderType, totalPrice))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SendRfq);