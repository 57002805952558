import React from "react";
import Select from 'react-select';
export function UserForm(props) {
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            if (props.isEdit) {
                props.updateUser();
            } else {
                props.createHandler();
            }
        }
    }
    return (
        <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="row">
                <div className="col-sm-6">

                    {props.isEdit ? <div className="form-group floating-label-wrap"> <input type="text" id="type" name="type" className="form-control" placeholder="Type"
                        value={props.type}
                        required readOnly={props.isEdit} />
                        <lable htmlFor="type" className="foating-labels-sty">Type</lable> </div>
                        : <div className="form-group d-flex align-items-center"><div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select User Type"
                                value={props.selectedTypeOption}
                                onChange={(e) => { props.handleSelectChange(e, 'type') }}
                                options={props.typeOption}
                            />
                        </div> </div>}

                </div>
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="email" id="email" name="email" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Email"
                            value={props.email}
                            required readOnly={props.isEdit} />
                        <lable htmlFor="email" className="foating-labels-sty">Email</lable>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" id="full_name" name="name" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Name"
                            value={props.name}
                            required />
                        <lable htmlFor="full_name" className="foating-labels-sty">Name</lable>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" id="phone" name="phone" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Phone No."
                            value={props.phone}
                            required />
                        <lable htmlFor="phone" className="foating-labels-sty">Phone No.</lable>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Country"
                                value={props.selectedCountryOption}
                                onChange={(e) => { props.handleSelectChange(e, 'country') }}
                                options={props.countryOption}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Port"
                                value={props.selectedPortOption}
                                onChange={(e) => { props.handleSelectChange(e, 'port') }}
                                options={props.portOption}
                            />
                        </div>
                    </div>
                </div>
                {props.type === 'captain' ? '' : <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Order Type"
                                value={props.selectedOrderTypeOption}
                                onChange={(e) => { props.handleSelectChange(e, 'orderType') }}
                                options={props.orderTypeOption}
                            />
                        </div>
                    </div>
                </div>
                }
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Vessel"
                                value={props.selectedVesselOption}
                                onChange={(e) => { props.handleSelectChange(e, 'vessel') }}
                                options={props.vesselOption}
                            // isDisabled={props.isEdit}
                            />
                        </div>
                    </div>
                </div>
                {(props.orderType === 'provision' || props.type === 'captain') ? '' : <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Department"
                                value={props.selectedDepOption}
                                onChange={(e) => { props.handleSelectChange(e, 'department') }}
                                options={props.departmentOption}
                                isMulti
                            />
                        </div>
                    </div>
                </div>}
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" id="websiteUrl" name="websiteUrl" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Website"
                            value={props.websiteUrl}
                            required />
                        <lable htmlFor="websiteUrl" className="foating-labels-sty">Website</lable>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Role"
                                value={props.selectedRoleOption}
                                onChange={(e) => { props.handleSelectChange(e, 'role') }}
                                options={props.roleOption}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {props.isEdit ? "" : <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="password" id="password" name="password" className="form-control" onChange={(e) => { props.handleFormChange(e) }} value={props.password} placeholder="Password" autoComplete="new-password" required />
                        <lable htmlFor="password" className="foating-labels-sty">Password</lable>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="password" id="confirmPassword" name="confirmPassword" className="form-control" onChange={(e) => { props.handleFormChange(e) }} value={props.confirmPassword} placeholder="Confirm Password" autoComplete="new-password" required />
                        <lable htmlFor="confirmPassword" className="foating-labels-sty">Confirm Password</lable>
                    </div>
                </div>
            </div>}

            {props.selectedTypeOption !== null && props.selectedTypeOption.value === 'cook' ?
                <div className="tags-par-wrap">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group floating-label-wrap input-tags-lis">
                                <input type="text" name="speciality" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Please Add Speciality" value={props.speciality} />
                                <lable className="foating-labels-sty">Please Add Speciality</lable>
                                <div class="form-btn">
                                    <button onClick={() => props.addItem()} class="btn themeReguler-btn bg-sky-blue btn-add" id="enter" type="button">Add</button>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-6">
                            <label className={props.specialityList.length > 0 ? 'd-none' : ''}>Speciality</label>
                            <div className="custom-select2 text-bx-wrap input-type-select p-0 form-group d-flex sm-mb-3">
                                <ul className="list-inline pl-0 mb-0 d-flex align-items-center flex-nowrap overflow-auto">
                                    {
                                        props.specialityList.length > 0 ? props.specialityList.map((val, i) => {
                                            return <li className="theme-tag-wrap">{val}<span onClick={() => props.removeItem(val)} class="close">&times;</span></li>
                                        }) : ''
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                : ''}

            {props.apiCallStatus.apiCallFor === props.apiCallName && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                <div className="alert alert-danger">
                    {props.apiCallStatus.message}
                </div>
                : ""}
            {props.errorMessage !== "" && props.errorType !== "" ?
                <div className="alert alert-danger">
                    {props.errorMessage}
                </div>
                : ""}
            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {props.apiCallStatus.apiCallFor === props.apiCallName && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">{props.isEdit ? "Update" : "Submit"}</button>
                </div>
            </div>
        </form>
    )
}