import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { getNotificationListAction,appendNotificationListAction } from "../Actions/actions";
import { FETCH } from "../../../Services/fetch";

export function getNotificationList(userId, offset, limit) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(
        Actions.ApiRequestedAction({ apiCallFor: "appendNotificationList" })
      );
    } else {
      dispatch(
        Actions.ApiRequestedAction({ apiCallFor: "getNotificationList" })
      );
      dispatch(getNotificationListAction([], 0, 0));
    }
    let myJson = await FETCH(
      "POST",
      Constant.apiURl + "/users/get-notification/" + offset + "/" + limit,
      {
        userId,
      }
    );
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(
          appendNotificationListAction(
            myJson.data,
            myJson.unReadMsgCount,
            myJson.count
          )
        );
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendNotificationList",
            message: myJson.message,
          })
        );
      } else {
        dispatch(
          getNotificationListAction(
            myJson.data,
            myJson.unReadMsgCount,
            myJson.count
          )
        );
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getNotificationList",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({ apiCallFor: "appendNotificationList" })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({ apiCallFor: "getNotificationList" })
        );
      }
    }
  };
}
