import * as Actions from "../../ApiCallStatus/Actions/action";
import { GetSupplierCatalougeAction, AppendSupplierCatalougeAction, GetCatalogueDetailAction } from "../Actions/action";
import { Constant } from "../../../Constants/constant";
import { FETCH } from "../../../Services/fetch";
import Swal from 'sweetalert2'

export function supplierCatalougeList(offset, filterQuery) {
    return async (dispatch) => {
        if (offset !== 0) {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendSupplierCatalouge" }));
        } else {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "getSupplierCatalouge" }));
            dispatch(GetSupplierCatalougeAction([], 0))
        }
        let myJson = await FETCH("GET", Constant.apiURl + "/catalouge/supplier/items/" + offset + "/50" + filterQuery);
        if (myJson && myJson.code === 201) {
            if (offset !== 0) {
                dispatch(AppendSupplierCatalougeAction(myJson.data));
                dispatch(Actions.ApiFulfilledAction({ apiCallFor: "appendSupplierCatalouge", message: '' }));

            } else {
                dispatch(GetSupplierCatalougeAction(myJson.data, myJson.count))
                dispatch(
                    Actions.ApiFulfilledAction({
                        apiCallFor: "getSupplierCatalouge",
                        message: myJson.message,
                    }))
            }

        } else {
            if (offset !== 0) {
                dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "appendSupplierCatalouge", message: myJson.errorMessage ? myJson.errorMessage : myJson.message }));
            } else {
                dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getSupplierCatalouge", message: myJson.message }))
            }
        }
    };
}
export function deleteCatalogue(catalogueId) {
    return async dispatch => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteCatalogue" }));
        let myJson = await FETCH('DELETE', Constant.apiURl + "/catalouge/" + catalogueId);
        if (myJson && myJson.code === 200) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "deleteCatalogue",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "deleteCatalogue",
                    message: myJson.message,
                })
            );
        }
    }
}
export function deleteAllCatalogue(query) {
    return async dispatch => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteAllCatalogue" }));
        Swal.queue([{
            title: "Delete Catalogues",
            text: `Are you sure you want to delete all items?`,
            icon: "warning",
            buttons: true,
            showCancelButton: true,
            dangerMode: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return FETCH('DELETE', Constant.apiURl + `/catalouge${query}`)
                    .then(myJson => {
                        if (myJson && myJson.code === 200) {
                            // Swal.insertQueueStep("Records have been Deleted")
                            dispatch(
                                Actions.ApiFulfilledAction({
                                    apiCallFor: "deleteAllCatalogue",
                                    message: myJson.message,
                                })
                            );
                        } else {
                            dispatch(
                                Actions.ApiRejectedAction({
                                    statusCode: myJson.statusCode,
                                    apiCallFor: "deleteAllCatalogue",
                                    message: myJson.message,
                                })
                            );
                        }
                    })
                    .catch(() => {
                        Swal.insertQueueStep({
                            icon: 'error',
                            title: 'Unable to get your public IP'
                        })
                    })
            }
        }])
        // let myJson = await FETCH('DELETE', Constant.apiURl + `/catalouge${query}`);
        // if (myJson && myJson.code === 200) {
        //     dispatch(
        //         Actions.ApiFulfilledAction({
        //             apiCallFor: "deleteAllCatalogue",
        //             message: myJson.message,
        //         })
        //     );
        // } else {
        //     dispatch(
        //         Actions.ApiRejectedAction({
        //             statusCode: myJson.statusCode,
        //             apiCallFor: "deleteAllCatalogue",
        //             message: myJson.message,
        //         })
        //     );
        // }
    }
}
export function getCatalogueDetail(id) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCatalogueDetail" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/catalouge/" + id);
        if (myJson && myJson.code === 201) {
            let data = myJson.data;
            data.catalogueId = myJson.data._id;
            delete data._id;
            dispatch(GetCatalogueDetailAction(data));
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getCatalogueDetail",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getCatalogueDetail",
                    message: myJson.message,
                })
            );
        }
    };
}
export function updateCatalogue(data, catalogueId) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateCatalogue" }));
        let myJson = await FETCH("PUT", Constant.apiURl + "/catalouge/" + catalogueId, data);
        if (myJson && myJson.code === 200) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "updateCatalogue",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "updateCatalogue",
                    message: myJson.message,
                })
            );
        }
    };
}