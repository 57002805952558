import React from 'react'
import '../Css/feedBack.css';
import ReactStars from "react-rating-stars-component";
import { getDashboardLink,getLoginDashboardLink } from '../../../Services/common';
const ratingChanged = (newRating) => {
    console.log(newRating);
  };
 function feedBack(props) {
    const redirectionalHandler=()=>{
        let url= getDashboardLink();
        props.history.push(url)
     }
    return (
        <div>
          <div className="pageWrapper py-4 ">
            <div className="container min2-H100 d-flex">
                <div className="pageContainer mb-0 w-100  min-height: 100vh;">
                <div className="section-store d-flex justify-content-end w-100 mt-1 text-right">
                <button type="button" class="btn btn-polygon-sty dark-green">
                    <img class="img-fluid" src="/static/media/left-arrow.06baa649.svg" alt="icon"/>
                    <span>Go back</span>
                </button>
                </div>
                <div id="feedback-container">
                  <div id="feedback-title">Tell us what you think!</div>
                    <form>
                        <label htmlFor="name">
                            <span class="feedbackLabel">Quality of goods </span>
                            <ReactStars
                               count={5}
                               onChange={ratingChanged}
                               size={24}
                               activeColor="#ffd700"
                            />
                        </label>
                        <input id="name" class="feedbackInfo" type="text" placeholder="Quality of goods" required/><br/>
                        <label htmlFor="email">
                            <div class="feedbackLabel"> Completeness of delivery</div>
                        </label>
                        <input id="email" class="feedbackInfo" type="text" placeholder="Completeness of delivery" required/><br/>
                        <label htmlFor="email">
                            <div class="feedbackLabel">Packaging of goods</div>
                        </label>
                        <input id="email" class="feedbackInfo" type="text" placeholder="Packaging of goods" required/><br/>
                        <label htmlFor="email">
                            <div class="feedbackLabel">Punctuality of supplier </div>
                        </label>
                        <input id="email" class="feedbackInfo" type="text" placeholder="Punctuality of supplier" required/><br/>
                        <label htmlFor="comment">
                            <div class="feedbackLabel">Additional Comments:</div>
                        </label>
                        <br/>
                        <textarea id="comment" class="feedbackInfo comment" rows="5" maxlength="450" placeholder="Any additional comments! (450 characters)"></textarea>
                        <button type="button" class="btn themeReguler-btn bg-dark-blue">send Feedback</button>
                    </form>
                 </div>
                </div>          
            </div>
            {/* welcome logo wrap */}
            <div className="section-store d-flex justify-content-end w-100 pr-5 text-right mt-3 mb-0" >
                <div className=" inn-pageLogo-wrap mr-5">
                    {/* <img className="img-fluid" src={require('../../assets/images/logo.png')} alt="Logo" /> */}
                </div>
            </div>    
        </div>
     </div>
    )
} 
export default feedBack;
