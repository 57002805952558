import React from 'react';
import Select from 'react-select';

export function OrderDetail(props) {
    return (
        <div className="order-dtls-sec-ctn creatStore-Order main-screen">
            <div className="card-group-cnt d-flex align-items-center flex-column flex-md-row w-100">
                <h2 className="title-dark-20">ORDER DETAILS</h2>
                <div className="flex-fill order-right-ctn">
                    <h6 className="require-note text-danger">Please fill in all details below:</h6>
                    <div className="card-bx-sty">
                        {/* Form Error Wrapper */}
                        {props.errorMessage !== "" && props.errorFor === "orderDetail" ?
                            // <div className="errorShow-wrap">
                            //     <p className="text-danger mb-0">{props.errorMessage}</p>
                            // </div>
                            <div className="alert alert-danger" role="alert">
                                {props.errorMessage}
                            </div>
                            : ""}
                        <div className="form-group m-0 d-flex align-items-center justify-content-center">
                            <label className="label-bold mr-2">{props.name}:</label>
                            <div className="custom-select-menu text-bx-wrap custom-select2 ">
                                <Select
                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder={props.placeholder}
                                    value={props.orderKind === '' ? null : { label: props.orderKind, value: props.orderKind }}
                                    onChange={(val) => props.handleSelectChange(val, "orderKind")}
                                    isDisabled={props.isOrderKindDisable}
                                    options={props.orderTypesOptions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}