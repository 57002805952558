import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import { Wrapper } from '../../../Common/Components/CommonWrapper';
import  DashboardNavbar  from '../../../Common/Components/dashboardNavbar';
import { getQuotationDetail, sendQuotation } from '../../Apicalls/quotations';
import loader from '../../../../assets/images/Spinner-1s-200px.gif';
import cloneDeep from 'lodash.clonedeep';
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2'
import { getCookie } from '../../../../Services/cookies';



function PrepareQuotation(props) {
    const [state, setState] = useState({
        isHitApi: false,
        price: 0,
        orderDetail: {},
        quotationDetailList: [],
    });
    useEffect(() => {
        if (!state.isHitApi) {
            props.getQuotationDetail(props.match.params.quotationId, props.userId);
            let data = { ...state };
            data.isHitApi = true;
            setState(data);
        }
        if (props.apiCallStatus.apiCallFor === "getQuotationDetail" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                quotationDetailList: props.quotationReducer.quotationDetailList,
                isHitApi: true
            })
            props.ApiClearAction()
        }
        if (props.apiCallStatus.apiCallFor === "sendQuotation" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            Swal.fire('Success', "RFQ send succesfully", 'success');
            props.history.push('/inprogress-quotations')
            props.ApiClearAction()
        }
        if (props.apiCallStatus.apiCallFor === "sendQuotation" &&
            props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed) {
            Swal.fire('Error', props.apiCallStatus.message ? props.apiCallStatus.message : "Error in saving quotation", 'error');
            props.ApiClearAction()
        }
    });
    const calculateSum = (data) => {
        let keys = Object.keys(data);
        let price = 0;
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            if (data[element] && data[element].price !== '') {
                price += parseFloat(data[element].price);
            }
        }
        return price.toFixed(2);
    }
    const sendQuotation = () => {
        let items = [];
        let keys = Object.keys(state.orderDetail);
        let orderId = '';
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            if (state.orderDetail[element].price !== "") {
                orderId = state.orderDetail[element].orderId
                items.push({
                    itemCode: state.orderDetail[element].itemCode, price: parseFloat(state.orderDetail[element].price)
                })
            }
        }
        props.sendQuotation({ items, quotationId: props.match.params.quotationId, orderId })

    }
    const declineQuotation = () => {
        let orderDetail = [];
        let keys = Object.keys(state.orderDetail);
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            if (state.orderDetail[element].price !== "") {
                orderDetail.push({
                    itemCode: state.orderDetail[element].itemCode, price: state.orderDetail[element].price
                })
            }
        }
    }
    const getNavbarOptions = () => {
        let options = [
            {
                img: 'left-arrow.svg',
                className: "orange",
                name:  "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.history.goBack()
            },
            // {
            //     img: 'cancel-icon.svg',
            //     className: "red",
            //     name: "decline",
            //     path: `/`,
            //     isLogout: false,
            //     isCustom: true,
            //     onClick: () => declineQuotation()
            // }
        ]
        if (props.userType === 'supplier') {
            options.push({
                img: 'Edit-icon.svg',
                className: "dark-green " + (state.quotationDetailList.length !== Object.keys(state.orderDetail).length ? "btn-disable" : ""),
                name: "Confirm<br />Order",
                path: `/prepare-quotation/${props.match.params.quotationId}`,
                isLogout: false,
                isCustom: true,
                onClick: () => sendQuotation()
            })
        }
        return options;
    }
    return (
        <Wrapper>
            <DashboardNavbar title="Confirm Order" navbarOptions={getNavbarOptions()} className="mesh-board-wrap d-flex align-items-end" />
            <div className="ordCatg-table-wrap mt-3 sup-rfq-wrap">

                {/* Table */}
                <div className="ordCatg-InnTable store-catgTable">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-left">ItemCode</th>
                                    <th>IMPA</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Unit</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.apiCallStatus.isStarted.indexOf("getQuotationDetail") !== -1 ?
                                    <tr><td colSpan="20">
                                        <div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={loader} alt='' />
                                        </div>
                                    </td></tr>
                                    : state.quotationDetailList && state.quotationDetailList.length > 0 ? state.quotationDetailList.map((item, index) => {
                                        return <tr key={index}>
                                            <td><span className="text-center td-wrap-sty">{item.itemCode}</span></td>
                                            <td><span className="td-wrap-sty text-center">{item.alternativeCode}</span></td>
                                            <td><span className="text-left td-wrap-sty">{item.description}</span></td>
                                            <td><span className="text-center td-wrap-sty">{item.order}</span></td>
                                            <td><span className="text-center td-wrap-sty">{item.standardPackagingUnit === "" ? item.pricePerPackagingUnit : item.standardPackagingUnit}</span></td>
                                            <td><span className="text-right td-wrap-sty"> {item.pricePerBaseUnit}</span></td>

                                        </tr>

                                    }) : state.quotationDetailList && state.quotationDetailList.length === 0 ?
                                            <tr><td colSpan="40" className="text-center">No Data Found</td></tr> : ''}
                            </tbody>
                        </table>
                    </div>
                </div>
                {props.userType === 'supplier' ?
                    <div className="tableFoot-content">
                        <ul className="list-inline pl-0 d-flex align-items-center justify-content-end deli-info-list mb-2">
                            <li>Total items:<span>{state.quotationDetailList.length}</span></li>
                            <li>Quoted items:<span>{Object.keys(state.orderDetail).length}</span></li>
                            <li>Amount:<span>{state.price} €</span></li>
                        </ul>
                        <div className="deli-info-ctn d-flex justify-content-end">
                            <div className="deli-info-items-ctn">
                                <div className="form-group mb-1 d-flex align-items-center justify-content-end">
                                    <label>Delivery time:</label>
                                    <input type="text" className="form-control" placeholder="" />
                                    <label className="ml-2">days</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""}

            </div>
        </Wrapper>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    quotationReducer: state.quotationReducer,
    userId: state.authReducer.userId,
    userType: state.authReducer.userType
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ApiClearAction: () => dispatch(ApiClearAction()),
    sendQuotation: (data) => dispatch(sendQuotation(data)),
    getQuotationDetail: (quotationId, supplierId) => dispatch(getQuotationDetail(quotationId, supplierId))

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrepareQuotation);