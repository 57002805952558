import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetAllPortAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function getAllPort(filterQuery) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getAllPort" }));
        let url = "";
        if (filterQuery !== '' || filterQuery !== undefined) {
            url = Constant.apiURl + "/port?" + filterQuery
        } else {
            url = Constant.apiURl + "/port"
        }
        let myJson = await FETCH("GET", url);
        if (myJson && myJson.code === 201) {
            dispatch(GetAllPortAction(myJson.data))
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getAllPort",
                    message: myJson.message,
                }))
        }
        else {
            dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getAllPort", message: myJson.message }))
        }
    };
}