import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Wrapper } from "../Css/sidebar";
import PerfectScrollbar from 'perfect-scrollbar';
import { getDashboardLink, isAllowPermission } from '../../../Services/common';
import { ApiClearAction, ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { logOutAction } from "../../Auth/Actions/action";
import { connect } from 'react-redux';
import { getCurrentUserVessel } from './../../Common/ApiCalls/vesselList';
import { rateSupplier } from "../../Supplier/ApiCalls/supplier";
import { logoutUser } from "../../../Services/common";
import cloneDeep from 'lodash.clonedeep';
import { getRoutesDetail, getRoutePathBykey } from '../../../Constants/routesConfig';
import { FeedbackModal } from './feedbackModel';
import $ from 'jquery';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

const initialDropdown = () => {
    let url = window.location.pathname;
    let val = '';
    if (url === '/port-listing' || url === '/create-port') {
        val = 'port';
    } if (url === '/reminder-listing' || url === '/create-reminder') {
        val = 'reminder';
    } else if (url === '/role-listing' || url === '/addRole') {
        val = 'role'
    } else if (url === '/country-listing' || url === '/create-country') {
        val = 'country'
    } else if (url === '/file-upload' || url === '/supplier-catalogue-listing') {
        val = 'upload'
    } else if (url === '/department-listing' || url === '/create-department') {
        val = 'department'
    } else if (url === '/captain-listing' || url === '/create-captain') {
        val = 'captain'
    } else if (url === '/package-listing' || url === '/create-package') {
        val = 'package'
    } else if (url === '/user-listing' || url === '/create-user') {
        val = 'user'
    } else if (url === '/supplier-listing' || url === '/create-supplier') {
        val = 'supplier'
    } else if (url === '/vessel-listing' || url === '/create-vessel') {
        val = 'vessel'
    } else if (url === '/customer-listing') {
        val = 'customer'
    } else if (url === '/open-orders' || url === '/inprogress-quotations' || url === '/inprogress-orders' || url === "/inventory-list") {
        val = 'currentOrder'
    } else if (url === '/supplierProfile' || url === '/top-supply-hub' || url === '/top-supplier' || url === '/catalogue-overview' || url === '/upgrademesh' || url === '/supplier-ranking' || url === '/CurrentOrders' || url === '/FleetOverview' || url === '/send-rfq' || url === '/FleetOverview' || url === '/send-rfq' || url === '/supplier-dashboard' || url === '/orderOverview' || url === '/SupplierOverviewCatalogue' || url === '/SupplierSelection') {
        val = 'completedPages'
    } else if (url === '/role-listing' || url === '/addRole') {
        val = 'role'
    } else if (url === '/subAdmins' || url === '/add-SubAdmin') {
        val = 'subAdmin'
    }
    return val;
}
function Sidebar(props) {
    const [state, setState] = useState({
        currentDropdown: initialDropdown(),
        isHitApi: false,
        vesselList: [],
        isRoleUpdated: false,
        dropdownsPermission: {
            vessel: {
                isShow: false,
                create: false,
                list: false
            },
            user: {
                isShow: false,
                create: false,
                list: false
            },
            captain: {
                isShow: false,
                create: false,
                list: false
            },
            port: {
                isShow: false,
                create: false,
                list: false
            },
            reminder: {
                isShow: false,
                create: false,
                list: false
            },
            country: {
                isShow: false,
                create: false,
                list: false
            },
            department: {
                isShow: false,
                create: false,
                list: false
            },
            supplier: {
                isShow: false,
                create: false,
                list: false
            },
            package: {
                isShow: false,
                create: false,
                list: false
            },
            customerList: {
                isShow: false,
                list: false
            },
            curentOrder: {
                isShow: false,
                rfq: false,
                order: false,
                inCompleteOrder: false
            },
        },
        routesDetail: getRoutesDetail()
    });
    const history = useHistory();
    useEffect(() => {
        new PerfectScrollbar('#sidebarListing');
        if (!state.isHitApi && props.userType === 'customer' && props.isShipManager && window.location.pathname !== '/contract-office') {
            let data = { ...state };
            props.getCurrentUserVessel(props.userId, '');
            data.isHitApi = true;
            setState(data);
        }
        if (props.apiCallStatus.apiCallFor === "getCurrentUserVessel" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                vesselList: props.commonReducer.currentUserVessel,
                isHitApi: true
            })
        }
        if (!state.isRoleUpdated && props.isSubAdmin && props.userType === "user") {
            checkDropdownPermission()
        }
        if ((props.apiCallStatus.message === "User does not exist." && props.apiCallStatus.statusCode === 405) ||
            (props.apiCallStatus.message === "Your session has been expired." && props.apiCallStatus.statusCode === 403)) {
            logoutUserAction();
            history.push('/?msg=expired')
        }
    }, [props])

    // useEffect(() => {
    //     if (props.subType === 'captain') {
    //         $('#feedbackModal').modal('show');
    //     }
    // }, [])
    const openDropdown = (name) => {
        if (state.currentDropdown === name) {
            setState({
                ...state,
                currentDropdown: ''
            })
        } else {
            setState({
                ...state,
                currentDropdown: name
            })
        }
    }
    const logoutUserAction = () => {
        props.logOutAction();
        logoutUser();
    }
    const checkDropdownPermission = () => {
        let dropdownsPermission = cloneDeep(state.dropdownsPermission);
        if (props.roles.length === 0 || isAllowPermission('vessel', props.roles)) {
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["vesselList"]) !== -1 || props.roles.indexOf("/delete-vessel") !== -1 || props.roles.indexOf(state.routesDetail["editVessel"]) !== -1) {
                dropdownsPermission['vessel']['list'] = true;
                dropdownsPermission['vessel']['isShow'] = true;
            }
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["addVessel"]) !== -1) {
                dropdownsPermission['vessel']['create'] = true;
                dropdownsPermission['vessel']['isShow'] = true;
            }
        }
        if (props.roles.length === 0 || isAllowPermission('user', props.roles)) {
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["userList"]) !== -1 || props.roles.indexOf("/delete-user") !== -1 || props.roles.indexOf(state.routesDetail["editUser"]) !== -1) {
                dropdownsPermission['user']['list'] = true;
                dropdownsPermission['user']['isShow'] = true;
            }
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["addUser"]) !== -1) {
                dropdownsPermission['user']['create'] = true;
                dropdownsPermission['user']['isShow'] = true;
            }
        }
        if (props.roles.length === 0 || isAllowPermission('captain', props.roles)) {
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["captainList"]) !== -1 || props.roles.indexOf("/delete-captain") !== -1 || props.roles.indexOf(state.routesDetail["editCaptain"]) !== -1) {
                dropdownsPermission['captain']['list'] = true;
                dropdownsPermission['captain']['isShow'] = true;
            }
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["addCaptain"]) !== -1) {
                dropdownsPermission['captain']['create'] = true;
                dropdownsPermission['captain']['isShow'] = true;
            }
        }
        if (props.roles.length === 0 || isAllowPermission('port', props.roles)) {
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["portList"]) !== -1 || props.roles.indexOf("/delete-port") !== -1 || props.roles.indexOf(state.routesDetail["editPort"]) !== -1) {
                dropdownsPermission['port']['list'] = true;
                dropdownsPermission['port']['isShow'] = true;
            }
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["addPort"]) !== -1) {
                dropdownsPermission['port']['create'] = true;
                dropdownsPermission['port']['isShow'] = true;
            }
        }
        if (props.roles.length === 0 || isAllowPermission('reminder', props.roles)) {
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["reminderList"]) !== -1 || props.roles.indexOf("/delete-reminder") !== -1 || props.roles.indexOf(state.routesDetail["editReminder"]) !== -1) {
                dropdownsPermission['reminder']['list'] = true;
                dropdownsPermission['reminder']['isShow'] = true;
            }
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["addPort"]) !== -1) {
                dropdownsPermission['reminder']['create'] = true;
                dropdownsPermission['reminder']['isShow'] = true;
            }
        }
        if (props.roles.length === 0 || isAllowPermission('country', props.roles)) {
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["countryList"]) !== -1 || props.roles.indexOf("/delete-country") !== -1 || props.roles.indexOf(state.routesDetail["editCoutnry"]) !== -1) {
                dropdownsPermission['country']['list'] = true;
                dropdownsPermission['country']['isShow'] = true;
            }
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["addCountry"]) !== -1) {
                dropdownsPermission['country']['create'] = true;
                dropdownsPermission['country']['isShow'] = true;
            }
        }
        if (props.roles.length === 0 || isAllowPermission('department', props.roles)) {
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["departmentList"]) !== -1 || props.roles.indexOf("/delete-department") !== -1 || props.roles.indexOf(state.routesDetail["editDepartment"]) !== -1) {
                dropdownsPermission['department']['list'] = true;
                dropdownsPermission['department']['isShow'] = true
            }
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["addDepartment"]) !== -1) {
                dropdownsPermission['department']['create'] = true;
                dropdownsPermission['department']['isShow'] = true;
            }
        }
        if (props.roles.length === 0 || isAllowPermission('supplier', props.roles)) {
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["supplierList"]) !== -1 || props.roles.indexOf("/delete-supplier") !== -1 || props.roles.indexOf(state.routesDetail["editSupplier"]) !== -1) {
                dropdownsPermission['supplier']['list'] = true;
                dropdownsPermission['supplier']['isShow'] = true;
            }
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["addSupplier"]) !== -1) {
                dropdownsPermission['supplier']['create'] = true;
                dropdownsPermission['supplier']['isShow'] = true;
            }
        }
        if (props.roles.length === 0 || isAllowPermission('package', props.roles)) {
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["packageList"]) !== -1 || props.roles.indexOf("/delete-package") !== -1 || props.roles.indexOf(state.routesDetail["editPackage"]) !== -1) {
                dropdownsPermission['package']['list'] = true;
                dropdownsPermission['package']['isShow'] = true;
            }
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["addPackage"]) !== -1) {
                dropdownsPermission['package']['create'] = true;
                dropdownsPermission['package']['isShow'] = true;
            }
        }
        if (props.roles.length === 0 || isAllowPermission(state.routesDetail["customerList"], props.roles)) {
            dropdownsPermission['customerList']['isShow'] = true
            dropdownsPermission['customerList']['list'] = true
        }
        if (props.roles.length === 0 || isAllowPermission(state.routesDetail["inProgressOrders"], props.roles) || isAllowPermission('send', props.roles)) {
            let path = state.routesDetail["SendRfq"];
            let rfq = path.replace(":type", "rfq");
            let order = path.replace(":type", "order");
            if (props.roles.length === 0 || props.roles.indexOf(rfq) !== -1) {
                dropdownsPermission['curentOrder']['rfq'] = true;
                dropdownsPermission['curentOrder']['isShow'] = true;
            }
            if (props.roles.length === 0 || props.roles.indexOf(order) !== -1) {
                dropdownsPermission['curentOrder']['order'] = true;
                dropdownsPermission['curentOrder']['isShow'] = true;
            }
            if (props.roles.length === 0 || props.roles.indexOf(state.routesDetail["inProgressOrders"]) !== -1) {
                dropdownsPermission['curentOrder']['order'] = true;
                dropdownsPermission['curentOrder']['inCompleteOrder'] = true;
            }
        }
        setState({
            ...state,
            isRoleUpdated: true,
            dropdownsPermission
        })
    }
    return (
        <Wrapper >
            <div id="dash-menubar" className="dash-menubar">
                <Link to={getDashboardLink()} className="dash-logo-wrap">
                    <img className="img-fluid" src={require('../../../assets/images/logo.png')} alt="logo" />
                    <button type="button" className="btn btn-link sidebar-toggle">
                        <img className="img-fluid" src={require('../../../assets/images/left-arrow.svg')} alt="icon" />
                    </button>
                </Link>
                <div id="sidebarListing" className="dash-menu-content">
                    <ul className="list-inline p-0 dash-menu-listing">
                        {
                            props.userType === 'supplier' ?
                                <>
                                    <li className="dash-menu-item"><Link to={getDashboardLink()} className="dash-menulinks"><img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Dashboard</Link></li>
                                    <li className="dash-menu-item">
                                        <div className="dropdown">
                                            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (state.currentDropdown === 'upload' ? " active" : "")} onClick={() => openDropdown('upload')} type="button">
                                                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Upload
                                            </button>
                                            <div className={state.currentDropdown === 'upload' ? 'dropdown-menu show' : 'dropdown-menu'}>
                                                <Link to="/file-upload" className={window.location.pathname === '/file-upload' ? "dropdown-item active" : "dropdown-item"}>Upload Catalogue</Link>
                                                <Link to="/supplier-catalogue-listing" className={window.location.pathname === '/supplier-catalogue-listing' ? "dropdown-item active" : "dropdown-item"} >All Catalogues</Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="dash-menu-item">
                                        <div className="dropdown">
                                            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (state.currentDropdown === 'currentOrder' ? " active" : "")} onClick={() => openDropdown('currentOrder')} type="button">
                                                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Current Order
                                        </button>
                                            <div className={state.currentDropdown === 'currentOrder' ? 'dropdown-menu show' : 'dropdown-menu'}>
                                                <Link to="/inprogress-quotations" className={window.location.pathname === '/inprogress-quotations' ? "dropdown-item active" : "dropdown-item"} >RFQ</Link>
                                                <Link to="/open-orders" className={window.location.pathname === '/open-orders' ? "dropdown-item active" : "dropdown-item"} >Orders</Link>
                                                {props.userType !== 'supplier' ?
                                                    <React.Fragment>
                                                        {/* <Link to="/inprogress-orders" className={window.location.pathname === '/inprogress-orders' ? "dropdown-item active" : "dropdown-item"} >Incomplete Orders</Link> */}
                                                        <Link to="/inventory-list" className={window.location.pathname === '/inventory-list' ? "dropdown-item active" : "dropdown-item"} >Inventory</Link>
                                                    </React.Fragment> : ""}

                                            </div>
                                        </div>
                                    </li>
                                    {/* <li className="dash-menu-item">

                                         <Link className="dropdown-item" to="/orderOverview" ><img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Vessel Overview </Link> </li> */}
                                    {/* <li className="dash-menu-item"><Link to="/upgrade-client" className="dash-menulinks"><img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Upgrade</Link></li> */}
                                    <li className="dash-menu-item logout-link-item">
                                        <Link to="/" className="dash-menulinks" onClick={() => logoutUserAction()}>
                                            <img className="img-fluid" src={require('../../../assets/images/logout.svg')} alt="icon" /> Logout</Link>
                                    </li>
                                </>
                                :
                                <>
                                    <li className="dash-menu-item"><Link to={getDashboardLink()} className="dash-menulinks"><img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Dashboard</Link></li>
                                    <OrderBasketDropDown roles={props.roles} isSubAdmin={props.isSubAdmin} userType={props.userType} />
                                    {props.userType === 'user' && !props.isSubAdmin ? <li className="dash-menu-item">
                                        <div className="dropdown">

                                            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (state.currentDropdown === 'upload' ? " active" : "")} onClick={() => openDropdown('upload')} type="button">
                                                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Upload
                                            </button>


                                            <div className={state.currentDropdown === 'upload' ? 'dropdown-menu show' : 'dropdown-menu'}>
                                                <Link to="/file-upload" className={window.location.pathname === '/file-upload' ? "dropdown-item active" : "dropdown-item"}>Upload Catalogue</Link>
                                                <Link to="/supplier-catalogue-listing" className={window.location.pathname === '/supplier-catalogue-listing' ? "dropdown-item active" : "dropdown-item"} >All Catalogues</Link>
                                            </div>
                                        </div>
                                    </li> : ''}

                                    {props.userType === 'user' && (props.subType === "user" || props.subType === 'subAdmin') ?
                                        <li className="dash-menu-item"><Link to="/vessel-listing?fleetOverview=1" className="dash-menulinks"><img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Fleet Overview</Link></li>
                                        //         <li className="dash-menu-item">
                                        //             <div className="dropdown">
                                        //                 <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (state.currentDropdown === 'fleetOverview' ? " active" : "")} onClick={() => openDropdown('fleetOverview')} type="button">
                                        //                     <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" />Fleet Overview
                                        //    </button>
                                        //                 <div className={state.currentDropdown === 'fleetOverview' ? 'dropdown-menu show' : 'dropdown-menu'}>
                                        //                     {
                                        //                         state.vesselList.map((item, index) => {
                                        //                             return <Link to={`/contract-vessel?vesselId=${item._id}`} key={index} className="dropdown-item" >{item.name}</Link>
                                        //                         })
                                        //                     }

                                        //                 </div>
                                        //             </div>
                                        //         </li> : ''
                                        : ""
                                    }
                                    <CustomerDropDown isSubAdmin={props.isSubAdmin} userType={props.userType} currentDropdown={state.currentDropdown} openDropdown={openDropdown} permission={state.dropdownsPermission.customerList} routes={{ list: state.routesDetail.customerList }} />
                                    <CurrentOrderDropDown isSubAdmin={props.isSubAdmin} userType={props.userType} subType={props.subType} currentDropdown={state.currentDropdown} openDropdown={openDropdown} permission={state.dropdownsPermission.curentOrder} routes={{ inCompleteOrder: state.routesDetail.inProgressOrders, orders: state.routesDetail.openOrder, rfq: state.routesDetail.inprogressQuotation }} />
                                    {/* Port DropDown */}
                                    <PortDropDown isSubAdmin={props.isSubAdmin} userType={props.userType} currentDropdown={state.currentDropdown} openDropdown={openDropdown} permission={state.dropdownsPermission.port} routes={{ list: state.routesDetail.portList, create: state.routesDetail.addPort }} />
                                    {/* Reminder Dropdown */}
                                    <ReminderDropDown isSubAdmin={props.isSubAdmin} userType={props.userType} currentDropdown={state.currentDropdown} openDropdown={openDropdown} permission={state.dropdownsPermission.reminder} routes={{ list: state.routesDetail.reminderList, create: state.routesDetail.addReminder }} />
                                    {props.userType === 'user' && !props.isSubAdmin ? <li className="dash-menu-item">
                                        <div className="dropdown">
                                            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (state.currentDropdown === 'role' ? " active" : "")} onClick={() => openDropdown('role')} type="button">
                                                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Role
                                        </button>
                                            <div className={state.currentDropdown === 'role' ? 'dropdown-menu show' : 'dropdown-menu'}>
                                                <Link to="/role-listing" className={window.location.pathname === '/role-listing' ? "dropdown-item active" : "dropdown-item"} >All Roles</Link>
                                                <Link to="/addRole" className={window.location.pathname === '/addRole' ? "dropdown-item active" : "dropdown-item"} >Create Role</Link>
                                            </div>
                                        </div>
                                    </li> : ''}
                                    {props.userType === 'user' && !props.isSubAdmin ? <li className="dash-menu-item">
                                        <div className="dropdown">
                                            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (state.currentDropdown === 'subAdmin' ? " active" : "")} onClick={() => openDropdown('subAdmin')} type="button">
                                                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Sub-Admins
                                        </button>
                                            <div className={state.currentDropdown === 'subAdmin' ? 'dropdown-menu show' : 'dropdown-menu'}>
                                                <Link to="/subAdmins" className={window.location.pathname === '/subAdmins' ? "dropdown-item active" : "dropdown-item"} >All Sub-Admin</Link>
                                                <Link to="/add-SubAdmin" className={window.location.pathname === '/add-SubAdmin' ? "dropdown-item active" : "dropdown-item"} >Create Sub-Admin</Link>
                                            </div>
                                        </div>
                                    </li> : ''}
                                    {/*Country DropDown  */}
                                    <CountryDropDown isSubAdmin={props.isSubAdmin} userType={props.userType} currentDropdown={state.currentDropdown} openDropdown={openDropdown} permission={state.dropdownsPermission.country} routes={{ list: state.routesDetail.countryList, create: state.routesDetail.addCountry }} />
                                    {/* DepartmentDropDown */}
                                    <DepartmentDropDown isSubAdmin={props.isSubAdmin} userType={props.userType} currentDropdown={state.currentDropdown} openDropdown={openDropdown} permission={state.dropdownsPermission.department} routes={{ list: state.routesDetail.departmentList, create: state.routesDetail.addDepartment }} />
                                    {/* CaptainDropDown */}
                                    {/* <CaptainDropDown isSubAdmin={props.isSubAdmin} userType={props.userType} currentDropdown={state.currentDropdown} openDropdown={openDropdown} permission={state.dropdownsPermission.captain} routes={{ list: state.routesDetail.captainList, create: state.routesDetail.addCaptain }} /> */}
                                    {/* Package Dropdown */}
                                    <PackageDropDown isSubAdmin={props.isSubAdmin} userType={props.userType} currentDropdown={state.currentDropdown} openDropdown={openDropdown} permission={state.dropdownsPermission.package} routes={{ list: state.routesDetail.packageList, create: state.routesDetail.addPackage }} />
                                    {/* User Dropdown */}
                                    <UserDropDown isSubAdmin={props.isSubAdmin} userType={props.userType} currentDropdown={state.currentDropdown} openDropdown={openDropdown} permission={state.dropdownsPermission.user} routes={{ list: state.routesDetail.userList, create: state.routesDetail.addUser }} />

                                    {/* Supplier Dropdown */}
                                    <SupplierDropDown isSubAdmin={props.isSubAdmin} userType={props.userType} currentDropdown={state.currentDropdown} openDropdown={openDropdown} permission={state.dropdownsPermission.supplier} routes={{ list: state.routesDetail.supplierList, create: state.routesDetail.addSupplier }} />
                                    {/* Vessel Dropdown */}
                                    <VesselDropDown isSubAdmin={props.isSubAdmin} userType={props.userType} currentDropdown={state.currentDropdown} openDropdown={openDropdown} permission={state.dropdownsPermission.vessel} routes={{ list: state.routesDetail.vesselList, create: state.routesDetail.addVessel }} />
                                    {/* {props.userType !== 'customer' && props.subType !== 'captain' ?
                                        <li className="dash-menu-item">
                                            <Link to="/upgrade-client" className="dash-menulinks"><img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Upgrade</Link></li>
                                        : ""} */}
                                    {/* Drop Menu */}

                                    <li className="dash-menu-item logout-link-item">
                                        <Link to="/" className="dash-menulinks" onClick={() => logoutUserAction()}>
                                            <img className="img-fluid" src={require('../../../assets/images/logout.svg')} alt="icon" /> Logout</Link>
                                    </li>
                                </>
                        }

                    </ul>
                </div>
            </div>
            {
                props.subType === 'captain' ?
                    <FeedbackModal ClearApiByNameAction={props.ClearApiByNameAction} apiCallStatus={props.apiCallStatus} rateSupplier={props.rateSupplier} userId={props.userId} subType={props.subType} />
                    : ''
            }
        </Wrapper>
    )
};

function CurrentOrderDropDown(props) {
    let isAllow = !props.isSubAdmin;
    return (isAllow || props.permission.isShow) && props.userType !== 'customer' ? <li className="dash-menu-item">
        <div className="dropdown">
            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (props.currentDropdown === 'currentOrder' ? " active" : "")} onClick={() => props.openDropdown('currentOrder')} type="button">
                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Current Order
</button>
            <div className={props.currentDropdown === 'currentOrder' ? 'dropdown-menu show' : 'dropdown-menu'}>
                {isAllow || props.permission.rfq ? <Link to={props.routes.rfq} className={window.location.pathname === '/inprogress-quotations' ? "dropdown-item active" : "dropdown-item"} >RFQ</Link> : ""}
                {isAllow || props.permission.order ? <Link to={props.routes.orders} className={window.location.pathname === '/open-orders' ? "dropdown-item active" : "dropdown-item"} >Orders</Link> : ""}
                {props.userType !== 'supplier' ? <Link to={"/inventory-list"} className={window.location.pathname === '/inventory-list' ? "dropdown-item active" : "dropdown-item"} >Inventory</Link> : ""}
                {/* {(isAllow || props.permission.inCompleteOrder) && props.userType !== 'supplier' && props.subType !== 'captain' ?
                    <Link to={props.routes.inCompleteOrder} className={window.location.pathname === '/inprogress-orders' ? "dropdown-item active" : "dropdown-item"} >Incomplete Orders</Link>
                    : ""} */}
            </div>
        </div>
    </li> : ''
}
function OrderBasketDropDown(props) {
    let index = -1;
    let isAllow = false;
    if (props.userType === 'user' && props.isSubAdmin) {
        if (props.roles.length === 0 || (props.roles.findIndex(x => x === getRoutePathBykey('orderBasket')) !== -1)) {
            isAllow = true;
        }
    };
    return isAllow ? <li className="dash-menu-item"><Link to="/order-basket" className="dash-menulinks"><img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Order Basket</Link></li> : ''
}
function CustomerDropDown(props) {
    let isAllow = ((props.userType === 'user' && !props.isSubAdmin));
    return isAllow || props.permission.isShow ? <li className="dash-menu-item">
        <div className="dropdown">
            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (props.currentDropdown === 'customer' ? " active" : "")} onClick={() => props.openDropdown('customer')} type="button">
                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Customers
</button>
            <div className={props.currentDropdown === 'customer' ? 'dropdown-menu show' : 'dropdown-menu'}>
                <Link to={props.routes.list} className={window.location.pathname === '/customer-listing' ? "dropdown-item active" : "dropdown-item"} >All Customer</Link>
            </div>
        </div>
    </li> : ''
}
function PackageDropDown(props) {
    let isAllow = ((props.userType === 'user' && !props.isSubAdmin));
    return isAllow || props.permission.isShow ? <li className="dash-menu-item">
        <div className="dropdown">
            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (props.currentDropdown === 'package' ? " active" : "")} onClick={() => props.openDropdown('package')} type="button">
                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" />Package
</button>
            <div className={props.currentDropdown === 'package' ? 'dropdown-menu show' : 'dropdown-menu'}>
                {isAllow || props.permission.list ? <Link to={props.routes.list} className={window.location.pathname === '/package-listing' ? "dropdown-item active" : "dropdown-item"} >All Packages</Link> : ""}
                {isAllow || props.permission.create ? <Link to={props.routes.create} className={window.location.pathname === '/create-package' ? "dropdown-item active" : "dropdown-item"} >Create Package</Link> : ""}
            </div>
        </div>
    </li> : ''
}
function SupplierDropDown(props) {
    let isAllow = ((props.userType === 'user' && !props.isSubAdmin));
    return isAllow || props.permission.isShow ? <li className="dash-menu-item">
        <div className="dropdown">
            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (props.currentDropdown === 'supplier' ? " active" : "")} onClick={() => props.openDropdown('supplier')} type="button">
                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Supplier
</button>
            <div className={props.currentDropdown === 'supplier' ? 'dropdown-menu show' : 'dropdown-menu'}>
                {isAllow || props.permission.list ? <Link to={props.routes.list} className={window.location.pathname === '/supplier-listing' ? "dropdown-item active" : "dropdown-item"} >All Suppliers</Link> : ""}
                {isAllow || props.permission.create ? <Link to={props.routes.create} className={window.location.pathname === '/create-supplier' ? "dropdown-item active" : "dropdown-item"} >Create Supplier</Link> : ""}
            </div>
        </div>
    </li> : ''
}
function CountryDropDown(props) {
    let isAllow = ((props.userType === 'user' && !props.isSubAdmin));
    return isAllow || props.permission.isShow ? <li className="dash-menu-item">
        <div className="dropdown">
            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (props.currentDropdown === 'country' ? " active" : "")} onClick={() => props.openDropdown('country')} type="button">
                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Country
</button>
            <div className={props.currentDropdown === 'country' ? 'dropdown-menu show' : 'dropdown-menu'}>
                {isAllow || props.permission.list ? <Link to={props.routes.list} className={window.location.pathname === '/country-listing' ? "dropdown-item active" : "dropdown-item"}>All Countries</Link> : ""}
                {isAllow || props.permission.create ? <Link to={props.routes.create} className={window.location.pathname === '/create-country' ? "dropdown-item active" : "dropdown-item"} >Create Country</Link> : ""}
            </div>
        </div>
    </li> : ''
}
function DepartmentDropDown(props) {
    let isAllow = ((props.userType === 'user' && !props.isSubAdmin));
    return isAllow || props.permission.isShow ? <li className="dash-menu-item">
        <div className="dropdown">
            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (props.currentDropdown === 'department' ? " active" : "")} onClick={() => props.openDropdown('department')} type="button">
                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Department
</button>
            <div className={props.currentDropdown === 'department' ? 'dropdown-menu show' : 'dropdown-menu'}>
                {isAllow || props.permission.list ? <Link to={props.routes.list} className={window.location.pathname === '/department-listing' ? "dropdown-item active" : "dropdown-item"}>All Department</Link> : ""}
                {isAllow || props.permission.create ? <Link to={props.routes.create} className={window.location.pathname === '/create-department' ? "dropdown-item active" : "dropdown-item"} >Create Department</Link> : ""}
            </div>
        </div>
    </li> : ''
}
function PortDropDown(props) {
    let isAllow = ((props.userType === 'user' && !props.isSubAdmin));
    return isAllow || props.permission.isShow ? <li className="dash-menu-item">
        <div className="dropdown">
            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (props.currentDropdown === 'port' ? " active" : "")} onClick={() => props.openDropdown('port')} type="button">
                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Port
    </button>
            <div className={props.currentDropdown === 'port' ? 'dropdown-menu show' : 'dropdown-menu'}>
                {isAllow || props.permission.list ? <Link to={props.routes.list} className={window.location.pathname === '/port-listing' ? "dropdown-item active" : "dropdown-item"} >All Ports</Link> : ""}
                {isAllow || props.permission.create ? <Link to={props.routes.create} className={window.location.pathname === '/create-port' ? "dropdown-item active" : "dropdown-item"} >Create Port</Link> : ""}
            </div>
        </div>
    </li> : ''
}
function ReminderDropDown(props) {
    let isAllow = ((props.userType === 'user' && !props.isSubAdmin));
    return isAllow || props.permission.isShow ? <li className="dash-menu-item">
        <div className="dropdown">
            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (props.currentDropdown === 'reminder' ? " active" : "")} onClick={() => props.openDropdown('reminder')} type="button">
                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Reminder
    </button>
            <div className={props.currentDropdown === 'reminder' ? 'dropdown-menu show' : 'dropdown-menu'}>
                {isAllow || props.permission.list ? <Link to={props.routes.list} className={window.location.pathname === '/reminder-listing' ? "dropdown-item active" : "dropdown-item"} >All Reminder</Link> : ""}
                {isAllow || props.permission.create ? <Link to={props.routes.create} className={window.location.pathname === '/create-reminder' ? "dropdown-item active" : "dropdown-item"} >Create Reminder</Link> : ""}
            </div>
        </div>
    </li> : ''
}
function CaptainDropDown(props) {
    let isAllow = ((props.userType === 'user' && !props.isSubAdmin));
    return isAllow || props.permission.isShow ? <li className="dash-menu-item">
        <div className="dropdown">
            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (props.currentDropdown === 'captain' ? " active" : "")} onClick={() => props.openDropdown('captain')} type="button">
                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Captain
</button>
            <div className={props.currentDropdown === 'captain' ? 'dropdown-menu show' : 'dropdown-menu'}>
                {isAllow || props.permission.list ? <Link to={props.routes.list} className={window.location.pathname === '/captain-listing' ? "dropdown-item active" : "dropdown-item"} >All Captains</Link> : ""}
                {isAllow || props.permission.create ?
                    <Link to={props.routes.create} className={window.location.pathname === '/create-captain' ? "dropdown-item active" : "dropdown-item"} >Create Captain</Link> : ""}
            </div>
        </div>
    </li> : ""
}
function UserDropDown(props) {
    let isAllow = ((props.userType === 'user' && !props.isSubAdmin));
    return isAllow || props.permission.isShow ? <li className="dash-menu-item">
        <div className="dropdown">
            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (props.currentDropdown === 'user' ? " active" : "")} onClick={() => props.openDropdown('user')} type="button">
                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> User
</button>
            <div className={props.currentDropdown === 'user' ? 'dropdown-menu show' : 'dropdown-menu'}>
                {isAllow || props.permission.list ? <Link to={props.routes.list} className={window.location.pathname === '/user-listing' ? "dropdown-item active" : "dropdown-item"} >All Users</Link> : ""}
                {isAllow || props.permission.create ? <Link to={props.routes.create} className={window.location.pathname === '/create-user' ? "dropdown-item active" : "dropdown-item"} >Create User</Link> : ""}
            </div>
        </div>
    </li> : ''
}
function VesselDropDown(props) {
    let isAllow = ((props.userType === 'user' && !props.isSubAdmin) || props.userType === 'customer');
    return isAllow || props.permission.isShow ? <li className="dash-menu-item">
        <div className="dropdown">
            <button className={"btn btn-link dropdown-toggle p-0 dash-menulinks" + (props.currentDropdown === 'vessel' ? " active" : "")} onClick={() => props.openDropdown('vessel')} type="button">
                <img className="img-fluid" src={require('../../../assets/images/Order-icon.svg')} alt="icon" /> Vessel
</button>
            <div className={props.currentDropdown === 'vessel' ? 'dropdown-menu show' : 'dropdown-menu'}>
                {isAllow || props.permission.list ?
                    <Link to={props.routes.list} className={window.location.pathname === '/vessel-listing' ? "dropdown-item active" : "dropdown-item"} >All Vessels</Link> : ""}
                {props.userType === 'user' && !props.isSubAdmin || (props.permission.create && props.isSubAdmin) ?
                    <Link to={props.routes.create} className={window.location.pathname === '/create-vessel' ? "dropdown-item active" : "dropdown-item"} >Create Vessel</Link> : ''}
            </div>
        </div>
    </li> : ''
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    commonReducer: state.commonReducer,
    isSubAdmin: state.authReducer.isSubAdmin,
    isShipManager: state.authReducer.isShipManager,
    userId: state.authReducer.userId,
    roles: state.authReducer.roles,
    userType: state.authReducer.userType,
    subType: state.authReducer.subType
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    rateSupplier: (data) => dispatch(rateSupplier(data)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    getCurrentUserVessel: (userId, vesselId) => dispatch(getCurrentUserVessel(userId, vesselId)),
    logOutAction: () => dispatch(logOutAction())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Sidebar);