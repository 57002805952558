export const getVessel = "GET_VESSEL";
export const appendVessalList = "APPEND_VESSEL_LIST";
export const getVesselDetail = "GET_VESSEL_DETAIL";
export const getQuestionaire = "GET_QUESTIONAIRE";

export function GetVesselAction(list, count) {
  return {
    type: getVessel,
    payload: { list, count },
  };
}
export function AppendVessalAction(list) {
  return {
    type: appendVessalList,
    payload: { list },
  };
}
export function GetVesselDetailAction(detail) {
  return {
    type: getVesselDetail,
    payload: { detail },
  };
}

export function QuestionaireAction(detail) {
  return {
    type: getQuestionaire,
    payload: { detail },
  };
}