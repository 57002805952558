import React, { useEffect } from 'react';
import $ from "jquery";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import { Wrapper } from '../Css/customSelect';
export default function CustomAutoComplete(props) {
    const scrollFunction = (o) => {
        if (Math.round($(o.target).scrollTop() + $(o.target).innerHeight()) >= $(o.target)[0].scrollHeight) {
            props.fetchMore();
        }
    }
    const handleEnter = (event, id) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            props.clickCustomeSelectHandler(id, event.target.value);
            let elem = $("#dropdown-" + props.inputId);
            if (elem.hasClass("show")) {
                elem.parent().removeClass("show");
                elem.removeClass("show");
                elem.removeAttr("style")
            }
        }
    }
    return (
        <Wrapper>
            <div className="dropdown custom-data pagin-select-menu">
                {/* <button className="btn w-100 dropdown-toggle" type="button" data-toggle="dropdown">{props.value !== "" ? props.value : props.placeholder}</button> */}
                <input type="text" className="w-100 dropdown-toggle" placeholder={props.placeholder} data-toggle="dropdown" value={props.inputValue} id={props.inputId} onChange={(e) => props.handleChange(props.inputId, e.target.value)} onKeyUp={(e) => handleEnter(e, props.id)} autoComplete="off"/>
                <ul id={"dropdown-" + props.inputId} className="dropdown-menu show-left filter-drop"
                    aria-labelledby="dropdownMenuButton">
                    {/* <div className="filter-search-wrap">
                        <input type="text" placeholder="Search...." value={props.inputValue} id={props.inputId} onChange={(e) => props.handleChange(props.inputId, e.target.value)} />
                    </div> */}
                    <div className="starting-page-box" onScroll={(e) => scrollFunction(e)}>
                        {props.pagesList && props.pagesList.length ?
                            <div>
                                {<ul className="filter-list-items">
                                    {props.pagesList.map((item, index) => {
                                        return (
                                            <li key={index} onClick={(e) => props.clickCustomeSelectHandler(props.id, (!props.isReturnObj ? item.value : item))}>
                                                {item.label}
                                            </li>)
                                    })}
                                </ul>
                                }
                                {props.pagesList.length < props.pageListTotal && props.pageListTotal ?
                                    <div className="loadmore" ><img src={loader} alt="" /></div>
                                    : ""}
                            </div>
                            : <ul><li className="text-center no-page"><p className="mb-0">No data found</p></li></ul>
                        }
                    </div>
                </ul>
            </div>
        </Wrapper>
    )
}