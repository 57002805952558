import React, { useEffect, useState } from 'react';
import { getSupplierDetail, editSupplier } from "../../ApiCalls/supplier";
import { ApiClearAction, ClearApiByNameAction } from "../../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import { getAllPort } from "../../../Common/ApiCalls/portList";
import { getAllCountry } from "../../../Common/ApiCalls/countryList";
import { SupplierForm } from './supplierForm';
import { Wrapper } from '../../../Common/Components/CommonWrapper';
import  DashboardNavbar  from '../../../Common/Components/dashboardNavbar';
import { isAllowPermission } from '../../../../Services/common';
import $ from 'jquery';
import cloneDeep from 'lodash.clonedeep';
import Swal from 'sweetalert2'
const formInitialState = {
    name: "",
    email: "",
    password: "",
    title: "",
    description: "",
    logo: [],
    companyName: "",
    websiteUrl: "",
    images: [],
    searchPort: "",
    supplierPorts: [],
    portList: [],
    countryList: [],
    isHitApi: false
}
function EditSupplier(props) {
    const [state, setState] = useState(formInitialState);
    const [isHitOnLoadApi, setIsHitOnLoadApi] = useState(false);
    useEffect(() => {
        $('#logo').removeAttr('required');
        $('#images').removeAttr('required');
        $('#password').removeAttr('required');
        if (!isHitOnLoadApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.getAllCountry('');
                props.getSupplierDetail(props.match.params.id)
                setIsHitOnLoadApi(true)
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getSupplierDetail") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState({
                ...state,
                ...props.supplierReducer.detail
            });
            props.ClearApiByNameAction("getSupplierDetail");
        }
        if ((props.apiCallStatus.apiCallFor === "getAllPort") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let arr = props.portList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].port, countryName: arr[i].countryName.toUpperCase() });
            }
            setState({
                ...state,
                portList: newArr,
            })
            props.ClearApiByNameAction("getAllPort");
        }
        if ((props.apiCallStatus.apiCallFor === "getAllCountry") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let countryList = [];
            for (let index = 0; index < props.countryList.length; index++) {
                let val = props.countryList[index];
                countryList.push({ label: val.name, value: val._id, countryCode: val.countryCode })
            }
            setState({
                ...state,
                countryList: countryList,
            })
            props.ClearApiByNameAction("getAllCountry");
        }
        if ((props.apiCallStatus.apiCallFor === "editSupplier") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(formInitialState)
            props.ApiClearAction();
            Swal.fire("Success", `The supplier has been updated successfully`, "success")
            props.history.push('/supplier-listing')
        }
    });
    const handleFormChange = (e) => {
        if (e.target.type === 'file') {
            if (e.target.files.length > 0) {
                let name = e.target.name;
                let formDetail = { ...state }
                formDetail[name] = e.target.files;
                setState(formDetail)
            }
        } else {
            let name = e.target.name;
            let value = e.target.value;
            let formDetail = { ...state }
            formDetail[name] = value;
            if (name === 'country') {
                formDetail["searchPort"] = "";
                formDetail["supplierPorts"] = props.supplierReducer.detail.supplierPorts;
                formDetail["portList"] = []
                let query = "countryCode=" + value.countryCode;
                props.getAllPort(query);
            }
            setState(formDetail)
        }
    }
    const editSupplier = () => {
        let obj = {
            userId: props.userId,
            email: state.email,
            password: state.password,
            name: state.name,
            title: state.title,
            description: state.description,
            supplierPorts: state.supplierPorts,
            logo: state.logo,
            companyName: state.companyName,
            websiteUrl: state.websiteUrl,
            images: state.images
        }
        props.editSupplier(props.match.params.id, obj)
    }
    const handleSupplierPortChange = (id, obj) => {
        let arr = cloneDeep(state.supplierPorts);
        let data = { ...state };
        let index = state.supplierPorts.findIndex(x => x.value === obj.value);
        if (id === 'removePorts') {
            if (index !== -1) {
                arr.splice(index, 1);
            }
            data.supplierPorts = arr;
        } else {
            if (index === -1) {
                arr.push(obj);
            }
            data.supplierPorts = arr;
        }
        setState(data);
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Edit Supplier" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="formBox">
                <SupplierForm {...state} isEdit={true} history={props.history} handleFormChange={handleFormChange} handleSubmit={editSupplier} apiCallStatus={props.apiCallStatus} apiName="editSupplier" handleSupplierPortChange={handleSupplierPortChange} countryList={state.countryList} selectedPorts={props.supplierReducer.detail.supplierPorts} />
            </div>
        </Wrapper>
    )
}


const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    supplierReducer: state.supplierReducer,
    userId: state.authReducer.userId,
    roles: state.authReducer.roles,
    countryList: state.commonReducer.countryList,
    portList: state.commonReducer.portList,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getSupplierDetail: (id) => dispatch(getSupplierDetail(id)),
    editSupplier: (id, data) => dispatch(editSupplier(id, data)),
    getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
    getAllCountry: (query) => dispatch(getAllCountry(query)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditSupplier);
