import React, { useEffect, useState } from 'react';
import { Wrapper ,DashboardNavbar} from '../../Common/Components/index';
import { createCountry } from "./../ApiCalls/country";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import { getDashboardLink, isAllowPermission } from '../../../Services/common';
import Swal from 'sweetalert2'
import { getRoutePathBykey } from '../../../Constants/routesConfig';
const countryInitialState = {
    name: '',
    countryCode: '',
}
function CreateCountry(props) {
    const [state, setState] = useState(countryInitialState);
    useEffect(() => {
        if (!isAllowPermission(window.location.pathname, props.roles)) {
            props.history.push('/not-allowed')
        } else {
            if ((props.apiCallStatus.apiCallFor === "createCountry") &&
                props.apiCallStatus.isCompleted === true &&
                props.apiCallStatus.isFailed === false) {
                setState(countryInitialState)
                props.ApiClearAction();
                Swal.fire("Success", `The country has been created successfully.`, "success")
                if (props.isSubAdmin && !isAllowPermission(getRoutePathBykey('countryList'), props.roles)) {
                    props.history.push(getDashboardLink())
                } else {
                    props.history.push('/country-listing')
                }
            }
        }
    });
    const handleFormChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let countryDetail = { ...state }
        countryDetail[name] = value;
        setState(countryDetail)
    }
    const createHandler = () => {
        let obj = {
            name: state.name,
            countryCode: state.countryCode
        }
        props.createCountry(obj)
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Create Country" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="formBox mt-4">
                <CountryForm {...state} history={props.history} handleFormChange={handleFormChange} createHandler={createHandler} apiCallStatus={props.apiCallStatus} />
            </div>
        </Wrapper>
    )
}

function CountryForm(props) {
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            if (props.isEdit) {
                props.updateHandler();
            } else {
                props.createHandler();
            }
        }
    }
    return (
        <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="row">
                <div className="col-sm-6">

                    <div className="form-group floating-label-wrap">
                        <input type="text" name="name" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Country Name"
                            value={props.name} required />
                        <lable className="foating-labels-sty">Country Name</lable>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="countryCode" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Country Code"
                            value={props.countryCode} required />
                        <lable className="foating-labels-sty">Country Code</lable>
                    </div>
                </div>
            </div>
            {props.apiCallStatus.apiCallFor === "createCountry" && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                <div className="alert alert-danger">
                    {props.apiCallStatus.message}
                </div>
                : ""}
            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {props.apiCallStatus.apiCallFor === "createCountry" && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">{props.isEdit ? "Update" : "Submit"}</button>
                </div>
            </div>
        </form>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    roles: state.authReducer.roles,
    vesselReducer: state.vesselReducer,
    isSubAdmin: state.authReducer.isSubAdmin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createCountry: (data) => dispatch(createCountry(data)),
    ApiClearAction: () => dispatch(ApiClearAction())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateCountry);
