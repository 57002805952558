import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './dashboard-style.css';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cartListing } from '../ApiCalls/orderListing';
import { topItemsListing } from './../../Orders/ApiCalls/provisionOrder';
import { getAllVessel } from "../../Common/ApiCalls/vesselList";
import { supplierListing } from "../../Supplier/ApiCalls/supplier";
import { quotationListing } from "../../Quotations/Apicalls/quotations";
import { ApiClearAction, ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { getAllPort } from './../../Common/ApiCalls/portList';
import { topSupplyHub, topCustomer, topVessel } from './../../Common/ApiCalls/index';
import { getCurrentUserVessel } from './../../Common/ApiCalls/vesselList';
import { customerListing } from './../../Common/ApiCalls/customerList';
import { connect } from 'react-redux';
import CustomSelect from '../../Common/Components/CustomSelect';
// import Map from "../../Common/Components/jvectorMap";
import { Wrapper, DashboardNavbar } from './../../Common/Components/index';
import { capitalizeFirstLetter } from '../../../Services/common';

function SupplierDashboard(props) {
    const [state, setState] = useState({
        orderList: [],
        topItemsList: [],
        topCustomerList: [],
        topVesselList: [],
        portList: [],
        vesselList: [],
        vesselOption: [],
        qoutationList: [],
        customerList: [],
        totalCustomer: 0,
        totalQuotation: 0,
        searchCustomer: '',
        customerId: '',
        selectedOption: null,
        activePage: 0,
        totalOrderCount: 0,
        totalTopItems: 0,
        startDate: new Date(new Date().getTime() - (60 * 60 * 24 * 30 * 1000)),
        endDate: new Date(),
        vesselDate: null,
        isTopCustomer: false,
        isTopVessel: false,
        isTopPort: false,
        filterQuery: '',
        isHitApi: false,
    });
    useEffect(() => {
        if (!state.isHitApi) {
            let data = { ...state };
            data.isHitApi = true;
            setState(data);
            let from = moment(data.startDate).format('YYYY-MM-DD');
            let to = moment(data.endDate).add(1, 'days').format('YYYY-MM-DD');
            props.cartListing(0, `?offset=${0}&limit=${5}&supplierId=${props.userDetail.userId}`)
            props.topCustomer(props.userDetail.userId, 'supplier', from, to)
            props.topItemsListing(props.userDetail.userId, 'supplier', from, to)
            props.getAllVessel();
            props.topSupplyHub(props.userDetail.userId, 'supplier', from, to)
            props.topVessel(props.userDetail.userId, 'supplier', from, to)
            props.quotationListing(0, 4, 'rfq', false);
            props.customerListing(0, 'customer', state.searchCustomer ? `&email=${state.searchCustomer}` : '')

        }
        if (props.apiCallStatus.apiCallFor === "getCart" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                orderList: props.dashboardReducer.cartListing,
                totalOrderCount: props.dashboardReducer.totalCart,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        // if (props.apiCallStatus.apiCallFor === "getAllPort" &&
        //     props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
        //     setState({
        //         ...state,
        //         portList: props.commonReducer.portList
        //     })
        // }
        if (props.apiCallStatus.apiCallFor === "topSupplyHub" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                portList: props.commonReducer.topSupplyHubList
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getTopItems") &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                topItemsList: props.orderReducer.topItemsList,
                totalTopItems: props.orderReducer.totalTopItems,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "getAllVessel" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                vesselList: props.commonReducer.vesselList
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "topVessel" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                topVesselList: props.commonReducer.topVesselList,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if ((props.apiCallStatus.apiCallFor === "getCustomer" || props.apiCallStatus.apiCallFor === "appendCustomerList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let data = [];
            for (let i = 0; i < props.commonReducer.customerList.length; i++) {
                const element = props.commonReducer.customerList[i];
                data.push({ label: element.email, value: element.id })
            }
            setState({
                ...state,
                customerList: data,
                totalCustomer: props.commonReducer.totalCustomer,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "getCurrentUserVessel" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                vesselList: props.commonReducer.currentUserVessel
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        // if (props.apiCallStatus.apiCallFor === "supplierListing" &&
        //     props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
        //     setState({
        //         ...state,
        //         supplierList: props.supplierReducer.list,
        //     })
        // }
        if (props.apiCallStatus.apiCallFor === "topCustomer" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                topCustomerList: props.commonReducer.topCustomerList,
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getQuotation") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                qoutationList: props.quotationList.slice(0, 5),
                totalQuotation: props.totalQuotation,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
    }, [props, state.isHitApi])
    const handleChange = (id, val) => {
        if (id === 'vesselDate') {
            setState({
                ...state,
                [id]: val,
            })
            props.getCurrentUserVessel(state.customerId, '', val)
        } else {
            setState({
                ...state,
                [id]: val,
                isHitApi: false
            })
        }

    }
    const handleSelectChange = (id, val) => {
        setState({
            ...state,
            [id]: val,
        })
        props.customerListing(state.activePage, 'customer', val ? `&email=${val}` : '')

    }
    const clickCustomeSelectHandler = (id, val) => {
        setState({
            ...state,
            ['searchCustomer']: val.label,
            ['customerId']: val.value
        })
        let vesselDate = state.vesselDate !== null ? state.vesselDate : undefined
        props.getCurrentUserVessel(val.value, '', vesselDate)
    }
    const fetchMoreUserData = () => {
        let { activePage } = state;
        activePage += 1;
        setState({
            ...state,
            activePage
        })
        props.customerListing(activePage, 'customer', state.searchCustomer ? `&email=${state.searchCustomer}` : '')
    };
    const navbarOptions = [
        {
            img: 'Cancel.svg',
            className: "red",
            name: "Logout",
            path: "/",
            isLogout: true
        }
    ]
    const collapsHandle = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }
    return (
        <Wrapper>
            <DashboardNavbar title="Supplier Dashboard" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            {/* Order Status Content */}
            <div className="flex-fill dash-ord-content ordContent-table cust-turnover text-left">
                <OrderStatusWrapper label="Current Orders" link={state.orderList.length === 0 ? "#" : "/orderOverview"}>
                    {props.apiCallStatus.isStarted.indexOf("getCart") !== -1 ?
                        <td colSpan="20"><div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div></td> : state.orderList && state.orderList.length === 0 ?
                            <td colSpan="40" className="text-center"><div>No Data Found</div></td>
                            : state.orderList.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.vesselName}</td>
                                        <td>{item.customerName}</td>
                                        <td>{capitalizeFirstLetter(item.type)}</td>
                                        <td>{moment(item.arrivalTime).format("DD.MM.YYYY")}</td>
                                        <td>{item.placeOfDelivery.label}</td>
                                        <td>{item.supplierName}</td>
                                        <td>{item.totalPrice ? parseFloat(item.totalPrice.toFixed(2)) : '0'}</td>
                                        <td>{item.orderStatus === 'pending' ?
                                            <><span className="sta-indicator pend"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : item.orderStatus === 'confirmed' && !item.isSupplied ? <><span className="sta-indicator appr"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : item.orderStatus === 'confirmed' && item.isSupplied ? <><span className="sta-indicator past"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : item.orderStatus}</td>

                                    </tr>
                                )
                            })}
                </OrderStatusWrapper>
                <OrderStatusWrapper label="Current RFQ's" link={state.qoutationList.length === 0 ? "#" : "/inprogress-quotations"}>
                    {props.apiCallStatus.isStarted.indexOf("getQuotation") !== -1 ?
                        <td colSpan="20"><div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div></td> : state.qoutationList && state.qoutationList.length === 0 ?
                            <td colSpan="40" className="text-center"><div>No Data Found</div></td>
                            : state.qoutationList.map((item, index) => {
                                let status = item.status
                                return (
                                    <tr key={index}>
                                        <td>{item.vesselId && item.vesselId.name ? item.vesselId.name : ''}</td>
                                        <td>{item.customerName}</td>
                                        <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>
                                        <td>{moment(item.nextDeliveryDate).format("DD.MM.YYYY")}</td>
                                        <td>{item.nextDeliveryPlace}</td>
                                        <td>{item.supplierId && item.supplierId.name ? item.supplierId.name : ''}</td>
                                        <td>{item.rfqValue}</td>
                                        <td>{(status === 'inprogress' || status === 'pending') ?
                                            <><span className="sta-indicator pend"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : status === 'confirmed' ? <><span className="sta-indicator appr"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : status === 'pastorder' ? <><span className="sta-indicator past"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : status}</td>

                                    </tr>
                                )
                            })}
                </OrderStatusWrapper>
            </div>
            <div className="mt-lg-4 d-flex contract-dash-wrap">
                <div className="dashNew-ord-wrap d-flex flex-column align-items-start mr-lg-3 mr-0">
                    {/* YOUR NEW ORDER */}
                    {/* <div className="content-box-wrap dash-content-bx mb-0 d-flex flex-column contBx-2 cust-over-wrap">
                        <div className="content-box-head ord-status-head d-flex align-items-center flex-wrap px-2">
                            <h2 className="semi-bold mr-2 flex-fill justify-content-start">Customer Overview</h2>
                            <div className="d-flex align-items-center">
                                <div className="custom-select-menu text-bx-wrap custom-select2 p-0 mr-2">
                                    <CustomSelect pagesList={state.customerList} isReturnObj={true} fetchMore={fetchMoreUserData} value={state.searchCustomer} pageListTotal={state.totalCustomer} inputValue={state.searchCustomer} id="customer" inputId="searchCustomer" placeholder="Search Customer" handleChange={handleSelectChange} clickCustomeSelectHandler={clickCustomeSelectHandler} />
                                </div>

                                <div className="datePicker form-control field-bx-sty p-0 mh-auto">
                                    <DatePicker
                                        selected={state.vesselDate}
                                        placeholderText="Time frame"
                                        onChange={(val) => handleChange('vesselDate', val)} className="link-style"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="dashOrder-buttons-wrap flex-fill content-bx-body2 px-2 position-relative min-h200">
                            <div className="table-responsive autoHeight-scroll">
                                <table className="table table-transparent table-fz-12 table-border-none white-clr">
                                    <thead className="semi-bold dark-clr">
                                        <tr>
                                            <th>VESSEL</th>
                                            <th>ORDERS</th>
                                            <th>ORDER AMOUNT</th>
                                        </tr>
                                    </thead>
                                    <tbody className="dark-clr">
                                        {props.apiCallStatus.isStarted.indexOf("getCurrentUserVessel") !== -1 ?
                                            <td colSpan="20"><div className="loader-img text-center">
                                                <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                            </div></td> : state.vesselList && state.vesselList.length === 0 ?
                                                <td colSpan="40" className="text-center"><div>No Data Found</div></td>
                                                : state.vesselList && state.vesselList.length > 0 && state.vesselList.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.name}</td>
                                                            <td className="font-weight-bold dark-pink-clr">{item.rate} {item.currency}</td>
                                                            <td>2.578,00 EUR</td>
                                                        </tr>
                                                    )
                                                })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="mt-lg-5 d-flex contract-dash-wrap cont-ves-wrap">
                <div className="dashVesl-overview-wrap">
                    <div className="content-box-wrap dash-content-bx">
                        <div className="vessel-overview-wrap d-flex align-items-start">
                            <div className="content-box-head d-flex align-items-center justify-content-end head-transparent w-100 mb-3 flex-wrap">
                                {/* <h2 className="font-weight-bold dark-clr mr-3">OPERATING FIGURES</h2> */}
                                {/* Date Range Picker */}
                                <div className="date-range-picker d-flex align-items-center">
                                    <span className="d-inline-block mr-1">
                                        <DatePicker
                                            selected={state.startDate}
                                            onChange={(val) => handleChange('startDate', val)} className="link-style"
                                        />
                                    </span>
                                    to
                                    <span className="d-inline-block ml-1">
                                        <DatePicker
                                            selected={state.endDate}
                                            onChange={(val) => handleChange('endDate', val)} className="link-style"
                                        />
                                    </span>
                                </div>
                                {/* <button type="button" className="btn btn-link d-flex align-items-center p-0 dark-clr">View complete list</button> */}
                            </div>
                        </div>
                        <CollapsWrapper>
                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                <thead>
                                    <tr>
                                        <th className="small-unit40" onClick={() => collapsHandle('isTopCustomer', !state.isTopCustomer)}>
                                            <span className="dropdown-icon d-inline-block">
                                                <i className={state.isTopCustomer ? "fa fa-angle-down" : "fa fa-angle-right"}></i>
                                            </span>
                                        </th>
                                        <th>Top Customer</th>
                                        <th>Customer Email</th>
                                        <th>Fleet Size</th>
                                        <th>Client Type</th>
                                        <th>Number of Supplies</th>
                                        <th>Volume</th>
                                    </tr>
                                </thead>
                                <tbody className={`custom-dropdown-menu ${state.isTopCustomer ? 'show' : ''}`}>
                                    {props.apiCallStatus.isStarted.indexOf("topCustomer") !== -1 ?
                                        <td colSpan="20"><div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                        </div></td> : state.topCustomerList && state.topCustomerList.length === 0 ?
                                            <td colSpan="40" className="text-center"><div>No Data Found</div></td>
                                            : state.topCustomerList && state.topCustomerList.length > 0 && state.topCustomerList.map((item, index) => {
                                                if (index < 5)
                                                    return (
                                                        <tr key={index}>
                                                            <td>&nbsp;</td>
                                                            <td>{index + 1}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.fleetSize}</td>
                                                            <td>{capitalizeFirstLetter(item.type)}</td>
                                                            <td>{item.count}</td>
                                                            <td>{item.totalVolume ? item.totalVolume.toFixed(2) : 0}</td>
                                                        </tr>
                                                    )
                                            })}
                                    {state.topCustomerList && state.topCustomerList.length > 0 ?
                                        <tr>
                                            <td colSpan="11">
                                                <div className="d-flex justify-content-end">
                                                    <Link to="/customer-listing?topCustomer=1" className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</Link>
                                                </div>
                                            </td>
                                        </tr>
                                        : ""}
                                </tbody>

                            </table>
                        </CollapsWrapper>
                        <CollapsWrapper>
                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                <thead>
                                    <tr>
                                        <th className="small-unit40" onClick={() => collapsHandle('isTopVessel', !state.isTopVessel)}>
                                            <span className="dropdown-icon d-inline-block">
                                                <i className={state.isTopVessel ? "fa fa-angle-down" : "fa fa-angle-right"}></i>
                                            </span>
                                        </th>
                                        <th>Top Vessels</th>
                                        <th>Vessel Name</th>
                                        <th>Vessel Type</th>
                                        <th>Vessel Manager</th>
                                        <th>Number of Supplies</th>
                                        <th>Volume</th>
                                    </tr>
                                </thead>
                                <tbody className={`custom-dropdown-menu ${state.isTopVessel ? 'show' : ''}`}>
                                    {props.apiCallStatus.isStarted.indexOf("topCustomer") !== -1 ?
                                        <td colSpan="20"><div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                        </div></td> : state.topVesselList && state.topVesselList.length === 0 ?
                                            <td colSpan="40" className="text-center"><div>No Data Found</div></td>
                                            : state.topVesselList && state.topVesselList.length > 0 && state.topVesselList.map((item, index) => {
                                                if (index < 5)
                                                    return (
                                                        <tr key={index}>
                                                            <td>&nbsp;</td>
                                                            <td>{index + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.type}</td>
                                                            <td>{item.customerName}</td>
                                                            <td>{item.orderCount}</td>
                                                            <td>{item.totalVolume ? item.totalVolume.toFixed(2) : 0}</td>
                                                        </tr>
                                                    )
                                            })}
                                    {state.topVesselList && state.topVesselList.length > 0 ?
                                        <tr>
                                            <td colSpan="11">
                                                <div className="d-flex justify-content-end">
                                                    <Link to="/vessel-listing?topVessel=1" className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</Link>
                                                </div>
                                            </td>
                                        </tr>
                                        : ""}
                                </tbody>

                            </table>
                        </CollapsWrapper>
                        <CollapsWrapper>
                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                <thead>
                                    <tr>
                                        <th className="small-unit40" onClick={() => collapsHandle('isTopPort', !state.isTopPort)}>
                                            <span className="dropdown-icon d-inline-block">
                                                <i className={state.isTopPort ? "fa fa-angle-down" : "fa fa-angle-right"}></i>
                                            </span>
                                        </th>
                                        <th>Top Ports</th>
                                        <th>Port name</th>
                                        <th>Country</th>
                                        <th>Number of supplies</th>
                                        <th>Top Suppliers</th>
                                        <th>Volume</th>
                                    </tr>
                                </thead>
                                <tbody className={`custom-dropdown-menu ${state.isTopPort ? 'show' : ''}`}>
                                    {props.apiCallStatus.isStarted.indexOf("topSupplyHub") !== -1 ?
                                        <tr><td colSpan="20"><div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                        </div></td></tr> : state.portList && state.portList.length === 0 ?
                                            <tr><td colSpan="40" className="text-center"><div>No Data Found</div></td></tr>
                                            : state.portList && state.portList.length > 0 && state.portList.map((item, index) => {
                                                if (index < 5)
                                                    return (
                                                        <tr key={index}>
                                                            <td>&nbsp;</td>
                                                            <td>{index + 1}.</td>
                                                            <td>{item.port}</td>
                                                            <td>{item.countryName}</td>
                                                            <td>{item.orderCount}</td>
                                                            <td><Link to={`/supplier-listing?topSupplier=1&portId=${item._id}`} className="d-flex  p-0 fz-12 dark-pink-clr">View</Link></td>
                                                            <td>{item.totalVolume ? item.totalVolume.toFixed(2) : 0}</td>
                                                        </tr>
                                                    )
                                            })}
                                    {state.portList && state.portList.length > 0 ?
                                        <tr>
                                            <td colSpan="11">
                                                <div className="d-flex justify-content-end">
                                                    <Link to="/port-listing?topPort=1" className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</Link>
                                                </div>
                                            </td>
                                        </tr>
                                        : ""}
                                </tbody>

                            </table>
                        </CollapsWrapper>
                        <CollapsWrapper>
                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                <thead>
                                    <tr>
                                        <th className="small-unit40" onClick={() => collapsHandle('isTopItem', !state.isTopItem)}>
                                            <span className="dropdown-icon d-inline-block">
                                                <i className={state.isTopItem ? "fa fa-angle-down" : "fa fa-angle-right"}></i>
                                            </span>
                                        </th>
                                        <th>Top Items</th>
                                        <th>Item No.</th>
                                        <th>Item description</th>
                                        <th>Item group</th>
                                        <th>Price/unit</th>
                                        <th>Number of Supplies</th>
                                        <th>Top Supplier</th>
                                        <th>Top Ports</th>
                                        {/* <th>Volume</th> */}
                                    </tr>
                                </thead>
                                <tbody className={`custom-dropdown-menu ${state.isTopItem ? 'show' : ''}`}>
                                    {props.apiCallStatus.isStarted.indexOf("getTopItems") !== -1 ?
                                        <td colSpan="20"><div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                        </div></td> : state.topItemsList && state.topItemsList.length === 0 ?
                                            <td colSpan="40" className="text-center">No Data Found</td>
                                            :
                                            state.topItemsList.map((item, index) => {
                                                if (index < 5)
                                                    return <tr key={index}>
                                                        <td>&nbsp;</td>
                                                        <td>{index + 1}</td>
                                                        <td><span className="td-wrap-sty">{item.topItems.itemCode}</span></td>
                                                        <td><span className="td-wrap-sty">{item.topItems.description}</span></td>
                                                        <td><span className="td-wrap-sty">{item.topItems.category}</span></td>
                                                        <td><span className="td-wrap-sty">{item.topItems.pricePerBaseUnit.toFixed(2)}</span></td>
                                                        <td><span className="td-wrap-sty">{item.supplies}</span></td>
                                                        <td><Link to={`/supplier-listing?topSupplier=1`} className="d-flex  p-0 fz-12 dark-pink-clr">View</Link></td>
                                                        <td><Link to={`/port-listing?topPort=1`} className="d-flex  p-0 fz-12 dark-pink-clr">View</Link></td>
                                                        {/* <td><span className="td-wrap-sty">{item.volume}</span></td> */}
                                                    </tr>
                                            })

                                    }
                                    <tr>
                                        <td colSpan="11">
                                            <div className="d-flex justify-content-end">
                                                <Link to="/catalogue-overview" className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CollapsWrapper>

                    </div>

                    {/* Order Status Content */}
                </div>
            </div>

        </Wrapper>
    )
}
const CollapsWrapper = React.memo(props => {
    return <div className="row dash-content-bx-columns dashborddemo">
        <div className="col-md-12">
            <div className="table-responsive">
                <div className="dash-chartInfo-table contr-figure-info dropdown">
                    <div className="">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </div>
})
const OrderStatusWrapper = React.memo(props => {
    return <div className="d-flex vessel-order ">
        <div className="content-box-wrap dash-content-bx  mt-3 w-100">
            <div className="status-wrap-head content-box-head d-flex align-items-center flex-nowrap overflow-auto">
                <div className="flex-fill justify-content-start "><h2>{props.label}</h2></div>
                <div className="d-flex">
                    <h2><span className="sta-indicator pend"></span><span className="sta-text-wrap text-truncate">Pending</span></h2>
                    <h2><span className="sta-indicator appr"></span><span className="sta-text-wrap text-truncate">Confirmed</span></h2>
                    <h2><span className="sta-indicator past"></span><span className="sta-text-wrap text-truncate">Delivered</span></h2>
                    <h2><span className="sta-indicator declined"></span><span className="sta-text-wrap text-truncate">Declined / Cancelled</span></h2>
                </div>
                <div className="flex-fill justify-content-end d-flex">
                    {props.link === "#" ? '' : <Link to={props.link} className="btn btn-link d-flex align-items-center p-0 white-clr fz-14">View all</Link>}
                </div>
            </div>
            <div className="d-flex dash-ord-content ordContent-table cust-turnover text-left">
                <div className="table-responsive">
                    <table className="table mt-4 table-light1 theme-table-wrap2 short-table">
                        <tbody>
                            {props.children}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
})
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    dashboardReducer: state.dashboardReducer,
    orderReducer: state.orderReducer,
    commonReducer: state.commonReducer,
    userDetail: {
        userType: state.authReducer.userType,
        subType: state.authReducer.subType,
        userId: state.authReducer.userId,
    },
    quotationList: state.quotationReducer.quotationList,
    totalQuotation: state.quotationReducer.totalQuotation,
    supplierReducer: state.supplierReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    cartListing: (offset, filterQuery) => dispatch(cartListing(offset, filterQuery)),
    supplierListing: (offset, type, filterQuery) => dispatch(supplierListing(offset, type, filterQuery)),
    customerListing: (offset, type, filterQuery) => dispatch(customerListing(offset, type, filterQuery)),
    topItemsListing: (userId, userType, from, to) => dispatch(topItemsListing(userId, userType, from, to)),
    topVessel: (userId, userType, from, to) => dispatch(topVessel(userId, userType, from, to)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    getAllVessel: () => dispatch(getAllVessel()),
    getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
    topSupplyHub: (userId, userType, from, to) => dispatch(topSupplyHub(userId, userType, from, to)),
    topCustomer: (userId, userType, from, to) => dispatch(topCustomer(userId, userType, from, to)),
    quotationListing: (offset, limit, type, isDetail) => dispatch(quotationListing(offset, limit, type, isDetail)),
    getCurrentUserVessel: (userId, vesselId, dateFilter) => dispatch(getCurrentUserVessel(userId, vesselId, dateFilter)),

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplierDashboard);
