import styled from "styled-components";

export const Wrapper = styled.div`
.mesh-board-wrap .btn-sty-title{margin-right:20px}
.mesh-board-wrap .dash-page-content {width: 100%;padding: 0;background: none;min-height: 100%;}
.mesh-board-wrap .content-box-wrap {padding: 10px 20px 20px;}
@media only screen and (max-width:1600px){
    .btn-sty-title {font-size: 28px;}
}
@media only screen and (max-width:1200px){
    .btn-sty-title{font-size: 25px;}
}
@media only screen and (max-width:1025px){
	.btn-sty-title {font-size: 22px;}
}
@media only screen and (max-width: 576px){
    .mesh-board-wrap.d-flex.align-items-end {-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;}
    .dash-page-content .mesh-board-wrap .btn-sty-title,.dash-head-logo-wrap {margin-right: 0;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;width: 100%;margin-top: 10px;}
    .mesh-board-wrap .content-box-wrap {padding: 10px 10px 20px;}
    .mesh-board-wrap .btn-sty-title{margin-right:0}
    .btn-sty-title {width: 100%;}
}
.your-logo-wrap>input {display: none;}
`