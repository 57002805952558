import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "./../../../Constants/constant";
import {
  GetItemCodeAction,
  AppendItemCodeAction,
  GetDescriptionAction,
  AppendDescriptionAction,
  GetItemGroupAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";

export function getItemCode(offset, query) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendItemCode" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getItemCode" }));
      dispatch(GetItemCodeAction([], 0));
    }
    let url = Constant.apiURl + "/catalouge/filter/code/" + offset + "/20";
    if (query && query !== "") {
      url = url + query;
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendItemCodeAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendItemCode",
            message: "",
          })
        );
      } else {
        dispatch(GetItemCodeAction(myJson.data, myJson.catalougeCount));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getItemCode",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendItemCode",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getItemCode",
            message: myJson.message,
          })
        );
      }
    }
  };
}
export function getDescription(offset, query) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendDescription" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getDescription" }));
      dispatch(GetDescriptionAction([], 0));
    }
    let url = Constant.apiURl + "/catalouge/filter/description/" + offset + "/20";
    if (query && query !== "") {
      url = url + query;
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendDescriptionAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendDescription",
            message: "",
          })
        );
      } else {
        dispatch(GetDescriptionAction(myJson.data, myJson.catalougeCount));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getDescription",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendDescription",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getDescription",
            message: myJson.message,
          })
        );
      }
    }
  };
}
export function getItemGroup(query) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getItemGroup" }));
    let url = Constant.apiURl + "/catalouge/filter/category";
    if (query && query !== "") {
      url = url + query;
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      dispatch(GetItemGroupAction(myJson.data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getItemGroup",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getItemGroup",
          message: myJson.message,
        })
      );
    }
  };
}
