import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import { DashboardWrapper } from './dashboarWrapper';
import { connect } from 'react-redux';
import { Constant } from "../../../Constants/constant";
import DatePicker from "react-datepicker";
import { quotationListing } from "../../Quotations/Apicalls/quotations";
import { cartListing, orderListing } from '../ApiCalls/orderListing';
import { topItemsListing } from './../../Orders/ApiCalls/provisionOrder'
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { topSupplyHub, topSupplier, getVesselById, topVessel, topCustomer } from './../../Common/ApiCalls/index';
import { vesselListing } from './../../Vessel/ApiCalls/vessel';
import { capitalizeFirstLetter } from '../../../Services/common';
// import Map from "../../Common/Components/jvectorMap";
// import { io } from 'socket.io-client';
import "react-datepicker/dist/react-datepicker.css";
import './dashboard-style.css';

function ContractOffice(props) {
    const [state, setState] = useState({
        orderList: [],
        topItemsList: [],
        topSupplierList: [],
        topCustomerList: [],
        topSupplyHubList: [],
        topVesselList: [],
        qoutationList: [],
        vesselList: [],
        activePage: 0,
        totalOrderCount: 0,
        totalTopItems: 0,
        vesselName: '',
        isCartList: false,
        vesselPic: '',
        vesselId: '',
        customerVessels: [],
        vesselStatus: false,
        startDate: new Date(new Date().getTime() - (60 * 60 * 24 * 30 * 1000)),
        endDate: new Date(),
        filterQuery: '',
        isFilter: false,
        isTopPort: false,
        isFleetOverview: false,
        isTopItem: false,
        isTopVessel: false,
        isTopCustomer: false,
        isTopSupplier: false,
        isHitApi: false,
    });
    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        let currentVesselId = query.get('vesselId');
        if (props.userDetail.isSubAdmin && props.userDetail.userType === "user" && props.userDetail.subType !== "subAdmin" && props.userDetail.activeVesselId) {
            currentVesselId = props.userDetail.activeVesselId;
        }
        if (currentVesselId !== null && state.vesselId !== currentVesselId && props.apiCallStatus.isStarted.indexOf("getVesselById") === -1 && props.apiCallStatus.apiCallFor !== "getVesselById") {
            props.getVesselById(currentVesselId)
        }
        if (!state.isHitApi) {
            // const socket = io('http://localhost:5000?user=adeel', { transports: ['websocket'] });
            // socket.on("connect", () => {
            //     console.log(socket.id);
            // });
            // socket.emit("join", props.userDetail.userId);
            // socket.on("new message", (arg) => {
            //     console.log("new message", arg);
            // });
            let data = { ...state };
            if (currentVesselId === null && !data.isFilter) {
                props.vesselListing(0, 5);
            }
            data.isHitApi = true;
            let from = moment(data.startDate).format('YYYY-MM-DD');
            let to = moment(data.endDate).add(1, 'days').format('YYYY-MM-DD');
            let cartQuery = `?from=${from}&to=${to}&offset=${0}&limit=${5}`;
            let userType = props.userDetail.userType === "supplier" ? 'supplier' : props.userDetail.subType === "captain" ? 'captain' : props.userDetail.subType === "customer" ? 'customer' : '';
            if (userType === 'supplier') {
                cartQuery += `&supplierId=${props.userDetail.userId}`
                props.cartListing(0, cartQuery)
            } else if (userType === 'captain') {
                cartQuery += `&captainId=${props.userDetail.userId}`
                props.cartListing(0, cartQuery)
            } else if (props.userDetail.userType === 'user' && (props.userDetail.subType === 'user' || props.userDetail.subType === 'subAdmin')) {
                props.cartListing(0, cartQuery)
            }
            if (props.userDetail.subType !== 'customer') {
                props.topSupplyHub(props.userDetail.userId, userType, from, to)
                props.topItemsListing(props.userDetail.userId, userType, from, to)
                props.topSupplier(props.userDetail.userId, userType, from, to)
                props.topVessel(props.userDetail.userId, userType, from, to)
                props.topCustomer(props.userDetail.userId, userType, from, to)
                props.quotationListing(0, 4, 'rfq', true);
            }
            setState(data);
        }
        if ((props.apiCallStatus.apiCallFor === "getCart" || props.apiCallStatus.apiCallFor === "getOrder") &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                orderList: props.apiCallStatus.apiCallFor === "getCart" ? props.dashboardReducer.cartListing : props.dashboardReducer.orderListing,
                totalOrderCount: props.apiCallStatus.apiCallFor === "getCart" ? props.dashboardReducer.totalCart : props.dashboardReducer.totalOrder,
                isCartList: props.apiCallStatus.apiCallFor === "getCart" ? true : false,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "topCustomer" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                topCustomerList: props.commonReducer.topCustomerList,
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getTopItems") &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                topItemsList: props.orderReducer.topItemsList,
                totalTopItems: props.orderReducer.totalTopItems,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "topSupplyHub" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                topSupplyHubList: props.commonReducer.topSupplyHubList,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "topSupplier" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                topSupplierList: props.commonReducer.topSupplierList,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "topVessel" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                topVesselList: props.commonReducer.topVesselList,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (currentVesselId === null && (props.apiCallStatus.apiCallFor === "vesselListing" || props.apiCallStatus.apiCallFor === "appendVesselList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let customerVessels = [];
            if (props.userDetail.subType === 'customer' && props.vesselList.length > 0) {
                for (let index = 0; index < props.vesselList.length; index++) {
                    const id = props.vesselList[index]._id;
                    customerVessels.push(id)
                }
                let from = moment(state.startDate).format('YYYY-MM-DD');
                let to = moment(state.endDate).add(1, 'days').format('YYYY-MM-DD');
                let newArray = JSON.stringify(customerVessels)
                let cartQuery = `?from=${from}&to=${to}&offset=${0}&limit=${5}&vesselId=${newArray}`;
                props.cartListing(0, cartQuery)
                props.topSupplyHub(customerVessels, 'customer', from, to)
                props.topSupplier(customerVessels, 'customer', from, to)
                props.topItemsListing(customerVessels, 'customer', from, to)
                props.topVessel(props.userDetail.userId, 'customer', from, to)
                props.topCustomer(props.userDetail.userId, 'customer', from, to)
                props.quotationListing(0, 4, 'rfq', customerVessels);
            }
            let index = props.vesselList.findIndex(x => x.active === true);
            if (index === -1 && props.vesselList.length > 0) {
                index = 0;
            }
            setState({
                ...state,
                vesselName: props.vesselList.length > 0 ? props.vesselList[index].name : '',
                vesselPic: props.vesselList.length > 0 ? props.vesselList[index].pic : '',
                vesselId: props.vesselList.length > 0 ? props.vesselList[index]._id : '',
                vesselStatus: props.vesselList.length > 0 ? props.vesselList[index].active : false,
                customerVessels,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if ((props.apiCallStatus.apiCallFor === "getQuotation") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                qoutationList: props.quotationList.slice(0, 5)
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "getVesselById" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            if (state.vesselId !== props.commonReducer.vesselById._id && Object.keys(props.commonReducer.vesselById).length > 0) {
                setState({
                    ...state,
                    vesselName: props.commonReducer.vesselById.name,
                    vesselPic: props.commonReducer.vesselById.pic,
                    vesselId: props.commonReducer.vesselById._id,
                    vesselStatus: props.commonReducer.vesselById.active,
                    isHitApi: true
                })
                props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
            }
        }
    }, [props, state.isHitApi])
    const handleChange = (id, val) => {
        setState({
            ...state,
            [id]: val,
            isHitApi: false,
            isFilter: true
        })
    }
    const navbarOptions = [
        {
            img: 'Cancel.svg',
            className: "red",
            name: "Logout",
            path: "/",
            isLogout: true
        },
    ];
    const collapsHandle = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }
    return (
        <DashboardWrapper title={(props.userDetail.isSubAdmin && props.userDetail.userType === 'user' && props.userDetail.subType !== 'sub-admin') ? `${props.userDetail.roleName} Dashboard` : props.userDetail.userType === 'supplier' ? 'Supplier Dashboard' : props.userDetail.userType === 'customer' ? 'Customer Dashboard' : 'Admin Panel'} navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end">
            {/* Body Content */}
            <div className="mt-lg-5 d-flex contract-dash-wrap cont-ves-wrap">
                <div className="dashVesl-overview-wrap">
                    <div className="content-box-wrap dash-content-bx">
                        <div className="vessel-overview-wrap d-flex align-items-start">
                            <div className="content-box-head d-flex align-items-center justify-content-end head-transparent w-100 mb-3 flex-wrap">
                                {/* <h2 className="font-weight-bold dark-clr mr-3">OPERATING FIGURES</h2> */}
                                {/* Date Range Picker */}
                                <div className="date-range-picker d-flex align-items-center">
                                    <span className="d-inline-block mr-1">
                                        <DatePicker
                                            selected={state.startDate}
                                            onChange={(val) => handleChange('startDate', val)} className="link-style"
                                        />
                                    </span>
                                    to
                                    <span className="d-inline-block ml-1">
                                        <DatePicker
                                            selected={state.endDate}
                                            onChange={(val) => handleChange('endDate', val)} className="link-style"
                                        />
                                    </span>
                                </div>
                                {/* <button type="button" className="btn btn-link d-flex align-items-center p-0 dark-clr">View complete list</button> */}
                            </div>
                        </div>
                        <CollapsWrapper>
                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                <thead>
                                    <tr>
                                        <th className="small-unit40" onClick={() => collapsHandle('isTopCustomer', !state.isTopCustomer)}>
                                            <span className="dropdown-icon d-inline-block">
                                                <i className={state.isTopCustomer ? "fa fa-angle-down" : "fa fa-angle-right"}></i>
                                            </span>
                                        </th>
                                        <th>Top Customer</th>
                                        <th>Customer Email</th>
                                        <th>Fleet Size</th>
                                        <th>Client Type</th>
                                        <th>Number of Supplies.</th>
                                        <th>Volume</th>
                                    </tr>
                                </thead>
                                <tbody className={`custom-dropdown-menu ${state.isTopCustomer ? 'show' : ''}`}>
                                    {props.apiCallStatus.isStarted.indexOf("topCustomer") !== -1 ?
                                        <td colSpan="20"><div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                        </div></td> : state.topCustomerList && state.topCustomerList.length === 0 ?
                                            <td colSpan="40" className="text-center"><div>No Data Found</div></td>
                                            : state.topCustomerList && state.topCustomerList.length > 0 && state.topCustomerList.map((item, index) => {
                                                if (index < 5)
                                                    return (
                                                        <tr key={index}>
                                                            <td>&nbsp;</td>
                                                            <td>{index + 1}</td>
                                                            <td>{item.email}</td>
                                                            <td>{item.fleetSize}</td>
                                                            <td>{capitalizeFirstLetter(item.type)}</td>
                                                            <td>{item.count}</td>
                                                            <td>{item.totalVolume ? item.totalVolume.toFixed(2) : 0}</td>
                                                        </tr>
                                                    )
                                            })}
                                    {state.topCustomerList && state.topCustomerList.length > 0 ?
                                        <tr>
                                            <td colSpan="11">
                                                <div className="d-flex justify-content-end">
                                                    <Link to="/customer-listing?topCustomer=1" className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</Link>
                                                </div>
                                            </td>
                                        </tr>
                                        : ""}
                                </tbody>

                            </table>
                        </CollapsWrapper>
                        <CollapsWrapper>
                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                <thead>
                                    <tr>
                                        <th className="small-unit40" onClick={() => collapsHandle('isTopVessel', !state.isTopVessel)}>
                                            <span className="dropdown-icon d-inline-block">
                                                <i className={state.isTopVessel ? "fa fa-angle-down" : "fa fa-angle-right"}></i>
                                            </span>
                                        </th>
                                        <th>Top Vessels</th>
                                        <th>Vessel Name</th>
                                        <th>Vessel Type</th>
                                        <th>Vessel Manager</th>
                                        <th>Number of Supplies</th>
                                        <th>Volume</th>
                                    </tr>
                                </thead>
                                <tbody className={`custom-dropdown-menu ${state.isTopVessel ? 'show' : ''}`}>
                                    {props.apiCallStatus.isStarted.indexOf("topVessel") !== -1 ?
                                        <td colSpan="20"><div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                        </div></td> : state.topVesselList && state.topVesselList.length === 0 ?
                                            <td colSpan="40" className="text-center"><div>No Data Found</div></td>
                                            : state.topVesselList && state.topVesselList.length > 0 && state.topVesselList.map((item, index) => {
                                                if (index < 5)
                                                    return (
                                                        <tr key={index}>
                                                            <td>&nbsp;</td>
                                                            <td>{index + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.type}</td>
                                                            <td>{item.customerName}</td>
                                                            <td>{item.orderCount}</td>
                                                            <td>{item.totalVolume ? item.totalVolume.toFixed(2) : 0}</td>
                                                        </tr>
                                                    )
                                            })}
                                    {state.topVesselList && state.topVesselList.length > 0 ?
                                        <tr>
                                            <td colSpan="11">
                                                <div className="d-flex justify-content-end">
                                                    <Link to="/vessel-listing?fleetOverview=1" className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</Link>
                                                </div>
                                            </td>
                                        </tr>
                                        : ""}
                                </tbody>

                            </table>
                        </CollapsWrapper>
                        <CollapsWrapper>
                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                <thead>
                                    <tr>
                                        <th className="small-unit40" onClick={() => collapsHandle('isTopPort', !state.isTopPort)}>
                                            <span className="dropdown-icon d-inline-block">
                                                <i className={state.isTopPort ? "fa fa-angle-down" : "fa fa-angle-right"}></i>
                                            </span>
                                        </th>
                                        <th>Top Ports</th>
                                        <th>Port name</th>
                                        <th>Country</th>
                                        <th>Number of supplies</th>
                                        <th>Top Suppliers</th>
                                        <th>Volume</th>
                                    </tr>
                                </thead>
                                <tbody className={`custom-dropdown-menu ${state.isTopPort ? 'show' : ''}`}>
                                    {props.apiCallStatus.isStarted.indexOf("topSupplyHub") !== -1 ?
                                        <tr><td colSpan="20"><div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                        </div></td></tr> : state.topSupplyHubList && state.topSupplyHubList.length === 0 ?
                                            <tr><td colSpan="40" className="text-center"><div>No Data Found</div></td></tr>
                                            : state.topSupplyHubList && state.topSupplyHubList.length > 0 && state.topSupplyHubList.map((item, index) => {
                                                if (index < 5)
                                                    return (
                                                        <tr key={index}>
                                                            <td>&nbsp;</td>
                                                            <td>{index + 1}.</td>
                                                            <td>{item.port}</td>
                                                            <td>{item.countryName}</td>
                                                            <td>{item.orderCount}</td>
                                                            <td><Link to={`/supplier-listing?topSupplier=1&portId=${item._id}`} className="d-flex  p-0 fz-12 dark-pink-clr">View</Link></td>
                                                            <td>{item.totalVolume ? item.totalVolume.toFixed(2) : 0}</td>
                                                        </tr>
                                                    )
                                            })}
                                    {state.topSupplyHubList && state.topSupplyHubList.length > 0 ?
                                        <tr>
                                            <td colSpan="11">
                                                <div className="d-flex justify-content-end">
                                                    <Link to="/port-listing?topPort=1" className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</Link>
                                                </div>
                                            </td>
                                        </tr>
                                        : ""}
                                </tbody>

                            </table>
                        </CollapsWrapper>
                        <CollapsWrapper>
                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                <thead>
                                    <tr>
                                        <th className="small-unit40" onClick={() => collapsHandle('isTopSupplier', !state.isTopSupplier)}>
                                            <span className="dropdown-icon d-inline-block">
                                                <i className={state.isTopSupplier ? "fa fa-angle-down" : "fa fa-angle-right"}></i>
                                            </span>
                                        </th>
                                        <th>Top Suppliers</th>
                                        <th>Supplier name</th>
                                        <th>Country</th>
                                        <th>Number of Supplies</th>
                                        <th>Top Vessels</th>
                                        <th>Top Clients</th>
                                        <th>Top Ports</th>
                                        <th>Volume</th>

                                    </tr>
                                </thead>
                                <tbody className={`custom-dropdown-menu ${state.isTopSupplier ? 'show' : ''}`}>
                                    {props.apiCallStatus.isStarted.indexOf("topSupplier") !== -1 ?
                                        <tr><td colSpan="20"><div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                        </div></td></tr> : state.topSupplierList && state.topSupplierList.length === 0 ?
                                            <tr><td colSpan="40" className="text-center"><div>No Data Found</div></td></tr>
                                            : state.topSupplierList && state.topSupplierList.length > 0 && state.topSupplierList.map((item, index) => {
                                                if (index < 5)
                                                    return (
                                                        <tr key={index}>
                                                            <td>&nbsp;</td>
                                                            <td>{index + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.countryName}</td>
                                                            <td>{item.totalOrder}</td>
                                                            <td><Link to={`/vessel-listing?topVessel=1&supplierId=${item._id}`} className="d-flex  p-0 fz-12 dark-pink-clr">View</Link></td>
                                                            <td><Link to={`/customer-listing?topCustomer=1&supplierId=${item._id}`} className="d-flex  p-0 fz-12 dark-pink-clr">View</Link></td>
                                                            <td><Link to={`/port-listing?topPort=1&supplierId=${item._id}`} className="d-flex  p-0 fz-12 dark-pink-clr">View </Link></td>
                                                            <td>{item.totalVolume ? item.totalVolume.toFixed(2) : 0}</td>
                                                        </tr>
                                                    )
                                            })}
                                    {state.topSupplierList && state.topSupplierList.length > 0 ?
                                        <tr>
                                            <td colSpan="11">
                                                <div className="d-flex justify-content-end">
                                                    <Link to="/supplier-listing?topSupplier=1" className="btn btn-link d-flex align-items-center p-0 dark-pink-clr fz-14">View all</Link>
                                                </div>
                                            </td>
                                        </tr>
                                        : ""}

                                </tbody>
                            </table>
                        </CollapsWrapper>

                        {/* <CollapsWrapper>
                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                <thead>
                                    <tr>
                                        <th className="small-unit40" onClick={() => collapsHandle('isFleetOverview', !state.isFleetOverview)}>
                                            <span className="dropdown-icon d-inline-block">
                                                <i className={state.isFleetOverview ? "fa fa-angle-down" : "fa fa-angle-right"}></i>
                                            </span>
                                        </th>
                                        <th>Fleet Overview</th>
                                        <th>Vessel Name</th>
                                        <th>Type</th>
                                        <th>Provision rate</th>
                                        <th>Store rate</th>
                                        <th>Next Delivery Date</th>
                                    </tr>
                                </thead>
                                <tbody className={`custom-dropdown-menu ${state.isFleetOverview ? 'show' : ''}`}>
                                    {props.apiCallStatus.isStarted.indexOf("vesselListing") !== -1 ?
                                        <td colSpan="20"><div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                        </div></td> : props.vesselList && props.vesselList.length === 0 ?
                                            <td colSpan="40" className="text-center"><div>No Data Found</div></td>
                                            : props.vesselList && props.vesselList.length > 0 && props.vesselList.map((item, index) => {
                                                let storeBudget = '';
                                                if (item.departments && item.departments.length > 0) {
                                                    let departments = item.departments
                                                    storeBudget = departments.map(s => parseInt(s.budget)).reduce((prev, next) => parseInt(prev) + parseInt(next));
                                                }
                                                if (index < 5)
                                                    return (
                                                        <tr key={index}>
                                                            <td>&nbsp;</td>
                                                            <td>{index + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.kindOfVessel && item.kindOfVessel.label ? item.kindOfVessel.label : ''}</td>
                                                            <td>{item.rate}</td>
                                                            <td>{storeBudget}</td>
                                                            <td>{item.arrivalTime ? moment(item.arrivalTime).format("DD-MM-YYYY") : ''}</td>
                                                        </tr>
                                                    )
                                            })}
                                    {props.vesselList && props.vesselList.length > 0 ?
                                        <tr>
                                            <td colSpan="11">
                                                <div className="d-flex justify-content-end">
                                                    <Link to="/vessel-listing?fleetOverview=1" className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</Link>
                                                </div>
                                            </td>
                                        </tr>
                                        : ""}
                                </tbody>

                            </table>
                        </CollapsWrapper> */}

                        <CollapsWrapper>
                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                <thead>
                                    <tr>
                                        <th className="small-unit40" onClick={() => collapsHandle('isTopItem', !state.isTopItem)}>
                                            <span className="dropdown-icon d-inline-block">
                                                <i className={state.isTopItem ? "fa fa-angle-down" : "fa fa-angle-right"}></i>
                                            </span>
                                        </th>
                                        <th>Top Items</th>
                                        <th>Item No.</th>
                                        <th>Item description</th>
                                        <th>Item group</th>
                                        <th>Price/unit</th>
                                        <th>Number of Supplies</th>
                                        <th>Top Supplier</th>
                                        <th>Top Ports</th>
                                        {/* <th>Volume</th> */}
                                    </tr>
                                </thead>
                                <tbody className={`custom-dropdown-menu ${state.isTopItem ? 'show' : ''}`}>
                                    {props.apiCallStatus.isStarted.indexOf("getTopItems") !== -1 ?
                                        <td colSpan="20"><div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                        </div></td> : state.topItemsList && state.topItemsList.length === 0 ?
                                            <td colSpan="40" className="text-center">No Data Found</td>
                                            :
                                            state.topItemsList.map((item, index) => {
                                                if (index < 5)
                                                    return <tr key={index}>
                                                        <td>&nbsp;</td>
                                                        <td>{index + 1}</td>
                                                        <td><span className="td-wrap-sty">{item.topItems.itemCode}</span></td>
                                                        <td><span className="td-wrap-sty">{item.topItems.description}</span></td>
                                                        <td><span className="td-wrap-sty">{item.topItems.category}</span></td>
                                                        <td><span className="td-wrap-sty">{item.topItems.pricePerBaseUnit.toFixed(2)}</span></td>
                                                        <td><span className="td-wrap-sty">{item.supplies}</span></td>
                                                        <td><Link to={`/supplier-listing?topSupplier=1`} className="d-flex  p-0 fz-12 dark-pink-clr">View</Link></td>
                                                        <td><Link to={`/port-listing?topPort=1`} className="d-flex  p-0 fz-12 dark-pink-clr">View</Link></td>
                                                        {/* <td><span className="td-wrap-sty">{item.volume}</span></td> */}
                                                    </tr>
                                            })

                                    }
                                    <tr>
                                        <td colSpan="11">
                                            <div className="d-flex justify-content-end">
                                                <Link to="/catalogue-overview" className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</Link>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CollapsWrapper>

                    </div>

                    {/* Order Status Content */}
                </div>
            </div>
            <div className="flex-fill dash-ord-content ordContent-table cust-turnover text-left">
                <OrderStatusWrapper apiCallStatus={props.apiCallStatus} label="Current Orders" link={state.orderList.length === 0 ? "#" : "/orderOverview"}>
                    {(props.apiCallStatus.isStarted.indexOf("getCart") !== -1 || props.apiCallStatus.isStarted.indexOf("getOrder") !== -1) ?
                        <tr><td colSpan="20"><div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div></td></tr> : state.orderList && state.orderList.length === 0 ?
                            <tr><td colSpan="40" className="text-center"><div>No Data Found</div></td></tr>
                            : state.orderList.map((item, index) => {
                                return (
                                    state.isCartList ? <tr key={index}>
                                        <td>{item.vesselName}</td>
                                        <td>{item.customerName}</td>
                                        <td>{capitalizeFirstLetter(item.type)}</td>
                                        <td>{moment(item.arrivalTime).format("DD.MM.YYYY")}</td>
                                        <td>{item.placeOfDelivery.label}</td>
                                        <td>{item.supplierName}</td>
                                        <td>{item.totalPrice ? parseFloat(item.totalPrice.toFixed(2)) : '0'}</td>
                                        <td>{item.orderStatus === 'pending' ?
                                            <><span className="sta-indicator pend"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : item.orderStatus === 'confirmed' && !item.isSupplied ? <><span className="sta-indicator appr"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : item.orderStatus === 'confirmed' && item.isSupplied ? <><span className="sta-indicator past"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : item.orderStatus}</td>

                                    </tr> :
                                        <tr key={index}>
                                            <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>

                                            <td>{moment(item.createdAt).format("Do MMM YYYY")}</td>
                                            <td>{item.status === 'inprogress' ?
                                                <><span className="sta-indicator pend"></span> <span className="sta-text-wrap text-truncate"></span>
                                                </> : item.status === 'confirmed' ? <><span className="sta-indicator appr"></span> <span className="sta-text-wrap text-truncate"></span>
                                                </> : item.status === 'pastorder' ? <><span className="sta-indicator past"></span> <span className="sta-text-wrap text-truncate"></span>
                                                </> : item.status}</td>
                                            <td>{item.totalPrice ? parseFloat(item.totalPrice.toFixed(2)) : '0'}</td>
                                        </tr>
                                )
                            })}
                </OrderStatusWrapper>
                <OrderStatusWrapper apiCallStatus={props.apiCallStatus} label="Current RFQ's" link={state.qoutationList.length === 0 ? "#" : "/inprogress-quotations"}>
                    {props.apiCallStatus.isStarted.indexOf("getQuotation") !== -1 ?
                        <tr><td colSpan="20"><div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div></td></tr> : state.qoutationList && state.qoutationList.length === 0 ?
                            <tr><td colSpan="40" className="text-center"><div>No Data Found</div></td></tr>
                            : state.qoutationList.map((item, index) => {
                                let status = item.status
                                return (
                                    <tr key={index}>
                                        <td>{item.vesselId && item.vesselId.name ? item.vesselId.name : ''}</td>
                                        <td>{item.customerName}</td>
                                        <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>
                                        <td>{moment(item.nextDeliveryDate).format("DD.MM.YYYY")}</td>
                                        <td>{item.nextDeliveryPlace}</td>
                                        <td>{item.supplierId && item.supplierId.name ? item.supplierId.name : ''}</td>
                                        <td>{item.rfqValue}</td>
                                        <td>{(status === 'inprogress' || status === 'pending') ?
                                            <><span className="sta-indicator pend"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : status === 'confirmed' ? <><span className="sta-indicator appr"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : status === 'pastorder' ? <><span className="sta-indicator past"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : status}</td>

                                    </tr>
                                )
                            })}
                </OrderStatusWrapper>
            </div>
        </DashboardWrapper >
    )
}
const CollapsWrapper = React.memo(props => {
    return <div className="row dash-content-bx-columns dashborddemo">
        <div className="col-md-12">
            <div className="table-responsive">
                <div className="dash-chartInfo-table contr-figure-info dropdown">
                    <div className="">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </div>
})

const OrderStatusWrapper = React.memo(props => {
    return <div className="d-flex vessel-order ">
        <div className="content-box-wrap dash-content-bx  mt-3 w-100">
            <div className="status-wrap-head content-box-head d-flex align-items-center flex-nowrap overflow-auto">
                <div className="flex-fill justify-content-start "><h2>{props.label}</h2></div>
                <div className="d-flex">
                    <h2><span className="sta-indicator pend"></span><span className="sta-text-wrap text-truncate">Pending</span></h2>
                    <h2><span className="sta-indicator appr"></span><span className="sta-text-wrap text-truncate">Confirmed</span></h2>
                    <h2><span className="sta-indicator past"></span><span className="sta-text-wrap text-truncate">Delivered</span></h2>
                    <h2><span className="sta-indicator declined"></span><span className="sta-text-wrap text-truncate">Declined / Cancelled</span></h2>
                </div>
                <div className="flex-fill justify-content-end d-flex">
                    {props.link === "#" ? '' : <Link to={props.link} className="btn btn-link d-flex align-items-center p-0 white-clr fz-14">View all</Link>}
                </div>
            </div>
            <div className="d-flex dash-ord-content ordContent-table cust-turnover text-left">
                <div className="table-responsive">
                    <table className="table mt-4 table-light1 theme-table-wrap2 short-table">
                        <tbody>
                            {props.children}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
})
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    dashboardReducer: state.dashboardReducer,
    orderReducer: state.orderReducer,
    commonReducer: state.commonReducer,
    supplierReducer: state.supplierReducer,
    quotationList: state.quotationReducer.quotationList,
    vesselList: state.vesselReducer.list,
    userDetail: {
        userType: state.authReducer.userType,
        permission: state.authReducer.permission,
        isSubAdmin: state.authReducer.isSubAdmin,
        roleName: state.authReducer.roleName,
        userId: state.authReducer.userId,
        activeVesselId: state.authReducer.activeVesselId,
        subType: state.authReducer.subType
    }

})

const mapDispatchToProps = (dispatch, ownProps) => ({
    cartListing: (offset, filterQuery) => dispatch(cartListing(offset, filterQuery)),
    orderListing: (offset, filterQuery) => dispatch(orderListing(offset, filterQuery)),
    topSupplier: (userId, userType, from, to) => dispatch(topSupplier(userId, userType, from, to)),
    topVessel: (userId, userType, from, to) => dispatch(topVessel(userId, userType, from, to)),
    topCustomer: (userId, userType, from, to) => dispatch(topCustomer(userId, userType, from, to)),
    topItemsListing: (userId, userType, from, to) => dispatch(topItemsListing(userId, userType, from, to)),
    topSupplyHub: (userId, userType, from, to) => dispatch(topSupplyHub(userId, userType, from, to)),
    vesselListing: (offset, limit) => dispatch(vesselListing(offset, limit)),
    getVesselById: (vesselId) => dispatch(getVesselById(vesselId)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    quotationListing: (offset, limit, type, isDetail, captainId) => dispatch(quotationListing(offset, limit, type, isDetail, captainId))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContractOffice);