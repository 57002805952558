import React, { useEffect, useState } from 'react';
import { Wrapper } from '../../Common/Components/CommonWrapper';
import { getUserDetail, userListing } from "../ApiCalls/user";
import { getAllCountry } from '../../Common/ApiCalls/countryList';
import { ApiClearAction, ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { updateCustomer } from '../../Common/ApiCalls/customerList';
import { getAllVessel } from '../../Common/ApiCalls/vesselList';
import { getAllRoles } from '../../Common/ApiCalls/getAllRoles';
import { connect } from 'react-redux';
import { getAllPort } from '../../Common/ApiCalls/portList';
import { isAllowPermission } from '../../../Services/common';
import  DashboardNavbar  from '../../Common/Components/dashboardNavbar';
import { UserForm } from './userForm';
import Swal from 'sweetalert2';
const userInitialState = {
    name: '',
    type: '',
    typeOption: [
        { label: 'Captain', value: 'captain' },
        { label: 'Chief Mate(1st Officer)', value: 'chiefMate' },
        { label: 'Chief Engineer', value: 'chiefEngineer' },
        { label: 'Electrician', value: 'electrician' },
        { label: 'Cook', value: 'cook' },
        { label: 'Controller', value: 'controller' },
        { label: 'Super intendent', value: 'superIntendent' },
        { label: 'Purchase manager', value: 'purchaseManager' },
        { label: 'Purchaser', value: 'purchaser' },
        { label: 'Management customer', value: 'managementCustomer' },
    ],
    selectedTypeOption: null,
    port: '',
    portOption: [],
    selectedPortOption: null,
    role: '',
    roleOption: [],
    selectedRoleOption: null,
    country: '',
    countryOption: [],
    selectedCountryOption: null,
    vessel: '',
    vesselOption: [],
    selectedVesselOption: null,
    speciality: '',
    specialityList: [],
    departmentOption: [],
    selectedDepOption: null,
    email: '',
    websiteUrl: '',
    phone: '',
    password: '',
    confirmPassword: '',
    isError: false,
    errorMessage: '',
    isHitApi: false
}
function EditUser(props) {
    const [state, setState] = useState(userInitialState);
    const [isHitOnLoadApi, setIsHitOnLoadApi] = useState(false);
    useEffect(() => {
        if (!isHitOnLoadApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.getUserDetail(0, 'all', `&userId=${props.match.params.id}`)
                props.getAllCountry();
                props.getAllRoles()
                props.getAllVessel()
                setIsHitOnLoadApi(true)
            }
        }
        if (props.apiCallStatus.apiCallFor === "getAllCountry" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.countryList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].name, countryCode: arr[i].countryCode });
            }
            setState({
                ...state,
                countryOption: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "getAllRoles" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            let newArr = [];
            for (let index = 0; index < props.roleList.length; index++) {
                let val = props.roleList[index];
                newArr.push({ label: val.roleName, value: val._id, roles: val.roles, permission: val.permission })
            }
            setState({
                ...state,
                roleOption: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "getAllPort" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.portList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].port });
            }
            setState({
                ...state,
                portOption: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "getAllVessel" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            let newArr = [];
            for (let index = 0; index < props.vesselList.length; index++) {
                let vesselElem = props.vesselList[index];
                let departments = [];
                if (vesselElem["departments"]) {
                    vesselElem["departments"].forEach(element => {
                        departments.push({ ...element, label: element.department, value: element.id })
                    });
                }
                newArr.push({ label: vesselElem.name, value: vesselElem._id, departments })
            }
            setState({
                ...state,
                vesselOption: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getUserDetail") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let data = props.userReducer.detail;
            let typeIndex = state.typeOption.findIndex(x => x.value === data.subType);
            let type = "";
            let selectedTypeOption = null;
            if (typeIndex !== -1) {
                type = state.typeOption[typeIndex].value;
                selectedTypeOption = state.typeOption[typeIndex]
            }
            setState({
                ...state,
                country: data.country,
                name: data.name,
                email: data.email,
                phone: data.phone,
                role: data.role,
                websiteUrl: data.websiteUrl,
                port: data.port,
                type: type,
                selectedDepOption: data.selectedDepOption,
                selectedCountryOption: data.selectedCountryOption,
                selectedPortOption: data.selectedPortOption,
                selectedVesselOption: data.selectedVesselOption,
                selectedRoleOption: data.selectedRoleOption,
                selectedTypeOption: selectedTypeOption,
                specialityList: data.specialityList,
                vessel: data.vessel,
            })
            let query = "countryCode=" + data.selectedCountryOption.countryCode;
            props.getAllPort(query);
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getUser") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            if (props.userReducer.list.length > 0) {
                Swal.fire("Error", "This Vessel is already assign", "error");
            } else {
                apiCallHandler()
            }
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "updateCustomer") &&
            props.apiCallStatus.isCompleted &&
            !props.apiCallStatus.isFailed) {
            let msg = `The ${state.name} has been updated successfully.`;
            Swal.fire("Success", msg, "success");
            setState(userInitialState)
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
            props.history.push('/user-listing')
        }
        props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    });
    const handleFormChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = { ...state }
        data[name] = value;
        data['errorMessage'] = ''
        data['isError'] = false
        setState(data)

    }
    const addItem = () => {
        const specialityList = [...state.specialityList]
        if (state.speciality !== '') {
            specialityList.push(state.speciality);
            setState({
                ...state,
                specialityList,
                speciality: ''
            })
        } else {

            setState({
                ...state,
                isError: true,
                errorMessage: 'Please Add Speciality',
            })
        }
    }
    const removeItem = (val) => {
        let specialityList = [...state.specialityList];
        let newArray = specialityList.filter(x => x !== val)
        setState({
            ...state,
            specialityList: newArray
        })
    }
    const apiCallHandler = () => {
        const { name, country, port, selectedTypeOption, selectedRoleOption, vessel, phone, websiteUrl, specialityList, selectedDepOption } = state;
        let obj = {
            subType: selectedTypeOption.value,
            type: "user",
            name,
            countryId: country,
            portId: port,
            vesselId: vessel,
            phone,
            departments: selectedDepOption,
            roleId: selectedRoleOption.value,
            roleName: selectedRoleOption.label,
            roles: selectedRoleOption.roles,
            permission: selectedRoleOption.permission,
            websiteUrl,
            specialityList
        }
        props.updateCustomer(props.match.params.id, obj)
    }
    const handleSelectChange = (e, name) => {
        let data = { ...state }
        if (name === 'port') {
            data['selectedPortOption'] = e
        } else if (name === 'type') {
            data['selectedTypeOption'] = e
            data.specialityList = []
        } else if (name === 'role') {
            data['selectedRoleOption'] = e
        }
        else if (name === 'country') {
            data['selectedCountryOption'] = e;
            data['port'] = '';
            data['portOption'] = [];
            data['selectedPortOption'] = null;
            let query = "countryCode=" + e.countryCode;
            props.getAllPort(query);
        } else if (name === 'department') {
            data['selectedDepOption'] = e
        } else {
            let departments = [];
            e.departments && e.departments.forEach(element => {
                if (element.userId === undefined || element.userId === props.match.params.id) {
                    departments.push(element);
                }
            });
            data["selectedDepOption"] = null;
            data["departmentOption"] = departments;
            data['selectedVesselOption'] = e
        }
        if (name !== 'department') {
            data[name] = e.value;
        }
        data['errorMessage'] = ''
        data['isError'] = false
        setState(data);
    }
    const updateUser = () => {
        if (validateForm()) {
            apiCallHandler()
        }
    }
    const validateForm = () => {
        let isValid = true;
        let message = "";
        if (state.type === '') {
            message = "Please select user type";
            isValid = false
        } else if (state.country === '') {
            message = "Please select country";
            isValid = false
        } else if (state.port === '') {
            message = "Please select port";
            isValid = false
        }
        else if (state.vessel === '') {
            message = "Please select vessel";
            isValid = false
        } else if (state.role === '') {
            message = "Please select role";
            isValid = false
        }


        if (!isValid) {
            Swal.fire("Error", message, "error");
        }
        return isValid;
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Edit User" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="formBox">
                <UserForm {...state} history={props.history} isEdit={true} removeItem={removeItem} addItem={addItem} handleFormChange={handleFormChange} handleSelectChange={handleSelectChange} updateUser={updateUser} apiCallStatus={props.apiCallStatus} apiCallName="updateCustomer" />
            </div>
        </Wrapper>
    )
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    countryReducer: state.countryReducer,
    portReducer: state.portReducer,
    roles: state.authReducer.roles,
    userReducer: state.userReducer,
    userReducer: state.userReducer,
    roleList: state.commonReducer.roleList,
    vesselList: state.commonReducer.vesselList,
    portList: state.commonReducer.portList,
    countryList: state.commonReducer.countryList,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
    getAllCountry: () => dispatch(getAllCountry()),
    getAllVessel: () => dispatch(getAllVessel()),
    getUserDetail: (offset, type, filterQuery) => dispatch(getUserDetail(offset, type, filterQuery)),
    userListing: (offset, type, filterQuery) => dispatch(userListing(offset, type, filterQuery)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    updateCustomer: (id, data) => dispatch(updateCustomer(id, data)),
    getAllRoles: () => dispatch(getAllRoles()),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUser);
