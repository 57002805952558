export const getPackage = "GET_PACKAGE";
export const AppendPackage = "APPEND_PACKAGE_LIST";
export const getPackageDetail = "GET_PACKAGE_DETAI";
export const getPackageCategory="GET_PACKAGE_CATEGORY";
export function GetPackageAction(list, count) {
    return {
        type: getPackage,
        payload: { list, count },
    };
}
export function AppendPackageAction(list) {
    return {
        type: AppendPackage,
        payload: { list },
    };
}
export function GetPackageDetailAction(detail) {
    return {
        type: getPackageDetail,
        payload: { detail },
      };
}
export function getPackageCategoryDetailAction(list) {
    return {
        type: getPackageCategory,
        payload: { list },
    };
}