import React from 'react';

export default function DemoHeader() {
    return (
        <div className="dash-pg-inn-wrap demo-header-wrap">
            <h2 className="demo-headTitle">DEMO ONLY!</h2>
            <p className="demo-headDes mb-0">Some buttons are out of function. Curious for more? Contact us!</p>
        </div>
    )
}
