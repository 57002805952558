import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
// import { Wrapper } from '../../../Orders/Css/previsionCatalouge';
import { Wrapper } from '../../../Common/Components/CommonWrapper';
import DashboardNavbar from '../../../Common/Components/dashboardNavbar';
import { getQuotationDetail, sendQuotation } from '../../Apicalls/quotations';
import loader from '../../../../assets/images/Spinner-1s-200px.gif';
import cloneDeep from 'lodash.clonedeep';
import Swal from 'sweetalert2';
import $ from 'jquery';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

function ConfirmOrder(props) {
    const [state, setState] = useState({
        isHitApi: false,
        price: 0,
        basketId: '',
        deliveryDays: '',
        orderDetail: {},
        quotationDetailList: [],
    });
    useEffect(() => {
        if (!state.isHitApi) {
            props.getQuotationDetail(props.match.params.quotationId, props.userId);
            let data = { ...state };
            data.isHitApi = true;
            setState(data);
        }
        if (props.apiCallStatus.apiCallFor === "getQuotationDetail" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                quotationDetailList: props.quotationReducer.quotationDetailList,
                basketId: props.quotationReducer.basketId,
                isHitApi: true
            })
            setTimeout(() => {
                $("input[type=number]").on("focus", function () {
                    $(this).on("keydown", function (event) {
                        if (event.keyCode === 38 || event.keyCode === 40 || event.key === "-") {
                            event.preventDefault();
                        }
                    });
                });
            }, 500);
            props.ApiClearAction()
        }
        if (props.apiCallStatus.apiCallFor === "sendQuotation" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            Swal.fire('Success', `Your quotation has been uploaded successfully`, 'success');
            // Swal.fire('Success', "RFQ send succesfully", 'success');
            props.history.push('/inprogress-quotations')
            props.ApiClearAction()
        }
        if (props.apiCallStatus.apiCallFor === "sendQuotation" &&
            props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed) {
            Swal.fire('Error', props.apiCallStatus.message ? props.apiCallStatus.message : "Error in saving quotation", 'error');
            props.ApiClearAction()
        }
    });
    const handleTableChange = (itemCode, price, orderId, order) => {
        let orderDetail = cloneDeep(state.orderDetail);
        if (price === '') {
            delete orderDetail[itemCode];
        } else {
            let obj = { itemCode, price, orderId, order };;
            orderDetail[itemCode] = obj;
        }
        let sum = calculateSum(orderDetail)
        setState({
            ...state,
            price: sum,
            orderDetail
        })
    }
    const calculateSum = (data) => {
        let keys = Object.keys(data);
        let price = 0;
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            if (data[element] && data[element].price !== '') {
                price += parseFloat(data[element].price) * parseFloat(data[element].order);
            }
        }
        return price.toFixed(2);
    }
    const sendQuotation = () => {
        let items = [];
        let keys = Object.keys(state.orderDetail);
        if (state.quotationDetailList.length !== keys.length || state.deliveryDays === "") {
            let msg = "Please enter the delivery days";
            if (state.quotationDetailList.length !== keys.length) {
                msg = "Please enter the prices of all the items";
            }
            Swal.fire('Error', msg, 'error');
        } else {
            for (let index = 0; index < keys.length; index++) {
                const element = keys[index];
                if (state.orderDetail[element].price !== "") {
                    items.push({
                        itemCode: state.orderDetail[element].itemCode, price: parseFloat(state.orderDetail[element].price)
                    })
                }
            }
            props.sendQuotation({ items, quotationId: props.match.params.quotationId, orderId: state.basketId, totolPrice: state.price })
        }
    }
    const handleChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setState({
            ...state,
            [name]: val
        })
    }
    // const declineQuotation = () => {
    //     let orderDetail = [];
    //     let keys = Object.keys(state.orderDetail);
    //     for (let index = 0; index < keys.length; index++) {
    //         const element = keys[index];
    //         if (state.orderDetail[element].price !== "") {
    //             orderDetail.push({
    //                 itemCode: state.orderDetail[element].itemCode, price: state.orderDetail[element].price
    //             })
    //         }
    //     }
    // }
    const getNavbarOptions = () => {
        let options = [
            {
                img: 'left-arrow.svg',
                className: "orange",
                name: "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.history.goBack()
            }
        ]
        if (props.userType === 'supplier') {
            options.push({
                img: 'Edit-icon.svg',
                className: "dark-green " + (state.quotationDetailList.length !== Object.keys(state.orderDetail).length || state.deliveryDays === "" ? "btn-disable" : ""),
                name: "Send<br /> QTN",
                path: `/prepare-quotation/${props.match.params.quotationId}`,
                isLogout: false,
                isCustom: true,
                onClick: () => sendQuotation()
            })
        }
        return options;
    }

    return (
        <Wrapper>
            <DashboardNavbar title="Quotation" navbarOptions={getNavbarOptions()} className="mesh-board-wrap d-flex align-items-end" />
            {/* RFQ Detials Section */}
            <div className="rfq-details-wrap ordCatg-table-wrap sup-rfq-wrap d-flex align-items-start">
                <div className="rfq-durations rfq-details-items  descriptrfq">
                    <h2 className="mt-2">Vessel Name:</h2>
                    <div className="inner-content text-left">
                        <h2 className="mt-0">Del. Place:  <span className="font-weight-bold mt-1 d-inline-block"></span></h2>
                        <h2 className="mt-0">Del. date:   <span className="font-weight-bold mt-1 d-inline-block"></span></h2>
                        <h2 className="mt-0">Agent Detail:   <span className="font-weight-bold mt-1 d-inline-block"></span></h2>
                        <h2 className="mt-0">Creation Date:   <span className="font-weight-bold mt-1 d-inline-block"></span></h2>
                    </div>
                </div>
                {Object.size(props.quotationReducer.orderDetail) > 0 ?
                    <div className="rfq-rating rfq-details-items">
                        <h2 className="mt-2">{props.quotationReducer.orderDetail ? props.quotationReducer.orderDetail.vesselName : ""}</h2>
                        <h2 className="mt-2">{props.quotationReducer.orderDetail && props.quotationReducer.orderDetail.placeOfDelivery ? props.quotationReducer.orderDetail.placeOfDelivery.label : ""}</h2>
                        <h2 className="mt-2">{props.quotationReducer.orderDetail ? moment(props.quotationReducer.orderDetail.arrivalTime).format('YYYY-MM-DD') : ""}</h2>
                        <h2 className="mt-2">{props.quotationReducer.orderDetail && props.quotationReducer.orderDetail.agentDetail ? props.quotationReducer.orderDetail.agentDetail : ""}</h2>
                        <h2 className="mt-2">{props.quotationReducer.orderDetail ? moment(props.quotationReducer.orderDetail.createdAt).format('YYYY-MM-DD') : ""}</h2>
                    </div> : ""}
            </div>

            {/* RFQ Detials Section End */}

            <div className="ordCatg-table-wrap mt-3 sup-rfq-wrap">
                {/* Table */}
                <div className="ordCatg-InnTable store-catgTable">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-left">ItemCode</th>
                                    <th className="text-left">IMPA</th>
                                    <th className="text-left">Description</th>
                                    <th className="text-left">Quantity</th>
                                    <th className="text-left">Estimated Price</th>
                                    <th className="text-left">Unit</th>
                                    <th className="text-left">Price</th>
                                    <th className="text-left">Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.apiCallStatus.isStarted.indexOf("getQuotationDetail") !== -1 ?
                                    <tr><td colSpan="20">
                                        <div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={loader} alt='' />
                                        </div>
                                    </td></tr>
                                    : state.quotationDetailList && state.quotationDetailList.length > 0 ? state.quotationDetailList.map((item, index) => {
                                        return <tr key={index}>
                                            <td><span className="text-center td-wrap-sty">{item.itemCode}</span></td>
                                            <td><span className="td-wrap-sty text-center">{item.alternativeCode}</span></td>
                                            <td><span className="text-left td-wrap-sty">{item.description}</span></td>
                                            <td><span className="text-center td-wrap-sty">{item.order}</span></td>
                                            <td><span className="text-center td-wrap-sty">{item.estimatedPrice && item.estimatedPrice[0] ? (item.estimatedPrice[0].pricePerCountry.price).toFixed(2) : ""}</span></td>
                                            <td><span className="text-center td-wrap-sty">{item.standardPackagingUnit === "" ? item.pricePerPackagingUnit : item.standardPackagingUnit}</span></td>
                                            <td className="button-round-td">{props.userType === 'supplier' ? <input className="whi-round text-right" type="number" onChange={(e) => handleTableChange(item.itemCode, e.target.value, item.orderId, item.order)} /> : <span className="text-right td-wrap-sty">
                                                0</span>}</td>
                                            <td><span className="td-wrap-sty text-right">{state.orderDetail[item.itemCode] ? (state.orderDetail[item.itemCode].price * item.order).toFixed(2) : 0}</span></td>

                                        </tr>

                                    }) : state.quotationDetailList && state.quotationDetailList.length === 0 ?
                                            <tr><td colSpan="40" className="text-center">No Data Found</td></tr> : ''}
                            </tbody>
                        </table>
                    </div>
                    {props.userType === 'supplier' ?
                        <div className="tableFoot-content mt-4">
                            <ul className="list-inline pl-0 d-flex align-items-center justify-content-end deli-info-list mb-2">
                                <li>Total items:<span>{state.quotationDetailList.length}</span></li>
                                <li>Quoted items:<span>{Object.keys(state.orderDetail).length}</span></li>
                                <li>Amount:<span>{state.price} €</span></li>
                            </ul>
                            <div className="deli-info-ctn d-flex justify-content-end">
                                <div className="deli-info-items-ctn">
                                    <div className="form-group mb-1 d-flex align-items-center justify-content-end deli-day-info">
                                        <label>Delivery time:</label>
                                        <input type="number" className="form-control" placeholder="" value={state.deliveryDays} name="deliveryDays" onChange={handleChange} />
                                        <label className="ml-2">days</label>
                                    </div>
                                </div>
                            </div>
                        </div> : ""}
                </div>
            </div>
        </Wrapper>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    quotationReducer: state.quotationReducer,
    userId: state.authReducer.userId,
    userType: state.authReducer.userType
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ApiClearAction: () => dispatch(ApiClearAction()),
    sendQuotation: (data) => dispatch(sendQuotation(data)),
    getQuotationDetail: (quotationId, supplierId) => dispatch(getQuotationDetail(quotationId, supplierId))

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmOrder);