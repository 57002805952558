import React, { useEffect, useState } from 'react';
import { RatingStars } from "./ratingStars";
import $ from 'jquery';
import { getCookie, setCookie } from '../../../Services/cookies';

export function FeedbackModal(props) {
    const [rate, setRate] = useState(0);
    const [supplierId, setSupplierId] = useState("0");
    useEffect(() => {
        if (props.apiCallStatus.apiCallFor === 'rateSupplier' && props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            $('#feedbackModal').modal('hide');
            setRate(0);
            props.ClearApiByNameAction('rateSupplier')
        }
        if (getCookie("orderFeedback") !== "" || (props.apiCallStatus.apiCallFor === 'orderFeedback' && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed)) {
            let data = JSON.parse(getCookie("orderFeedback"));
            const urlParams = new URLSearchParams(data.url);
            let supplierId = urlParams.get('supplierId');
            setSupplierId(supplierId)
            $('#feedbackModal').modal('show');
            setCookie("orderFeedback", "", "")
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
    }, [props.apiCallStatus])
    const rateSupplier = () => {
        props.rateSupplier({ customerId: props.userId, supplierId: supplierId, rate: rate })
    }
    const handleChange = (val) => {
        setRate(val)
    }
    return (
        <div className=" ratingmodel modal fade themeRounded-modal " id="feedbackModal" tabIndex="-1" aria-labelledby="NutritaionModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog">
                <div className="modal-content theme-modal-innr rat-us-mod px-5 pb-5">
                    <div class="modal-header">
                        <button type="button" className="close pull-left" data-dismiss="modal">&times;</button>
                    </div>
                    <RatingStars id="input" className="rating-star-wrap" isEdit={true} ratingAvg={rate} handleChange={handleChange} />
                    <h5 className="mb-2">Thank your for using MESH.</h5>
                    <p className="grey">Please be so kind to give us a short feedback regarding your selected supplier.We will only use this given feedback for the rating of the supplier, he will not be informed about it.Thank you for your kind assistance. We wish you a nice trip ahead.</p>
                    <p className="grey">Take care & best regards,</p>
                    <p className="grey">your MESH team.</p>
                    {props.apiCallStatus.isStarted.indexOf("rateSupplier") !== -1 ? <div className="loader-img text-center w-100">
                        <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                    </div> : ""}
                    {props.apiCallStatus.apiCallFor === "rateSupplier" && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                        <div className="alert alert-danger">
                            {props.apiCallStatus.message}
                        </div>
                        : ""}
                    <button type="button" onClick={() => rateSupplier()} className="btn tb-btn-rounded orng rounded">Rate & Send feedback</button>
                </div>
            </div>
        </div>
    )
}