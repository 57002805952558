import React, { Component } from 'react';
import { Wrapper } from '../../Orders/Css/previsionCatalouge';
import  DashboardNavbar  from '../../Common/Components/dashboardNavbar';
import cloneDeep from 'lodash.clonedeep';
import { provisionListing, getFilterDetail, saveOrder } from '../../Orders/ApiCalls/provisionOrder';
import { getItemCode, getItemGroup } from '../../Orders/ApiCalls/searchProvisionOrder';
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import Swal from 'sweetalert2'
import { connect } from 'react-redux';
import { getDashboardLink, Sum } from '../../../Services/common';
var Config = require('../../Orders/Components/Catalogue/config.json');



const calculateTotalUsedPoint = (data) => {
    let keys = Object.keys(data);
    let total = 0;
    for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        if (data[element] && data[element].usedPoints !== '') {
            total += data[element].usedPoints;
        }
    }
    let percentage = (total / 10000) * 100;
    return { total: total.toFixed(2), percentage: percentage.toFixed(2) };
}
class PriceListing extends Component {
    state = {
        selectedOption: null,
        catalougeList: [],
        filterCatalouge: [],
        currentOrder: {},
        isOrder: true,
        orderDetail: {},
        numOfCrew: 0,
        numOfDays: 0,
        itemGroupList: [{ label: "MEAT", value: "MEAT" }, { label: "FISH", value: "FISH" }],
        itemNoList: [{ label: "1101", value: "1101" },
        { label: "1102", value: "1102" },
        { label: "1103", value: "1103" },
        { label: "1104", value: "1104" },
        { label: "1105", value: "1105" },
        { label: "1106", value: "1106" },
        { label: "1107", value: "1107" },
        { label: "1108", value: "1108" },
        { label: "1109", value: "1109" }],
        filters: {
            itemGroup: "",
            itemNo: "",
            isApplied: false
        },
        showOrderDetailModel: false,
        orderNeeded: 0,
        totalOrder: 0,
        totalOrderPoints: 10000,
        totalOrderBudget: 0,
        filterQuery: '',
        totalCount: 0,
        totalItemCode: 0,
        activePage: 0,
        itemCodePage: 0,
    };

    componentDidMount() {
        if (this.props.currentOrder && this.props.currentOrder.numberOfCrew && this.props.currentOrder.orderPeriod) {
            let numOfCrew = Sum(this.props.currentOrder.numberOfCrew.regular, this.props.currentOrder.numberOfCrew.additional);
            let numOfDays = Sum(this.props.currentOrder.orderPeriod.regular, this.props.currentOrder.orderPeriod.expectedDelay);
            let orderNeeded = 0;
            let vesselPriceRate = parseFloat(this.props.currentOrder.vesselRate);
            for (let index = 0; index < Config.category.length; index++) {
                orderNeeded += parseFloat(Config.category[index].qtyPerDay) / 1000 * numOfCrew * numOfDays;
            }
            this.setState({
                numOfCrew,
                numOfDays,
                totalOrderBudget: vesselPriceRate * numOfCrew, numOfDays,
                orderNeeded: orderNeeded.toFixed(2)
            })
            this.props.provisionListing(0, 200, this.state.filterQuery)
            this.props.getItemCode(0);
            this.props.getItemGroup()
        } else {
            this.props.history.push('/create-store-order')
        }

    }
    componentDidUpdate(prevProps, prevState) {
        if (Object.keys(this.props.currentOrder).length !== 0 && Object.keys(this.state.currentOrder).length === 0 && this.state.isOrder) {
            this.setState({
                currentOrder: this.props.currentOrder,
                isOrder: false
            })
        }
        if (((prevProps.apiCallStatus.isStarted.indexOf('getProvision') !== -1 &&
            this.props.apiCallStatus.isStarted.indexOf('getProvision') === -1) || (prevProps.apiCallStatus.isStarted.indexOf('appendProvisionList') !== -1 &&
                this.props.apiCallStatus.isStarted.indexOf('appendProvisionList') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
            this.setState({
                ...this.state,
                catalougeList: this.props.orderReducer.provisionList,
                filterCatalouge: this.props.orderReducer.provisionList,
                totalCount: this.props.orderReducer.totalProvision,
            })
        }
        if (((prevProps.apiCallStatus.isStarted.indexOf('getItemCode') !== -1 &&
            this.props.apiCallStatus.isStarted.indexOf('getItemCode') === -1) || (prevProps.apiCallStatus.isStarted.indexOf('appendItemCode') !== -1 &&
                this.props.apiCallStatus.isStarted.indexOf('appendItemCode') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = this.props.orderReducer.itemCodeList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i].itemCode, label: arr[i].itemCode });
            }
            this.setState({
                ...this.state,
                itemNoList: newArr,
                totalItemCode: this.props.orderReducer.totalItemCode,
            })
        }

        if ((prevProps.apiCallStatus.isStarted.indexOf('getItemGroup') !== -1 &&
            this.props.apiCallStatus.isStarted.indexOf('getItemGroup') === -1) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = this.props.orderReducer.itemGroupList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i], label: arr[i] });
            }
            this.setState({
                ...this.state,
                itemGroupList: newArr,
            })
        }
    }
    handleFilterChange = (id, val) => {
        let filters = { ...this.state.filters };
        filters[id] = val;
        this.setState({ filters });
    };

    clearFilters = () => {
        this.setState({
            filters: {
                itemGroup: "",
                itemNo: "",
                isApplied: false
            },
            itemCodePage: 0,
        }, () => {
            this.props.provisionListing(0, 200, '')
            this.props.getItemCode(0)
            this.props.getItemGroup()
        })
    }

    applyFilters = () => {
        let val1 = '';
        let val2 = '';
        let activePage = 0;
        if (this.state.filters.itemGroup !== '') {
            val1 = "itemGroup=" + this.state.filters.itemGroup
        } if (this.state.filters.itemNo !== '') {
            val2 = "itemNo=" + this.state.filters.itemNo;
        }
        let filterQuery = val1 !== '' ? `?${val1}` : '?';
        filterQuery = filterQuery === '?' ? `${filterQuery + val2}` : `${filterQuery}&${val2}`;
        this.setState({
            filterQuery,
            activePage,
            itemCodePage: 0,
            isApplied: true
        }, () => {
            this.props.provisionListing(activePage, 200, filterQuery)
        })
        // let th = this;
        // let filters = { ...this.state.filters };
        // filters.isApplied = true;
        // let filterCatalouge = this.state.catalougeList.filter(function (el) {
        // 	if (th.state.filters.itemGroup !== '' && th.state.filters.itemNo !== '') {
        // 		return el.itemGroup === th.state.filters.itemGroup &&
        // 			el.itemNo === th.state.filters.itemNo;
        // 	} else if (th.state.filters.itemGroup !== '') {
        // 		return el.itemGroup === th.state.filters.itemGroup;
        // 	} else if (th.state.filters.itemNo !== '') {
        // 		return el.itemNo === th.state.filters.itemNo;
        // 	}
        // });
        // this.setState({
        // 	filters,
        // 	filterCatalouge
        // })
    }

    handleTableChange = (itemNo, id, value, budgetPoints, category) => {
        let orderDetail = cloneDeep(this.state.orderDetail);
        let obj = orderDetail[itemNo];
        let totalOrder = 0;
        let catIndex = Config.category.findIndex(x => x.category === category);
        let needed = 0;
        if (catIndex !== -1) {
            needed = parseFloat(Config.category[catIndex].qtyPerDay) / 1000 * this.state.numOfCrew * this.state.numOfDays
        } else {
            catIndex = Config.category.findIndex(x => x.category === "MEAT");
            if (catIndex !== -1) {
                needed = parseFloat(Config.category[catIndex].qtyPerDay) / 1000 * this.state.numOfCrew * this.state.numOfDays
            }
        }
        if (obj) {
            obj[id] = value;
            obj.budgetPoints = budgetPoints;
            obj.category = category;
        } else {
            obj = { remark: '', order: '', budgetPoints: '', usedPoints: '', category: "" };
            obj[id] = value;
            obj.budgetPoints = budgetPoints;
            obj.category = category;
        }
        let order = parseFloat(obj.order);
        let count = 0;
        if (!isNaN(order)) {
            count += order
        }
        obj.usedPoints = budgetPoints * count;
        orderDetail[itemNo] = obj;
        let calculateOrderedProduct = this.calculateOrderedProduct(category, orderDetail);
        if (needed < calculateOrderedProduct) {
            Swal.fire("Error", "Pleas adjust the quantities of item group before sending the order", "error");
        } else if (calculateTotalUsedPoint(orderDetail).percentage <= 100) {
            let orderkeys = Object.keys(orderDetail);
            for (let index = 0; index < orderkeys.length; index++) {
                const element = orderkeys[index];
                if (orderDetail[element]) {
                    let num = parseFloat(orderDetail[element].order)
                    if (!isNaN(num)) {
                        totalOrder += num;
                    }
                }

            }
            this.setState({
                totalOrder,
                orderDetail
            })
        } else {
            Swal.fire("Error", "Pleas adjust the quantities of item group before sending the order", "error");
        }

    }
    fetchMoreData = () => {
        let { activePage } = this.state;
        activePage += 1;
        this.setState({
            activePage
        }, () => { this.props.provisionListing(activePage, 200, this.state.filterQuery) })
    };
    fetchMoreItemCode = () => {
        let { itemCodePage } = this.state;
        itemCodePage += 1;
        this.setState({
            itemCodePage
        }, () => { this.props.getItemCode(itemCodePage) })
    }
    saveOrder = () => {
        let percnetage = parseFloat(Config["Sufficient-provisions-minimum"]);
        let needed = (this.state.orderNeeded / 100) * percnetage;
        // if (needed > this.state.totalOrder) {
        // 	Swal.fire("Error", `Attention! Your ordered quantities are below ${percnetage}% of the recommended quantities for your trip. In order to ensure sufficient provisions on board, please adjust quantities accordingly.`, "error");
        // } else {
        let data = { ...this.props.currentOrder };
        let orderDetail = []
        let keys = Object.keys(this.state.orderDetail);
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            orderDetail.push({
                itemCode: element,
                inventory: this.state.orderDetail[element].inventory,
                order: this.state.orderDetail[element].order,
                budgetPoints: this.state.orderDetail[element].budgetPoints,
                usedPoints: this.state.orderDetail[element].usedPoints
            })
        }
        data.orderDetail = orderDetail;
        data.userId = this.props.userId;
        data.orderProvisionType = "store";
        delete data.vesselRate;
        this.props.saveOrder(data);
        // }
    }
    calculateOrderedProduct = (category, orderDetail) => {
        let sum = 0;
        let keys = Object.keys(orderDetail)
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            if (orderDetail[element].category === category && orderDetail[element].order !== '') {
                sum += parseFloat(orderDetail[element].order)
            }
        }
        return sum;
    }
    render() {
        const navbarOptions = [
            {
                img: 'left-arrow.svg',
                className: "dark-pink",
                name:  "Go back",
                path: "/checkout",
                isLogout: false,
                isCustom: true,
                onClick: () => this.props.history.goBack()
            },
            {
                img: 'Monitor-icon.svg',
                className: "dark-blue",
                name: "Dash<br />board",
                path: getDashboardLink(),
                isLogout: false
            },
            {
                img: 'Message-icon.svg',
                className: "green",
                name: "Send<br />Order",
                path: "/checkout",
                isLogout: false,
                isCustom: true,
                onClick: this.saveOrder
            }
        ];
        const totalUsedPoints = calculateTotalUsedPoint(this.state.orderDetail);
        return (
            <Wrapper>
                <div className="pageWrapper">
                    <div className="pageContainer mb-0">
                        <div className="order-dlts-wrapper">
                            <DashboardNavbar title="MESH* YOUR PROVISION ORDER" navbarOptions={navbarOptions} isShowLogo={true} logoUrl="logo.png" logoType="Order" className="pageHeader d-flex align-items-center flex-column flex-lg-row" />
                        </div>
                        <div className="ordCatg-table-wrap mt-3">
                            <div className="ordCatg-InnTable">
                                {/* <InfiniteScroll
                                    dataLength={props.data.length}
                                    next={getMoreData}
                                    height={"80vh"}
                                    hasMore={props.totalCount <= props.data.length ? false : true}
                                    loader={props.apiCallStatus.isStarted.indexOf("appendProvisionList") !== -1 ? <div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div> : ""}
                                > */}
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <TableHead />
                                        </thead>
                                        <tbody>

                                            <TableRow />
                                        </tbody>
                                    </table>
                                </div>
                                {/* </InfiniteScroll> */}
                            </div>

						</div>
                    </div>
                    {/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
                    {/* Order Details Modal */}
                </div>
            </Wrapper>
        )
    }
}
function TableHead(props) {
    return <tr>
        <th>Product group</th>
        <th>IMPA code.</th>
        <th>Description</th>
        <th>Remarks</th>
        <th><span className="bg-rounded white">Order</span></th>
        <th>Standard pack. unit</th>
        <th>Points per unit </th>
        <th>Price total</th>
        <th><span className="bg-rounded white">Remark</span></th>
    </tr>
}

function TableRow(props) {
    return <tr >
        <td><span className="td-wrap-sty">{'props.category'}</span></td>
        <td><span className="td-wrap-sty">{'props.alternativeCode'}</span></td>
        <td><span className="td-wrap-sty">{'props.description'}</span></td>
        <td><span className="td-wrap-sty">{'props.explanation'}</span></td>
        <td><input className="whi-round" type="number" value='dummy' /></td>
        <td><span className="td-wrap-sty">{'props.standardPackagingUnit'}</span></td>
        <td><span className="td-wrap-sty">{'props.price'}</span></td>
        <td><span className="td-wrap-sty">{'props.priceAvg'}</span></td>
        <td><input className="whi-round" type="text" value={'props.remark'} /></td>
    </tr>
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    orderReducer: state.orderReducer,
    currentOrder: state.orderReducer.currentOrder,
    userId: state.authReducer.userId

})

const mapDispatchToProps = (dispatch, ownProps) => ({
    provisionListing: (offset, limit, filterQuery) => dispatch(provisionListing(offset, limit, filterQuery)),
    getItemCode: (offset) => dispatch(getItemCode(offset)),
    getItemGroup: () => dispatch(getItemGroup()),
    getFilterDetail: (offset, filterQuery) => dispatch(getFilterDetail(offset, filterQuery)),
    saveOrder: (data) => dispatch(saveOrder(data)),
    ApiClearAction: () => dispatch(ApiClearAction())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PriceListing);




