import React, { useEffect, useState } from 'react';
import { DashboardWrapper } from "./dashboarWrapper";
import { supplierListing } from "../../Supplier/ApiCalls/supplier";
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from '../../../assets/images/Spinner-1s-200px.gif';
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";

function TopClients(props) {
    const [state, setState] = useState({
        supplierList: [],
        activePage: 0,
        totalCount: 0,
        startDate: new Date(new Date().getTime() - (60 * 60 * 24 * 30 * 1000)),
        endDate: new Date(),
        isHitApi: false,
    });
    useEffect(() => {
        if (!state.isHitApi) {
            props.supplierListing(0, 'supplier');
            let data = { ...state };
            data.isHitApi = true;
            setState(data);
        }
        if ((props.apiCallStatus.apiCallFor === "supplierListing" || props.apiCallStatus.apiCallFor === "appendSupplierList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                supplierList: props.supplierReducer.list,
                totalCount: props.supplierReducer.count,
                isHitApi: true
            })
        }
    }, [props])
    const fetchMoreData = () => {
        let { activePage } = state;
        activePage += 1;
        setState({
            ...state,
            activePage
        })
        props.supplierListing(activePage, 'supplier')
    };
    const handleChange = (id, val) => {
        setState({
            ...state,
            [id]: val,
            isHitApi: false
        })
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name:  "Go back",
            path: "/upgrademesh",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <DashboardWrapper title="Top Clients" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end">
            <div className="mt-lg-5 d-flex flex-column flex-lg-row">
                <div className="dashVesl-overview-wrap">
                    <div className=" dash-content-bx">
                        <div className="dash-chartInfo-table">
                            <h6>Top Supply Hub</h6>
                            <div className="date-range-picker d-flex align-items-center">
                                            <span className="d-inline-block ml-0">
                                                <DatePicker
                                                    selected={state.startDate}
                                                    onChange={(val) => handleChange('startDate', val)} className="link-style"
                                                />
                                            </span>
                                            to
                                                <span className="d-inline-block">
                                                <DatePicker
                                                    selected={state.endDate}
                                                    onChange={(val) => handleChange('endDate', val)} className="link-style"
                                                />
                                            </span>
                                        </div>
                            <InfiniteScroll
                                dataLength={state.supplierList.length}
                                next={fetchMoreData}
                                height={"80vh"}
                                hasMore={state.supplierList.length <= state.supplierList.length ? false : true}
                                loader={<div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div>}
                            >
                                <div className="table-responsive">
                                    <table className="table table-light2">
                                        <tbody>
                                            <tr>
                                                <td>1.</td>
                                                <td>RAG STERILIZED LIGHT COLOR</td>
                                                <td>453</td>
                                                <td>453</td>
                                                <td className="semi-bold">1250.000,00 USD</td>
                                                <td className="semi-bold"></td>
                                            </tr>
                                            <tr>
                                                <td>1.</td>
                                                <td>RAG STERILIZED LIGHT COLOR</td>
                                                <td>453</td>
                                                <td>453</td>
                                                <td className="semi-bold">1250.000,00 USD</td>
                                                <td className="semi-bold"></td>
                                            </tr>
                                            <tr>
                                                <td>1.</td>
                                                <td>RAG STERILIZED LIGHT COLOR</td>
                                                <td>453</td>
                                                <td>453</td>
                                                <td className="semi-bold">1250.000,00 USD</td>
                                                <td className="semi-bold"></td>
                                            </tr>
                                            <tr>
                                                <td>1.</td>
                                                <td>RAG STERILIZED LIGHT COLOR</td>
                                                <td>453</td>
                                                <td>453</td>
                                                <td className="semi-bold">1250.000,00 USD</td>
                                                <td className="semi-bold"></td>
                                            </tr>
                                            <tr>
                                                <td>1.</td>
                                                <td>RAG STERILIZED LIGHT COLOR</td>
                                                <td>453</td>
                                                <td>453</td>
                                                <td className="semi-bold">1250.000,00 USD</td>
                                                <td className="semi-bold"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardWrapper>
    )

}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    supplierReducer: state.supplierReducer,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    supplierListing: (offset, type) => dispatch(supplierListing(offset, type)),
    ApiClearAction: () => dispatch(ApiClearAction()),

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopClients);
