import React from 'react';
import Sidebar from '../../Common/Components/sidebar';
import { Wrapper } from '../Css/vessel.jsx';

export const VesselWrapper = ({ children }) => (
    <Wrapper>
        <div className="form-content-ctn d-flex">
            <Sidebar />
            <div className="dash-page-content contrVessel-Dash-wrap">
                <div className="dash-pg-inn-wrap">
                    <div className="">
                        {children}
                    </div>

                </div>
            </div>
        </div>
    </Wrapper>
);