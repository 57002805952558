import React, { useEffect } from 'react';
import Select from 'react-select';
import { Sum } from "../../../../../Services/common";
import DatePicker from "react-datepicker";
import moment from "moment";
import $ from 'jquery';
import { AutoCompleteInput } from '../../../../Common/Components/autoComplete';
import "react-datepicker/dist/react-datepicker.css";

export function DeliveryDetail(props) {
    useEffect(() => {
        $("input[type=number]").on("focus", function () {
            $(this).on("keydown", function (event) {
                if (event.keyCode === 38 || event.keyCode === 40 || event.key === "-") {
                    event.preventDefault();
                }
            });
        });
    }, [])
    const handleChange = (id, value, changeFor, provisions) => {
        let obj = { ...props[changeFor] };
        obj[id] = value;
        props.handleChange({ target: { id: changeFor, value: obj } }, 'deliveryDetail')

    }
    return (
        <div className="order-dtls-sec-ctn deli-dlts-ctn storeCreate-order main-screen">
            <div className="card-group-cnt d-flex align-items-center flex-column flex-md-row w-100">
                <h2 className="title-dark-20">DELIVERY DETAILS</h2>
                <div className="flex-fill order-right-ctn">
                    <h6 className="require-note text-danger">Fields with * are mandatory!</h6>
                    <div className="card-bx-sty deli-dlts-ctn-info custom-column">
                        {/* Form Error Wrapper */}
                        {props.errorMessage !== "" && props.errorFor === "deliveryDetail" ?
                            <div className="alert alert-danger" role="alert">
                                {props.errorMessage}
                            </div>
                            : ""}
                        <div className="row">
                            <div className="col-md-5">
                                <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">IMO-Nr.:*</label>
                                    <input type="text" id="imoNum" value={props.imoNum}
                                        className="form-control field-bx-sty" placeholder="Enter IMO-Nr value" required required readOnly />
                                </div>
                                <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">Reference no.:*</label>
                                    <input type="text" id="refNo" value={props.refNo}
                                        className="form-control field-bx-sty" placeholder="Reference no" required readOnly />
                                </div>
                                <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">Vessel.:*</label>
                                    <input type="text" id="vessel" value={props.vessel === "" ? "" : props.vessel.label}
                                        className="form-control field-bx-sty" placeholder="Select Vessel" required readOnly />
                                </div>
                                 <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">Captain.:*</label> 
                                    <div className="custom-select-menu text-bx-wrap custom-select2">
                                        <Select
                                            className="custom-select-wrap" classNamePrefix="react-select" placeholder="Enter Captain’s Name"
                                            value={props.captain === "" ? null : props.captain}
                                            onChange={(e) => props.handleChange({ target: { id: "captain", value: e } }, 'deliveryDetail')}
                                            options={props.captainList}
                                            isSearchable={false}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center content-items-sty">
                                    <div className="form-group d-flex align-items-center flex-fill">
                                        <label className="label-reg-sty">Who orders? (name):*</label>
                                        <input type="text" id="orderBy" value={props.orderBy}
                                            onChange={(e) => props.handleChange(e, 'deliveryDetail')} className="form-control field-bx-sty" placeholder="Enter name" required />
                                    </div>
                                </div> 
                                <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">Who orders? (rank)</label>
                                    <div className="custom-select-menu text-bx-wrap custom-select2">
                                        <Select
                                            className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Rank"
                                            value={props.rank === "" ? null : props.rank}
                                            onChange={(e) => props.handleChange({ target: { id: "rank", value: e } }, 'deliveryDetail')}
                                            isDisabled={props.isRankDisable}
                                            options={props.rankList}
                                        />
                                    </div>
                                </div>
                                <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">Country.:*</label>
                                    <div className="custom-select-menu text-bx-wrap custom-select2">
                                        <Select
                                            className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Country"
                                            value={props.country === "" ? null : props.country}
                                            onChange={(e) => props.handleChange({ target: { id: "country", value: e } }, 'deliveryDetail')}
                                            options={props.countryList}
                                        />
                                    </div>
                                </div>
                                <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">Place of delivery.:*</label>
                                    <div className="custom-select-menu text-bx-wrap custom-select2" style={{ opacity: props.isStarted.indexOf('getAllPort') !== -1 ? 0.5 : 1 }}>
                                        <Select
                                            className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Port"
                                            value={props.place === "" ? null : props.place}
                                            onChange={(e) => props.handleChange({ target: { id: "place", value: e } }, 'deliveryDetail')}
                                            options={props.portList}
                                            isDisabled={props.isStarted.indexOf('getAllPort') !== -1 ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">Agent's Detail:</label>
                                    <input type="text" id="agentDetail" value={props.agentDetail}
                                        onChange={(e) => props.handleChange(e, 'deliveryDetail')} className="form-control field-bx-sty" placeholder="Enter Agent's Detail" />
                                </div>
                            </div>
                            <div className="col-md-7 deli-dlts-ctn-right">
                                <div className="d-flex align-items-center content-items-sty flex-row date-selector-wrap">
                                    <div className="form-group d-flex align-items-center flex-fill justify-content-end">
                                        <label className="label-reg-sty">ETA.:*</label>
                                        <div className="datePicker form-control field-bx-sty p-0 fa-icon" >
                                            <DatePicker selected={props.eta}
                                                onChange={(val) => props.handleChange({ target: { id: "eta", value: val } }, 'deliveryDetail')} minDate={new Date()} />
                                        </div>
                                    </div>
                                    <div className="form-group d-flex align-items-center flex-fill justify-content-end">
                                        <label className="label-reg-sty">ETD.:*</label>
                                        <div className="datePicker form-control field-bx-sty p-0 fa-icon" >
                                            <DatePicker selected={props.etd}
                                                disabled={props.eta === "" ? true : false}
                                                onChange={(val) => props.handleChange({ target: { id: "etd", value: val } }, 'deliveryDetail')}
                                                minDate={props.eta !== "" ? new Date(moment(props.eta).add(1, 'days')) : new Date(moment().add(1, 'days'))} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group d-flex align-items-center flex-fill list-grouped">
                                    <label className="label-reg-sty">Order period.:*</label>
                                    <div className="deliv-dtls-list-sty d-flex align-items-center flex-fill">
                                        <div className="form-group mr-2 flex-fill">
                                            <label className="label-reg-sty text-truncate">Regular</label>
                                            <input type="number" min="1" className="form-control field-bx-sty" placeholder="60 days" value={props.orderPeriod.regular === 0 || props.orderPeriod.regular === "0" ? "" : props.orderPeriod.regular} onChange={(e) => handleChange('regular', e.target.value,
                                                'orderPeriod')} required />
                                        </div>
                                        <div className="form-group flex-fill">
                                            <label className="label-reg-sty text-truncate">Expected delays</label>
                                            <input type="number" min="1" className="form-control field-bx-sty" placeholder="3 days" value={props.orderPeriod.expectedDelay === 0 || props.orderPeriod.expectedDelay === "0" ? "" : props.orderPeriod.expectedDelay}
                                                onChange={(e) => handleChange('expectedDelay', e.target.value,
                                                    'orderPeriod')} />
                                        </div>
                                        <div className="form-group total-count-ctn ml-2 flex-fill mb-0">
                                            <label className="label-reg-sty text-truncate pl-0">Total</label>
                                            <input type="text" className="form-control field-bx-sty bg_orng no-bg without-value" value={Sum(props.orderPeriod.regular, props.orderPeriod.expectedDelay)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center content-items-sty flex-row currency-fields-wrap">
                                    <div className="form-group d-flex align-items-center flex-fill">
                                        <label className="label-reg-sty">Budget for period:*</label>
                                        <input type="text" id="deptBudget" value={((Sum(props.orderPeriod.regular, props.orderPeriod.expectedDelay) * (props.deptBudget)) / 365).toFixed(2)} className="form-control field-bx-sty" placeholder="Enter Budget" required readOnly />
                                    </div>
                                    <div className="form-group d-flex align-items-center flex-fill">
                                        <label className="label-reg-sty">Currency:*</label>
                                        <input type="text" className="currency-field form-control field-bx-sty text-center" placeholder="Enter Currency" id="currency" value={props.currency}
                                            onChange={(e) => props.handleChange(e, 'deliveryDetail')} required readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}