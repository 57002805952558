import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CustomSelect from '../../Common/Components/CustomAutoComplete';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export function Filters(props) {
    // const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {

    }, []);
    const fetchMore = () => {

    }
    return (
        <div className="mb-2 customer_search">
            <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                <div className="fil-wrap-ctn d-flex align-items-center flex-column flex-sm-row mb-2 mb-lg-0">
                    <div className="date-range-picker d-flex align-items-center vesseldateinput">
                        <span className="d-inline-block ml-0">
                            <DatePicker
                                selected={props.startDate}
                                onChange={(val) => props.handleChange('startDate', val)}
                                maxDate={new Date()}
                                className="link-style"
                            />
                        </span>
                        to
                         <span className="d-inline-block mr-0">
                            <DatePicker
                                selected={props.endDate}
                                onChange={(val) => props.handleChange('endDate', val)}
                                disabled={props.startDate === null ? true : false}
                                className="link-style"
                            />
                        </span>
                    </div>

                    <div className="vessel_fiel dcustom-select-menu text-bx-wrap custom-select2  p-0">
                        <CustomSelect pagesList={props.vesselNameOptions} inputId="inputName" id="name" fetchMore={fetchMore} inputValue={props.name} value={props.name} placeholder="Search by name" handleChange={props.handleChange} clickCustomeSelectHandler={props.handleChange} />
                    </div>
                    <div className=" vessel_field custom-select-menu text-bx-wrap custom-select2 p-0">
                        <CustomSelect pagesList={props.vesselTypeOptions} inputId="inputType" id="type" fetchMore={fetchMore} inputValue={props.type} value={props.type} placeholder="Search by type" handleChange={props.handleChange} clickCustomeSelectHandler={props.handleChange} />
                    </div>
                    <div className="custom-select-menu text-bx-wrap custom-select2  p-0">
                        <CustomSelect pagesList={props.vesselCustomerOptions} isReturnObj={true} inputId="inputcustomerName" id="customerName" fetchMore={fetchMore} inputValue={props.customerName.label ? props.customerName.label : ''} value={props.customerName} placeholder="Search by Customer Name" handleChange={props.handleChange} clickCustomeSelectHandler={props.handleChange} />
                    </div>
                    <div className="date-range-picker d-flex align-items-center">
                        <span className="d-inline-block ml-0">
                            <DatePicker
                                selected={props.nextDeliveryDate}
                                onChange={(val) => props.handleChange('nextDeliveryDate', val)}
                                placeholderText={"Delivery Date"}
                                className="link-style"
                            />
                        </span>
                    </div>
                    <div className="d-flex flex-fill ml-1">
                        <button type="button" className="btn btn-yellow" onClick={() => props.clearFilter()} data-toggle="modal" data-target="#orderDetailModal">Clear</button>
                    </div>
                </div>

                <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill justify-content-end">
                    {/* <button type="button" className=" btn btn-yellow" onClick={() => props.applyFilters()} >Search</button> */}
                    {/* <button type="button" className=" btn btn-yellow  ml-1" onClick={() => props.clearFilters()} >Reset</button>*/}


                </div>
            </div>
        </div>
    )
}