import * as Action from "./../Actions/actions";
import { LogOutSuccessful } from "../../Auth/Actions/action";
import InitialState from "./../../../Constants/initialState";
import cloneDeep from "lodash/cloneDeep";
export default function SocketReducer(
  state = InitialState.socketDetail,
  action
) {
  switch (action.type) {
    case Action.getNotificationList:
      let s = cloneDeep(state);
      s.notificationsList = action.payload.list;
      s.unReadMsgCount = action.payload.unReadMsgCount;
      s.totalCount = action.payload.totalCount;
      return s;
    case Action.appendNewNotification:
      let s1 = cloneDeep(state);
      s1.notificationsList.unshift(action.payload.data);
      s1.unReadMsgCount = action.payload.unReadMsgCount;
      return s1;
    case Action.updateUnReadMsgCount:
      let s2 = cloneDeep(state);
      s2.unReadMsgCount = action.payload.unReadMsgCount;
      return s2;
    case Action.appendNotificationList:
      let s3 = cloneDeep(state);
      let notifIndex = s3.notificationsList.findIndex(
        (x) => x._id === action.payload.data._id
      );
      if (notifIndex === -1) {
        var arr = s3.notificationsList.slice();
        s3.notificationsList = arr.concat(action.payload.data);
        s3.unReadMsgCount = action.payload.unReadMsgCount;
        s3.totalCount = action.payload.totalCount;
        return s3;
      }
    case Action.readNotificationItem:
      let s4 = cloneDeep(state);
      let index = s4.notificationsList.findIndex(
        (x) => x._id === action.payload.notificationId
      );
      if (index !== -1) {
        s4.notificationsList[index].isRead = true;
      }
      return s4;
    case LogOutSuccessful:
      return {
        notificationsList: [],
        unReadMsgCount: 0,
        totalCount: 0,
      };
    default:
      return state;
  }
}
