import React, { useEffect, useState } from "react";
import {
  Wrapper,
  DashboardNavbar,
  CsvDownload,
} from "./../../Common/Components/index";
import {
  customerListing,
  deleteCustomer,
  updateCustomer,
  getCustomerEmail,
} from "./../../Common/ApiCalls/customerList";
import { changePassword } from "../../User/ApiCalls/user";
import { topCustomer } from "./../../Common/ApiCalls/topSupplier";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { vesselListing } from './../../Vessel/ApiCalls/vessel';
import { connect } from "react-redux";
import moment from "moment";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { isAllowPermission, getDashboardLink } from "../../../Services/common";
// import { getRoutePathBykey } from "../../../Constants/routesConfig";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { io } from "socket.io-client";
import { ChangePasswordModal } from "../../Common/Components/changePasswordModal";
const headers = [
  { label: "Email", key: "email" },
  { label: "Status", key: "isActive" },
];
var csvLinkRef = React.createRef();
function CustomerList(props) {
  const [state, setState] = useState({
    customerList: [],
    customerVessels: [],
    csvData: [],
    startDate: null,
    endDate: null,
    filterQuery: "",
    selectedEmail: null,
    emailListing: [],
    activePage: 0,
    totalCount: 0,
    isHitApi: false,
    selectedUser: "",
    showModal: "",
  });
  const query = new URLSearchParams(props.location.search);
  let topCustomer = query.get("topCustomer");
  let supplierId = query.get("supplierId");
  useEffect(() => {
    if (!state.isHitApi) {
      const socket = io("http://localhost:5000?user=test", {
        transports: ["websocket"],
      });
      socket.on("connect", () => {
        console.log(socket.id);
      });
      socket.on("hello", (arg) => {
        console.log(arg);
      });
      if ((props.isSubAdmin && props.userType === 'user' && props.subType !== 'sub-admin') || isAllowPermission(window.location.pathname, props.roles)) {
        let data = { ...state };
        let filterQuery = "";
        if (topCustomer !== null) {
          let userType =
            props.userType === "supplier"
              ? "supplier"
              : props.subType === "captain" || props.subType === "customer"
                ? "customer"
                : "";
          let filterQuery = {};
          if (supplierId) {
            filterQuery['supplierId'] = supplierId
          }
          if (props.userType !== 'customer') {
            props.topCustomer(props.userId, userType, '', '', filterQuery);
          } else {
            props.vesselListing(0, 50);
          }

        } else {
          props.customerListing(0, "customer", filterQuery);
        }
        data.isHitApi = true;
        data.filterQuery = filterQuery;
        setState(data);
      } else if (!isAllowPermission(window.location.pathname, props.roles)) {
        props.history.push("/not-allowed");
      }
    }
    if (
      (props.apiCallStatus.apiCallFor === "getCustomer" ||
        props.apiCallStatus.apiCallFor === "appendCustomerList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let newArray = [];
      let data = [...props.commonReducer.customerList];
      for (let index = 0; index < data.length; index++) {
        let email = data[index].email;
        let isActive = data[index].isActive ? "Enable" : "Disable";
        let obj = {
          email,
          isActive,
        };
        newArray.push(obj);
      }
      setState({
        ...state,
        customerList: props.commonReducer.customerList,
        totalCount: props.commonReducer.totalCustomer,
        csvData: newArray,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (props.apiCallStatus.apiCallFor === "vesselListing" || props.apiCallStatus.apiCallFor === "appendVesselList" &&
      props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
      let customerVessels = [];
      if (props.subType === 'customer' && props.vesselList.length > 0) {
        for (let index = 0; index < props.vesselList.length; index++) {
          const id = props.vesselList[index]._id;
          customerVessels.push(id)
        }
        let userType =
          props.userType === "supplier"
            ? "supplier"
            : props.subType === "captain" || props.subType === "customer"
              ? "customer"
              : "";
        let filterQuery = {};
        if (supplierId) {
          filterQuery['supplierId'] = supplierId
        }
        props.topCustomer(customerVessels, userType, '', '', filterQuery);
      }
      setState({
        ...state,
        customerVessels,
        isHitApi: true
      })
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
    }
    if (
      props.apiCallStatus.apiCallFor === "topCustomer" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      let newArray = [];
      let data = [...props.commonReducer.topCustomerList];
      for (let index = 0; index < data.length; index++) {
        let email = data[index].email;
        let isActive = data[index].isActive ? "Enable" : "Disable";
        let obj = {
          email,
          isActive,
        };
        newArray.push(obj);
      }
      setState({
        ...state,
        customerList: props.commonReducer.topCustomerList,
        totalCount: props.commonReducer.topCustomerList.length,
        csvData: newArray,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "deleteCustomer" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let startDate = null;
      let endDate = null;
      let filterQuery = "";
      props.customerListing(0, "customer", "");
      setState({
        ...state,
        startDate,
        endDate,
        filterQuery,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
  }, [props, state.isHitApi]);
  const fetchMoreData = () => {
    let { activePage, filterQuery } = state;
    activePage += 1;
    setState({
      ...state,
      activePage,
    });
    if (topCustomer !== null) {
      let userType =
        props.userType === "supplier"
          ? "supplier"
          : props.subType === "captain" || props.subType === "customer"
            ? "customer"
            : "";
      let filterQuery = {};
      if (supplierId) {
        filterQuery['supplierId'] = supplierId
      }
      if (props.userType !== 'customer') {
        props.topCustomer(props.userId, userType, '', '', filterQuery);
      } else {
        props.topCustomer(state.customerVessels, userType, '', '', filterQuery);

      }

    } else {
      props.customerListing(activePage, "customer", filterQuery);
    }
  };
  const removeRecord = (id) => {
    Swal.fire({
      title: "Delete Customer",
      text: "Are you sure you want to delete this customer?",
      icon: "warning",
      buttons: true,
      showCancelButton: true,
      dangerMode: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteCustomer(id);
      }
    });
  };
  const statusHandler = (id, data) => {
    let obj = {
      isActive: data,
    };
    let newArray = state.customerList;
    let index = newArray.findIndex((x) => x.id === id);
    newArray[index]["isActive"] = data;
    setState({
      ...state,
      customerList: newArray,
    });
    props.updateCustomer(id, obj);
  };
  const handleChange = (id, val) => {
    setState({
      ...state,
      [id]: val,
    });
  };
  const applyFilters = () => {
    if (state.startDate !== null && state.endDate !== null) {
      let from = moment(state.startDate).format("YYYY-MM-DD");
      let to = moment(state.endDate).add(1, "days").format("YYYY-MM-DD");
      let filterQuery = "";
      if (topCustomer !== null) {
        let userType =
          props.userType === "supplier"
            ? "supplier"
            : props.subType === "captain" || props.subType === "customer"
              ? "customer"
              : "";
        let filterQuery = {};
        if (supplierId) {
          filterQuery['supplierId'] = supplierId
        }
        if (props.userType !== 'customer') {
          props.topCustomer(props.userId, userType, from, to, filterQuery);
        } else {
          props.topCustomer(state.customerVessels, userType, from, to, filterQuery);
        }

      } else {
        filterQuery = `&from=${from}&to=${to}`;
        props.customerListing(0, "customer", filterQuery);
      }
      setState({
        ...state,
        filterQuery,
        activePage: 0,
        isHitApi: true,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select both date.",
      });
    }
  };
  const clearFilters = () => {
    let filterQuery = "";
    if (topCustomer !== null) {
      let userType =
        props.userType === "supplier"
          ? "supplier"
          : props.subType === "captain" || props.subType === "customer"
            ? "customer"
            : "";
      let filterQuery = {};
      if (supplierId) {
        filterQuery['supplierId'] = supplierId
      }
      if (props.userType !== 'customer') {
        props.topCustomer(props.userId, userType, '', '', filterQuery);
      } else {
        props.topCustomer(state.customerVessels, userType, '', '', filterQuery);
      }
    } else {
      props.customerListing(0, "customer", filterQuery);
    }
    setState({
      ...state,
      startDate: null,
      endDate: null,
      filterQuery,
      activePage: 0,
      isHitApi: true,
    });
  };
  const handleModalChange = (val, userId) => {
    setState({
      ...state,
      selectedUser: userId,
      showModal: val,
    });
  };
  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
  ];
  const apiCallForCSVData = () => {
    /**
     * Requesting csv files data
     */
    csvLinkRef.current.link.click();
  };
  return (
    <Wrapper>
      <DashboardNavbar
        title={`${
          topCustomer !== null ? "Customer Overview" : "Customer Listing"
          } `}
        navbarOptions={navbarOptions}
        className="mesh-board-wrap d-flex align-items-end"
      />
      <div className="ordCatg-table-wrap mt-3 system-cat-filter">
        <div className="card-bx-sty">
          <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
            <div className="date-range-picker date-range-holder d-flex align-items-center">
              <span className="d-inline-block ml-0 datePicker customerListing form-control fa-icon field-bx-sty px-0 py-0">
                <DatePicker
                  selected={state.startDate}
                  maxDate={new Date()}
                  onChange={(val) => handleChange('startDate', val)} className="link-style"
                />
              </span>
              to
              <span className="d-inline-block mr-0 datePicker customerListing form-control fa-icon field-bx-sty px-0 py-0">
                <DatePicker
                  selected={state.endDate}
                  disabled={state.startDate === null ? true : false}
                  minDate={state.startDate !== null ? new Date(moment(state.startDate).add(1, 'days')) : new Date(moment().add(1, 'days'))}
                  onChange={(val) => handleChange('endDate', val)} className="link-style"
                />
              </span>
            </div>
            <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
              <button type="button" className="btn btn-yellow ml-1" onClick={() => applyFilters()} >Search</button>
              <button type="button" className="btn btn-yellow ml-1 mr-1" onClick={() => clearFilters()} >Clear</button>
              <CsvDownload
                data={state.csvData}
                csvLinkRef={csvLinkRef}
                filename={"Top-Customer.csv"}
                headers={headers}
                apiCallForCSVData={apiCallForCSVData}
              />
            </div>
          </div>
        </div>
      </div>
      <InfiniteScroll
        dataLength={state.customerList.length}
        next={fetchMoreData}
        height={"80vh"}
        hasMore={state.totalCount <= state.customerList.length ? false : true}
        loader={
          <div
            colSpan="12"
            className="loadmore d-flex align-items-center justify-content-center"
          >
            {" "}
            <img src={loader} alt="" />
          </div>
        }
      >
        <div className="table-responsive mt-4">
          <table className="table theme-table-wrap">
            <thead>
              <TableHeader topCustomer={topCustomer} subType={props.subType} userType={props.userType} />
            </thead>
            <tbody>
              {(props.apiCallStatus.isStarted.indexOf("getCustomer") !== -1 || props.apiCallStatus.isStarted.indexOf("topCustomer") !== -1) ? (
                <tr>
                  <td colSpan="20">
                    <div className="loader-img text-center">
                      <img style={{ width: "46px" }} src={loader} alt="" />
                    </div>
                  </td>
                </tr>
              ) : state.customerList && state.customerList.length > 0 ? (
                state.customerList.map((item, index) => {
                  return <TableContent topCustomer={topCustomer} index={index} item={item} userType={props.userType} isSubAdmin={props.isSubAdmin} roles={props.roles} removeRecord={removeRecord} statusHandler={statusHandler} handleModalChange={handleModalChange} />

                })
              ) : state.customerList && state.customerList.length === 0 ? (
                <tr>
                  <td colSpan="40" className="text-center">
                    <div>No Data Found</div>
                  </td>
                </tr>
              ) : (
                      ""
                    )}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
      <ChangePasswordModal
        ClearApiByNameAction={props.ClearApiByNameAction}
        apiCallStatus={props.apiCallStatus}
        changePassword={props.changePassword}
        selectedUser={state.selectedUser}
        showModal={state.showModal}
        handleModalChange={(val, userId) => handleModalChange(val, userId)}
      />
    </Wrapper>
  );
}
const TableHeader = React.memo(props => {
  if (props.topCustomer) {
    return <tr>
      <th scope="col">#</th>
      <th>Customer Email</th>
      <th>Type</th>
      <th>Fleet Size</th>
      <th>Turnover P-MESH</th>
      <th>Turnover C-MESH</th>
      <th>Number of Supplies</th>
      {props.userType === 'supplier' ?
        <>
          <th>Next supply date</th>
          <th>Delivery place</th>
        </>
        : ''}
    </tr>

  } else {
    return <tr>
      <th scope="col">#</th>
      <th scope="col">Email</th>
      <th scope="col">Status</th>
      <th scope="col">Actioin</th>
    </tr>
  }
})
const TableContent = React.memo(props => {
  if (props.topCustomer) {
    return <tr key={props.index}>
      <td>{props.index + 1}</td>
      <td>{props.item.email}</td>
      <td>{props.item.type}</td>
      <td>{props.item.fleetSize}</td>
      <td>{props.item.pMeshCount}</td>
      <td>{props.item.cMeshCount}</td>
      <td>{props.item.count}</td>
      {props.userType === 'supplier' ?
        <>
          <td>{moment(props.item.arrivalTime).format("DD-MM-YYYY")}</td>
          <td>{props.item.portName}</td>
        </>
        : ''}
    </tr>
  } else {
    return <tr key={props.index}>
      <td>{props.index + 1}</td>
      <td>{props.item.email}</td>
      <td>
        {props.item.isActive === true ? "Enabled" : "Disabled"}
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="Slide-checkbx">
            <input
              type="checkbox"
              value="None"
              onChange={() =>
                props.statusHandler(props.item.id, !props.item.isActive)
              }
              id={props.index}
              checked={props.item.isActive}
            />
            <label htmlFor={props.index}></label>
            <span className="indic-line"></span>
          </div>
          {props.userType === "user" && !props.isSubAdmin ? (
            <button
              className="btn btn-change-password-tab"
              onClick={() =>
                props.handleModalChange("changePassword", props.item.id)
              }
            ></button>
          ) : (
              ""
            )}
          {props.isSubAdmin &&
            props.roles.length > 0 &&
            !isAllowPermission(
              "/delete-customer",
              props.roles
            ) ? (
              ""
            ) : (
              <button
                type="button"
                onClick={() => props.removeRecord(props.item.id)}
                className="btn btn-dlt-tab"
              ></button>
            )}
        </div>
      </td>
    </tr>

  }
})
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  commonReducer: state.commonReducer,
  vesselList: state.vesselReducer.list,
  userType: state.authReducer.userType,
  userId: state.authReducer.userId,
  subType: state.authReducer.subType,
  isSubAdmin: state.authReducer.isSubAdmin,
  roles: state.authReducer.roles,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCustomerEmail: (offset, limit, type) =>
    dispatch(getCustomerEmail(offset, limit, type)),
  vesselListing: (offset, limit) => dispatch(vesselListing(offset, limit)),
  topCustomer: (userId, userType, from, to, filterQuery) => dispatch(topCustomer(userId, userType, from, to, filterQuery)),
  customerListing: (offset, type, filterQuery) =>
    dispatch(customerListing(offset, type, filterQuery)),
  updateCustomer: (id, data) => dispatch(updateCustomer(id, data)),
  deleteCustomer: (id) => dispatch(deleteCustomer(id)),
  changePassword: (data) => dispatch(changePassword(data)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
});
 export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
