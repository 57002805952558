import React, { useEffect } from 'react';
import CustomSelect from '../../../../Common/Components/CustomAutoComplete'

export function Filters(props) {
    useEffect(() => {

    }, []);
    return (
        <div className="card-bx-sty">
            <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                <div className="fil-wrap-ctn d-flex align-items-center justify-content-center order_list ml-2">
                    <label className="text-nowrap  mb-0">Ordered List
                    <div className="Slide-checkbx ml-2 justify-content-center" >
                        <input type="checkbox" value="None" id="toggleOrderList" onChange={() => props.handleOrderedList(!props.showOrderedList)} checked={props.showOrderedList} />
                        <label htmlFor="toggleOrderList"></label>
                        <span className="indic-line"></span>
                    </div>
                    </label>
                </div>
                {!props.showOrderedList ?
                    <React.Fragment><div className="fil-wrap-ctn d-flex align-items-center flex-column flex-sm-row mb-lg-0">
                        <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                            <CustomSelect pagesList={props.itemGroupList} inputId="inputItemGroup" id="itemGroup" fetchMore={props.fetchMoreItemCode} pageListTotal={props.itemGroupList.length} value={props.itemGroup} inputValue={props.inputItemGroup} placeholder="Item Group" handleChange={props.handleChange} clickCustomeSelectHandler={props.clickCustomeSelectHandler} />
                        </div>
                        <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                            <CustomSelect pagesList={props.itemNoList} inputId="inputItemNo" id="itemNo" fetchMore={props.fetchMoreItemCode} pageListTotal={props.totalItemCode} inputValue={props.inputItemNo} value={props.itemNo} placeholder="IMPA code" handleChange={props.handleChange} clickCustomeSelectHandler={props.clickCustomeSelectHandler} />
                        </div>
                        <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                            <CustomSelect pagesList={props.descriptionList} inputId="inputDescription" id="description" fetchMore={props.fetchMoreDescription} pageListTotal={props.totalDescription} value={props.description} inputValue={props.inputDescription} placeholder="Description" handleChange={props.handleChange} clickCustomeSelectHandler={props.clickCustomeSelectHandler} />
                        </div>
                    </div>
                        <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                            <button type="button" className="btn btn-yellow ml-1" onClick={() => props.clearFilters()} >Reset</button>
                        </div></React.Fragment> : ""}

            </div>
        </div>
    )
}