import React from 'react';
import { Wrapper } from '../Css/profile';
import { getDashboardLink } from '../../../Services/common';
import  DashboardNavbar  from '../../Common/Components/dashboardNavbar';
import DemoHeader from '../../Common/Components/demoHeader';
import { Filters } from "./filters";


const navbarOptions = [
    {
        img: 'Shopping-cart.svg',
        className: "dark-pink",
        name: "Order<br />basket",
        path: '/inprogress-orders',
        isLogout: false
    },
    {
        img: 'Monitor-icon.svg',
        className: "dark-blue",
        name: "Dash<br />board",
        path: getDashboardLink(),
        isLogout: false
    }
    // , {
    //     img: 'Monitor-icon.svg',
    //     className: "dark-green",
    //     name: "send<br />order",
    //     path: getDashboardLink(),
    //     isLogout: false
    // }
];

export const SupplierWrapper = (props) => (
    <Wrapper>
        <div className="pageWrapper py-4">
            <div className="d-flex justify-content-end">
                <button onClick={() => props.history.goBack()} className="pageGoBackBtn text-center">Back</button>
            </div>
            <div className="pageContainer">
                <DemoHeader />
                <div className="order-dlts-wrapper">
                    <DashboardNavbar isShowLogo={true} logoType="Order" logoUrl={"logo.png"} title={props.title ? props.title: "SUPPLIER RANKING"} navbarOptions={navbarOptions} className="pageHeader d-flex align-items-center flex-column flex-lg-row" />
                </div>
                <div className="pageContent supplierProfilePageContent">
                    <Filters handleSortChange={props.handleSortChange} handlePortChange={props.handlePortChange} selectedPortOption={props.selectedPortOption} selectedSortOption={props.selectedSortOption} portOptions={props.portOptions} sortByOptions={props.sortByOptions}
                        screenName={props.screenName}
                    />
                    {props.children}

                </div>
                {/* <span className="pageProfileBottomText text-white">*meshing = connect your vessel(s) to our worlwide network of suppliers &amp; knowledge</span> */}
            </div>
        </div>
    </Wrapper>
);