// Public Routes
import Login from "../Views/Auth/Components/login";
import WelcomeNote from "../Views/Auth/Components/customerWelcomeNote";
import LoginSupplier from "../Views/Auth/Components/loginSupplier&Admin";
import SignUp from "../Views/Auth/Components/signup";
import ForgotPassword from "../Views/Auth/Components/forgotPassword";
import ResetPassword from "../Views/Auth/Components/resetPassword";
import VerifyAccount from "../Views/Auth/Components/verifyAccount";
// Roles
import Roles from "../Views/roles/Components/roles";
import rolesListing from "../Views/roles/Components/rolesListing";
import addRole from "../Views/roles/Components/addRole";
import editRole from "../Views/roles/Components/editRole";
import subAdmins from "../Views/roles/Components/subAdmins";
import addSubAdmin from "../Views/roles/Components/addSubAdmin";
import editSubAdmin from "../Views/roles/Components/editSubAdmin";
import allQuotes from "../Views/roles/Components/allQuotes";
import createQuotes from "../Views/roles/Components/createQuotes";

import UpgradeMesh from "../Views/upgrademesh/upgrademesh";
import NotificationBox from "../Views/upgrademesh/notificationBox";
import UpgradeClient from "../Views/upgrademesh/UpgradeClient";
import SupplierRanking from "../Views/Supplier/Components/Ranking/supplierRanking";
import SupplierProfile from "../Views/Supplier/Components/Profile/supplierprofile";
import CreateStoreOrder from "../Views/Orders/Components/Create/CreateStoreOrder/createStoreOrder";
import CreateProvisionOrder from "../Views/Orders/Components/Create/CreateOrder/createOrder";
import OpenOrderList from "../Views/Orders/Components/OrderList/openOrderList";
import InProgressOrderList from "../Views/Orders/Components/OrderList/inProgressOrderList";
import inventoryListing from "../Views/Orders/Components/OrderList/inventoryListing";
import InventoryDetail from "../Views/Orders/Components/OrderList/inventoryDetail";
import OrderDetail from "../Views/Orders/Components/OrderList/orderDetail";
import InProgressQuotationList from "../Views/Quotations/Components/InProgressQuotationList/InProgressQuotationList";
import provisionCatalogue from "../Views/Orders/Components/Catalogue/ProvisionCatalogue/provisionCatalogueContainer";
import SupplierOverviewCatalogue from "../Views/Catalogues/SupplierOverviewCatalogue";
import SendRfq from "../Views/Orders/Components/Catalogue/SendRfq/sendRfq";
import SupplierOrderCrud from "../Views/Catalogues/SupplierOrderCrud";
import SupplierOrderOverview from "../Views/Catalogues/SupplierOrderOverview";
import PrepareQuotation from "../Views/Quotations/Components/PrepareQuotation/PrepareQuotation";
import CompareQuotation from "../Views/Quotations/Components/compareQuotaion/CompareQuotation";
import PrepareQuotationOrder from "../Views/Catalogues/PrepareQuotationOrder";
import ConfirmOrder from "../Views/Quotations/Components/ConfirmOrder/confirmOrder";
import marketPlace from "../Views/marketplace/marketplace";
import StoreCatalogue from "../Views/Orders/Components/Catalogue/StoreCatalogue/storeCatalogueContainer";
import Checkout from "../Views/Orders/Components/Checkout/checkoutContainer";
import MeshBoard from "../Views/Dashboard/Components/meshBoard";
import ContractOffice from "../Views/Dashboard/Components/ContractOffice";
import UserDashboard from "../Views/Dashboard/Components/UserDashboard";
import CurrentOrders from "../Views/Dashboard/Components/CurrentOrders";
import SupplierDashboard from "../Views/Dashboard/Components/SupplierDashboard.jsx";
import recentOrderOverview from "../Views/Dashboard/Components/recentOrderOverview";
import OrderDetailDescription from "../Views/Dashboard/Components/OrderDetailDescription";
import TopSupplyHub from "../Views/Dashboard/Components/TopSupplyHub";
import TopClients from "../Views/Dashboard/Components/TopClients";
import TopItems from "../Views/Dashboard/Components/TopItems";
import PriceListing from "../Views/Dashboard/Components/SupplierPriceList";
import TopSupplier from "../Views/Dashboard/Components/TopSupplier";
import ContractVessel from "../Views/Dashboard/Components/ContractVessel";
import DashboardCustomer from "../Views/Dashboard/Components/DashboardCustomer";
import DashboardVessel from "../Views/Dashboard/Components/DashboardVessel";
import CatalogueOverview from "../Views/Dashboard/Components/CatalogueOverview";
//User
import UserListing from "../Views/User/Components/userListing";
import CreateUser from "../Views/User/Components/createUser";
import EditUser from "../Views/User/Components/editUser";

//File
import UploadFile from "../Views/fileUpload/Components/fileUpload";
import SupplierCatalogueList from "../Views/fileUpload/Components/supplierCatalogue";
import EditCatalogue from "../Views/fileUpload/Components/editCatalogue";

// Captain
import CaptainListing from "../Views/Captain/Components/CaptainListing.jsx";
import CreateCaptain from "../Views/Captain/Components/createCaptain";
import EditCaptain from "../Views/Captain/Components/editCaptain";

// Port
import PortList from "../Views/Port/Components/portListing";
import CreatePort from "../Views/Port/Components/createPort";
import EditPort from "../Views/Port/Components/editPort";
// Reminder
import ReminderList from "../Views/Reminder/Components/reminderListing";
import CreateReminder from "../Views/Reminder/Components/createReminder";
import EditReminder from "../Views/Reminder/Components/editReminder";

// Package
import PackageList from "../Views/Package/Components/packageListing";
import CreatePackage from "../Views/Package/Components/createPackage";
import EditPackage from "../Views/Package/Components/editPackage";
import feedBack from "../Views/Package/Components/feedBack";
// Vessel
import VesselListing from "../Views/Vessel/Components/vesselListing";
import CreateVessel from "../Views/Vessel/Components/createVessel";

// Country
import CountryList from "../Views/Country/Components/countryListing";
import CreateCountry from "../Views/Country/Components/createCountry";
import EditCountry from "../Views/Country/Components/editCountry";

// Department
import CreateDepartment from "../Views/Department/Components/createDepartment";
import DepartmentList from "../Views/Department/Components/departmentListing";
import EditDepartment from "../Views/Department/Components/editDepartment";

// Supplier
import SupplierListing from "../Views/Supplier/Components/CRUD/supplierListing";
import CreateSupplier from "../Views/Supplier/Components/CRUD/createSupplier";
import EditSupplier from "../Views/Supplier/Components/CRUD/editSupplier";
import SupplierComparison from "../Views/Supplier/Components/Comparision/supplierComparison";
// Customer listing
import CustomerList from "../Views/Customer/Components/customerListing";

import SupplierSelection from "../Views/Orders/Components/Selection/supplierSelection";
//Mesh Market
import MarketDashboard from "../Views/meshMarket/components/MarketDashboard";
// Medicine Journey
import MedicineInventory from "../Views/customerJourney/MedicineInventory";
// Mesh Questionnarie
import VesselInfo from "../Views/Vessel/Components/vesselInfo";
import CreateQuestionnarie from "../Views/Vessel/Components/Questionaire/createQuestionnarie";
// Order Basket
import OrderBasket from "../Views/OrderBasket/components/orderBasket";

//Not Found
import Notfound from "../Views/Common/Components/notfound";
import NotAllowed from "../Views/Common/Components/notAllowed";

export const AllRoutes = [
  // Public Routes
  { isPrivate: false, path: "/", name: "", component: Login },
  { isPrivate: false, path: "/login", name: "Login", component: Login },
  {
    isPrivate: false,
    path: "/LoginSupplier",
    name: "LoginSupplier",
    component: LoginSupplier,
  },
  { isPrivate: false, path: "/signUp", name: "Sign Up", component: SignUp },
  {
    isPrivate: false,
    path: "/forgotPasword",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    isPrivate: false,
    path: "/resetPasword/:id",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    isPrivate: false,
    path: "/verifyAccount/:userId",
    name: "Verify Your Account",
    component: VerifyAccount,
  },
  /* Roles screen */
  { isPrivate: true, name: "Roles", path: "/roles", component: Roles },
  {
    isPrivate: true,
    name: "Roles Listing",
    path: "/role-listing",
    component: rolesListing,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Add Role",
    path: "/addRole",
    component: addRole,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Edit Role",
    path: "/edit-role/:id",
    component: editRole,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Sub Admins",
    path: "/subAdmins",
    component: subAdmins,
  },
  {
    isPrivate: true,
    name: "Add Sub Admin",
    path: "/add-SubAdmin",
    component: addSubAdmin,
  },
  {
    isPrivate: true,
    name: "Edit Sub Admin",
    path: "/edit-SubAdmin/:id",
    component: editSubAdmin,
  },
  {
    isPrivate: true,
    name: "Create Quotes",
    path: "/createQuotes",
    component: createQuotes,
  },
  {
    isPrivate: true,
    name: "All Quotes",
    path: "/all-quotes",
    component: allQuotes,
  },

  {
    isPrivate: false,
    path: "/welcome",
    name: "Welcome Note",
    component: WelcomeNote,
  },
  {
    isPrivate: true,
    name: "Upgrade Mesh",
    path: "/upgrademesh",
    component: UpgradeMesh,
  },
  {
    isPrivate: true,
    name: "notification Box",
    path: "/notificationBox",
    component: NotificationBox,
  },
  {
    isPrivate: true,
    name: "Upgrade Client",
    path: "/upgrade-client",
    component: UpgradeClient,
    // roles: ["supplier", "customer"],
  },
  {
    isPrivate: true,
    name: "Supplier Ranking",
    path: "/supplier-ranking",
    component: SupplierRanking,
    roles: ["user", "customer"],
  },
  {
    isPrivate: true,
    name: "Order Details",
    path: "/order-detail/:id",
    key: "orderDetail",
    component: OrderDetail,
  },
  {
    isPrivate: true,
    path: "/supplier-profile/:id",
    name: "Supplier Profile",
    component: SupplierProfile,
  },
  {
    isPrivate: true,
    path: "/create-store-order/:vesselId",
    name: "Order Store",
    component: CreateStoreOrder,
  },
  {
    isPrivate: true,
    path: "/open-orders",
    name: "Open Orders",
    key: "openOrder",
    component: OpenOrderList,
  },
  {
    isPrivate: true,
    path: "/inprogress-orders",
    name: "InProgress Orders",
    key: "inProgressOrders",
    component: InProgressOrderList,
    roles: ["user", "customer"],
  },
  {
    isPrivate: true,
    path: "/inventory-list",
    name: "Inventory List",
    key: "inventoryListing",
    component: inventoryListing,
    roles: ["user", "customer"],
  },
  {
    isPrivate: true,
    path: "/inventory-detail/:id",
    name: "Inventory Detail",
    key: "inventoryDetail",
    component: InventoryDetail,
    roles: ["user", "customer"],
  },
  {
    isPrivate: true,
    path: "/inprogress-quotations",
    key: "inprogressQuotation",
    name: "Inprogress Quotation",
    component: InProgressQuotationList,
  },
  {
    isPrivate: true,
    path: "/create-provision-order/:vesselId",
    name: "Create Provision Order",
    key: "createProvisionOrder",
    component: CreateProvisionOrder,
  },
  {
    isPrivate: true,
    path: "/provision-catalogue/:orderId",
    name: "Provision Catalogue",
    component: provisionCatalogue,
  },
  {
    isPrivate: true,
    path: "/marketPlace",
    name: "Market Place",
    component: marketPlace,
  },
  {
    isPrivate: true,
    path: "/store-catalogue/:orderId",
    name: "Store Catalogue",
    component: StoreCatalogue,
  },
  {
    isPrivate: true,
    path: "/checkout/:id",
    name: "Checkout",
    component: Checkout,
  },
  {
    isPrivate: true,
    path: "/meshBoard",
    name: "meshBoard",
    component: MeshBoard,
  },

  /* Port */
  {
    isPrivate: true,
    path: "/port-listing",
    name: "Port Listing",
    key: "portList",
    component: PortList,
  },
  {
    isPrivate: true,
    path: "/create-port",
    name: "Create Port",
    key: "addPort",
    component: CreatePort,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/edit-port/:id",
    name: "Edit Port",
    key: "editPort",
    component: EditPort,
    roles: ["user"],
  },

  /* Reminder */
  {
    isPrivate: true,
    path: "/reminder-listing",
    name: "Reminder Listing",
    key: "reminderList",
    component: ReminderList,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/create-reminder",
    name: "Create Reminder",
    key: "addReminder",
    component: CreateReminder,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/edit-reminder/:id",
    name: "Edit Reminder",
    key: "editReminder",
    component: EditReminder,
    roles: ["user"],
  },
  /* Country */
  {
    isPrivate: true,
    path: "/country-listing",
    name: "Country Listing",
    key: "countryList",
    component: CountryList,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/create-country",
    name: "Create Country",
    key: "addCountry",
    component: CreateCountry,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/edit-country/:id",
    name: "Edit Country",
    key: "editCountry",
    component: EditCountry,
    roles: ["user"],
  },

  /* Department */
  {
    isPrivate: true,
    path: "/department-listing",
    name: "Department Listing",
    key: "departmentList",
    component: DepartmentList,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/create-department",
    name: "Create Department",
    key: "addDepartment",
    component: CreateDepartment,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/edit-department/:id",
    name: "Edit Department",
    key: "editDepartment",
    component: EditDepartment,
    roles: ["user"],
  },

  /* User */
  {
    isPrivate: true,
    name: "User Listing",
    path: "/user-listing",
    key: "userList",
    component: UserListing,
  },
  {
    isPrivate: true,
    name: "Create User",
    path: "/create-user",
    key: "addUser",
    component: CreateUser,
  },
  {
    isPrivate: true,
    name: "Edit User",
    path: "/edit-user/:id",
    key: "editUser",
    component: EditUser,
  },

  /* Captain */
  {
    isPrivate: true,
    name: "Captain Listing",
    path: "/captain-listing",
    key: "captainList",
    component: CaptainListing,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Create Captain",
    path: "/create-captain",
    key: "addCaptain",
    component: CreateCaptain,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Edit Captain",
    path: "/edit-captain/:id",
    key: "editCaptain",
    component: EditCaptain,
    roles: ["user"],
  },

  /* File Upload */
  {
    isPrivate: true,
    path: "/file-upload",
    key: "fileUpload",
    component: UploadFile,
    roles: ["user", "supplier"],
  },
  {
    isPrivate: true,
    path: "/supplier-catalogue-listing",
    key: "catalogueList",
    component: SupplierCatalogueList,
    roles: ["user", "supplier"],
  },
  {
    isPrivate: true,
    path: "/edit-catalogue/:id/:type",
    key: "editCatalogue",
    component: EditCatalogue,
    roles: ["user", "supplier"],
  },

  /* Vessel */
  {
    isPrivate: true,
    name: "Vessel Listing",
    key: "vesselList",
    path: "/vessel-listing",
    component: VesselListing,
  },
  {
    isPrivate: true,
    name: "Create Vessel",
    key: "addVessel",
    path: "/create-vessel",
    component: CreateQuestionnarie,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Vessel Config",
    key: "configVessel",
    path: "/config",
    component: CreateVessel,
    roles: ["user", "customer"],
  },
  {
    isPrivate: true,
    name: "Edit Vessel",
    key: "editVessel",
    path: "/edit-vessel/:id",
    component: CreateQuestionnarie,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Vessel Info",
    key: "vesselInfo",
    path: "/vessel-info/:vesselId",
    name: "Vessel Information",
    component: VesselInfo,
    roles: ["user", "customer"],
  },

  /* Package */
  {
    isPrivate: true,
    path: "/package-listing",
    key: "packageList",
    name: "Package Listing",
    component: PackageList,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/create-package",
    key: "addPackage",
    name: "Create Package",
    component: CreatePackage,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/edit-package/:id",
    key: "editPackage",
    name: "Edit Package",
    component: EditPackage,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/feed-back",
    name: "FeedBack",
    component: feedBack,
  },
  /* Customer */
  {
    isPrivate: true,
    path: "/customer-listing",
    key: "customerList",
    component: CustomerList,
  },

  /* Supplier */
  {
    isPrivate: true,
    name: "Supplier Listing",
    key: "supplierList",
    path: "/supplier-listing",
    component: SupplierListing,
  },
  {
    isPrivate: true,
    name: "Create Supplier",
    key: "addSupplier",
    path: "/create-supplier",
    component: CreateSupplier,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Edit Supplier",
    key: "editSupplier",
    path: "/edit-supplier/:id",
    component: EditSupplier,
    roles: ["user"],
  },

  /* Dashboards */
  {
    isPrivate: true,
    path: "/contract-vessel",
    name: "ContractVessel",
    component: ContractVessel,
  },
  {
    isPrivate: true,
    path: "/dashboard-customer",
    name: "dashboardCustomer",
    component:DashboardCustomer,
  },
  {
    isPrivate: true,
    path: "/dashboard-vessel",
    name: "DashboardVessel",
    component:DashboardVessel,
  },
  {
    isPrivate: true,
    path: "/catalogue-overview",
    name: "CatalogueOverview",
    component: CatalogueOverview,
  },
  {
    isPrivate: true,
    path: "/contract-office",
    name: "ContractOffice",
    key: "contractOffice",
    component: ContractOffice,
  },
  {
    isPrivate: true,
    path: "/user-dashboard",
    name: "UserDashboard",
    key: "userDashboard",
    component: UserDashboard,
  },
  {
    isPrivate: true,
    path: "/CurrentOrders",
    name: "CurrentOrders",
    component: CurrentOrders,
  },
  {
    isPrivate: true,
    path: "/send/:type/:supplierId/:orderId",
    name: "SendRfq",
    key: "SendRfq",
    component: SendRfq,
  },
  {
    isPrivate: true,
    path: "/SupplierOrderCrud",
    name: "SupplierOrderCrud",
    component: SupplierOrderCrud,
  },
  {
    isPrivate: true,
    path: "/supplier-comparison/:basketId",
    name: "Supplier Comparison",
    component: SupplierComparison,
  },
  {
    isPrivate: true,
    path: "/supplier-order-overview",
    name: "SupplierOrderOverview",
    component: SupplierOrderOverview,
  },
  {
    isPrivate: true,
    path: "/prepare-quotation/:quotationId",
    name: "PrepareQuotation",
    component: PrepareQuotation,
  },
  {
    isPrivate: true,
    path: "/compare-quotation/:orderId",
    key: "compareQuotation",
    name: "CompareQuotation",
    component: CompareQuotation,
  },
  {
    isPrivate: true,
    path: "/PrepareQuotationOrder",
    name: "PrepareQuotationOrder",
    component: PrepareQuotationOrder,
  },
  {
    isPrivate: true,
    path: "/confirm-order/:quotationId",
    name: "ConfirmOrder",
    component: ConfirmOrder,
  },
  {
    isPrivate: true,
    path: "/supplier-dashboard",
    name: "SupplierDashboard",
    component: SupplierDashboard,
  },
  {
    isPrivate: true,
    path: "/orderOverview",
    name: "orderOverview",
    component: recentOrderOverview,
    roles: ["user", "customer", "supplier"],
  },
  {
    isPrivate: true,
    path: "/order-detail-description/:orderId/:supplierId",
    name: "OrderDetailDescription",
    component: OrderDetailDescription,
  },
  {
    isPrivate: true,
    path: "/top-supply-hub",
    name: "topSupplyHub",
    component: TopSupplyHub,
    roles: ["user", "customer"],
  },
  {
    isPrivate: true,
    path: "/top-clients",
    name: "TopClients",
    component: TopClients,
  },
  {
    isPrivate: true,
    path: "/top-items",
    name: "TopItems",
    component: TopItems,
  },
  {
    isPrivate: true,
    path: "/price-listing",
    name: "PriceListing",
    component: PriceListing,
  },
  {
    isPrivate: true,
    path: "/top-supplier",
    name: "topSupplier",
    component: TopSupplier,
  },
  {
    isPrivate: true,
    path: "/SupplierOverviewCatalogue",
    name: "SupplierOverviewCatalogue",
    component: SupplierOverviewCatalogue,
  },
  {
    isPrivate: true,
    path: "/supplier-selection/:id",
    name: "SupplierSelection",
    component: SupplierSelection,
  },

  /* Mesh Dashboard  */
  {
    isPrivate: true,
    path: "/mesh-market-dashboard",
    name: "Marker Dashboard",
    component: MarketDashboard,
  },

  /* Medicine Inventory  */
  {
    isPrivate: true,
    path: "/medicine-inventory",
    name: "Medicine Inventory",
    component: MedicineInventory,
  },
  {
    isPrivate: true,
    path: "/order-basket",
    name: "orderBasket",
    key: "orderBasket",
    component: OrderBasket,
  },
  /* Not Allowed*/
  { path: "/not-allowed", name: "Not Allowed", component: NotAllowed },
  /* Page not found */
  { isPrivate: true, component: Notfound },
];

export function getRoutePathByName(name) {
  let index = AllRoutes.findIndex((x) => x.name === name);
  let path = "";
  if (index !== -1) {
    path = AllRoutes[index].path;
  }
  return path;
}

export function getRoutePathBykey(key) {
  let index = AllRoutes.findIndex((x) => x.key === key);
  let path = "";
  if (index !== -1) {
    path = AllRoutes[index].path;
  }
  return path;
}

export function getRoutesDetail() {
  let obj = {};
  AllRoutes.forEach((element) => {
    if (element.key) {
      obj[element.key] = element.path;
    }
  });
  return obj;
}
