import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Sidebar  from "../../Common/Components/sidebar";
import './dashboard-style.css';
import $ from "jquery";
import { getDashboardLink } from '../../../Services/common';

const options = [
    { value: 'Provisions', label: 'Provisions' },
    { value: 'Deck', label: 'Deck' },
    { value: 'Engine', label: 'Engine' },
    { value: 'Galley / ', label: 'Galley / ' },
    { value: 'laundry', label: 'laundry' },
    { value: 'Electrical', label: 'Electrical' },
    { value: 'Stationary', label: 'Stationary' },
];

export default class CurrentOrders extends Component {
    state = {
        selectedOption: null,
    };
    componentDidMount() {
        $('.sidebar-toggle').click(function () {
            $('.dash-menubar').toggleClass('show')
        })
        $('.dropdown').on('show.bs.dropdown', function (e) {
            $(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
        });

        $('.dropdown').on('hide.bs.dropdown', function (e) {
            $(this).find('.dropdown-menu').first().stop(true, true).slideUp(300);
        });
    }
    handleChange = selectedOption => {
        this.setState({ selectedOption });
    };

    render() {
        const { selectedOption } = this.state;
        return (
            <div className="dash-content-ctn d-flex">
                {/* Slide Bar Content */}
                <Sidebar />
                {/* Body Content */}
                <div className="dash-page-content">
                    <div className="dash-pg-inn-wrap">
                        <div className="mesh-board-wrap d-flex align-items-end">
                            <h1 className="btn-sty-title flex-fill">Current Orders</h1>
                            <div className="mesh-control-btns d-flex align-items-center">
                                <Link to={getDashboardLink()}><button type="button" className="btn btn-polygon-sty green">
                                    <img className="img-fluid" src={require('../../../assets/images/left-arrow.svg')} alt="icon" />
                                    <span>Go back</span>
                                </button></Link>
                                <button type="button" className="btn btn-polygon-sty dark-blue">
                                    <img className="img-fluid" src={require('../../../assets/images/Shopping-cart.svg')} alt="icon" />
                                    <span>New<br />order</span>
                                </button>
                                <button type="button" className="btn btn-polygon-sty orange">
                                    <img className="img-fluid" src={require('../../../assets/images/Dashboard-icon.svg')} alt="icon" />
                                    <span>upgrade</span>
                                </button>
                            </div>
                        </div>
                        {/* Order Status Content */}
                        <div className="row mt-lg-3 align-content-stretch newDash-contentStyle">
                            <div className="col-12">
                                <div className="content-box-wrap dash-content-bx  h-70v">
                                    <div className="status-wrap-head content-box-head d-flex align-items-center flex-nowrap overflow-auto">
                                        {/* <div className="flex-fill"></div> */}
                                        <h2>Current Orders</h2>
                                        <h2><span className="sta-indicator pend"></span><span className="sta-text-wrap text-truncate">Pending</span></h2>
                                        <h2><span className="sta-indicator appr"></span><span className="sta-text-wrap text-truncate">Confirmed</span></h2>
                                        <h2><span className="sta-indicator past"></span><span className="sta-text-wrap text-truncate">Past orders</span></h2>
                                    </div>
                                    <div className="flex-fill dash-ord-content ordContent-table cust-turnover text-left">
                                        <div className="table-responsive">
                                            <table className="table mt-4 table-light1">
                                                <tbody>
                                                    <tr>
                                                        <td className="semi-bold">Provisions</td>
                                                        <td>10th Sept. 2020</td>
                                                        <td>Hamburg</td>
                                                        <td>3.952,78 EUR</td>
                                                        <td><span className="d-flex align-items-center justify-content-center">
                                                            <span className="sta-indicator pend"></span>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="semi-bold">Provisions</td>
                                                        <td>10th Sept. 2020</td>
                                                        <td>Hamburg</td>
                                                        <td>3.952,78 EUR</td>
                                                        <td><span className="d-flex align-items-center justify-content-center"><span className="sta-indicator appr"></span></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="semi-bold">Provisions</td>
                                                        <td>10th Sept. 2020</td>
                                                        <td>Hamburg</td>
                                                        <td>3.952,78 EUR</td>
                                                        <td><span className="d-flex align-items-center justify-content-center"><span className="sta-indicator past"></span></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/* Buttons Wrap */}
                                <div className="crnt-orders-btn-wrap d-flex align-items-center justify-content-end mt-4 small-btns">
                                    <button type="button" className="btn themeReguler-btn bg-sky-blue">Print list</button>
                                    <button type="button" className="btn themeReguler-btn bg-sky-blue">Share via email</button>
                                    <button type="button" className="btn themeReguler-btn bg-sky-blue">Download as xls-file</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
                </div>
            </div>
        )
    }
}
