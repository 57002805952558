import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './dashboard-style.css';
import { VectorMap } from "react-jvectormap";

export default class meshBoard extends Component {

    render() {
        return (
            <div className="pageWrapper meshBoard-main-wrap">
                <div className="dash-content-ctn d-flex mesh-board-wrap">
                    <div className="dash-page-content">
                        <div className="dash-pg-inn-wrap">
                            <div className="order-dlts-wrapper">
                                <div className="pageHeader d-flex align-items-center flex-column flex-lg-row">
                                    <div className="d-flex flex-fill align-items-center mesh-logoWrap flex-column flex-sm-row ">
                                        <div className="logoHolder mr-sm-4 text-sm-left text-center">
                                            <img className="img-fluid" src={require('../../../assets/images/logo.png')} alt="Logo" />
                                        </div>
                                        <h1 className="btn-sty-title flex-fill my-3 my-md-0">SUPPLY MESH*</h1>
                                    </div>
                                    <div className="mesh-control-btns d-flex align-items-center">
                                        <button type="button" className="btn btn-polygon-sty dark-pink">
                                            <img className="img-fluid" src={require('../../../assets/images/left-arrow.svg')} alt="icon"/>
                                            <span>Go back</span>
                                        </button>
                                        <button type="button" className="btn btn-polygon-sty dark-blue">
                                            <img className="img-fluid" src={require('../../../assets/images/Monitor-icon.svg')} alt="icon"/>
                                            <span>evaluation</span>
                                        </button>
                                        <button type="button" className="btn btn-polygon-sty green">
                                            <img className="img-fluid" src={require('../../../assets/images/Dashboard-icon.svg')} alt="icon"/>
                                            <span>upgrade</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Mesh Board Map */}
                            <div className="content-box-wrap dash-content-bx">
                                <div className="row">
                                    <div className="col-sm-8">
                                        <div className="content-box-head d-flex align-items-center justify-content-between">
                                            <h2 className="font-weight-bold">Region</h2>
                                        </div>
                                        <div className="dash-map-content-wrap meshBoard-map">
                                            <div className="map-bx-wrap">
                                                <VectorMap map={'us_aea'}
                                                    backgroundColor="#3b96ce"
                                                    ref="map"
                                                    containerStyle={{
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                    containerClassName="map"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 mt-3 mt-sm-0">
                                        <div className="content-box-head d-flex align-items-center justify-content-between">
                                            <h2 className="font-weight-bold">Ports</h2>
                                        </div>
                                        <div className="dash-map-content-wrap">
                                            <ul className="list-inline p-0 mesh-boardPort-list">
                                                <li><button type="button" className="btn btn-links">Hamburg</button></li>
                                                <li><button type="button" className="btn btn-links">Kiel</button></li>
                                                <li><button type="button" className="btn btn-links">Bremen</button></li>
                                                <li><button type="button" className="btn btn-links">Bremerhaven</button></li>
                                                <li><button type="button" className="btn btn-links">Rotterdam</button></li>
                                                <li><button type="button" className="btn btn-links">Antwerp</button></li>
                                                <li><button type="button" className="btn btn-links"></button></li>
                                                <li><button type="button" className="btn btn-links"></button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
                    </div>
                </div>
            </div>
        )
    }
}
