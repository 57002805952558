import React, { useEffect, useState } from "react";
import Routes from "./routes";
import Loader from "./Views/Common/Components/Loader";
// import { io } from 'socket.io-client';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "../src/assets/js/custom-style";
import "./App.css";

export default function App(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    window.addEventListener("load", function (params) {
      if (!isLoaded) {
        setIsLoaded(true);
      }
    });
  }, []);
  return (
    <React.Fragment>
      {!isLoaded ? <Loader /> : ""}
      <Routes />
    </React.Fragment>
  );
}
