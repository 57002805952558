import React from 'react';
import loader from '../../../../../assets/images/Spinner-1s-200px.gif';
import InfiniteScroll from "react-infinite-scroll-component";
import { getCountryPrice } from '../../../../../Services/common';

export function CatalougeTable(props) {
    const getMoreData = () => {
        props.fetchMoreData();
    };
    return (
        <div className="ordCatg-InnTable">
            <InfiniteScroll
                dataLength={props.data.length}
                next={getMoreData}
                height={"80vh"}
                hasMore={props.totalCount <= props.data.length ? false : true}
                loader={props.apiCallStatus.isStarted.indexOf("appendProvisionList") !== -1 ? <div colSpan="40" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div> : ""}
            >
                <table className="table fixed-head">
                    <thead>
                        <TableHead />
                    </thead>
                    <tbody>
                        {props.apiCallStatus.isStarted.indexOf("getProvision") !== -1 ? <tr><td colSpan="40" className="text-center"><div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div></td></tr> : props.data.length === 0 ? <tr><td colSpan="40" className="text-center"><div className="white-clr noData-found mb-2">No Data Found</div></td></tr> : props.data.map((item, index) => {
                            let { price } = getCountryPrice(item.pricePerCountry, props.country);
                            return <TableRow {...item} data={item} key={index} handleTableChange={props.handleTableChange} order={props.orderDetail[item.itemCode] ? props.orderDetail[item.itemCode].order : ''} remarkVal={props.orderDetail[item.itemCode] ? props.orderDetail[item.itemCode].remark : ''} price={price} />
                        })}
                    </tbody>
                </table>
            </InfiniteScroll>
        </div>)
}

function TableHead(props) {
    return <tr>
        <th>Product group</th>
        <th className="text-center">IMPA code.</th>
        <th>Description</th>
        <th>Remarks</th>
        <th><span className="bg-rounded white text-center">Order</span></th>
        <th className="text-center">STD. unit</th>
        <th className="text-center">Price/unit</th>
        <th className="text-center">Price total</th>
        <th><span className="bg-rounded white d-inline-block w-100 text-center">Remark</span></th>
    </tr>
}

function TableRow(props) {
    return <tr>
        <td><span className="td-wrap-sty border-left-none text-left">{props.orderType}</span></td>
        <td><span className="td-wrap-sty">{props.alternativeCode}</span></td>
        <td><span className="td-wrap-sty text-left">{props.description}</span></td>
        <td><span className="td-wrap-sty">{props.explanation}</span></td>
        <td><input className="whi-round" type="number" onChange={(e) => props.handleTableChange(props.itemCode, 'order', e.target.value, props.category, props.data, { energy: props.energy, itemCode: props.itemCode, category: props.category, pricePerBaseUnit: props.price, category: props.category, standardPackagingUnit: props.standardPackagingUnit, description: props.description, explanation: props.explanation, protein: props.protein, carbohydrates: props.carbohydrates, fat: props.fat })} value={props.order} /></td>
        <td className="small-unit40"><span className="td-wrap-sty">{props.pricePerPackagingUnit}</span></td>
        <td><span className="td-wrap-sty text-right">{props.price === 0 || props.price === '0' ? 'N/A' : props.price}</span></td>
        <td><span className="td-wrap-sty text-right">{props.order === 0 || props.order === '0' ? 0 : (parseFloat(props.price) * props.order).toFixed(2)}</span></td>
        <td className="small-unit90"><input className="whi-round text-left" type="text" onChange={(e) => props.handleTableChange(props.itemCode, 'remark', e.target.value, props.category, props.data, { energy: props.energy, itemCode: props.itemCode, category: props.category, pricePerBaseUnit: props.price, category: props.category, standardPackagingUnit: props.standardPackagingUnit, description: props.description, explanation: props.explanation, protein: props.protein, carbohydrates: props.carbohydrates, fat: props.fat })} value={props.remarkVal} /></td>
    </tr>
}
