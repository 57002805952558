import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetAllCaptainAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function getAllCaptain(offset, limit, type) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getAllCaptain" }));
        let myJson = await FETCH("GET", Constant.apiURl + `/users/${offset}/${limit}?type=${type}`);
        if (myJson && myJson.code === 201) {
            dispatch(GetAllCaptainAction(myJson.data))
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getAllCaptain",
                    message: myJson.message,
                }))
        }
        else {
            dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getAllCaptain", message: myJson.message }))
        }
    };
}