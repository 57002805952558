import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetAllCountryAction, GetCurrencyCodeAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function getAllCountry(query) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getAllCountry" }));
        let url = Constant.apiURl + "/country";
        if (query && query !== "") {
            url += query;
        }
        let myJson = await FETCH("GET", url);
        if (myJson && myJson.code === 201) {
            dispatch(GetAllCountryAction(myJson.data))
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getAllCountry",
                    message: myJson.message,
                }))
        }
        else {
            dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getAllCountry", message: myJson.message }))
        }
    };
}
export function getCurrencyCode() {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCurrencyCode" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/filter/currency/code/0/200");
        if (myJson && myJson.code === 201) {
            dispatch(GetCurrencyCodeAction(myJson.data))
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getCurrencyCode",
                    message: myJson.message,
                }))
        }
        else {
            dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getCurrencyCode", message: myJson.message }))
        }
    };
}