export const getDepartment = "GET_DEPARTMENT";
export const getDepartmentDetail = "GET_DEPARTMENT_DETAIL";
export const AppendDepartmentList = "APPEND_DEPARTMENT_LIST";
export function GetDepartmentAction(list, count) {
    return {
        type: getDepartment,
        payload: { list, count },
    };
}
export function AppendDepartmentAction(list) {
    return {
        type: AppendDepartmentList,
        payload: { list },
    };
}
export function GetDepartmentDetailAction(detail) {
    return {
        type: getDepartmentDetail,
        payload: { detail },
    };
}