import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ClearApiByNameAction } from "../../../ApiCallStatus/Actions/action";
import { Wrapper } from '../../../Common/Components/CommonWrapper';
import DashboardNavbar from '../../../Common/Components/dashboardNavbar';
import { getOrderDetail } from './../../ApiCalls/provisionOrder';
import loader from '../../../../assets/images/Spinner-1s-200px.gif';
import { getCountryPrice } from '../../../../Services/common';


function InventoryDetail(props) {
    const [state, setState] = useState({
        isHitApi: false,
        orderedItems: [],
    });
    useEffect(() => {
        if (!state.isHitApi) {
            props.getOrderDetail(props.match.params.id);
            let data = { ...state };
            data.isHitApi = true;
            setState(data);
        }
        if (props.apiCallStatus.apiCallFor === "getOrderDetail" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                orderedItems: props.currentOrder.orderDetails ? props.currentOrder.orderDetails : [],
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
    });
    const getNavbarOptions = () => {
        let options = [
            {
                img: 'left-arrow.svg',
                className: "orange",
                name: "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.history.goBack()
            }
        ]
        return options;
    }
    return (
        <Wrapper>
            <DashboardNavbar title="Inventory Detail" navbarOptions={getNavbarOptions()} className="mesh-board-wrap d-flex align-items-end" />
            <div className="ordCatg-table-wrap mt-3 sup-rfq-wrap">

                {/* Table */}
                <div className="ordCatg-InnTable store-catgTable">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-left">Item No.</th>
                                    <th className="text-left">Item group</th>
                                    <th className="text-left">Description</th>
                                    <th className="text-left">Quantity</th>
                                    <th className="text-left">STD. unit</th>
                                    <th className="text-left">Packaging Size</th>
                                    <th className="text-left">Price Per Unit</th>
                                    <th className="text-left">Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.apiCallStatus.isStarted.indexOf("getOrderDetail") !== -1 ?
                                    <tr><td colSpan="20">
                                        <div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={loader} alt='' />
                                        </div>
                                    </td></tr>
                                    : state.orderedItems && state.orderedItems.length > 0 ? state.orderedItems.map((item, index) => {
                                        let { price, packageUnit, orKg } = getCountryPrice(item.pricePerCountry, props.country);
                                        return <tr key={index}>
                                            <td><span className="text-center td-wrap-sty">{item.itemCode}</span></td>
                                            <td><span className="td-wrap-sty text-center">{item.category}</span></td>
                                            <td><span className="text-left td-wrap-sty">{item.description}</span></td>
                                            <td><span className="text-center td-wrap-sty">{item.inventory}</span></td>
                                            <td><span className="td-wrap-sty text-center">{item.standardPackagingUnit}</span></td>
                                            <td><span className="td-wrap-sty text-center">{orKg}</span></td>
                                            <td><span className="td-wrap-sty text-right">{price.toFixed(2)}</span></td>
                                            <td><span className="td-wrap-sty text-right">{(item.inventory * price).toFixed(2)}</span></td>
                                        </tr>

                                    }) : state.orderedItems && state.orderedItems.length === 0 ?
                                            <tr><td colSpan="40" className="text-center">No Data Found</td></tr> : ''}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    quotationReducer: state.quotationReducer,
    rfqList: state.orderReducer.rfqList,
    currentOrder: state.orderReducer.currentOrder,
    orderStatus: state.orderReducer.orderStatus,
    userId: state.authReducer.userId,
    userType: state.authReducer.userType
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getOrderDetail: (orderId) => dispatch(getOrderDetail(orderId)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryDetail);