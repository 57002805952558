import React, { Component } from 'react';
import { connect } from 'react-redux';
import { packageListing } from "./../Package/ApiCalls/package";
import { ApiClearAction } from "./../ApiCallStatus/Actions/action";
import { getCookie } from "../../Services/cookies";
import DemoHeader from '../Common/Components/demoHeader';
import './upgrademesh.css';

class UpgradeClient extends Component {
	constructor(props) {
		super(props)
		this.state = {
			Basic: {},
			Comfort: {},
			Premium: {},
		}
	}
	componentDidMount() {
		this.props.packageListing(0);
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.apiCallStatus.isStarted.indexOf('getPackage') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getPackage') === -1) {
			let Basic = this.props.packageReducer.list.filter(x => x.name === 'Basic')
			let Comfort = this.props.packageReducer.list.filter(x => x.name === 'Comfort')
			let Premium = this.props.packageReducer.list.filter(x => x.name === 'Premium')
			this.setState({
				...this.state,
				Basic,
				Comfort,
				Premium
			})
		}
	}
	render() {
		return (
			<div className="pageWrapper">
				<div className="pageContainer upgrade-cli-par-cnt">
					<DemoHeader />
					<div className="order-dlts-wrapper">
						<div className="pageHeader d-flex align-items-center flex-column flex-lg-row">
							<div className="d-flex flex-fill align-items-center mesh-logoWrap flex-column flex-sm-row ">
								<div className="logoHolder mr-sm-4 text-sm-left text-center">
									<img className="img-fluid" src={require('../../assets/images/logo.png')} alt="Logo" />
								</div>
								<div className="pageHeader-details-wrap flex-fill">
									<p className="white-clr text-center mb-0">Dear {getCookie('email')}<br />welcome to your MESH!<br />Please choose below your package and go ahead with your order.<br />Thank you very much for choosing us. Have a safe trip ahead!</p>
								</div>
							</div>
						</div>
					</div>
					<div className="pageContent">
						<div className="d-flex meshPackageTable upgradeCli-optns-list">
							<ul className="list-inline pl-0 d-flex align-items-center mb-0 cli-optn-list justify-content-end w-100">
								<li><span className="font-weight-bold dark-blue-clr fz-16">click to book:</span></li>
								<li><span className="btn mesh-btn-brown">Basic Mesh</span></li>
								<li><span className="btn mesh-btn-grey">Comfort Mesh</span></li>
								<li><span className="btn mesh-btn-yellow">Premium Mesh</span></li>
							</ul>
						</div>
						<div className="tableContent upgradeClient-table">
							<div className="table-responsive">
								<table className="table meshPackageTable">
									<thead>
										<tr>
											<th className="text-right title-th-wrap dark-green-clr">
												<span className="table-textWrap">Monthly fee<br />Commision per order</span>
											</th>
											<th>
												<span className="btn-sm-rounded bg-dark-green white-clr mb-1 d-inline-block">free</span>
												<br />
												<span className="btn-sm-rounded bg-dark-green white-clr d-inline-block">19,95 EUR</span>
											</th>
											<th>
												<span className="btn-sm-rounded bg-dark-green white-clr mb-1 d-inline-block">59,95 EUR</span>
												<br />
												<span className="btn-sm-rounded bg-dark-green white-clr d-inline-block">2,5 %</span>
											</th>
											<th>
												<span className="btn-sm-rounded bg-dark-green white-clr mb-1 d-inline-block">59,95 EUR</span>
												<br />
												<span className="btn-sm-rounded bg-dark-green white-clr d-inline-block">7,5 %</span>
											</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<div className="d-flex justify-content-start">
													<span className="btn-sm-rounded bg-dark-blue white-clr d-flex align-items-center cli-up-title">Order Form</span>
													<div className="upg-points-wrap flex-fill d-flex justify-content-start flex-column ml-2">
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Provision - incl. consumption & nutrition</span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Stores  - incl. budget overview</span>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Provision - incl. consumption & nutrition" && x.type === 'orderForm') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Stores - incl. budget overview" && x.type === 'orderForm') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Provision - incl. consumption & nutrition" && x.type === 'orderForm') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Stores - incl. budget overview" && x.type === 'orderForm') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Provision - incl. consumption & nutrition" && x.type === 'orderForm') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Stores - incl. budget overview" && x.type === 'orderForm') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>
											</td>
											<td>&nbsp;</td>
										</tr>
										<tr>
											<td>
												<div className="d-flex justify-content-start">
													<span className="btn-sm-rounded bg-dark-blue white-clr d-flex align-items-center cli-up-title">Service</span>
													<div className="upg-points-wrap flex-fill d-flex justify-content-start flex-column ml-2">
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Reliable global supplier network</span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Sustainable vonder management </span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Fixed prices in all main supply ports </span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Automated price comparsion </span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Supply data transfer into ERP </span>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Reliable global supplier network" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Sustainable vonder management" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Fixed prices in all main supply ports" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Automated price comparsion" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Supply data transfer into ERP" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Reliable global supplier network" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Sustainable vonder management" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Fixed prices in all main supply ports" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Automated price comparsion" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Supply data transfer into ERP" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Reliable global supplier network" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Sustainable vonder management" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Fixed prices in all main supply ports" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Automated price comparsion" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Supply data transfer into ERP" && x.type === 'service') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>
											</td>
											<td>&nbsp;</td>
										</tr>
										<tr>
											<td>
												<div className="d-flex justify-content-start">
													<span className="btn-sm-rounded bg-dark-blue white-clr d-flex align-items-center cli-up-title">Dashboard</span>
													<div className="upg-points-wrap flex-fill d-flex justify-content-start flex-column ml-2">
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Order history during membership period</span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Supplier ranking per port </span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Evaluation of actual rate and consumption </span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Evaluation of budget per IMPA stores dept. </span>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Order history during membership period" && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Supplier ranking per port" && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Evaluation of actual rate and consumption" && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Evaluation of budget per IMPA stores dept." && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>

											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Order history during membership period" && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Supplier ranking per port" && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Evaluation of actual rate and consumption" && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Evaluation of budget per IMPA stores dept." && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Order history during membership period" && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Supplier ranking per port" && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Evaluation of actual rate and consumption" && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Evaluation of budget per IMPA stores dept." && x.type === 'dashboard') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>
											</td>
											<td>&nbsp;</td>
										</tr>
										<tr>
											<td>
												<div className="d-flex justify-content-start">
													<span className="btn-sm-rounded bg-dark-blue white-clr d-flex align-items-center cli-up-title">Claim Management</span>
													<div className="upg-points-wrap flex-fill d-flex justify-content-start flex-column ml-2">
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Coordination of supplies | incl. back order</span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Order proposal convenient ports</span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Quaterly reports per fleet & vessel</span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Frequent on-board audits</span>
														<span className="btn-sm-rounded bg-dark-blue white-clr d-inline-block">Electronic Invoice Management</span>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Coordination of supplies | incl. back order" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Order proposal convenient ports" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Quaterly reports per fleet & vessel" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Frequent on-board audits" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Basic.length > 0 ?
														this.state.Basic[0].features.findIndex(x => x.title === "Electronic Invoice Management" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Coordination of supplies | incl. back order" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Order proposal convenient ports" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Quaterly reports per fleet & vessel" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Frequent on-board audits" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Comfort.length > 0 ?
														this.state.Comfort[0].features.findIndex(x => x.title === "Electronic Invoice Management" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>
											</td>
											<td>
												<div className="d-flex align-items-center flex-column cli-up-checks">
													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Coordination of supplies | incl. back order" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Order proposal convenient ports" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Quaterly reports per fleet & vessel" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}

													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Frequent on-board audits" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
													{this.state.Premium.length > 0 ?
														this.state.Premium[0].features.findIndex(x => x.title === "Electronic Invoice Management" && x.type === 'claimManagement') !== -1 ? <span className="d-flex align-items-center">
															<img src={require('../../assets/images/correct2.svg')} alt="Icon" />
														</span> : ''
														: ''
													}
												</div>
											</td>
											<td>&nbsp;</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = (state, ownProps) => ({
	apiCallStatus: state.apicallStatusReducer,
	packageReducer: state.packageReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	packageListing: (offset) => dispatch(packageListing(offset)),
	ApiClearAction: () => dispatch(ApiClearAction()),
})
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UpgradeClient);