import React from 'react';
import { getDashboardLink } from '../../Services/common';
import './notificationBox.css';

function NotificationBox() {
	return (
		<div className="pageWrapper">
			<div className="pageContainer">
				<div className="order-dlts-wrapper">
					<div className="pageHeader d-flex align-items-center flex-column flex-lg-row">
						<div className="d-flex flex-fill align-items-center mesh-logoWrap flex-column flex-sm-row ">
							<div className="logoHolder mr-sm-4 text-sm-left text-center">
								<img className="img-fluid" src={require('../../assets/images/logo.png')} alt="Logo" />
							</div>
							<h1 className="btn-sty-title flex-fill my-3 my-lg-0 ">Check Your notifications*
                     
							<div class="dropdown bell-notifty">
                                <a id="dLabel" role="button" data-toggle="dropdown" data-target="#" href="/page.html">
                                    <span className="bell-notifty"><i class="fa fa-bell" aria-hidden="true"></i><span className="contact-note"><h5>8+</h5></span></span>
                                </a>
                               <ul class="dropdown-menu notifications" role="menu" aria-labelledby="dLabel">
                                  <div class="notification-heading"><h4 className="btn-sty-title mr-0 text-left">Notifications</h4>
                             </div>
                           <li class="divider"></li>
                              <div class="notifications-wrapper justify-content-center">
                                 <a class="content" href="#">
                                    <div class="notification-item ">
									{/* <div className="d-flex"> */}
									<span className="dfdjfd">A</span>
                                       <h4 class="item-title">Evaluation Deadline 1 · day ago<br/>
									   <span className="date-note mt-1">2-4-2021</span>
									   </h4>
									  
									   <span className="indicator text-right ml-3"></span>
									   {/* </div>. */}
									   {/* <div class=""> */}
									   
                                       <a href="https://www.w3schools.com/"></a>
									   {/* </div> */}
                                   </div>
                                </a>
                               <a class="content" href="#">
                                  <div class="notification-item d-flex">
								  <span className="dfdjfd flex-fill d-flex">B</span>
								  <h4 class="item-title ">Evaluation Deadline 1 · day ago<br/>
                                      <span className="date-note mt-1">2-4-2021</span>
                                  </h4>
									  <span className="indicator text-right ml-3"></span>
                                      <a href="https://www.w3schools.com/"></a>
                                 </div>
                              </a>
                             <a class="content" href="#">
                               <div class="notification-item">
							   <span className="dfdjfd">C</span>
							   <h4 class="item-title d-flex flex-column"> Evaluation Evaluation Deadlinegdfgdd 1 · day ago<br/>
                                   <span className="date-note mt-1">2-4-2021</span>
                               </h4>
								  <span className="indicator text-right ml-3"></span>
                                  <a href="https://www.w3schools.com/"></a>
                              </div>
                            </a>
                           <a class="content" href="#">
                             <div class="notification-item">
							 <span className="dfdjfd">D</span>
                             <h4 class="item-title">Evaluation Evaluation Deadline 1 · day ago<br/>
                               <span className="date-note mt-1">2-4-2021</span>
                             </h4>
							   <span className="indicator text-right ml-3"></span>
                               <a href="https://www.w3schools.com/"></a>
                            </div>
                          </a>
                          <a class="content" href="#">
                             <div class="notification-item ">
								
							 <span className="dfdjfd">D</span>
							 <h4 class="item-title">Evaluation Evaluation Deadline 1 · day ago<br/>
                               <span className="date-note mt-1">2-4-2021</span>
                               </h4>
								<span className="indicator text-right ml-3"></span>
                            </div>
                          </a>
						  <a class="content" href="#">
                                <div class="notification-item ">
	                                <span className="dfdjfd">D</span>
	                                   <h4 class="item-title">Evaluation Evaluation Deadline 1 · day ago<br/>
                                          <span className="date-note mt-1">2-4-2021</span>
                                       </h4>
		                           <span className="indicator text-right ml-3"></span>
                               </div>
                           </a>
						    {/* <div className="notification-footer"> */}
                                {/* <h4 className="btn-sty-title mr-0 text-left">View all</h4></div> */}
                          <a class="content" href="#">
                            <div class="notification-item">
							<span className="dfdjfd flex-fill">D</span>
							<h4 class="item-title">Evaluation Deadline 1 Evaluation · day ago<br/>
                          <span className="date-note mt-1">2-4-2021</span><br/>
						  <a href="https://www.w3schools.com/">w3schools</a>
                           </h4>
							  <span className="indicator text-right ml-3"></span>
							  {/* <div className="d-flex"> */}
                             
                           </div>
                           <div className="loader-img text-center">
                              {/* <img style={{ width: "46px"}} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' /> */}
                           </div>
						   </a>
                          <li class="divider"></li>
                         <div class="notification-footer">
                            {/* <h4><a href="http://localhost:3000/notificationBOX" target="_blank" className="btn-sty-title mr-0 text-left">View all</a></h4> */}
						</div>
                        
                       </div>
					   </ul>
						</div>
						</h1>						<div className="mesh-control-btns d-flex align-items-center">
							<a href={getDashboardLink()}><button type="button" className="btn btn-polygon-sty dark-pink">
								<img className="img-fluid" src={require('../../assets/images/left-arrow.svg')} alt="icon" />
								<span>Go back</span>
							</button></a>
							<a href={getDashboardLink()}><button type="button" className="btn btn-polygon-sty dark-blue">
								<img className="img-fluid" src={require('../../assets/images/Monitor-icon.svg')} alt="icon" />
								<span>Dash<br />board</span>
							</button></a>
							<button type="button" className="btn btn-polygon-sty dark-green">
								<img className="img-fluid" src={require('../../assets/images/Cancel.svg')} alt="icon" />
								<span>check<br />out</span>
							</button>
						</div>
					</div>
				</div>
				<div class="flex-fill dash-ord-content ordContent-table cust-turnover text-left">
                    <div class="table-responsive">
                        <table class="table mt-4 table-light1 orderbasket-align-table">
                            <thead>
                                <tr>
                                    <td class="semi-bold ">Department</td>
                                    <td class="semi-bold ">Order Created</td>
                                    <td class=" semi-bold ">Place of delivery</td>
                                    <td class=" semi-bold ">Order Value</td>
                                    <td class="text-center semi-bold ">Order Status</td>
                                    <td class="semi-bold ">Responsible</td>
                               </tr>
                        </thead>
                    <tbody>
                        <tr>
                           <td >Main Order</td>
                           <td></td>
                           <td></td>
                           <td></td>
                           <td> </td>
                           <td>Cook</td>
                        </tr>
                    </tbody>
                 </table>
              </div>
                            
             </div>
			</div>
		</div>
		</div>
	);
}

export default NotificationBox;