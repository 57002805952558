export const getQuotationDetail = "GET_QUOTATION_DETAIL";
export const getQuotation = "GET_QUOTATION";
export const AppendQuotationList = "APPEND_QUOTATION_LIST";

export function GetQuotationAction(list, count) {
  return {
    type: getQuotation,
    payload: { list, count },
  };
}
export function AppendQuotationAction(list) {
  return {
    type: AppendQuotationList,
    payload: { list },
  };
}
export function getQuotationDetailAction(list, basketId,orderDetail) {
  return {
    type: getQuotationDetail,
    payload: { list, basketId,orderDetail },
  };
}
