export const getOrderBasket = "GET_ORDER_BASKET";
export const updateIgnoreDepart = "UPDATE_IGNORE_DEPART";

export function basketOrdersAction(
  list,
  provisionBasketId,
  storeBasketId,
  provisionSupplier,
  storeSupplier,
  ignoreDepart,
  storeArrivalTime,
  provisionArrivalTime
) {
  return {
    type: getOrderBasket,
    payload: {
      list,
      provisionBasketId,
      storeBasketId,
      provisionSupplier,
      storeSupplier,
      ignoreDepart,
      storeArrivalTime,
      provisionArrivalTime
    },
  };
}

export function updateIgnoreDepartAction(data) {
  return {
    type: updateIgnoreDepart,
    payload: data,
  };
}
