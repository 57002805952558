import React, { Component } from 'react';
import { Wrapper,DashboardNavbar } from '../../Common/Components/index';
import { getCookie } from './../../../Services/cookies';
import Select from 'react-select';
import { FETCH } from "../../../Services/fetch";
import { Constant } from "./../../../Constants/constant";
import Swal from 'sweetalert2'

class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            missedCatalogueData: [],
            type: null,
            typeOption: [{ name: 'Provision Catalogue', label: 'Provision Catalogue' },
            { name: 'Store Catalogue', label: 'Store Catalogue' }],
            navbarOptions: [
                {
                    img: 'left-arrow.svg',
                    className: "orange",
                    name: "Go back",
                    path: "/",
                    isLogout: false,
                    isCustom: true,
                    onClick: () => props.history.goBack()
                }
            ],
            isLoading: false
        }
    }
    onChangeHandler = event => {
        if (event.target !== undefined) {
            this.setState({
                selectedFile: event.target.files[0],
            })
        } else {
            this.setState({
                type: event,
            })
        }

    }

    uploadExcelFile = async (event) => {
        event.preventDefault();
        let th = this;
        if (this.state.type === null) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please Select Catalogue Type',
            })
        }
        else if (this.state.selectedFile) {
            this.setState({
                isLoading: true
            }, async () => {
                var formData = new FormData();
                formData.append("file", th.state.selectedFile);
                formData.append("userId", getCookie('userId'));
                formData.append("type", th.state.type.name === 'Provision Catalogue' ? 'provision' : 'store');
                let url = '';
                let userType = getCookie('userType');
                let msg = 'The catalogue has been uploaded successfully.';
                if (userType === 'supplier') {
                    url = Constant.apiURl + "/users/upload";
                    msg = "Your catalogue has been uploaded successfully";
                }
                else if (th.state.type.name === 'Provision Catalogue') {
                    url = Constant.apiURl + "/users/system/catalog"
                } else {
                    url = Constant.apiURl + "/users/store/catalog"
                }
                let myJson = await FETCH(
                    "POST",
                    url,
                    formData,
                    true
                );
                if (myJson && myJson.code === 201) {
                    Swal.fire('Success', msg, 'success')
                } else {
                    Swal.fire('Error!', myJson.message, 'error')
                }
                document.getElementById("uploadCatalogue").value = null;
                th.setState({
                    selectedFile: null,
                    missedCatalogueData: [],
                    type: null,
                    isLoading: false
                })
            })

        }
    };

    render() {

        return (
            <Wrapper>
                {/* <div className="mesh-board-wrap d-flex align-items-end">
                    <h1 className="btn-sty-title flex-fill"></h1>
                    <div className="mesh-control-btns d-flex align-items-center">
                        <Link onClick={() => this.props.history.goBack()} className="btn btn-polygon-sty orange">
                            <img className="img-fluid" src={require('../../../assets/images/left-arrow.svg')} alt="icon" />
                            <span>Go back</span>
                        </Link>

                        {
                            getCookie('userType') === 'supplier' ? '' : <Link to="/contract-vessel" className="btn btn-polygon-sty dark-blue">
                                <img className="img-fluid" src={require('../../../assets/images/Monitor-icon.svg')} alt="icon" />
                                <span>Profile</span>
                            </Link>
                        }
                    </div>
                </div> */}
                <DashboardNavbar title={getCookie('userType') === 'supplier' ? 'Upload Supplier Catalogue' : 'Upload  System Catalogue'} navbarOptions={this.state.navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
                <div className="formBox mt-4">
                    <form className="needs-validation">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group d-flex align-items-center">
                                    <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                        <Select
                                            className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Catalogue Type"
                                            value={this.state.type}
                                            onChange={this.onChangeHandler}
                                            options={this.state.typeOption}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group form-input-container">
                                    <input type="file" name="file" id="uploadCatalogue" className="form-control" onChange={this.onChangeHandler} />
                                    <label htmlFor="uploadCatalogue" className="d-flex align-items-center">
                                        <span className="text-truncate">{this.state.selectedFile ? this.state.selectedFile.name : "Upload File"}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center flex-column flex-sm-row">
                            {this.state.isLoading ?
                                <div className="loader-img text-center">
                                    <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                </div>
                                : ""}

                            <div className="form-btn d-flex justify-content-end flex-column flex-md-row flex-fill">
                                {
                                    getCookie('userType') === 'supplier' ? <div className="d-flex align-items-center">
                                        <a href={Constant.mediaUrl + "Catalogue/Sample Provision Catalogue.xlsx"} className="btn btn themeReguler-btn bg-sky-blue mr-2" target="_blank">Download Sample Provision Template</a>
                                        <a href={Constant.mediaUrl + "Catalogue/Sample Store Catalogue.xlsx"} className="btn btn themeReguler-btn bg-sky-blue" target="_blank">Download Sample Store Template</a>
                                    </div>
                                        : ''
                                }
                                <div className="form-upload-btn d-flex flex-fill justify-content-end mt-3 mt-md-0">
                                    <button type="submit" className="btn btn themeReguler-btn bg-sky-blue mr-0" onClick={this.uploadExcelFile}>Upload</button>
                                </div>
                            </div>
                        </div>
                        {
                            getCookie('userType') === 'supplier' ? <div>
                                <p className="dark-red-clr mb-0 mt-3 fz-md-12 fz-14">Note: Please fill in yellow fields only to ensure an easy order handling.</p>
                            </div>
                                : ''}
                    </form>
                </div>
                {this.state.missedCatalogueData && this.state.missedCatalogueData.length > 0 ?
                    <div className="table-responsive">
                        <table className="table theme-table-wrap with-action-btns">
                            <thead>
                                <tr>

                                    <th scope="col">#</th>
                                    <th scope="col">ITEM CODE</th>
                                    <th scope="col">ALTERNATIVE CODE</th>
                                    <th scope="col">DESCRIPTION</th>
                                    {/* <th scope="col">EXPLANATION</th> */}
                                    <th scope="col">QTD PRICE / PACKAGING UNIT</th>
                                    <th scope="col">PACKAGING UNIT</th>
                                    {/* <th scope="col">BASE QUANTITY</th> */}
                                    <th scope="col">BASE UNIT</th>
                                    {/* <th scope="col">PRICE PER BASE UNIT</th> */}
                                    {/* <th scope="col">REMARK (MAX. 50 DIGITS)</th> */}
                                    {/* <th scope="col">MOQ</th> */}
                                    {/* <th scope="col">Vendor Item Code</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.missedCatalogueData.map((item, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.item_code}</td>
                                        <td>{item.alternative_code}</td>
                                        <td>{item.description}</td>
                                        <td>{item.qtd_price_packaging_unit}</td>
                                        <td>{item.packaging_unit}</td>
                                        <td>{item.base_unit}</td>
                                    </tr>

                                })
                                }
                            </tbody>
                        </table>
                    </div> : ''
                }


            </Wrapper>
        )
    }
}
export default Upload;
