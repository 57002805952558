import React from "react";
export function AddRoleForm(props) {
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            props.handleSubmit();
        }
    }
    return (
        <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="formBox mt-4">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group floating-label-wrap">
                            <input type="text" id="roleName" name="roleName" onChange={(e) => { props.handleFormChange(e) }} value={props.roleName} name="roleName" className="form-control" placeholder="Role Name" required />
                            <lable htmlFor="roleName" className="foating-labels-sty">Role Name</lable>
                        </div>
                    </div>
                    <div className="col-sm-6">
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Permissions</h2>
                            <div className="select-usr-optns-wrap d-flex align-items-center">
                                <div className="Slide-checkbx lg-checkbx grey-checkbx">
                                    <input type="checkbox" checked={props.permissionAll} onChange={(e) => { props.handleFormChange(e) }} name="permissionAll" id="permissionAll" />
                                    <label htmlFor="permissionAll"></label>
                                    <span className="indic-line"></span>
                                </div>
                                <h6 className="mb-0"> {props.permissionAll ? "Unselect All" : 'Select All'}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Dashboard</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Admin Dashboard" name="contractOffice" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "contractOffice") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="User Dashboard" name="userDashboard" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={ props.roles.findIndex(x => x.key === "userDashboard") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Provision Order</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Main & Fresh" listName="provisionOrder" name="Main & Fresh" handleFormChange={props.dropDownHandler} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.permission.provisionOrder.findIndex(x => x === "Main & Fresh") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Additional" listName="provisionOrder" name="AdditionalPro" handleFormChange={props.dropDownHandler} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.permission.provisionOrder.findIndex(x => x === "Additional") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Store Order Departments</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Deck" listName="department" name="Deck" handleFormChange={props.dropDownHandler} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.permission && props.permission.department.findIndex(x => x === "Deck") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Engine" listName="department" name="Engine" handleFormChange={props.dropDownHandler} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.permission && props.permission.department.findIndex(x => x === "Engine") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Safety" listName="department" name="Safety" handleFormChange={props.dropDownHandler} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.permission && props.permission.department.findIndex(x => x === "Safety") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Office" listName="department" name="Office" handleFormChange={props.dropDownHandler} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.permission && props.permission.department.findIndex(x => x === "Office") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Electrical" listName="department" name="Electrical" handleFormChange={props.dropDownHandler} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.permission && props.permission.department.findIndex(x => x === "Electrical") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Additional" listName="department" name="Additional" handleFormChange={props.dropDownHandler} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.permission && props.permission.department.findIndex(x => x === "Additional") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Steward/Galley/Laundry" listName="department" name="Steward/Galley/Laundry" handleFormChange={props.dropDownHandler} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.permission && props.permission.department.findIndex(x => x === "Steward/Galley/Laundry") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Orders</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Send Order" name="sendOrder" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "sendOrder") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Send RFQ" name="sendRfq" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "sendRfq") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="In-complete Orders" name="inProgressOrders" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "inProgressOrders") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Order Basket" name="orderBasket" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "orderBasket") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Vessel</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Create Vessel" name="addVessel" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "addVessel") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="View Vessel" name="vesselList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "vesselList") !== -1 ? true : false}
                                    isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "/delete-vessel") !== -1 || props.roles.findIndex(x => x.key === "editVessel") !== -1) ? true : false} />
                                <Checkbox label="Delete Vessel" name="/delete-vessel" listName="vesselList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "/delete-vessel") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Edit Vessel" name="editVessel" listName="vesselList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "editVessel") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Customer</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="View Customer" name="customerList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "customerList") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "/delete-customer") !== -1 || props.roles.findIndex(x => x.key === "editCustomer") !== -1) ? true : false} />
                                <Checkbox label="Delete Customer" name="/delete-customer" listName="customerList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "/delete-customer") !== -1 ? true : false} isDisabled={props.permissionAll} />

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Captain</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Create Captain" name="addCaptain" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "addCaptain") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="View Captain" name="captainList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "captainList") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "/delete-captain") !== -1 || props.roles.findIndex(x => x.key === "editCaptain") !== -1) ? true : false} />
                                <Checkbox label="Delete Captain" name="/delete-captain" listName="captainList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "/delete-captain") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Edit Captain" name="editCaptain" listName="captainList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "editCaptain") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">User</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Create User" name="addUser" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "addUser") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="View User" name="userList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "userList") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "/delete-cook") !== -1 || props.roles.findIndex(x => x.key === "editCook") !== -1) ? true : false} /> 
                                <Checkbox label="Delete User" name="/delete-user" listName="userList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "/delete-user") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Edit User" name="editUser" listName="userList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "editUser") !== -1 ? true : false} isDisabled={props.permissionAll} /> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Cook</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Create Cook" name="addCook" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "addCook") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="View Cook" name="cookList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "cookList") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "/delete-cook") !== -1 || props.roles.findIndex(x => x.key === "editCook") !== -1) ? true : false} />
                                <Checkbox label="Delete Cook" name="/delete-cook" listName="cookList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "/delete-cook") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Edit Cook" name="editCook" listName="cookList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "editCook") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Port</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Create Port" name="addPort" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "addPort") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="View Port" name="portList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "portList") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "/delete-port") !== -1 || props.roles.findIndex(x => x.key === "editPort") !== -1) ? true : false} />
                                <Checkbox label="Delete Port" name="/delete-port" listName="portList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "/delete-port") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Edit Port" name="editPort" listName="portList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "editPort") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Country</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Create Country" name="addCountry" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "addCountry") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="View Country" name="countryList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "countryList") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "/delete-country") !== -1 || props.roles.findIndex(x => x.key === "editCountry") !== -1) ? true : false} />
                                <Checkbox label="Delete Country" name="/delete-country" listName="countryList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "/delete-country") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Edit Country" name="editCountry" listName="countryList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "editCountry") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Department</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Create Department" name="addDepartment" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "addDepartment") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="View Department" name="departmentList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "departmentList") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "/delete-department") !== -1 || props.roles.findIndex(x => x.key === "editDepartment") !== -1) ? true : false} />
                                <Checkbox label="Delete Department" name="/delete-department" listName="departmentList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "/delete-department") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Edit Department" name="editDepartment" listName="departmentList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "editDepartment") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Supplier</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Create Supplier" name="addSupplier" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "addSupplier") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="View Supplier" name="supplierList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "supplierList") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "/delete-supplier") !== -1 || props.roles.findIndex(x => x.key === "editSupplier") !== -1) ? true : false} />
                                <Checkbox label="Delete Supplier" name="/delete-supplier" listName="supplierList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "/delete-supplier") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Edit Supplier" name="editSupplier" listName="supplierList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "editSupplier") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Package</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Create Package" name="addPackage" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "addPackage") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="View Package" name="packageList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "packageList") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "/delete-package") !== -1 || props.roles.findIndex(x => x.key === "editPackage") !== -1) ? true : false} />
                                <Checkbox label="Delete Package" name="/delete-package" listName="packageList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "/delete-package") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Edit Package" name="editPackage" listName="packageList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "editPackage") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Catalogue Upload</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="Catalogue Upload" name="fileUpload" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "fileUpload") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="View Catalogues" name="catalogueList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "catalogueList") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "/delete-catalogue") !== -1 || props.roles.findIndex(x => x.key === "editCatalogue") !== -1) ? true : false} />
                                <Checkbox label="Delete Catalogue" name="/delete-catalogue" listName="catalogueList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "/delete-catalogue") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="Edit Catalogue" name="editCatalogue" listName="catalogueList" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "editCatalogue") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">

                    {/* <div className="col-sm-6">
                        <div className="form-group with-toggle-switch">
                            <h2 className="form-lg-title with-border">Current Order</h2>
                            <div className="select-optns-group d-flex align-items-center flex-wrap">
                                <Checkbox label="View RFQ" name="inprogressQuotation" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "inprogressQuotation") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "compareQuotation") !== -1) ? true : false} />
                                <Checkbox label="Compare Quotation" name="compareQuotation" listName="inprogressQuotation" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "compareQuotation") !== -1 ? true : false} isDisabled={props.permissionAll} />
                                <Checkbox label="View Orders" name="openOrder" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "openOrder") !== -1 ? true : false} isDisabled={props.permissionAll || (props.roles.findIndex(x => x.key === "orderDetail") !== -1) ? true : false} />
                                <Checkbox label="Order Detail" name="orderDetail" listName="openOrder" handleFormChange={props.handleFormChange} permissionAll={props.permissionAll} checked={props.permissionAll ? true : props.roles.findIndex(x => x.key === "orderDetail") !== -1 ? true : false} isDisabled={props.permissionAll} />
                            </div>
                        </div>
                    </div> */}

                </div>

                {props.apiCallStatus.apiCallFor === props.apiName && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                    <div className="alert alert-danger">
                        {props.apiCallStatus.message}
                    </div>
                    : ""}
                <div className="d-flex align-items-center flex-column flex-sm-row">
                    <div className="form-btn d-flex justify-content-end flex-fill">
                        {props.apiCallStatus.apiCallFor === props.apiName && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                            <div className="loader-img text-center">
                                <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                            </div>
                            : ""}
                        <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">{props.isEdit ? "Update" : "Submit"}</button>
                    </div>
                </div>
            </div>
        </form>

    )
}


function Checkbox(props) {
    return <div className="select-usr-optns-wrap d-flex align-items-center">
        <div className="Slide-checkbx lg-checkbx grey-checkbx">
            <input type="checkbox" checked={props.checked} onChange={(e) => { props.handleFormChange(e, props.listName) }} name={props.name} id={props.name} disabled={props.isDisabled} />
            <label htmlFor={props.name}></label>
            <span className="indic-line"></span>
        </div>
        <h6 className="mb-0">{props.label}</h6>
    </div>
}