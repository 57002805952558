import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { FETCH } from "../../../Services/fetch";
import {
    GetSubAdminAction,
    AppendSubAdminAction,
    GetSubAdminDetailAction,
    getAllRoleAction
} from "../Actions/action";
export function addSubAdmin(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "addSubAdmin" }));
        let myJson = await FETCH("POST", Constant.apiURl + "/auth/register", data);
        if (myJson && myJson.code === 200) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "addSubAdmin",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "addSubAdmin",
                    message: myJson.message,
                })
            );
        }
    };
}
export function subAdminListing(offset, query) {
    return async (dispatch) => {
        if (offset !== 0) {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendSubAdminList" }));
        } else {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "getSubAdmin" }));
            dispatch(GetSubAdminAction([], 0));
        }
        let url = Constant.apiURl + "/users/" + offset + "/20";
        if (query && query !== "") {
            url = url + query;
        }
        let myJson = await FETCH("GET", url);
        if (myJson && myJson.code === 201) {
            if (offset !== 0) {
                dispatch(AppendSubAdminAction(myJson.data));
                dispatch(
                    Actions.ApiFulfilledAction({
                        apiCallFor: "appendSubAdminList",
                        message: "",
                    })
                );
            } else {
                dispatch(GetSubAdminAction(myJson.data, myJson.count));
                dispatch(
                    Actions.ApiFulfilledAction({
                        apiCallFor: "getSubAdmin",
                        message: myJson.message,
                    })
                );
            }
        } else {
            if (offset !== 0) {
                dispatch(
                    Actions.ApiRejectedAction({
                        statusCode: myJson.statusCode,
                        apiCallFor: "appendSubAdminList",
                        message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
                    })
                );
            } else {
                dispatch(
                    Actions.ApiRejectedAction({
                        statusCode: myJson.statusCode,
                        apiCallFor: "getSubAdmin",
                        message: myJson.message,
                    })
                );
            }
        }
    };
}
export function deleteSubAdmin(adminId) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteSubAdmin" }));
        let myJson = await FETCH("DELETE", Constant.apiURl + `/users/${adminId}`);
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "deleteSubAdmin",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "deleteSubAdmin",
                    message: myJson.message,
                })
            );
        }
    };
}

export function getSubAdminDetail(id) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getSubAdminDetail" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/users/" + id);
        if (myJson && myJson.code === 201) {
            dispatch(
                GetSubAdminDetailAction({
                    name: myJson.data.name,
                    email: myJson.data.email,
                    password: '',
                    confirmPassword: '',
                    role: { value: myJson.data.roleId, label: myJson.data.roleName, roles: myJson.data.roles, permission: myJson.data.permission },
                    subAdminId: id,
                    isHitApi: true
                })
            );
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getSubAdminDetail",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getSubAdminDetail",
                    message: myJson.message,
                })
            );
        }
    };
}
export function updateSubAdmin(id, data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateSubAdmin" }));
        let myJson = await FETCH("PATCH", Constant.apiURl + "/users/" + id, data);
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "updateSubAdmin",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "updateSubAdmin",
                    message: myJson.message,
                })
            );
        }
    };
}
export function getAllRole() {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getAllRole" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/role");
        if (myJson && myJson.code === 201) {
            dispatch(getAllRoleAction(myJson.data));
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getAllRole",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getAllRole",
                    message: myJson.message,
                })
            );
        }
    };
}