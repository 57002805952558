import React, { useEffect } from 'react';
import Select from 'react-select';
import CustomSelect from '../../Common/Components/CustomSelect'
export function Filters(props) {  
    return (
        <div className="card-bx-sty">
            <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                <div className="fil-wrap-ctn d-flex align-items-center flex-column flex-sm-row mb-2 mb-lg-0">
                    <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                        <Select
                            className="custom-select-wrap small-select-sty" classNamePrefix="react-select" placeholder="Item Group"
                            value={props.itemGroup === "" ? null : { label: props.itemGroup, value: props.itemGroup }}
                            onChange={(e) => props.handleChange('itemGroup', e.value)}
                            options={props.itemGroupList}
                        />
                    </div>
                    <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                        <CustomSelect pagesList={props.itemNoList} inputId="inputItemNo" id="itemNo" fetchMore={props.fetchMoreItemCode} pageListTotal={props.totalItemCode} inputValue={props.inputItemNo} value={props.itemNo || ""} placeholder="IMPA code" handleChange={props.handleChange} clickCustomeSelectHandler={props.clickCustomeSelectHandler} />
                    </div>
                    <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                        <CustomSelect pagesList={props.descriptionList} inputId="inputDescription" id="description" fetchMore={props.fetchMoreDescription} pageListTotal={props.totalDescription} value={props.description || ""} inputValue={props.inputDescription || ""} placeholder="Description" handleChange={props.handleChange} clickCustomeSelectHandler={props.clickCustomeSelectHandler} />
                    </div>
                </div>
                <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill ">
                    <button type="button" className="btn btn-yellow" onClick={() => props.applyFilters()} >Search</button>
                    <button type="button" className="btn btn-yellow ml-1" onClick={() => props.clearFilters()} >Clear</button>
                    {
                        props.catalogueDetailLength > 0 ? <div className="d-flex flex-fill justify-content-end ml-1">

                            {props.userType === "supplier" || (props.userType === "user" && !props.isSubAdmin) || (props.userType === "user" && props.isSubAdmin  && props.roles.length !== 0 && props.roles.findIndex(x => x === '/delete-catalogue') !== -1) ? <button type="button" className="btn btn-yellow bg-dark-red" onClick={() => props.removeAllCatalogues()} >Delete Catalogue</button> : ""}

                        </div> : ''
                    }

                </div>
            </div>
        </div>
    )
}