import React from 'react';
import moment from "moment";
export function OrderOverView(props) {
    const calculateOrderedProduct = (category) => {
        let sum = 0;
        let keys = Object.keys(props.orderDetail)
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            if (props.orderDetail[element].category === category && props.orderDetail[element].order !== '') {
                sum += parseFloat(props.orderDetail[element].order) * props.orderDetail[element].orKg
            }
        }
        return sum;
    }
    const CatogeryDetails = (item, numOfCrew, numOfDays, index) => {
        let needed = (parseFloat(item.qtyPerDay) / 1000 * numOfCrew * numOfDays).toFixed(2);
        let ordered = calculateOrderedProduct(item.category);
        return <tr key={index} className={props.provisionOrderType === 'Fresh Top Up' ? item.type === 'fresh' ? '' : 'all-td-disable td' : props.provisionOrderType === 'Main Order' ? item.type === 'main' ? '' : 'all-td-disable td' : ''}>
            <td>{item.category}</td>
            <td className="text-right ">{needed}</td>
            <td className="text-center"><span className="d-inline-block mr-2"><i className={ordered == 0 ? 'fas fa-arrow-up arrow-control' : needed == ordered ? 'fas fa-arrow-right light-orng-clr' : needed > ordered ? 'fas fa-arrow-up value-in' : 'fas fa-arrow-down text-danger'}></i></span>{ordered.toFixed(2)}</td>
        </tr >
    }
    let totalUsedPoints = props.totalUsedPoints.total == 0 ? '10000' : ((10000 - Number(props.totalUsedPoints.total)));
    let totalUsedPointsPercentage = 100 - props.totalUsedPoints.percentage;
    return (
        <div className="ordCatg-wrapper">
            <div className="card-bx-sty d-flex align-items-center flex-column flex-sm-row pb-2">
                <div className="box-item-inn-wrap flex-fill mr-0 pb-0 w-100 mb-0">
                    {/* table collapse btn */}
                    <div className="pro-stat-collapse-btn">
                        <button type="button" className="btn btn themeReguler-btn bg-dark-blue" data-toggle="collapse" href="#prov_catalouge" role="button" aria-expanded="false" aria-controls="prov_catalouge">View Order Details</button>
                    </div>
                    {/* New header design */}
                    <div className="table-responsive w-100 collapse mt-3" id="prov_catalouge">
                        <div className=" d-flex flex-nowrap prov-upgrade-info">
                            {/* Provision Details */}
                            <table className="table theme-table-wrap">
                                <thead>
                                    <tr>
                                        <th colSpan="2">Provision needed until next order in kg:</th>
                                        <th className="text-center">Ordered</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.category && props.category.length > 0 ?
                                            props.category.map((item, index) => {
                                                if (index < props.category.length / 2) {
                                                    return CatogeryDetails(item, props.numOfCrew, props.numOfDays, index)

                                                }
                                            }) : ''}
                                    {/* Status row */}
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td className=" text-right">&nbsp;</td>
                                        <td className="text-center border-left-none">&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* Provision Details two */}
                            <table className="table theme-table-wrap">
                                <thead>
                                    <tr>
                                        <th colSpan="2">Provision needed until next order in kg:</th>
                                        <th className="text-center">Ordered</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.category && props.category.length > 0 ?
                                            props.category.map((item, index) => {
                                                if (index < props.category.length / 2) {

                                                } else {
                                                    return CatogeryDetails(item, props.numOfCrew, props.numOfDays, index)

                                                }
                                            }) : ''}
                                    <tr>
                                        <td className="border-left-none">Total Kg needed for trip:</td>
                                        <td className="text-right">{props.orderNeeded}</td>
                                        <td className="text-center border-left-none"><span className="d-inline-block mr-2"></span>{props.totalOrder.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* Nutritation Details */}
                            <table className="table theme-table-wrap nutri-table-wrap">
                                <thead>
                                    <tr>
                                        <th colSpan="2">Nutrition recommended:</th>
                                        <th className="text-center">Ordered:</th>
                                        <th className="text-center">Unit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Energy(Kcal)</td>
                                        <td className="text-right ">2800</td>
                                        <td className="text-center">
                                            <span className="d-inline-block mr-2">
                                                <i className={props.totalUsedPoints.energy == 0 ? 'fas fa-arrow-up arrow-control' : 2800 == props.totalUsedPoints.energy ? 'fas fa-arrow-right light-orng-clr' : 2800 > props.totalUsedPoints.energy ? 'fas fa-arrow-up value-in' : 'fas fa-arrow-down text-danger'}></i>
                                            </span>
                                            {props.totalUsedPoints.energy ? props.totalUsedPoints.energy : '0'}
                                        </td>
                                        <td className="text-right">Kcal/d/crew</td>
                                    </tr>
                                    <tr>
                                        <td>PROTEIN(g)</td>
                                        <td className="text-right ">101</td>
                                        <td className="text-center">
                                            <span className="d-inline-block mr-2">
                                                <i className={props.totalUsedPoints.protein == 0 ? 'fas fa-arrow-up arrow-control' : 101 == props.totalUsedPoints.protein ? 'fas fa-arrow-right light-orng-clr' : 101 > props.totalUsedPoints.protein ? 'fas fa-arrow-up value-in' : 'fas fa-arrow-down text-danger'}></i>
                                            </span>
                                            {props.totalUsedPoints.protein ? props.totalUsedPoints.protein : '0'}
                                        </td>
                                        <td className="text-right">g/d/crew</td>
                                    </tr>
                                    <tr>
                                        <td>FAT, TOTAL(g)</td>
                                        <td className="text-right">92</td>
                                        <td className="text-center">
                                            <span className="d-inline-block mr-2">
                                                <i className={props.totalUsedPoints.fat == 0 ? 'fas fa-arrow-up arrow-control' : 92 == props.totalUsedPoints.fat ? 'fas fa-arrow-right light-orng-clr' : 92 > props.totalUsedPoints.fat ? 'fas fa-arrow-up value-in' : 'fas fa-arrow-down text-danger'}></i>
                                            </span>
                                            {props.totalUsedPoints.fat ? props.totalUsedPoints.fat : '0'}
                                        </td>
                                        <td className="text-right">g/d/crew</td>
                                    </tr>
                                    <tr>
                                        <td>CARBOHYDRATES(g)</td>
                                        <td className="text-right ">370</td>
                                        <td className="text-center">
                                            <span className="d-inline-block mr-2">
                                                <i className={props.totalUsedPoints.carbohydrates == 0 ? 'fas fa-arrow-up arrow-control' : 370 == props.totalUsedPoints.carbohydrates ? 'fas fa-arrow-right light-orng-clr' : 370 > props.totalUsedPoints.carbohydrates ? 'fas fa-arrow-up value-in' : 'fas fa-arrow-down text-danger'}></i>
                                            </span>
                                            {props.totalUsedPoints.carbohydrates ? props.totalUsedPoints.carbohydrates : '0'}
                                        </td>
                                        <td className="text-right">g/d/crew</td>
                                    </tr>
                                    <tr>
                                        <td>THEREOF SUGAR(g)</td>
                                        <td className="text-right"></td>
                                        <td className="text-center">  {props.totalUsedPoints.sugar ? props.totalUsedPoints.sugar : '0'}</td>
                                        <td className="text-right">g/d/crew</td>
                                    </tr>
                                    <tr>
                                        <td>DIETARY FIBRES</td>
                                        <td className="text-right">42</td>
                                        <td className="text-center">
                                            <span className="d-inline-block mr-2">
                                                <i className={props.totalUsedPoints.dietaryFibres == 0 ? 'fas fa-arrow-up arrow-control' : 42 == props.totalUsedPoints.dietaryFibres ? 'fas fa-arrow-right light-orng-clr' : 42 > props.totalUsedPoints.dietaryFibres ? 'fas fa-arrow-up value-in' : 'fas fa-arrow-down text-danger'}></i>
                                            </span>
                                            {props.totalUsedPoints.dietaryFibres ? props.totalUsedPoints.dietaryFibres : '0'}
                                        </td>
                                        <td className="text-right">g/d/crew</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-right "></td>
                                        <td className="text-center ">&nbsp;</td>
                                        <td className="text-right "></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-right "></td>
                                        <td className="text-center ">&nbsp;</td>
                                        <td className="text-right "></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="text-right "></td>
                                        <td className="text-center ">&nbsp;</td>
                                        <td className="text-right "></td>
                                    </tr>
                                    <tr>
                                        <td className="text-left">Remaining pts.</td>
                                        <td colspan="2" className="text-right">{Number(totalUsedPoints).toFixed(2)}</td>
                                        <td className="text-right border-left-none">{Number(totalUsedPointsPercentage).toFixed(2)}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/*                    
                    <div className="update-statics-wrap d-flex align-items-center mt-1 flex-nowrap overflow-auto py-2">
                        <div className="card-bx-sty box-item-inn">
                            <h1 className="text-nowrap h1">Order Overview</h1>
                            <p className="text-nowrap">Provision <span className="stat-value-sty">{moment().format("DD-MM-YYYY")}</span></p>
                        </div>
                        <div className="card-bx-sty box-item-inn">
                            <h1 className="text-nowrap">Remaining Pts:</h1>
                            <p className="text-nowrap"><span className="stat-value-sty">{props.totalUsedPoints.total}</span>/ {props.totalOrderPoints} points  =  <span className="stat-value-sty">{(100 - props.totalUsedPoints.percentage).toFixed(2)}</span>%</p>
                        </div>
                        <div className="card-bx-sty box-item-inn">
                            <h1 className="text-nowrap">Provision Order in kg:</h1>
                            <p className="text-nowrap"><span className="mr-3">needed: <span className="stat-value-sty">{props.orderNeeded}</span> kg</span><span>ordered: <span className="stat-value-sty">{props.totalOrder.toFixed(2)}</span> kg</span></p>
                        </div>
                        <div className="card-bx-sty box-item-inn">
                            <h1 className="text-nowrap">Nutrition / day</h1>
                            <p className="text-nowrap"><span className="mr-3">needed: <span className="stat-value-sty">2800</span> kcal</span><span>ordered: <span className="stat-value-sty">{props.totalUsedPoints.energy}</span> kcal</span></p>
                        </div>
                    </div>  */}
                    <div className="update-statics-wrap d-flex align-items-start mt-1 flex-nowrap overflow-auto py-2">
                        <div className="card-bx-sty box-item-inn">
                            <span className="text-nowrap order-h1">Order Overview</span>
                            <span className="text-nowrap order-p">Provision <span className="stat-value-sty">{moment().format("DD-MM-YYYY")}</span></span>
                        </div>
                        <div className="card-bx-sty box-item-inn">
                            <span className="text-nowrap order-h1">Remaining pts:</span>
                            <span className="text-nowrap order-p"><span className="stat-value-sty">{(10000 - props.totalUsedPoints.total).toFixed(2)}</span> = <span className="stat-value-sty">{(100 - props.totalUsedPoints.percentage).toFixed(0)}</span>%</span>
                        </div>
                        <div className="card-bx-sty box-item-inn">
                            <span className="text-nowrap order-h1">Provision Order:</span>
                            <span className="text-nowrap order-p"><span className="mr-1">needed: <span className="stat-value-sty">{props.orderNeeded}</span> kg</span><span>ordered: <span className="stat-value-sty">{props.totalOrder.toFixed(2)}</span> kg</span></span>
                        </div>
                        <div className="card-bx-sty box-item-inn">
                            <span className="text-nowrap order-h1">Nutrition / day</span>
                            <span className="text-nowrap order-p"><span className="mr-1">needed: <span className="stat-value-sty">2800</span> kcal</span><span>ordered: <span className="stat-value-sty">{props.totalUsedPoints.energy}</span> kcal</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}