import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import { Wrapper, DashboardNavbar, CsvDownload } from '../../../Common/Components/index';
import { compareQuotationList, sendQuotation } from '../../Apicalls/quotations';
import loader from '../../../../assets/images/Spinner-1s-200px.gif';
import cloneDeep from 'lodash.clonedeep';
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import moment from 'moment';

const headers = [
    { label: 'Item Code', key: 'itemCode' },
    { label: 'Description', key: 'description' },
    { label: 'Price', key: 'price' },
];
var csvLinkRef = React.createRef();
function CompareQuotation(props) {
    const [state, setState] = useState({
        isHitApi: false,
        price: 0,
        orderDetail: {},
        csvData: [],
        isCsvShow: false,
        quotationDetailList: [],
    });
    useEffect(() => {
        if (!state.isHitApi) {
            props.compareQuotationList(props.match.params.orderId);
            let data = { ...state };
            data.isHitApi = true;
            setState(data);
        }
        if (props.apiCallStatus.apiCallFor === "compareQuotationList" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let isCsvShow = props.quotationReducer.quotationDetailList.length > 0 && props.quotationReducer.quotationDetailList[0].status && props.quotationReducer.quotationDetailList[0].status === "confirmed" ? true : false
            let csvData = [];
            if (isCsvShow) {
                let catalouges = props.quotationReducer.quotationDetailList[0].catalouges;
                for (let index = 0; index < catalouges.length; index++) {
                    const element = catalouges[index];
                    let csvObj = {
                        itemCode: element.itemCode,
                        description: element.description,
                        price: element.pricePerCountry.price,
                    }
                    csvData.push(csvObj)
                }
            }

            setState({
                ...state,
                quotationDetailList: props.quotationReducer.quotationDetailList,
                isCsvShow,
                csvData,
                isHitApi: true
            })
            props.ApiClearAction()
        }
        if (props.apiCallStatus.apiCallFor === "sendQuotation" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            Swal.fire('Success', "RFQ send succesfully", 'success');
            props.history.push('/inprogress-quotations')
            props.ApiClearAction()
        }
        if (props.apiCallStatus.apiCallFor === "sendQuotation" &&
            props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed) {
            Swal.fire('Error', props.apiCallStatus.message ? props.apiCallStatus.message : "Error in saving quotation", 'error');
            props.ApiClearAction()
        }
    });
    const sendQuotation = () => {
        let items = [];
        let keys = Object.keys(state.orderDetail);
        let orderId = '';
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            if (state.orderDetail[element].price !== "") {
                orderId = state.orderDetail[element].orderId
                items.push({
                    itemCode: state.orderDetail[element].itemCode, price: parseFloat(state.orderDetail[element].price)
                })
            }
        }
        props.sendQuotation({ items, quotationId: props.match.params.quotationId, orderId })
    }
    const declineQuotation = () => {
        let orderDetail = [];
        let keys = Object.keys(state.orderDetail);
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            if (state.orderDetail[element].price !== "") {
                orderDetail.push({
                    itemCode: state.orderDetail[element].itemCode, price: state.orderDetail[element].price
                })
            }
        }
    }
    const getNavbarOptions = () => {
        let options = [
            {
                img: 'left-arrow.svg',
                className: "orange",
                name: "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.history.goBack()
            }
        ]
        if (props.userType === 'supplier') {
            options.push({
                img: 'Edit-icon.svg',
                className: "dark-green " + (state.quotationDetailList.length !== Object.keys(state.orderDetail).length ? "btn-disable" : ""),
                name: "Send<br />Prepare Quotation",
                path: `/prepare-quotation/${props.match.params.quotationId}`,
                isLogout: false,
                isCustom: true,
                onClick: () => sendQuotation()
            })
        }
        return options;
    }
    const apiCallForCSVData = () => {
        /**
         * Requesting csv files data
         */
        csvLinkRef.current.link.click()

    }
    return (
        <Wrapper>
            <DashboardNavbar title="Quotation" navbarOptions={getNavbarOptions()} className="mesh-board-wrap d-flex align-items-end" />
            {/* RFQ Detials Section */}
            <div className="rfq-details-wrap ordCatg-table-wrap sup-rfq-wrap one-of-four d-flex align-items-start  next-oder  mb-2 align-items-center ml-2">
                <div className="rfq-durations rfq-details-items  descriptrfq">
                    <h2 className="mt-2 font-weight-bold">Vessel Name</h2>
                    <div className="inner-content text-left">
                        <h4 className="mt-0 font-weight-bold">Del. Place:  <span className="font-weight-bold mt-1 d-inline-block"></span></h4>
                        <h4 className="mt-0 font-weight-bold">Del. date:   <span className="font-weight-bold mt-1 d-inline-block"></span></h4>
                        <h4 className="mt-0 font-weight-bold">Agent Detail:   <span className="font-weight-bold mt-1 d-inline-block"></span></h4>                    </div>
                </div>
                {Object.size(props.quotationReducer.orderDetail) > 0 ?
                    <div className="rfq-rating rfq-details-items">
                        <h2 className="mt-2">{props.quotationReducer.orderDetail ? props.quotationReducer.orderDetail.vesselName : ""}</h2>
                        <h2 className="mt-2">{props.quotationReducer.orderDetail && props.quotationReducer.orderDetail.placeOfDelivery ? props.quotationReducer.orderDetail.placeOfDelivery.label : ""}</h2>
                        <h2 className="mt-2">{props.quotationReducer.orderDetail ? moment(props.quotationReducer.orderDetail.arrivalTime).format('YYYY-MM-DD') : ""}</h2>
                        <h2 className="mt-2">{props.quotationReducer.orderDetail && props.quotationReducer.orderDetail.agentDetail ? props.quotationReducer.orderDetail.agentDetail : ""}</h2>
                    </div> : ""}
            </div>
            {/* RFQ Detials Section End */}
            {
                state.isCsvShow ? <div className="ordCatg-table-wrap mt-3 system-cat-filter">
                    <div className="card-bx-sty">
                        <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                            <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                                <CsvDownload data={state.csvData} csvLinkRef={csvLinkRef} filename={"Recent-RFQ.csv"} headers={headers} apiCallForCSVData={() => apiCallForCSVData()} />
                            </div>
                        </div>
                    </div>
                </div>
                    : ''
            }
            {/* Table */}
            <div className="d-flex justify-content-center">
                {props.apiCallStatus.isStarted.indexOf("compareQuotationList") !== -1 ?
                    <div className="loader-img text-center">
                        <img style={{ width: "46px" }} src={loader} alt='' />
                    </div>
                    : state.quotationDetailList && state.quotationDetailList.length > 0 ? state.quotationDetailList.map((item, index) => {

                        return (
                            <div className={`ordCatg-table-wrap mt-4 sup-rfq-wrap ${state.quotationDetailList.length === 1 ? '' : state.quotationDetailList.length === 2 ? 'one-of-two' : state.quotationDetailList.length === 3 ? 'one-of-three' : ''}`}>
                                <div className="table-group d-flex">
                                    <SupplierSection key={index} orderType={props.quotationReducer.orderDetail && props.quotationReducer.orderDetail.type ? props.quotationReducer.orderDetail.type : ""} itemDetail={props.quotationReducer.orderDetail && props.quotationReducer.orderDetail.itemDetail ? props.quotationReducer.orderDetail.itemDetail : {}} apiCallStatus={props.apiCallStatus} {...item} userType={props.userType} />
                                </div>
                            </div>
                        );
                    }) : state.quotationDetailList && state.quotationDetailList.length === 0 ?
                            <div>No Data Found</div>
                            : ''}
            </div>
        </Wrapper>
    )
}
function SupplierSection(props) {
    return <div className="table-responsive">
        <table className="table theme-table-wrap2 short-table sticky-th-border">
            <thead>
                <tr>
                    <th colSpan= {props.orderType === "store" ? "6":"5"} className="table-title text-center">
                    <strong className="mr-2">Supplier:</strong> <span className="text-capitalize mr-2">{props.supplier.title}</span><br/>
                    <strong>Status:</strong> <span className="text-capitalize">{props.status}</span>
                </th></tr>
                <tr>
                    <th className="text-left">ItemCode</th>
                    <th className="text-left">Description</th>
                    {props.orderType === "store" ? <th className="text-left">Department</th> : ""}
                    <th className="text-left">Price</th>
                    <th className="text-left">Quantity</th>
                    <th className="text-left">Total Price</th>
                </tr>
            </thead>
            <tbody>
                {props.apiCallStatus.isStarted.indexOf("compareQuotationList") !== -1 ?
                    <tr><td colSpan="20">
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={loader} alt='' />
                        </div>
                    </td></tr>
                    : props.catalouges && props.catalouges.length > 0 ? props.catalouges.map((item, index) => {
                        let quantity = props.itemDetail[item.itemCode] ? props.itemDetail[item.itemCode].order : 0;
                        let department = props.itemDetail[item.itemCode] ? props.itemDetail[item.itemCode].department : '';
                        let totalPrice = (quantity * item.pricePerCountry.price).toFixed(2);
                        return <tr key={index}>
                            <td><span className="text-center td-wrap-sty">{item.itemCode}</span></td>
                            <td><span className="text-left td-wrap-sty">{item.description}</span></td>
                            {props.orderType === "store" ? <td><span className="text-left td-wrap-sty">{department}</span></td> : ""}
                            <td className="text-start">{item.priceType === "estimated" ? <React.Fragment><span
                                className="price-text" data-effect="float" data-tip="Estimated Price">
                                <img className="img-fluid" src={require('../../../../assets/images/circle_outline_with_exclamation.svg')} alt="icon" />
                            </span>
                                <span className="price-text">{item.pricePerCountry.price} </span>
                            </React.Fragment> : <span className="price-text">{item.pricePerCountry.price} </span>}</td>
                            {/* <td><span className="text-center td-wrap-sty">{item.pricePerCountry.price}</span></td> */}
                            <td><span className="text-center td-wrap-sty">{quantity}</span></td>
                            <td><span className="text-center td-wrap-sty">{totalPrice}</span></td>
                        </tr>

                    }) : props.catalouges && props.catalouges.length === 0 ?
                            <tr><td colSpan="40" className="text-center">No Data Found</td></tr> : ''}
                {props.status === "confirmed" && props.userType !== 'customer' ?
                    <tr><td colSpan={props.orderType === "store" ? "6" : "5"} className="text-center"><Link to={"/send/order/" + props.supplierId + "/" + props.orderId} className="btn tb-btn-rounded  orng">Select Supplier</Link></td></tr>
                    : ""}
            </tbody>
        </table>
    </div>

}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    quotationReducer: state.quotationReducer,
    userId: state.authReducer.userId,
    userType: state.authReducer.userType
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ApiClearAction: () => dispatch(ApiClearAction()),
    sendQuotation: (data) => dispatch(sendQuotation(data)),
    compareQuotationList: (quotationId, supplierId) => dispatch(compareQuotationList(quotationId, supplierId))

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompareQuotation);