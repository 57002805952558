import React, { useEffect, useState } from 'react';
import { getCookie, setCookie } from '../../../Services/cookies';
import { Wrapper,DashboardNavbar } from '../../Common/Components/index';
import { getCatalogueDetail, updateCatalogue } from "./../ApiCalls/supplierCatalougeList";
import { ApiClearAction, ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
const catalogueInitialState = {
    alternativeCode: '',
    sortOrder: '',
    rank: '',
    qtdPricePackagingUnit: '',
    energyKcal: '',
    itemId: '',
    itemCode: '',
    itemCode2: '',
    category: '',
    subCategory: '',
    countryCode: '',
    durability: '',
    description: '',
    explanation: '',
    qtdPrice: '',
    availability: '',
    pricePerPackagingUnit: '',
    standardPackagingUnit: '',
    baseQuantity: '',
    baseUnit: '',
    remark: '',
    pricePerBaseUnit: '',
    countryName: '',
    priceAvg: '',
    pacakageUnitAvg: '',
    baseQuantityAvg: '',
    energy: '',
    protein: '',
    fat: '',
    fatTotalGr: '',
    carboHydratesGr: '',
    sugar: '',
    sugarGr: '',
    orderType: '',
    alternativeCode2: '',
    dietaryFibres: '',
    supplierId: '',
    catalogueId: '',
    pricePerCountry: [],
    changedValues: [],
    isError: false,
    errorMessage: '',
    isHitApi: false
}
function EditCatalogue(props) {
    const [state, setState] = useState(catalogueInitialState);
    const [isHitOnLoadApi, setIsHitOnLoadApi] = useState(false);
    useEffect(() => {
        if (!isHitOnLoadApi) {
            props.getCatalogueDetail(props.match.params.id)
            setIsHitOnLoadApi(true)
        }
        if ((props.apiCallStatus.apiCallFor === "getCatalogueDetail") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let data = props.uploadReducer.detail;
            setState({
                ...state,
                alternativeCode: data.alternativeCode,
                sortOrder: data.sortOrder,
                rank: data.rank,
                catalogueId: data.catalogueId,
                qtdPricePackagingUnit: data.qtdPricePackagingUnit,
                energyKcal: data.energyKcal,
                itemId: data.itemId,
                itemCode: data.itemCode,
                itemCode2: data.itemCode2,
                category: data.category,
                subCategory: data.subCategory,
                countryCode: data.countryCode,
                durability: data.durability,
                description: data.description,
                explanation: data.explanation,
                qtdPrice: data.qtdPrice,
                availability: data.availability,
                pricePerPackagingUnit: data.pricePerPackagingUnit,
                standardPackagingUnit: data.standardPackagingUnit,
                baseQuantity: data.baseQuantity,
                baseUnit: data.baseUnit,
                remark: data.remark,
                pricePerBaseUnit: data.pricePerBaseUnit,
                countryName: data.countryName,
                priceAvg: data.priceAvg,
                pacakageUnitAvg: data.pacakageUnitAvg,
                baseQuantityAvg: data.baseQuantityAvg,
                energy: data.energy,
                protein: data.protein,
                fat: data.fat,
                fatTotalGr: data.fatTotalGr,
                sugar: data.sugar,
                carboHydratesGr: data.carboHydratesGr,
                sugarGr: data.sugarGr,
                orderType: data.orderType,
                alternativeCode2: data.alternativeCode2,
                dietaryFibres: data.dietaryFibres,
                supplierId: data.supplierId,
                pricePerCountry: data.pricePerCountry,
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "updateCatalogue") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(catalogueInitialState)
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
            Swal.fire("Success", `Catalogue has been updated successfully`, "success")
            props.history.push('/supplier-catalogue-listing')
        }

    });
    const handleFormChange = (e, type, position) => {
        let name = e.target.name;
        let value = e.target.value;
        let changedValuesName = type !== undefined ? type : name;
        let catalogueDetail = { ...state }
        let newArray = catalogueDetail['changedValues'];
        if (type !== undefined) {
            let newArray = catalogueDetail[type]
            if (name === 'baseQuantity' || name === 'packageUnitPrice') {
                newArray[position][name] = value !== '' ? Number(value) : value
                let packageUnitPrice = newArray[position]['packageUnitPrice']
                let baseQuantity = newArray[position]['baseQuantity']
                newArray[position]['price'] = packageUnitPrice / baseQuantity
            } else {
                newArray[position][name] = value;
            }
            catalogueDetail[type] = newArray;

        } else {
            catalogueDetail[name] = value;

        }
        let index = newArray.findIndex(x => x === changedValuesName);
        if (index === -1) {
            newArray.push(changedValuesName)
        }
        catalogueDetail['changedValues'] = newArray;
        catalogueDetail['errorMessage'] = ''
        catalogueDetail['isError'] = false
        setState(catalogueDetail)

    }
    const updateCatalogue = () => {
        let data = { ...state };
        let newArry = data.changedValues;
        let obj = {};
        if (newArry.length > 0) {
            for (let i = 0; i < newArry.length; i++) {
                const element = newArry[i];
                obj[element] = data[element]

            }
            props.updateCatalogue(obj, data['catalogueId'])

        }
    }

    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Edit Catalogue" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="formBox">
                <CatalogueForm {...state} history={props.history} handleFormChange={handleFormChange} updateCatalogue={updateCatalogue} apiCallStatus={props.apiCallStatus} type={props.match.params.type} />
            </div>
        </Wrapper>
    )
}

function CatalogueForm(props) {
    const payload = [
        { key: 'itemCode', label: 'Item Code', show: true },
        { key: 'alternativeCode', label: 'Alternative Code', show: true },
        { key: 'sortOrder', label: 'Sort Order', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'rank', label: 'Rank', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'energyKcal', label: 'Energy', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'itemCode2', label: 'Item Code2', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'orderType', label: 'Category', show: true },
        { key: 'category', label: 'Sub Category 1', show: true },
        { key: 'subCategory', label: 'Sub Category 2', show: true },
        { key: 'countryCode', label: 'Country Code', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'durability', label: 'Durability', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'description', label: 'Description', show: true },
        { key: 'qtdPricePackagingUnit', label: 'Quantity Price Packaging Unit', show: true },
        { key: 'baseQuantity', label: 'Base Quantity', show: true },
        { key: 'explanation', label: 'Explanation', show: true },
        { key: 'qtdPrice', label: 'Quantity Price', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'availability', label: 'Availability', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'remark', label: 'Remark', show: true },
        { key: 'standardPackagingUnit', label: 'Standard Packaging Unit', show: true },
        { key: 'baseUnit', label: 'Base Unit', show: true },
        { key: 'pricePerBaseUnit', label: 'Price Per Base Unit', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'countryName', label: 'Country Name', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'priceAvg', label: 'Price Avg', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'pacakageUnitAvg', label: 'Pacakage Unit Avg', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'baseQuantityAvg', label: 'Base Quantity Avg', show: getCookie('userType') === 'supplier' ? false : true },
        { key: 'energy', label: 'Energy Kal', show: (props.type === 'provision' && getCookie('userType') === 'user') ? true : false },
        { key: 'protein', label: 'Protein (g)', show: (props.type === 'provision' && getCookie('userType') === 'user') ? true : false },
        { key: 'fat', label: 'Fat (g)', show: (props.type === 'provision' && getCookie('userType') === 'user') ? true : false },
        { key: 'carbohydrates', label: 'Carbohydrates', show: (props.type === 'provision' && getCookie('userType') === 'user') ? true : false },
        { key: 'sugar', label: 'Sugar (g)', show: (props.type === 'provision' && getCookie('userType') === 'user') ? true : false },
        { key: 'dietaryFibres', label: 'Dietary Fibres (g)', show: (props.type === 'provision' && getCookie('userType') === 'user') ? true : false },

    ];
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            props.updateCatalogue();
        }
    }
    const routeChange = () => {
        props.history.push('/supplier-catalogue-listing')
    }
    return (
        <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="row">
                {payload.map((item, index) => {
                    if (item.show) {
                        return <div className="col-sm-6">
                            <div className="form-group floating-label-wrap">
                                <input type="text" name={item.key} className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder={item.label}
                                    value={props[item.key]}
                                />
                                <lable className="foating-labels-sty">{item.label}</lable>
                            </div>
                        </div>
                    }

                })

                }


            </div>
            <div className="row">
                {
                    props.pricePerCountry && props.pricePerCountry.length > 0 ?
                        props.pricePerCountry.map((item, index) => {
                            return <>
                                <div className="col-sm-3">
                                    <div className="form-group floating-label-wrap">
                                        <input type="text" name="name" className="form-control" onChange={(e) => { props.handleFormChange(e, 'pricePerCountry', index) }} placeholder="Country Name"
                                            value={item.name}
                                        />
                                        <lable className="foating-labels-sty">Country Name</lable>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group floating-label-wrap">
                                        <input type="number" name="price" className="form-control" onChange={(e) => { props.handleFormChange(e, 'pricePerCountry', index) }} placeholder="Base Quantity"
                                            value={item.price}
                                        />
                                        <lable className="foating-labels-sty">QTD PRICE / PACKAGING UNIT</lable>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group floating-label-wrap">
                                        <input type="number" name="baseQuantity" className="form-control" onChange={(e) => { props.handleFormChange(e, 'pricePerCountry', index) }} placeholder="Base Quantity"
                                            value={item.baseQuantity}
                                        />
                                        <lable className="foating-labels-sty">Base Quantity</lable>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group floating-label-wrap">
                                        <input type="number" step="any" name="price" className="form-control" onChange={(e) => { props.handleFormChange(e, 'pricePerCountry', index) }} placeholder="Price Per Base Unit"
                                            value={item.price !== 0 ? item.price / item.baseQuantity : 0}
                                            readOnly />
                                        <lable className="foating-labels-sty">Price Per Base Unit</lable>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group floating-label-wrap">
                                        <input type="text" name="packageUnit" className="form-control" onChange={(e) => { props.handleFormChange(e, 'pricePerCountry', index) }} placeholder="Package Unit"
                                            value={item.packageUnit}
                                        />
                                        <lable className="foating-labels-sty">Package Unit</lable>
                                    </div>
                                </div>
                            </>
                        })
                        : ''
                }

            </div>
            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {props.apiCallStatus.apiCallFor === "editVessel" && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button type="button" onClick={() => routeChange()} className="btn btn themeReguler-btn bg-sky-blue mr-2">Cancel</button>
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">Update</button>
                </div>
            </div>
        </form>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    uploadReducer: state.uploadReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateCatalogue: (data, catalogueId) => dispatch(updateCatalogue(data, catalogueId)),
    getCatalogueDetail: (id) => dispatch(getCatalogueDetail(id)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCatalogue);
