import React, { useEffect, useState } from 'react';
import { Wrapper, DashboardNavbar } from '../../Common/Components/index';
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { roleListing, deleteRole } from '../ApiCalls/role'
import { connect } from 'react-redux';
import '../css/roles.css';
import DatePicker from "react-datepicker";
import loader from '../../../assets/images/Spinner-1s-200px.gif';
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from 'sweetalert2'
import { Link } from "react-router-dom";
import { getCookie, setCookie } from '../../../Services/cookies';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

function RolesListing(props) {
    const [state, setState] = useState({
        roleList: [],
        activePage: 0,
        startDate: null,
        endDate: null,
        roleName: '',
        filterQuery: '',
        totalCount: 0,
        isHitApi: false
    });
    useEffect(() => {
        if (!state.isHitApi) {
            if (props.isSubAdmin) {
                props.history.push('/not-allowed')
            } else {
                let data = { ...state };
                data.isHitApi = true;
                setState(data)
                props.roleListing(0, '')
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getRole" || props.apiCallStatus.apiCallFor === "appendRoleList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                roleList: props.roleReducer.list,
                totalCount: props.roleReducer.count,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "deleteRole" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            Swal.fire("Deleted!", "Role has been deleted!", "success");
            let data = { ...state };
            let from = moment(new Date(new Date().getTime() - (60 * 60 * 24 * 30 * 1000))).format('YYYY-MM-DD');
            let to = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
            let filterQuery = `?from=${from}&to=${to}`;
            data.isHitApi = true;
            data.from = from;
            data.to = to;
            data.filterQuery = filterQuery;
            data.activePage = 0;
            setState(data)
            props.roleListing(0, filterQuery)
            setState(data)
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "deleteRole" &&
            props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed) {
            Swal.fire("Error!", props.apiCallStatus.message, "error");
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }

    }, [props]);
    const removeRecord = (id) => {
        Swal.fire({
            title: "Delete Role",
            text: "Are you sure you want to delete this Role?",
            icon: "warning",
            buttons: true,
            showCancelButton: true,
            dangerMode: true,
        })
            .then(result => {
                if (result.isConfirmed) {
                    props.deleteRole(id)
                }
            });

    }
    const fetchMoreData = () => {
        let { activePage, filterQuery } = state;
        activePage += 1;
        setState({
            ...state,
            activePage
        })
        props.roleListing(activePage, filterQuery)
    };
    const handleChange = (id, val) => {
        setState({
            ...state,
            [id]: val,
        })
    }
    const applyFilters = () => {
        if (state.startDate !== null && state.endDate !== null) {
            let data = { ...state };
            let from = moment(data.startDate).format('YYYY-MM-DD');
            let to = moment(data.endDate).add(1, 'days').format('YYYY-MM-DD');
            let roleName = data.roleName;
            let filterQuery = `?from=${from}&to=${to}`
            if (roleName !== '') {
                filterQuery += `&roleName=${roleName}`
            }
            props.roleListing(0, filterQuery)
            data.isHitApi = true;
            data.activePage = 0;
            setState(data);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "Please select both date."
            })
        }
    }
    const clearFilters = () => {
        let data = { ...state };
        data.activePage = 0
        data.startDate = null
        data.endDate = null
        data.roleName = ''
        props.roleListing(0, '')
        data.isHitApi = true;
        data.filterQuery = '';
        setState(data);
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];

    return (
        <Wrapper>
            <div className="">
                <DashboardNavbar title="Roles Listing" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
                <div className="ordCatg-table-wrap mt-4 system-cat-filter role-content-wrap">
                    <div className="card-bx-sty">
                        <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                            <div className="fil-wrap-ctn d-flex align-items-center flex-column flex-sm-row mb-2 mb-lg-0">
                                <div className="date-range-picker d-flex align-items-center">
                                    <span className="d-inline-block mr-1">
                                        <DatePicker
                                            selected={state.startDate}
                                            onChange={(val) => handleChange('startDate', val)}
                                            maxDate={new Date()}
                                            className="link-style"
                                        />
                                    </span>
                                    to
                                        <span className="d-inline-block ml-1">
                                        <DatePicker
                                            selected={state.endDate}
                                            onChange={(val) => handleChange('endDate', val)}
                                            disabled={state.startDate === null ? true : false}
                                            minDate={state.startDate !== null ? new Date(moment(state.startDate).add(1, 'days')) : new Date(moment().add(1, 'days'))}
                                            className="link-style"
                                        />
                                    </span>
                                </div>
                                <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0 filter-searchbar">
                                    <div className="form-group">
                                        <input type="text" className="form-control" onChange={(e) => handleChange('roleName', e.target.value)} value={state.roleName} placeholder="Search by role name" />
                                    </div>
                                </div>
                            </div>
                            <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                                <button type="button" className="btn btn-yellow" onClick={() => applyFilters()} >Search</button>
                                <button type="button" className="btn btn-yellow ml-1" onClick={() => clearFilters()} >Clear</button>
                                <div className="d-flex flex-fill justify-content-end ml-1">
                                    <Link to="/addRole" className="btn btn-yellow bg-dark-green">Add Roles</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <InfiniteScroll
                    dataLength={state.roleList.length}
                    next={fetchMoreData}
                    height={"80vh"}
                    hasMore={state.totalCount <= state.roleList.length ? false : true}
                    loader={<div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div>}
                >
                    <div className="table-responsive mt-4">
                        <table className="table theme-table-wrap with-action-btns">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Role Name</th>
                                    <th scope="col">Created At </th>
                                    <th scope="col">Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(props.apiCallStatus.isStarted.indexOf("getRole") !== -1) ?
                                    <td colSpan="10">
                                        <div className="loader-img text-center ">
                                            <img style={{ width: "46px" }} src={loader} alt='' />
                                        </div>
                                    </td> : state.roleList && state.roleList.length === 0 ?
                                        <td colSpan="40" className="text-center">No Data Found</td>
                                        : state.roleList && state.roleList.length > 0 && state.roleList.map((item, index) => {
                                            return <tr key={index + 1}>
                                                <td>{index + 1}</td>
                                                <td>{item.roleName}</td>
                                                <td>{moment(item.createdAt).format("LLL")}</td>
                                                <td>
                                                    <Link to={"/edit-role/" + item._id} className="btn btn-edit-tab mr-2"></Link>
                                                    <button type="button" onClick={() => removeRecord(item._id)} className="btn btn-dlt-tab"></button>

                                                </td>
                                            </tr>

                                        })}
                            </tbody>
                        </table>
                    </div>
                </InfiniteScroll>
            </div>
        </Wrapper>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    roleReducer: state.roleReducer,
    isSubAdmin: state.authReducer.isSubAdmin
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    roleListing: (offset, filterQuery) => dispatch(roleListing(offset, filterQuery)),
    deleteRole: (roleId) => dispatch(deleteRole(roleId)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RolesListing);
