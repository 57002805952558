export const getSupplier = "GET_SUPPLIER";
export const appendSupplierList = "APPEND_SUPPLIER_LIST";
export const getSupplierDetail = "GET_SUPPLIER_DETAIL";
export const supplierComparisonList = "SUPPLIER_COMAPARISON_LIST";

export function GetSupplierAction(list, count) {
  return {
    type: getSupplier,
    payload: { list, count },
  };
}
export function AppendSupplierAction(list) {
  return {
    type: appendSupplierList,
    payload: { list },
  };
}
export function GetSupplierDetailAction(detail) {
  return {
    type: getSupplierDetail,
    payload: { detail },
  };
}

export function SupplierComparisonListAction(data) {
  return {
    type: supplierComparisonList,
    payload: { data },
  };
}
