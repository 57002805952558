import React from "react";
import Select from 'react-select';

export function Filters(props) {
    return (
        <div className="filterRow mb-3">
            {
                props.screenName !== undefined && (props.screenName === 'supplierProfile' || props.screenName === 'supplier-ranking') ?
                    '' : <div className="filterCol d-flex flex-row">
                        <span className="filterLabel d-block text-sm-right">Select<br />supplier:</span>
                        <div className="mesh-control-btns d-flex align-items-center">
                            <button type="button" className="btn btn-polygon-sty dark-pink">
                                <span className="fz-14 lineheight-normal">Send<br />RFQ</span>
                            </button>
                            <button type="button" className="btn btn-polygon-sty dark-green">
                                <span className="fz-14 lineheight-normal">Send<br />Order</span>
                            </button>
                        </div>
                    </div>
            }

            <div className="filterCol d-flex justify-content-start justify-content-xl-center align-items-sm-center align-items-start flex-column flex-sm-row">
                <span className="filterLabel d-block text-sm-right">Port:</span>
                <div className="custom-select-menu text-bx-wrap custom-select2 flex-fill">
                    <Select className="custom-select-wrap" classNamePrefix="react-select" placeholder="Port"
                        value={props.selectedPortOption || ""}
                        onChange={props.handlePortChange}
                        options={props.portOptions}
                    />
                </div>
            </div>
            <div className="filterCol d-flex flex-column flex-sm-row justify-content-end justify-content-xl-center mb-3 mb-xl-0">
                <ul className="appliedFilterList list-unstyled mb-0">
                    <li>Trained &amp; audited suppliers</li>
                    <li>Boarding rep. with delivery</li>
                    <li>No extra delivery charges</li>
                </ul>
            </div>
            <div className="filterCol d-flex flex-column flex-sm-row">
                <span className="filterLabel d-block d-xl-none text-sm-right">Sort By:</span>
                <div className="custom-select-menu text-bx-wrap custom-select2 flex-fill">
                    <Select className="custom-select-wrap" classNamePrefix="react-select" placeholder="Sort By"
                        value={props.selectedSortOption || ""}
                        onChange={props.handleSortChange}
                        options={props.sortByOptions}
                    />
                </div>
            </div>
            <div className="filterCol d-flex flex-column flex-sm-row justify-content-end justify-content-xl-center">
                <ul className="list-unstyled suppliersTypeList mr-3">
                    <li>
                        <label className="customCheckBoxLabel">Provisions
											<input type="checkbox" />
                            <span className="checkmark"></span>
                        </label>
                    </li>
                    <li>
                        <label className="customCheckBoxLabel">Stores
											<input type="checkbox" />
                            <span className="checkmark"></span>
                        </label>
                    </li>
                </ul>
                <ul className="list-unstyled suppliersTypeList">
                    <li>
                        <label className="customCheckBoxLabel">ISO/DIN certified
											<input type="checkbox" />
                            <span className="checkmark"></span>
                        </label>
                    </li>
                    <li>
                        <label className="customCheckBoxLabel">IMPA member
											<input type="checkbox" />
                            <span className="checkmark"></span>
                        </label>
                    </li>
                    <li>
                        <label className="customCheckBoxLabel">ISSA member
											<input type="checkbox" />
                            <span className="checkmark"></span>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    )
}