export const getCaptain = "GET_CAPTAIN";
export const getCaptainDetail = "GET_CAPTAIN_DETAIL";
export const AppendCaptainList="APPEND_CAPTAIN_LIST";

export function GetCaptainAction(list, count) {
  return {
    type: getCaptain,
    payload: { list, count },
  };
}
export function AppendCaptainAction(list) {
  return {
    type: AppendCaptainList,
    payload: { list },
  };
}
export function GetCaptainDetailAction(detail) {
  return {
    type: getCaptainDetail,
    payload: { detail },
  };
}