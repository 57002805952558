import React from "react";
import { Link } from "react-router-dom";
import { Constant } from "../../../../Constants/constant";
import { RatingStars } from "../../../Common/Components/ratingStars";
export function ProfileItem(props) {
    return (
        <article className="supplierItem d-flex mb-3 flex-column flex-md-row w-100">
            <div className="supplierLogoHolder mr-3">
                <img className="img-fluid" src={props.logo ? Constant.mediaUrl + props.logo.filename : ""} alt="Image" />
            </div>
            <div className="supplierDetailHolder d-flex flex-fill text-left">
                <div className="d-flex flex-fill flex-column flex-lg-row">
                    <div className="supplierDetailBlock flex-fill">
                        <div className="headingBlock d-flex mb-2 flex-column flex-xl-row">
                            <h2 className="supplierName">{props.companyName}</h2>
                            <RatingStars className="starRating" id={props.id} isShowModel={true} showRatingModal={props.showRatingModal} ratingAvg={props.ratingAvg} />
                        </div>
                        <div className="supplierDetailText">
                            <p>{props.description}</p>
                        </div>
                    </div>
                    <div className="supplierCertificateBlock d-flex flex-row flex-lg-column">
                        <div className="w-100 text-right mb-3 h-auto">
                            <span className="certificateName">ISO/DIN Certificate</span>
                        </div>
                        <div className="certificate-logo-wrap w-100 text-center text-lg-right mb-3 h-auto">
                            <img className="img-fluid" src={require('../../../../assets/images/certificate-01.png')} alt="" />
                        </div>
                        <div className="w-100 text-center text-lg-right mb-3 h-auto">
                            <img className="img-fluid" src={require('../../../../assets/images/certificate-02.png')} alt="" />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-fill align-self-end w-100">
                    <div className="d-flex flex-fill bottomLinks flex-row">
                        <Link to={"/supplier-profile/" + props.id} className="btn btn-link ml-4 mb-2 cur-poi btn-profile">View Profile</Link>
                        <Link to="yourOrder" className="btn btn-link ml-4 mb-2 cur-poi">Select supplier</Link>
                    </div>
                </div>
            </div>
        </article>
    )
}