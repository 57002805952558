import React, { useEffect, useState } from 'react';
import { Wrapper } from '../../Common/Components/CommonWrapper';
import { createPackage, getPackageCategoryDetail } from "./../ApiCalls/package";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import { getDashboardLink, isAllowPermission } from '../../../Services/common';
import  DashboardNavbar  from '../../Common/Components/dashboardNavbar';
import Swal from 'sweetalert2'
import { getRoutePathBykey } from '../../../Constants/routesConfig';
const packageInitialState = {
    name: '',
    price: '',
    discount: '',
    commision: '',
    orderFormList: [],
    serviceList: [],
    dashboardList: [],
    claimManagementList: [],
    selectedItem: [],
    errorMessage: "",
    errorType: "",
    isHitApi: false
}
function CreatePackage(props) {
    const [state, setState] = useState(packageInitialState);
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.getPackageCategoryDetail();
                let data = { ...state };
                data.isHitApi = true;
                setState(data);
            }
        }
        if (props.apiCallStatus.apiCallFor === "getPackageCategoryDetail" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let data = { ...state };
            let orderFormList = props.packageReducer.categoryList.filter((x) => x.type === 'orderForm');
            let serviceList = props.packageReducer.categoryList.filter((x) => x.type === 'service');
            let dashboardList = props.packageReducer.categoryList.filter((x) => x.type === 'dashboard');
            let claimManagementList = props.packageReducer.categoryList.filter((x) => x.type === 'claimManagement');
            data.orderFormList = orderFormList
            data.serviceList = serviceList
            data.dashboardList = dashboardList
            data.claimManagementList = claimManagementList
            setState(data)
            props.ApiClearAction();
        }
        if ((props.apiCallStatus.apiCallFor === "createPackage") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(packageInitialState)
            props.ApiClearAction();
            Swal.fire("Success", `Package has been created successfully`, "success")
            if (props.isSubAdmin && !isAllowPermission(getRoutePathBykey('packageList'), props.roles)) {
                props.history.push(getDashboardLink())
            } else {
                props.history.push('/package-listing')
            }
        }
    }, [props]);
    const handleFormChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let packageDetail = { ...state }
        packageDetail[name] = value;
        setState(packageDetail)
    }
    const createHandler = () => {
        if (state.selectedItem.length < 1) {
            setState({
                ...state,
                errorMessage: "Please select Package Features",
                errorType: "danger",
            })
        } else {
            let obj = {
                name: state.name,
                price: state.price,
                discount: state.discount,
                commision: state.commision,
                features: state.selectedItem
            }
            props.createPackage(obj)
        }

    }
    const handleCheckboxChange = (val) => {
        let arr = [...state.selectedItem];
        let index = arr.findIndex(x => x.title === val.title);
        if (index > -1) {
            arr.splice(index, 1)
        } else {
            arr.push(val)
        }
        setState({
            ...state,
            selectedItem: arr,
            errorMessage: "",
            errorType: "",
        })
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Create Package" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="formBox mt-4">
                <PackageForm {...state} history={props.history} handleFormChange={handleFormChange} createHandler={createHandler} apiCallStatus={props.apiCallStatus} handleCheckboxChange={handleCheckboxChange} />
            </div>
        </Wrapper>
    )
}

function PackageForm(props) {
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            if (props.isEdit) {
                props.updateHandler();
            } else {
                props.createHandler();
            }
        }
    }
    return (
        <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="row">
                <div className="col-sm-6">

                    <div className="form-group floating-label-wrap">
                        <input type="text" name="name" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Package Name"
                            value={props.name} required />
                        <lable className="foating-labels-sty">Package Name</lable>

                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="price" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Enter Price"
                            value={props.price} required />
                        <lable className="foating-labels-sty">Enter Price</lable>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="discount" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Enter Discount"
                            value={props.discount} required />
                        <lable className="foating-labels-sty">Enter Discount</lable>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="commision" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="CoEnter commision"
                            value={props.commision} required />
                        <lable className="foating-labels-sty">CoEnter commision</lable>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="pack-feature-wrap">
                        <div className="form-control semi-bold mb-1">
                            <label>Claim Management</label>
                        </div>
                       <div className="form-check-inline mb-2">
                           {props.claimManagementList && props.claimManagementList.length > 0 ?
                               props.claimManagementList.map((item, index) => {
                                   return <label className="form-check-label">
                                       <input type="checkbox" name={item.title} onChange={(e) => { props.handleCheckboxChange({ title: item.title, type: item.type }) }} className="form-check-input" value={item.title} />{item.title}
                                   </label>

                               }) : ''}
                       </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="pack-feature-wrap">
                        <div className="form-control semi-bold mb-1">
                            <label>Service</label>
                        </div>
                        <div className="form-check-inline mb-2">
                            {props.serviceList && props.serviceList.length > 0 ?
                                props.serviceList.map((item, index) => {
                                    return <label className="form-check-label">
                                        <input type="checkbox" name={item.title} onChange={(e) => { props.handleCheckboxChange({ title: item.title, type: item.type }) }} className="form-check-input" value={item.title} />{item.title}
                                    </label>

                                }) : ''}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="pack-feature-wrap">
                        <div className="form-control semi-bold mb-1">
                            <label>Dashboard</label>
                        </div>
                        <div className="form-check-inline mb-2">
                            {props.dashboardList && props.dashboardList.length > 0 ?
                                props.dashboardList.map((item, index) => {
                                    return <label className="form-check-label">
                                        <input type="checkbox" name={item.title} onChange={(e) => { props.handleCheckboxChange({ title: item.title, type: item.type }) }} className="form-check-input" value={item.title} />{item.title}
                                    </label>

                                }) : ''}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="pack-feature-wrap">
                        <div className="form-control semi-bold mb-1">
                            <label>Order Form</label>
                        </div>
                         <div className="form-check-inline mb-2">
                             {props.orderFormList && props.orderFormList.length > 0 ?
                                 props.orderFormList.map((item, index) => {
                                     return <label className="form-check-label">
                                         <input type="checkbox" name={item.title} onChange={(e) => { props.handleCheckboxChange({ title: item.title, type: item.type }) }} className="form-check-input" value={item.title} />{item.title}
                                     </label>

                                 }) : ''}
                         </div>
                    </div>
                </div>
            </div>

            {props.apiCallStatus.apiCallFor === "createCountry" && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                <div className="alert alert-danger">
                    {props.apiCallStatus.message}
                </div>
                : ""}
            {props.errorMessage !== "" && props.errorType !== "" ?
                <div className="alert alert-danger">
                    {props.errorMessage}
                </div>
                : ""}
            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {props.apiCallStatus.apiCallFor === "createCountry" && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">{props.isEdit ? "Update" : "Submit"}</button>
                </div>
            </div>
        </form>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    roles: state.authReducer.roles,
    packageReducer: state.packageReducer,
    isSubAdmin: state.authReducer.isSubAdmin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createPackage: (data) => dispatch(createPackage(data)),
    getPackageCategoryDetail: () => dispatch(getPackageCategoryDetail()),
    ApiClearAction: () => dispatch(ApiClearAction())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreatePackage);
