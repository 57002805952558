import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const defaultState = {
    password: "",
    confirmPassword: "",
    message: "",
    messageType: "",
    messageFor: ""
}
export function ChangePasswordModal(props) {
    const [state, setState] = useState(defaultState);
    useEffect(() => {
        if (props.apiCallStatus.apiCallFor === "changePassword") {
            if (props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
                handleModalChange();
                props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
                let msg = `Password has been changed successfully.`;
                Swal.fire("Success", msg, "success");
            } else if (props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed) {
                let cloneState = { ...state };
                cloneState.message = props.apiCallStatus.message;
                cloneState.messageType = "danger";
                cloneState.messageFor = 'changePassword';
                setState(cloneState);
                props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
            }
        }

    }, [props.apiCallStatus])
    const handleStateChange = (e) => {
        let id = e.target.id;
        let val = e.target.value;
        let cloneState = { ...state };
        cloneState[id] = val;
        setState(cloneState);
    }
    const handleModalChange = () => {
        setState(defaultState);
        props.handleModalChange('', '');

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            props.changePassword({
                password: state.password,
                userId: props.selectedUser
            })
        }
    }
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        } else if (state.password !== state.confirmPassword) {
            let cloneState = { ...state };
            cloneState.message = "Password mismatch";
            cloneState.messageType = "danger";
            cloneState.messageFor = 'changePassword';
            setState(cloneState);
            isValid = false;
        }
        return isValid;
    }
    return (
        <Modal show={props.showModal === "changePassword"} className="modal-centered" onHide={() => handleModalChange()}>
            <div className="change-header">
            <Modal.Header closeButton>
                {/* <Modal.Title>Change Password</Modal.Title> */}
                <h5 className="welcome-inn-content m-0 p-0 text-left">Change Password</h5>
            </Modal.Header>
            </div>
            <Modal.Body>
                <form className="authMeshForm resetPassForm needs-validation listing_customerpopup" onSubmit={(e) => handleSubmit(e)} noValidate>
                    <div className="form-group floating-label-wrap">
                        <input type="password" className="form-control" id="password" placeholder="Password" value={state.password} onChange={(e) => handleStateChange(e)} required />
                        <label className="foating-labels-sty">Password</label>
                        <span className="animated-border"></span>
                    </div>
                    <div className="form-group floating-label-wrap mb-0">
                        <input type="password" className="form-control" id="confirmPassword" placeholder="Confirm Password" value={state.confirmPassword} onChange={(e) => handleStateChange(e)} required />
                        <label className="foating-labels-sty">Repeat Password</label>
                        <span className="animated-border"></span>
                    </div>
                    {props.apiCallStatus.isStarted.indexOf("changePassword") !== -1 ?
                        <div className="align-items-center flex-column flex-sm-row">
                            <div className="loader-img text-center">
                                <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                            </div>
                        </div> : ""
                    }
                    {state.messageFor === "changePassword" && state.messageType !== "" && state.messageFor !== "" ?
                        <div className={"alert alert-" + state.messageType}>
                            {state.message}
                        </div>
                        : ""}
                    <div className="d-flex  align-items-center m-2 approve-button mt-3">
                        <div className="col-5 text-left ">
                            <button className="btn tb-btn-rounded red ml-2" onClick={() => handleModalChange()}>Cancel</button>
                        </div>
                        <div className="col-5 text-left">
                            <button className="btn tb-btn-rounded orng ml-2 " onClick={(e) => handleSubmit(e)}>Change Password</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}