import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetOrderAction, AppendOrderAction, GetCartAction,AppendCartAction} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function orderListing(offset, filterQuery) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendOrderList" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getOrder" }));
      dispatch(GetOrderAction([], 0));
    }
    let url = "";
    if (filterQuery !== "" || filterQuery !== undefined) {
      url = Constant.apiURl + "/orders/" + offset + "/20" + filterQuery;
    } else {
      url = Constant.apiURl + "/orders/" + offset + "/20";
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendOrderAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendOrderList",
            message: "",
          })
        );
      } else {
        dispatch(GetOrderAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getOrder",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendOrderList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getOrder",
            message: myJson.message,
          })
        );
      }
    }
  };
}

export function cartListing(offset, filterQuery) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendCartList" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCart" }));
      dispatch(GetCartAction([], 0));
    }
    let url = "";
    if (filterQuery !== "" || filterQuery !== undefined) {
      url = Constant.apiURl + "/orders/cart-list/" + offset + "/20" + filterQuery;
    } else {
      url = Constant.apiURl + "/orders/cart-list/" + offset + "/20";
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendCartAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendCartList",
            message: "",
          })
        );
      } else {
        dispatch(GetCartAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getCart",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendCartList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getCart",
            message: myJson.message,
          })
        );
      }
    }
  };
}
export function nextDelieveryDetail(offset, filterQuery) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getOrder" }));
    dispatch(GetOrderAction([], 0));
    let url = Constant.apiURl + "/orders/" + offset + "/20";
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      dispatch(GetOrderAction(myJson.data, myJson.count));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getOrder",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getOrder",
          message: myJson.message,
        })
      );
    }
  };
}
