import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import './dashboard-style.css';
import { DashboardWrapper } from './dashboarWrapper';
import { connect } from 'react-redux';
import { Constant } from "../../../Constants/constant";
import DatePicker from "react-datepicker";
import { quotationListing } from "../../Quotations/Apicalls/quotations";
import { cartListing, orderListing } from '../ApiCalls/orderListing';
import { topItemsListing } from '../../Orders/ApiCalls/provisionOrder'
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { topSupplyHub } from '../../Common/ApiCalls/topSupplyHub';
import { topSupplier } from "../../Common/ApiCalls/topSupplier";
import { vesselListing } from '../../Vessel/ApiCalls/vessel';
import { getVesselById } from '../../Common/ApiCalls/vesselList'
import { capitalizeFirstLetter } from '../../../Services/common';
// import Map from "../../Common/Components/jvectorMap";
// import { io } from 'socket.io-client';
import "react-datepicker/dist/react-datepicker.css";
import './dashboard-style.css';

function DashboardCustomer(props) {
    const [state, setState] = useState({
        orderList: [],
        topItemsList: [],
        topSupplierList: [],
        topSupplyHubList: [],
        qoutationList: [],
        vesselList: [],
        activePage: 0,
        totalOrderCount: 0,
        totalTopItems: 0,
        vesselName: '',
        isCartList: false,
        vesselPic: '',
        vesselId: '',
        vesselStatus: false,
        startDate: new Date(new Date().getTime() - (60 * 60 * 24 * 30 * 1000)),
        endDate: new Date(),
        filterQuery: '',
        isFilter: false,
        isHitApi: false,
        isFleetOverview: false,
        isTopPorts: false,
        isTopSupplier: false,
        isTopItem: false,
    });
    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        let currentVesselId = query.get('vesselId');
        if (props.userDetail.isSubAdmin && props.userDetail.userType === "user" && props.userDetail.subType !== "subAdmin" && props.userDetail.activeVesselId) {
            currentVesselId = props.userDetail.activeVesselId;
        }
        if (currentVesselId !== null && state.vesselId !== currentVesselId && props.apiCallStatus.isStarted.indexOf("getVesselById") === -1 && props.apiCallStatus.apiCallFor !== "getVesselById") {
            props.getVesselById(currentVesselId)
        }
        if (!state.isHitApi) {
            // const socket = io('http://localhost:5000?user=adeel', { transports: ['websocket'] });
            // socket.on("connect", () => {
            //     console.log(socket.id);
            // });
            // socket.emit("join", props.userDetail.userId);
            // socket.on("new message", (arg) => {
            //     console.log("new message", arg);
            // });
            let data = { ...state };
            data.isHitApi = true;
            let from = moment(data.startDate).format('YYYY-MM-DD');
            let to = moment(data.endDate).add(1, 'days').format('YYYY-MM-DD');
            let cartQuery = `?from=${from}&to=${to}&offset=${0}&limit=${5}`;
            let filterQuer = `?from=${from}&to=${to}&offset=${0}&limit=${5}`;
            let supplyHubQuery = filterQuer;
            if (props.userDetail.userType === "supplier") {
                supplyHubQuery += "&supplierId=" + props.userDetail.userId;
            } else if (props.userDetail.subType === "captain" || props.userDetail.subType === "customer") {
                supplyHubQuery += "&customerId=" + props.userDetail.userId;
            }
            props.topSupplier(supplyHubQuery)
            if (props.userDetail.userType === 'supplier') {
                cartQuery += `&supplierId=${props.userDetail.userId}`
                props.cartListing(0, cartQuery)
            } else if (props.userDetail.isSubAdmin && props.userDetail.userType === 'user' && props.userDetail.subType === 'captain') {
                cartQuery += `&captainId=${props.userDetail.userId}`
                props.cartListing(0, cartQuery)
            } else if (props.userDetail.userType === 'user' && (props.userDetail.subType === 'user' || props.userDetail.subType === 'subAdmin')) {
                props.cartListing(0, cartQuery)
            }
            props.topItemsListing(0, 4, `?from=${from}&to=${to}${(props.userDetail.subType === "captain" || props.userDetail.subType === "customer") ? `&userId=${props.userDetail.userId}` : props.userDetail.userType === 'supplier' ? `&supplierId=${props.userDetail.userId}` : ''}`)
            props.topSupplyHub(supplyHubQuery)
            if (currentVesselId === null && !data.isFilter) {
                props.vesselListing(0);
            }
            props.quotationListing(0, 'rfq', true);
            setState(data);
        }
        if ((props.apiCallStatus.apiCallFor === "getCart" || props.apiCallStatus.apiCallFor === "getOrder") &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                orderList: props.apiCallStatus.apiCallFor === "getCart" ? props.dashboardReducer.cartListing : props.dashboardReducer.orderListing,
                totalOrderCount: props.apiCallStatus.apiCallFor === "getCart" ? props.dashboardReducer.totalCart : props.dashboardReducer.totalOrder,
                isCartList: props.apiCallStatus.apiCallFor === "getCart" ? true : false,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if ((props.apiCallStatus.apiCallFor === "getTopItems") &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                topItemsList: props.orderReducer.topItemsList,
                totalTopItems: props.orderReducer.totalTopItems,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "topSupplyHub" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                topSupplyHubList: props.commonReducer.topSupplyHubList,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "topSupplier" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                topSupplierList: props.commonReducer.topSupplierList,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (currentVesselId === null && (props.apiCallStatus.apiCallFor === "vesselListing" || props.apiCallStatus.apiCallFor === "appendVesselList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let index = props.vesselList.findIndex(x => x.active === true);
            if (index === -1 && props.vesselList.length > 0) {
                index = 0;
            }
            setState({
                ...state,
                vesselName: props.vesselList.length > 0 ? props.vesselList[index].name : '',
                vesselPic: props.vesselList.length > 0 ? props.vesselList[index].pic : '',
                vesselId: props.vesselList.length > 0 ? props.vesselList[index]._id : '',
                vesselStatus: props.vesselList.length > 0 ? props.vesselList[index].active : false,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if ((props.apiCallStatus.apiCallFor === "getQuotation") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                qoutationList: props.quotationList.slice(0, 5)
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "getVesselById" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            if (state.vesselId !== props.commonReducer.vesselById._id && Object.keys(props.commonReducer.vesselById).length > 0) {
                setState({
                    ...state,
                    vesselName: props.commonReducer.vesselById.name,
                    vesselPic: props.commonReducer.vesselById.pic,
                    vesselId: props.commonReducer.vesselById._id,
                    vesselStatus: props.commonReducer.vesselById.active,
                    isHitApi: true
                })
                props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
            }
        }
    }, [props, state.isHitApi])
    const handleChange = (id, val) => {
        setState({
            ...state,
            [id]: val,
            isHitApi: false,
            isFilter: true
        })
    }
    const navbarOptions = [
        {
            img: 'Cancel.svg',
            className: "red",
            name: "Logout",
            path: "/",
            isLogout: true
        },
        // {
        //     img: 'Shopping-cart.svg',
        //     className: "dark-blue " + (state.vesselId === '' ? 'not-allowed' : ''),
        //     name: "New<br />order",
        //     path: "/create-provision-order/" + state.vesselId,
        //     isLogout: false,
        //     isCustom: state.vesselId === '' ? true : false,
        //     onClick: () => { }
        // },
        // {
        //     img: 'Shopping-cart.svg',
        //     className: "green " + (state.vesselId === '' ? 'not-allowed' : ''),
        //     name: "Provisions<br />order",
        //     path: "/provision-catalogue/" + state.vesselId,
        //     isLogout: false,
        //     isCustom: state.vesselId === '' ? true : false,
        //     onClick: () => { }
        // }
    ];
    const collapsHandle = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }
    return (
        <DashboardWrapper title={(props.userDetail.isSubAdmin && props.userDetail.userType === 'user' && props.userDetail.subType !== 'sub-admin') ? `${props.userDetail.roleName} Dashboard` : props.userDetail.userType === 'supplier' ? 'Supplier Dashboard' : props.userDetail.userType === 'customer' ? 'Customer Dashboard' : 'Admin Panel'} navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end">
            {/* Body Content */}
            <div className="mt-lg-5 d-flex contract-dash-wrap cont-ves-wrap">
                <div className="dashVesl-overview-wrap">
                    <div className="content-box-wrap dash-content-bx">
                        <div className="vessel-overview-wrap d-flex align-items-start">
                            <div className="content-box-head d-flex align-items-center justify-content-end head-transparent w-100 mb-3 flex-wrap">
                                {/* <h2 className="font-weight-bold dark-clr mr-3">OPERATING FIGURES</h2> */}
                                <div className="date-range-picker d-flex align-items-center">
                                    <span className="d-inline-block mr-1">
                                        <DatePicker
                                            selected={state.startDate}
                                            onChange={(val) => handleChange('startDate', val)} className="link-style"
                                        />
                                    </span>
                                    to
                                    <span className="d-inline-block ml-1">
                                        <DatePicker
                                            selected={state.endDate}
                                            onChange={(val) => handleChange('endDate', val)} className="link-style"
                                        />
                                    </span>
                                </div>
                                {/* <button type="button" className="btn btn-link d-flex align-items-center p-0 dark-clr">View complete list</button> */}
                            </div>
                        </div>
                        <div className="row dash-content-bx-columns dashborddemo">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <div className="dash-chartInfo-table contr-figure-info dropdown">
                                        {/* <Link to="/port-listing?topPort=1" className="mb-2 figureTitle dark-pink-clr">Top Ports</Link> */}
                                        <div className="">
                                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                                <thead>
                                                    <tr>
                                                        <th onClick={() => collapsHandle('isFleetOverview', !state.isFleetOverview)}>
                                                            <span className="dropdown-icon d-inline-block">
                                                                <i className="fa fa-angle-right"></i>
                                                            </span>
                                                        </th>
                                                        <th>Top Vessels</th>
                                                        <th>Vessel name</th>
                                                        <th>Vessel type</th>
                                                        <th>Rate</th>
                                                        <th>Number of Crew</th>
                                                    </tr>
                                                </thead>
                                                <tbody className={`custom-dropdown-menu ${state.isFleetOverview ? 'show active' : ''}`}>
                                                    {/* <div className={`custom-dropdown-menu ${state.isFleetOverview?'show':''}`}> */}
                                                    <tr>

                                                        <td>&nbsp;</td>
                                                        {/* <td rowSpan="3">
																{/* Date Range Picker */}
                                                        {/* <div className="date-range-picker d-flex  flex-column">
                                                                <div className="mb-1 d-block">From</div>
																	<span className="mb-1 d-block">
																		<DatePicker
																			selected={state.startDate}
																			onChange={(val) => handleChange('startDate', val)} className="link-style"
																		/>
																	</span>
																	<div className="mb-1 d-block">Untill</div>
																	<span className="mb-1 d-block">
																		<DatePicker
																			selected={state.endDate}
																			onChange={(val) => handleChange('endDate', val)} className="link-style"
																		/>
																	</span>
																</div> */}
                                                        {/* </td> */}
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td className="dark-pink-clr">8,30</td>
                                                        <td>Stores budget</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td className="dark-pink-clr">8,30</td>
                                                        <td>Stores budget</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td className="dark-pink-clr">8,30</td>
                                                        <td>Stores budget</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="9">
                                                            <div className="d-flex justify-content-end">
                                                                <a className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {/* </div> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <div className="dash-chartInfo-table contr-figure-info dropdown">
                                        {/* <Link to="/port-listing?topPort=1" className="mb-2 figureTitle dark-pink-clr">Top Ports</Link> */}
                                        <div className="">
                                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                                <thead>
                                                    <tr>
                                                        <th onClick={() => collapsHandle('isTopPorts', !state.isTopPorts)}>
                                                            <span className="dropdown-icon d-inline-block">
                                                                <i className="fa fa-angle-right"></i>
                                                            </span>
                                                        </th>
                                                        <th>Top Ports</th>
                                                        <th>Port name</th>
                                                        <th>Country</th>
                                                        <th>Number of supplies</th>
                                                        <th>Top Suppliers</th>
                                                    </tr>
                                                </thead>
                                                <tbody className={`custom-dropdown-menu ${state.isTopPorts ? 'show' : ''}`}>
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td>Actual rate</td>
                                                        <td>Stores budget</td>
                                                        <td>Remaining budget</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td>Actual rate</td>
                                                        <td>Stores budget</td>
                                                        <td>Remaining budget</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td>Actual rate</td>
                                                        <td>Stores budget</td>
                                                        <td>Remaining budget</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="9">
                                                            <div className="d-flex justify-content-end">
                                                                <a className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row dash-content-bx-columns dashborddemo">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <div className="dash-chartInfo-table contr-figure-info dropdown">
                                        {/* <Link to="/port-listing?topPort=1" className="mb-2 figureTitle dark-pink-clr">Top Ports</Link> */}
                                        <div className="">
                                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                                <thead>
                                                    <tr>
                                                        <th onClick={() => collapsHandle('isTopSupplier', !state.isTopSupplier)}>
                                                            <span className="dropdown-icon d-inline-block">
                                                                <i className="fa fa-angle-right"></i>
                                                            </span>
                                                        </th>
                                                        <th>Top Suppliers</th>
                                                        <th>Supplier name</th>
                                                        <th>Country</th>
                                                        <th>Number of supplies</th>
                                                        <th>Top Vessels</th>
                                                        <th>Top Customers</th>
                                                        <th>Top Ports</th>
                                                    </tr>
                                                </thead>
                                                <tbody className={`custom-dropdown-menu ${state.isTopSupplier ? 'show' : ''}`}>
                                                    {/* <div className={`custom-dropdown-menu ${state.isFleetOverview?'show':''}`}> */}
                                                    <tr>
                                                        <td>&nbsp;</td>
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td>Actual rate</td>
                                                        <td>Stores budget</td>
                                                        <td>Remaining budget</td>
                                                        <td>Next supply date</td>
                                                        <td>Delivery place</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td>Actual rate</td>
                                                        <td>Stores budget</td>
                                                        <td>Remaining budget</td>
                                                        <td>Next supply date</td>
                                                        <td>Delivery place</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td>Actual rate</td>
                                                        <td>Stores budget</td>
                                                        <td>Remaining budget</td>
                                                        <td>Next supply date</td>
                                                        <td>Delivery place</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="11">
                                                            <div className="d-flex justify-content-end">
                                                                <a className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row dash-content-bx-columns dashborddemo">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <div className="dash-chartInfo-table contr-figure-info dropdown">
                                        {/* <Link to="/port-listing?topPort=1" className="mb-2 figureTitle dark-pink-clr">Top Ports</Link> */}
                                        <div className="">
                                            <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                                                <thead>
                                                    <tr>
                                                        <th onClick={() => collapsHandle('isTopItem', !state.isTopItem)}>
                                                            <span className={`dropdown-icon ${state.isTopItem ? 'active' : ''} d-inline-block`}>
                                                                <i className="fa fa-angle-right "></i>
                                                            </span>
                                                        </th>
                                                        <th>Top Items</th>
                                                        <th>Item no</th>
                                                        <th>Item description</th>
                                                        <th>Item group</th>
                                                        <th>Number of supplies</th>
                                                        <th>Top Suppliers</th>
                                                        <th>Top Ports</th>
                                                    </tr>
                                                </thead>
                                                <tbody className={`custom-dropdown-menu ${state.isTopItem ? 'show' : ''}`}>
                                                    <tr>

                                                        <td>&nbsp;</td>
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td>Actual rate</td>
                                                        <td>Stores budget</td>
                                                        <td>Remaining budget</td>
                                                        <td>Next supply date</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td>Actual rate</td>
                                                        <td>Stores budget</td>
                                                        <td>Remaining budget</td>
                                                        <td>Next supply date</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td>Vessel name</td>
                                                        <td>Vessel type</td>
                                                        <td>Actual rate</td>
                                                        <td>Stores budget</td>
                                                        <td>Remaining budget</td>
                                                        <td>Next supply date</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="10">
                                                            <div className="d-flex justify-content-end">
                                                                <a className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr">View all</a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 
                        <div className="dash-chartInfo-table contr-figure-info mb-0 h-auto-adj d-flex flex-column">
                            {/* <Link to={state.topItemsList.length > 0 ? "/catalogue-overview" : '#'} className="mb-2 figureTitle dark-pink-clr">Top Items</Link> */}
                        {/* <div className="table-in-dash">
                                <table className="table theme-table-wrap2 short-table th-bd-none">
                                    <thead>
                                        <tr>
                                            <th className="small-unit90">Item Code</th>
                                            <th>Category</th>
                                            <th>unit</th>
                                            <th>Description</th>
                                            <th>Sub Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.apiCallStatus.isStarted.indexOf("getTopItems") !== -1 ?
                                            <tr><td colSpan="20"><div className="loader-img text-center">
                                                <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                            </div></td></tr> : state.topItemsList && state.topItemsList.length === 0 ?
                                                <tr><td colSpan="20" className="text-center"><div>No Data Found</div></td></tr>
                                                : state.topItemsList && state.topItemsList.length > 0 && state.topItemsList.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.itemCode}</td>
                                                            <td>{item.category}</td>
                                                            <td className="semi-bold">{item.standardPackagingUnit}</td>
                                                            <td>{item.description}</td>
                                                            <td>{item.subCategory}</td>
                                                        </tr>
                                                    )
                                                })}
                                    </tbody>
                                </table>
                            </div>
                            {state.topItemsList && state.topItemsList.length > 0 ?
                                <div className="d-flex justify-content-end mt-2 flex-fill align-items-end">
                                    <Link to="/catalogue-overview" className="btn btn-link d-flex align-items-center p-0 dark-pink-clr fz-14">View all</Link> */}
                        {/* </div>  */}
                        {/* </div>  */}
                    </div>
                </div>
            </div>
            {/* Order Status Content */}
            <div className="row mt-lg-3 align-content-stretch newDash-contentStyle dash-rfq-status-info">
                <OrderStatusWrapper apiCallStatus={props.apiCallStatus} label="Current Orders" link={state.orderList.length === 0 ? "#" : "/orderOverview"}>
                    {(props.apiCallStatus.isStarted.indexOf("getCart") !== -1 || props.apiCallStatus.isStarted.indexOf("getOrder") !== -1) ?
                        <tr><td colSpan="20"><div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div></td></tr> : state.orderList && state.orderList.length === 0 ?
                            <tr><td colSpan="40" className="text-center"><div>No Data Found</div></td></tr>
                            : state.orderList.map((item, index) => {
                                return (
                                    state.isCartList ? <tr key={index}>
                                        <td>{capitalizeFirstLetter(item.type)}</td>
                                        <td>{moment(item.createdAt).format("Do MMM YYYY")}</td>
                                        <td>{item.orderStatus === 'pending' ?
                                            <><span className="sta-indicator pend"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : item.orderStatus === 'confirmed' ? <><span className="sta-indicator appr"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : item.orderStatus === 'pastorder' ? <><span className="sta-indicator past"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : item.orderStatus}</td>
                                        <td>{item.totalPrice ? parseFloat(item.totalPrice.toFixed(2)) : '0'}</td>
                                    </tr> :
                                        <tr key={index}>
                                            <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>
                                            <td>{moment(item.createdAt).format("Do MMM YYYY")}</td>
                                            <td>{item.status === 'inprogress' ?
                                                <><span className="sta-indicator pend"></span> <span className="sta-text-wrap text-truncate"></span>
                                                </> : item.status === 'confirmed' ? <><span className="sta-indicator appr"></span> <span className="sta-text-wrap text-truncate"></span>
                                                </> : item.status === 'pastorder' ? <><span className="sta-indicator past"></span> <span className="sta-text-wrap text-truncate"></span>
                                                </> : item.status}</td>
                                            <td>{item.totalPrice ? parseFloat(item.totalPrice.toFixed(2)) : '0'}</td>
                                        </tr>
                                )
                            })}
                </OrderStatusWrapper>
                <OrderStatusWrapper apiCallStatus={props.apiCallStatus} label="Current RFQ's" link={state.qoutationList.length === 0 ? "#" : "/inprogress-quotations"}>
                    {props.apiCallStatus.isStarted.indexOf("getQuotation") !== -1 ?
                        <tr><td colSpan="20"><div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div></td></tr> : state.qoutationList && state.qoutationList.length === 0 ?
                            <tr><td colSpan="40" className="text-center"><div>No Data Found</div></td></tr>
                            : state.qoutationList.map((item, index) => {
                                let status = props.userDetail.userType === "supplier" ? item.status : item.orderDetail.orderStatus;
                                return (
                                    <tr key={index}>
                                        <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>
                                        <td>{moment(item.createdAt).format("Do MMM YYYY")}</td>
                                        <td>{(status === 'in-progress' || status === 'pending') ?
                                            <><span className="sta-indicator pend"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : status === 'confirmed' ? <><span className="sta-indicator appr"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : status === 'pastorder' ? <><span className="sta-indicator past"></span> <span className="sta-text-wrap text-truncate"></span>
                                            </> : status}</td>
                                        {/* <td>{item.orderDetail.totalPrice ? item.orderDetail.totalPrice.toFixed(0) : '0'}</td> */}
                                        <td>{item.orderDetail.currency}</td>
                                    </tr>
                                )
                            })}
                </OrderStatusWrapper>
                {/* <div className="col-lg-5">
                    <div className="content-box-wrap dash-content-bx">
                        <div className="content-box-head d-flex align-items-center justify-content-between">
                            <h2 className="font-weight-bold">Next Delivery</h2>
                        </div>
                        <div className="dash-map-content-wrap">
                            <h2><span>Hamburg, Germany </span> <span>2017-20-10</span></h2>
                            <div className="dash-map-content-wrap">
                                <div className="map-bx-wrap d-flex">

                                    <Map containerStyle={{
                                        width: '100%',
                                    }} />
                                </div>

                            </div>
                            <div className="map-bx-wrap">
                                <img className="img-fluid ml-2" src={require('../../../assets/images/Map.svg')} alt="icon" />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
        </DashboardWrapper >
    )
}

function OrderStatusWrapper(props) {
    return <div className="col-md-12 mb-2 current-orders">
        <div className="content-box-wrap dash-content-bx mt-3 ">
            <div className="status-wrap-head content-box-head d-flex align-items-center flex-nowrap overflow-auto">
                <div className="flex-fill justify-content-start "><h2>Current orders</h2></div>
                <div className="d-flex">
                    <h2><span className="sta-indicator pend"></span><span className="sta-text-wrap text-truncate">Pending</span></h2>
                    <h2><span className="sta-indicator appr"></span><span className="sta-text-wrap text-truncate">Confirmed</span></h2>
                    <h2><span className="sta-indicator past"></span><span className="sta-text-wrap text-truncate">Delivered</span></h2>
                    <h2><span className="sta-indicator declined"></span><span className="sta-text-wrap text-truncate">Declined / Cancelled</span></h2>
                </div>
                <div className="flex-fill justify-content-end d-flex">
                    <a className="btn btn-link d-flex align-items-center p-0 white-clr fz-14">View all</a>
                </div>
            </div>
            <div className="flex-fill dash-ord-content ordContent-table cust-turnover text-left">
                <div className="table-responsive">
                    <table className="table mt-4 table-light1 theme-table-wrap2 short-table">
                        <tbody>
                            {/* {props.children} */}
                            <tr>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>
                                    <span className="sta-indicator appr"></span><span className="sta-text-wrap text-truncate"></span>
                                </td>
                            </tr>
                            <tr>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>
                                    <span className="sta-indicator pend"></span><span className="sta-text-wrap text-truncate"></span>
                                </td>
                            </tr>
                            <tr>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>
                                    <span className="sta-indicator pend"></span><span className="sta-text-wrap text-truncate"></span>
                                </td>
                            </tr>
                            <tr>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>MVcontainership-2</td>
                                <td>
                                    <span className="sta-indicator rfq"></span><span className="sta-text-wrap text-truncate"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
}
const OrderButtonWrapper = React.memo(props => {
    let isProvisionDisable = props.userDetail.isSubAdmin && props.userDetail.subType !== 'sub-admin' && props.userDetail.permission && props.userDetail.permission.provisionOrder && props.userDetail.permission.provisionOrder.length === 0;
    let isStoreDisable = props.userDetail.isSubAdmin && props.userDetail.subType !== 'sub-admin' && props.userDetail.permission && props.userDetail.permission.department && props.userDetail.permission.department.length === 0;
    return <div className="content-box-wrap dash-content-bx mb-2 d-flex flex-column w-100">
        <div className="content-box-head ord-status-head d-flex align-items-center justify-content-between">
            <h2 className="font-weight-bold">MESH* YOUR NEW ORDER:</h2>
        </div>
        {/* Order buttons wrap */}
        <div className="dashOrder-buttons-wrap d-flex flex-column justify-content-center align-items-center flex-fill">
            {(props.vesselStatus && props.vesselId !== "") ?
                <>
                    <Link to={isProvisionDisable ? '#' : "/create-provision-order/" + props.vesselId} className={`btn themeReguler-btn bg-dark-green ${isProvisionDisable ? "not-allowed btn-disable" : ""}`} >PROVISION ORDER</Link>
                    <Link to={isStoreDisable ? "#" : "/create-store-order/" + props.vesselId} className={`btn themeReguler-btn bg-dark-blue ${isStoreDisable ? "not-allowed btn-disable" : ""}`}>STORE ORDER</Link>
                </> : <>
                    <button className="btn themeReguler-btn bg-dark-green not-allowed" >PROVISION ORDER</button>
                    <button className="btn themeReguler-btn bg-dark-blue not-allowed" >STORE ORDER</button>
                </>}

            <button type="button" className="btn themeReguler-btn bg-dark-yellow">SPARE PARTS</button>
            <button type="button" className="btn themeReguler-btn bg-dark-pink">CONTRACTED</button>
        </div>
    </div>
});

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    dashboardReducer: state.dashboardReducer,
    orderReducer: state.orderReducer,
    commonReducer: state.commonReducer,
    supplierReducer: state.supplierReducer,
    quotationList: state.quotationReducer.quotationList,
    vesselList: state.vesselReducer.list,
    userDetail: {
        userType: state.authReducer.userType,
        permission: state.authReducer.permission,
        isSubAdmin: state.authReducer.isSubAdmin,
        roleName: state.authReducer.roleName,
        userId: state.authReducer.userId,
        activeVesselId: state.authReducer.activeVesselId,
        subType: state.authReducer.subType
    }

})

const mapDispatchToProps = (dispatch, ownProps) => ({
    cartListing: (offset, filterQuery) => dispatch(cartListing(offset, filterQuery)),
    orderListing: (offset, filterQuery) => dispatch(orderListing(offset, filterQuery)),
    topSupplier: (filterQuery) => dispatch(topSupplier(filterQuery)),
    topItemsListing: (offset, limit, filterQuery) => dispatch(topItemsListing(offset, limit, filterQuery)),
    topSupplyHub: (filterQuery) => dispatch(topSupplyHub(filterQuery)),
    vesselListing: (offset) => dispatch(vesselListing(offset)),
    getVesselById: (vesselId) => dispatch(getVesselById(vesselId)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    quotationListing: (offset, type, isDetail, captainId) => dispatch(quotationListing(offset, type, isDetail, captainId))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardCustomer);