import * as Action from "./../Actions/action";
import InitialState from "./../../../Constants/initialState";
import cloneDeep from "lodash/cloneDeep";
export default function PackageReducer(state = InitialState.package, action) {
    switch (action.type) {
        case Action.getPackage:
            let s = cloneDeep(state);
            s.list = action.payload.list;
            s.count = action.payload.count;
            return s;
        case Action.AppendPackage:
            var s1 = cloneDeep(state);
            var arr = s1.list.slice();
            s1.list = arr.concat(action.payload.list);
            return s1;
        case Action.getPackageDetail:
            let s2 = cloneDeep(state);
            s2.detail = action.payload.detail;
            return s2;
        case Action.getPackageCategory:
            let s3 = cloneDeep(state);
            s3.categoryList = action.payload.list;
            return s3;
        default:
            return state;
    }
}