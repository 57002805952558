import React from "react";
import { Link } from "react-router-dom";
import { Constant } from "../../../../Constants/constant";
import { RatingStars } from "../../../Common/Components/ratingStars";


export function ProfileDetail(props) {
    return (
        <article className="supplierProfileItem d-flex mb-3">
            <div className="d-flex mb-4 flex-column flex-md-row">
                <div className="supplierLogoHolder mr-3">
                    <img className="img-fluid" src={props.logo ? Constant.mediaUrl + props.logo[0].filename : ""} alt="Image" />
                </div>
                <div className="supplierDetailHolder d-flex text-left w-100">
                    <div className="d-flex flex-fill">
                        <div className="supplierDetailBlock d-flex flex-column justify-content-start align-items-start flex-fill">
                            <div className="headingBlock d-flex mb-4 flex-column flex-lg-row flex-fill justify-content-between w-100">
                                <h2 className="supplierName">{props.companyName}</h2>
                                <div className="ratingLogosHolder">
                                    <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                                        <RatingStars isEdit={true} className="starRating" id={props.id} ratingAvg={props.ratingAvg} />
                                        <div className="supplierCertificateBlock d-flex flex-row align-items-center">
                                            <div className="text-center mb-1 mr-2 ml-sm-2 ml-0">
                                                <span className="certificateName">ISO/DIN Certificate</span>
                                            </div>
                                            <div className="certificate-logo-wrap text-center mb-1 mx-2">
                                                <img className="img-fluid" src={require('../../../../assets/images/certificate-01.png')} alt="" />
                                            </div>
                                            <div className="certificate-logo-wrap text-center mb-1 mx-2">
                                                <img className="img-fluid" src={require('../../../../assets/images/certificate-02.png')} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <span className="preferredSupplier">approx. 10.000 prices available</span>
                                </div>
                            </div>
                            <div className="d-flex align-items-left align-items-lg-center  justify-content-between flex-fill flex-column flex-lg-row w-100">
                                <div className="profileProgressHolder d-flex w-100 mb-2">
                                    <div className="progressBarHolder d-flex">
                                        <div className="w-100 progressStep cleared"></div>
                                        <div className="w-100 progressStep cleared"></div>
                                        <div className="w-100 progressStep cleared"></div>
                                        <div className="w-100 progressStep cleared"></div>
                                        <div className="w-100 progressStep cleared"></div>
                                        <div className="w-100 progressStep"></div>
                                        <div className="w-100 progressStep"></div>
                                    </div>
                                    <span className="completionArrow">
                                        <span className="completionArrowCircle d-block h-100">&nbsp;</span>
                                    </span>
                                </div>
                                {/* link */}
                                <div className="webUrlHolder d-flex justify-content-start justify-content-lg-end px-lg-2 flex-fill ">
                                    <Link to={props.websiteUrl} className="webUrl" target="_blank">{props.websiteUrl}</Link>
                                </div>
                            </div>
                            <ul className="supplierMainItems list-unstyled text-uppercase mb-2 mt-md-4">
                                <li>Deck</li>
                                <li>Engine</li>
                                <li>Cabin</li>
                                <li>Hand &amp; Power Tools</li>
                                <li>Uniform &amp; PPE</li>
                                <li>Provision</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <div className="profileTextHolder">
                <p>{props.description}</p>
            </div>
            <div className="supplierImagesHolder d-flex flex-column flex-md-row">
                <div className="supplierImagesBlock d-flex flex-fill flex-nowrap overflow-auto">
                    {props.images ? props.images.map((item, index) => {
                        return <div key={index} className="supplierImageHolder mr-md-2 mb-2 px-2 px-md-0 text-center">
                            <img className="img-fluid" src={Constant.mediaUrl + item.filename} alt="" />
                        </div>
                    }) : ""}
                </div>
                {/* <div className="selectOrderText align-self-center align-self-md-end justify-content-md-end justify-content-center d-flex">
                    <Link to={`/send/${props.selectionType}/${props.selectedItem}/${props.currentId}`} className="btn btn-link">Select for Order</Link>
                </div> */}
            </div>
        </article>
    )
}