import React from 'react';
import { Link } from "react-router-dom";
import '../css/roles.css';

function Roles() {
	return (
        <div className="rolesOuter">
			<Link to="/login" className="goBackButton polygonGreen text-center d-flex">
				<div className="goBackButtonContent">
					<img src={require('../../../assets/images/left-arrow.svg')} alt="Icon" />
					<span className="btnText w-100 d-block text-white text-center">Go back</span>
				</div>
			</Link>
			<div className="rolesBlockHolder d-flex h-100">
				<div className="rolesHolder">
					<ul className="rolesIconsHolder list-unstyled text-center w-100">
						<li className="active">
							<div className="rolesIconHolder">
								<div className="roleIcon w-100">
									<img className="img-fluid" src={require('../../../assets/images/user.svg')} alt="Icon" />
								</div>
								<span className="roleName d-block">Cook</span>
							</div>
						</li>
						<li>
							<div className="rolesIconHolder">
								<div className="roleIcon w-100">
									<img className="img-fluid" src={require('../../../assets/images/user.svg')} alt="Icon" />
								</div>
								<span className="roleName d-block">Captain</span>
							</div>
						</li>
						<li>
							<div className="rolesIconHolder">
								<div className="roleIcon w-100">
									<img className="img-fluid" src={require('../../../assets/images/user.svg')} alt="Icon" />
								</div>
								<span className="roleName d-block">Chief engineer</span>
							</div>
						</li>
					</ul>
					<div className="role-foot-btns d-flex align-items-center justify-content-between">
						<Link to="/orderDetails" className="btn btn-theme-blue">Mesh provisions</Link>
						<Link to="/create-store-order" className="btn btn-theme-blue">Mesh  Stores</Link>
					</div>
				</div>
			</div>
			<div className="rolesLogoholder">
				<img className="img-fluid" src={require('../../../assets/images/logo.png')} alt="Logo" />
			</div>
		</div>
	);
}

export default Roles;