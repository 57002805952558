import React from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getCookie } from '../../../../Services/cookies';
import CustomSelect from '../../../Common/Components/CustomSelect';
import "react-datepicker/dist/react-datepicker.css";

const getSelectStyle = (maxHeight) => {
    return {
        menuList: (provided, state) => ({
            ...provided,
            maxHeight: maxHeight
        })
    }
}

export function QuestionnarieForm(props) {
    return (
        <>
            {props.activeTab === 1 ? <GeneralInfo {...props} /> :
                props.activeTab === 2 ? <CrewInfo {...props} /> :
                    props.activeTab === 3 ? <OperationInfo {...props} /> :
                        props.activeTab === 4 ? <SupplyPorts {...props} /> :
                            props.activeTab === 5 ? <Department {...props} /> :
                                props.activeTab === 6 ? <InvoiceInfo {...props} /> :
                                    props.activeTab === 7 ? <ConfigInfo {...props} changeHandler={props.changeHandler} totalCategoriesDetail={props.totalCategoriesDetail} updateConfigCat={props.updateConfigCat} rate={props.crewInformation.rate} /> : <SuccessMessage {...props} />}
        </>
    )
}

function GeneralInfo(props) {
    const extractFileName = (data) => {
        let val = "";
        if (typeof data === 'string') {
            val = data
        } else {
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                val += (element.filename ? element.filename : element.name ? element.name : "");
                if (data.length !== 1 && index !== data.length - 1) {
                    val += ", "
                }
            }
        }
        return val;
    }
    const CustomSelectHandler = (e, obj) => {
        if (obj || obj === "") {
            props.changeHandler(e, obj)
        }
    }
    return <fieldset>
        <form className="needs-validation-basic" onSubmit={(e) => props.submitHandler(e, 'basicInfo')} noValidate>
            <>
                <div className="form-card">
                    <h2 className="fs-title">General Information</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group floating-label-wrap">
                                <input type="text" className="form-control" placeholder="Vessel Name" value={(props.basicInfo && props.basicInfo.name) ? props.basicInfo.name : ''} onChange={(e) => props.changeHandler(e, 'basicInfo')} name="name" required />
                                <label className="foating-labels-sty">Vessel Name</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group floating-label-wrap">
                                <input type="text" className="form-control" name="alternativeName" value={(props.basicInfo && props.basicInfo.alternativeName) ? props.basicInfo.alternativeName : ''} onChange={(e) => props.changeHandler(e, 'basicInfo')} placeholder="Alternative Name" />
                                <label className="foating-labels-sty">Alternative Name</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group floating-label-wrap">
                                <input type="number" className="form-control" placeholder="IMO-No." onChange={(e) => props.changeHandler(e, 'basicInfo')} value={(props.basicInfo && props.basicInfo.imo) ? props.basicInfo.imo : ''} name="imo" required />
                                <label className="foating-labels-sty">IMO-No.</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group floating-label-wrap">
                                <input type="text" className="form-control" name="build" onChange={(e) => props.changeHandler(e, 'basicInfo')} value={(props.basicInfo && props.basicInfo.build) ? props.basicInfo.build : ''} placeholder="Build" required />
                                <label className="foating-labels-sty">Build</label>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group floating-label-wrap">
                                <input type="text" className="form-control" placeholder="Tdw / TEU" onChange={(e) => props.changeHandler(e, 'basicInfo')} value={(props.basicInfo && props.basicInfo.tdw) ? props.basicInfo.tdw : ''} name="tdw" required />
                                <label className="foating-labels-sty">Tdw / TEU</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group d-flex align-items-center select-z-Index">
                                <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                    <Select
                                        className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Vessel Kind"
                                        value={Object.keys(props.basicInfo).length > 0 && props.basicInfo.kindOfVessel ? props.basicInfo.kindOfVessel : null}
                                        onChange={(e) => { props.changeHandler(e, 'basicInfo', 'kindOfVessel', 'selectHandler') }}
                                        options={props.vesselKindOption}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group d-flex align-items-center select-z-Index">
                                <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                    <Select
                                        className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Gear"
                                        value={Object.keys(props.basicInfo).length > 0 && props.basicInfo.gear ? props.basicInfo.gear : null}
                                        onChange={(e) => { props.changeHandler(e, 'basicInfo', 'gear', 'selectHandler') }}
                                        options={props.gearOption}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group form-input-container">
                                <input type="file" id="pic" name="pic" className="form-control" accept="image/jpg, image/jpeg, image/png" onChange={(e) => props.changeHandler(e, 'basicInfo')} placeholder="PIC" />
                                <label htmlFor="pic" className="d-flex align-items-center"><span className="text-truncate">{(props.basicInfo && props.basicInfo.pic) && props.basicInfo.pic.length > 0 ? extractFileName(props.basicInfo.pic) : "Pic"}</span></label>
                            </div>
                        </div>
                    </div>
                    {/* {
                        props.userType === 'user' ?
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group d-flex align-items-center select-z-Index">
                                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                            <Select
                                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Cook"
                                                value={Object.keys(props.basicInfo).length > 0 && props.basicInfo.cook ? props.basicInfo.cook : null}
                                                onChange={(e) => { props.changeHandler(e, 'basicInfo', 'cook', 'selectHandler') }}
                                                options={props.cookOption}
                                                isMulti={true}
                                                closeMenuOnSelect={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group d-flex align-items-center select-z-Index">
                                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                            <Select
                                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Captain"
                                                value={Object.keys(props.basicInfo).length > 0 && props.basicInfo.captain ? props.basicInfo.captain : null}
                                                onChange={(e) => { props.changeHandler(e, 'basicInfo', 'captain', 'selectHandler') }}
                                                options={props.captainOption}
                                                isMulti={true}
                                                closeMenuOnSelect={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> : ''
                    } */}
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group d-flex align-items-center select-z-Index">
                                <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                    <Select
                                        className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Currency"
                                        value={Object.keys(props.basicInfo).length > 0 && props.basicInfo.currency ? props.basicInfo.currency : null}
                                        onChange={(e) => { props.changeHandler(e, 'basicInfo', 'currency', 'selectHandler') }}
                                        options={props.currencyOption}
                                    />
                                </div>
                            </div>
                        </div>
                        {getCookie('userType') === "user" ?
                            <div className="col-md-6">
                                <div className="form-group d-flex align-items-center">
                                    <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100 btn-p-0">
                                        <CustomSelect pagesList={props.customerList} id="customer" isReturnObj={true} fetchMore={props.fetchMoreCustomerData} pageListTotal={props.customerCount} value={Object.keys(props.basicInfo).length > 0 && props.basicInfo.user ? props.basicInfo.user.label : ''} inputValue={props.searchCustomer} inputId="searchCustomer" placeholder="Select Customer" handleChange={CustomSelectHandler} clickCustomeSelectHandler={CustomSelectHandler} />
                                    </div>
                                </div>
                            </div> : ""}
                    </div>
                </div>

            </>
        </form>
        {props.userType === "customer" ? <Link to={props.dashboardLink} className=" action-button-previous btn btn themeReguler-btn bg-sky-blue" >Skip</Link> : ""}
        <button type="button" onClick={(e) => props.submitHandler(e, 'basicInfo')} className=" action-button btn btn themeReguler-btn bg-sky-blue">Next</button>
    </fieldset >
}

function CrewInfo(props) {
    return <fieldset>
        <form className="needs-validation-crew" onSubmit={(e) => props.submitHandler(e, 'basicInfo')} noValidate>
            <div className="form-card">
                <h2 className="fs-title">Crew Information</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group floating-label-wrap">
                            <input type="text" className="form-control" placeholder="Number of Crew" onChange={(e) => props.changeHandler(e, 'crewInformation')} value={(props.crewInformation && props.crewInformation.noOfCrew) ? props.crewInformation.noOfCrew : ''} name="noOfCrew" required />
                            <label className="foating-labels-sty">Number of Crew</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group floating-label-wrap">
                            <input type="number" className="form-control" onChange={(e) => props.changeHandler(e, 'crewInformation')} value={(props.crewInformation && props.crewInformation.rate) ? props.crewInformation.rate : ''} name="rate" placeholder="Rate per person per day" required />
                            <label className="foating-labels-sty">Rate per person per day</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group d-flex align-items-center select-z-Index">
                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                <Select
                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Crew Nationalities"
                                    value={Object.keys(props.crewInformation).length > 0 && props.crewInformation.crewMix ? props.crewInformation.crewMix : null}
                                    onChange={(e) => { props.changeHandler(e, 'crewInformation', 'crewMix', 'selectHandler') }}
                                    options={props.CrewNationalitiesOptions}
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
        <button type="button" className=" action-button-previous btn btn themeReguler-btn bg-sky-blue" onClick={() => props.handleTabChange(props.activeTab - 1, true)}>Previous</button>
        <button onClick={(e) => props.submitHandler(e, 'crewInformation')} className={" action-button btn btn themeReguler-btn bg-sky-blue"} >Next</button>
    </fieldset>
}

function OperationInfo(props) {
    return <fieldset>
        <div className="form-card">
            <h2 className="fs-title">Operation Area</h2>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group d-flex align-items-center select-z-Index">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Regions"
                                value={Object.keys(props.regionsInformation).length > 0 && props.regionsInformation.rotation ? props.regionsInformation.rotation : null}
                                onChange={(e) => { props.changeHandler(e, 'regionsInformation', 'rotation', 'selectHandler') }}
                                options={props.rotationRegionsOptions}
                                isMulti={true}
                                closeMenuOnSelect={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" className=" action-button-previous btn btn themeReguler-btn bg-sky-blue" onClick={() => props.handleTabChange(props.activeTab - 1, true)}>Previous</button>
        <button type="button" onClick={(e) => props.submitHandler(e, 'regionsInformation')} className={" action-button btn btn themeReguler-btn bg-sky-blue"} >Next</button>
    </fieldset>
}

function SupplyPorts(props) {
    return <fieldset>
        <div className="form-card">
            <h2 className="fs-title">Main Supply Ports</h2>
            {/* <div className="row">
                <div className="col-md-6">
                    <div className="form-group d-flex align-items-center select-z-Index">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Ports"
                                value={Object.keys(props.mainSupplyPorts).length > 0 && props.mainSupplyPorts.supplyPorts ? props.mainSupplyPorts.supplyPorts : null}
                                onChange={(e) => { props.changeHandler(e, 'mainSupplyPorts', 'supplyPorts', 'selectHandler') }}
                                options={props.portsOptions}
                                isMulti={true}
                                closeMenuOnSelect={false}
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="row">
                <div className="col-sm-6">
                    <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select mr-2 p-0 mw-100 form-group">
                        <Select
                            className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Country"
                            value={props.mainSupplyPorts.country === "" ? null : props.mainSupplyPorts.country}
                            onChange={(e) => { props.changeHandler(e, 'mainSupplyPorts', 'country', 'selectHandler') }} options={props.countryList}
                        />
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select mr-2 p-0 mw-100 form-group" style={{ opacity: props.isPortLoading ? 0.5 : 1 }}>
                        <Select
                            className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Port"
                            value={null}
                            onChange={(e) => { props.changeHandler(e, 'mainSupplyPorts', 'supplyPorts', 'selectHandler') }} options={props.portsOptions}
                        />
                    </div>
                </div>
            </div>
            <div className="tags-par-wrap">
                <div className="row">
                    <div className="col-sm-12 ">
                        <label className={props.mainSupplyPorts.supplyPorts.length > 0 ? 'd-none' : ''}>Ports</label>
                        <div className="custom-select2 text-bx-wrap input-type-select p-0 form-group d-flex">
                            <ul className="list-inline pl-0 mb-0 d-flex align-items-center flex-wrap">
                                {props.mainSupplyPorts.supplyPorts.map((item, index) => {
                                    return <li key={index} className="theme-tag-wrap mb-0">{item.label}
                                        <span onClick={(e) => { props.changeHandler(item, 'mainSupplyPorts', 'removePorts', 'selectHandler') }} className="close">&times;</span>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" className=" action-button-previous btn btn themeReguler-btn bg-sky-blue" onClick={() => props.handleTabChange(props.activeTab - 1, true)}>Previous</button>
        <button type="button" onClick={(e) => props.submitHandler(e, 'mainSupplyPorts')} className={" action-button btn btn themeReguler-btn bg-sky-blue"} >Next</button>
    </fieldset>
}

function Department(props) {
    return <fieldset>
        <form className="needs-validation-department" onSubmit={(e) => props.submitHandler(e, 'basicInfo')} noValidate>
            <div className="form-card">
                <h2 className="fs-title">Budget per Department</h2>
                <div className="row">
                    {
                        props.departmentList && props.departmentList.length > 0 ?
                            props.departmentList.map((item, index) => {
                                return <div className="col-md-6" key={index}>
                                    <div className="form-group floating-label-wrap">
                                        <input type="text" className="form-control" placeholder={item.name} value={(props.budgetPerDepartment && props.budgetPerDepartment[`${item.name}`]) ? props.budgetPerDepartment[`${item.name}`].budget : ''} name={item.name} onChange={(e) => props.changeHandler(e, 'budgetPerDepartment')} required />
                                        <label className="foating-labels-sty">{item.name}</label>
                                    </div>
                                </div>
                            })
                            : ""
                    }
                </div>
            </div>
        </form>
        <button type="button" className=" action-button-previous btn btn themeReguler-btn bg-sky-blue" onClick={() => props.handleTabChange(props.activeTab - 1, true)}>Previous</button>
        <button type="button" onClick={(e) => props.submitHandler(e, 'budgetPerDepartment')} className={" action-button btn btn themeReguler-btn bg-sky-blue"}>Next</button>
    </fieldset>
}

function InvoiceInfo(props) {
    return <fieldset>
        <form className="needs-validation-invoice" onSubmit={(e) => props.submitHandler(e, 'basicInfo')} noValidate>
            <div className="form-card">
                <h2 className="fs-title">Invoice Information</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group floating-label-wrap">
                            <input type="text" className="form-control" placeholder="Invoice Name" onChange={(e) => props.changeHandler(e, 'invoiceInformation')} value={(props.invoiceInformation && props.invoiceInformation.invoiceName) ? props.invoiceInformation.invoiceName : ''} name="invoiceName" required />
                            <label className="foating-labels-sty">Invoice Name</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group floating-label-wrap">
                            <input type="text" className="form-control" name="invoiceStreet" value={(props.invoiceInformation && props.invoiceInformation.invoiceStreet) ? props.invoiceInformation.invoiceStreet : ''} onChange={(e) => props.changeHandler(e, 'invoiceInformation')} placeholder="Invoice Street" required />
                            <label className="foating-labels-sty">Invoice Street</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group floating-label-wrap">
                            <input type="text" className="form-control" placeholder="Invoice City" value={(props.invoiceInformation && props.invoiceInformation.invoiceCity) ? props.invoiceInformation.invoiceCity : ''} onChange={(e) => props.changeHandler(e, 'invoiceInformation')} name="invoiceCity" required />
                            <label className="foating-labels-sty">Invoice City</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group floating-label-wrap">
                            <input type="text" className="form-control" name="vatNo" value={(props.invoiceInformation && props.invoiceInformation.vatNo) ? props.invoiceInformation.vatNo : ''} onChange={(e) => props.changeHandler(e, 'invoiceInformation')} placeholder="VAT Nr" required />
                            <label className="foating-labels-sty">VAT Nr</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group floating-label-wrap">
                            <input type="text" className="form-control" placeholder="Invoice Reference" onChange={(e) => props.changeHandler(e, 'invoiceInformation')} value={(props.invoiceInformation && props.invoiceInformation.invoiceRef) ? props.invoiceInformation.invoiceRef : ''} name="invoiceRef" required />
                            <label className="foating-labels-sty">Invoice Reference</label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <button type="button" className=" action-button-previous btn btn themeReguler-btn bg-sky-blue" onClick={() => props.handleTabChange(props.activeTab - 1, true)}>Previous</button>
        <button type="button" name="next" onClick={(e) => props.submitHandler(e, 'invoiceInformation')} className={" action-button btn btn themeReguler-btn bg-sky-blue"}>Next</button>
    </fieldset>
}

function ConfigInfo(props) {
    const getDropDownVal = (val) => {
        let label = "";
        let value = val;
        if (val === "") {
            return null;
        } else if (val === false) {
            label = 'No';
        } else if (val === true) {
            label = "Yes";
        } else if (val) {
            if (typeof val === "number" || (typeof val === "string" && val.indexOf("%") === -1)) {
                label = val + "%";
            } else {
                label = val;
            }
        }
        return { value: value, label: label }
    }
    let durablePercentage = props.crewInformation && props.crewInformation.rate ? ((props.totalCategoriesDetail.durable / props.crewInformation.rate) * 100).toFixed(2) : "";
    let freshPercentage = props.crewInformation && props.crewInformation.rate ? ((props.totalCategoriesDetail.fresh / props.crewInformation.rate) * 100).toFixed(2) : "";
    return <fieldset>
        <div className="form-card">
            <h5 className="form-title semi-bold sky-blue-clr">Ration Configuration</h5>
            <div className="vessel-rate-wrap mt-4 card-style-wrap">
                <div className="card-sty-table with-title">
                    <h6 className="card-table-title">Rate</h6>
                    <div className="table-responsive">
                        <table className="table vess-rate-lis">
                            <tbody>
                                <tr>
                                    <td>Contracted Rate</td>
                                    <td>{(props.crewInformation && props.crewInformation.rate) ? props.crewInformation.rate : ''}</td>
                                </tr>
                                <tr>
                                    <td>Proportions</td>
                                    <td>{props.totalCategoriesDetail.durable} </td>
                                    <td>{durablePercentage}%</td>
                                </tr>
                                <tr>
                                    <td>Fresh</td>
                                    <td>{props.totalCategoriesDetail.fresh} </td>
                                    <td>{freshPercentage}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* Ration Table */}
            <div className="vessel-Ration-wrap mt-4 card-style-wrap mb-4 w-100">
                <div className="card-sty-table with-title w-100">
                    <h6 className="card-table-title mb-0">Ration</h6>
                    <div className="table-responsive">
                        <table className="table theme-table-wrap">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Qty Person per day</th>
                                    <th>Proportion of total Qty</th>
                                    <th>Reference Qty</th>
                                    <th className="small-unit80">Unit</th>
                                    {props.userType === "user" ?
                                        <React.Fragment>
                                            <th>Difference</th>
                                            <th>Difference%</th>
                                            <th>Avg price per KG</th>
                                            <th>Avg Value per category</th>
                                            <th>Proportion of Total Value</th>
                                            <th>Proportional Value of contracted rate by qty</th>
                                            <th>Price per KG optimum</th>
                                            <th>Number of items in optimum range</th>
                                            <th>Proportional Value of Rate by value per category</th>
                                        </React.Fragment> : ""}

                                    <th>Price per KG optimum per item category</th>
                                    <th>Number of items in optimum range per category</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.configInformation && props.configInformation.categories && props.configInformation.categories.length > 0 ?
                                        props.configInformation.categories.map((item, index) => {
                                            return <tr key={index}>
                                                <td className="text-left" >{item.category}</td>
                                                <td className="pt-0 pb-0"><input type="text" className="form-control" value={item.qtyPerDay} placeholder="0" onChange={(e) => props.changeHandler(e, 'configInformation', 'qtyPerDay', index)} /></td>
                                                <td>{item['Proportion-of-total-Qty']}%</td>
                                                <td>{item['Reference-Qty']}</td>
                                                <td>{item.Unit}</td>
                                                {props.userType === "user" ?
                                                    <React.Fragment>
                                                        <td>{item["Difference"]}</td>
                                                        <td>{item["Difference%"]}</td>
                                                        <td>{item['Avg-price-per-KG']}</td>
                                                        <td>{item['Avg-Value-per-category']}</td>
                                                        <td>{item['Proportion-of-Total-Value'] + "%"}</td>
                                                        <td>{item['Proportional-Value-of-contracted-rate-by-qty']}</td>
                                                        <td>{item['Price-per-KG-optimum']}</td>
                                                        <td>{item["Number-of-items-in-optimum-range"]}</td>
                                                        <td>{item['Proportional-Value-of-Rate-by-value-per-category']}</td>
                                                    </React.Fragment>
                                                    : ""}
                                                <td>{item['Price-per-KG-optimum-per-item-category']}</td>
                                                <td>{item["Number-of-items-in-optimum-range-per-category"]}</td>
                                            </tr>
                                        })
                                        : ''
                                }
                                <tr >
                                    <td className="text-left" >Total</td>
                                    <td>{props.totalCategoriesDetail.categoryTotal}</td>
                                    <td>{props.totalCategoriesDetail.totalProportionTotalQty}%</td>
                                    <td>{props.totalCategoriesDetail.totalRefQty}</td>
                                    <td>KG</td>
                                    {props.userType === "user" ?
                                        <React.Fragment>
                                            <td>{props.totalCategoriesDetail.totalDiff}</td>
                                            <td>{props.totalCategoriesDetail.totalDiffPercentage}</td>
                                            <td>{props.totalCategoriesDetail.avgPricePerKg}</td>
                                            <td>{props.totalCategoriesDetail.totalAvgValuePerCat}</td>
                                            <td>{props.totalCategoriesDetail.totalProportionTotalValue}%</td>
                                            <td>{props.totalCategoriesDetail.totalProValOfContractedRateQty}</td>
                                            <td>{props.crewInformation && props.crewInformation.rate ? (props.crewInformation.rate / props.totalCategoriesDetail.categoryTotal).toFixed(2) : ''}</td>
                                            <td>{props.totalCategoriesDetail.totalNoOfItemInOptimumRange}</td>
                                            <td>{props.totalCategoriesDetail.totalProValOfRateByValperCat}</td>
                                        </React.Fragment> : ""}

                                    <td>{props.totalCategoriesDetail.totalPricePerKgOptimumPerItemCat}</td>
                                    <td>{props.totalCategoriesDetail.totalNumOfItemInOptimumPerCat}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {/* Administration Wrap */}
            {props.userType === "user" ?
                <div className="vessel-Admin-wrap mt-5">
                    <h6 className="form-title semi-bold sky-blue-clr">Administration</h6>
                    <div className="ves-admin-lis mt-4">
                        <form action="">
                            <div className="row">
                                <div className="col-md-6">
                                    <div class="form-group">
                                        <label className="field-label">Rate limit fixed</label>
                                        <div className="form-group d-flex align-items-center select-z-Index">
                                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder=""
                                                    value={getDropDownVal(props.configInformation.rateLimitFixed)}
                                                    onChange={(e) => { props.changeHandler(e.value, 'configInformation', 'rateLimitFixed', 'selectHandler') }}
                                                    options={props.limitOption}
                                                    styles={getSelectStyle('200px')}
                                                    isSearchable={false}
                                                // isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="form-group">
                                        <label className="field-label">Rate tolerance</label>
                                        <div className={"form-group d-flex align-items-center select-z-Index " + (!props.configInformation.rateLimitFixed ? "disable-wrap" : "")}>
                                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder=""
                                                    value={getDropDownVal(props.configInformation.rateTolerance)}
                                                    onChange={(e) => { props.changeHandler(e.value, 'configInformation', 'rateTolerance', 'selectHandler') }}
                                                    options={props.toleranceOption}
                                                    styles={getSelectStyle('200px')}
                                                    isDisabled={!props.configInformation.rateLimitFixed}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="field-label">Maximum Quantity limit fixed</label>
                                        <div className="form-group d-flex align-items-center select-z-Index">
                                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder=""
                                                    value={getDropDownVal(props.configInformation.maxQuantityLimitFixed)}
                                                    onChange={(e) => { props.changeHandler(e.value, 'configInformation', 'maxQuantityLimitFixed', 'selectHandler') }}
                                                    options={props.limitOption}
                                                    styles={getSelectStyle('200px')}
                                                    isSearchable={false}
                                                // isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="field-label">Maximum quantity tolerance</label>
                                        <div className={"form-group d-flex align-items-center select-z-Index " + (!props.configInformation.maxQuantityLimitFixed ? "disable-wrap" : "")}>
                                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder=""
                                                    value={getDropDownVal(props.configInformation.maxQuantityTolerance)}
                                                    onChange={(e) => { props.changeHandler(e.value, 'configInformation', 'maxQuantityTolerance', 'selectHandler') }}
                                                    options={props.toleranceOption}
                                                    styles={getSelectStyle('200px')}
                                                    isDisabled={!props.configInformation.maxQuantityLimitFixed}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="field-label">Category limit fixed</label>
                                        <div className={"form-group d-flex align-items-center select-z-Index " + (!props.configInformation.maxQuantityLimitFixed ? "disable-wrap" : "")}>
                                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder=""
                                                    value={getDropDownVal(props.configInformation.categoyLimitFixed)}
                                                    onChange={(e) => { props.changeHandler(e.value, 'configInformation', 'categoyLimitFixed', 'selectHandler') }}
                                                    options={props.limitOption}
                                                    styles={getSelectStyle('200px')}
                                                    isDisabled={!props.configInformation.maxQuantityLimitFixed}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="field-label">Maximum category tolerance</label>
                                        <div className={"form-group d-flex align-items-center select-z-Index " + (!props.configInformation.categoyLimitFixed || !props.configInformation.maxQuantityLimitFixed ? "disable-wrap" : "")}>
                                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder=""
                                                    value={getDropDownVal(props.configInformation.maxCategoryTolerance)}
                                                    onChange={(e) => { props.changeHandler(e.value, 'configInformation', 'maxCategoryTolerance', 'selectHandler') }}
                                                    options={props.catToleranceOption}
                                                    styles={getSelectStyle('200px')}
                                                    isDisabled={!props.configInformation.categoyLimitFixed || !props.configInformation.maxQuantityLimitFixed}
                                                    isSearchable={false}
                                               />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div class="form-group">
                                        <label className="field-label">Minimum fixed</label>
                                        <div className="form-group d-flex align-items-center select-z-Index">
                                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder=""
                                                    value={getDropDownVal(props.configInformation.minimumFixed)}
                                                    onChange={(e) => { props.changeHandler(e.value, 'configInformation', 'minimumFixed', 'selectHandler') }}
                                                    options={props.limitOption}
                                                    styles={getSelectStyle('200px')}
                                                    isSearchable={false}
                                                // isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="form-group">
                                        <label className="field-label">Sufficient provisions minimum</label>
                                        <div className={"form-group d-flex align-items-center select-z-Index " + (!props.configInformation.minimumFixed ? "disable-wrap" : "")}>
                                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder=""
                                                    value={getDropDownVal(props.configInformation.sufficientProvisionsMinimum)}
                                                    onChange={(e) => { props.changeHandler(e.value, 'configInformation', 'sufficientProvisionsMinimum', 'selectHandler') }}
                                                    options={props.provisionOption}
                                                    styles={getSelectStyle('120px')}
                                                    isDisabled={!props.configInformation.minimumFixed}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-6">
                                    <div class="form-group">
                                        <label className="field-label">Allow durable provisions order in fresh top up</label>
                                        <div className="form-group d-flex align-items-center select-z-Index">
                                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder=""
                                                    value={getDropDownVal(props.configInformation.allowDurableProvisionsOrder)}
                                                    onChange={(e) => { props.changeHandler(e.value, 'configInformation', 'allowDurableProvisionsOrder', 'selectHandler') }}
                                                    options={props.limitOption}
                                                    // isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="form-group">
                                        <label className="field-label">Fixed main supply ports</label>
                                        <div className="form-group d-flex align-items-center select-z-Index">
                                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder=""
                                                    value={getDropDownVal(props.configInformation.fixedMainSupplyPorts)}
                                                    onChange={(e) => { props.changeHandler(e.value, 'configInformation', 'fixedMainSupplyPorts', 'selectHandler') }}
                                                    options={props.limitOption}
                                                    // isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </form>
                    </div>
                </div> : ""}
        </div>

        <div className="vessel-Admin-wrap mt-5">
            <button type="button" name="prev" className=" action-button-previous btn btn themeReguler-btn bg-sky-blue" onClick={() => props.handleTabChange(props.activeTab - 1)}>Previous</button>
            <button type="button" name="next" onClick={(e) => props.submitHandler(e, 'configInformation', {
                durablePercentage, freshPercentage,
                durable: props.totalCategoriesDetail.durable,
                fresh: props.totalCategoriesDetail.fresh
            })} className={" action-button btn btn themeReguler-btn bg-sky-blue" + (props.isLoading ? " btn-inn-loader" : "")}>Confirm</button>
        </div>

    </fieldset >
}

function SuccessMessage(props) {
    return <fieldset>
        <div className="form-card h-100">
            <div className="form-thanks-you-wrap d-flex flex-column align-items-center justify-content-center flex-fill h-100">
                <h2>Thank You</h2>
                <div className="thank-you-icon-wrap">
                    <h3><i class="far fa-thumbs-up"></i></h3>
                </div>
                <p>{window.location.pathname === "/create-vessel" ? "The vessel has been created successfully." : "The vessel has been updated successfully."}</p>
            </div>
        </div>
    </fieldset>
}