import React from 'react';
import { Wrapper } from '../../Css/supplierSelection';
import { getDashboardLink } from '../../../../Services/common';
import  DashboardNavbar  from '../../../Common/Components/dashboardNavbar';
import { Filters } from "./filters";
import DemoHeader from '../../../Common/Components/demoHeader';



export function SupplierWrapper(props) {
    const getNavbarOptions = () => {
        let arr = [{
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.goBack()
        },
        {
            img: 'Monitor-icon.svg',
            className: "dark-blue",
            name: "Close<br />Order",
            path: getDashboardLink(),
            isLogout: false
        }];
        if (props.isAllowRfq || props.isAllowOrder) {
            arr.push({
                img: 'Monitor-icon.svg',
                className: "dark-green",
                name: 'Send' + ((props.selectionType === "") ? "" : (props.selectionType === 'rfq') ? "<br />RFQ" : "<br />Order"),
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.sendOrder()
            })
        }
        return arr;
    }
    return (
        <Wrapper>
            <div className="pageWrapper py-4">
                <div className="pageContainer">
                    <DemoHeader />
                    <div className="order-dlts-wrapper">
                        <DashboardNavbar isShowLogo={true} logoType="Order" logoUrl={"logo.png"} title="SELECT SUPPLIER" navbarOptions={getNavbarOptions()} className="pageHeader d-flex align-items-center flex-column flex-lg-row" />
                    </div>
                    <div className="pageContent suppl-select-wrapper">
                        <Filters handleSortChange={props.handleSortChange} port={props.port} selectedSortOption={props.selectedSortOption} portOptions={props.portOptions} sortByOptions={props.sortByOptions} sendOrder={props.sendOrder} />
                        {props.children}
                    </div>
                    {/* <span className="pageProfileBottomText text-white">*meshing = connect your vessel(s) to our worlwide network of suppliers &amp; knowledge</span> */}
                </div>
            </div>
        </Wrapper>
    )
};