import React, { Component } from 'react'
import { getDashboardLink } from '../../../Services/common';
import { Wrapper } from '../Css/notfound';

export default function Notfound(props) {
    return (
        <Wrapper>
            <section className="wrapper">
                <div className="container">

                    <div id="scene" className="scene" data-hover-only="false">


                        <div className="circle" data-depth="1.2"></div>

                        <div className="one" data-depth="0.9">
                            <div className="content">
                                <span className="piece"></span>
                                <span className="piece"></span>
                                <span className="piece"></span>
                            </div>
                        </div>

                        <div className="two" data-depth="0.60">
                            <div className="content">
                                <span className="piece"></span>
                                <span className="piece"></span>
                                <span className="piece"></span>
                            </div>
                        </div>

                        <div className="three" data-depth="0.40">
                            <div className="content">
                                <span className="piece"></span>
                                <span className="piece"></span>
                                <span className="piece"></span>
                            </div>
                        </div>

                        <p className="p404" data-depth="0.50">Alert</p>
                        <p className="p404" data-depth="0.10">Alert</p>

                    </div>

                    <div className="text">
                        <article>
                            <p>Uh oh! Looks like you don't  have permission of this feature. <br />Go back to the homepage if you dare!</p>
                            <button onClick={() => props.history.push(getDashboardLink())} >i dare!</button>
                        </article>
                    </div>

                </div>
            </section>
        </Wrapper>
    )
}