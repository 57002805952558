import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import {
  GetUserAction,
  AppendUserAction,
  GetUserDetailAction,
  roleAssignmentAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function createUser(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "createUser" }));
    let myJson = await FETCH("POST", Constant.apiURl + "/users", data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "createUser",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "createUser",
          message: myJson.message,
        })
      );
    }
  };
}
export function userListing(offset, type, filterQuery) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendUserList" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getUser" }));
      dispatch(GetUserAction([], 0));
    }
    let url = "";
    if (
      filterQuery !== "" &&
      filterQuery !== undefined &&
      filterQuery !== null
    ) {
      url = Constant.apiURl + `/users/${offset}/20?type=${type}${filterQuery}`;
    } else {
      url = Constant.apiURl + `/users/${offset}/20?type=${type}`;
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendUserAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendUserList",
            message: "",
          })
        );
      } else {
        dispatch(GetUserAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getUser",
            message: myJson.message,
          })
        );
      }
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getUser",
          message: myJson.message,
        })
      );
    }
  };
}
export function deleteUser(userId, filterQuery) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteUser" }));
    let url = Constant.apiURl + `/users/${userId}`;
    if (filterQuery && filterQuery !== "") {
      url += `?${filterQuery}`;
    }
    let myJson = await FETCH("DELETE", url);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "deleteUser",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "deleteUser",
          message: myJson.message,
        })
      );
    }
  };
}
export function getUserDetail(offset, type, filterQuery) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getUserDetail" }));
    let url = "";
    if (
      filterQuery !== "" &&
      filterQuery !== undefined &&
      filterQuery !== null
    ) {
      url = Constant.apiURl + `/users/${offset}/20?type=${type}${filterQuery}`;
    } else {
      url = Constant.apiURl + `/users/${offset}/20?type=${type}`;
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      let data = myJson.data[0];
      let portCheck = data.portId !== undefined && data.portId !== null;
      let vesselCheck = data.vesselId !== undefined && data.vesselId !== null;
      let countryCheck =
        data.countryId !== undefined && data.countryId !== null;
      let roleCheck = data.roleId !== undefined && data.roleId !== null;
      dispatch(
        GetUserDetailAction({
          name: data.name,
          email: data.email,
          websiteUrl: data.websiteUrl,
          phone: data.phone,
          type: data.type,
          subType: data.subType,
          country: countryCheck ? data.countryId._id : "",
          port: portCheck ? data.portId._id : "",
          vessel: vesselCheck ? data.vesselId._id : "",
          role: roleCheck ? data.roleId._id : "",
          specialityList: data.speciality,
          selectedDepOption: data.departments ? data.departments : null,
          selectedCountryOption: countryCheck
            ? {
                value: data.countryId._id,
                label: data.countryId.name,
                countryCode: data.countryId.countryCode,
              }
            : null,
          selectedPortOption: portCheck
            ? { value: data.portId._id, label: data.portId.port }
            : null,
          selectedVesselOption: vesselCheck
            ? { value: data.vesselId._id, label: data.vesselId.name }
            : null,
          selectedRoleOption: roleCheck
            ? { value: data.roleId._id, label: data.roleId.roleName }
            : null,
        })
      );
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getUserDetail",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getUserDetail",
          message: myJson.message,
        })
      );
    }
  };
}
export function roleAssignment(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "roleAssignment" }));
    let myJson = await FETCH(
      "POST",
      Constant.apiURl + "/users/check/permission",
      data
    );
    if (myJson && myJson.code === 201) {
      dispatch(
        roleAssignmentAction({
          isShow: myJson.isShow,
          message: myJson.msg,
        })
      );
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "roleAssignment",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "roleAssignment",
          message: myJson.message,
        })
      );
    }
  };
}
export function changePassword(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "changePassword" }));
    let myJson = await FETCH(
      "POST",
      Constant.apiURl + "/users/change/password",
      data
    );
    if (myJson && myJson.code === 201) {
      dispatch(
        roleAssignmentAction({
          isShow: myJson.isShow,
          message: myJson.msg,
        })
      );
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "changePassword",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "changePassword",
          message: myJson.message,
        })
      );
    }
  };
}
