import React from "react";
import Swal from 'sweetalert2';
import Select from 'react-select';
// import { reminderNameList } from '../../../Constants/constant';

// export const reminderInitialState = {
//     nameList: reminderNameList,
//     maxReminderList: [{
//         label: 1, value: 1
//     }, {
//         label: 2, value: 2
//     }],
//     name: null,
//     days: '',
//     maxReminder: null,
//     errorMessage: "",
//     errorType: "",
//     isHitApi: false,
//     showDaysField: true
// }

export function ReminderForm(props) {
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        } else if (props.name === null) {
            isValid = false;
            Swal.fire("Error", "Please select name", "error");
        } else if (props.showDaysField === true && props.maxReminder === null) {
            isValid = false;
            Swal.fire("Error", "Please select max reminder", "error");
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            if (props.isEdit) {
                props.updateReminder();
            } else {
                props.createHandler();
            }
        }
    }
    return (
        < form className="needs-validation floating-form " onSubmit={(e) => handleSubmit(e)} noValidate >
            <div className="row">
                <div className={props.showDaysField === true ? "col-sm-6" : "col-sm-12"}>
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu reminder-name-dropdown text-bx-wrap custom-select2 input-type-select w-100 mw-100 h">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Name"
                                value={props.name}
                                onChange={(e) => { props.handleFormChange(e, 'name') }}
                                options={props.nameList}
                                isDisabled={props.isEdit}
                            />
                        </div>
                    </div>
                </div>
                {props.showDaysField === true ? 
                                <div className="col-sm-6">
                                <div className="form-group d-flex align-items-center">
                                    <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                        <Select
                                            className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Max Reminder"
                                            value={props.maxReminder}
                                            onChange={(e) => { props.handleFormChange(e, 'maxReminder') }}
                                            options={props.maxReminderList}
                                        />
                                    </div>
                                </div>
                            </div>
                : ''}
            </div>
            <div className="row">
            <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="number" name="days" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Days"
                            value={props.days}
                            required />
                        <lable className="foating-labels-sty">Days</lable>
                    </div>
            </div>
            </div>

            { (props.apiCallStatus.apiCallFor === "createReminder" || props.apiCallStatus.apiCallFor === "updateReminder")
                && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                <div className="alert alert-danger">
                    {props.apiCallStatus.message}
                </div>
                : ""
            }
            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {(props.apiCallStatus.apiCallFor === "createReminder" || props.apiCallStatus.apiCallFor === "updateReminder") && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">{props.isEdit ? "Update" : "Submit"}</button>
                </div>
            </div>
        </form >
    )
}