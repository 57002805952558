import styled from "styled-components";

export const Wrapper = styled.div`
.card-bx-sty.box-item-inn.ordCatg-dtl-btn{background:#94989E;border:2px solid #979797;-webkit-box-shadow:0 4px 4px rgba(0,0,0,0.25);box-shadow:0 4px 4px rgba(0,0,0,0.25);border-radius:10px;max-width:180px;min-width:150px}.ordCatg-dtl-btn h1 {color: #F9F9F9!important;font-size: 14px;font-weight: 600;}
.btn.btn-yellow {background: #E6A935;border: 1px solid #979797;-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 5px;width: 100%;padding: 6px 15px;font-size: 14px;color: #F9F9F9;font-weight: 600;}
.order-card-dtls-item {background: #250A72;border: 2px solid #F9F9F9;-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 10px;max-width: 300px;padding: 20px;margin: 0 auto;}
.order-card-dtls {margin: 30px 0;}
.check-icon-cir {border: 3px solid #FFFFFF;border-radius: 50%;width: 110px;height: 110px;margin: 0 auto;margin-top: 20px;}
.check-icon-cir img {width: 45px;}
.order-card-dtls h1,.order-card-dtls h2 {font-weight: 600;font-size: 20px;color: #fff;text-align: center;margin-top: 22px;line-height: 17px;}
.order-card-dtls h2 {font-weight: 300;font-size: 18px;margin-top: 60px;}
.order-card-dtls h2 span{font-weight: 600;line-height: 26px;}
.order-card-dtls h1 span {font-weight: normal;font-size: 13px;}

@media only screen and (max-width:1600px){
    .check-icon-cir {width: 100px;height: 100px;}
    .order-card-dtls h1,.order-card-dtls h2 {font-weight: 500;font-size: 18px;}
}
@media only screen and (max-width: 576px){
    .card-bx-sty.box-item-inn.ordCatg-dtl-btn {min-width: 110px;padding: 6px 10px;}
    .card-bx-sty.box-item-inn.ordCatg-dtl-btn .btn.btn-yellow {padding: 4px 11px 2px;font-size: 12px;}
    .check-icon-cir img {width: 40px;}
    .order-card-dtls h1 span {font-size: 12px;}
    .order-card-dtls h2 {font-size: 16px;}
}
`