import React, { useEffect } from 'react';
import Select from 'react-select';
import { Sum } from "../../../../../Services/common";
import { AutoCompleteInput } from "../../../../Common/Components/autoComplete";
import DatePicker from "react-datepicker";
import moment from "moment";
import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";

export function DeliveryDetail(props) {
    useEffect(() => {
        $("input[type=number]").on("focus", function () {
            $(this).on("keydown", function (event) {
                if (event.keyCode === 38 || event.keyCode === 40 || event.key === "-") {
                    event.preventDefault();
                }
            });
        });
    }, [])
    const handleChange = (id, value, changeFor, provisions, e) => {
        if (changeFor === "numberOfCrew") {
            let obj = { ...props[changeFor] };
            obj[id] = value;
            props.handleChange({ target: { id: changeFor, value: obj } }, 'deliveryDetail')
        } else {
            let obj = { ...props[changeFor] };
            obj[provisions][id] = value;
            props.handleChange({ target: { id: changeFor, value: obj } }, 'deliveryDetail')
        }
    }
    return (
        <div className="order-dtls-sec-ctn deli-dlts-ctn">
            <div className="card-group-cnt d-flex align-items-center flex-column flex-md-row w-100">
                <h2 className="title-dark-20">DELIVERY DETAILS</h2>
                <div className="flex-fill order-right-ctn">
                    <h6 className="require-note text-danger">Fields with * are mandatory!</h6>
                    <div className="card-bx-sty deli-dlts-ctn-info custom-column">
                        {/* Form Error Wrapper */}
                        {props.errorMessage !== "" && props.errorFor === "deliveryDetail" ?
                            // <div className="errorShow-wrap">
                            //     <p className="text-danger mb-0">{props.errorMessage}</p>
                            // </div>
                            <div className="alert alert-danger" role="alert">
                                {props.errorMessage}
                            </div>
                            : ""}
                        <div className="row">
                            <div className="col-md-5">
                                <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">IMO-Nr.:*</label>
                                    <input type="text" id="imoNum" value={props.imoNum}
                                        className="form-control field-bx-sty" placeholder="Enter IMO-Nr value" required readOnly />
                                </div>
                                <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">Vessel.:*</label>
                                    <input type="text" id="vessel" value={props.vessel === "" ? "" : props.vessel.label}
                                        className="form-control field-bx-sty" placeholder="Select Vessel" required readOnly />
                                </div>
                                <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">Captain.:*</label>
                                    {/* <AutoCompleteInput key={'captain'} items={props.captainList} id="captain" value={props.captain === "" ? "" : props.captain.label} handleChange={props.handleChange} changeFor="deliveryDetail" placeholderText="Enter Captain’s Name" /> */}
                                    <div className="custom-select-menu text-bx-wrap custom-select2">
                                        <Select
                                            className="custom-select-wrap" classNamePrefix="react-select" placeholder="Enter Captain’s Name"
                                            value={props.captain === "" ? null : props.captain}
                                            onChange={(e) => props.handleChange({ target: { id: "captain", value: e } }, 'deliveryDetail')}
                                            options={props.captainList}
                                            isSearchable={false}
                                        />
                                    </div>
                                </div>
                                <div className="form-group d-flex align-items-center">
                                    <label className="label-reg-sty">Cook.:*</label>
                                    {/* <AutoCompleteInput key={'cook'} items={props.cookList} id="cook" value={props.cook === "" ? "" : props.cook.label} handleChange={props.handleChange} changeFor="deliveryDetail" placeholderText="Enter Cook’s Name" /> */}
                                    <div className="custom-select-menu text-bx-wrap custom-select2">
                                        <Select
                                            className="custom-select-wrap" classNamePrefix="react-select" placeholder="Enter Cook’s Name"
                                            value={props.cook === "" ? null : props.cook}
                                            onChange={(e) => props.handleChange({ target: { id: "cook", value: e } }, 'deliveryDetail')}
                                            options={props.cookList}
                                            isSearchable={false}
                                        />
                                    </div>
                                </div>
                                {!props.isInventory ?
                                    <React.Fragment>
                                        <div className="form-group d-flex align-items-center">
                                            <label className="label-reg-sty">Country.:*</label>
                                            <div className="custom-select-menu text-bx-wrap custom-select2">
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Country"
                                                    value={props.country === "" ? null : props.country}
                                                    onChange={(e) => props.handleChange({ target: { id: "country", value: e } }, 'deliveryDetail')}
                                                    options={props.countryList}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group d-flex align-items-center">
                                            <label className="label-reg-sty">Place of delivery.:*</label>
                                            <div className="custom-select-menu text-bx-wrap custom-select2" style={{ opacity: props.isStarted.indexOf('getAllPort') !== -1 ? 0.5 : 1 }}>
                                                <Select
                                                    className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Port"
                                                    value={props.place === "" ? null : props.place}
                                                    onChange={(e) => props.handleChange({ target: { id: "place", value: e } }, 'deliveryDetail')}
                                                    options={props.portList}
                                                    isDisabled={props.isStarted.indexOf('getAllPort') !== -1 ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group d-flex align-items-center">
                                            <label className="label-reg-sty">Agent's Detail:</label>
                                            <input type="text" id="agentDetail" value={props.agentDetail}
                                                onChange={(e) => props.handleChange(e, 'deliveryDetail')} className="form-control field-bx-sty" placeholder="Enter Agent's Detail" />
                                        </div>
                                    </React.Fragment>
                                    : ""}
                            </div>
                            <div className="col-md-7 deli-dlts-ctn-right">
                                <div className="d-flex align-items-center content-items-sty flex-row date-selector-wrap">
                                    <div className="form-group d-flex align-items-center flex-fill">
                                        <label className="label-reg-sty">{!props.isInventory ? "ETA.:*" : "Date.:*"}</label>
                                        <div className="datePicker form-control field-bx-sty p-0 fa-icon">
                                            <DatePicker selected={props.eta}
                                                minDate={new Date()}
                                                onChange={(val) => props.handleChange({ target: { id: "eta", value: val } }, 'deliveryDetail')} />

                                        </div>
                                    </div>
                                    {!props.isInventory ?
                                        <div className="form-group d-flex align-items-center flex-fill">
                                            <label className="label-reg-sty">ETD.:*</label>
                                            <div className="datePicker form-control field-bx-sty p-0 fa-icon">
                                                <DatePicker selected={props.etd} disabled={props.eta === "" ? true : false}
                                                    minDate={props.eta !== "" ? new Date(moment(props.eta).add(1, 'days')) : new Date(moment().add(1, 'days'))}
                                                    onChange={(val) => props.handleChange({ target: { id: "etd", value: val } }, 'deliveryDetail')} />
                                            </div>
                                        </div> : ""}
                                </div>
                                {!props.isInventory ?
                                    <React.Fragment>
                                        <div className="d-flex align-items-center content-items-sty flex-row currency-fields-wrap">
                                            <div className="form-group d-flex align-items-center flex-fill">
                                                <label className="label-reg-sty">Budget per day:*<br className="d-none d-lg-block" />(per crew member)</label>
                                                <input type="text" id="budgetPerDay" value={props.budgetPerDay}
                                                    onChange={(e) => props.handleChange(e, 'deliveryDetail')} className="form-control field-bx-sty" placeholder="Enter Budget" required readOnly />
                                            </div>
                                            <div className="form-group d-flex align-items-center flex-fill">
                                                <label className="label-reg-sty">Currency:*</label>
                                                <input type="text" className="form-control field-bx-sty text-center" placeholder="Enter Currency" id="currency" value={props.currency}
                                                    onChange={(e) => props.handleChange(e, 'deliveryDetail')} required readOnly />
                                            </div>
                                        </div>
                                        <div className="form-group d-flex align-items-center flex-fill list-grouped">
                                            <label className="label-reg-sty">Number of crew.:*</label>
                                            <div className="deliv-dtls-list-sty d-flex align-items-center flex-fill">
                                                <div className="form-group mr-2">
                                                    <label className="label-reg-sty text-truncate">Regular</label>
                                                    <input type="number" min="1" className="form-control field-bx-sty" placeholder="16" value={props.numberOfCrew.regular === 0 || props.numberOfCrew.regular === "0" ? "" : props.numberOfCrew.regular}
                                                        onChange={(e) => handleChange('regular', e.target.value,
                                                            'numberOfCrew')} required readOnly/>
                                                </div>
                                                <div className="form-group">
                                                    <label className="label-reg-sty text-truncate">Additional</label>
                                                    <input type="number" min="0" className="form-control field-bx-sty" placeholder="4" value={props.numberOfCrew.additional === 0 || props.numberOfCrew.additional === "0" ? "" : props.numberOfCrew.additional}
                                                        onChange={(e) => handleChange('additional', e.target.value,
                                                            'numberOfCrew')} />
                                                </div>
                                                <div className="form-group total-count-ctn ml-2 mb-0">
                                                    <label className="label-reg-sty text-truncate pl-0">Total</label>
                                                    <input type="text" className="form-control field-bx-sty bg_orng no-bg without-value" value={Sum(props.numberOfCrew.regular, props.numberOfCrew.additional)} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group d-flex align-items-center flex-fill list-grouped">
                                            <label className="label-reg-sty">Order period.:*<br />(Dry and frozen provisions)</label>
                                            <div className="deliv-dtls-list-sty d-flex align-items-center flex-fill">
                                                <div className="form-group mr-2">
                                                    <label className="label-reg-sty text-truncate">Regular</label>
                                                    <input type="number" min="1" className="form-control field-bx-sty" placeholder="60 days" value={props.orderPeriod.dryFrozen.regular === 0 || props.orderPeriod.dryFrozen.regular === "0" ? "" : props.orderPeriod.dryFrozen.regular}
                                                        onChange={(e) => handleChange('regular', e.target.value,
                                                            'orderPeriod', 'dryFrozen')} required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="label-reg-sty text-truncate">Expected delays</label>
                                                    <input type="number" min="0" className="form-control field-bx-sty" placeholder="3 days" value={props.orderPeriod.dryFrozen.expectedDelay === 0 || props.orderPeriod.dryFrozen.expectedDelay === "0" ? "" : props.orderPeriod.dryFrozen.expectedDelay}
                                                        onChange={(e) => handleChange('expectedDelay', e.target.value,
                                                            'orderPeriod', 'dryFrozen')} />
                                                </div>
                                                <div className="form-group total-count-ctn ml-2 mb-0">
                                                    <label className="label-reg-sty pl-0"><span className="labelReg-innSty">Total</span></label>
                                                    <input type="text" className="form-control field-bx-sty bg_orng no-bg without-value" value={Sum(props.orderPeriod.dryFrozen.regular, props.orderPeriod.dryFrozen.expectedDelay)} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group d-flex align-items-center flex-fill list-grouped">
                                            <label className="label-reg-sty">Order period.:*<br />(Fresh provisions)</label>
                                            <div className="deliv-dtls-list-sty d-flex align-items-center flex-fill">
                                                <div className="form-group mr-2">
                                                    <label className="label-reg-sty text-truncate">Regular</label>
                                                    <input type="number" min="1" className="form-control field-bx-sty" placeholder="30 days" value={props.orderPeriod.freshProvisions.regular === 0 || props.orderPeriod.freshProvisions.regular === "0" ? "" : props.orderPeriod.freshProvisions.regular}
                                                        onChange={(e) => handleChange('regular', e.target.value,
                                                            'orderPeriod', 'freshProvisions')} required />
                                                </div>
                                                <div className="form-group">
                                                    <label className="label-reg-sty text-truncate">Expected delays</label>
                                                    <input type="number" min="0" className="form-control field-bx-sty" placeholder="3 days" value={props.orderPeriod.freshProvisions.expectedDelay === 0 || props.orderPeriod.freshProvisions.expectedDelay === "0" ? "" : props.orderPeriod.freshProvisions.expectedDelay}
                                                        onChange={(e) => handleChange('expectedDelay', e.target.value,
                                                            'orderPeriod', 'freshProvisions')} />
                                                </div>
                                                <div className="form-group total-count-ctn ml-2 mb-0">
                                                    <label className="label-reg-sty pl-0"><span className="labelReg-innSty">Total</span></label>
                                                    <input type="text" className="form-control field-bx-sty bg_orng no-bg without-value" value={Sum(props.orderPeriod.freshProvisions.regular, props.orderPeriod.freshProvisions.expectedDelay)} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment> : ""}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

