import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import {
  GetCustomerAction,
  AppendCustomerAction,
  GetCustomerEmailAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function customerListing(offset, type, filterQuery) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(
        Actions.ApiRequestedAction({ apiCallFor: "appendCustomerList" })
      );
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCustomer" }));
      dispatch(GetCustomerAction([], 0));
    }
    let url = "";
    if (
      filterQuery !== "" &&
      filterQuery !== undefined &&
      filterQuery !== null
    ) {
      url = `/users/${offset}/20?type=${type}${filterQuery}`;
    } else {
      url = `/users/${offset}/20?type=${type}`;
    }
    let myJson = await FETCH("GET", Constant.apiURl + url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendCustomerAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendCustomerList",
            message: "",
          })
        );
      } else {
        dispatch(GetCustomerAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getCustomer",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendCustomerList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getCustomer",
            message: myJson.message,
          })
        );
      }
    }
  };
}
export function deleteCustomer(id) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteCustomer" }));
    let myJson = await FETCH("DELETE", Constant.apiURl + `/users/${id}`);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "deleteCustomer",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "deleteCustomer",
          message: myJson.message,
        })
      );
    }
  };
}
export function updateCustomer(id, data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateCustomer" }));
    let myJson = await FETCH("PATCH", Constant.apiURl + "/users/" + id, data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "updateCustomer",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "updateCustomer",
          message:
            myJson.message && typeof myJson.message === "string"
              ? myJson.message
              : "",
        })
      );
    }
  };
}
export function getCustomerEmail(offset, limit, type) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCustomerEmail" }));
    let myJson = await FETCH(
      "GET",
      Constant.apiURl + `/users/${offset}/${limit}?type=${type}`
    );
    if (myJson && myJson.code === 201) {
      dispatch(GetCustomerEmailAction(myJson.data, myJson.count));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getCustomerEmail",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getCustomerEmail",
          message: myJson.message,
        })
      );
    }
  };
}
