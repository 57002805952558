import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { getCookie, setCookie } from './Services/cookies';
import { AllRoutes } from './Constants/routesConfig';
import SocketConnection from "./Views/SocketConnection/Components/socket";
const createBrowserHistory = require("history").createBrowserHistory;
const history = createBrowserHistory();
export default () => (
    <Router history={history}>
        <SocketConnection />
        <Switch>
            {AllRoutes.map((item, index) => {
                const {
                    component: Component,
                } = item;
                if (item.isPrivate) {
                    return <PrivateRoute key={index} {...item} />
                } else {
                    return <Route exact key={index} path={item.path} name={item.name} component={Component} />
                }
            })}
        </Switch>
    </Router>
);

const PrivateRoute = ({ component: Component, name: name, roles: roles, ...rest }) => {
    let isAuthenticated = getCookie('token');
    let loginUrl = '/login';
    let index = -1;
    index = roles !== undefined ? roles.indexOf(getCookie('userType')) : '';
    if (index === -1) {
        loginUrl = '/not-found'
    }
    if (getCookie('isTokenExpire') === 'true') {
        setCookie('isTokenExpire', '')
        loginUrl = loginUrl + '?msg=expired';
    }
    return <Route {...rest} name={name} render={props => (
        isAuthenticated === '' || index === -1 ?
            <Redirect to={loginUrl} /> :
            <Component {...props} />
    )} />
}

