import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import {
  GetPortsAction,
  AppendPortAction,
  GetPortDetailAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function createPort(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "createPort" }));
    let myJson = await FETCH("POST", Constant.apiURl + "/port", {
      code: data.code,
      country: data.country,
      continent: data.continent,
      continent2: data.continent2,
      region: data.region,
      port: data.port,
      portType: data.portType,
      latitude: data.latitude,
      longitude: data.longitude,
      countryName: data.countryName,
    });
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "createPort",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "createPort",
          message: myJson.message,
        })
      );
    }
  };
}

export function portListing(offset, query) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendPortList" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getPorts" }));
      dispatch(GetPortsAction([], 0));
    }
    let url = Constant.apiURl + "/port/" + offset + "/20";
    if (query && query !== "") {
      url = url + query;
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendPortAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendPortList",
            message: "",
          })
        );
      } else {
        dispatch(GetPortsAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getPorts",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendPortList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getPorts",
            message: myJson.message,
          })
        );
      }
    }
  };
}
export function updatePort(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "updatePort" }));
    let myJson = await FETCH("PUT", Constant.apiURl + "/port", {
      code: data.code,
      country: data.country,
      continent: data.continent,
      continent2: data.continent2,
      region: data.region,
      port: data.port,
      portType: data.portType,
      latitude: data.latitude,
      countryName: data.countryName,
      longitude: data.longitude,
      portId: data.portId,
    });
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "updatePort",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "updatePort",
          message: myJson.message,
        })
      );
    }
  };
}
export function deletePort(portId) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "deletePort" }));
    let myJson = await FETCH("DELETE", Constant.apiURl + "/port", {
      portId,
    });
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "deletePort",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "deletePort",
          message: myJson.message,
        })
      );
    }
  };
}
export function getPortDetail(id) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getPortDetail" }));
    let myJson = await FETCH("GET", Constant.apiURl + "/port/" + id);
    if (myJson && myJson.code === 201) {
      dispatch(
        GetPortDetailAction({
          code: myJson.data.code,
          country: (myJson.data.country && myJson.data.country._id) ? myJson.data.country._id : '',
          continent: myJson.data.continent,
          continent2: myJson.data.continent2,
          region: myJson.data.region,
          port: myJson.data.port,
          portType: myJson.data.portType,
          latitude: myJson.data.latitude,
          longitude: myJson.data.longitude,
          countryName: myJson.data.countryName,
          selectedOption: (myJson.data.country && myJson.data.country._id) ? {
            value: myJson.data.country._id,
            label: myJson.data.country.name,
          } : null,
        })
      );
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getPortDetail",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getPortDetail",
          message: myJson.message,
        })
      );
    }
  };
}
