import React, { useEffect, useState } from 'react';
import { Wrapper,DashboardNavbar } from '../../Common/Components/index';
import { captainListing, deleteCaptain } from "../ApiCalls/captain";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import loader from '../../../assets/images/Spinner-1s-200px.gif';
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from 'sweetalert2'
import { isAllowPermission } from '../../../Services/common';
import { getRoutePathBykey } from "../../../Constants/routesConfig";
function CaptainListing(props) {
    const [state, setState] = useState({
        captainList: [],
        activePage: 0,
        totalCount: 0,
        isHitApi: false
    });
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.captainListing(0);
                let data = { ...state };
                data.isHitApi = true;
                setState(data);
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getCaptain" || props.apiCallStatus.apiCallFor === "appendCaptainList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                captainList: props.captainReducer.list,
                totalCount: props.captainReducer.count,
                isHitApi: true
            })
        }
        if (props.apiCallStatus.apiCallFor === "deleteCaptain" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            Swal.fire("Deleted!", "Captain has been deleted!", "success");
            props.captainListing(0);
        }

    }, [props]);
    const fetchMoreData = () => {
        let { activePage } = state;
        activePage += 1;
        setState({
            ...state,
            activePage
        })
        props.captainListing(activePage)
    };
    const removeRecord = (id) => {
        if (!isAllowPermission('/delete-captain', props.roles)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "You don't have permission of this feature"
            })
        } else {
            Swal.fire({
                title: "Delete Captain",
                text: "Are you sure you want to delete this Captain?",
                icon: "warning",
                buttons: true,
                // showCancelButton: true,
                dangerMode: true,
            })
                .then(result => {
                    if (result.isConfirmed) {
                        props.deleteCaptain(id)
                    }
                });
        }

    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
        {
            img: 'Edit-icon.svg',
            className: "dark-green",
            name: "Create<br />Captain",
            path: "/create-captain",
            isLogout: false
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Captains" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <InfiniteScroll
                dataLength={state.captainList.length}
                next={fetchMoreData}
                height={"80vh"}
                hasMore={state.totalCount <= state.captainList.length ? false : true}
                loader={<div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div>}
            >
                <div className="table-responsive mt-4">
                    <table className="table theme-table-wrap with-action-btns">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Country </th>
                                <th scope="col">Port </th>
                                {/* <th scope="col">Vessel </th> */}
                                {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editCaptain'), props.roles) && !isAllowPermission('/delete-captain', props.roles) ? "" : <th scope="col">Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {props.apiCallStatus.isStarted.indexOf("getCaptain") !== -1 ?
                                <td colSpan="20">
                                    <div className="loader-img text-center">
                                        <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                    </div>
                                </td>
                                : state.captainList && state.captainList.length > 0 ? state.captainList.map((item, index) => {
                                    let isDisableEdit = props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editCaptain'), props.roles) ? true : false;
                                    let isDisableDel = props.isSubAdmin && props.roles.length > 0 && !isAllowPermission('/delete-captain', props.roles) ? true : false;
                                    return <tr key={index}>
                                        <td className="no-wrap">{index + 1}</td>
                                        <td className="no-wrap">{item.name}</td>
                                        <td className="no-wrap">{item.country && (item.country.name !== undefined || item.country.name !== null) ? item.country.name : ''}</td>
                                        <td className="no-wrap">{item.port && (item.port.port !== undefined || item.port.port !== null) ? item.port.port : ''}</td>
                                        {/* <td className="no-wrap">{item.vessel && (item.vessel.name !== undefined || item.vessel.name !== null) ? item.vessel.name : ''}</td> */}
                                        {isDisableDel && isDisableEdit ? "" :
                                            <td>
                                                {isDisableEdit ? "" : <Link to={"/edit-captain/" + item._id} className="btn btn-edit-tab mr-2"></Link>}
                                                {isDisableDel ? "" : <button type="button" onClick={() => removeRecord(item._id)} className="btn btn-dlt-tab"></button>}
                                            </td>}
                                    </tr>

                                }) : state.captainList && state.captainList.length === 0 ?
                                        <td colSpan="40" className="text-center">No Data Found</td> : ''}
                        </tbody>
                    </table>
                </div>
            </InfiniteScroll>
        </Wrapper >
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    captainReducer: state.captainReducer,
    roles: state.authReducer.roles,
    isSubAdmin: state.authReducer.isSubAdmin,

})

const mapDispatchToProps = (dispatch, ownProps) => ({
    captainListing: (offset) => dispatch(captainListing(offset)),
    deleteCaptain: (id) => dispatch(deleteCaptain(id)),
    ApiClearAction: () => dispatch(ApiClearAction)
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CaptainListing);
