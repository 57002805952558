import React from "react";
import Select from 'react-select';
export function SubAdminForm(props) {
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            props.handleSubmit();
        }
    }
    return (
        <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" id="name" name="name" className="form-control" onChange={(e) => { props.handleFormChange(e) }} value={props.name} placeholder="Role Name" required />
                        <lable htmlFor="name" className="foating-labels-sty">Name</lable>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" id="email" name="email" className="form-control" onChange={(e) => { props.handleFormChange(e) }} value={props.email} placeholder="Role Name" required  readOnly={props.isEdit}/>
                        <lable htmlFor="email" className="foating-labels-sty">Email</lable>
                    </div>
                </div>
            </div>
            {props.isEdit ? '' : <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="password" id="password" name="password" className="form-control" onChange={(e) => { props.handleFormChange(e) }} value={props.password} placeholder="Password" autoComplete="new-password" required />
                        <lable htmlFor="password" className="foating-labels-sty">Password</lable>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="password" id="confirmPassword" name="confirmPassword" className="form-control" onChange={(e) => { props.handleFormChange(e) }} value={props.confirmPassword} placeholder="Confirm Password" autoComplete="new-password" required />
                        <lable htmlFor="confirmPassword" className="foating-labels-sty">Confirm Password</lable>
                    </div>
                </div>
            </div>}
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Role Permissions"
                                value={props.role}
                                onChange={(e) => { props.handleFormChange(e) }}
                                options={props.allRoles}
                            />
                        </div>
                    </div>
                    {/* <div className="form-group floating-label-wrap">
                        <input type="text" id="permission" name="permission" className="form-control" onChange={(e) => { props.handleFormChange(e) }} value={props.permission}   placeholder="Role Permissions" required />
                        <lable htmlFor="permission" className="foating-labels-sty">Role Permissions</lable>
                    </div> */}
                </div>
            </div>
            {props.apiCallStatus.apiCallFor === props.apiName && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                <div className="alert alert-danger">
                    {props.apiCallStatus.message}
                </div>
                : ""}
            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {props.apiCallStatus.apiCallFor === props.apiName && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">{props.isEdit ? "Update" : "Submit"}</button>
                </div>
            </div>
        </form>
    )
}