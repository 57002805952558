export const getDocumentsList = "GET_DOCUMENTS_LIST";
export const getSupplierCatalogue = "GET_SUPPLIER_CATALOGUE";
export const AppendSupplierCatalogue = "APPEND_SUPPLIER_CATALOGUE_LIST";
export const getCatalogueDetail = "GET_CATALOGUE_DETAIL";
export function getDocumentsListAction(list) {
  return {
    type: getDocumentsList,
    payload: { list },
  };
}
export function GetSupplierCatalougeAction(list, count) {
  return {
    type: getSupplierCatalogue,
    payload: { list, count },
  };
}
export function AppendSupplierCatalougeAction(list) {
  return {
    type: AppendSupplierCatalogue,
    payload: { list },
  };
}
export function GetCatalogueDetailAction(detail) {
  return {
    type: getCatalogueDetail,
    payload: { detail },
  };
}