export const getNotificationList = "GET_NOTIFICATION_LIST";
export const appendNewNotification = "APPEND_NEW_NOTIFICATION";
export const appendNotificationList = "APPEND_NOTIFICATION_LIST";
export const updateUnReadMsgCount = "UPDATE_UNREAD_MSG_COUNT";
export const readNotificationItem = "READ_NOTIFICATION_ITEM"

export function getNotificationListAction(list, unReadMsgCount, totalCount) {
  return {
    type: getNotificationList,
    payload: { list, unReadMsgCount, totalCount },
  };
}

export function appendNewNotificationAction(data, unReadMsgCount) {
  return {
    type: appendNewNotification,
    payload: { data, unReadMsgCount },
  };
}

export function appendNotificationListAction(data, unReadMsgCount, totalCount) {
  return {
    type: appendNotificationList,
    payload: { data, unReadMsgCount, totalCount },
  };
}

export function updateUnReadMsgCountAction(unReadMsgCount) {
  return {
    type: updateUnReadMsgCount,
    payload: { unReadMsgCount },
  };
}

export function readNotificationAction(notificationId) {
  return {
    type: readNotificationItem,
    payload: { notificationId },
  };
}
