import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Wrapper ,DashboardNavbar} from '../../Common/Components/index';
import { departmentListing, deleteDepartment } from "../ApiCalls/department";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import loader from '../../../assets/images/Spinner-1s-200px.gif';
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from 'sweetalert2'
import { isAllowPermission } from '../../../Services/common';
import { getRoutePathBykey } from "../../../Constants/routesConfig";
function DepartmentList(props) {
    const [state, setState] = useState({
        departmentList: [],
        activePage: 0,
        totalCount: 0,
        isHitApi: false,
    });
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.departmentListing(0);
                let data = { ...state };
                data.isHitApi = true;
                setState(data);
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getDepartment" || props.apiCallStatus.apiCallFor === "appendDepartmentList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                departmentList: props.departmentReducer.list,
                totalCount: props.departmentReducer.count,
                isHitApi: true
            })
        }
        if (props.apiCallStatus.apiCallFor === "deleteDepartment" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            Swal.fire("Deleted!", "Department has been deleted!", "success");
            props.departmentListing(0);
        }

    }, [props]);
    const fetchMoreData = () => {
        let { activePage } = state;
        activePage += 1;
        setState({
            ...state,
            activePage
        })
        props.departmentListing(activePage)
    };
    const removeRecord = (id) => {
        if (!isAllowPermission('/delete-department', props.roles)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "You don't have permission of this feature"
            })
        } else {
            Swal.fire({
                title: "Delete Department",
                text: "Are you sure you want to delete this Department?",
                icon: "warning",
                buttons: true,
                showCancelButton: true,
                dangerMode: true,
            })
                .then(result => {
                    if (result.isConfirmed) {
                        props.deleteDepartment(id)
                    }
                });
        }
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
        {
            img: 'Edit-icon.svg',
            className: "dark-green",
            name: "Create<br/>dept.",
            path: "/create-department",
            isLogout: false
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Departments" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <InfiniteScroll
                dataLength={state.departmentList.length}
                next={fetchMoreData}
                height={"80vh"}
                hasMore={state.totalCount <= state.departmentList.length ? false : true}
                loader={<div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div>}
            >
                <div className="table-responsive mt-4">
                    <table className="table theme-table-wrap with-action-btns">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Department Name</th>
                                {/* <th scope="col">Budget Per Day</th>
                                <th scope="col">Budget Per Year</th> */}
                                {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editDepartment'), props.roles) && !isAllowPermission('/delete-department', props.roles) ? "" : <th scope="col">Action</th>}

                            </tr>
                        </thead>
                        <tbody>
                            {props.apiCallStatus.isStarted.indexOf("getDepartment") !== -1 ?
                                <tr><td colSpan="20">
                                    <div className="loader-img text-center">
                                        <img style={{ width: "46px" }} src={loader} alt='' />
                                    </div>
                                </td></tr>
                                : state.departmentList && state.departmentList.length > 0 ? state.departmentList.map((item, index) => {
                                    let isDisableEdit = props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editDepartment'), props.roles) ? true : false;
                                    let isDisableDel = props.isSubAdmin && props.roles.length > 0 && !isAllowPermission('/delete-department', props.roles) ? true : false;
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        {/* <td>{item.budgetPrice}</td>
                                        <td>{(item.budgetPrice * 365).toFixed(2)}</td> */}
                                        {isDisableDel && isDisableEdit ? "" : <td>
                                            {isDisableEdit ? "" : <Link to={"/edit-department/" + item._id} className="btn btn-edit-tab mr-2"></Link>}
                                            {isDisableDel ? "" : <button type="button" onClick={() => removeRecord(item._id)} className="btn btn-dlt-tab"></button>}
                                        </td>}

                                    </tr>

                                }) : state.departmentList && state.departmentList.length === 0 ?
                                        <tr><td colSpan="40" className="text-center">No Data Found</td></tr> : ''}
                        </tbody>
                    </table>
                </div>
            </InfiniteScroll>
        </Wrapper>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    roles: state.authReducer.roles,
    isSubAdmin: state.authReducer.isSubAdmin,
    departmentReducer: state.departmentReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ApiClearAction: () => dispatch(ApiClearAction()),
    departmentListing: (offset) => dispatch(departmentListing(offset)),
    deleteDepartment: (departmentId) => dispatch(deleteDepartment(departmentId))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DepartmentList);
