// var env = process.env.REACT_APP_ENV;//'production'
export var env = "development";
export const Constant = {
  apiURl:
    env === "development"
      ? "https://mesh.arhamsoft.com/v1"
      : "https://mesh.arhamsoft.com/v1",
  mediaUrl:
    env === "development"
      ? "https://mesh.arhamsoft.com/media/"
      : "https://mesh.arhamsoft.com/media/",
  socketUrl:
    env === "development"
      ? "https://mesh.arhamsoft.com/"
      : "https://mesh.arhamsoft.com/",
};
export const reminderNameList = [
  {
    label: "First Order in order basket",
    value: "First Order in order basket",
  },
  {
    label: "Order basket complete",
    value: "Order basket complete",
  },
  {
    label: "Quotation by suppliers required",
    value: "Quotation by suppliers required",
  },
  {
    label: "Captain sends order / Supplier receives order",
    value: "Captain sends order / Supplier receives order",
  },
  {
    label: "Upcoming delivery",
    value: "Upcoming delivery",
  },
  {
    label: "Order delivery",
    value: "Order delivery",
  },
];
export const inventory = "Inventory";
