import React, { useState } from 'react';
import './orderCatalogue.css';
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import { ApiClearAction } from "../ApiCallStatus/Actions/action";
import { Wrapper ,DashboardNavbar} from '../Common/Components/index';
import DatePicker from "react-datepicker";
const options = [
    { value: 'Provisions', label: 'Provisions' },
    { value: 'Deck', label: 'Deck' },
    { value: 'Engine', label: 'Engine' },
    { value: 'Galley / ', label: 'Galley / ' },
    { value: 'laundry', label: 'laundry' },
    { value: 'Electrical', label: 'Electrical' },
    { value: 'Stationary', label: 'Stationary' },
];

function PrepareQuotationOrder(props) {
    const [state, setState] = useState({
        supplierList: [],
        activePage: 0,
        eta: '',
        totalCount: 0,
        isHitApi: false,
    });
    const handleChange = selectedOption => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name:  "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
        {
            img: 'Edit-icon.svg',
            className: "dark-green",
            name: "Send<br />RFQ",
            path: "/",
            isLogout: false
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Order" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="ordCatg-table-wrap mt-3 sup-rfq-wrap">

                {/* Table */}
                <div className="ordCatg-InnTable store-catgTable">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-left">Dept.</th>
                                    <th>IMPA</th>
                                    <th>Description</th>
                                    <th>Remark</th>
                                    <th>Quantity</th>
                                    <th>Unit</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Decline</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><span className="text-left td-wrap-sty">DECK</span></td>
                                    <td><span className="td-wrap-sty">174321</span></td>
                                    <td><span className="text-left td-wrap-sty">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
                                    <td><span className="whi-round">12</span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="whi-round"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td align="center">
                                        <span className="td-checkbx-wrap"><input type="checkbox" id="test1" /><label htmlFor="test1"></label></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className="text-left td-wrap-sty">DECK</span></td>
                                    <td><span className="td-wrap-sty">174321</span></td>
                                    <td><span className="text-left td-wrap-sty">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
                                    <td><span className="whi-round">12</span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="whi-round"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td align="center">
                                        <span className="td-checkbx-wrap"><input type="checkbox" id="test1" /><label htmlFor="test1"></label></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className="text-left td-wrap-sty">DECK</span></td>
                                    <td><span className="td-wrap-sty">174321</span></td>
                                    <td><span className="text-left td-wrap-sty">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
                                    <td><span className="whi-round">12</span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="whi-round"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td align="center">
                                        <span className="td-checkbx-wrap"><input type="checkbox" id="test1" /><label htmlFor="test1"></label></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className="text-left td-wrap-sty">DECK</span></td>
                                    <td><span className="td-wrap-sty">174321</span></td>
                                    <td><span className="text-left td-wrap-sty">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
                                    <td><span className="whi-round">12</span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="whi-round"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td align="center">
                                        <span className="td-checkbx-wrap"><input type="checkbox" id="test1" /><label htmlFor="test1"></label></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className="text-left td-wrap-sty">DECK</span></td>
                                    <td><span className="td-wrap-sty">174321</span></td>
                                    <td><span className="text-left td-wrap-sty">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
                                    <td><span className="whi-round">12</span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="whi-round"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td align="center">
                                        <span className="td-checkbx-wrap"><input type="checkbox" id="test1" /><label htmlFor="test1"></label></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className="text-left td-wrap-sty">DECK</span></td>
                                    <td><span className="td-wrap-sty">174321</span></td>
                                    <td><span className="text-left td-wrap-sty">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
                                    <td><span className="whi-round">12</span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="whi-round"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td align="center">
                                        <span className="td-checkbx-wrap"><input type="checkbox" id="test1" /><label htmlFor="test1"></label></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className="text-left td-wrap-sty">DECK</span></td>
                                    <td><span className="td-wrap-sty">174321</span></td>
                                    <td><span className="text-left td-wrap-sty">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
                                    <td><span className="whi-round">12</span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="whi-round"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td align="center">
                                        <span className="td-checkbx-wrap"><input type="checkbox" id="test1" /><label htmlFor="test1"></label></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span className="text-left td-wrap-sty">DECK</span></td>
                                    <td><span className="td-wrap-sty">174321</span></td>
                                    <td><span className="text-left td-wrap-sty">ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM</span></td>
                                    <td><span className="whi-round">12</span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td><span className="whi-round"></span></td>
                                    <td><span className="text-left td-wrap-sty"></span></td>
                                    <td align="center">
                                        <span className="td-checkbx-wrap"><input type="checkbox" id="test1" /><label htmlFor="test1"></label></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="tableFoot-content">
                        <ul className="list-inline pl-0 d-flex align-items-center justify-content-end deli-info-list mb-2">
                            <li>Ordered items:<span>xxx</span></li>
                            <li>Confirmed items:<span>xxx</span></li>
                            <li>Amount:<span>x.xxx,xx €</span></li>
                        </ul>
                        <div className="deli-info-ctn d-flex justify-content-end">
                            <div className="deli-info-items-ctn">
                                <div className="form-group mb-1 d-flex align-items-center justify-content-end">
                                    <label>Delivery place:</label>
                                    <input type="text" className="form-control" placeholder="Hamburg" />
                                </div>
                                <div className="form-group mb-1 d-flex align-items-center justify-content-end">
                                    <label>Port:</label>
                                    <input type="text" className="form-control" placeholder="Burchardkai" />
                                </div>
                            </div>
                            <div className="deli-info-items-ctn ml-4">
                                <div className="form-group mb-1 d-flex align-items-center justify-content-end">
                                    <label>Delivery date:</label>
                                    <div className="datePicker form-control field-bx-sty p-0 fa-icon">
                                        <DatePicker selected={state.eta}
                                            onChange={(val) => handleChange({ target: { id: "eta", value: val } }, 'deliveryDetail')} minDate={new Date()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Wrapper>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    supplierReducer: state.supplierReducer,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ApiClearAction: () => dispatch(ApiClearAction()),

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrepareQuotationOrder);