import React, { useEffect, useState } from 'react';
import { VesselWrapper } from './vesselWrapper';
import { getDashboardLink } from '../../../Services/common';
import  DashboardNavbar  from '../../Common/Components/dashboardNavbar';
import { getVesselDetail } from "../ApiCalls/vessel";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import '../Css/settings.css';
import cloneDeep from 'lodash.clonedeep';

const tabsList = [{
    id: "general", label: "General Information", className: "",
    keys: ["name", "alternativeName", "imo", "build", "tdw", { key: "kindOfVessel", type: "object", keyName: "label", }],//, "kindOfVessel"
    headers: ["Vessel Name", "Alternative Name", "IMO-No.:", "build", "tdw / TEU", "Kind of Vessel"]//, "Kind of Vessel"
},
{
    id: "Crew", label: "Crew Information", className: "crew-info-table", isIterateItems: true, keyName: "crewMix",
    keys: [{ key: "crewMix", type: "array", index: 0, }],
    headers: ["Nationality Name"]
},
{
    id: "Operation", label: "Operation Area", className: "operation-info-table", isIterateItems: true, keyName: "rotation", keys: [{ key: "rotation", type: "array", index: 0, }], headers: ["Regions Name"]
},
{
    id: "Ports", label: "Main Supply Ports", className: "operation-info-table", isIterateItems: true, keyName: "supplyPorts", keys: [{ key: "supplyPorts", type: "array", index: 0, }], headers: ["Port Name"]
},
{
    id: "Budget", label: "Budget per Department", className: "", isIterateItems: true, keyName: "departments", keys: [{ key: "departments", type: "array", index: 0, propName: "department", isPropExist: true },
    { key: "departments", type: "array", index: 0, propName: "budget", isPropExist: true }], headers: ["Department Name",
        "Budget"]
},
{
    id: "Invoice", label: "Invoice Information", className: "invoice-info-table", keys: ["invoiceName",
        "invoiceStreet",
        "invoiceCity",
        "vatNo",
        "invoiceRef"], headers: ["Invoice Name",
            "Invoice Street",
            "Invoice City",
            "VAT Nr",
            "Invoice Reference"]
},
{
    id: "config", label: "Config Information", className: "",
    isIterateItems: true, keyName: "categories",
    keys: [{ key: "categories", type: "array", index: 0, propName: "category", isPropExist: true },
    { key: "categories", type: "array", index: 0, propName: "qtyPerDay", isPropExist: true },
    { key: "categories", type: "array", index: 0, propName: "Proportion-of-total-Qty", isPropExist: true },
    { key: "categories", type: "array", index: 0, propName: "Reference-Qty", isPropExist: true },
    { key: "categories", type: "array", index: 0, propName: "Unit", isPropExist: true },
    { key: "categories", type: "array", index: 0, propName: "Price-per-KG-optimum-per-item-category", isPropExist: true },
    { key: "categories", type: "array", index: 0, propName: "Number-of-items-in-optimum-range-per-category", isPropExist: true }],
    headers: ["Category", "Qty Person per day", "Proportion of total Qty", "Reference Qty", "Unit", "Price per KG optimum per item category", "Number of items in optimum range per category"]//, "Kind of Vessel"
}]
function CalculateCatTotal(categories) {
    let categoryTotal = 0;
    let totalRefQty = 0;
    let avgPricePerKg = 0;
    let totalAvgValuePerCat = 0;
    let totalProValOfContractedRateQty = 0;
    let totalProportionTotalQty = 0;
    let totalProportionTotalValue = 0;
    let totalPricePerKGOptimumPerCat = 0;
    let totalItemsPerOptimumPerCat = 0;
    let totalNoOfItemInOptimumRange = 0;
    let totalProValOfRateByValperCat = 0;
    let totalPricePerKgOptimumPerItemCat = 0;
    let durable = 0;
    let fresh = 0;
    let totalNumOfItemInOptimumPerCat = 0;
    let totalDiff = 0;
    categories.forEach(element => {
        let qtyPerDay = parseFloat(element.qtyPerDay);
        categoryTotal += isNaN(qtyPerDay) ? 0 : qtyPerDay;
        totalRefQty += parseFloat(element['Reference-Qty']);
        totalDiff += parseFloat(element['Difference'])
        avgPricePerKg += parseFloat(element["Avg-price-per-KG"])
        totalPricePerKGOptimumPerCat += parseFloat(element['Price-per-KG-optimum-per-item-category']);
        totalItemsPerOptimumPerCat += parseFloat(element["Number-of-items-in-optimum-range-per-category"]);
        totalProportionTotalQty += parseFloat(element['Proportion-of-total-Qty'])
        totalAvgValuePerCat += parseFloat(element["Avg-Value-per-category"]);
        totalProportionTotalValue += parseFloat(element["Proportion-of-Total-Value"]);
        totalProValOfContractedRateQty += parseFloat(element['Proportional-Value-of-contracted-rate-by-qty'])
        totalProValOfRateByValperCat += parseFloat(element['Proportional-Value-of-Rate-by-value-per-category'])
        totalPricePerKgOptimumPerItemCat += parseFloat(element['Price-per-KG-optimum-per-item-category']);
        totalNoOfItemInOptimumRange += parseFloat(element["Number-of-items-in-optimum-range"]);
        totalNumOfItemInOptimumPerCat += parseFloat(element["Number-of-items-in-optimum-range-per-category"])
        if (element.type === "main") {
            durable += parseFloat(element['Proportional-Value-of-contracted-rate-by-qty'])
        } else {
            fresh += parseFloat(element['Proportional-Value-of-contracted-rate-by-qty'])
        }
    });
    if (totalProportionTotalQty > 98) {
        totalProportionTotalQty = 100
    }
    totalDiff = parseFloat((totalDiff / 1000).toFixed(2));
    totalRefQty = parseFloat((totalRefQty / 1000).toFixed(2));
    return {
        categoryTotal: parseFloat((categoryTotal / 1000).toFixed(2)),
        totalItemsPerOptimumPerCat: totalItemsPerOptimumPerCat.toFixed(2),
        totalDiff: totalDiff,
        totalDiffPercentage: parseFloat(((totalDiff / totalRefQty) * 100).toFixed(2)),
        avgPricePerKg: (avgPricePerKg / categories.length).toFixed(2),
        avgPricePerKGOptimumPerCat: (totalPricePerKGOptimumPerCat / categories.length).toFixed(2), totalPricePerKGOptimumPerCat: totalPricePerKGOptimumPerCat.toFixed(2),
        totalRefQty: totalRefQty,
        totalProportionTotalQty: totalProportionTotalQty.toFixed(2),
        totalAvgValuePerCat: parseFloat(totalAvgValuePerCat.toFixed(2)),
        totalProportionTotalValue: parseFloat(totalProportionTotalValue.toFixed(2)),
        totalProValOfContractedRateQty: parseFloat(totalProValOfContractedRateQty.toFixed(2)),
        totalNumOfItemInOptimumPerCat: totalNumOfItemInOptimumPerCat.toFixed(2),
        totalPricePerKgOptimumPerItemCat: (totalPricePerKgOptimumPerItemCat / categories.length).toFixed(2),
        totalProValOfRateByValperCat: totalProValOfRateByValperCat.toFixed(2),
        durable: durable.toFixed(2), fresh: fresh.toFixed(2),
        totalNoOfItemInOptimumRange: totalNoOfItemInOptimumRange.toFixed(2)
    };
}
function VesselInfo(props) {
    const [state, setState] = useState({
        vesselList: [],
        activePage: 0,
        totalCount: 0,
        totalCatStats: {},
        vesselDetail: {},
        isHitApi: false
    });
    useEffect(() => {
        if (!state.isHitApi) {
            props.getVesselDetail(props.match.params.vesselId);
            let data = { ...state };
            data.isHitApi = true;
            setState(data);
        }
        if (props.apiCallStatus.apiCallFor === "getVesselDetail" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            if (props.vesselDetail.categories) {
                let vesselDetail = cloneDeep(props.vesselDetail);
                let totalCat = CalculateCatTotal(props.vesselDetail.categories);
                let item = props.vesselDetail.categories[0];
                if (item) {
                    item["category"] = "Total";
                    item["qtyPerDay"] = totalCat.categoryTotal;
                    item["Proportion-of-total-Qty"] = totalCat.totalProportionTotalQty + "%";
                    item["Reference-Qty"] = totalCat.totalRefQty;
                    item["Unit"] = "KG";
                    item["Price-per-KG-optimum-per-item-category"] = totalCat.totalPricePerKgOptimumPerItemCat;
                    item["Number-of-items-in-optimum-range-per-category"] = totalCat.totalNumOfItemInOptimumPerCat;
                    vesselDetail.categories.push(item);
                }
                setState({
                    ...state,
                    vesselDetail: vesselDetail
                })
            }
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
    }, [props]);
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "go back",
            path: "/vessel-listing",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
        {
            img: 'Monitor-icon.svg',
            className: "dark-blue",
            name: "Dash</br>board",
            path: getDashboardLink(),
            isLogout: false
        }
    ];
    return (
        <VesselWrapper>
            <DashboardNavbar title="Vessel Information" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="setting-wrapper">
                <Tabs tabsHeader={tabsList} />
                <div className="tab-content" id="myTabContent">
                    {tabsList.map((tab, index) => {
                        let vesselDetailLength = Object.size(state.vesselDetail);
                        return <TabWrapper key={index} isActive={index === 0} tableClassName={tab.className} id={tab.id} headers={tab.headers} rate={state.vesselDetail.rate ? state.vesselDetail.rate : 0} durableRate={state.vesselDetail.durableRate ? state.vesselDetail.durableRate : 0} durableRatePercent={state.vesselDetail.durableRatePercent ? state.vesselDetail.durableRatePercent : 0} freshRatePercent={state.vesselDetail.freshRatePercent ? state.vesselDetail.freshRatePercent : 0} freshRate={state.vesselDetail.freshRate ? state.vesselDetail.freshRate : 0} noOfCrew={state.vesselDetail.noOfCrew ? state.vesselDetail.noOfCrew : 0}
                            catConfig={{
                                rateLimitFixed: state.vesselDetail.rateLimitFixed,
                                rateTolerance: state.vesselDetail.rateTolerance,
                                maxQuantityLimitFixed: state.vesselDetail.maxQuantityLimitFixed,
                                maxQuantityTolerance: state.vesselDetail.maxQuantityTolerance,
                                categoyLimitFixed: state.vesselDetail.categoyLimitFixed,
                                maxCategoryTolerance: state.vesselDetail.maxCategoryTolerance,
                                minimumFixed: state.vesselDetail.minimumFixed,
                                sufficientProvisionsMinimum: state.vesselDetail.sufficientProvisionsMinimum,
                                allowDurableProvisionsOrder: state.vesselDetail.allowDurableProvisionsOrder,
                                fixedMainSupplyPorts: state.vesselDetail.fixedMainSupplyPorts,
                            }} userType={props.userType}>
                            {props.apiCallStatus.isStarted.indexOf("getVesselDetail") !== -1 ?
                                <tr><td colSpan="20"><div className="loader-img text-center">
                                    <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                                </div></td></tr> : state.vesselDetail && vesselDetailLength === 0 ?
                                    <tr><td colSpan="40" className="text-center"><div>No Data Found</div></td></tr>
                                    : state.vesselDetail && vesselDetailLength > 0 ?
                                        tab.isIterateItems ? state.vesselDetail[tab.keyName].map((dep, depIndex) => {
                                            return < tr key={depIndex} >
                                                {tab.keys.map((keyName, i) => {
                                                    return <td>{keyName.propName && dep[keyName.propName] !== undefined ? dep[keyName.propName] : dep ? dep : ""}</td>
                                                })}
                                            </tr>
                                        })
                                            : <tr>
                                                {tab.keys.map((keyName, i) => {
                                                    return <td key={i}>{typeof keyName === "string" ?
                                                        state.vesselDetail[keyName] ? state.vesselDetail[keyName]
                                                            : keyName : state.vesselDetail[keyName.key] && state.vesselDetail[keyName.key][keyName.index] ?
                                                            keyName.isPropExist ?
                                                                state.vesselDetail[keyName.key][keyName.index][keyName.propName]
                                                                : state.vesselDetail[keyName.key][keyName.index] : typeof keyName === "object" ? state.vesselDetail[keyName.key][keyName.keyName] : keyName}</td>
                                                })}
                                            </tr> : ""}
                            { }
                        </TabWrapper>
                    })}

                </div>
            </div>
        </VesselWrapper >
    )
}

function Tabs(props) {
    return <ul className="nav nav-tabs" id="myTab" role="tablist">
        {props.tabsHeader.map((item, index) => {
            return <React.Fragment>
                <TabItem key={index} id={item.id} isActive={index === 0} label={item.label} />
            </React.Fragment>
        })}

    </ul>
}

function TabItem(props) {
    return <li className="nav-item" role="presentation">
        <a className={"nav-link " + (props.isActive ? 'active' : '')} id={props.id + "-tab"} data-toggle="tab" href={"#" + props.id} role="tab" aria-controls={props.id} aria-selected="true">{props.label}</a>
    </li>
}

function TabWrapper(props) {
    return <div className={"tab-pane fade " + (props.isActive ? "show active" : "")} id={props.id} role="tabpanel" aria-labelledby={props.id + "-tab"}>
        <div className="settings-steps-wrap">
            <div className="table-responsive">
                {props.id === "Crew" ?
                    <table className="table theme-table-wrap mt-2crew-info-table w-auto table-card-view">
                        <tbody>
                            <tr>
                                <td>Number of Crew</td>
                                <td>{props.noOfCrew}</td>
                                <td>Rate per person per day</td>
                                <td>{props.rate}</td>
                            </tr>
                        </tbody>
                    </table> : ""}
                {props.id === "config" ? <div className="confiq-output-wrap">
                    <div className="form-card">
                        <h5 className="form-title semi-bold sky-blue-clr pl-3 pt-4">Ration Configuration</h5>
                        <div className="vessel-rate-wrap mt-4 card-style-wrap pl-3 mb-4">
                            <div className="card-sty-table with-title">
                                <h6 className="card-table-title">Rate</h6>
                                <div className="table-responsive">
                                    <table className="table vess-rate-lis">
                                        <tbody>
                                            <tr>
                                                <td>Contracted Rate</td>
                                                <td></td>
                                                <td>{props.rate}</td>
                                            </tr>
                                            <tr>
                                                <td>Proportions</td>
                                                <td>{props.durableRate}</td>
                                                <td>{props.durableRatePercent}%</td>
                                            </tr>
                                            <tr>
                                                <td>Fresh</td>
                                                <td>{props.freshRate}</td>
                                                <td>{props.freshRatePercent}%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* Ration Table Content */}
                        <h5 className="form-title semi-bold sky-blue-clr pl-3 pt-4">Ration</h5>
                        <table className={"table theme-table-wrap mt-2" + (props.tableClassName)}>
                            <thead>
                                <tr>
                                    {props.headers.map((headerItem, index) => {
                                        return <th key={index}>{headerItem}</th>
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {props.children}
                            </tbody>
                        </table>
                        {/* Administration Wrap */}
                        {props.userType === "user" ?
                            <div className="vessel-Admin-wrap mt-5">
                                <h6 className="form-title semi-bold sky-blue-clr pl-3">Administration</h6>
                                <div className="formBox vessel-admin-output mt-4">
                                    <form action="">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group floating-label-wrap">
                                                    <input type="text" className="form-control" placeholder="Rate limit fixed" value={props.catConfig.rateLimitFixed ? "Yes" : "No"} readOnly />
                                                    <label className="foating-labels-sty">Rate limit fixed</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group floating-label-wrap">
                                                    <input type="text" className="form-control" placeholder="Rate tolerance" value={props.catConfig.rateTolerance} readOnly />
                                                    <label className="foating-labels-sty">Rate tolerance</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group floating-label-wrap">
                                                    <input type="text" className="form-control" placeholder="Maximum Quantity limit fixed" value={props.catConfig.maxQuantityLimitFixed ? "Yes" : "No"} readOnly />
                                                    <label className="foating-labels-sty">Maximum Quantity limit fixed</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group floating-label-wrap">
                                                    <input type="text" className="form-control" placeholder="Maximum quantity tolerance" value={props.catConfig.maxQuantityTolerance} readOnly />
                                                    <label className="foating-labels-sty">Maximum quantity tolerance</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group floating-label-wrap">
                                                    <input type="text" className="form-control" placeholder="Category limit fixed" value={props.catConfig.categoyLimitFixed ? "Yes" : "No"} readOnly />
                                                    <label className="foating-labels-sty">Category limit fixed</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group floating-label-wrap">
                                                    <input type="text" className="form-control" placeholder="Maximum category tolerance" value={props.catConfig.maxCategoryTolerance} readOnly />
                                                    <label className="foating-labels-sty">Maximum category tolerance</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group floating-label-wrap">
                                                    <input type="text" className="form-control" placeholder="Minimum fixed" value={props.catConfig.minimumFixed ? "Yes" : "No"} readOnly />
                                                    <label className="foating-labels-sty">Minimum fixed</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group floating-label-wrap">
                                                    <input type="text" className="form-control" placeholder="Sufficient provisions minimum" value={props.catConfig.sufficientProvisionsMinimum} readOnly />
                                                    <label className="foating-labels-sty">Sufficient provisions minimum</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group floating-label-wrap">
                                                    <input type="text" className="form-control" placeholder="Allow durable provisions order in fresh top up" value={props.catConfig.allowDurableProvisionsOrder ? "Yes" : "No"} readOnly />
                                                    <label className="foating-labels-sty">Allow durable provisions order in fresh top up</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group floating-label-wrap">
                                                    <input type="text" className="form-control" placeholder="Fixed main supply ports" value={props.catConfig.fixedMainSupplyPorts ? "Yes" : "No"} readOnly />
                                                    <label className="foating-labels-sty">Fixed main supply ports</label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div> : ''}
                    </div>
                </div> : <table className={"table theme-table-wrap mt-2" + (props.tableClassName)}>
                        <thead>
                            <tr>
                                {props.headers.map((headerItem, index) => {
                                    return <th key={index}>{headerItem}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {props.children}
                        </tbody>
                    </table>}
            </div>
        </div>
    </div>
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    vesselDetail: state.vesselReducer.detail,
    userType: state.authReducer.userType
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    getVesselDetail: (vesselId) => dispatch(getVesselDetail(vesselId))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VesselInfo);