import React from 'react';
import moment from "moment";
export function OrderOverView(props) {
    return (
        <div className="ordCatg-wrapper">
            <div className="card-bx-sty d-flex align-items-center flex-column flex-sm-row pb-2 pt-2">
                <div className="d-flex align-items-center flex-nowrap overflow-auto box-item-inn-wrap flex-fill mr-0 mb-0 py-2">
                    <div className="card-bx-sty box-item-inn">
                        <h1 className="text-nowrap">Order Overview</h1>
                        <p className="text-nowrap">{props.department}: <span className="stat-value-sty">{moment().format("DD-MM-YYYY")}</span></p>
                    </div>
                    <div className="card-bx-sty box-item-inn">
                        <h1 className="text-nowrap">Remaining Budget :</h1>
                        <p className="text-nowrap"><span className="stat-value-sty">{props.totalUsedPoints.total}</span> / <span className="stat-value-sty">{props.deptBudget}</span>   =  <span className="stat-value-sty">{100 - props.totalUsedPoints.percentage}</span> %</p>
                    </div>
                </div>
            </div>
        </div>
    )
}