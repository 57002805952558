import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetAllDepartmentAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function getAllDepartment() {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getAllDepartment" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/department/all");
        if (myJson && myJson.code === 201) {
            dispatch(GetAllDepartmentAction(myJson.data))
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getAllDepartment",
                    message: myJson.message,
                }))
        }
        else {
            dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getAllDepartment", message: myJson.message }))
        }
    };
}