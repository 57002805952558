import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { getDashboardLink } from '../../Services/common';
import './marketplace.css';

const options = [
	{ value: 'name', label: 'Name' },
	{ value: 'release-date', label: 'Release Date' },
	{ value: 'valid-until', label: 'Valid Until' },
	{ value: 'availability', label: 'Availability' },
	{ value: 'low-price', label: 'Low Price' },
];

export default class supplierprofile extends Component {
	state = {
		selectedOption: null,
	};
	handleChange = selectedOption => {
		this.setState({ selectedOption });
	};
	render() {
		const { selectedOption } = this.state;
		return (
			<div className="pageWrapper py-4">
				<div className="d-flex justify-content-end">
					<Link to={getDashboardLink()} className="pageGoBackBtn text-center">Back</Link>
				</div>
				<div className="pageContainer">
					<div className="order-dlts-wrapper mb-3 mb-lg-0">
						<div className="pageHeader d-flex align-items-center flex-column flex-lg-row">
							<div className="d-flex flex-fill align-items-center mesh-logoWrap flex-column flex-sm-row ">
								<div className="logoHolder mr-sm-4 text-sm-left text-center">
									<img className="img-fluid" src={require('../../assets/images/logo.png')} alt="Logo" />
								</div>
								<h1 className="btn-sty-title flex-fill my-3 my-lg-0">Market Place</h1>
							</div>
							<div className="mesh-control-btns d-flex align-items-center">
								<button type="button" className="btn btn-polygon-sty dark-pink">
									<img className="img-fluid" src={require('../../assets/images/Shopping-cart.svg')} alt="icon" />
									<span>Order<br />basket</span>
								</button>
								<button type="button" className="btn btn-polygon-sty dark-blue">
									<img className="img-fluid" src={require('../../assets/images/Monitor-icon.svg')} alt="icon" />
									<span>Dash<br />board</span>
								</button>
								<button type="button" className="btn btn-polygon-sty green">
									<img className="img-fluid" src={require('../../assets/images/Message-icon.svg')} alt="icon" />
									<span>Send<br />Order</span>
								</button>
							</div>
						</div>
					</div>
					<div className="pageContent supplierProfilePageContent">
						<div className="tabsHeader d-flex justify-center flex-column flex-lg-row mb-3">
							<div className="specialOfferBlock">
								<img className="img-fluid" src={require('../../assets/images/special offer.png')} alt="special offer" />
							</div>
							<ul className="nav nav-tabs flex-fill d-flex justify-contnet-start justify-content-lg-center text-center" id="myTab" role="tablist">
								<li className="nav-item mx-1 mb-2">
									<a className="nav-link active" id="new-release" data-toggle="tab" href="#newRelease" role="tab" aria-controls="newRelease" aria-selected="true">New Releases</a>
								</li>
								<li className="nav-item mx-1 mb-2">
									<a className="nav-link" id="best-sellers" data-toggle="tab" href="#bestSellers" role="tab" aria-controls="bestSellers" aria-selected="false">Best sellters</a>
								</li>
								<li className="nav-item mx-1 mb-2">
									<a className="nav-link" id="provisions-tab" data-toggle="tab" href="#provisions" role="tab" aria-controls="provisions" aria-selected="false">Provisions</a>
								</li>
								<li className="nav-item mx-1 mb-2">
									<a className="nav-link" id="stores-tab" data-toggle="tab" href="#stores" role="tab" aria-controls="stores" aria-selected="false">Stores</a>
								</li>
								<li className="nav-item mx-1 mb-2">
									<a className="nav-link" id="spare-parts-tab" data-toggle="tab" href="#spare-parts" role="tab" aria-controls="spare-parts" aria-selected="false">Spare Parts</a>
								</li>
							</ul>
							<div className="filterSelectHolder">
								<div className="custom-select-menu text-bx-wrap custom-select2">
									<Select className="custom-select-wrap" classNamePrefix="react-select" placeholder="Sort By"
										value={selectedOption}
										onChange={this.handleChange}
										options={options}
									/>
								</div>
							</div>
						</div>
						<div className="tab-content" id="myTabContent">
							<div className="tab-pane fade show active" id="newRelease" role="tabpanel" aria-labelledby="new-release">
								<div className="marketPlaceItems">
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img04.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img05.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img06.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="bestSellers" role="tabpanel" aria-labelledby="best-sellers">
								<div className="marketPlaceItems">
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img04.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img05.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img06.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="provisions" role="tabpanel" aria-labelledby="provisions-tab">
								<div className="marketPlaceItems">
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img04.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img05.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img06.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="stores" role="tabpanel" aria-labelledby="stores-tabs">
								<div className="marketPlaceItems">
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img04.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img05.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img06.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="spare-parts" role="tabpanel" aria-labelledby="spare-parts-tab">
								<div className="marketPlaceItems">
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img04.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img05.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
									<div className="marketPlaceItem d-flex mb-3 flex-column flex-md-row">
										<div className="marketProductHolder mb-3">
											<div className="imageHolder px-2">
												<img className="img-fluid" src={require('../../assets/images/img06.png')} alt="market" />
											</div>
										</div>
										<div className="flex-fill d-flex flex-column flex-lg-row">
											<div className="detailHolder px-2 px-md-5 flex-fill d-flex flex-column">
												<div className="logoLocationHolder d-flex flex-column flex-sm-row w-100 align-items-center mb-2">
													<div className="w-100 text-center">
														<div className="marketPlaceLogoHolder d-inline-block align-top">
															<img className="img-fluid" src={require('../../assets/images/supplier-logo.png')} alt="Supllier logg" />
														</div>
													</div>
													<div className="locationHolder w-100 text-center text-sm-left">
														<span className="locationName">Shanghai</span>
													</div>
												</div>
												<div className="namePriceHolder w-100 d-flex flex-column mb-2">
													<div className="nameHolder text-uppercase">IMPA 190102 - GLOVES WORKING COTTON, RUBBER COATED PALM</div>
													<div className="priceHolder pl-md-5">0,59 € / pair</div>
												</div>
											</div>
											<div className="dateDetailHolder px-2 d-flex flex-lg-column flex-md-row flex-column">
												<span className="validToDate d-block w-100 align-self-lg-start mb-2">valid until 31.10.2020</span>
												<Link className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm-right mb-2" to="">View Details</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <span className="marketPlaceBottomText text-white">*meshing = connect your vessel(s) to our worlwide network of suppliers &amp; knowledge</span> */}
				</div>
			</div>
		)
	}
}