import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { getCurrentUserVesselAction, getAllVesselAction, GetVesselByIdAction,getVesselKindAction } from "../Actions/action";
import moment from 'moment';
import { FETCH } from "../../../Services/fetch";
import { getCookie, setCookie } from "../../../Services/cookies";
export function getAllVessel() {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getAllVessel" }));
        let url = '';
        if (getCookie('userType') === 'customer') {
            url = Constant.apiURl + "/vessel/all?userId=" + getCookie('userId')
        } else {
            url = Constant.apiURl + "/vessel/all"
        }
        let myJson = await FETCH("GET", url);
        if (myJson && myJson.code === 201) {
            dispatch(getAllVesselAction(myJson.data))
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getAllVessel",
                    message: myJson.message,
                }))
        }
        else {
            dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getAllVessel", message: myJson.message }))
        }
    };
}
export function getCurrentUserVessel(userId, vesselId, dateFilter) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCurrentUserVessel" }));
        let url = "";
        if (userId !== undefined && dateFilter !== undefined) {
            url = Constant.apiURl + "/vessel/customer/" + userId + `?userType=${getCookie('userType')}&&from=${moment(dateFilter).format('YYY-MM-DD')}&&to=${moment(dateFilter).add(1, 'd').format('YYY-MM-DD')}`
        } else if (userId !== undefined && dateFilter !== undefined) {
            url = Constant.apiURl + "/vessel/customer/" + userId + `?userType=${getCookie('userType')}&&from=${moment(dateFilter).format('YYY-MM-DD')}&&to=${moment(dateFilter).add(1, 'd').format('YYY-MM-DD')}`
        } else if (userId !== undefined && vesselId !== '') {
            url = Constant.apiURl + "/vessel/customer/" + userId + `?userType=${getCookie('userType')}&&vesselId=${vesselId} `
        }
        else {
            url = Constant.apiURl + "/vessel/customer/" + userId+`?userType=${getCookie('userType')}`
        }
        let myJson = await FETCH("GET", url);
        if (myJson && myJson.code === 201) {
            if (getCookie('userType') === 'customer' && myJson.data.length === 2) {
                setCookie('isShipManager', true)
            } else if (getCookie('userType') === 'customer' && myJson.data.length < 2) {
                setCookie('isShipManager', false)
            }
            dispatch(getCurrentUserVesselAction(myJson.data))
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getCurrentUserVessel",
                    message: myJson.message,
                }))
        }
        else {
            dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getCurrentUserVessel", message: myJson.message }))
        }
    };
}
export function getVesselById(id) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getVesselById" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/vessel/" + id);
        if (myJson && myJson.code === 201) {
            dispatch(
                GetVesselByIdAction(myJson.data)
            );
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getVesselById",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getVesselById",
                    message: myJson.message,
                })
            );
        }
    };
}
export function getVesselKind() {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getVesselKind" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/cms/vessel/type");
        if (myJson && myJson.code === 201) {
            dispatch(getVesselKindAction(myJson.data))
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getVesselKind",
                    message: myJson.message,
                }))
        }
        else {
            dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getVesselKind", message: myJson.message }))
        }
    };
}