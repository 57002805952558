/* eslint-disable no-useless-escape */
import { getRoutePathBykey } from "../Constants/routesConfig";
import { getCookie, setCookie } from "./cookies";

export function getHeaders(isFormData) {
  let token = getCookie("token");
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  if (token) {
    headers["Authorization"] = "Bearer " + token;
    headers["access-token"] = token;
  }
  if (isFormData) {
    delete headers["Accept"];
    delete headers["Content-Type"];
  }
  return headers;
}

export function logoutUser() {
  setCookie("token", "");
  setCookie("userType", "");
  setCookie("isVerified", "");
  setCookie("vesselCount", "");
  setCookie("isShipManager", "");
  setCookie("userId", "");
  setCookie("email", "");
  setCookie("logo", "");
  setCookie("roles", "");
  setCookie("permission", "");
  setCookie("isSubAdmin", "");
  setCookie("roleName", "");
  setCookie("subType", "");
  setCookie("activeVesselId", "");
}

export const Sum = (a, b) => {
  let num = parseInt(a);
  let num2 = parseInt(b);
  let res = 0;
  if (!isNaN(num)) {
    res += num;
  }
  if (!isNaN(num2)) {
    res += num2;
  }
  return res;
};
export const getLoginDashboardLink = () => {
  let link = "/welcome";
  return link;
};
export const getDashboardLink = () => {
  let isShipManger = getCookie("isShipManager");
  let userType = getCookie("userType");
  let isSubAdmin = getCookie("isSubAdmin");
  let subType = getCookie("subType");
  let link = "";
  if (isSubAdmin === "true" && userType === "user" && subType !== "subAdmin") {
    var roles = getCookie("roles");
    roles = roles !== "" ? JSON.parse(roles) : [];
    // if (roles.length > 0 && subType === 'captain') {
    //   link = "/order-basket"
    // }
    if (roles.length > 0 && roles.indexOf("/user-dashboard") !== -1) {
      link = "/user-dashboard";
    } else {
      link = "/contract-vessel";
    }
  } else if (isShipManger === "true" || userType === "user") {
    link = "/contract-office";
  } else if (userType === "supplier") {
    link = "/supplier-dashboard";
  } else {
    link = "/contract-vessel";
  }
  return link;
};

export const getCountryPrice = (arr, country) => {
  let price = 0;
  let packageUnit = "";
  let orKg = 0;
  // baseQuantity: 1
  // name: "GERMANY"
  // packageUnit: "KGS"
  // price: 1.3426952451342697
  let index = arr.findIndex((x) => x.name === country);
  if (index !== -1) {
    price = arr[index].price;
    packageUnit = arr[index].packageUnit;
    orKg = arr[index].baseQuantity;
  } else {
    index = arr.findIndex((x) => x.name === "GERMANY");
    if (index !== -1) {
      price = arr[index].price;
      packageUnit = arr[index].packageUnit;
      orKg = arr[index].baseQuantity;
    }
    // else if (arr.length > 0) {
    //   price = arr[0].price;
    // }
  }
  return { price, packageUnit, orKg };
};

Object.size = function (obj) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);

  }else{
    return string

  }
}

export function isAllowPermission(path, roles) {
  let isAllow = false;
  let currentUrl = path.split("/")[1] ? path.split("/")[1] : path;
  if (
    getCookie("userType") !== "user" ||
    getCookie("isSubAdmin") !== "true" ||
    roles.length === 0
  ) {
    isAllow = true;
  } else if (roles.length > 0) {
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].includes(currentUrl)) {
        isAllow = true;
        break;
      }
    }
  }
  return isAllow;
}
