import * as Action from "../Actions/action";
import InitialState from "../../../Constants/initialState";
import cloneDeep from "lodash/cloneDeep";
export default function UploadReducer(state = InitialState.upload, action) {
  switch (action.type) {
    case Action.getDocumentsList:
      let s = cloneDeep(state);
      s.documentList = action.payload.list;
      return s;
    case Action.getSupplierCatalogue:
      let s2 = cloneDeep(state);
      s2.catalogueList = action.payload.list;
      s2.totalCatalogue = action.payload.count;
      return s2;
    case Action.AppendSupplierCatalogue:
      var s3 = cloneDeep(state);
      var arr = s3.catalogueList.slice();
      s3.catalogueList = arr.concat(action.payload.list);
      return s3;
      case Action.getCatalogueDetail:
        let s4 = cloneDeep(state);
        s4.detail = action.payload.detail;
        return s4;
    default:
      return state;
  }
}
