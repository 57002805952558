import React, { Component } from 'react';
import { Wrapper } from '../../../Css/createOrder';
import { DeliveryDetail } from "./deliveryDetail";
import { OrderDetail } from "../orderDetail";
import { VesselCrewDetail } from "./vesselCrewDetail";
import { saveOrder, getOrderDetail } from './../../../ApiCalls/provisionOrder';
import DashboardNavbar from '../../../../Common/Components/dashboardNavbar';
import { getAllPort } from "../../../../Common/ApiCalls/portList";
import { getAllCountry } from "../../../../Common/ApiCalls/countryList";
import { getVesselById } from "../../../../Common/ApiCalls/vesselList";
import { AddOrderAction } from '../../../Actions/action';
import { connect } from 'react-redux';
import { getDashboardLink, Sum } from '../../../../../Services/common';
import $ from 'jquery';
import cloneDeep from 'lodash.clonedeep';
import Swal from 'sweetalert2'
import moment from 'moment';
var orderKind = null;
var deptBudget = null;
var rank = null;
class CreateStoreOrder extends Component {
	constructor() {
		super();
		const urlParams = new URLSearchParams(window.location.search);
		const orderId = urlParams.get('orderId');
		const isOrderKindDisable = urlParams.get('orderKind')
		const isRankDisable = urlParams.get('rank')
		this.state = {
			orderId,
			isOrderKindDisable,
			isRankDisable,
			// Order Detail
			orderTypesOptions: [],
			vesselList: [],
			captainList: [],
			rankList: [
				{ value: "user", label: "user" },
				{ value: "crew", label: "crew" },
				{ value: "shipManager", label: "shipManager" },
				{ value: "subAdmin", label: "subAdmin" },
				{ value: "cook", label: "cook" },
				{ value: "controller", label: "controller" },
				{ value: "captain", label: "captain" },
				{ value: "chiefMate", label: "chiefMate" },
				{ value: "chiefEngineer", label: "chiefEngineer" },
				{ value: "electrician", label: "electrician" },
				{ value: "superIntendent", label: "superIntendent" },
				{ value: "purchaseManager", label: "purchaseManager" },
				{ value: "purchaser", label: "purchaser" },
				{ value: "managementCustomer", label: "managementCustomer" },
			],
			portList: [],
			countryList: [],//countryList,

			orderKind: "",
			deptBudget: 0,
			// Delivery Detail
			deliveryDetail: {
				imoNum: "",
				vessel: "",
				refNo: '',
				captain: "",
				rank: "",
				place: "",
				country: "",
				orderBy: "",
				agentDetail: "",
				eta: "",
				etd: "",
				budgetPerDay: "",
				currency: "",
				numberOfCrew: {
					regular: 0,
					additional: 0
				},
				orderPeriod: {
					regular: 0,
					expectedDelay: 0
				},
			},
			// Vessel Detail
			vesselDetail: {
				vesselType: "",
				nationality: "",
				nationalityRating: ""
			},
			// Error Handlers
			errorMessage: "",
			errorType: "",
			errorFor: "",
			isSaveAndClose: false

		};
	}
	componentDidMount() {
		this.props.getVesselById(this.props.match.params.vesselId);
		this.props.getAllCountry();
		this.disableScroll();
		Swal.fire("Welcome to your stores order",
			"<p>MESH supports you to order the best possible consumables for your good crew.</p><p>Please create your order smart as there will not be any cuttings on your order quantities.</p><h6>Thank you, your MESH team.</h6>"
			, "success");
		if (this.state.orderId) {
			if (Object.size(this.props.currentOrder) > 0 && this.props.currentOrder._id === this.state.orderId) {
				this.upadteOrderState(this.props.currentOrder)
			} else {
				this.props.getOrderDetail(this.state.orderId)
			}
		} else {
			let subType = this.props.userDetail.subType;
			let index = this.state.rankList.findIndex(x => x.label == subType);
			let rank = ""
			if (index !== -1) {
				rank = this.state.rankList[index];
				let deliveryDetail = { ...this.state.deliveryDetail };
				deliveryDetail.rank = this.state.rankList[index];
				this.setState({
					deliveryDetail
				})
			}
			this.props.AddOrderAction({});
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.apiCallStatus.isStarted.indexOf('getVesselById') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getVesselById') === -1) {
			const urlParams = new URLSearchParams(window.location.search);
			orderKind = (this.state.orderKind === '' && urlParams.get('orderKind')) ? urlParams.get('orderKind') : this.state.orderKind;
			deptBudget = (this.state.deptBudget === 0 && urlParams.get('deptBudget')) ? urlParams.get('deptBudget') : this.state.deptBudget;
			rank = urlParams.get('rank');
			let deliveryDetail = { ...this.state.deliveryDetail }
			let keys = Object.keys(this.props.vesselDetail);
			let departmentList = [];
			let captainList = [];
			if (keys.length > 0) {
				deliveryDetail["imoNum"] = this.props.vesselDetail.imo;
				// deliveryDetail["refNo"] = this.props.vesselDetail.imo + month + dep + time;
				deliveryDetail["budgetPerDay"] = this.props.vesselDetail.rate;
				deliveryDetail["currency"] = this.props.vesselDetail.currency;
				deliveryDetail["vessel"] = { label: this.props.vesselDetail.name, value: this.props.vesselDetail._id }
				captainList = this.props.vesselDetail.captain && this.props.vesselDetail.captain.length > 0 ? this.props.vesselDetail.captain : [];
				if (this.state.orderId && Object.size(this.props.currentOrder) > 0) {
					let captainIndex = captainList.findIndex(x => x.value === this.props.currentOrder.captain);
					if (captainIndex !== -1) {
						deliveryDetail["captain"] = captainList[captainIndex]
					}
				}
				if (this.props.vesselDetail && this.props.vesselDetail.departments && this.props.vesselDetail.departments.length > 0) {
					for (let index = 0; index < this.props.vesselDetail.departments.length; index++) {
						let val = this.props.vesselDetail.departments[index];
						if (this.props.userDetail.department === undefined || (this.props.userDetail.department && this.props.userDetail.department.indexOf(val.department) !== -1)) {
							departmentList.push({ value: val.department, label: val.department, budget: val.budget })
						}
					}
					if (this.state.orderKind === '' && orderKind) {
						let newIndex = this.props.vesselDetail.departments.findIndex(x => x.department === orderKind);
						if (newIndex !== -1) {
							let month = moment().format("MM");
							let time = moment().format("hhmmss");
							let year = moment().format("YY");
							deliveryDetail["refNo"] = this.props.vesselDetail.imo + "_" + year + month + orderKind.substring(0, 2) + "_" + time;
						}
					}

				}
				if (rank) {
					deliveryDetail['rank'] = { value: rank, label: rank };
				}
			} else {
				this.setState({
					errorMessage: "No vessel found",
					errorType: "danger",
					errorFor: "orderDetail"
				})

			}
			this.setState({
				captainList,
				deliveryDetail,
				deptBudget,
				orderKind,
				orderTypesOptions: departmentList
			})
		}
		if (prevProps.apiCallStatus.isStarted.indexOf('getAllPort') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getAllPort') === -1) {
			let portList = [];
			for (let index = 0; index < this.props.portList.length; index++) {
				let val = this.props.portList[index];
				portList.push({ label: val.port, value: val._id })
			}
			this.setState({
				portList
			})
		}
		if (prevProps.apiCallStatus.isStarted.indexOf('getAllCountry') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getAllCountry') === -1) {
			let countryList = [];
			for (let index = 0; index < this.props.countryList.length; index++) {
				let val = this.props.countryList[index];
				countryList.push({ label: val.name, value: val._id, countryCode: val.countryCode, name: val.name })
			}
			this.setState({
				countryList
			})
		}
		if ((prevProps.apiCallStatus.isStarted.indexOf('saveOrder') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('saveOrder') === -1) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			let id = this.props.apiCallStatus.message;
			if (this.state.isSaveAndClose) {
				this.props.history.push(getDashboardLink())
			} else {
				this.props.history.push('/store-catalogue/' + id);

			}
		}
		if (prevProps.apiCallStatus.isStarted.indexOf('getOrderDetail') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getOrderDetail') === -1 && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			if (Object.size(this.props.currentOrder) > 0) {
				this.upadteOrderState(this.props.currentOrder);
			}
		}
	}
	handleSelectChange = (selectedOption, id) => {
		let obj = {};
		obj[id] = selectedOption.value;
		if (id === "orderKind") {
			let deliveryDetail = cloneDeep(this.state.deliveryDetail);
			let month = moment().format("MM");
			let time = moment().format("hhmmss");
			let year = moment().format("YY");
			deliveryDetail["refNo"] = deliveryDetail.imoNum + "_" + year + month + selectedOption.value.substring(0, 2) + "_" + time;
			obj.deliveryDetail = deliveryDetail;
		}
		if (id === 'orderKind') {
			obj.deptBudget = selectedOption.budget
		}
		this.setState(obj);
	};
	handleChange = (e, changeFor) => {
		let cloneObj = { ...this.state[changeFor] };
		let id = e.target.id;
		let val = e.target.value;
		cloneObj[id] = val;
		if (id === "country") {
			let query = "countryCode=" + val.countryCode;
			cloneObj["place"] = "";
			this.props.getAllPort(query)
		} else if (id === 'eta') {
			cloneObj["etd"] = "";
		}
		this.setState({
			[changeFor]: cloneObj
		})
	}
	validateForm = () => {
		var form = document.getElementsByClassName('needs-validation')[0];
		let isValid = true;
		let errorMessage = "";
		let errorType = "";
		let errorFor = "";
		if (this.state.orderKind === "") {
			isValid = false;
			errorMessage = "Please select Department";
			errorType = "danger";
			errorFor = "orderDetail";
		} else if (form.checkValidity() === false) {
			isValid = false;
			form.classList.add('was-validated');
		} else if (this.state.deliveryDetail.vessel === "") {
			isValid = false;
			errorMessage = "Please select vessel";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (this.state.deliveryDetail.captain === "") {
			isValid = false;
			errorMessage = "Please select captain";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (this.state.deliveryDetail.captain.label === this.state.deliveryDetail.captain.value) {
			isValid = false;
			errorMessage = "Selected captain doesn't exist";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (this.state.deliveryDetail.rank === "") {
			isValid = false;
			errorMessage = "Please select rank";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (this.state.deliveryDetail.place === "") {
			isValid = false;
			errorMessage = "Please select place of delivery";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (this.state.deliveryDetail.country === "") {
			isValid = false;
			errorMessage = "Please select country";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (this.state.deliveryDetail.eta === "") {
			isValid = false;
			errorMessage = "Please select ETA";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (this.state.deliveryDetail.etd === "") {
			isValid = false;
			errorMessage = "Please select ETD";
			errorType = "danger";
			errorFor = "deliveryDetail";
		}
		// if (!isValid && errorMessage !== '') {
		// Swal.fire("Validation Error", errorMessage, "error","");
		// Swal.fire({
		// 	title: "Validation Error!",
		// 	text: errorMessage,
		// 	icon: "error",
		// showCancelButton: true,
		// 	customClass: 'Swal.fire-wide'
		// });
		// }
		this.setState({
			errorMessage,
			errorType,
			errorFor
		})
		return isValid;
	}
	handleSubmit = (e) => {
		e.preventDefault();
		let isValid = this.validateForm();
		if (isValid) {
			let data = cloneDeep(this.state.deliveryDetail);
			data.country.label = this.updateCountryLabel(data.country);
			let orderNeeded = Number(((Sum(data.orderPeriod.regular, data.orderPeriod.expectedDelay) * (this.state.deptBudget)) / 365).toFixed(2))
			let deliveryDetailObj = {
				imoNum: data.imoNum,
				refNo: data.refNo,
				vessel: data.vessel.value,
				captain: data.captain.value,
				rank: data.rank.value,
				place: data.place,
				country: data.country,
				orderBy: data.orderBy,
				eta: data.eta,
				etd: data.etd,
				agentDetail: data.agentDetail,
				budgetPerDay: data.budgetPerDay,
				currency: data.currency,
				numberOfCrew: data.numberOfCrew,
				orderPeriod: data.orderPeriod,
				department: this.state.orderKind,
				deptBudget: this.state.deptBudget,
				vesselRate: data.budgetPerDay,
				userId: this.props.userDetail.userId,
				orderNeeded,
				orderProvisionType: "store",
				orderDetail: this.props.currentOrder.orderDetail ? this.props.currentOrder.orderDetail : {},
				redirectionPath: "/create-store-order/" + data.vessel.value + "?orderId="
			}
			if (this.state.orderId) {
				deliveryDetailObj.orderId = this.state.orderId;
			}
			this.props.AddOrderAction(deliveryDetailObj);
			this.props.saveOrder(deliveryDetailObj);
		}
	}
	updateCountryLabel = (country) => {
		let label = '';
		let countryLable = country.label.trim();
		countryLable.toUpperCase();
		if (countryLable === "DEMO COUNTRY") {
			label = countryLable;
		} else if (countryLable === "Africa" || countryLable === "AFRICA") {
			label = "AFRICA"
		} else if (countryLable === "Caribbean" || countryLable === "CARIBBEAN") {
			label = "CARIBBEAN"
		}
		else if (countryLable === "Africa South" || countryLable === "AFRICA SOUTH") {
			label = "AFRICA SOUTH"
		}
		else if (countryLable === "Australia" || countryLable === "AUSTRALIA") {
			label = "AUSTRALIA"
		}
		else if (countryLable === "Brazil" || countryLable === "BRAZIL") {
			label = "BRAZIL"
		}
		else if (countryLable === "Chile" || countryLable === "CHILE") {
			label = "CHILE"
		}
		else if (countryLable === "China" || countryLable === "CHINA") {
			label = "CHINA"
		}
		else if (countryLable === "Corea" || countryLable === "COREA") {
			label = "COREA"
		}
		else if (countryLable === "Greece" || countryLable === "GREECE") {
			label = "GREECE"
		}
		else if (countryLable === "India" || countryLable === "INDIA") {
			label = "INDIA"
		}
		else if (countryLable === "Japan" || countryLable === "JAPAN") {
			label = "JAPAN"
		}
		else if (countryLable === "malaysia" || countryLable === "MALAYSIA") {
			label = "MALAYSIA"
		}
		else if (countryLable === "Malta" || countryLable === "MALTA") {
			label = "MALTA"
		}
		else if (countryLable === "Panama" || countryLable === "PANAMA") {
			label = "PANAMA"
		}
		else if (countryLable === "Singapore" || countryLable === "SINGAPORE") {
			label = "SINGAPORE"
		}
		else if (countryLable === "Slovenia" || countryLable === "SLOVENIA") {
			label = "SLOVENIA"
		}
		else if (countryLable === "Spain East" || countryLable === "SPAIN EAST") {
			label = "SPAIN EAST"
		}
		else if (countryLable === "Spain" || countryLable === "SPAIN") {
			label = "SPAIN"
		}
		else if (countryLable === "Sri Lanka" || countryLable === "SRI LANKA") {
			label = "SRI LANKA"
		}
		else if (countryLable === "Turkey" || countryLable === "TURKEY") {
			label = "TURKEY"
		}
		else if (countryLable === "UAE" || countryLable === "UAE") {
			label = "UAE"
		}
		else if (countryLable === "USA East" || countryLable === "USA EAST") {
			label = "USA EAST"
		}
		else if (countryLable === "USA South" || countryLable === "USA SOUTH") {
			label = "USA SOUTH"
		}
		else if (countryLable === "USA West" || countryLable === "USA West") {
			label = "USA WEST"
		}
		else if (countryLable === "New Zealand" || countryLable === "NEW ZEALAND") {
			label = "NEW ZEALAND"
		} else if (countryLable === "DEMO COUNTRY" || countryLable === "DEMO COUNTRY") {
			label = "DEMO COUNTRY"
		} else {
			label = "GERMANY";
		}
		return label;
	}
	disableScroll = () => {
		$('form').on('focus', 'input[type=number]', function (e) {
			$(this).on('wheel.disableScroll', function (e) {
				e.preventDefault()
			})
		})
	}
	upadteOrderState = (currentOrder) => {
		let obj = {};
		obj.orderKind = currentOrder.department;
		obj.deliveryDetail = { ...this.state.deliveryDetail };
		obj.deliveryDetail.place = currentOrder.place;
		obj.deptBudget = currentOrder.deptBudget
		obj.deliveryDetail.country = currentOrder.country;
		obj.deliveryDetail["refNo"] = currentOrder.refNo;
		obj.deliveryDetail.agentDetail = currentOrder.agentDetail;
		obj.deliveryDetail.eta = new Date(currentOrder.eta);
		obj.deliveryDetail.etd = new Date(currentOrder.etd);
		obj.deliveryDetail.orderBy = currentOrder.orderBy;
		obj.deliveryDetail.rank = { label: currentOrder.rank, value: currentOrder.rank };
		obj.deliveryDetail.orderPeriod = currentOrder.orderPeriod;
		if (this.state.orderId) {
			if (this.state.captainList.length > 0) {
				let captainIndex = this.state.captainList.findIndex(x => x.value === currentOrder.captain);
				if (captainIndex !== -1) {
					obj.deliveryDetail["captain"] = this.state.captainList[captainIndex]
				}
			}
		}
		this.setState(obj)
	}
	saveAndClose = (e) => {
		e.preventDefault();
		this.setState({
			isSaveAndClose: true,
		}, () => this.handleSubmit(e))
	}
	render() {
		const navbarOptions = [
			{
				img: 'Monitor-icon.svg',
				className: "dark-blue",
				name: "Dash<br />board",
				path: getDashboardLink(),
				isLogout: false
			}
		];
		return (
			<Wrapper>
				<div className="pageWrapper py-4">
					<div className="pageContainer mb-0">
						<div className="order-dlts-wrapper">
							<DashboardNavbar title="MESH your stores order" navbarOptions={navbarOptions} isShowLogo={true} logoUrl="logo.png" logoType="Order" className="pageHeader d-flex align-items-center flex-column flex-lg-row" />
						</div>
						<form className="needs-validation" onSubmit={(e) => this.handleSubmit(e)} noValidate>
							{/* Order Details  */}
							<OrderDetail orderKind={this.state.orderKind} orderTypesOptions={this.state.orderTypesOptions} handleSelectChange={this.handleSelectChange} isOrderKindDisable={this.state.isOrderKindDisable ? true : false} name="Departments" placeholder="Select Department" errorMessage={this.state.errorMessage}
								errorFor={this.state.errorFor} errorType={this.state.errorType} />
							{/* Delivery Details  */}
							<DeliveryDetail errorMessage={this.state.errorMessage}
								errorFor={this.state.errorFor} errorType={this.state.errorType} portList={this.state.portList} isRankDisable={this.state.isRankDisable ? true : false} rankList={this.state.rankList} captainList={this.state.captainList} countryList={this.state.countryList} handleSelectChange={this.handleSelectChange} handleChange={this.handleChange} {...this.state.deliveryDetail} deptBudget={this.state.deptBudget} isStarted={this.props.apiCallStatus.isStarted} />
							{/* Vessel & Crew Details  */}
							{/* <VesselCrewDetail handleSelectChange={this.handleSelectChange} /> */}
							<div className="d-flex justify-content-end sendOrder-wrap">
								{this.props.apiCallStatus.apiCallFor === "saveOrder" && !this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed ?
									<div className="loader-img text-center">
										<img style={{ width: "46px" }} src={require("../../../../../assets/images/Spinner-1s-200px.gif")} alt='' />
									</div>
									: ""}
								<button type="button" disabled={this.props.apiCallStatus.isStarted.indexOf("saveOrder") !== -1} className="btn themeReguler-btn bg-sky-blue mr-2" onClick={(e) => this.saveAndClose(e)}>Save & Close Order</button>
								<button type="button" disabled={this.props.apiCallStatus.isStarted.indexOf("saveOrder") !== -1} className="btn themeReguler-btn bg-dark-blue" onClick={(e) => this.handleSubmit(e)}>Next</button>
							</div>
						</form>
					</div>
					{/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
				</div>
			</Wrapper>
		)
	}
}

const mapStateToProps = (state, ownProps) => ({
	apiCallStatus: state.apicallStatusReducer,
	vesselDetail: state.commonReducer.vesselById,
	captainList: state.commonReducer.captainList,
	portList: state.commonReducer.portList,
	departmentList: state.commonReducer.departmentList,
	countryList: state.commonReducer.countryList,
	currentOrder: state.orderReducer.currentOrder,
	userDetail: {
		userId: state.authReducer.userId,
		department: state.authReducer.permission.department,
		subType: state.authReducer.subType
	}
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getVesselById: (id) => dispatch(getVesselById(id)),
	getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
	getAllCountry: (query) => dispatch(getAllCountry(query)),
	AddOrderAction: (data) => dispatch(AddOrderAction(data)),
	saveOrder: (data) => dispatch(saveOrder(data)),
	getOrderDetail: (orderId) => dispatch(getOrderDetail(orderId))
})
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateStoreOrder);