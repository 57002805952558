import * as Action from "../Actions/action";
import InitialState from "../../../Constants/initialState";
import cloneDeep from "lodash/cloneDeep";
export default function PortReducer(state = InitialState.role, action) {
    switch (action.type) {
        case Action.getRole:
            let s = cloneDeep(state);
            s.list = action.payload.list;
            s.count = action.payload.count;
            return s;
        case Action.AppendRoleList:
            var s1 = cloneDeep(state);
            var arr = s1.list.slice();
            s1.list = arr.concat(action.payload.list);
            return s1;
        case Action.getRoleDetail:
            let s2 = cloneDeep(state);
            s2.detail = action.payload.detail;
            return s2;
        case Action.getSubAdmin:
            let s3 = cloneDeep(state);
            s3.subAdminList = action.payload.list;
            s3.subAdminCount = action.payload.count;
            return s3;
        case Action.AppendSubAdminList:
            var s4 = cloneDeep(state);
            var arr = s4.subAdminList.slice();
            s4.subAdminList = arr.concat(action.payload.list);
            return s4;
        case Action.getSubAdminDetail:
            let s5 = cloneDeep(state);
            s5.subAdminDetail = action.payload.detail;
            return s5;
        case Action.getAllRole:
            let s6 = cloneDeep(state);
            s6.allRoles = action.payload.list;
            return s6;
        default:
            return state;
    }
}