import React, { useEffect, useState } from 'react';
import DashboardNavbar from '../../../Common/Components/dashboardNavbar';
import { Wrapper } from '../../../Common/Components/CommonWrapper';
import { SupplierComparisonListing } from '../../ApiCalls/supplier';
import { ClearApiByNameAction } from "../../../ApiCallStatus/Actions/action";
import { ComparisonWrapper } from '../../Css/comparison.jsx';
import { getDashboardLink } from '../../../../Services/common';
import moment from 'moment';
import { Modal } from 'react-responsive-modal';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip'
import { RatingStars } from '../../../Common/Components/ratingStars';

function CalculateTotalPrice(list) {
    let totalPrice = 0;
    list.forEach(element => {
        totalPrice += element.totalPrice;
    });
    return totalPrice.toFixed(2);
}
function compareDepartment(a, b) {
    // Use toUpperCase() to ignore character casing
    const itemCodeA = a.itemCode.toUpperCase();
    const itemCodeB = b.itemCode.toUpperCase();

    let comparison = 0;
    if (itemCodeA > itemCodeB) {
        comparison = 1;
    } else if (itemCodeA < itemCodeB) {
        comparison = -1;
    }
    return comparison;
}

function SupplierComparison(props) {
    const [state, setState] = useState({
        "data": [],
        "vesselDetail": {},
        "departments": {},
        "totalItems": 0,
        "activeDepartment": "",
        "basketId": props.match.params.basketId,
        "filters": {
            "priceType": "",
        },
        "depList": [],
        "type": "",
        "showModal": "",
        "selectedSupplier": "",
        "isSelectRfqExist": true,
        "isHitApi": false
    })
    useEffect(() => {
        if (!state.isHitApi) {
            props.SupplierComparisonListing({ basketId: state.basketId });
            let data = { ...state };
            data.isHitApi = true;
            setState(data);
        }
        if ((props.apiCallStatus.apiCallFor === "SupplierComparisonListing") &&
            props.apiCallStatus.isCompleted &&
            !props.apiCallStatus.isFailed) {
            let activeDepartment = "";
            let depList = [];
            let departments = {}
            if (props.comparison.departments && Object.size(props.comparison.departments) > 0) {
                depList = Object.keys(props.comparison.departments).sort();
                activeDepartment = depList[0];
                depList.forEach(element => {
                    departments[element] = props.comparison.departments[element].sort(compareDepartment)
                });
            }
            setState({
                ...state,
                ...props.comparison,
                activeDepartment,
                depList,
                departments
            });
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
    })
    const handleDepChange = (val) => {
        if (val === state.activeDepartment) {
            val = "";
        }
        setState({
            ...state,
            activeDepartment: val
        })
    }
    const handleFilterChange = (val) => {
        if (val === state.filters.priceType) {
            val = ""
        }
        setState({
            ...state,
            filters: {
                priceType: val
            }
        })
        let obj = { basketId: state.basketId };
        if (val !== "") {
            obj["priceType"] = val;
        }
        // props.SupplierComparisonListing(obj);
    }
    const redirectToPath = (path) => {
        props.history.push(path);
    }
    const sendRfqOrder = (url) => {
        setState({
            ...state,
            showModal: false,
            selectedSupplier: "",
            isSelectRfqExist: true
        })
        redirectToPath(url)
    }
    const handleModalChange = (val, selectedSupplier, isSelectRfqExist) => {
        setState({
            ...state,
            showModal: val,
            selectedSupplier,
            isSelectRfqExist
        })
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.push(getDashboardLink())
        }
    ];
    let currency = state.vesselDetail.currency ? state.vesselDetail.currency : '';
    return (
        <Wrapper>
            <DashboardNavbar title="Supplier Comparison" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <ComparisonWrapper className="supplier-comparison-wrap">
                {props.apiCallStatus.isStarted.indexOf("SupplierComparisonListing") !== -1 ?
                    <div className="loader-img text-center">
                        <img style={{ width: "46px" }} src={require("../../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                    </div> :
                    Object.size(state.vesselDetail) === 0 ? <div colSpan="40" className="text-center">No Data Found</div> :
                        < div className="ordCatg-table-wrap sup-rfq-wrap bg-light-grey pr-0 overflow-auto">
                            <div className="comp-first-row diff-details-wrap d-flex">
                                <VesselDetail {...state.vesselDetail} port={props.comparison.portName} deliveryDate={props.comparison.deliveryDate} totalItems={state.totalItems} handleFilterChange={handleFilterChange} priceType={state.filters.priceType} />
                                {state.data.map((item, index) => {
                                    return <SupplierDetail {...item.supplier} isRfqExist={item.isRfqExist} port={props.comparison.portName} deliveryDate={props.comparison.deliveryDate} currency={currency} key={index} basketId={state.basketId} redirectToPath={redirectToPath} handleModalChange={handleModalChange} />
                                })}
                            </div>
                            <div className="comp-second-row compar-table-wrap">
                                <div className="compar-table-head-wrap">
                                    {state.depList.map((item, index) => {
                                        return <div key={index} className={`compar-table-items d-flex  mt-3 ${state.activeDepartment === item ? 'collapse-content' : ''}`}>
                                            <DepartmentDetail depName={item} items={state.departments[item]} handleDepChange={handleDepChange} />
                                            {state.data.map((suppliers, index) => {
                                                let supplierDep = state.type === "store" ? suppliers.departments[item] : suppliers.orderedItems;
                                                return <PricingDetail currency={currency} departments={state.departments[item]} supplierDep={supplierDep} key={index} priceType={state.filters.priceType} />
                                            })}
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>}
            </ComparisonWrapper>
            <Modal open={state.showModal === "rfq"} onClose={() => handleModalChange("", "")} center>
                <div className="formBox request-price">
                    <div className="col-12">
                        <h5>Requesting prices always takes time (1-2 days). You can also order these items directly to save time and efforts.</h5>
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <button onClick={() => sendRfqOrder(`/send/rfq/${state.selectedSupplier}/${state.basketId}`)} className="btn themeReguler-btn bg-sky-blue m-0 mr-2">Send RFQ</button>
                                {/* </div> */}
                                {/* <div className="col-sm-6 text-center"> */}
                                <button onClick={() => sendRfqOrder(`/send/order/${state.selectedSupplier}/${state.basketId}`)} className="btn themeReguler-btn bg-sky-blue m-0">Send ORDER</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={state.showModal === "order"} onClose={() => handleModalChange("", "")} center>
                <div className="formBox request-price">
                    <div className="col-12">
                        <h5>Some prices are estimated. Actual prices can vary. How do you want to proceed ?</h5>
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                {state.isSelectRfqExist ?
                                    <button onClick={() => sendRfqOrder(`/send/rfq/${state.selectedSupplier}/${state.basketId}`)} className="btn themeReguler-btn bg-sky-blue m-0 mr-2">Send RFQ</button>
                                    : ""}
                                {/* <div className="col-sm-6 text-center"> */}
                                  <button onClick={() => sendRfqOrder(`/send/order/${state.selectedSupplier}/${state.basketId}`)} className="btn themeReguler-btn bg-sky-blue m-0">Send ORDER</button>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </Wrapper >
    )
}

const VesselDetail = React.memo(props => {
    return <div className="rfq-durations rfq-details-items text-left flex-fill">
        <h2 className="mt-1 dark-blue-clr semi-bold">{props.name}</h2>
        <div className="inner-content text-left">
            <div className="d-flex flex-wrap justify-content-between">
                <h4><span className="font-weight-bold mt-1 d-inline-block mr-1">Del. Place:   </span>{props.port}</h4>
            </div>
            <div className="d-flex flex-wrap justify-content-between">
                <h4><span className="font-weight-bold mt-1 d-inline-block mr-1">Del. Date:    </span>{moment(props.deliveryDate).format('YYYY-MM-DD')}</h4>
            </div>
            <div><h4 className="mt-1"><span className="font-weight-bold d-inline-block mr-1"> Items ordered:</span>{props.totalItems}</h4></div>
            <div className="check_contnet_wrap">
                <p className="mt-1 mb-1 d-flex align-items-center">
                    <span className="customCheckBoxLabel">
                        <input name="priceType" checked={props.priceType === "fixed"} type="checkbox" className="star-checkbox" id="fixed" onChange={() => props.handleFilterChange("fixed")} />
                        <label htmlFor="fixed" className="checkmark"></label>
                    </span >
                    Items with fixed prices
                </p>
                <p className="mb-1 d-flex align-items-center">
                    <span className="customCheckBoxLabel">
                        <input name="priceType" type="checkbox" checked={props.priceType === "estimated"} className="star-checkbox" id="estimated" onChange={() => props.handleFilterChange("estimated")} />
                        <label htmlFor="estimated" className="checkmark"></label>
                    </span >
                    Items with estimated prices
                </p>
            </div>
        </div>
    </div>;
});

const SupplierDetail = React.memo(props => {
    const handleSendOrder = () => {
        if (!props.isRfqExist) {
            props.redirectToPath(`/send/order/${props._id}/${props.basketId}`)
        } else {
            props.handleModalChange('order', props._id, props.isRfqExist)
        }
    }
    return <div className="rfq-rating rfq-details-items">
        <button type="button" className="btn themeReguler-btn bg-dark-blue m-0">{props.name}</button>
        <div className="inner-content text-center">
            <div className="rating-wrap d-flex align-items-center mt-2">
                <h3 className="rounded-tag mb-0 mr-2">Best Rating</h3>
                <RatingStars id="input" className="rating-checks list-inline pl-0 d-flex align-items-center mb-0" isEdit={true} ratingAvg={props.ratingAvg} handleChange={() => { }} />
                {/* <ul className="rating-checks list-inline pl-0 d-flex align-items-center mb-0">
                    <li>
                        <input type="checkbox" className="star-checkbox" id="start-1" />
                        <label htmlFor="start-1"><i class="fas fa-star"></i></label>
                    </li>
                    <li>
                        <input type="checkbox" className="star-checkbox" id="start-2" />
                        <label htmlFor="start-2"><i class="fas fa-star"></i></label>
                    </li>
                    <li>
                        <input type="checkbox" className="star-checkbox" id="start-3" />
                        <label htmlFor="start-3"><i class="fas fa-star"></i></label>
                    </li>
                    <li>
                        <input type="checkbox" className="star-checkbox" id="start-4" />
                        <label htmlFor="start-4"><i class="fas fa-star"></i></label>
                    </li>
                    <li>
                        <input type="checkbox" className="star-checkbox" id="start-5" />
                        <label htmlFor="start-5"><i class="fas fa-star"></i></label>
                    </li>
                </ul> */}
            </div>
            <div className="inner-content text-center d-flex align-items-center mt-1 rounder-new">
                <h3 className="rounded-tag mr-2 orng-clr mb-0">Most prices</h3>
                <div className="rounded-tag progress-bar-wrap"><h3 className="rounded-tag mb-0" style={{ width: props.fixedItems !== 0 && props.estimatedItems !== 0 ? ((props.fixedItems / (props.fixedItems + props.estimatedItems)) * 100) : 0 }}>{`${props.fixedItems}/${(props.fixedItems + props.estimatedItems)}`}</h3></div>
            </div>
            <h3 className="semi-bold total-value-count mb-0 mt-3"><span className="d-inline-block mr-2">Total:</span> {props.totalPrice ? props.totalPrice.toFixed(2) : 0} {props.currency}</h3>
            {/* Order Buttons */}
            <div className="next-process-btns d-flex align-items-center justify-content-center mt-3">
                {props.isRfqExist ?
                    <button onClick={() => props.handleModalChange('rfq', props._id, props.isRfqExist)} className="btn themeReguler-btn bg-sky-blue m-0 mr-2">Send RFQ</button>
                    : ""}
                <button onClick={() => handleSendOrder()} className="btn themeReguler-btn bg-sky-blue m-0">Send ORDER</button>
            </div>
        </div>
    </div>;
});

const PricingDetail = React.memo(props => {
    let totalPrice = CalculateTotalPrice(props.supplierDep);
    return <div className="compar-table-item">
        <h2 className="table-title-wrap text-center">{totalPrice} {props.currency}</h2>
        <div className="table-responsive">
            <table className="table theme-table-wrap2 short-table sticky-th-border">
                <thead>
                    <tr>
                        <th>Price / unit</th>
                        <th>Total price</th>
                    </tr>
                </thead>
                <tbody>
                    {props.departments.map((item, index) => {
                        let supplierDepIndex = props.supplierDep.findIndex(x => x.itemCode === item.itemCode);
                        let elem = {};
                        if (supplierDepIndex !== -1) {
                            elem = props.supplierDep[supplierDepIndex];
                        }
                        let price = elem.itemPrice ? parseFloat(elem.itemPrice).toFixed(2) : 0;
                        let totalPrice = elem.totalPrice;
                        if (props.priceType !== "" && props.priceType !== elem.priceType) {
                            price = "--";
                            totalPrice = "--";
                        }
                        return <tr key={index}>
                            <td className="text-start">{elem.priceType === "estimated" && props.priceType === "" ? <React.Fragment><span
                                className="price-text" data-effect="float" data-tip="Estimated Price">
                                <img className="img-fluid" src={require('../../../../assets/images/circle_outline_with_exclamation.svg')} alt="icon" />
                            </span>
                                <span className="price-text">{price ? price : 0} {props.currency}</span>
                            </React.Fragment> : price ? price + " " + props.currency : 0 + props.currency}</td>
                            <td>{totalPrice ? totalPrice : 0} {props.currency}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    </div>;
});

const DepartmentDetail = React.memo(props => {
    return <div className="compar-table-item flex-fill">
        <div className="table-title-wrap d-flex align-items-center">
            <button type="button" className="btn btn-link" onClick={() => props.handleDepChange(props.depName)}></button>
            <h2 className="mb-0">{props.depName}</h2>
        </div>
        <div className="table-responsive ">
            <table className=" table theme-table-wrap2 short-table sticky-th-border suppliertable">
                <thead>
                    <tr>
                        <th className="text-center">No</th>
                        <th className="text-left">Item No</th>
                        <th className="text-left">Description</th>
                        <th className="text-center">Quantity</th>
                        <th className="text-left">Unit</th>
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((item, index) => {
                        return <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-left">{item.itemCode}</td>
                            <td className="text-nowrap text-left">{item.description}</td>
                            <td className="text-center"> {item.order}</td>
                            <td className="text-left">{item.standardPackagingUnit}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
        <ReactTooltip />
    </div>;

});

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    comparison: state.supplierReducer.comparison
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    SupplierComparisonListing: (data) => dispatch(SupplierComparisonListing(data)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplierComparison);