import React, { Component } from 'react';
import { Wrapper } from '../../../Css/previsionCatalouge';
import DashboardNavbar from '../../../../Common/Components/dashboardNavbar';
import { CatalougeTable } from './catalougeTable';
import { Filters } from './filters';
import { Constant, inventory as InventoryConstant } from '../../../../../Constants/constant';
import { NutritionDetail, OrderDetailModal } from './models';
import { OrderOverView } from './orderOverview';
import { provisionListing, getFilterDetail, updateOrder, getOrderDetail } from './../../../ApiCalls/provisionOrder';
import { getItemCode, getItemGroup, getDescription } from './../../../ApiCalls/searchProvisionOrder';
import { getVesselById } from "../../../../Common/ApiCalls/vesselList";
import { ApiClearAction } from "../../../../ApiCallStatus/Actions/action";
import { AddOrderAction } from './../../../Actions/action';
import { connect } from 'react-redux';
import { getDashboardLink, Sum } from '../../../../../Services/common';
import DemoHeader from '../../../../Common/Components/demoHeader';
import Swal from 'sweetalert2'
import cloneDeep from 'lodash.clonedeep';
import $ from 'jquery';
// var Config = require('../config.json');



const calculateTotalUsedPoint = (data, numOfCrew, numOfDays) => {
	let keys = Object.keys(data);
	let total = 0;
	let energy = 0;
	let protein = 0;
	let fat = 0;
	let carbohydrates = 0;
	let sugar = 0;
	let dietaryFibres = 0;
	for (let index = 0; index < keys.length; index++) {
		const element = keys[index];
		let orKg = data[element].orKg;
		let order = data[element].order ? parseFloat(data[element].order) : 0;
		let diffInkg = order * 10 * orKg;
		let proteinVal = parseFloat(data[element].protein);
		if (data[element] && data[element].usedPoints !== '') {
			total += data[element].usedPoints;
		}
		if (data[element] && data[element].energy !== 0) {
			energy += parseFloat(data[element].energy) * diffInkg;
		}
		if (data[element] && proteinVal !== 0) {
			protein += proteinVal * diffInkg;
		}
		if (data[element] && data[element].fat !== 0) {
			fat += parseFloat(data[element].fat) * diffInkg;
		}
		if (data[element] && data[element].carbohydrates !== 0) {
			carbohydrates += parseFloat(data[element].carbohydrates) * diffInkg;
		}
		if (data[element] && data[element].sugar !== 0) {
			sugar += parseFloat(data[element].sugar) * diffInkg;
		}
		if (data[element] && data[element].dietaryFibres !== 0) {
			dietaryFibres += parseFloat(data[element].dietaryFibres) * diffInkg;
		}
	}
	if (energy > 0) {
		energy = ((energy / numOfDays) / numOfCrew).toFixed(0);
	}
	if (protein > 0) {
		protein = ((protein / numOfDays) / numOfCrew).toFixed(0);
	}
	if (fat > 0) {
		fat = ((fat / numOfDays) / numOfCrew).toFixed(0);
	}
	if (carbohydrates > 0) {
		carbohydrates = ((carbohydrates / numOfDays) / numOfCrew).toFixed(0);
	}
	if (sugar > 0) {
		sugar = ((sugar / numOfDays) / numOfCrew).toFixed(0);
	}
	if (dietaryFibres > 0) {
		dietaryFibres = ((dietaryFibres / numOfDays) / numOfCrew).toFixed(0);
	}
	let percentage = (total / 10000) * 100;
	return { total: total.toFixed(2), percentage: percentage.toFixed(2), energy, protein, fat, carbohydrates, sugar, dietaryFibres };
}
let filterInputTimeout = null;
class ProvisionCatalogue extends Component {
	constructor(props) {
		super(props);
		let durability = this.props.currentOrder.orderKind === "Main Order" ? "d" : "f";
		this.state = {
			selectedOption: null,
			catalougeList: [],
			filterCatalouge: [],
			currentOrder: {},
			isOrder: true,
			orderDetail: {},
			numOfCrew: 0,
			numOfDays: 0,
			itemGroupList: [],
			cloneItemGroupList: [],
			itemNoList: [{ label: "1101", value: "1101" },
			{ label: "1102", value: "1102" },
			{ label: "1103", value: "1103" },
			{ label: "1104", value: "1104" },
			{ label: "1105", value: "1105" },
			{ label: "1106", value: "1106" },
			{ label: "1107", value: "1107" },
			{ label: "1108", value: "1108" },
			{ label: "1109", value: "1109" }],
			filters: {
				itemGroup: "",
				inputItemGroup: "",
				itemNo: "",
				inputItemNo: "",
				inputDescription: "",
				description: "",
				isApplied: false
			},
			descriptionList: [],
			totalDescription: 0,
			showOrderDetailModel: false,
			orderNeeded: 0,
			totalOrder: 0,
			totalOrderPoints: 10000,
			totalOrderBudget: 0,
			filterQuery: '?systemType=p&durability=' + (durability),
			totalCount: 0,
			totalItemCode: 0,
			activePage: 0,
			itemCodePage: 0,
			descriptionPage: 0,
			selectedItem: {},
			durability: durability,
			isError: false,
			vesselCategory: [],
			isSaveAndClose: false,
			captainId: "",
			showOrderedList: false
		}
	};
	componentDidMount() {
		if (this.props.currentOrder && this.props.currentOrder.numberOfCrew && this.props.currentOrder.orderPeriod) {
			this.calculateAndUpdateOrderDetail();
		} else {
			this.props.getOrderDetail(this.props.match.params.orderId)
			// this.props.history.push('/create-provision-order/' + this.props.match.params.order)
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (Object.keys(this.props.currentOrder).length !== 0 && this.state.isOrder) {
			this.calculateAndUpdateOrderDetail();
		}
		if (((prevProps.apiCallStatus.isStarted.indexOf('getProvision') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getProvision') === -1) || (prevProps.apiCallStatus.isStarted.indexOf('appendProvisionList') !== -1 &&
				this.props.apiCallStatus.isStarted.indexOf('appendProvisionList') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			this.setState({
				...this.state,
				catalougeList: this.props.orderReducer.provisionList,
				filterCatalouge: this.props.orderReducer.provisionList,
				totalCount: this.props.orderReducer.totalProvision,
			})
			if (this.props.apiCallStatus.apiCallFor === "getProvision") {
				this.disableScroll();
			}
			setTimeout(() => {
				$("input[type=number]").on("focus", function () {
					$(this).on("keydown", function (event) {
						if (event.keyCode === 38 || event.keyCode === 40 || event.key === "-") {
							event.preventDefault();
						}
					});
				});
			}, 500);

		}
		if (((prevProps.apiCallStatus.isStarted.indexOf('getItemCode') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getItemCode') === -1) || (prevProps.apiCallStatus.isStarted.indexOf('appendItemCode') !== -1 &&
				this.props.apiCallStatus.isStarted.indexOf('appendItemCode') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			let newArr = [];
			let arr = this.props.orderReducer.itemCodeList;
			for (let i = 0; i < arr.length; i++) {
				newArr.push({ value: arr[i].itemCode, label: arr[i].itemCode });
			}
			this.setState({
				...this.state,
				itemNoList: newArr,
				totalItemCode: this.props.orderReducer.totalItemCode,
			})
		}
		if (((prevProps.apiCallStatus.isStarted.indexOf('getDescription') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getDescription') === -1) || (prevProps.apiCallStatus.isStarted.indexOf('appendDescription') !== -1 &&
				this.props.apiCallStatus.isStarted.indexOf('appendDescription') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			let newArr = [];
			let arr = this.props.orderReducer.descriptionList;
			for (let i = 0; i < arr.length; i++) {
				newArr.push({ value: arr[i].description, label: arr[i].description });
			}
			this.setState({
				...this.state,
				descriptionList: newArr,
				totalDescription: this.props.orderReducer.totalDescription,
			})
		}

		if ((prevProps.apiCallStatus.isStarted.indexOf('getItemGroup') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getItemGroup') === -1) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			let newArr = [];
			let arr = this.props.orderReducer.itemGroupList;
			for (let i = 0; i < arr.length; i++) {
				newArr.push({ value: arr[i], label: arr[i] });
			}
			this.setState({
				...this.state,
				cloneItemGroupList: newArr,
				itemGroupList: newArr,
			})
		}
		if ((prevProps.apiCallStatus.isStarted.indexOf('updateOrder') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('updateOrder') === -1) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			let id = this.props.apiCallStatus.message;
			this.props.AddOrderAction({})
			if (this.state.isSaveAndClose) {
				this.props.history.push(getDashboardLink())
			} else {
				let vesselId = this.props.currentOrder.vessel;
				let url = getDashboardLink();
				let isInvenotry = this.props.currentOrder.orderKind === InventoryConstant ? true : false;
				if (this.props.isSubAdmin && this.props.userType === 'user' && this.props.subType === 'captain') {
					url = `/order-basket?vesselId=${vesselId}`;
					if (isInvenotry) {
						url = `/inventory-list`;
					}
				}
				let msg = 'Your order has been added into the basket successfully.';
				if (isInvenotry) {
					msg = 'Your inventory has been updated successfully.';
				} else if (this.props.userType === "user" && !this.props.isSubAdmin) {
					msg = 'Your order was added to the order basket.';
				}
				Swal.fire({
					title: msg,
					icon: 'success',
					confirmButtonColor: '#3085d6',
					allowOutsideClick: false,
					confirmButtonText: 'Ok'
				}).then((result) => {
					if (result.isConfirmed) {
						this.props.history.push(url)

					}
				})
				this.props.ApiClearAction();

			}

		}
	}
	calculateAndUpdateOrderDetail = () => {
		// let categoryType = this.props.currentOrder.orderKind === "Main Order" ? 'main' : 'fresh';
		let vesselCategory = this.props.currentOrder.categories.filter(function (cat) {
			return cat;
		})
		let durability = this.props.currentOrder.orderKind === "Main Order" ? "d" : "f";
		let filterQuery = '?systemType=p&durability=' + (durability);
		let query = '?availability=p&durability=' + durability;
		if (this.props.currentOrder.orderKind === InventoryConstant) {
			filterQuery = '?systemType=p';
			query = '?availability=p';
		}
		let numOfCrew = Sum(this.props.currentOrder.numberOfCrew.regular, this.props.currentOrder.numberOfCrew.additional);
		let numOfDays = 0;
		if (durability === "d") {
			numOfDays = Sum(this.props.currentOrder.orderPeriod.dryFrozen.regular, this.props.currentOrder.orderPeriod.dryFrozen.expectedDelay);
		} else {
			numOfDays = Sum(this.props.currentOrder.orderPeriod.freshProvisions.regular, this.props.currentOrder.orderPeriod.freshProvisions.expectedDelay);
		}
		let orderNeeded = 0;
		let totalOrder = 0;
		let vesselPriceRate = parseFloat(this.props.currentOrder.vesselRate);
		let totalOrderBudget = vesselPriceRate * numOfCrew * numOfDays;
		let orderDetail = {};
		for (let index = 0; index < vesselCategory.length; index++) {
			orderNeeded += parseFloat(vesselCategory[index].qtyPerDay) / 1000 * numOfCrew * numOfDays;
		}
		let captainId = this.props.currentOrder.captain;
		if (this.props.currentOrder.orderDetail) {
			for (let key in this.props.currentOrder.orderDetail) {
				let budgetPoints = ((parseFloat(this.props.currentOrder.orderDetail[key].pricePerBaseUnit) / totalOrderBudget) * this.state.totalOrderPoints)
				let inventory = parseFloat(this.props.currentOrder.orderDetail[key].inventory);
				let order = parseFloat(this.props.currentOrder.orderDetail[key].order);
				let count = 0;
				if (!isNaN(inventory)) {
					count += inventory
				}
				if (!isNaN(order)) {
					count += order
					totalOrder += order;
				}
				orderDetail[key] = this.props.currentOrder.orderDetail[key];
				orderDetail[key].budgetPoints = budgetPoints;
				orderDetail[key].usedPoints = parseFloat((budgetPoints * count).toFixed(2));
			}
		}
		this.setState({
			orderDetail: orderDetail,
			isOrder: false,
			filterQuery,
			durability,
			vesselCategory,
			numOfCrew,
			numOfDays,
			totalOrder,
			totalOrderBudget: totalOrderBudget,
			currentOrder: this.props.currentOrder,
			orderNeeded: orderNeeded.toFixed(2),
			captainId
		})

		this.props.provisionListing(0, 200, filterQuery);
		this.props.getItemCode(0, query);
		this.props.getDescription(0, query);
		this.props.getItemGroup(query);
		if (Object.size(this.props.vesselDetail) === 0) {
			this.props.getVesselById(this.props.currentOrder.vessel);
		}
		if (this.props.currentOrder.orderKind !== InventoryConstant) {
			if (this.props.currentOrder.orderKind === "Main Order") {
				Swal.fire("Welcome to your provision order", "Please only fill in inventory of item, you are planning to order. We kindly ask you to recommended order quantities of the item groups, to ensure a balanced and healthy diet of your good crew.", "success");
			} else {
				Swal.fire("Welcome to fresh provision order", "Please only fill in inventory of item, you are planning to order. We kindly ask you to recommended order quantities of the item groups, to ensure a balanced and healthy diet of your good crew.", "success");
			}
		}
	}
	handleFilterChange = (id, val) => {
		let filters = { ...this.state.filters };
		let obj = {}
		filters[id] = val;
		obj.filters = filters;
		let th = this;
		let query = "?availability=p&durability=" + this.state.durability;
		if (this.props.currentOrder.orderKind === InventoryConstant) {
			query = '?availability=p';
		}
		clearTimeout(filterInputTimeout);
		if (id === 'inputItemNo') {
			obj.itemCodePage = 0;
			// query = "?availability=p&durability=" + this.state.durability;
			if (val !== '') {
				query = query + '&itemCode=' + val;
			}
		} else if (id === 'inputDescription') {
			obj.descriptionPage = 0;
			// query = "?availability=p&durability=" + this.state.durability;
			if (val !== '') {
				query = query + '&description=' + val;
			}
		} else if (id === "inputItemGroup") {
			let arr = this.state.cloneItemGroupList.filter((item) => {
				let trimVal = val.toLowerCase();
				let selectedVal = item.value.toLowerCase();
				return selectedVal.indexOf(trimVal) !== -1
			});

			obj.itemGroupList = arr;
		}
		// Make a new timeout set to go off in 1000ms (1 second)
		filterInputTimeout = setTimeout(function () {
			if (id === 'inputItemNo') {
				th.props.getItemCode(0, query)
			} else if (id === 'inputDescription') {
				th.props.getDescription(0, query)
			}
		}, 500);
		this.setState(obj);

	};
	clickCustomeSelectHandler = (id, val) => {
		let filters = { ...this.state.filters };
		let obj = {}
		filters[id] = val;
		if (id === 'itemNo') {
			filters.inputItemNo = val;
			obj.itemCodePage = 0;
			// let query = "?availability=p&durability=" + this.state.durability;
			// if (val !== '') {
			// 	query = query + '&itemCode=' + val;
			// }
		} else if (id === 'description') {
			filters.inputDescription = val;
			obj.descriptionPage = 0;
			// let query = "?availability=p&durability=" + this.state.durability;
			// if (val !== '') {
			// 	query = query + '&description=' + val;
			// }
		} else if (id === "itemGroup") {
			filters.inputItemGroup = val;
		}
		obj.filters = filters;
		this.setState(obj, () => this.applyFilters());
	}
	clearFilters = () => {
		let filterQuery = '?systemType=p&durability=' + this.state.durability;
		let query = "?availability=p&durability=" + this.state.durability;
		if (this.props.currentOrder.orderKind === InventoryConstant) {
			filterQuery = '?systemType=p';
			query = "?availability=p";
		}
		this.setState({
			filters: {
				itemGroup: "",
				itemNo: "",
				inputItemGroup: "",
				inputItemNo: "",
				inputDescription: "",
				description: "",
				isApplied: false
			},
			itemGroupList: this.state.cloneItemGroupList,
			itemCodePage: 0,
			descriptionPage: 0,
			filterQuery
		}, () => {
			this.props.provisionListing(0, 200, filterQuery)
			this.props.getItemCode(0, query)
			this.props.getDescription(0, query)
			this.props.getItemGroup(query)
		})
	}
	applyFilters = () => {
		let val1 = '';
		let val2 = '';
		let val3 = '';
		let activePage = 0;
		if (this.state.filters.itemGroup !== '') {
			val1 = "&itemGroup=" + encodeURIComponent(this.state.filters.itemGroup)
		} if (this.state.filters.itemNo !== '') {
			val2 = "&itemCode=" + this.state.filters.itemNo;
		}
		if (this.state.filters.description !== '') {
			val3 = "&description=" + encodeURIComponent(this.state.filters.description);
		}
		let filterQuery = '?systemType=p&durability=' + this.state.durability;
		if (this.props.currentOrder.orderKind === InventoryConstant) {
			filterQuery = '?systemType=p'
		}
		if (val1 !== '') {
			filterQuery += val1
		}
		if (val2 !== '') {
			filterQuery += val2
		}
		if (val3 !== '') {
			filterQuery += val3
		}
		this.setState({
			filterQuery,
			activePage,
			itemCodePage: 0,
			descriptionPage: 0,
			isApplied: true
		}, () => {
			this.props.provisionListing(activePage, 200, filterQuery)
		})
	}
	handleTableChange = (itemNo, id, value, budgetPoints, category, nutritions, item) => {
		let orderDetail = cloneDeep(this.state.orderDetail);
		let catIndex = this.state.vesselCategory.findIndex(x => x.category === category);
		let needed = 0;
		let catName = "";
		if (catIndex !== -1) {
			catName = this.state.vesselCategory[catIndex].category;
			needed = parseFloat(this.state.vesselCategory[catIndex].qtyPerDay) / 1000 * this.state.numOfCrew * this.state.numOfDays
		} else {
			catIndex = this.state.vesselCategory.findIndex(x => x.category === "MEAT");
			if (catIndex !== -1) {
				needed = parseFloat(this.state.vesselCategory[catIndex].qtyPerDay) / 1000 * this.state.numOfCrew * this.state.numOfDays
				catName = this.state.vesselCategory[catIndex].category;
			}
		}
		let isShowError = true;
		let obj = orderDetail[itemNo];
		let totalOrder = 0;
		if (obj) {
			if (id === 'order' && obj.inventory === '') {
				Swal.fire("Error", "Pleas fill the inventory column before filling in the desired order quantities", "error");
			} else {
				if (parseInt(obj[id]) > parseInt(value)) {
					isShowError = false;
				}
				obj[id] = value;
				obj.budgetPoints = budgetPoints;
				obj.category = category;
				obj.energy = nutritions.energy !== 0 ? nutritions.energy : 0;
				obj.protein = nutritions.protein !== 0 ? nutritions.protein : 0;
				obj.fat = nutritions.fat !== 0 ? nutritions.fat : 0;
				obj.carbohydrates = nutritions.carbohydrates !== 0 ? nutritions.carbohydrates : 0;
				obj.sugar = nutritions.sugar !== 0 ? nutritions.sugar : 0;
				obj.dietaryFibres = nutritions.dietaryFibres !== 0 ? nutritions.dietaryFibres : 0;
				obj.description = nutritions.description
				obj.explanation = nutritions.explanation
				obj.standardPackagingUnit = nutritions.standardPackagingUnit
				obj.pricePerBaseUnit = nutritions.pricePerBaseUnit
				obj.orderUsedPoints = nutritions.orderUsedPoints
				obj.category = nutritions.category
				obj.itemCode = nutritions.itemCode
			}
		} else {
			delete item._id;
			delete item.createdAt;
			delete item.updatedAt;
			obj = { ...item, inventory: '', order: '', budgetPoints: '', usedPoints: '' };
			if (id === 'order') {
				Swal.fire("Error", "Please fill the inventory column before filling in the desired order quantities", "error");
			} else {
				if (parseInt(obj[id]) > parseInt(value)) {
					isShowError = false;
				}
				obj[id] = value;
				obj.budgetPoints = budgetPoints;
				obj.category = category;
				obj.energy = nutritions.energy !== 0 ? nutritions.energy : 0;
				obj.protein = nutritions.protein !== 0 ? nutritions.protein : 0;
				obj.fat = nutritions.fat !== 0 ? nutritions.fat : 0;
				obj.carbohydrates = nutritions.carbohydrates !== 0 ? nutritions.carbohydrates : 0;
				obj.sugar = nutritions.sugar !== 0 ? nutritions.sugar : 0;
				obj.dietaryFibres = nutritions.dietaryFibres !== 0 ? nutritions.dietaryFibres : 0;
				obj.pricePerBaseUnit = nutritions.pricePerBaseUnit;
				obj.orderUsedPoints = nutritions.orderUsedPoints;

			}
		}
		let inventory = parseFloat(obj.inventory);
		let order = parseFloat(obj.order);
		let count = 0;
		if (!isNaN(inventory)) {
			count += inventory
		}
		if (!isNaN(order)) {
			count += order
		}
		obj.usedPoints = parseFloat((budgetPoints * count).toFixed(2));
		orderDetail[itemNo] = obj;
		let calculateOrderedProduct = this.calculateOrderedProduct(category, orderDetail);
		let { total, percentage } = calculateTotalUsedPoint(orderDetail, this.state.numOfCrew, this.state.numOfDays);
		let { rateLimitFixed, rateTolerance, maxQuantityLimitFixed, maxQuantityTolerance, categoyLimitFixed, maxCategoryTolerance } = this.props.vesselDetail;
		let rateAllowance = this.state.totalOrderPoints + ((this.state.totalOrderPoints / 100) * rateTolerance);
		if ((this.props.currentOrder && this.props.currentOrder.orderKind === InventoryConstant) || (rateLimitFixed || percentage <= 100) || (!rateLimitFixed && rateAllowance <= total)) {
			let orderkeys = Object.keys(orderDetail);
			for (let index = 0; index < orderkeys.length; index++) {
				const element = orderkeys[index];
				if (orderDetail[element]) {
					let num = parseFloat(orderDetail[element].order) * orderDetail[element].orKg
					if (!isNaN(num)) {
						totalOrder += num;
					}
				}
			}
			let quantityAllowance = this.state.orderNeeded + ((this.state.orderNeeded / 100) * maxQuantityTolerance);
			let catAllowance = needed + ((needed / 100) * maxCategoryTolerance);
			let isError = false;
			if (isShowError && this.props.currentOrder && this.props.currentOrder.orderKind !== InventoryConstant) {
				if ((!categoyLimitFixed && needed < calculateOrderedProduct) || (categoyLimitFixed && catAllowance <= calculateOrderedProduct)) {
					let msg = `Please adjust the quantities of item group ${catName} before sending the order`;
					if (!categoyLimitFixed) {
						msg = `The ordered category quantities have exceeded the maximum order quantities. Please adjust the quantities of item group ${catName} before sending the order`;
					}
					Swal.fire("Error", msg, "error");
					isError = true;
				} else if (maxQuantityLimitFixed && quantityAllowance <= totalOrder) {
					Swal.fire("Error", `The ordered quantities have exceeded the maximum order quantities. Please adjust the quantities of item group ${catName} before sending the order`, "error");
					isError = true;
				}
			}
			this.setState({
				totalOrder,
				orderDetail,
				isError: isError
			})
		} else {
			if (isShowError) {
				let msg = `Please adjust the quantities of item group ${catName} before sending the order`
				if (!rateLimitFixed && rateTolerance !== 0) {
					msg = `Your order is exceeding the budget. Please adjust the quantities of item group ${catName} before sending the order`
				}
				Swal.fire("Error", msg, "error");
			}
		}
	}
	fetchMoreData = () => {
		let { activePage } = this.state;
		activePage += 1;
		this.setState({
			activePage
		}, () => { this.props.provisionListing(activePage, 200, this.state.filterQuery) })
	};
	fetchMoreItemCode = () => {
		let { itemCodePage } = this.state;
		itemCodePage += 1;
		let query = '?availability=p&durability=' + this.state.durability;
		if (this.props.currentOrder.orderKind === InventoryConstant) {
			query = '?availability=p';
		}
		if (this.state.filters.itemNo !== '') {
			query = query + '&itemCode=' + this.state.filters.itemNo;
		}
		this.setState({
			itemCodePage
		}, () => { this.props.getItemCode(itemCodePage, query) })
	}
	fetchMoreDescription = () => {
		let { descriptionPage } = this.state;
		descriptionPage += 1;
		let query = '?availability=p&durability=' + this.state.durability;
		if (this.props.currentOrder.orderKind === InventoryConstant) {
			query = '?availability=p';
		}
		if (this.state.filters.inputDescription !== '') {
			query = query + '&description=' + this.state.filters.inputDescription;
		}
		this.setState({
			descriptionPage
		}, () => { this.props.getDescription(descriptionPage, query) })
	}
	updateOrder = () => {
		if (this.props.apiCallStatus.isStarted.indexOf("updateOrder") === -1) {
			let { minimumFixed, sufficientProvisionsMinimum } = this.props.vesselDetail;
			if (Object.size(this.props.vesselDetail) > 0) {
				let needed = this.state.orderNeeded;
				if (minimumFixed) {
					needed = (this.state.orderNeeded / 100) * sufficientProvisionsMinimum;
				}
				if (needed > this.state.totalOrder) {
					Swal.fire("Error", `Attention! Your ordered quantities are below ${minimumFixed ? sufficientProvisionsMinimum + "%" : ""} of the recommended quantities for your trip. In order to ensure sufficient provisions on board, please adjust quantities accordingly.`, "error");
				} else {
					let isInvenotry = this.props.currentOrder.orderKind === InventoryConstant ? true : false;
					let data = {};
					let orderDetail = [];
					let itemCodeList = [];
					let itemPriceAvailable = 0;
					let itemPriceReq = 0;
					let pointsUsed = 0;
					let totalBudget = 0;
					let totalPricePerBaseUnit = 0;
					let totalPrice = 0;
					let keys = Object.keys(this.state.orderDetail);
					for (let index = 0; index < keys.length; index++) {
						const element = keys[index];
						if ((isInvenotry && this.state.orderDetail[element].inventory !== "") || (this.state.orderDetail[element].inventory !== "" && this.state.orderDetail[element].order !== "")) {
							if (this.state.orderDetail[element].pricePerBaseUnit > 0) {
								itemPriceAvailable += 1;
							} else {
								itemPriceReq += 1;
							}
							if (this.state.orderDetail[element].usedPoints !== 0) {
								pointsUsed += this.state.orderDetail[element].usedPoints
							}
							if (this.state.orderDetail[element].budgetPoints !== 0) {
								totalBudget += parseFloat(this.state.orderDetail[element].budgetPoints)
							}
							if (this.state.orderDetail[element].pricePerBaseUnit !== 0) {
								totalPricePerBaseUnit += this.state.orderDetail[element].pricePerBaseUnit;
								totalPrice += this.state.orderDetail[element].pricePerBaseUnit * parseInt(this.state.orderDetail[element].order)
							}
							itemCodeList.push(element);
							orderDetail.push({ ...this.state.orderDetail[element] })
						}
					}
					data.orderId = this.props.match.params.orderId;
					data.itemCodeList = itemCodeList;
					data.orderDetail = orderDetail;
					data.orderItems = orderDetail.length;
					data.itemPriceReq = itemPriceReq;
					data.itemPriceAvailable = itemPriceAvailable;
					data.totalBudget = totalBudget;
					data.pointsUsed = pointsUsed;
					data.orderNeeded = this.state.orderNeeded;
					data.orderRequested = this.state.totalOrder;
					data.captain = this.state.captainId;
					data.totalPricePerBaseUnit = totalPricePerBaseUnit;
					data.totalPrice = totalPrice
					data.redirectionPath = "/provision-catalogue/";
					if (orderDetail.length > 0) {
						if (this.state.isError) {
							Swal.fire("Error", "Pleas adjust the quantities of item group before sending the order", "error");
						} else {
							this.props.updateOrder(data);
						}
					} else {
						Swal.fire("Error", `Please select some items before sending ${isInvenotry ? "inventory" : "order"}.`, "error");
					}
				}
			} else {
				Swal.fire("Error", `Vessel not found`, "error");
			}
		}
	}
	calculateOrderedProduct = (category, orderDetail) => {
		let sum = 0;
		let keys = Object.keys(orderDetail)
		for (let index = 0; index < keys.length; index++) {
			const element = keys[index];
			if (orderDetail[element].category === category && orderDetail[element].order !== '') {
				sum += parseFloat(orderDetail[element].order) * orderDetail[element].orKg
			}
		}
		return sum;
	}
	showItemDetailModel = (selectedItem) => {
		this.setState({
			selectedItem
		}, () => $("#NutritaionModal").modal('show'))
	}
	disableScroll = () => {
		$('.ordCatg-InnTable').on('focus', 'input[type=number]', function (e) {
			$(this).on('wheel.disableScroll', function (e) {
				e.preventDefault()
			})
		})
	}
	goBack = () => {
		let url = getDashboardLink();
		if (this.props.currentOrder && this.props.currentOrder.vessel) {
			url = '/create-provision-order/' + this.props.currentOrder.vessel + "?orderId=" + this.props.match.params.orderId;
		}
		let data = { ...this.props.currentOrder };
		data.orderDetail = this.state.orderDetail;
		if (data.orderKind !== InventoryConstant) {
			data.country.label = data.country.name;
		}
		this.props.AddOrderAction(data);
		this.props.history.push(url);
	}
	closeOrder = () => {
		this.props.AddOrderAction({});
		this.props.history.push(getDashboardLink());
	}
	saveAndClose = (e) => {
		this.setState({
			isSaveAndClose: true,
		})
		this.updateOrder()
	}
	downloadCatalogue = () => {
		window.open(Constant.mediaUrl + "Catalogue/inventory.xlsx");
	}
	getNavbarOptions = () => {
		let arr = [
			{
				img: 'left-arrow.svg',
				className: "orange",
				name: "Go back",
				path: '/create-provision-order/',
				isLogout: false,
				isCustom: true,
				onClick: this.goBack
			},

		];
		if (this.props.currentOrder.orderKind !== InventoryConstant && this.props.subType!=='subAdmin') {
			arr.push({
				img: 'Monitor-icon.svg',
				className: "dark-blue",
				name: "Save<br />Order",
				path: '',
				isLogout: false,
				isCustom: true,
				onClick: this.saveAndClose
			})
		}
		arr.push({
			img: 'Message-icon.svg',
			className: `green ${this.props.apiCallStatus.isStarted.indexOf("updateOrder") !== -1 ? "disabled" : ""}`,
			name: `Add  ${this.props.currentOrder && this.props.currentOrder.orderKind === InventoryConstant ? "" : "to Order Basket"}`,
			path: "/checkout",
			isLogout: false,
			isCustom: true,
			onClick: this.updateOrder
		})
		return arr;
	}
	handleOrderedList = (showOrderedList) => {
		let filterCatalouge = [];
		let totalCount = 0;
		if (showOrderedList) {
			filterCatalouge = Object.values(this.state.orderDetail);
			totalCount = filterCatalouge.length;
		} else {
			filterCatalouge = this.props.orderReducer.provisionList;
			totalCount = this.props.orderReducer.totalProvision;
		}
		this.setState({
			filterCatalouge,
			totalCount,
			showOrderedList
		})
	}
	render() {
		const totalUsedPoints = calculateTotalUsedPoint(this.state.orderDetail, this.state.numOfCrew, this.state.numOfDays);
		let isInvenotry = this.props.currentOrder && this.props.currentOrder.orderKind === InventoryConstant ? true : false
		return (
			<Wrapper>
				<div className="pageWrapper">
					<div className="pageContainer mb-0">
						<DemoHeader />
						<div className="order-dlts-wrapper">

							<DashboardNavbar title={`${this.props.currentOrder && this.props.currentOrder.orderKind ? isInvenotry ? "INVENTORY DETAILS" : "PROVISION ORDER DETAILS" : ''}`} navbarOptions={this.getNavbarOptions()} isShowLogo={true} logoUrl="logo.png" logoType="Order" className="pageHeader d-flex align-items-center flex-column flex-lg-row" />

						</div>
						{/* Order Overview */}
						{this.props.currentOrder && this.props.currentOrder.orderKind !== InventoryConstant ?
							<OrderOverView provisionOrderType={this.props.currentOrder.orderKind} category={this.state.vesselCategory} numOfCrew={this.state.numOfCrew} numOfDays={this.state.numOfDays} orderDetail={this.state.orderDetail} orderNeeded={this.state.orderNeeded} totalOrder={this.state.totalOrder} totalUsedPoints={totalUsedPoints} totalOrderPoints={this.state.totalOrderPoints} />
							: ""}
						{/* Provision Catalogue Table */}
						<div className="ordCatg-table-wrap mt-3">
							<Filters {...this.state.filters} downloadCatalogue={this.downloadCatalogue} orderKind={this.props.currentOrder.orderKind} handleOrderedList={this.handleOrderedList} showOrderedList={this.state.showOrderedList} isInvenotry={isInvenotry} fetchMoreItemCode={this.fetchMoreItemCode} fetchMoreDescription={this.fetchMoreDescription} totalItemCode={this.state.totalItemCode} totalDescription={this.state.totalDescription} itemGroupList={this.state.itemGroupList} itemNoList={this.state.itemNoList} descriptionList={this.state.descriptionList} handleChange={this.handleFilterChange} clickCustomeSelectHandler={this.clickCustomeSelectHandler} clearFilters={this.clearFilters} applyFilters={this.applyFilters} getFilterDetail={this.props.getFilterDetail} />
							{/* Table */}
							<CatalougeTable isInvenotry={isInvenotry} data={this.state.filterCatalouge} fetchMoreData={this.fetchMoreData} totalCount={this.state.totalCount} handleTableChange={this.handleTableChange} orderDetail={this.state.orderDetail} totalOrderBudget={this.state.totalOrderBudget} totalOrderPoints={this.state.totalOrderPoints} apiCallStatus={this.props.apiCallStatus} country={this.props.currentOrder && this.props.currentOrder.country ? this.props.currentOrder.country.label : ''} showItemDetailModel={this.showItemDetailModel} />
						</div>
					</div>
					{/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
					{/* Order Details Modal */}
					{/* <OrderDetailModal category={this.state.vesselCategory} totalUsedPoints={totalUsedPoints} numOfDays={this.state.numOfDays} numOfCrew={this.state.numOfCrew} orderDetail={this.state.orderDetail} /> */}
					<NutritionDetail selectedItem={this.state.selectedItem} />
				</div>
			</Wrapper>
		)
	}
}


const mapStateToProps = (state, ownProps) => ({
	apiCallStatus: state.apicallStatusReducer,
	vesselDetail: state.commonReducer.vesselById,
	orderReducer: state.orderReducer,
	currentOrder: state.orderReducer.currentOrder,
	userId: state.authReducer.userId,
	userType: state.authReducer.userType,
	isSubAdmin: state.authReducer.isSubAdmin,
	subType: state.authReducer.subType,

})

const mapDispatchToProps = (dispatch, ownProps) => ({
	provisionListing: (offset, limit, filterQuery) => dispatch(provisionListing(offset, limit, filterQuery)),
	getItemCode: (offset, query) => dispatch(getItemCode(offset, query)),
	getDescription: (offset, query) => dispatch(getDescription(offset, query)),
	getItemGroup: (query) => dispatch(getItemGroup(query)),
	getFilterDetail: (offset, filterQuery) => dispatch(getFilterDetail(offset, filterQuery)),
	updateOrder: (data) => dispatch(updateOrder(data)),
	AddOrderAction: (data) => dispatch(AddOrderAction(data)),
	ApiClearAction: () => dispatch(ApiClearAction()),
	getOrderDetail: (orderId) => dispatch(getOrderDetail(orderId)),
	getVesselById: (id) => dispatch(getVesselById(id)),
})
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProvisionCatalogue);




