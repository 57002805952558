import React, { useEffect, useState } from 'react';
import { Wrapper,DashboardNavbar } from '../../Common/Components/index';
import { getCaptainDetail, updateCaptain } from "./../ApiCalls/captain";
import { getAllCountry } from '../../Common/ApiCalls/countryList';
import { getAllPort } from '../../Common/ApiCalls/portList';
import { getAllVessel } from '../../Common/ApiCalls/vesselList';
import { ApiClearAction, ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { isAllowPermission } from '../../../Services/common';

const captianInitialState = {
    name: '',
    port: '',
    portOption: [],
    selectedPortOption: null,
    country: '',
    countryOption: [],
    selectedCountryOption: null,
    vessel: '',
    vesselOption: [],
    selectedVesselOption: null,
    isHitApi: false
}
function EditCaptain(props) {
    const [state, setState] = useState(captianInitialState);
    const [isHitOnLoadApi, setIsHitOnLoadApi] = useState(false);
    useEffect(() => {
        if (!isHitOnLoadApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.getCaptainDetail(props.match.params.id)
                props.getAllCountry();
                // props.getAllPort('')
                props.getAllVessel()
                setIsHitOnLoadApi(true)
            }
        }
        if (props.apiCallStatus.apiCallFor === "getAllCountry" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.commonReducer.countryList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].name, countryCode: arr[i].countryCode });
            }
            setState({
                ...state,
                countryOption: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "getAllPort" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.commonReducer.portList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].port });
            }
            setState({
                ...state,
                portOption: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "getAllVessel" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            let newArr = [];
            for (let index = 0; index < props.commonReducer.vesselList.length; index++) {
                let val = props.commonReducer.vesselList[index];
                newArr.push({ label: val.name, value: val._id })
            }
            setState({
                ...state,
                vesselOption: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getCaptainDetail") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(props.captainReducer.detail)
            let query = "countryCode=" + props.captainReducer.detail.selectedCountryOption.countryCode;
            props.getAllPort(query);
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "updateCaptain") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(captianInitialState)
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
            Swal.fire("Success", `Captain has been updated successfully`, "success")
            props.history.push('/captain-listing')
        }
    });
    const handleFormChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let captainDetail = { ...state }
        captainDetail[name] = value;
        setState(captainDetail)
    }
    const handleSelectChange = (e, name) => {
        if (name === 'port') {
            let captainDetail = { ...state }
            captainDetail['selectedPortOption'] = e
            captainDetail['port'] = e.value

            setState(
                captainDetail
            )
        } else if (name === 'country') {
            let captainDetail = { ...state }
            captainDetail['selectedCountryOption'] = e
            captainDetail['country'] = e.value
            captainDetail['port'] = '';
            captainDetail['portOption'] = [];
            captainDetail['selectedPortOption'] = null;
            let query = "countryCode=" + e.countryCode;
            props.getAllPort(query);
            setState(
                captainDetail
            )
        } else {
            let captainDetail = { ...state }
            if (e.value !== state.vessel) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Do you want to release your previous vessel !",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then(result => {
                        if (result.isConfirmed) {
                            captainDetail['selectedVesselOption'] = e
                            captainDetail['vessel'] = e.value
                            captainDetail['errorMessage'] = ''
                            captainDetail['isError'] = false
                            setState(
                                captainDetail
                            )
                        }
                    });
            }
        }

    }
    const updateCaptain = () => {
        const { name, country, port, vessel } = state;
        if (country === undefined || country === null || country === '') {
            Swal.fire("Error", "Please select country", "error");

        } else if (port === undefined || port === null || port === '') {
            Swal.fire("Error", "Please select port", "error");

        }
        // else if (vessel === undefined || vessel === null || vessel === '') {
        //     Swal.fire("Error", "Please select vessel", "error");

        // } 
        else {
            let obj = { name, country, port, vessel, captainId: props.match.params.id }
            props.updateCaptain(obj)
        }

    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Edit Captain" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="formBox">
                <CaptainForm {...state} history={props.history} handleFormChange={handleFormChange} handleSelectChange={handleSelectChange} updateCaptain={updateCaptain} apiCallStatus={props.apiCallStatus} />
            </div>
        </Wrapper>
    )
}

function CaptainForm(props) {
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            props.updateCaptain();
        }
    }
    return (
        <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="name" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Captain Name"
                            value={props.name}
                            required />
                        <lable className="foating-labels-sty">Captain Name</lable>

                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Country"
                                value={props.selectedCountryOption}
                                onChange={(e) => { props.handleSelectChange(e, 'country') }}
                                options={props.countryOption}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Port"
                                value={props.selectedPortOption}
                                onChange={(e) => { props.handleSelectChange(e, 'port') }}
                                options={props.portOption}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Vessel"
                                value={props.selectedVesselOption}
                                onChange={(e) => { props.handleSelectChange(e, 'vessel') }}
                                options={props.vesselOption}
                            />
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {props.apiCallStatus.apiCallFor === "editVessel" && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">Update</button>
                </div>
            </div>
        </form>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    countryReducer: state.countryReducer,
    portReducer: state.portReducer,
    captainReducer: state.captainReducer,
    commonReducer: state.commonReducer,
    roles: state.authReducer.roles
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
    getAllCountry: () => dispatch(getAllCountry()),
    getAllVessel: () => dispatch(getAllVessel()),
    updateCaptain: (data) => dispatch(updateCaptain(data)),
    getCaptainDetail: (id) => dispatch(getCaptainDetail(id)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCaptain);
