export const getRole = "GET_ROLE";
export const AppendRoleList = "APPEND_ROLE_LIST";
export const getRoleDetail = "GET_ROLE_DETAIL";
export const getSubAdmin = "GET_SUB_ADMIN";
export const AppendSubAdminList = "APPEND_SUB_ADMIN_LIST";
export const getSubAdminDetail = "GET_SUB_ADMIN_DETAIL";
export const getAllRole = "GET_ALL_ROLE";

export function GetRoleAction(list, count) {
  return {
    type: getRole,
    payload: { list, count },
  };
}
export function AppendRoleAction(list) {
  return {
    type: AppendRoleList,
    payload: { list },
  };
}
export function GetRoleDetailAction(detail) {
  return {
    type: getRoleDetail,
    payload: { detail },
  };
}
export function GetSubAdminAction(list, count) {
  return {
    type: getSubAdmin,
    payload: { list, count },
  };
}
export function AppendSubAdminAction(list) {
  return {
    type: AppendSubAdminList,
    payload: { list },
  };
}
export function GetSubAdminDetailAction(detail) {
  return {
    type: getSubAdminDetail,
    payload: { detail },
  };
}
export function getAllRoleAction(list) {
  return {
    type: getAllRole,
    payload: { list },
  };
}
