import React, { Component } from 'react';
import { SupplierWrapper } from '../supplierWrapper';
import { ProfileDetail } from './profileDetail';
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import { getSupplierDetail } from "../../ApiCalls/supplier";
import { connect } from 'react-redux';

class Profile extends Component {
	state = {
		selectedPortOption: null,
		selectedSortOption: null,
		selectionType:'',
		id:'',
		portOptions: [
			{ value: 'singapore', label: 'SingaPore' },
			{ value: 'china', label: 'China' },
			{ value: 'russia', label: 'Russia' },
		],
		sortByOptions: [
			{ value: 'bestRate', label: 'Best Rate' },
			{ value: 'fastest', label: 'Fastes' },
			{ value: 'lowPrice', label: 'Low Price' },
		]
	};
	componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);
		const selectionType = query.get('selectionType')
		const id = query.get('id')
		this.setState({
			selectionType,
			id
		})
		this.props.getSupplierDetail(this.props.match.params.id)
	}
	handlePortChange = selectedPortOption => {
		this.setState({ selectedPortOption });
	};
	handleSortChange = selectedSortOption => {
		this.setState({ selectedSortOption });
	};
	render() {

		return (
			<SupplierWrapper title="SUPPLIER PROFILE" handleSortChange={this.handleSortChange} handlePortChange={this.handlePortChange}   selectedPortOption={this.state.selectedPortOption} selectedSortOption={this.state.selectedSortOption} portOptions={this.state.portOptions} sortByOptions={this.state.sortByOptions} history={this.props.history} screenName='supplierProfile'>
				<ProfileDetail {...this.props.detail} selectedItem={this.props.match.params.id} selectionType={this.state.selectionType} currentId={this.state.id} />
			</SupplierWrapper>
		)
	}
}

const mapStateToProps = (state, ownProps) => ({
	apiCallStatus: state.apicallStatusReducer,
	detail: state.supplierReducer.detail,
	userId: state.authReducer.userId
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	getSupplierDetail: (id) => dispatch(getSupplierDetail(id)),
	ApiClearAction: () => dispatch(ApiClearAction())
})
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Profile);