import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "./../../../Constants/constant";
import {
  AddOrderAction,
  GetProvisionAction,
  GetTopItemsAction,
  AppendTopItemsAction,
  AppendProvisionAction,
  GetFilterDetailAction,
  AppendFilterDetailAction,
  GetOrderAction,
  AppendOrderAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
import { getCookie } from "../../../Services/cookies";

export function provisionListing(offset, limit, filterQuery) {
  return async (dispatch) => {
    let url = "";
    if (filterQuery !== "" && filterQuery !== undefined) {
      url = Constant.apiURl + "/catalouge/items/" + offset + "/" + limit + filterQuery;
    } else {
      url = Constant.apiURl + "/catalouge/items/" + offset + "/" + limit;
    }
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendProvisionList" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getProvision" }));
      dispatch(GetProvisionAction([], 0));
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendProvisionAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendProvisionList",
            message: "",
          })
        );
      } else {
        dispatch(GetProvisionAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getProvision",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendProvisionList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getProvision",
            message: myJson.message,
          })
        );
      }
    }
  };
}
export function topItemsListing(data, userType, from, to, itemCode, description) {
  return async (dispatch) => {
    let url = "";
    let bodyContent = {};
    url = Constant.apiURl + "/quotation/topItems";
    if (userType) {
      userType === "supplier"
        ? (bodyContent["supplierId"] = data)
        : userType === "customer"
        ? (bodyContent["vesselId"] = data)
        : (bodyContent["customerId"] = data);
    }
    if (itemCode) {
      bodyContent["itemCode"] = itemCode;
    }
    if (description) {
      bodyContent["description"] = description;
    }
    if (from && to) {
      bodyContent["from"] = from;
      bodyContent["to"] = to;
    }
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getTopItems" }));
    let myJson = await FETCH("POST", url, {
      ...bodyContent,
    });
    if (myJson && myJson.code === 201) {
      dispatch(GetTopItemsAction(myJson.data, myJson.count));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getTopItems",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getTopItems",
          message: myJson.message,
        })
      );
    }
  };
}
export function getFilterDetail(offset, filterQuery) {
  return async (dispatch) => {
    let url = "";
    if (filterQuery !== "" && filterQuery !== undefined) {
      url = Constant.apiURl + "/catalouge/items/" + offset + "/200" + filterQuery;
    } else {
      url = Constant.apiURl + "/catalouge/items/" + offset + "/200";
    }
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendFilterDetail" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getFilterDetail" }));
      dispatch(GetFilterDetailAction([], [], 0));
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendFilterDetailAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendFilterDetail",
            message: "",
          })
        );
      } else {
        dispatch(GetFilterDetailAction(myJson.data, myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getFilterDetail",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendFilterDetail",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getFilterDetail",
            message: myJson.message,
          })
        );
      }
    }
  };
}

export function saveOrder(data) {
  return async (dispatch) => {
    let url = Constant.apiURl + "/orders/save";
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "saveOrder" }));
    let userType = getCookie("userType");
    let subType = getCookie("subType");
    if (userType === "customer" && subType === "customer") {
      data["userType"] = "customer";
    }
    let myJson = await FETCH("POST", url, data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "saveOrder",
          message: myJson.data._id,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "saveOrder",
          message: myJson.message,
        })
      );
    }
  };
}

export function getOrderListing(offset, limit, filterQuery) {
  return async (dispatch) => {
    let url = "";
    if (getCookie("userType") === "customer") {
      if (filterQuery !== "" && filterQuery !== undefined) {
        url =
          Constant.apiURl +
          "/orders/" +
          offset +
          "/" +
          limit +
          filterQuery +
          `&userId=${getCookie("userId")}`;
      } else {
        url =
          Constant.apiURl + "/orders/" + offset + "/" + limit + `?userId=${getCookie("userId")}`;
      }
    } else {
      url = Constant.apiURl + "/orders/" + offset + "/" + limit + filterQuery;
    }

    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendOrderList" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getOrder" }));
      dispatch(GetOrderAction([], 0));
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendOrderAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendOrderList",
            message: "",
          })
        );
      } else {
        dispatch(GetOrderAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getOrder",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendOrderList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getOrder",
            message: myJson.message,
          })
        );
      }
    }
  };
}

export function getOrderDetail(orderId) {
  return async (dispatch) => {
    let url = Constant.apiURl + "/orders/orderById";
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getOrderDetail" }));
    let myJson = await FETCH("POST", url, { orderId });
    if (myJson && myJson.code === 201) {
      let obj = {};
      if (myJson.data) {
        let orderDetail = {};
        myJson.data.orderDetails &&
          myJson.data.orderDetails.forEach((element) => {
            orderDetail[element.itemCode] = element;
          });
        obj = {
          imoNum: myJson.data.imoNumber,
          _id: myJson.data._id,
          vessel: myJson.data.vesselId,
          captain: myJson.data.captainId,
          place: myJson.data.placeOfDelivery,
          country: {
            name: myJson.data.countryLabel ? myJson.data.countryLabel : myJson.data.countryName,
            value: myJson.data.countryId,
            countryCode: "DE",
            label: myJson.data.countryLabel ? myJson.data.countryLabel : myJson.data.countryName,
          },
          orderDetail: orderDetail,
          orderDetails: myJson.data.orderDetails,
          agentDetail: myJson.data.agentDetails,
          eta: myJson.data.arrivalTime,
          etd: myJson.data.departureTime,
          budgetPerDay: myJson.data.budgetPerDay,
          currency: myJson.data.currency,
          numberOfCrew: {
            regular: myJson.data.regularCrew,
            additional: myJson.data.additionalCrew,
          },
          vesselRate: myJson.data.budgetPerDay,
          userId: myJson.data.customerId,
          supplierId: myJson.data.supplierId,
          isOrder: myJson.data.isOrder,
          orderProvisionType: myJson.data.orderProvisionType,
        };
        if (myJson.data.orderProvisionType === "store") {
          obj.department = myJson.data.department;
          obj.refNo = myJson.data.refNo ? myJson.data.refNo : "";
          obj.rank = myJson.data.rank;
          obj.orderPeriod = {
            regular: myJson.data.dryOrderDays,
            expectedDelay: myJson.data.dryAddtionalOrderDays,
          };
          obj.numberOfCrew = {
            regular: 0,
            additional: 0,
          };
          obj.deptBudget = myJson.data.deptBudget;
          obj.orderBy = myJson.data.orderBy;
        } else {
          obj.orderKind = myJson.data.orderType;
          obj.categories = myJson.data.categories ? myJson.data.categories : [];
          obj.cook = myJson.data.cookId;
          obj.numberOfCrew = {
            regular: myJson.data.regularCrew,
            additional: myJson.data.additionalCrew,
          };
          obj.orderPeriod = {
            dryFrozen: {
              regular: myJson.data.dryOrderDays,
              expectedDelay: myJson.data.dryAddtionalOrderDays,
            },
            freshProvisions: {
              regular: myJson.data.freshOrderDays,
              expectedDelay: myJson.data.freshAddtionalOrderDays,
            },
          };
        }
      }
      dispatch(AddOrderAction(obj));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getOrderDetail",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getOrderDetail",
          message: myJson.message,
        })
      );
    }
  };
}

export function updateOrder(data) {
  return async (dispatch) => {
    let url = Constant.apiURl + "/orders/updateById";
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateOrder" }));
    let myJson = await FETCH("POST", url, data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "updateOrder",
          message: myJson.data._id,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "updateOrder",
          message: myJson.message,
        })
      );
    }
  };
}

export function updateOrderType(data) {
  return async (dispatch) => {
    let url = Constant.apiURl + "/orders/updateType";
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateOrderType" }));
    let myJson = await FETCH("POST", url, data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "updateOrderType",
          message: myJson.data._id,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "updateOrderType",
          message: myJson.message,
        })
      );
    }
  };
}
