import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "./../../../Constants/constant";
import {
  basketOrdersAction,
  updateIgnoreDepartAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function basketOrders(id) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "basketOrders" }));
    let myJson = await FETCH("POST", Constant.apiURl + "/orders/order/basket", {
      captainId: id,
    });
    if (myJson && myJson.code === 201) {
      let provisionSupplier = { id: myJson.provisionSupplierId, name: "" };
      let storeSupplier = { id: myJson.storeSupplierId, name: "" };
      if (myJson.suppliers !== undefined && myJson.suppliers.length > 0) {
        let provisionIndex = myJson.suppliers.findIndex(
          (x) => x._id === myJson.provisionSupplierId
        );
        let storeIndex = myJson.suppliers.findIndex(
          (x) => x._id === myJson.storeSupplierId
        );
        if (provisionIndex !== -1) {
          provisionSupplier["name"] = myJson.suppliers[provisionIndex].name;
        }
        if (storeIndex !== -1) {
          storeSupplier["name"] = myJson.suppliers[storeIndex].name;
        }
      }
      dispatch(
        basketOrdersAction(
          myJson.data,
          myJson.provisionBasketId,
          myJson.storeBasketId,
          provisionSupplier,
          storeSupplier,
          myJson.ignoreDepart,
          myJson.storeArrivalTime,
          myJson.provisionArrivalTime,
        )
      );
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "basketOrders",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "basketOrders",
          message: myJson.message,
        })
      );
    }
  };
}

export function updateCartETA(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateCartETA" }));
    let myJson = await FETCH(
      "POST",
      Constant.apiURl + "/orders/update/eta",
      data
    );
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "updateCartETA",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "updateCartETA",
          message: myJson.message,
        })
      );
    }
  };
}

export function ignoreDepartmentCart(data) {
  return async (dispatch) => {
    dispatch(
      Actions.ApiRequestedAction({
        apiCallFor: "ignoreDepartmentCart",
        message: data.cartId,
      })
    );
    let myJson = await FETCH(
      "POST",
      Constant.apiURl + "/orders/ignore/department",
      data
    );
    if (myJson && myJson.code === 201) {
      dispatch(updateIgnoreDepartAction(data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "ignoreDepartmentCart",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "ignoreDepartmentCart",
          message: myJson.message,
        })
      );
    }
  };
}
