import React, { Component } from 'react'
import { Wrapper } from '../Css/notfound';

export default function Notfound(props) {
    return (
        <Wrapper>
            <>
                {/* <div className="notfound">
                <div className="notfound-404"></div>
                <h1>404</h1>
                <h2>Oops! Page Not Be Found</h2>
                <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
                <a onClick={() => props.history.goBack()}>Back to homepage</a>
            </div> */}
                {/*  */}
                <section className="wrapper">
                    <div className="container">

                        <div id="scene" className="scene" data-hover-only="false">


                            <div className="circle" data-depth="1.2"></div>

                            <div className="one" data-depth="0.9">
                                <div className="content">
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                </div>
                            </div>

                            <div className="two" data-depth="0.60">
                                <div className="content">
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                </div>
                            </div>

                            <div className="three" data-depth="0.40">
                                <div className="content">
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                    <span className="piece"></span>
                                </div>
                            </div>

                            <p className="p404" data-depth="0.50">404</p>
                            <p className="p404" data-depth="0.10">404</p>

                        </div>

                        <div className="text">
                            <article>
                                <p>Uh oh! Looks like you got lost. <br />Go back to the homepage if you dare!</p>
                                <button onClick={() => props.history.goBack()} >i dare!</button>
                            </article>
                        </div>

                    </div>
                </section>
            </>
        </Wrapper>
    )
}