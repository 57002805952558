export const getPorts = "GET_PORTS";
export const getPortDetail = "GET_PORT_DETAIL";
export const AppendPortList="APPEND_PORT_LIST";

export function GetPortsAction(list, count) {
  return {
    type: getPorts,
    payload: { list,count },
  };
}
export function AppendPortAction(list) {
  return {
    type: AppendPortList,
    payload: { list },
  };
}
export function GetPortDetailAction(detail) {
  return {
    type: getPortDetail,
    payload: { detail },
  };
}
