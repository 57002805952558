import React from 'react';
import Sidebar from '../../Common/Components/sidebar';
import  DashboardNavbar  from '../../Common/Components/dashboardNavbar';
import DemoHeader from '../../Common/Components/demoHeader';
import './dashboard-style.css';

export const DashboardWrapper = (props) => (
    <div className="dash-content-ctn d-flex">
        {/* Slide Bar Content */}
        <Sidebar />
        {/* Body Content */}
        <div className="dash-page-content vesselOverview-cont-wrap">
            <DemoHeader />
            <div className="dash-pg-inn-wrap">
                <DashboardNavbar title={props.title} navbarOptions={props.navbarOptions} className={props.className} />
                {props.children}
            </div>
            {/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
        </div>
    </div>
);