import * as Action from "./../Actions/action";
import InitialState from "./../../../Constants/initialState";
import cloneDeep from "lodash/cloneDeep";
export default function orderBasketReducer(
  state = InitialState.orderBasket,
  action
) {
  switch (action.type) {
    case Action.getOrderBasket:
      let s = cloneDeep(state);
      s.list = action.payload.list;
      s.provisionBasketId = action.payload.provisionBasketId;
      s.storeBasketId = action.payload.storeBasketId;
      s.provisionSupplier = action.payload.provisionSupplier;
      s.storeSupplier = action.payload.storeSupplier;
      s.ignoreDepart = action.payload.ignoreDepart;
      s.storeArrivalTime = action.payload.storeArrivalTime;
      s.provisionArrivalTime = action.payload.provisionArrivalTime;
      return s;

    case Action.updateIgnoreDepart:
      let s1 = cloneDeep(state);
      if (action.payload.status === "ready") {
        s1.ignoreDepart.push(action.payload.department);
      } else if (action.payload.status === "remove") {
        let index = s1.ignoreDepart.indexOf(action.payload.department);
        if (index > -1) {
          s1.ignoreDepart.splice(index, 1);
        }
      }
      return s1;
    default:
      return state;
  }
}
