import React, { useEffect, useState } from 'react';
import { RatingStars } from "../../../Common/Components/ratingStars";

export function RatingModal(props) {
    const [rate, setRate] = useState(0);
    useEffect(() => {
        if (props.apiCallStatus.apiCallFor === 'rateSupplier' && props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed && rate !== 0) {
            setRate(0)
            props.ClearApiByNameAction('rateSupplier')
        }
        if (props.apiCallStatus.apiCallFor === 'getUserRate' && props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setRate(props.apiCallStatus.message)
            props.ClearApiByNameAction('getUserRate')
        }

    })
    const rateSupplier = () => {
        props.rateSupplier({ customerId: props.customerId, supplierId: props.selectedSupplier, rate: rate })
    }
    const handleChange = (val) => {
        setRate(val)
    }
    return (
        <div  className=" ratingmodel modal fade themeRounded-modal " id="ratingModal" tabIndex="-1" aria-labelledby="NutritaionModal" aria-hidden="true" >
            <div className="modal-dialog">
                <div className="modal-content theme-modal-innr rat-us-mod px-5 pb-5">
                    <RatingStars id="input" className="rating-star-wrap" isEdit={true} ratingAvg={rate} handleChange={handleChange} />
                    <h5 className="mb-2">Thank you for using Mesh</h5>
                    <p className="grey">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                    {props.apiCallStatus.isStarted.indexOf("rateSupplier") !== -1 ? <div className="loader-img text-center w-100">
                        <img style={{ width: "46px" }} src={require("../../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                    </div> : ""}
                    {props.apiCallStatus.apiCallFor === "rateSupplier" && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                        <div className="alert alert-danger">
                            {props.apiCallStatus.message}
                        </div>
                        : ""}
                    <button type="button" onClick={() => rateSupplier()} className="btn tb-btn-rounded orng rounded">Rate & Send feedback</button>
                </div>
            </div>
        </div >
    )
}