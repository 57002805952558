import React, { useEffect, useState } from 'react';
import { Wrapper, DashboardNavbar } from '../../Common/Components/index';
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { addRole } from "../ApiCalls/role";
import { AddRoleForm } from './addRoleForm';
import { connect } from 'react-redux';
import { getRoutesDetail } from '../../../Constants/routesConfig';
import cloneDeep from 'lodash.clonedeep';
import Swal from 'sweetalert2';
import "react-datepicker/dist/react-datepicker.css";
import '../css/roles.css';
const initialPermission = {
    department: [],
    provisionOrder: []
}
const addRoleInitialState = {
    roleName: '',
    allRoutes: {},
    roles: [],
    permission: JSON.parse(JSON.stringify(initialPermission)),
    isApiHit: false,
    permissionAll: false,
}
function AddRole(props) {
    const [state, setState] = useState(addRoleInitialState);
    useEffect(() => {
        if (!state.isApiHit) {
            if (props.isSubAdmin) {
                props.history.push('/not-allowed')
            } else {
                let arr = cloneDeep(getRoutesDetail());
                setState({
                    ...state,
                    isApiHit: true,
                    allRoutes: arr,
                    permission: {
                        department: [],
                        provisionOrder: []
                    },
                    roles: [{ key: "contractOffice", path: "/contract-office" }]
                })
            }
        }
        if ((props.apiCallStatus.apiCallFor === "addRole") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let msg = `The ${state.roleName} has been created successfully.`
            Swal.fire("Success", msg, "success");
            setState(addRoleInitialState)
            props.history.push('/role-listing')
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
    })
    const dropDownHandler = (e, fieldName) => {
        let data = { ...state }
        let name = e.target.name === 'AdditionalPro' ? 'Additional' : e.target.name;
        let permissionName = data.permission[fieldName];
        let index = permissionName.findIndex(x => x === name);
        if (index === -1) {
            permissionName.push(name)
        } else {
            permissionName.splice(index, 1)

        }
        data.permission[fieldName] = permissionName
        setState(data)
    }
    const handleFormChange = (e, listName) => {
        let data = { ...state }
        let roles = data.roles;
        let name = e.target.name;
        if (name === 'permissionAll') {
            let value = e.target.checked
            setState({
                ...state,
                roles: [],
                permission: {
                    department: [],
                    provisionOrder: []
                },
                permissionAll: value
            })
        } else {
            let value = '';
            if (name === 'roleName') {
                value = e.target.value
                data[name] = value
                setState(data)
            } else {
                if (name === '/delete-vessel' || name === '/delete-user' || name === '/delete-port' || name === '/delete-country' || name === '/delete-department' || name === "/delete-supplier" || name === '/delete-package' || name === '/delete-catalogue' || name === '/delete-customer') {
                    value = {
                        key: name,
                        path: name
                    }
                } else if (state.allRoutes[name]) {
                    value = {
                        key: name,
                        path: state.allRoutes[name]
                    };

                } else {
                    if (name === 'sendOrder') {
                        value = {
                            key: "sendOrder",
                            path: '/send/order/:supplierId/:orderId'
                        }
                    } else if (name === 'sendRfq') {
                        value = {
                            key: "sendRfq",
                            path: '/send/rfq/:supplierId/:orderId'
                        }
                    }
                }
                if (listName) {
                    let index = roles.findIndex(x => x.key === listName);
                    if (index === -1) {
                        let obj = {
                            key: listName,
                            path: state.allRoutes[listName]
                        };

                        roles.push(obj)
                    }
                }
                let index = roles.findIndex(x => x.key === name);
                if (index === -1) {
                    if (name === 'contractOffice' || name === 'userDashboard') {
                        let removedDashboard = name === 'contractOffice' ? 'userDashboard' : 'contractOffice';
                        let newIndex = roles.findIndex(x => x.key === removedDashboard);
                        if (newIndex !== -1) {
                            roles.splice(newIndex, 1)
                        }
                    }
                    roles.push(value)
                } else {
                    if (name === 'contractOffice') {
                        roles.push({
                            key: "userDashboard",
                            path: state.allRoutes["userDashboard"]
                        })
                    } else if (name === 'userDashboard') {
                        roles.push({
                            key: "contractOffice",
                            path: state.allRoutes["contractOffice"]
                        })
                    }
                    roles.splice(index, 1)
                }
                setState({
                    ...state,
                    roles
                })
            }


        }

    }
    const createHandler = () => {
        let data = { ...state }
        let obj = {
            roleName: data.roleName,
            roles: data.roles,
            permission: data.permission,
            permissionAll: data.permissionAll
        }
        props.addRole(obj)
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
    ];

    return (
        <Wrapper>
            <div className="">
                <DashboardNavbar title="Add Role" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
                <AddRoleForm {...state} history={props.history} handleFormChange={handleFormChange} handleSubmit={createHandler} apiCallStatus={props.apiCallStatus} apiName="addRole" dropDownHandler={dropDownHandler} />
            </div>
        </Wrapper>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    isSubAdmin: state.authReducer.isSubAdmin
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    addRole: (data) => dispatch(addRole(data))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddRole);