import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { io } from 'socket.io-client';
import { Constant } from "../../../Constants/constant";
import { getCookie, setCookie } from "../../../Services/cookies";
import { updateUnReadMsgCountAction, appendNewNotificationAction } from "../Actions/actions";
import { getNotificationList } from "../ApiCalls/socket";
import { ClearApiByNameAction, ApiRequestedAction } from "../../ApiCallStatus/Actions/action";

export var socket = null;
function Sockets(props) {
    const [state, updateState] = useState({
        isBind: false,
        isAllowCon: true,
        offset: 0,
        limit: 8,
        isLogin: getCookie("token") === "" ? false : true
    });
    useEffect(() => {
        props.getNotificationList(props.user.userId, state.offset, state.limit)
        if (state.isLogin && !state.isBind) {
            console.log("join room")
            bindSocket();
            updateState({
                ...state,
                isBind: true,
                isAllowCon: true,
                isLogin: props.user.isLogin
            });
        }
    }, []);
    useEffect(() => {
        if (state.isLogin !== props.user.isLogin) {
            if (props.user.isLogin) {
                console.log("join room")
                setTimeout(() => {
                    props.getNotificationList(props.user.userId, 0, state.limit)
                }, 500)
                bindSocket();
                updateState({
                    ...state,
                    offset: 0,
                })
            } else if (!props.user.isLogin && socket) {
                console.log("remove From room")
                socket.emit("leaveRoom", props.user.userId);
                socket = null;
            }
            updateState({
                ...state,
                isLogin: props.user.isLogin
            });
        }
        if (props.apiCallStatus.apiCallFor === "fetchNewNotification" && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let offset = state.offset + 1;
            props.getNotificationList(props.user.userId, state.offset + 1, state.limit);
            updateState({
                ...state,
                offset: offset
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
    }, [props]);
    const bindSocket = () => {
        console.log('connecting')
        socket = io(Constant.socketUrl, { transports: ['websocket'] });
        // socketObj = socket;
        socket.on("connect", () => {
            console.log(socket.id);
        });
        socket.emit("joinRoom", props.user.userId);
        socket.on("getUnreadMesage", (count) => {
            console.log("count", count)
            props.updateUnReadMsgCountAction(count)
        })
        socket.on("new message", (data) => {
            console.log(data)
        })
        socket.on("getUserNotification", (data) => {
            if (props.user.userId === data.data.receiverId) {
                if (data.data.title === "Supplier sends/uploads an order confirmation") {
                    setCookie("orderFeedback", JSON.stringify(data.data), 1);
                    props.ApiRequestedAction({ apiCallFor: "orderFeedback", message: data.data })
                }
                props.appendNewNotification(data.data, data.count)
            }
        })
    }
    return "";
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    user: {
        isLogin: state.authReducer.isLogin,
        userId: state.authReducer.userId
    },
    notificationsList: state.socketReducer.notificationsList
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    // Actions
    updateUnReadMsgCountAction: (count) => dispatch(updateUnReadMsgCountAction(count)),
    appendNewNotification: (data, count) => dispatch(appendNewNotificationAction(data, count)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    ApiRequestedAction: (data) => dispatch(ApiRequestedAction(data)),
    // Apicalls
    getNotificationList: (userId, offset, limit) => dispatch(getNotificationList(userId, offset, limit))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Sockets)