import React, { useEffect, useState } from 'react';
import { Wrapper } from '../../Common/Components/CommonWrapper';
import { getAllCountry } from '../../Common/ApiCalls/countryList';
import { createPort } from "../ApiCalls/port";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import Select from 'react-select';
import { getDashboardLink ,isAllowPermission} from '../../../Services/common';
import  DashboardNavbar  from '../../Common/Components/dashboardNavbar';
import Swal from 'sweetalert2'
import { getRoutePathBykey } from '../../../Constants/routesConfig';

const portInitialState = {
    code: '',
    countryOption: [],
    country: '',
    countryName: '',
    selectedOption: null,
    continent: '',
    continent2: '',
    region: '',
    port: '',
    portType: '',
    latitude: '',
    longitude: '',
    portsArray: [],
    errorMessage: "",
    errorType: "",
    isHitApi: false
}
function CreatePort(props) {
    const [state, setState] = useState(portInitialState);
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.getAllCountry();
                let data = { ...state };
                data.isHitApi = true;
                setState(data);
            }
        }
        if (props.apiCallStatus.apiCallFor === "getAllCountry" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.commonReducer.countryList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].name });
            }
            setState({
                ...state,
                countryOption: newArr,
                isHitApi: true
            })
            props.ApiClearAction();
        }
        if ((props.apiCallStatus.apiCallFor === "createPort") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(portInitialState)
            props.ApiClearAction();
            Swal.fire("Success", `The port has been created successfully.`, "success")
            if (props.isSubAdmin && !isAllowPermission(getRoutePathBykey('portList'), props.roles)) {
                props.history.push(getDashboardLink())
            } else {
                props.history.push('/port-listing')
            }
        }
    });
    const handleFormChange = (e) => {
        if (e.label !== undefined) {
            let countryDetail = { ...state }
            countryDetail['selectedOption'] = e
            countryDetail['country'] = e.value
            countryDetail['countryName'] = e.label

            setState(
                countryDetail
            )
        } else {
            let name = e.target.name;
            let value = e.target.value;
            let countryDetail = { ...state }
            countryDetail[name] = value;
            setState(countryDetail)
        }

    }
    const createHandler = () => {
        const { code, country, countryName, continent, continent2, region, port, portType, latitude, longitude } = state;
        if (country === undefined || country === null || country === '') {
            Swal.fire("Error", "Please select country", "error");
        } else {
            let obj = {
                code,
                country,
                continent,
                countryName,
                continent2,
                region,
                port,
                portType,
                latitude,
                longitude
            }
            setState({
                ...state,
                errorMessage: "",
                errorType: "",
            })
            props.createPort(obj)
        }

    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Create Port" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />

            <div className="formBox mt-4">
                <PortForm {...state} history={props.history} handleFormChange={handleFormChange} createHandler={createHandler} apiCallStatus={props.apiCallStatus} />
            </div>
        </Wrapper>
    )
}

function PortForm(props) {
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            if (props.isEdit) {
                props.updateHandler();
            } else {
                props.createHandler();
            }
        }
    }
    return (
        <form className="needs-validation floating-form" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="code" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Code"
                            value={props.code}
                            required />
                        <lable className="foating-labels-sty">Code</lable>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Country"
                                value={props.selectedOption}
                                onChange={(e) => { props.handleFormChange(e) }}
                                options={props.countryOption}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="continent" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Continent"
                            value={props.continent}
                            required />
                        <lable className="foating-labels-sty">Continent</lable>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="continent2" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Continent 2"
                            value={props.continent2}
                            required />
                        <lable className="foating-labels-sty">Continent 2</lable>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="region" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Region"
                            value={props.region}
                            required />
                        <lable className="foating-labels-sty">Region</lable>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="port" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Port"
                            value={props.port}
                            required />
                        <lable className="foating-labels-sty">Port</lable>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="portType" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Port Type"
                            value={props.portType}
                            required />
                        <lable className="foating-labels-sty">Port Type</lable>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="number" min={0} step="any" name="latitude" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Latitude"
                            value={props.latitude}
                            required />
                        <lable className="foating-labels-sty">Latitude</lable>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="number" min={0} step="any" name="longitude" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Longitude"
                            value={props.longitude}
                            required />
                        <lable className="foating-labels-sty">Longitude</lable>
                    </div>
                </div>
            </div>
            {props.apiCallStatus.apiCallFor === "createCountry" && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                <div className="alert alert-danger">
                    {props.apiCallStatus.message}
                </div>
                : ""}
            {props.errorMessage !== "" && props.errorType !== "" ?
                <div className="alert alert-danger">
                    {props.errorMessage}
                </div>
                : ""}
            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {props.apiCallStatus.apiCallFor === "createCountry" && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">{props.isEdit ? "Update" : "Submit"}</button>
                </div>
            </div>
        </form>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    portReducer: state.portReducer,
    roles: state.authReducer.roles,
    countryReducer: state.countryReducer,
    commonReducer: state.commonReducer,
    isSubAdmin: state.authReducer.isSubAdmin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createPort: (data) => dispatch(createPort(data)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    getAllCountry: () => dispatch(getAllCountry())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreatePort);
