import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import {
  GetQuotationAction,
  AppendQuotationAction,
  getQuotationDetailAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
import { getCookie } from "../../../Services/cookies";

export function quotationListing(offset, limit, type, isDetail) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(
        Actions.ApiRequestedAction({ apiCallFor: "appendQuotationList" })
      );
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getQuotation" }));
      dispatch(GetQuotationAction([], 0));
    }
    let url =
      Constant.apiURl + "/quotation/" + offset + `/${limit}?type=${type}`;
    if (getCookie("userType") === "supplier") {
      url += `&supplierId=${getCookie("userId")}`;
    } else if (getCookie("subType") === "captain") {
      url += `&userId=${getCookie("userId")}`;
    }
    if (isDetail === true) {
      url += "&isDetail=true";
    } else if (getCookie("subType") === "customer") {
      let arr = JSON.stringify(isDetail);
      url += `&isDetail=true&vesselId=${arr}`;
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendQuotationAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendQuotationList",
            message: "",
          })
        );
      } else {
        dispatch(GetQuotationAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getQuotation",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendCountryList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getCountry",
            message: myJson.message,
          })
        );
      }
    }
  };
}
export function getQuotationDetail(quotationId, supplierId) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getQuotationDetail" }));
    let myJson = await FETCH("POST", Constant.apiURl + "/quotation/items", {
      supplierId: supplierId,
      quotationId: quotationId,
    });
    if (myJson && myJson.code === 201) {
      dispatch(
        getQuotationDetailAction(
          myJson.data,
          myJson.basketId,
          myJson.orderDetail
        )
      );
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getQuotationDetail",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getQuotationDetail",
          message: myJson.message,
        })
      );
    }
  };
}

export function compareQuotationList(orderId) {
  return async (dispatch) => {
    dispatch(
      Actions.ApiRequestedAction({ apiCallFor: "compareQuotationList" })
    );
    let myJson = await FETCH("POST", Constant.apiURl + "/quotation/compare", {
      orderId,
    });
    if (myJson && myJson.code === 201) {
      let data = myJson.data ? myJson.data.sort(compareSupplier) : [];
      // let arr = [];
      let orderDetail = myJson.orderDetail;
      if (orderDetail.itemDetail && orderDetail.itemDetail.length > 0) {
        orderDetail.itemDetail = toObject(orderDetail.itemDetail);
      }
      // for (let index = 0; index < 3; index++) {
      //   arr.push(myJson.data[0])
      // }
      dispatch(getQuotationDetailAction(data, orderId, orderDetail));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "compareQuotationList",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "compareQuotationList",
          message: myJson.message,
        })
      );
    }
  };
}

export function sendQuotation(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "sendQuotation" }));
    let myJson = await FETCH("PUT", Constant.apiURl + "/quotation", data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "sendQuotation",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "sendQuotation",
          message: myJson.message,
        })
      );
    }
  };
}

function compareSupplier(a, b) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.supplier.title.toUpperCase();
  const nameB = b.supplier.title.toUpperCase();
  // const nameA = a.supplier.name.toUpperCase();
  // const nameB = b.supplier.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

function toObject(arr) {
  var rv = {};
  for (var i = 0; i < arr.length; ++i) {
    let itemCode = arr[i].itemCode;
    rv[itemCode] = arr[i];
  }
  return rv;
}
