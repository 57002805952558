import styled from "styled-components";

export const Wrapper = styled.div`
position: absolute;
background-color: transparent;
left: 0;top: 0;width:100%;height: 100%;z-index: 9;
&:after{content: '';position: absolute;left: 0;top: 0;width:100%;height: 100%;margin: auto;    background-color: #ffffffd1;filter: blur(15px);z-index: -1;}

.mesh-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.mesh-spinner svg tspan {
    animation: meshLoader 2s infinite forwards;
    text-shadow: 2px 1.5px 1px #00000012;
}
.mesh-spinner svg tspan:nth-child(1) {animation-delay: 0s;}
.mesh-spinner svg tspan:nth-child(2) {animation-delay: 0.5s;}
.mesh-spinner svg tspan:nth-child(3) {animation-delay: 1s;}
.mesh-spinner svg tspan:nth-child(4) {animation-delay: 1.5s;}

@keyframes meshLoader {
    0% {
        stroke-dasharray: 89;
        stroke-dashoffset: 89;
    }
    100%{
        stroke-dashoffset: 0;
    }
}
`