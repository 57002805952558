import React, { useEffect, useState } from 'react';
import { Wrapper, DashboardNavbar } from '../../../Common/Components/index';
import { getDashboardLink, isAllowPermission } from '../../../../Services/common';
import { getAllPort } from '../../../Common/ApiCalls/portList';
import { getAllCountry } from "../../../Common/ApiCalls/countryList";
import { getAllDepartment } from '../../../Common/ApiCalls/departmentList';
import { getCurrencyCode } from '../../../Common/ApiCalls/countryList';
import { getAllCaptain } from "../../../Common/ApiCalls/captainList";
import { getAllCook } from "../../../Common/ApiCalls/cookListing";
import { getCrewRotationListing } from "../../../Common/ApiCalls/crewRotation";
import { getVesselKind } from "../../../Common/ApiCalls/vesselList";
import { ApiClearAction, ClearApiByNameAction } from "../../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import { createVessel, editVessel, getVesselDetail } from "../../ApiCalls/vessel";
import { supplierListing } from "../../../Supplier/ApiCalls/supplier";
import Swal from 'sweetalert2';
import cloneDeep from "lodash/cloneDeep";
import { QuestionnarieForm } from './questionnarieForm';
import $ from 'jquery';
import Loader from "../../../Common/Components/Loader";
import { getCookie } from '../../../../Services/cookies';
var { category } = require('./../../../Orders/Components/Catalogue/config.json');
var { catalogue } = require('./../../catalog-json.json');

const getCatOptions = () => {
    let arr = [];
    for (let i = 1; i <= 100; i++) {
        arr.push({
            label: i + '%', value: i
        })
    }
    return arr;
}
const questionnarieInitialState = {
    basicInfo: {
        user: { label: "", value: "" },
        name: '',
        alternativeName: '',
        imo: '',
        build: '',
        tdw: '',
        kindOfVessel: null,
        gear: null,
        pic: '',
        currency: null,
        cook: [],
        captain: []
    },
    crewInformation: {
        noOfCrew: '',
        rate: '',
        crewMix: []
    },
    regionsInformation: {
        rotation: []
    },
    mainSupplyPorts: {
        supplyPorts: [],
        country: ""
    },
    budgetPerDepartment: {},
    invoiceInformation: {
        invoiceName: '',
        invoiceStreet: '',
        invoiceCity: '',
        vatNo: '',
        invoiceRef: '',
    },
    configInformation: {
        categories: category,
        categoryTotal: 0,
        totalAvgValuePerCat: 0,

        rateLimitFixed: true,
        rateTolerance: '0',
        maxQuantityLimitFixed: true,
        maxQuantityTolerance: '0',
        categoyLimitFixed: true,
        maxCategoryTolerance: '0',
        minimumFixed: true,
        sufficientProvisionsMinimum: '0',
        allowDurableProvisionsOrder: false,
        fixedMainSupplyPorts: false,
    },
    currencyOption: [],
    departmentList: [],
    gearOption: [
        { label: 'gear', value: 'gear' },
        { label: 'non-gear', value: 'non-gear' },
    ],
    captainOption: [],
    cookOption: [],
    limitOption: [{
        label: 'Yes', value: true
    }, {
        label: 'No', value: false
    }],
    toleranceOption: getCatOptions(),
    catToleranceOption: [],
    provisionOption: getCatOptions(),
    vesselKindOption: [],
    CrewNationalitiesOptions: [],
    rotationRegionsOptions: [],
    portsOptions: [],
    countryList: [],
    activeCustomerPage: 0,
    customerList: [],
    searchCustomer: '',
    vesselId: '',
    isError: false,
    errorMessage: '',
    isHitApi: false,
    activeTab: 1
}

function CalculateCatTotal(categories) {
    let categoryTotal = 0;
    let totalRefQty = 0;
    let avgPricePerKg = 0;
    let totalAvgValuePerCat = 0;
    let totalProValOfContractedRateQty = 0;
    let totalProportionTotalQty = 0;
    let totalProportionTotalValue = 0;
    let totalPricePerKGOptimumPerCat = 0;
    let totalItemsPerOptimumPerCat = 0;
    let totalNoOfItemInOptimumRange = 0;
    let totalProValOfRateByValperCat = 0;
    let totalPricePerKgOptimumPerItemCat = 0;
    let durable = 0;
    let fresh = 0;
    let totalNumOfItemInOptimumPerCat = 0;
    let totalDiff = 0;
    categories.forEach(element => {
        let qtyPerDay = parseFloat(element.qtyPerDay);
        categoryTotal += isNaN(qtyPerDay) ? 0 : qtyPerDay;
        totalRefQty += parseFloat(element['Reference-Qty']);
        totalDiff += parseFloat(element['Difference'])
        avgPricePerKg += parseFloat(element["Avg-price-per-KG"])
        totalPricePerKGOptimumPerCat += parseFloat(element['Price-per-KG-optimum-per-item-category']);
        totalItemsPerOptimumPerCat += parseFloat(element["Number-of-items-in-optimum-range-per-category"]);
        totalProportionTotalQty += parseFloat(element['Proportion-of-total-Qty'])
        totalAvgValuePerCat += parseFloat(element["Avg-Value-per-category"]);
        totalProportionTotalValue += parseFloat(element["Proportion-of-Total-Value"]);
        totalProValOfContractedRateQty += parseFloat(element['Proportional-Value-of-contracted-rate-by-qty'])
        totalProValOfRateByValperCat += parseFloat(element['Proportional-Value-of-Rate-by-value-per-category'])
        totalPricePerKgOptimumPerItemCat += parseFloat(element['Price-per-KG-optimum-per-item-category']);
        totalNoOfItemInOptimumRange += parseFloat(element["Number-of-items-in-optimum-range"]);
        totalNumOfItemInOptimumPerCat += parseFloat(element["Number-of-items-in-optimum-range-per-category"])
        if (element.type === "main") {
            durable += parseFloat(element['Proportional-Value-of-contracted-rate-by-qty'])
        } else {
            fresh += parseFloat(element['Proportional-Value-of-contracted-rate-by-qty'])
        }
    });
    if (totalProportionTotalQty > 98) {
        totalProportionTotalQty = 100
    }
    totalDiff = parseFloat((totalDiff / 1000).toFixed(2));
    totalRefQty = parseFloat((totalRefQty / 1000).toFixed(2));
    return {
        categoryTotal: parseFloat((categoryTotal / 1000).toFixed(2)),
        totalItemsPerOptimumPerCat: totalItemsPerOptimumPerCat.toFixed(2),
        totalDiff: totalDiff,
        totalDiffPercentage: parseFloat(((totalDiff / totalRefQty) * 100).toFixed(2)),
        avgPricePerKg: (avgPricePerKg / categories.length).toFixed(2),
        avgPricePerKGOptimumPerCat: (totalPricePerKGOptimumPerCat / categories.length).toFixed(2), totalPricePerKGOptimumPerCat: totalPricePerKGOptimumPerCat.toFixed(2),
        totalRefQty: totalRefQty,
        totalProportionTotalQty: totalProportionTotalQty.toFixed(2),
        totalAvgValuePerCat: parseFloat(totalAvgValuePerCat.toFixed(2)),
        totalProportionTotalValue: parseFloat(totalProportionTotalValue.toFixed(2)),
        totalProValOfContractedRateQty: parseFloat(totalProValOfContractedRateQty.toFixed(2)),
        totalNumOfItemInOptimumPerCat: totalNumOfItemInOptimumPerCat.toFixed(2),
        totalPricePerKgOptimumPerItemCat: (totalPricePerKgOptimumPerItemCat / categories.length).toFixed(2),
        totalProValOfRateByValperCat: totalProValOfRateByValperCat.toFixed(2),
        durable: durable.toFixed(2), fresh: fresh.toFixed(2),
        totalNoOfItemInOptimumRange: totalNoOfItemInOptimumRange.toFixed(2)
    };
}

function CreateQuestionnarie(props) {
    const [state, setState] = useState(cloneDeep(questionnarieInitialState));
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                if (props.match.params.id) {
                    $('#pic').removeAttr('required');
                    props.getVesselDetail(props.match.params.id);
                }
                // props.getAllPort();
                props.getAllCountry('');
                props.getAllDepartment();
                // props.getAllCaptain(0, 0, 'captain');
                props.getVesselKind()
                // props.getAllCook(0, 0, 'cook');
                props.getCurrencyCode();
                props.getCrewRotationListing()
                props.customerListing(state.activeCustomerPage, 'customer', '', 0);
                let data = cloneDeep(questionnarieInitialState);
                let { categoryTotal, totalAvgValuePerCat } = CalculateCatTotal(category);
                data.configInformation["categoryTotal"] = categoryTotal;
                data.configInformation["totalAvgValuePerCat"] = totalAvgValuePerCat;
                data.isHitApi = true;
                setState(data);
            }
        }
        if (state.activeTab === 1 && props.match.params.id && $('#pic').attr("required")) {
            $('#pic').removeAttr('required');
        }
        if ((props.apiCallStatus.apiCallFor === "getVesselDetail") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            updateQuestionaryState(props.vesselReducer.detail);
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }

        if (props.apiCallStatus.apiCallFor === "getAllPort" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.commonReducer.portList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].port });
            }
            setState({
                ...state,
                portsOptions: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if ((props.apiCallStatus.apiCallFor === "getAllCountry") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let countryList = [];
            for (let index = 0; index < props.commonReducer.countryList.length; index++) {
                let val = props.commonReducer.countryList[index];
                countryList.push({ label: val.name, value: val._id, countryCode: val.countryCode })
            }
            setState({
                ...state,
                countryList: countryList,
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "getVesselKind" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.commonReducer.vesselKindList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].name });
            }
            setState({
                ...state,
                vesselKindOption: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction("getVesselKind")
        }
        if (props.apiCallStatus.apiCallFor === "getCrewRotationListing" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.commonReducer.crewList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i].name, label: arr[i].name });
            }
            setState({
                ...state,
                CrewNationalitiesOptions: newArr,
                rotationRegionsOptions: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction("getCrewRotationListing")
        }
        if (props.apiCallStatus.apiCallFor === "getAllDepartment" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let data = { ...state };
            let obj = data['budgetPerDepartment'];
            for (let index = 0; index < props.commonReducer.departmentList.length; index++) {
                let depElem = props.commonReducer.departmentList[index];
                let depName = depElem.name;
                if (obj[depName] === undefined) {
                    obj[depName] = {
                        "department": depName,
                        "budget": "",
                        "id": depElem._id
                    };
                }
            }
            setState({
                ...state,
                departmentList: props.commonReducer.departmentList,
                budgetPerDepartment: obj,
                isHitApi: true
            })
            props.ClearApiByNameAction("getAllDepartment")
        }
        if ((props.apiCallStatus.apiCallFor === "getAllCook") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let arr = props.commonReducer.cookList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].name });
            }
            setState({
                ...state,
                cookOption: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getAllCaptain") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let arr = props.commonReducer.captainList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].name });
            }
            setState({
                ...state,
                captainOption: newArr,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getCurrencyCode") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let topCurrency = [];
            let finalArr = [];
            let arr = props.commonReducer.currencyCodeList;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === "EUR" || arr[i] === "USD" || arr[i] === "CAD") {
                    topCurrency.push({ value: arr[i], label: arr[i] })
                } else {
                    newArr.push({ value: arr[i], label: arr[i] });

                }
            }
            finalArr = topCurrency.concat(newArr)
            setState({
                ...state,
                currencyOption: finalArr,
                isHitApi: true,
            })
            props.ClearApiByNameAction("getCurrencyCode");
        }
        if ((props.apiCallStatus.apiCallFor === "createVessel" || props.apiCallStatus.apiCallFor === "editVessel") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let result = props.vesselReducer.questionaire;
            let updatedState = {
                ...state,
                vesselId: result._id,
                isHitApi: true,
                activeTab: (state.activeTab + 1),
            }
            if (state.activeTab === 2 && props.apiCallStatus.apiCallFor === "editVessel") {
                let configInformation = { ...state.configInformation }
                let categories = props.vesselReducer.detail.categories && props.vesselReducer.detail.categories.length > 0 ? props.vesselReducer.detail.categories : state.configInformation.categories;
                configInformation.categories = updateConfigCat(categories, parseFloat(state.crewInformation.rate));
                let { categoryTotal, totalAvgValuePerCat } = CalculateCatTotal(configInformation.categories);
                configInformation.categoryTotal = categoryTotal;
                configInformation.totalAvgValuePerCat = totalAvgValuePerCat;
                updatedState.configInformation = configInformation;
            }
            setState(updatedState)
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "createVessel" || props.apiCallStatus.apiCallFor === "editVessel") && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed) {
            Swal.fire("Error!", props.apiCallStatus.message, "error");
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "supplierListing" || props.apiCallStatus.apiCallFor === "appendSupplierList") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let newArr = [];
            let arr = props.customerList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i].id, label: arr[i].email });
            }
            setState({
                ...state,
                customerList: newArr,
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
    })
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "go back",
            path: "/vessel-listing",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
        {
            img: 'Monitor-icon.svg',
            className: "dark-blue",
            name: "Dash</br>board",
            path: getDashboardLink(),
            isLogout: false
        }
    ];
    const updateQuestionaryState = (detail, vesselRate) => {
        let basicInfo = {
            user: detail.user,
            customerName: detail.customerName,
            numberOfVessel: detail.numberOfVessel,
            name: detail.name,
            alternativeName: detail.alternativeName,
            imo: detail.imo,
            build: detail.build,
            tdw: detail.tdw,
            kindOfVessel: typeof detail.kindOfVessel == 'string' ? { label: detail.kindOfVessel, value: detail.kindOfVessel } : detail.kindOfVessel,
            pob: detail.pob,
            owner: detail.owner,
            currency: { value: detail.currency, label: detail.currency },
            gear: { value: detail.gear, label: detail.gear },
            pic: detail.pic,
            cook: detail.cook,
            captain: detail.captain
        }
        let crewMix = [];
        detail.crewMix && detail.crewMix.map((item, index) => {
            crewMix.push({ label: item, value: item })
        })
        let crewInformation = {
            noOfCrew: detail.noOfCrew,
            rate: vesselRate ? vesselRate : detail.rate,
            crewMix: crewMix
        }
        let rotation = [];
        detail.rotation && detail.rotation.map((item, index) => {
            rotation.push({ label: item, value: item })
        })
        let regionsInformation = {
            rotation: rotation
        }
        let supplyPorts = [];
        detail.supplyPorts && detail.supplyPorts.map((item, index) => {
            supplyPorts.push({ label: item, value: item })
        })
        let mainSupplyPorts = {
            supplyPorts: supplyPorts,
        }
        let budgetPerDepartment = cloneDeep(state.budgetPerDepartment);
        if (detail.departments && detail.departments.length > 0) {
            detail.departments.forEach(function (item) {
                budgetPerDepartment[item.department] = item;
            });
        }
        let invoiceInformation = {
            invoiceName: detail.invoiceName,
            invoiceStreet: detail.invoiceStreet,
            invoiceCity: detail.invoiceCity,
            vatNo: detail.vatNo,
            invoiceRef: detail.invoiceRef,
        }
        let categories = detail.categories && detail.categories.length > 0 ? detail.categories : state.configInformation.categories;
        let { categoryTotal, totalAvgValuePerCat } = CalculateCatTotal(categories);
        let configInformation = {
            categories: updateConfigCat(categories, vesselRate ? parseFloat(vesselRate) : parseFloat(detail.rate)),
            categoryTotal: categoryTotal,
            totalAvgValuePerCat: totalAvgValuePerCat,
            rateLimitFixed: detail.rateLimitFixed,
            rateTolerance: detail.rateTolerance,
            maxQuantityLimitFixed: detail.maxQuantityLimitFixed,
            maxQuantityTolerance: detail.maxQuantityTolerance,
            categoyLimitFixed: detail.categoyLimitFixed,
            maxCategoryTolerance: detail.maxCategoryTolerance,
            minimumFixed: detail.minimumFixed,
            sufficientProvisionsMinimum: detail.sufficientProvisionsMinimum,
            allowDurableProvisionsOrder: detail.allowDurableProvisionsOrder,
            fixedMainSupplyPorts: detail.fixedMainSupplyPorts,
        }
        let index = state.toleranceOption.findIndex(x => x.value === detail.maxCategoryTolerance);
        let catToleranceOption = state.toleranceOption.slice(0, index + 1);
        setState({
            ...state,
            basicInfo,
            crewInformation,
            regionsInformation,
            mainSupplyPorts,
            invoiceInformation,
            budgetPerDepartment,
            configInformation,
            catToleranceOption
        })
    }
    const changeHandler = (e, formName, fieldName, handlerName) => {
        let data = cloneDeep(state);
        if (typeof e === "string" && formName !== "configInformation") {
            data["activeCustomerPage"] = 0;
            if (typeof formName === "string") {
                data[e] = formName;
                let query = "";
                if (formName !== "") {
                    query = "email=" + formName
                }
                props.customerListing(data.activeCustomerPage, 'customer', query, 0);
            } else {
                data['basicInfo']["user"] = formName;
            }
        } else if (fieldName && formName === "configInformation") {
            if (handlerName === "selectHandler") {
                data[formName][fieldName] = e;
                if (fieldName === "maxQuantityTolerance") {
                    let index = state.toleranceOption.findIndex(x => x.value === e);
                    data.catToleranceOption = state.toleranceOption.slice(0, index + 1);
                    data.configInformation.maxCategoryTolerance = null;
                }
            } else {
                let value = e.target.value;
                data[formName]["categories"][handlerName][fieldName] = value;
                if (fieldName === "qtyPerDay") {
                    let avgValuePerCat = parseFloat(data[formName]["categories"][handlerName]['Avg-price-per-KG']) * (value / 1000);
                    data[formName]["categories"][handlerName]['Avg-Value-per-category'] = avgValuePerCat;
                    updateConfigCat(data[formName]["categories"], parseFloat(state.crewInformation.rate))
                }
            }

        } else if (formName === "budgetPerDepartment") {
            let name = e.target.name;
            let value = e.target.value;
            data[formName][name]["budget"] = value;
        } else {
            let name = fieldName === undefined ? e.target.name : '';
            let value = fieldName === undefined ? (e.target.type === 'file' && e.target.files.length > 0) ? e.target.files : e.target.value : e;
            if (name === 'imo' && (value.charAt(0) !== '9' && value !== '')) {
                return false
            }
            if (handlerName !== undefined) {
                if (fieldName === 'country') {
                    data[formName]['country'] = value;
                    data["portsOptions"] = [];
                    let query = "countryCode=" + value.countryCode;
                    props.getAllPort(query);
                } else if (fieldName === 'supplyPorts') {
                    let arr = data[formName]['supplyPorts'];
                    let index = arr.findIndex(x => x.value === value.value);
                    if (index === -1) {
                        arr.push(value);
                    }
                    data[formName]['supplyPorts'] = arr;
                } else if (fieldName === 'removePorts') {
                    let arr = data[formName]['supplyPorts'];
                    let index = arr.findIndex(x => x.value === value.value);
                    if (index !== -1) {
                        arr.splice(index, 1);
                    }
                    data[formName]['supplyPorts'] = arr;
                } else {
                    data[formName][fieldName] = value;
                }
            } else {
                data[formName][name] = name !== 'imo' ? value : (name === 'imo' && value.length < 8) ? value : data[formName][name];
            }
        }
        setState(data);
    }
    const formValidation = (phaseName) => {
        let isValid = true;
        let msg = "";
        if (phaseName === 'basicInfo') {
            if (state.basicInfo.imo.length < 7) {
                isValid = false;
                msg = "IMO No. should be 7 figure value";
            } else if (state.basicInfo.kindOfVessel === null) {
                isValid = false;
                msg = "Please select vessel kind";
            } else if (state.basicInfo.gear === null) {
                isValid = false;
                msg = "Please select gear";
            } else if (state.basicInfo.currency === null) {
                isValid = false;
                msg = "Please select currency";
            }
            //  else if (state.basicInfo.cook.length <= 0 && props.userType === "user") {
            //     isValid = false;
            //     msg = "Please assign cook to this vessel";
            // } else if (state.basicInfo.captain.length <= 0 && props.userType === "user") {
            //     isValid = false;
            //     msg = "Please assign captain to this vessel";
            // } 
            else if (state.basicInfo.user.value === "" && props.userType === "user") {
                isValid = false;
                msg = "Please assign customer to this vessel";
            }
        } else if (phaseName === 'crewInformation') {
            if (state.crewInformation.crewMix.length <= 0) {
                isValid = false;
                msg = "Please select crew nationalities";
            }
        } else if (phaseName === "regionsInformation") {
            if (state.regionsInformation.rotation.length <= 0) {
                isValid = false;
                msg = "Please select regions";
            }
        } else if (phaseName === "mainSupplyPorts") {
            if (state.mainSupplyPorts.supplyPorts.length <= 0) {
                isValid = false;
                msg = "Please select ports";
            }
        }

        if (!isValid) {
            Swal.fire("Error", msg, "error")
        }
        return isValid;
    }
    const fetchMoreCustomerData = () => {
        let data = { ...state };
        data.activeCustomerPage += 1;
        setState(data);
        let query = "";
        if (state.searchCustomer !== "") {
            query = "?email=" + state.searchCustomer;
        }
        props.customerListing(state.activeCustomerPage, 'customer', query, 0);
    };
    const validateForm = (phaseName) => {
        let formClass = phaseName === 'basicInfo' ? 'needs-validation-basic' : phaseName === 'crewInformation' ? 'needs-validation-crew' : phaseName === 'budgetPerDepartment' ? 'needs-validation-department' : phaseName === 'invoiceInformation' ? 'needs-validation-invoice' : ''
        var form = document.getElementsByClassName(formClass)[0];
        let isValid = true;
        if (phaseName === 'regionsInformation' || phaseName === 'operationArea' || phaseName === 'mainSupplyPorts' || phaseName === 'configInformation') {

        }
        else if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const submitHandler = (e, phaseName, payload) => {
        e.preventDefault();
        let isValid = validateForm(phaseName);
        if (isValid) {
            if (formValidation(phaseName)) {
                let data = cloneDeep(state);
                let obj = data[phaseName];
                if (phaseName === 'basicInfo' && (Object.keys(obj).length > 0 && obj.kindOfVessel)) {
                    obj.kindOfVessel = obj.kindOfVessel
                    obj.gear = obj.gear.label;
                    obj.currency = obj.currency.label
                    obj.userId = props.userType === "user" ? state.basicInfo.user.value : props.userId;
                    if (props.userType === "user") {
                        obj.userEmail = state.basicInfo.user.label;
                    } else if (getCookie("email") !== "") {
                        obj.userEmail = getCookie("email");
                    }
                    delete obj.cook;
                    delete obj.captain;
                } else if (phaseName === 'crewInformation' && (Object.keys(obj).length > 0 && obj.crewMix.length > 0)) {
                    let newArray = [];
                    obj.crewMix.map((item, index) => {
                        newArray.push(item.value)
                    })
                    obj.crewMix = newArray
                    obj.userId = props.userType === "user" ? state.basicInfo.user.value : props.userId
                }
                else if (phaseName === 'regionsInformation' && (Object.keys(obj).length > 0 && obj.rotation.length > 0)) {
                    let newArray = [];
                    obj.rotation.map((item, index) => {
                        newArray.push(item.value)
                    })
                    obj.rotation = newArray
                    obj.userId = props.userType === "user" ? state.basicInfo.user.value : props.userId
                } else if (phaseName === 'mainSupplyPorts' && (Object.keys(obj).length > 0 && obj.supplyPorts.length > 0)) {
                    let newArray = [];
                    obj.supplyPorts.map((item, index) => {
                        newArray.push(item.label)
                    })
                    obj.supplyPorts = newArray
                    obj.userId = props.userType === "user" ? state.basicInfo.user.value : props.userId;
                    if (state.mainSupplyPorts.country && state.mainSupplyPorts.country.label) {
                        obj.countryName = state.mainSupplyPorts.country.label
                    }
                } else if (phaseName === 'budgetPerDepartment' && (Object.keys(obj).length > 0)) {
                    var result = Object.keys(obj).map((key) => {
                        let item = obj[key];
                        if (item.id === undefined) {
                            let index = state.departmentList.findIndex(x => x.name === key);
                            item.id = index !== -1 ? state.departmentList[index]._id : "";
                        }
                        return item;
                    });
                    obj = { departments: result }
                    obj.userId = props.userType === "user" ? state.basicInfo.user.value : props.userId
                } else if (phaseName === 'invoiceInformation' && (Object.keys(obj).length > 0)) {
                    obj.userId = props.userType === "user" ? state.basicInfo.user.value : props.userId
                } else if (phaseName === 'configInformation' && (Object.keys(obj).length > 0)) {
                    obj.rateLimitFixed = obj.rateLimitFixed;
                    obj.rateTolerance = obj.rateTolerance;
                    obj.maxQuantityLimitFixed = obj.maxQuantityLimitFixed;
                    obj.maxQuantityTolerance = obj.maxQuantityTolerance;
                    obj.categoyLimitFixed = obj.categoyLimitFixed;
                    obj.maxCategoryTolerance = obj.maxCategoryTolerance;
                    obj.minimumFixed = obj.minimumFixed;
                    obj.sufficientProvisionsMinimum = obj.sufficientProvisionsMinimum;
                    obj.allowDurableProvisionsOrder = obj.allowDurableProvisionsOrder;
                    obj.fixedMainSupplyPorts = obj.fixedMainSupplyPorts;
                    obj.active = true;
                    obj.userId = props.userType === "user" ? state.basicInfo.user.value : props.userId;
                    obj.durableRatePercent = payload.durablePercentage;
                    obj.freshRatePercent = payload.freshPercentage;
                    obj.durableRate = payload.durable;
                    obj.freshRate = payload.fresh;
                }
                let vesselId = state.vesselId;
                let questionaire = phaseName !== 'basicInfo' ? "questionaire" : ""
                if (props.match.params.id) {
                    vesselId = props.match.params.id;
                }
                if (vesselId) {
                    props.editVessel(vesselId, obj, questionaire)
                } else {
                    props.createVessel(obj, questionaire)
                }
            }
        }
    }
    const handleTabChange = (val, isAllow) => {
        if (isAllow) {
            setState({
                ...state,
                activeTab: val
            })
        }

    }
    const updateConfigCat = (categories, rate) => {
        categories.forEach((element, index) => {
            let value = parseFloat(element.qtyPerDay);
            let totalPrice = 0
            let selectedCategory = catalogue.filter(function (item) {
                if (element["category"] === item.category) {
                    totalPrice += item.pricePerBaseUnit;
                    return true;
                }
                return false;
            });
            let avgPricePerKg = parseFloat((totalPrice / selectedCategory.length).toFixed(2));
            element['Avg-price-per-KG'] = avgPricePerKg;
            let avgValuePerCat = parseFloat((parseFloat(element['Avg-price-per-KG']) * (value / 1000)).toFixed(2));
            element['Avg-Value-per-category'] = avgValuePerCat;
            let { categoryTotal, totalAvgValuePerCat } = CalculateCatTotal(categories);
            let proportionTotalQty = parseFloat((parseFloat(value) / 10 / categoryTotal).toFixed(2));
            if (isNaN(proportionTotalQty)) {
                proportionTotalQty = 0;
            }
            let proportionOfTotalValue = parseFloat(((avgValuePerCat / totalAvgValuePerCat) * 100).toFixed(0));
            let proValOfContractedRateQty = ((parseFloat(rate) * proportionTotalQty) / 100).toFixed(2);
            let pricePerKgOptimum = (proValOfContractedRateQty / (value / 1000)).toFixed(2);
            if (isNaN(pricePerKgOptimum)) {
                pricePerKgOptimum = 0;
            }
            let referenceQty = element["Reference-Qty"];
            let difference = value - referenceQty;
            let diffPercentage = ((difference / referenceQty) * 100).toFixed(0);
            element['Difference'] = parseFloat(difference.toFixed(2));
            element['Difference%'] = diffPercentage;
            element['Proportion-of-total-Qty'] = proportionTotalQty;
            element['Proportion-of-Total-Value'] = proportionOfTotalValue;
            element['Proportional-Value-of-contracted-rate-by-qty'] = proValOfContractedRateQty;
            element['Price-per-KG-optimum'] = pricePerKgOptimum;
            categories[index] = element;
        })
        let { totalProValOfContractedRateQty } = CalculateCatTotal(categories);
        categories.forEach((element, index) => {
            let selectedCategory = catalogue.filter(function (item) {
                if (element["category"] === item.category) {
                    return true;
                }
                return false;
            });
            let pricePerKgOptimum = element['Price-per-KG-optimum'];
            let value = parseFloat(element.qtyPerDay);
            let proportionOfTotalValue = parseFloat(element['Proportion-of-Total-Value'].toFixed(0));
            let proValOfRateByCat = parseFloat(((proportionOfTotalValue * totalProValOfContractedRateQty) / 100).toFixed(2));
            let pricePerKgOptimumPerItemCat = parseFloat(((proValOfRateByCat / value) * 1000).toFixed(2));
            if (isNaN(pricePerKgOptimumPerItemCat)) {
                pricePerKgOptimumPerItemCat = 0;
            }
            element['Proportional-Value-of-Rate-by-value-per-category'] = proValOfRateByCat;
            element['Price-per-KG-optimum-per-item-category'] = pricePerKgOptimumPerItemCat;
            let numOfItemInOptimumPerCat = selectedCategory.filter(function (item) {
                if (item.pricePerBaseUnit <= pricePerKgOptimumPerItemCat) {
                    return true;
                }
                return false;
            });
            let numOfItemInOptimum = selectedCategory.filter(function (item) {
                if (item.pricePerBaseUnit <= pricePerKgOptimum) {
                    return true;
                }
                return false;
            });
            element["Number-of-items-in-optimum-range"] = numOfItemInOptimum.length;
            element["Number-of-items-in-optimum-range-per-category"] = numOfItemInOptimumPerCat.length;
            categories[index] = element;
        });
        return categories;
    }
    return (
        <Wrapper>
            <DashboardNavbar title="Mesh Questionnarie" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="setting-wrapper bg-transparent">
                <div className="setting-wizard-wrap">
                    <div className="card">
                        <div className="wizard-inn-wrap formBox">
                            <form id="msform" className="wizardSteps-wrap">
                                <ul id="progressbar" className="wizard-progressbar">
                                    <li className={state.activeTab === 1 ? "active" : ""} onClick={() => handleTabChange(1, props.match.params.id ? true : false)} id="General">General</li>
                                    <li id="Crew" className={state.activeTab === 2 ? "active" : ""} onClick={() => handleTabChange(2, props.match.params.id ? true : false)}>Crew</li>
                                    <li id="Operation" className={state.activeTab === 3 ? "active" : ""} onClick={() => handleTabChange(3, props.match.params.id ? true : false)}>Area</li>
                                    <li id="Ports" className={state.activeTab === 4 ? "active" : ""} onClick={() => handleTabChange(4, props.match.params.id ? true : false)}>Ports</li>
                                    <li id="Budget" className={state.activeTab === 5 ? "active" : ""} onClick={() => handleTabChange(5, props.match.params.id ? true : false)}>Budget</li>
                                    <li id="Invoice" className={state.activeTab === 6 ? "active" : ""} onClick={() => handleTabChange(6, props.match.params.id ? true : false)}>Invoice</li>
                                    <li id="Config" className={state.activeTab === 7 ? "active" : ""} onClick={() => handleTabChange(7, props.match.params.id ? true : false)}>Config</li>
                                </ul>
                                <div className="wizard-fieldset-wrap">
                                    {(props.apiCallStatus.apiCallFor === "createVessel" || props.apiCallStatus.apiCallFor === "editVessel") &&
                                        props.apiCallStatus.isCompleted === false &&
                                        props.apiCallStatus.isFailed === false ? <Loader /> : <QuestionnarieForm {...state} submitHandler={submitHandler} changeHandler={changeHandler} fetchMoreCustomerData={fetchMoreCustomerData} customerList={state.customerList} customerCount={props.customerCount} userType={props.userType} dashboardLink={getDashboardLink()} handleTabChange={handleTabChange} isLoading={(props.apiCallStatus.apiCallFor === "createVessel" || props.apiCallStatus.apiCallFor === "editVessel") &&
                                            props.apiCallStatus.isCompleted === false &&
                                            props.apiCallStatus.isFailed === false}
                                            isPortLoading={props.apiCallStatus.isStarted.indexOf('getAllPort') !== -1}
                                            totalCategoriesDetail={state.activeTab === 7 ? CalculateCatTotal(state.configInformation.categories) : {}}
                                            updateConfigCat={updateConfigCat} />}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    commonReducer: state.commonReducer,
    customerList: state.supplierReducer.list,
    customerCount: state.supplierReducer.count,
    userId: state.authReducer.userId,
    userType: state.authReducer.userType,
    vesselReducer: state.vesselReducer,
    roles: state.authReducer.roles
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getAllPort: (query) => dispatch(getAllPort(query)),
    getVesselKind: () => dispatch(getVesselKind()),
    getCurrencyCode: () => dispatch(getCurrencyCode()),
    getCrewRotationListing: () => dispatch(getCrewRotationListing()),
    getAllDepartment: () => dispatch(getAllDepartment()),
    getAllCaptain: (offset, limit, type) => dispatch(getAllCaptain(offset, limit, type)),
    getAllCook: (offset, limit, type) => dispatch(getAllCook(offset, limit, type)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    createVessel: (data, type) => dispatch(createVessel(data, type)),
    editVessel: (id, data, type) => dispatch(editVessel(id, data, type)),
    getVesselDetail: (vesselId) => dispatch(getVesselDetail(vesselId)),
    customerListing: (offset, type, filterQuery, limit) => dispatch(supplierListing(offset, type, filterQuery, limit)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    getAllCountry: (query) => dispatch(getAllCountry(query)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateQuestionnarie);