import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { vesselListing, deleteVessel } from "./../ApiCalls/vessel";
import { ApiClearAction, ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import { Wrapper, DashboardNavbar, CsvDownload } from '../../Common/Components/index';
import { topVessel, getAllVessel } from '../../Common/ApiCalls/index';
import { getCookie, setCookie } from '../../../Services/cookies';
import loader from '../../../assets/images/Spinner-1s-200px.gif';
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from 'sweetalert2'
import { isAllowPermission } from '../../../Services/common';
import { getRoutePathBykey } from "../../../Constants/routesConfig";
import moment from 'moment';
import { Filters } from './Filters';
const headers = [
    { label: 'IMO', key: 'imo' },
    { label: 'Name', key: 'name' },
    { label: 'Rate', key: 'rate' },
    { label: 'Number of Crew', key: 'noOfCrew' },
    { label: 'PIC', key: 'pic' },
    { label: 'Status', key: 'status' },
];
var csvLinkRef = React.createRef();
function VesselList(props) {
    const [state, setState] = useState({
        vesselList: [],
        vesselNameOptions: [],
        vesselTypeOptions: [],
        vesselCustomerOptions: [],
        activePage: 0,
        userType: props.userType === "supplier" ? 'supplier' : props.subType === "captain" ? 'captain' : props.subType === "customer" ? 'customer' : '',
        customerVessels: [],
        startDate: null,
        endDate: null,
        totalCount: 0,
        csvData: [],
        isCsVDownload: false,
        isHitApi: false,
        name: '',
        type: '',
        customerName: '',
        nextDeliveryDate: null,
    });
    const query = new URLSearchParams(props.location.search);
    let topVessel = query.get('topVessel');
    let fleetOverview = query.get('fleetOverview');
    let supplierId = query.get('supplierId')
    useEffect(() => {
        let data = { ...state };
        if (!state.isHitApi) {
            if ((props.isSubAdmin && props.userType === 'user' && props.subType !== 'sub-admin') || isAllowPermission(window.location.pathname, props.roles)) {
                props.getAllVessel();
                if (topVessel !== null) {
                    if (state.userType !== 'customer') {
                        let filterQuery = {};
                        if (supplierId) {
                            filterQuery['supplierId'] = supplierId
                        }
                        props.topVessel(props.userId, state.userType, '', '', filterQuery)
                    } else {
                        props.vesselListing(0, 50);
                    }
                } else {
                    props.vesselListing(0, 20);
                }
                data.isHitApi = true;
                setState(data);
            } else if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            }
        }
        if ((props.apiCallStatus.apiCallFor === "vesselListing" || props.apiCallStatus.apiCallFor === "appendVesselList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            if (state.isCsVDownload) {
                csvLinkRef.current.link.click()
            }
            let csvData = [];
            let customerVessels = [];
            for (let index = 0; index < props.vesselReducer.list.length; index++) {
                const element = props.vesselReducer.list[index];
                if (props.subType === 'customer' && props.vesselReducer.list.length > 0) {
                    const id = element._id;
                    customerVessels.push(id)
                }
                let csvObj = {
                    imo: element.imo,
                    name: element.name,
                    rate: element.rate,
                    noOfCrew: element.noOfCrew,
                    pic: element.pic,
                    status: element.active ? 'Active' : 'In-Active'
                }
                csvData.push(csvObj)
            }
            if (props.subType === 'customer' && props.vesselReducer.list.length > 0) {
                let filterQuery = {};
                if (supplierId) {
                    filterQuery['supplierId'] = supplierId
                }
                if (topVessel) {
                    props.topVessel(customerVessels, 'customer', '', '', filterQuery)
                }
            }
            setState({
                ...state,
                vesselList: props.vesselReducer.list,
                totalCount: props.vesselReducer.count,
                customerVessels,
                csvData,
                isCsVDownload: false,
                isHitApi: true
            })
        }
        if (props.apiCallStatus.apiCallFor === "topVessel" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                vesselList: props.commonReducer.topVesselList,
                totalCount: props.commonReducer.topVesselList.length,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
        if (props.apiCallStatus.apiCallFor === "deleteVessel" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let vesselCount = parseInt(getCookie("vesselCount")) - 1;
            setCookie("vesselCount", vesselCount);
            Swal.fire("Deleted!", "Vessel has been deleted!", "success");
            if (topVessel !== null) {
                let filterQuery = {};
                if (supplierId) {
                    filterQuery['supplierId'] = supplierId
                }
                if (state.userType !== 'customer') {
                    props.topVessel(props.userId, state.userType, '', '', filterQuery)
                } else {
                    props.topVessel(state.customerVessels, state.userType, '', '', filterQuery)
                }
            } else {
                props.vesselListing(0, 20);

            }

        }
        if (props.apiCallStatus.apiCallFor === "getAllVessel" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            let vesselNameOptions = [];
            let vesselTypeOptions = [];
            let vesselCustomerOptions = [];
            for (let index = 0; index < props.commonReducer.vesselList.length; index++) {
                let val = props.commonReducer.vesselList[index];
                vesselNameOptions.push({ label: val.name, value: val.name })
            }
            for (let index = 0; index < props.commonReducer.vesselList.length; index++) {
                let val = props.commonReducer.vesselList[index];
                let currentIndex = vesselTypeOptions.findIndex(x => x.label === val.kindOfVessel.label);
                if (currentIndex === -1) {
                    vesselTypeOptions.push({ label: val.kindOfVessel.label, value: val.kindOfVessel.label })
                }
            }
            for (let index = 0; index < props.commonReducer.vesselList.length; index++) {
                let val = props.commonReducer.vesselList[index];
                let userName = val.userId ? val.userId : '';
                let currentIndex = vesselCustomerOptions.findIndex(x => x.label === userName.email);
                if (currentIndex === -1) {
                    if (userName) {
                        vesselCustomerOptions.push({ label: userName.email, value: userName._id })
                    }
                }
            }
            setState({
                ...state,
                vesselNameOptions,
                vesselTypeOptions,
                vesselCustomerOptions,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
    }, [props]);
    const fetchMoreData = () => {
        let { activePage } = state;
        activePage += 1;
        setState({
            ...state,
            activePage
        })
        if (topVessel !== null) {
            let filterQuery = {};
            if (supplierId) {
                filterQuery['supplierId'] = supplierId
            }
            if (state.userType !== 'customer') {
                props.topVessel(props.userId, state.userType, '', '', filterQuery)
            } else {
                props.topVessel(state.customerVessels, state.userType, '', '', filterQuery)
            }
        } else {
            props.vesselListing(activePage, 20)
        }

    };
    const removeRecord = (id) => {
        if (!isAllowPermission('/delete-vessel', props.roles)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "You don't have permission of this feature"
            })
        } else {
            Swal.fire({
                title: "Delete Vessel",
                text: "Are you sure you want to delete this Vessel?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                // showCancelButton: true,
            })
                .then(result => {
                    if (result.isConfirmed) {
                        props.deleteVessel(id)
                    }
                });
        }
    }
    const getNavbarOptions = () => {
        let options = [
            {
                img: 'left-arrow.svg',
                className: "orange",
                name: "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.history.goBack()
            },
        ]
        if (props.userType === 'user' && (props.subType === 'user' || props.subType === 'subAdmin')) {
            options.push({
                img: 'Edit-icon.svg',
                className: "dark-green",
                name: "Create<br />Vessel",
                path: "/create-vessel",
                isLogout: false
            })
        }
        return options;
    }
    const handleChange = (id, val) => {
        setState({
            ...state,
            [id]: val,
        })
        let filterQuery = "?";
        if (state.startDate || id === 'startDate') {
            let from = id ? val : state.startDate
            from = moment(from).format('YYYY-MM-DD');
            filterQuery += `from=${from}&`
        }
        if (state.startDate && state.endDate || id === 'endDate') {
            let to = id ? val : state.endDate
            to = moment(to).add(1, 'days').format('YYYY-MM-DD');
            filterQuery += `to=${to}&`
        }
        if (state.name || id === 'name') {
            let name = state.name ? state.name : val;
            filterQuery += `name=${name}&`
        }
        if (state.type || id === 'type') {
            let type = state.type ? state.type : val;
            filterQuery += `kindOfVessel=${type}&`
        }
        if (state.customerName || id === 'customerName') {
            let customerName = state.customerName ? state.customerName : val;
            filterQuery += `userId=${customerName.value}&`
        }
        if (state.nextDeliveryDate || id === 'nextDeliveryDate') {
            let nextDeliveryDate = id ? val : state.nextDeliveryDate
            nextDeliveryDate = moment(nextDeliveryDate).format('YYYY-MM-DD');
            filterQuery += `arrivalTime=${nextDeliveryDate}&`
        }

        if (topVessel !== null) {
            if (state.userType !== 'customer') {
                let filterQuery = {};
                if (supplierId) {
                    filterQuery['supplierId'] = supplierId
                }
                props.topVessel(props.userId, state.userType, '', '', filterQuery)
            } else {
                props.vesselListing(0, 50);
            }
        } else {
            props.vesselListing(0, 20, filterQuery);
        }
    }
    const apiCallForCSVData = () => {
        /**
         * Requesting csv files data
         */
        if (topVessel !== null) {
            let filterQuery = {};
            if (supplierId) {
                filterQuery['supplierId'] = supplierId
            }
            if (state.userType !== 'customer') {
                props.topVessel(props.userId, state.userType, '', '', filterQuery)
            } else {
                props.topVessel(state.customerVessels, state.userType, '', '', filterQuery)
            }
        } else {
            props.vesselListing(0, state.totalCount)
        }

        setState({
            ...state,
            isCsVDownload: true
        })

    }
    const clearFilter = () => {
        props.getAllVessel();
        if (topVessel !== null) {
            if (state.userType !== 'customer') {
                let filterQuery = {};
                if (supplierId) {
                    filterQuery['supplierId'] = supplierId
                }
                props.topVessel(props.userId, state.userType, '', '', filterQuery)
            } else {
                props.vesselListing(0, 50);
            }
        } else {
            props.vesselListing(0, 20);
        }
        setState({
            ...state,
            name: '',
            type: '',
            customerName: '',
            startDate: null,
            endDate: null,
            nextDeliveryDate: '',
        })
    }
    return (
        <Wrapper>
            <DashboardNavbar title={topVessel || fleetOverview ? "Vessel Overview" : "Vessels"} navbarOptions={getNavbarOptions()} className="mesh-board-wrap d-flex align-items-end" />
            <div className="ordCatg-table-wrap mt-3 system-cat-filter">
                <div className="card-bx-sty">
                    <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                        <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                            <CsvDownload data={state.csvData} csvLinkRef={csvLinkRef} filename={"Vessel.csv"} headers={headers} apiCallForCSVData={apiCallForCSVData} />
                        </div>
                    </div>
                </div>
            </div>

            <InfiniteScroll
                dataLength={state.vesselList.length}
                next={fetchMoreData}
                height={"90vh"}
                hasMore={state.totalCount <= state.vesselList.length ? false : true}
                loader={<div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div>}
            >
                <div className=" mt-4 card-bx-sty">
                    {fleetOverview ?
                        <div className="ordCatg-table-wrap mt-3 system-cat-filter">
                            <Filters {...state} handleChange={handleChange} clearFilter={clearFilter} />
                        </div> : ''
                    }
                    <div className="table-responsive">
                        <table className="table theme-table-wrap with-action-btns">
                            <thead>
                                <TableHeader topVessel={topVessel} fleetOverview={fleetOverview} userType={props.userType} subType={props.subType} />
                            </thead>
                            <tbody>
                                {(props.apiCallStatus.isStarted.indexOf("vesselListing") !== -1 || props.apiCallStatus.isStarted.indexOf("topVessel") !== -1) ?
                                    <tr><td colSpan="20"><div className="loader-img text-center">
                                        <img style={{ width: "46px" }} src={loader} alt='' />
                                    </div></td></tr> : state.vesselList && state.vesselList.length === 0 ?
                                        <tr><td colSpan="40" className="text-center"><div>No Data Found</div></td></tr>
                                        : state.vesselList && state.vesselList.length > 0 && state.vesselList.map((item, index) => {
                                            return <TableContent topVessel={topVessel} fleetOverview={fleetOverview} index={index} item={item} userType={props.userType} subType={props.subType} isSubAdmin={props.isSubAdmin} removeRecord={removeRecord} roles={props.roles} />
                                        })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </InfiniteScroll>
        </Wrapper>
    )
}
const TableHeader = React.memo(props => {
    if (props.topVessel) {
        return <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Cutomer Name</th>
            <th scope="col">Turnover Total</th>

            {(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin' || props.subType === 'customer' ?
                <>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </>
                : ''
            }
        </tr>
    } else if (props.fleetOverview) {
        return <tr>
            <th scope="col">#</th>
            <th>Vessel Name</th>
            <th>Type</th>
            <th scope="col">Cutomer</th>
            <th>Provision rate</th>
            <th>Store rate</th>
            <th>Next Delivery Date</th>
            <th>Next Delivery Place</th>
            {(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin' || props.subType === 'customer' ?
                <th scope="col">Action</th>
                : ''
            }
        </tr>
    } else {
        return <tr>
            <th scope="col">#</th>
            <th scope="col">IMO</th>
            <th scope="col">Name</th>
            <th scope="col">Rate</th>
            <th scope="col">Number of Crew</th>
            <th scope="col">PIC</th>
            {(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin' || props.subType === 'customer' ?
                <>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </>
                : ''
            }
        </tr>
    }
})
const TableContent = React.memo(props => {
    if (props.topVessel) {
        return <tr key={props.index}>
            <td>{props.index + 1}</td>
            <td>{props.item.name}</td>
            <td>{props.item.type}</td>
            <td>{props.item.customerName}</td>
            <td>{props.item.orderCount}</td>
            {(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin' || props.subType === 'customer' ?
                <>  <td>{props.item.active ? <span className="text-success">Active</span> : <span className="text-danger">In-active</span>}</td>
                    <td>
                        <div className="actions-btns">
                            <Link to={"/vessel-info/" + props.item._id} className="btn mr-2 btn-info-tab"></Link>
                            {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editVessel'), props.roles) || (!(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin') ? "" : <Link to={"/edit-vessel/" + props.item._id} className="btn btn-edit-tab mr-2"></Link>}
                            {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission('/delete-vessel', props.roles) || (!(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin') ? "" : <button type="button" onClick={() => props.removeRecord(props.item._id)} className="btn btn-dlt-tab"></button>}

                        </div>
                    </td>
                </>
                : ""}
        </tr>
    } else if (props.fleetOverview) {
        let storeBudget = '';
        if (props.item.departments && props.item.departments.length > 0) {
            let departments = props.item.departments
            storeBudget = departments.map(s => parseInt(s.budget)).reduce((prev, next) => parseInt(prev) + parseInt(next));
        }
        return <tr key={props.index}>
            <td>{props.index + 1}</td>
            <td>{props.item.name}</td>
            <td>{props.item.kindOfVessel && props.item.kindOfVessel.label ? props.item.kindOfVessel.label : ''}</td>
            <td>{props.item.userId && props.item.userId.email ? props.item.userId.email : ''}</td>
            <td>{props.item.rate}</td>
            <td>{storeBudget}</td>
            <td>{props.item.arrivalTime ? moment(props.item.arrivalTime).format("DD-MM-YYYY") : ''}</td>
            <td>{props.item.placeOfDelivery}</td>
            {(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin' || props.subType === 'customer' ?
                <>  <td>
                    <div className="actions-btns">
                        <Link to={"/vessel-info/" + props.item._id} className="btn mr-2 btn-info-tab"></Link>
                        {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editVessel'), props.roles) || (!(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin')? "" : <Link to={"/edit-vessel/" + props.item._id} className="btn btn-edit-tab mr-2"></Link>}
                        {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission('/delete-vessel', props.roles) || (!(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin') ? "" : <button type="button" onClick={() => props.removeRecord(props.item._id)} className="btn btn-dlt-tab"></button>}

                    </div>
                </td>
                </>
                : ""}
        </tr>
    } else {
        return <tr key={props.index}>
            <td>{props.index + 1}</td>
            <td>{props.item.imo}</td>
            <td>{props.item.name}</td>
            <td>{props.item.rate}</td>
            <td>{props.item.noOfCrew}</td>
            <td>{props.item.pic}</td>
            {(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin' || props.subType === 'customer' ?
                <>
                    <td>{props.item.active ? <span className="text-success">Active</span> : <span className="text-danger">In-active</span>}</td>
                    <td>
                        <div className="actions-btns">
                            <Link to={"/vessel-info/" + props.item._id} className="btn mr-2 btn-info-tab"></Link>
                            {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editVessel'), props.roles) || (!(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin') ? "" : <Link to={"/edit-vessel/" + props.item._id} className="btn btn-edit-tab mr-2"></Link>}
                            {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission('/delete-vessel', props.roles) || (!(props.userType === 'user' && props.subType === 'user') || props.subType === 'subAdmin') ? "" : <button type="button" onClick={() => props.removeRecord(props.item._id)} className="btn btn-dlt-tab"></button>}

                        </div>
                    </td>
                </>
                : ""}
        </tr>
    }
})
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    commonReducer: state.commonReducer,
    vesselReducer: state.vesselReducer,
    userType: state.authReducer.userType,
    subType: state.authReducer.subType,
    isSubAdmin: state.authReducer.isSubAdmin,
    userId: state.authReducer.userId,
    roles: state.authReducer.roles,
    isSubAdmin: state.authReducer.isSubAdmin
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    vesselListing: (offset, limit, filterQuery) => dispatch(vesselListing(offset, limit, filterQuery)),
    topVessel: (userId, userType, from, to, filterQuery) => dispatch(topVessel(userId, userType, from, to, filterQuery)),
    deleteVessel: (id) => dispatch(deleteVessel(id)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    getAllVessel: () => dispatch(getAllVessel()),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VesselList);
