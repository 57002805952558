import styled from "styled-components";
import EditIcon from "../../../assets/images/editpen-icon.svg";
import DltIcon from "../../../assets/images/delete-icon.svg";
import ChangePasswordIcon from "../../../assets/images/change-password-ico.png";

export const CommonWrapper = styled.div`
.reminder {min-height:400px}
.formBox{margin-top:0;border:1px solid #000;-webkit-box-shadow:0 4px 4px rgba(0,0,0,0.25);box-shadow:0 4px 4px rgba(0,0,0,0.25);border-radius:10px;padding:30px;height:100%;background:#EBEBEB}
.formBox h1{margin:0;padding:0;text-align:center;margin-bottom:50px;text-transform:uppercase;font-size:28px}
.formBox .form-group{position:relative;-webkit-box-sizing:border-box;box-sizing:border-box;margin-bottom:50px}
.formBox .form-group .inputText{position:absolute;font-size:24px;line-height:50px;-webkit-transition:.5s;-o-transition:.5s;transition:.5s;opacity:.5}
.formBox .form-group .form-control{position:relative;width:100%;height:40px;background-color:transparent;border:none;border-bottom:1px solid #979797;border-radius:0;padding-left:0;font-size:16px;outline:none!important;-webkit-box-shadow:none!important;box-shadow:none!important;font-weight:500}
.formBox .form-group .form-control::-webkit-input-placeholder{color:#00000080;font-weight:500}
.formBox .form-group .form-control::-moz-placeholder{color:#00000080;font-weight:500}
.formBox .form-group .form-control:-ms-input-placeholder{color:#00000080;font-weight:500}
.formBox .form-group .form-control:-moz-placeholder{color:#00000080;font-weight:500}
.theme-table-wrap{background:#EBEBEB}
.uploadFile-wrap{width:40%;min-width:320px;border:1px dashed #b8b8b8;padding:20px;margin:0 auto;margin-bottom:30px;border-radius:10px}
.uploadFile-wrap .input-file-container{position:relative;width:225px;margin:0 auto}
.uploadFile-wrap .input-file-trigger{display:block;padding:10px 45px;background:#39D2B4;color:#fff;font-size:1em;-webkit-transition:all .4s;-o-transition:all .4s;transition:all .4s;cursor:pointer;text-align:center}
.uploadFile-wrap .input-file{position:absolute;top:0;left:0;width:225px;opacity:0;padding:14px 0;cursor:pointer}
.uploadFile-wrap .input-file:hover + .input-file-trigger,.uploadFile-wrap .input-file:focus + .input-file-trigger,.uploadFile-wrap .input-file-trigger:hover,.uploadFile-wrap .input-file-trigger:focus{background:#206F9E;color:#fff}
.uploadFile-wrap .file-return{margin:0}
.uploadFile-wrap .file-return:not(:empty){margin:1em 0}
.uploadFile-wrap .file-return{font-style:italic;font-size:13px;font-weight:600;color:#2e2e2e}
.uploadFile-wrap .file-return:not(:empty):before{content:"Selected file: ";font-style:normal;font-weight:400;font-size:14px}
.uploadFile-wrap h2,.form-opti-text{font-size:19px;margin-bottom:20px;color:#5e5e5e}
/* Comparison Details Style */
.diff-details-wrap .rfq-details-items {
    padding: 10px 10px;
    width: 240px;
    min-width: 240px;
    text-align: center;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    margin-right: 20px;
    box-shadow: 0 0 8px #0000001f;
} 
.diff-details-wrap .rfq-details-items:last-child {
    margin-right: 10px;
}
.diff-details-wrap .rfq-details-items .btn.themeReguler-btn {
    width: 100%;
    max-width: 100%;
    font-size: 11px;
    padding: 5px 20px;
}
.diff-details-wrap .customCheckBoxLabel {
    margin-bottom: 0;
    width: 11px;
    height: 11px;
    padding-left: 17px;
}
.diff-details-wrap .customCheckBoxLabel .checkmark {
    border: 1px solid #9e9e9e;
    background-color: #e9e9e9;
    border-radius: 3px;
    box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 17%);
    border-radius: 50%;
    height: 11px;
    width: 11px;
}
.diff-details-wrap .customCheckBoxLabel input:checked ~ .checkmark {
    background-color: #ffffff;
    border-color: #E91E63;
}
.diff-details-wrap .next-process-btns .btn.themeReguler-btn {
    min-width: max-content;
    width: calc(50% - 5px);
    font-size: 10px;
    padding: 4px 20px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
.rfq-details-items {
    padding: 0 15px 
}
.rfq-durations.rfq-details-items {
    margin-right: 20px
}
.rfq-details-items h2 {
    font-size: 16px;
    font-weight: 400;
}
.next-oder .rfq-details-items h2{  font-size: 13px;!important }
.rfq-details-items .inner-content h4 {
    font-size: 13px;
    font-weight: 400;
    margin-top: 15px;
    max-width: max-content;
}
.rfq-details-items .inner-content h4 span {
    font-size: 12px;
}
.rfq-rating.rfq-details-items .inner-content h4 {
    margin-top: 25px;
}
.star-checkbox {
    display: none;
}
.rating-checks label {
    color: #dad9d9;
    margin: 0;
    font-size: 13px;
    position: sticky;
}
.star-checkbox:checked + label {
    color: #fff;
}
.rfq-rating.rfq-details-items .rounded-tag{
    font-size: 9px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #559709;
    border-radius: 40px;
    color: #fff;
    padding: 4px 11px;
}
.rfq-rating.rfq-details-items .rounded-tag.orng-clr {
    background: #E6A935;
}
.rfq-rating li {
    padding: 0 2px;
}
.rfq-rating .rounded-tag.progress-bar-wrap {
    width: 90px;
    background: #00539CFF!important;
    box-shadow: 0 0 0 1px #b7b7b7;
    padding: 0
}
.rfq-rating .rounded-tag.progress-bar-wrap .rounded-tag{
   width: 85%
}
.diff-details-wrap .rfq-details-items p{
    font-size: 11px;
    text-align: left;
    color: #6b6b6b;
}
.contact-info.rfq-details-items ul li {
    font-size: 14px;
    color: #4c4c4c;
    margin-bottom: 5px
}
.contact-info.rfq-details-items ul li:last-child {
    margin-bottom: 0
}
.contact-info.rfq-details-items ul li span {
    display: inline-block;
    margin-right: 45px
}
.total-value-count {
    font-size: 13px;
    text-align: right;
}
.contact-info.rfq-details-items.items-order-detail ul li {font-size:13px !important;color:#212529!important;}
.compar-table-items .compar-table-item {
    padding: 10px 10px;
    width: 240px;
    min-width: 240px;
    text-align: center;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    margin-right: 20px;
    box-shadow: 0 0 8px #0000001f;
}
.compar-table-items .compar-table-item:last-child {
    margin-right: 10px;
}
.compar-table-items .compar-table-item .table-title-wrap {
    font-size: 12px;
    padding: 10px 12px;
    font-weight: 600;
    background: #f2f2f2;
    margin-bottom: 0;
    border-bottom: 1px solid #E91E63;
    text-align: left;
}
.compar-table-items .compar-table-item .table-title-wrap h2 {
    font-size: 12px;
    font-weight: 600;
}
.compar-table-items .compar-table-item .table-responsive {
    height: 0;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.compar-table-items.collapse-content .compar-table-item .table-responsive {
    height: auto;
    margin-top: 5px;
}
.compar-table-items .theme-table-wrap2 thead th {
    font-size: 10px;
    padding: 10px 7px;
    white-space: nowrap;
    color: #656565;
}

 /* .suppliertable .item1{width:8%;} */
/* .suppliertable .item1{width:12%;} */
.compar-table-items .compar-table-item .btn.btn-link {
    padding: 0 !important;
    background: transparent !important;
    border: 8px solid transparent;
    border-left-color: #5e5e5e ;
    margin-right: 10px;
    transition: unset;
    -webkit-transition: unset;
    -moz-transition: unset;
    -ms-transition: unset;
    -o-transition: unset;
}
.compar-table-items.collapse-content .compar-table-item .btn.btn-link {
    border-top-color: #5e5e5e ;
    border-left-color: transparent;
    border-bottom: unset;
}
.supplier-comparison-wrap .ordCatg-table-wrap.sup-rfq-wrap {
    overflow: auto;
}
.comp-first-row, .comp-second-row {
    min-width: 1035px;
}
.price-text{margin-right:5px;vertical-align: revert;}
// .rfq-rating.rfq-details-items .carousel-indicators{display:none !important}
/* Table Style */
.table.theme-table-wrap thead th{white-space: nowrap !important;}
.table.theme-table-wrap thead th{vertical-align:bottom;border-bottom:2px solid #dee2e6;font-size:13px;font-weight:600;background: #f2f2f2;}
.table.theme-table-wrap tbody td{font-size:12px;font-weight:500;vertical-align: middle;}
.table.theme-table-wrap tbody tr:nth-child(even) td{background: #f2f2f2;}
.btn-edit-tab {background: url(${EditIcon}) no-repeat;background-size: 20px;width: 30px;height: 30px;opacity: 0.6;background-position: center;-webkit-transition: none !important;-o-transition: none !important;transition: none !important;}
.btn-dlt-tab {background: url(${DltIcon}) no-repeat;background-size: 20px;width: 30px;height: 30px;opacity: 0.6;background-position: center;-webkit-transition: none !important;-o-transition: none !important;transition: none !important;}
.btn-change-password-tab {background: url(${ChangePasswordIcon}) no-repeat;background-size: 20px;width: 30px;height: 30px;opacity: 0.6;background-position: center;-webkit-transition: none !important;-o-transition: none !important;transition: none !important;}
.btn-edit-tab:hover {opacity: 1;filter: invert(62%) sepia(68%) saturate(353%) hue-rotate(45deg) brightness(99%) contrast(91%);}
.btn-dlt-tab:hover {opacity: 1;-webkit-filter: invert(12%) sepia(81%) saturate(5841%) hue-rotate(11deg) brightness(89%) contrast(123%);filter: invert(12%) sepia(81%) saturate(5841%) hue-rotate(11deg) brightness(89%) contrast(123%);}
.btn-change-password-tab:hover {opacity: 1;-webkit-filter: invert(12%) sepia(81%) saturate(5841%) hue-rotate(11deg) brightness(89%) contrast(123%);filter: invert(12%) sepia(81%) saturate(5841%) hue-rotate(11deg) brightness(89%) contrast(123%);}
.pack-feature-wrap .form-control label {margin-bottom: 0}

@media (max-width: 1600px) {
    .uploadFile-wrap .input-file-trigger {padding: 7px 25px;font-size: 14px;}
    .uploadFile-wrap .input-file-container {width: 155px}
    .uploadFile-wrap .file-return,.uploadFile-wrap .file-return:not(:empty):before {font-size: 12px;}
    .table.theme-table-wrap thead th {font-size: 12px;}
    .table.theme-table-wrap tbody td {font-size: 11px;}
    .btn-dlt-tab,.btn-edit-tab,.btn-change-password-tab {background-size: 16px;}
    .formBox .form-group .form-control {font-size: 13px;}
    .formBox .form-group {margin-bottom: 30px;}
    .pack-feature-wrap .form-control {height: 29px;font-size: 12px;line-height: normal;}
    .pack-feature-wrap .form-check-label {font-size: 11px;}
    .pack-feature-wrap .form-check-inline {display:inline-grid !important;}
    .pack-feature-wrap .form-check-inline .form-check-input {vertical-align: middle !important;position: relative;top:-2px;}
    .formBox h1 {font-size: 23px;margin-bottom: 35px;}
    .request-price h3{color:red !important; background:yellow;}
}
@media (max-width: 576px) {
    .formBox {padding: 20px;}
    .uploadFile-wrap {padding: 20px 10px;min-width: 230px;}
}
`
