import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetTopSupplyHubAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function topSupplyHub(data, userType, from, to, filterQuery) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "topSupplyHub" }));
    let url = "";
    let bodyContent = {};
    if (userType) {
      userType === "supplier" ? bodyContent['supplierId'] = data : userType === "customer" ? bodyContent['vesselId'] = data : bodyContent['customerId'] = data
    }
    if (from && to) {
      bodyContent['from'] = from
      bodyContent['to'] = to
    }
    if (filterQuery && filterQuery.supplierId && userType !== 'supplier') {
      bodyContent['supplierId'] = filterQuery.supplierId
    }
    url = Constant.apiURl + "/quotation/topPorts";
    let myJson = await FETCH("POST", url, {
      ...bodyContent,
    });
    if (myJson && myJson.code === 201) {
      dispatch(GetTopSupplyHubAction(myJson.data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "topSupplyHub",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "topSupplyHub",
          message: myJson.message,
        })
      );
    }
  };
}
