import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import {
  GetRemindersAction,
  AppendReminderAction,
  GetReminderDetailAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function createReminder(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "createReminder" }));
    let myJson = await FETCH("POST", Constant.apiURl + "/cms/reminder", data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "createReminder",
          message: myJson.message,
        })
      );
    } else {
      let error = myJson.message;
      if (myJson.error.code === 11000) {
        error = `${myJson.error.keyValue.name} alreasy exists.`;
      }
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "createReminder",
          message: error,
        })
      );
    }
  };
}

export function reminderListing() {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getReminders" }));
    dispatch(GetRemindersAction([]));
    let url = Constant.apiURl + "/cms/reminder/";
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      dispatch(GetRemindersAction(myJson.data, myJson.count));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getReminders",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getReminders",
          message: myJson.message,
        })
      );
    }
  };
}
export function updateReminder(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateReminder" }));
    let myJson = await FETCH("PUT", Constant.apiURl + "/cms/reminder", data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "updateReminder",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "updateReminder",
          message: myJson.message,
        })
      );
    }
  };
}
export function deleteReminder(id) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteReminder" }));
    let myJson = await FETCH("DELETE", Constant.apiURl + "/cms/reminder", {
      id,
    });
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "deleteReminder",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "deleteReminder",
          message: myJson.message,
        })
      );
    }
  };
}
export function getReminderDetail(id) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getReminderDetail" }));
    let myJson = await FETCH("GET", Constant.apiURl + "/cms/reminder/" + id);
    if (myJson && myJson.code === 201) {
      dispatch(GetReminderDetailAction(myJson.data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getReminderDetail",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getReminderDetail",
          message: myJson.message,
        })
      );
    }
  };
}
